import styled from '@emotion/styled'
import * as React from 'react'

import Button from '~/components/atoms/Button'
import Chip from '~/components/atoms/Chip'
import TextField from '~/components/atoms/TextField'
import { Invitation } from '~/domain/user/Invitation'
import { InvitationResult } from '~/domain/user/InvitationResult'
import { User } from '~/domain/user/User'
import { useComposingRef } from '~/hooks/useComposingRef'
import { useInvitationEmails } from '~/presentation/user/useInvitationEmails'
import { scrollbarStyle } from '~/styles/scrollbar'
import * as vars from '~/styles/variables'

interface Props {
  handleClose: () => void
  handleInvite: (emails: string[]) => Promise<InvitationResult>
  inviting: boolean
  users: User[]
  invitations: Invitation[]
  isActive: boolean
}

export interface InvitationEmail {
  address: string
  error?: string
}

export const InviteUsersForm: React.FC<Props> = (props) => {
  const { handleClose, handleInvite } = props
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [inputText, setInputText] = React.useState('')
  const composingRef = useComposingRef()
  const [errorMessage, setErrorMessage] = React.useState<string>()
  const {
    invitationEmails,
    addEmail,
    clearEmails,
    deleteLast,
    deleteEmailAt,
    applyErrors,
    hasError,
  } = useInvitationEmails(props.users, props.invitations)

  React.useEffect(() => {
    if (!props.isActive) return
    const timer = window.setTimeout(() => {
      if (inputRef.current !== null) {
        inputRef.current.focus()
      }
    }, 50)
    return () => {
      window.clearTimeout(timer)
    }
  }, [props.isActive])

  const handleInputTextChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputText(event.target.value)
    },
    []
  )

  const handleCancelButtonClick = React.useCallback(() => {
    clearEmails()
    setErrorMessage(undefined)
    setInputText('')
    handleClose()
  }, [clearEmails, handleClose])

  const handleClickScrollContainer = React.useCallback(() => {
    if (inputRef.current !== null) {
      inputRef.current.focus()
    }
  }, [])

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (composingRef.current) {
        return
      }
      if ((event.key === 'Enter' || event.key === ' ') && inputText !== '') {
        event.stopPropagation()
        event.preventDefault()
        addEmail(inputText)
        setInputText('')
      }
      if (event.key === 'Backspace' && inputText === '') {
        event.stopPropagation()
        event.preventDefault()
        deleteLast()
      }
    },
    [composingRef, inputText, addEmail, deleteLast]
  )

  const handleOnPaste = React.useCallback(
    (event: React.ClipboardEvent) => {
      const pasteText = event.clipboardData.getData('Text').trim()
      event.preventDefault()
      addEmail(pasteText)
      setInputText('')
      if (inputRef.current !== null) {
        inputRef.current.scrollIntoView({ block: 'end' })
      }
    },
    [addEmail]
  )

  const handleCloseChip = React.useCallback(
    (index: number) => {
      deleteEmailAt(index)
    },
    [deleteEmailAt]
  )

  const handleSubmit = React.useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault()
      if (props.inviting) {
        return
      }
      if (invitationEmails.length === 0) {
        return
      }
      if (hasError) {
        return
      }
      setErrorMessage(undefined)
      try {
        const invitationResult = await handleInvite(
          invitationEmails.map((email) => email.address)
        )
        if (invitationResult.isSuccess) {
          clearEmails()
          setInputText('')
          return
        }
        applyErrors(invitationResult.errors)
        setErrorMessage(invitationResult.wholeError)
      } catch (error) {
        setErrorMessage('招待メールの送信に失敗しました')
        console.error(error)
      }
    },
    [
      props.inviting,
      invitationEmails,
      hasError,
      handleInvite,
      applyErrors,
      clearEmails,
    ]
  )

  return (
    <>
      <form onSubmit={handleSubmit}>
        <ScrollContainer onClick={handleClickScrollContainer}>
          {invitationEmails.map((email, i) => (
            <_Chip
              key={i}
              text={email.address}
              handleClose={() => handleCloseChip(i)}
              hasError={email.error !== undefined}
              title={email.error}
            />
          ))}
          <_TextField
            ref={inputRef}
            value={inputText}
            autoFocus={true}
            hasBorder={false}
            placeholder="メールアドレスを入力してください"
            onChange={handleInputTextChange}
            onKeyDown={handleKeyDown}
            onPaste={handleOnPaste}
          />
        </ScrollContainer>
        {hasError && (
          <Errors>
            {invitationEmails
              .filter((email) => email.error !== undefined)
              .map((email, i) => (
                <p key={i}>{email.error}</p>
              ))}
          </Errors>
        )}
        {errorMessage && <Error>{errorMessage}</Error>}
      </form>
      <Buttons>
        <Button
          type="tertiary"
          onClick={handleCancelButtonClick}
          style={{ marginRight: vars.space.s }}
        >
          キャンセル
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit}
          loading={props.inviting}
          disabled={invitationEmails.length === 0 || hasError}
        >
          招待メールを送る
        </Button>
      </Buttons>
    </>
  )
}

const ScrollContainer = styled('div')({
  overflowY: 'scroll',
  overflowX: 'hidden',
  display: 'inline-flex',
  flexWrap: 'wrap',
  alignItems: 'start',
  height: 110,
  width: '100%',
  paddingTop: vars.space.s,
  paddingBottom: vars.space.s,
  paddingRight: vars.space.m,
  paddingLeft: vars.space.m,
  border: `1px solid ${vars.color.border}`,
  borderRadius: vars.borderRadius.m,
  ...scrollbarStyle,
})

const _Chip = styled(Chip)({
  marginRight: vars.space.s,
  marginBottom: vars.space.s,
})

const _TextField = styled(TextField)({
  width: '16em', // placeholder の文字長
  padding: 0,
  lineHeight: vars.lineHeight.just,
  height: 40,
})

const Buttons = styled('div')({
  marginTop: vars.space.s * 1.5,
  textAlign: 'right',
})

const Errors = styled('div')({
  overflowY: 'scroll',
  overflowX: 'hidden',
  maxHeight: '10em',
  lineHeight: vars.lineHeight.heading,
  marginTop: vars.space.s,
  marginBottom: vars.space.l,
  color: vars.color.error,
  fontSize: vars.fontSize.xs,
  ...scrollbarStyle,
})

const Error = styled('div')({
  marginTop: vars.space.s,
  color: vars.color.error,
  fontSize: vars.fontSize.xs,
})
