import { Pagination } from '~/common/Pagination'
import config from '~/common/config'
import { Tson } from '~/data/Tson'
import { LookupTableApi } from '~/data/lookupTable/LookupTableApi'
import {
  LookupTableCellJson,
  LookupTableColumnSchemaJson,
  LookupTableJson,
  LookupTableRowJson,
} from '~/data/lookupTable/LookupTableJson'
import {
  mapToLookupTableColumnSchemaJson,
  mapToLookupTableJson,
  mapToLookupTableRowJson,
} from '~/data/lookupTable/mapToLookupTableJson'
import requestJson, {
  HttpError,
  requestDelete,
  requestPatch,
  requestPost,
} from '~/data/request'
import { sanitize } from '~/data/utils'

export class LookupTableApiImpl implements LookupTableApi {
  async get(lookupTableId: string): Promise<LookupTableJson | undefined> {
    try {
      const res = await requestJson(
        `${config.apiRoot}/lookup_tables/${sanitize(lookupTableId)}`
      )
      return mapToLookupTableJson(new Tson(res))
    } catch (e) {
      if (e instanceof HttpError && e.statusCode === 404) {
        return undefined
      }
      throw e
    }
  }

  async getList(pageNumber: number): Promise<Pagination<LookupTableJson>> {
    const res = await requestJson(
      `${config.apiRoot}/lookup_tables?page=${pageNumber}`
    )
    const json = mapToLookupTableListResponseJson(new Tson(res))
    return {
      paginationMeta: {
        countPerPage: json.limit,
        currentPageNumber: json.current,
        totalCount: json.count,
      },
      items: json.results,
    }
  }

  async create(name: string): Promise<LookupTableJson> {
    const body = {
      name,
    }
    const res = await requestPost(`${config.apiRoot}/lookup_tables`, body)
    return mapToLookupTableJson(new Tson(res))
  }

  async getColumnSchemas(
    lookupTableId: string
  ): Promise<LookupTableColumnSchemaJson[]> {
    const res = await requestJson(
      `${config.apiRoot}/lookup_tables/${lookupTableId}/schemas`
    )
    return new Tson(res).asArray().map(mapToLookupTableColumnSchemaJson)
  }

  async updateColumnSchema(
    lookupTableId: string,
    columnSchema: LookupTableColumnSchemaJson
  ): Promise<LookupTableColumnSchemaJson> {
    const body = {
      label: columnSchema.label,
      appId: columnSchema.appId ?? '',
    }
    const res = await requestPatch(
      `${config.apiRoot}/lookup_tables/${lookupTableId}/schemas/${columnSchema.id}`,
      body
    )
    return mapToLookupTableColumnSchemaJson(new Tson(res))
  }

  async updateTableName(
    lookupTableId: string,
    tableName: string
  ): Promise<LookupTableJson> {
    const body = {
      name: tableName,
    }
    const res = await requestPatch(
      `${config.apiRoot}/lookup_tables/${lookupTableId}`,
      body
    )
    return mapToLookupTableJson(new Tson(res))
  }

  async getRows(
    lookupTableId: string,
    pageNumber: number
  ): Promise<Pagination<LookupTableRowJson>> {
    const res = await requestJson(
      `${config.apiRoot}/lookup_tables/${lookupTableId}/rows?page=${pageNumber}`
    )
    const json = mapToRowListResponseJson(new Tson(res))
    return {
      paginationMeta: {
        countPerPage: json.limit,
        currentPageNumber: json.current,
        totalCount: json.count,
      },
      items: json.results,
    }
  }

  async insertRow(
    lookupTableId: string,
    cells: LookupTableCellJson[]
  ): Promise<LookupTableRowJson> {
    const body = {
      cells,
    }
    const res = await requestPost(
      `${config.apiRoot}/lookup_tables/${lookupTableId}/rows`,
      body
    )
    return mapToLookupTableRowJson(new Tson(res))
  }

  async deleteRow(lookupTableId: string, rowId: number): Promise<void> {
    await requestDelete(
      `${config.apiRoot}/lookup_tables/${lookupTableId}/rows/${rowId}`
    )
  }
}

interface LookupTableListResponseJson {
  limit: number
  current: number
  count: number
  results: LookupTableJson[]
}

function mapToLookupTableListResponseJson(
  tson: Tson
): LookupTableListResponseJson {
  return {
    count: tson.getNumber('count'),
    current: tson.getNumber('current'),
    limit: tson.getNumber('limit'),
    results: tson.getArray('results').map(mapToLookupTableJson),
  }
}

interface RowListResponseJson {
  limit: number
  current: number
  count: number
  results: LookupTableRowJson[]
}

function mapToRowListResponseJson(tson: Tson): RowListResponseJson {
  return {
    count: tson.getNumber('count'),
    current: tson.getNumber('current'),
    limit: tson.getNumber('limit'),
    results: tson.getArray('results').map(mapToLookupTableRowJson),
  }
}
