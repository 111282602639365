import * as React from 'react'

import { Result } from '~/common/Result'
import { apiClients } from '~/common/apiClients'
import {
  firebaseAuth,
  googleSignInWithPopup,
} from '~/presentation/auth/firebaseAuth'

const authService = apiClients.authService
const invitationService = apiClients.invitationService

export function useGoogleSignUpByInvitation(
  invitationToken: string
): {
  signUp: () => Promise<boolean>
  verifyTokenResult: Result<boolean>
} {
  const [verifyTokenResult, setVerifyTokenResult] = React.useState<
    Result<boolean>
  >(new Result.Loading())

  const signUp = React.useCallback(async () => {
    try {
      const { user } = await googleSignInWithPopup()
      if (user === null) {
        return false
      }
      const idToken = await user.getIdToken()
      await authService.signUpByInvitationWithGoogle(idToken, invitationToken)
      return true
    } finally {
      await firebaseAuth().signOut()
    }
  }, [invitationToken])

  React.useEffect(() => {
    ;(async () => {
      try {
        const isVerified = await invitationService.verifyToken(invitationToken)
        setVerifyTokenResult(new Result.Success(isVerified))
      } catch (error) {
        setVerifyTokenResult(new Result.Failure(error))
      }
    })()
  }, [invitationToken])

  return { signUp, verifyTokenResult }
}
