import styled from '@emotion/styled'
import * as React from 'react'
import ReactContentLoader from 'react-content-loader'

import { Result } from '~/common/Result'
import UserComponent from '~/components/molecules/User'
import NoResults from '~/components/organisms/NoResults'
import {
  Table,
  Body as Tbody,
  Cell as Td,
  HeaderCell as Th,
  Header as Thead,
  Row as Tr,
} from '~/components/organisms/Table'
import { User } from '~/domain/user/User'

interface Props {
  result: Result<User[]>
}

export const UserTable: React.FC<Props> = (props) => {
  if (props.result.loading) {
    return <ContentLoader />
  }

  if (props.result.error !== undefined) {
    return (
      <NoResults
        heading="エラー"
        description="ユーザー一覧の取得に失敗しました"
      />
    )
  }

  return (
    <Table style={{ tableLayout: 'fixed' }}>
      <Thead>
        <Tr>
          <_Th style={{ width: 400 }}>ユーザー名</_Th>
          <_Th>権限</_Th>
        </Tr>
      </Thead>
      <Tbody>
        {props.result.data.map((user) => (
          <Tr key={user.id}>
            <_Td>
              <UserComponent username={user.username} fontWeight={'bold'} />
            </_Td>
            <_Td>{user.isAdmin ? '管理者' : '一般'}</_Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

const _Th = styled(Th)({
  whiteSpace: 'nowrap',
})

const _Td = styled(Td)({
  whiteSpace: 'nowrap',
})

const ContentLoader: React.FC = () => {
  return (
    <>
      <div style={{ display: 'flex', height: 37, alignItems: 'center' }}>
        <ReactContentLoader speed={1} width={1100} height={14}>
          <rect x={0} y={0} rx="2" ry="2" width="150" height="14" />
          <rect x={408} y={0} rx="2" ry="2" width="50" height="14" />
        </ReactContentLoader>
      </div>
      {new Array(10).fill(null).map((__, i) => (
        <div
          key={i}
          style={{ display: 'flex', height: 58, alignItems: 'center' }}
        >
          <ReactContentLoader speed={1} width={1100} height={14}>
            <rect x={0} y={0} rx="2" ry="2" width="392" height="14" />
            <rect x={408} y={0} rx="2" ry="2" width="50" height="14" />
          </ReactContentLoader>
        </div>
      ))}
    </>
  )
}
