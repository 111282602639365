import styled from '@emotion/styled'
import * as React from 'react'
import { X } from 'react-feather'

import * as vars from '~/styles/variables'

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  text: string
  hasError?: boolean
  handleClose: () => void
}

const Chip: React.FC<Props> = (props) => (
  <Container hasError={props.hasError} {...props}>
    <span>{props.text}</span>
    <CloseButton onClick={props.handleClose} hasError={props.hasError}>
      <X size={12} />
    </CloseButton>
  </Container>
)

const Container = styled('span')(
  {
    display: 'inline-flex',
    alignItems: 'center',
    padding: vars.space.s * 1.25,
    color: vars.fontColor.secondary,
    fontSize: vars.fontSize.s,
    lineHeight: vars.lineHeight.just,
    borderRadius: vars.borderRadius.s,
    whiteSpace: 'pre',
  },
  (props: { hasError?: boolean }) => ({
    backgroundColor: props.hasError ? '#FFD7D7' : vars.colorPalette.blue50,
  })
)

const CloseButton = styled('span')(
  {
    marginLeft: vars.space.s,
    cursor: 'pointer',
  },
  (props: { hasError?: boolean }) => ({
    color: props.hasError
      ? vars.fontColor.secondary
      : vars.colorPalette.blue800,
  })
)

export default React.memo(Chip)
