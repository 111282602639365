import { ExpectedObjectKey } from '~/domain/workflow/object/ExpectedObjectKey'
import { ExpectedObjectSnapshot } from '~/domain/workflow/test/TestResult'

export class ExpectedObjectSnapshotMap {
  private map: Map<string, ExpectedObjectSnapshot>

  constructor(instances: ExpectedObjectSnapshot[] = []) {
    this.map = new Map(instances.map((it) => [it.expectedObjectKey.value, it]))
  }

  find(key: ExpectedObjectKey): ExpectedObjectSnapshot | undefined {
    return this.map.get(key.value)
  }

  add(snapshot: ExpectedObjectSnapshot) {
    this.map.set(snapshot.expectedObjectKey.value, snapshot)
  }

  remove(key: ExpectedObjectKey) {
    this.map.delete(key.value)
  }

  getAll(): ExpectedObjectSnapshot[] {
    return Array.from(this.map.values())
  }
}
