import styled from '@emotion/styled'
import * as hash from 'object-hash'
import * as React from 'react'
import Slider from 'react-slick'

import Inner from '~/components/organisms/Inner'
import UseCaseCard from '~/containers/Home/useCases/UseCaseCard'
import { useIsTablet } from '~/containers/Home/useIsTablet'
import * as vars from '~/styles/variables'

interface UseCase {
  title: string
  description: string
  media: { imagePath: string } | { videoUrl: string }
}

const data: UseCase[] = [
  {
    title: '把握すべき重要な変化のみを\nタイムリーにSlackに通知',
    description:
      '変化を見逃したくないレコードの条件及び通知先を設定すると、条件に該当するレコードの変化が起きた場合のみ、リアルタイムに通知されます。',
    media: {
      videoUrl: 'https://www.youtube.com/embed/0e_wFjsUiHU',
    },
  },
  {
    title: 'ToDO漏れやフェーズの停滞を\n自動でSlackに通知',
    description:
      '期日を過ぎたToDOや完了予定日が近い商談、フェーズが変化していない商談などを、予め設定した時間やチャンネルに通知することが可能です。',
    media: {
      videoUrl: 'https://www.youtube.com/embed/LkaebtLOqHg',
    },
  },
  {
    title: 'Salesforce上での承認申請を\nSlackに通知',
    description:
      'Salesforceの承認プロセスを利用して、承認申請が挙がった場合に必要な関係者にSlackで内容を通知し、そのままSlack上で承認・却下を行えます。 ',
    media: {
      imagePath: '/static/images/lp/usecase1@2x.png',
    },
  },
  {
    title: 'オンライン商談に必要な\n一連の作業を自動化',
    description:
      'Salesforceで商談が作成されたら、カレンダーにも予定が登録され、オンライン商談の場合は当日のZoomの発行、先方へのメール連絡まで自動完結します。',
    media: {
      videoUrl: 'https://www.youtube.com/embed/9d6jGkn7PZs',
    },
  },
  {
    title: '商談情報を元にクラウドサインで\n申込書を自動作成・送信',
    description:
      'Salesforceで商談フェーズ等が予め設定した条件になったら、商談情報を元にクラウドサインで自動で申込書を作成し、送付まで完結することが可能です。',
    media: {
      videoUrl: 'https://www.youtube.com/embed/rNqUZT7XCac',
    },
  },
]

const UseCases: React.FC = () => {
  const isTablet = useIsTablet()
  return (
    <>
      <Inner size="m" responsive={true}>
        <Heading>
          ノーコードだから
          <br />
          素早く簡単に自動化
        </Heading>
        <Subheading>
          業務で使うSaaSを連携し、自動化・効率化。
          <br />
          ノーコードで簡単・迅速に作成できて、開発に費やす時間を減らします。
        </Subheading>
      </Inner>
      <div style={{ width: '100%', marginTop: vars.space.m * 1.5 }}>
        <_Slider
          centerMode={true}
          centerPadding="0px"
          variableWidth={!isTablet}
          dots={true}
          autoplay={true}
          autoplaySpeed={4000}
          pauseOnHover={true}
          pauseOnDotsHover={true}
          arrows={false}
        >
          {data.map((useCase) => (
            <div key={hash(useCase.title)}>
              <UseCaseCard
                title={useCase.title}
                description={useCase.description}
                media={useCase.media}
              />
            </div>
          ))}
        </_Slider>
      </div>
    </>
  )
}

const Heading = styled('h1')({
  fontSize: vars.fontSize.xxxxl,
  lineHeight: vars.lineHeight.heading,
  fontWeight: 800,
  textAlign: 'center',
  [vars.media.tablet]: {
    fontSize: vars.fontSize.xl,
  },
})

const Subheading = styled('p')({
  marginTop: vars.space.m,
  fontSize: 17,
  fontWeight: 500,
  lineHeight: vars.lineHeight.body,
  textAlign: 'center',
  [vars.media.tablet]: {
    fontSize: 15,
  },
})

const _Slider = styled(Slider)({
  '& div': {
    outline: 'none',
  },
  '& div.slick-slide:not(.slick-current)': {
    opacity: 0.6,
  },
  '& ul.slick-dots': {
    position: 'unset',
    bottom: 'unset',
  },
})

export default UseCases
