import styled from '@emotion/styled'
import * as _ from 'lodash'
import * as React from 'react'
import { Filter, X } from 'react-feather'
import Helmet from 'react-helmet'

import { FetchStatus } from '~/common/types'
import Loader from '~/components/atoms/Loader'
import App from '~/components/molecules/App'
import Tabs from '~/components/molecules/Tabs'
import Inner from '~/components/organisms/Inner'
import NoResults from '~/components/organisms/NoResults'
import { Recipe } from '~/domain/recipe/Recipe'
import { useRecipes } from '~/hooks/useRecipes'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import Content from '~/presentation/recipe/Content'
import FilterByApp from '~/presentation/recipe/FilterByApp'
import * as vars from '~/styles/variables'

enum Category {
  all = 'all',
  sales = 'sales',
}

const RecipeList: React.FC = () => {
  const definitions = useDefinitions()

  const [isFilterShown, setIsFilterShown] = React.useState(false)
  const [category, setCategory] = React.useState(Category.all)
  const [filteredApps, setFilteredApps] = React.useState<string[]>([])

  const { recipes, fetchStatus, errorMessage } = useRecipes()

  function addFilteredApp(appId: string) {
    const _filteredApps = filteredApps.slice()
    _filteredApps.push(appId)
    setFilteredApps(_filteredApps)
  }

  function removeAppFromFilters(appId: string) {
    const _filteredApps = filteredApps.slice()
    _.remove(_filteredApps, (id) => id === appId)
    setFilteredApps(_filteredApps)
  }

  function handleFilterButtonClick() {
    if (filteredApps.length >= 5) {
      alert('フィルターは5個以上追加できません')
      return
    }
    setIsFilterShown(true)
  }

  function handleTabClick(categoryId: Category) {
    setCategory(categoryId)
  }

  function renderFilter() {
    return (
      <>
        <Helmet>
          <title>レシピ</title>
        </Helmet>
        <FilterContainer>
          <FilterButton tabIndex={0} onClick={handleFilterButtonClick}>
            <Filter size={16} color={vars.fontColor.secondary} />
            <FilterButtonLabel>フィルター</FilterButtonLabel>
          </FilterButton>
          <FilterList>
            {filteredApps.map((appId) => {
              const app = definitions.getApp(appId)
              return (
                <FilterItem
                  key={app.appId}
                  onClick={() => removeAppFromFilters(app.appId)}
                >
                  <App icon={app.iconPath} color={app.color} size="xxs" />
                  <FilterAppName>{app.name}</FilterAppName>
                  <X size={16} color={vars.fontColor.secondary} />
                </FilterItem>
              )
            })}
          </FilterList>
        </FilterContainer>
      </>
    )
  }

  const renderContent = React.useCallback(
    (recipeList: Recipe[]) => {
      const filteredRecipes = recipeList.filter((recipe) => {
        // タスクが存在しないソースを持つレシピは排除
        if (recipe.source.getRootTask() === undefined) {
          return false
        }
        // カテゴリで絞り込んでいる場合、対象カテゴリを持っていなければ除外
        if (category !== Category.all) {
          if (!recipe.belong(category)) {
            return false
          }
        }
        // アプリでフィルターされている場合、ソースにアプリが含まれているか確認
        if (filteredApps.length > 0) {
          const appIds: Set<string> = new Set()
          const triggerId = recipe.getTriggerId()
          if (triggerId !== undefined) {
            appIds.add(definitions.getAppByTriggerId(triggerId).appId)
          }
          recipe.getAllActionIds().forEach((actionId) => {
            appIds.add(definitions.getAppByActionId(actionId).appId)
          })
          return filteredApps.every((appId) => appIds.has(appId))
        }
        return true
      })
      return <Content recipes={filteredRecipes} />
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [definitions, category, filteredApps, fetchStatus, errorMessage]
  )

  const renderTabContent = React.useCallback(() => {
    return (
      <>
        {fetchStatus === FetchStatus.loading ? (
          <Centering>
            <Loader />
          </Centering>
        ) : fetchStatus === FetchStatus.failed ||
          (fetchStatus === FetchStatus.loaded &&
            recipes &&
            recipes.length === 0) ? (
          <Centering>
            <NoResults
              heading="レシピが見つかりません"
              description={errorMessage ?? ''}
            />
          </Centering>
        ) : fetchStatus === FetchStatus.loaded &&
          recipes &&
          recipes.length > 0 ? (
          renderContent(recipes)
        ) : null}
      </>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, filteredApps, recipes, fetchStatus, errorMessage])

  return (
    <_Inner>
      <Heading>レシピ (β)</Heading>
      <FilterByApp
        isShown={isFilterShown}
        onSelect={(appId) => addFilteredApp(appId)}
        onClose={() => setIsFilterShown(false)}
      />
      <_Tabs>
        <Tab id="all" label="すべて" isDefault={true} onClick={handleTabClick}>
          {renderFilter()}
          {renderTabContent()}
        </Tab>
        <Tab id="sales" label="セールス" onClick={handleTabClick}>
          {renderFilter()}
          {renderTabContent()}
        </Tab>
        <Tab
          id="hr"
          label="人事 (Coming Soon)"
          disabled={true}
          renderTab={() => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span style={{ lineHeight: 1 }}>人事</span>
              <ComingSoon>Coming Soon</ComingSoon>
            </div>
          )}
        />
        <Tab
          id="accounting"
          label="経理 (Coming Soon)"
          disabled={true}
          renderTab={() => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span style={{ lineHeight: 1 }}>経理</span>
              <ComingSoon>Coming Soon</ComingSoon>
            </div>
          )}
        />
        <Tab
          id="cs"
          label="CS (Coming Soon)"
          disabled={true}
          renderTab={() => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span style={{ lineHeight: 1 }}>CS</span>
              <ComingSoon>Coming Soon</ComingSoon>
            </div>
          )}
        />
      </_Tabs>
    </_Inner>
  )
}

// molecules/Tabs に children として渡すコンポーネント。
// molecules/Tabs が要求する props の型を定義する。
// 親である molecules/Tabs は children を直接参照して props を使用しているのだ。
// このコンポーネント自体は何もしない、ただの容れ物。
const Tab: React.FC<{
  id: string
  label: string
  isDefault?: boolean
  disabled?: boolean
  onClick?: (key: string) => void
  renderTab?: () => React.ReactNode
}> = () => {
  return <div />
}

const Centering = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
})

const _Inner = styled(Inner)({
  padding: vars.space.l,
  margin: 'unset',
  width: '100%',
})

const _Tabs = styled(Tabs)({
  marginTop: vars.space.m,
})

const Heading = styled('h2')({
  fontSize: vars.fontSize.xxl,
})

const FilterContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginTop: vars.space.m,
})

const FilterButton = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  paddingTop: vars.space.xs,
  paddingRight: vars.space.s,
  paddingBottom: vars.space.xs,
  paddingLeft: vars.space.s,
  cursor: 'pointer',
})

const FilterButtonLabel = styled('span')({
  marginLeft: vars.space.s,
  color: vars.fontColor.secondary,
  fontSize: vars.fontSize.s,
  lineHeight: 1,
})

const FilterList = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginLeft: vars.space.s,
})

const FilterItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginLeft: vars.space.s,
  paddingTop: vars.space.xs,
  paddingRight: vars.space.s,
  paddingBottom: vars.space.xs,
  paddingLeft: vars.space.s,
  border: `1px solid ${vars.color.border}`,
  borderRadius: vars.borderRadius.s,
  cursor: 'pointer',
  '&:first-of-type': {
    marginLeft: 0,
  },
})

const FilterAppName = styled('span')({
  marginLeft: vars.space.s,
  marginRight: vars.space.s,
  color: vars.fontColor.secondary,
  fontSize: vars.fontSize.s,
  lineHeight: 1,
})

const ComingSoon = styled('div')({
  marginLeft: vars.space.s,
  paddingTop: 1,
  paddingRight: 2,
  paddingBottom: 1,
  paddingLeft: 2,
  color: 'red',
  fontSize: 8,
  fontWeight: vars.fontWeight.bold,
  border: `1px solid red`,
  borderRadius: vars.borderRadius.s,
  textTransform: 'uppercase',
})

export default RecipeList
