import { AssertionError } from '~/common/utils'
import { mapJsonToGroupMeta } from '~/data/group/mapJsonToGroup'
import { MeJson } from '~/data/user/MeJson'
import { NoOrganizationError } from '~/data/user/NoOrganizationError'
import { mapJsonToOrganization } from '~/data/user/organization/mapJsonToOrganization'
import { Me } from '~/domain/user/Me'

export function mapJsonToMe(json: MeJson): Me {
  if (json.organization === null) {
    throw new NoOrganizationError()
  }
  if (json.currentGroup === null) {
    throw new AssertionError(
      'me must belong currentGroup if me has an organization '
    )
  }
  return new Me({
    email: json.email,
    id: json.slug,
    organization: mapJsonToOrganization(json.organization),
    username: json.username,
    isAdmin: json.isAdmin,
    belongingGroups: json.belongingGroups.map(mapJsonToGroupMeta),
    currentGroupId: json.currentGroup.slug,
  })
}
