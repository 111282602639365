import styled from '@emotion/styled'
import React from 'react'

import { FetchStatus } from '~/common/types'
import { assertNever } from '~/common/utils'
import Loader from '~/components/atoms/Loader'
import { ExpectedObjectApiImpl } from '~/data/workflow/expectedObject/ExpectedObjectApiImpl'
import { RecipeWizard } from '~/domain/recipe/RecipeWizard'
import { ExpectedObjects } from '~/domain/workflow/expectedObject/ExpectedObjects'
import {
  ExpectedObjectsContext,
  ExpectedObjectsContextInterface,
} from '~/presentation/workflow/detail/editor/form/expectedObject/ExpectedObjectsContext'

interface Props {
  wizard: RecipeWizard
  stepNumber: number
  renderLoader?: () => React.ReactElement
  renderError?: (errorMessage?: string) => React.ReactElement
}

const expectedObjectApi = new ExpectedObjectApiImpl()

const RecipeWizardExpectedObjectProvider: React.FC<Props> = (props) => {
  const [expectedObjects, setExpectedObjects] = React.useState<
    ExpectedObjects
  >()
  const [fetchStatus, setFetchStatus] = React.useState<FetchStatus>(
    FetchStatus.none
  )

  const step = React.useMemo(() => props.wizard.getStep(props.stepNumber), [
    props.wizard,
    props.stepNumber,
  ])

  const sourceBody = React.useMemo(
    () => props.wizard.createWorkflowSourceBody(),
    [props.wizard]
  )

  React.useEffect(() => {
    const targetTaskId =
      step.targetNode.kind === 'task' ? step.targetNode.taskId : undefined
    const parentTaskId =
      targetTaskId === undefined
        ? undefined
        : sourceBody.findParentTaskOf(targetTaskId)?.taskId ?? 'trigger'
    setFetchStatus(FetchStatus.loading)
    expectedObjectApi
      .get(sourceBody, parentTaskId)
      .then((it) => {
        setExpectedObjects(it)
        setFetchStatus(FetchStatus.loaded)
      })
      .catch((reason) => {
        console.error(reason)
        setExpectedObjects(undefined)
        setFetchStatus(FetchStatus.failed)
      })
  }, [sourceBody, step])

  const expectedObjectContextValue: ExpectedObjectsContextInterface = React.useMemo(
    () => ({ expectedObjects }),
    [expectedObjects]
  )

  const renderChildren = () => {
    if (expectedObjects === undefined) {
      console.warn(
        'The fetch status is loaded but expectedObjects is undefined.'
      )
      return null
    }
    return (
      <ExpectedObjectsContext.Provider value={expectedObjectContextValue}>
        {props.children}
      </ExpectedObjectsContext.Provider>
    )
  }

  switch (fetchStatus) {
    case FetchStatus.none:
    case FetchStatus.loading:
      return props.renderLoader !== undefined ? (
        props.renderLoader()
      ) : (
        <Container>
          <Loader />
        </Container>
      )
    case FetchStatus.failed:
      return props.renderError !== undefined ? (
        props.renderError('変数の取得に失敗しました')
      ) : (
        <Container>
          <p>変数の取得に失敗しました</p>
        </Container>
      )
    case FetchStatus.loaded:
      return renderChildren()
    default:
      assertNever(fetchStatus)
  }
}

export default RecipeWizardExpectedObjectProvider

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
