import { TokenBasedProviderDefinition } from '~/domain/provider/tokenBased/TokenBasedProviderDefinition'

export const tokenBasedProviderDefinitions: TokenBasedProviderDefinition[] = [
  {
    providerId: 'cloudsign',
    token: {
      label: 'クライアント ID',
      note:
        '[こちら](https://www.cloudsign.jp/api/clients)のページよりクライアント ID を発行してください',
    },
    extraDataFields: [],
  },
  {
    providerId: 'camcard',
    token: {
      label: 'app secret',
      note:
        'CAMCARDサービス提供元に「app secret」及び「base url」を発行依頼し、取得して下さい。',
    },
    extraDataFields: [
      {
        key: 'base_url',
        label: 'base url',
        note: 'base url を入力して下さい。',
        required: true,
        form: {
          formType: 'text',
        },
      },
    ],
  },
  {
    providerId: 'smarthr_access_token',
    token: {
      label: 'アクセストークン',
      note:
        '[こちら](https://knowledge.smarthr.jp/hc/ja/articles/360026266033-アクセストークンの発行方法)の手順に従いアクセストークンを発行してください',
    },
    extraDataFields: [
      {
        key: 'tennantId',
        label: 'テナント ID',
        note:
          'ログイン後画面の URL のサブドメイン部分を入力してください。例: https://foo.smarthr.jp の foo を入力',
        required: true,
        form: {
          formType: 'text',
        },
      },
    ],
  },
  {
    providerId: 'kintone_api_token',
    token: {
      label: 'API トークン',
      note:
        '[こちら](https://developer.cybozu.io/hc/ja/articles/202463840-APIトークンを使ってみよう)の手順に従い API トークンを発行してください',
    },
    extraDataFields: [
      {
        key: 'subDomain',
        label: 'サブドメイン',
        note:
          'kintone ポータル画面の URL のサブドメイン部分を入力してください。例: https://foo.cybozu.com の foo を入力',
        required: true,
        form: {
          formType: 'text',
        },
      },
      {
        key: 'appId',
        label: 'アプリ ID',
        note:
          'kintone ポータル画面の上部にある歯車アイコンから｢アプリ管理｣を開くとアプリ ID が見られます。',
        required: true,
        form: {
          formType: 'text',
        },
      },
    ],
  },
  {
    providerId: 'sansan_api_key',
    token: {
      label: 'API キー',
      note:
        '[こちら](https://docs.ap.sansan.com/ja/api/openapi/index.html#header-api-key)の手順に従い API キーを発行してください',
    },
    extraDataFields: [],
  },
  {
    providerId: 'senses_api_key',
    token: {
      label: 'API KEY',
      note:
        '[こちら](https://intercom.help/senses_manual/ja/articles/2979535-growthプラン-sensesapiを利用する)の手順に従いAPI KEYを発行してください',
    },
    extraDataFields: [],
  },
  {
    providerId: 'zoom_jwt_token',
    token: {
      label: 'JWT トークン',
      note:
        'Zoom マーケットプレイスで作成したアプリの JWT トークンを入力してください。',
    },
    extraDataFields: [
      {
        key: 'apiKey',
        label: 'API Key',
        note:
          'Zoom マーケットプレイスで作成したアプリの API Key を入力してください。',
        required: true,
        form: {
          formType: 'text',
        },
      },
      {
        key: 'apiSecret',
        label: 'API Secret',
        note:
          'Zoom マーケットプレイスで作成したアプリの API Secret を入力してください。',
        required: true,
        form: {
          formType: 'text',
        },
      },
    ],
  },
]
