import * as utils from '~/common/utils'

export enum Env {
  local = 'local',
  dev = 'dev',
  stg = 'stg',
  prd = 'prd',
}

// STG 環境を作るにあたり、NODE_ENV が都合が悪かったので TARGET_ENV を使用
// 理由:
// React はビルド時、NODE_ENV=production かどうかによって最適化を入れるかどうか決定しているが、
// NODE_ENV=staging にすると最適化が行われず、｢準本番環境｣としての意味が薄れてしまうため
// そこで TARGET_ENV を導入することで、ビルドは本番、API の向き先は STG を行う
export const targetEnv: Env = (() => {
  switch (process.env.TARGET_ENV) {
    case 'local':
      return Env.local
    case 'development':
      return Env.dev
    case 'staging':
      return Env.stg
    case 'production':
      return Env.prd
    default:
      throw new Error('存在しない TARGET_ENV が指定されています')
  }
})()

interface Config {
  gaId: string
  serverRoot: string
  apiRoot: string
  recipeApiRoot: string
  sentryDsn: string
  firebase: {
    apiKey: string
    authDomain: string
  }
}

const config: Config = (() => {
  switch (targetEnv) {
    case Env.local:
      return new (class implements Config {
        gaId = 'UA-127742440-1'
        serverRoot = 'http://localhost:8000'
        apiRoot = 'http://localhost:8000/api'
        recipeApiRoot = 'https://stg-recipes.anyflow.jp'
        get sentryDsn(): string {
          throw new Error('開発環境では Sentry は使用できません')
        }

        firebase = {
          apiKey: 'AIzaSyA4kQ97i_SLY0eZgwfx4MU3CQarTgNQ3f8',
          authDomain: 'local-anyflow.firebaseapp.com',
        }
      })()
    case Env.dev:
      return {
        gaId: 'UA-127742440-1',
        serverRoot: 'https://dev-api.anyflow.jp',
        apiRoot: 'https://dev-api.anyflow.jp/api',
        recipeApiRoot: 'https://stg-recipes.anyflow.jp', // dev環境だが、stgのレシピを使用する
        sentryDsn: 'https://8bd45797e68b488bb7faa9957e03e45d@sentry.io/5509320',
        firebase: {
          apiKey: 'AIzaSyAYZf94WhbVIUGNOXU88ltdn6Uv7WiBMv4',
          authDomain: 'dev-anyflow.firebaseapp.com',
        },
      }
    case Env.stg:
      return {
        gaId: 'UA-127742440-1',
        serverRoot: 'https://stg-api.anyflow.jp',
        apiRoot: 'https://stg-api.anyflow.jp/api',
        recipeApiRoot: 'https://stg-recipes.anyflow.jp',
        sentryDsn: 'https://93f6ac7b9ddb47038f150e475975f523@sentry.io/1889595',
        firebase: {
          apiKey: 'AIzaSyDSzoC3Dv9AKipavtACAO5f57aX416r3SE',
          authDomain: 'stg-anyflow.firebaseapp.com',
        },
      }
    case Env.prd:
      return {
        gaId: 'UA-127742440-2',
        serverRoot: 'https://api.anyflow.jp',
        apiRoot: 'https://api.anyflow.jp/api',
        recipeApiRoot: 'https://recipes.anyflow.jp',
        sentryDsn: 'https://a10366ec8c9e4f919bae2abeaa3e816e@sentry.io/1475146',
        firebase: {
          apiKey: 'AIzaSyC4-WCrVicD8ZtzHmLWHz1hpqq6kUJb-tc',
          authDomain: 'anyflow.firebaseapp.com',
        },
      }
    default:
      return utils.assertNever(targetEnv)
  }
})()

export default config
