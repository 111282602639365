import styled from '@emotion/styled'
import produce from 'immer'
import * as _ from 'lodash'
import * as React from 'react'
import { AlertTriangle, XCircle } from 'react-feather'
import Helmet from 'react-helmet'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { formatRoute } from 'react-router-named-routes'

import { StandardTrigger } from '~/common/constants'
import * as defaultInput from '~/common/defaultInput'
import { FetchStatus, SubmitStatus } from '~/common/types'
import * as utils from '~/common/utils'
import Button from '~/components/atoms/Button'
import Link from '~/components/atoms/Link'
import Switch from '~/components/atoms/Switch'
import Text from '~/components/atoms/Text'
import Triangle from '~/components/atoms/Triangle'
import AppComp from '~/components/molecules/App'
import ExecButton from '~/components/molecules/ExecButton'
import Pager from '~/components/molecules/Pager'
import Inner from '~/components/organisms/Inner'
import {
  Body,
  Cell,
  Header,
  HeaderCell,
  Row,
  Table,
} from '~/components/organisms/Table'
import Tooltip from '~/components/utils/Tooltip'
import { Workflow } from '~/domain/workflow/Workflow'
import { AppDefinition } from '~/domain/workflow/app/AppDefinition'
import { WorkflowSourceBody } from '~/domain/workflow/source/WorkflowSourceBody'
import * as tipDuck from '~/ducks/ui/tip'
import { useUpdateWorkflow } from '~/hooks/useUpdateWorkflow'
import { useDefinitions, useMe } from '~/presentation/AnyflowAppContext'
import { useToaster } from '~/presentation/ToasterContext'
import { usePageQuery } from '~/presentation/utilityHooks'
import ContentLoader from '~/presentation/workflow/Loader'
import RenameModal from '~/presentation/workflow/RenameModal'
import TriggerSelectModal from '~/presentation/workflow/TriggerSelectModal'
import { WorkflowListMoreActions } from '~/presentation/workflow/WorkflowListMoreActions'
import { useCreateWorkflow } from '~/presentation/workflow/useCreateWorkflow'
import { useDeleteWorkflow } from '~/presentation/workflow/useDeleteWorkflow'
import { useDuplicateWorkflow } from '~/presentation/workflow/useDuplicateWorkflow'
import { useExecuteWorkflow } from '~/presentation/workflow/useExecuteWorkflow'
import { useWorkflows } from '~/presentation/workflow/useWorkflows'
import * as routes from '~/routes'
import * as vars from '~/styles/variables'

const WorkflowList: React.FC = () => {
  const dispatch = useDispatch()
  const definitions = useDefinitions()
  const history = useHistory()
  const page = usePageQuery()
  const me = useMe()
  const toaster = useToaster()
  const { workflows, paginationMeta, fetchStatus } = useWorkflows(page)
  const { updateWorkflow } = useUpdateWorkflow()
  const { createWorkflow } = useCreateWorkflow()
  const { executeWorkflow } = useExecuteWorkflow()
  const { deleteWorkflow, status: deleteWorkflowStatus } = useDeleteWorkflow()
  const {
    duplicateWorkflow,
    status: duplicateWorkflowStatus,
  } = useDuplicateWorkflow()

  const [enableStatuses, setEnableStatuses] = React.useState<{
    [workflowId: string]: SubmitStatus
  }>({})
  const [
    isTriggerSelectModalOpened,
    setIsTriggerSelectModalOpened,
  ] = React.useState(false)

  const [renameTarget, setRenameTarget] = React.useState<Workflow>()
  const [isRenameError, setIsRenameError] = React.useState(false)
  const renameModal = React.useMemo(
    () => ({
      isOpened: renameTarget !== undefined,
      open: (target: Workflow) => {
        setRenameTarget(target)
      },
      close: () => {
        setRenameTarget(undefined)
        setIsRenameError(false)
      },
    }),
    [renameTarget]
  )

  // 表示用の Workflow リスト
  const [presentationWorkflows, setPresentationWorkflows] = React.useState<
    Workflow[]
  >()

  // unmount 時に tip を非表示にする
  React.useEffect(() => {
    return () => {
      dispatch(tipDuck.actions.hide())
    }
  }, [dispatch])

  // workflows が変更された時に presentationWorkflows も変更する
  React.useEffect(() => {
    setPresentationWorkflows(workflows)
  }, [workflows])

  function handleExec(workflowId: string, name: string) {
    if (window.confirm(`「${name}」を実行してもよろしいですか？`)) {
      executeWorkflow(workflowId)
        .then((workflowInstanceId) => {
          history.push(
            formatRoute(routes.HISTORY_DETAIL, {
              workflowId,
              workflowInstanceId,
            })
          )
        })
        .catch((reason) => {
          console.error(reason)
          toaster.showError('エラーが発生しました')
        })
    }
  }

  const handleEnabledChange = React.useCallback(
    (workflowId: string, isEnabled: boolean) => {
      if (!presentationWorkflows) {
        return
      }
      setEnableStatuses((status) => {
        return produce(status, (it) => {
          it[workflowId] = SubmitStatus.submitting
        })
      })
      updateWorkflow(workflowId, { isEnabled })
        .then((newWorkflow) => {
          setEnableStatuses((status) => {
            return produce(status, (it) => {
              it[workflowId] = SubmitStatus.submitted
            })
          })
          const index = _.findIndex(
            presentationWorkflows,
            (it) => it.workflowId === newWorkflow.workflowId
          )
          if (index === -1) {
            return
          }
          // 複数個の WF を同時に更新したときに状態がバグることがあったので
          // 関数で set するようにした
          setPresentationWorkflows((_presentationWorkflows) => {
            const _workflows = _presentationWorkflows?.slice() ?? []
            _workflows[index] = newWorkflow
            return _workflows
          })
        })
        .catch((reason) => {
          console.error(reason)
          setEnableStatuses((status) => {
            return produce(status, (it) => {
              it[workflowId] = SubmitStatus.failed
            })
          })
          toaster.showError(
            isEnabled
              ? 'ワークフローの有効化に失敗しました'
              : 'ワークフローの無効化に失敗しました'
          )
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, presentationWorkflows, enableStatuses]
  )

  const handleTriggerSelect = React.useCallback(
    (triggerId: string) => {
      const defaultTriggerInputs = defaultInput.getDefaultTriggerInputs(
        triggerId,
        me.id
      )
      const triggerSource = {
        triggerId,
        inputs: defaultTriggerInputs,
      }
      createWorkflow(new WorkflowSourceBody(triggerSource))
        .then((workflow) => {
          history.push(
            formatRoute(routes.WORKFLOW_EDIT, {
              workflowId: workflow.workflowId,
            })
          )
        })
        .catch((reason) => {
          console.error(reason)
          toaster.showError('ワークフローの作成に失敗しました')
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, history, me]
  )

  const handleRenameSubmit = React.useCallback(
    (name: string) => {
      if (renameTarget === undefined || presentationWorkflows === undefined) {
        return
      }
      if (renameTarget.name === name) {
        renameModal.close()
        return
      }
      updateWorkflow(renameTarget.workflowId, { name })
        .then((updatedWorkflow) => {
          const index = _.findIndex(
            presentationWorkflows,
            (it) => it.workflowId === updatedWorkflow.workflowId
          )
          if (index === -1) {
            return
          }
          const _workflows = presentationWorkflows.slice()
          _workflows[index] = updatedWorkflow
          setPresentationWorkflows(_workflows)
          renameModal.close()
          toaster.showSuccess('ワークフロー名を変更しました')
        })
        .catch((reason) => {
          console.error(reason)
          setIsRenameError(true)
          toaster.showError('更新に失敗しました')
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, renameTarget, presentationWorkflows, renameModal]
  )

  return (
    <>
      <Helmet>
        <title>ワークフロー</title>
      </Helmet>
      <_Inner>
        <HeadingContainer>
          <div>
            <Heading>ワークフロー</Heading>
          </div>
          <div>
            <Button onClick={() => setIsTriggerSelectModalOpened(true)}>
              新規作成
            </Button>
            <_RightButton to={routes.RECIPE_LIST}>レシピから作成</_RightButton>
          </div>
        </HeadingContainer>
        {fetchStatus === FetchStatus.loading ? (
          <div style={{ marginTop: 40 }}>
            <ContentLoader />
          </div>
        ) : fetchStatus === FetchStatus.loaded &&
          presentationWorkflows &&
          presentationWorkflows.length > 0 ? (
          <>
            <_Table>
              <Header>
                <Row>
                  <_HeaderCell width="90">実行</_HeaderCell>
                  <_HeaderCell>ワークフロー名</_HeaderCell>
                  <_HeaderCell width="210">更新日時</_HeaderCell>
                  <_HeaderCell width="180">アプリ</_HeaderCell>
                  <_HeaderCell
                    style={{
                      width: 50,
                    }}
                  >
                    {''}
                  </_HeaderCell>
                </Row>
              </Header>
              <Body>
                {presentationWorkflows.map((workflow) => {
                  const source = workflow.source
                  const trigger = source.body.getTrigger()
                  const triggerApp: AppDefinition | undefined = trigger
                    ? utils.run(() => {
                        const appId = definitions.getTrigger(trigger.triggerId)
                          .appId
                        return definitions.getApp(appId)
                      })
                    : undefined
                  const rootTask = source.body.getRootTask()
                  const apps: AppDefinition[] = utils.run(() => {
                    if (!rootTask) {
                      return []
                    }
                    const flattenedTasks = utils.flattenTaskSource(rootTask)
                    const actionIds = flattenedTasks.map(
                      (task) => task.actionId
                    )
                    // 空オペレータのとき、idがundefinedで配列に入ってくるので除く
                    // [undefined] になり、length は1になってしまうのでこうしている
                    _.remove(actionIds, (actionId) => !actionId)
                    const appIds = actionIds.map(
                      (actionId) => definitions.getAction(actionId).appId
                    )
                    return appIds.map((appId) => definitions.getApp(appId))
                  })

                  const hasDeprecatedAction = source.hasDeprecatedAction(
                    definitions
                  )
                  const hasDeletedAction = source.hasDeletedAction(definitions)

                  return (
                    <Row
                      key={workflow.workflowId}
                      style={{
                        opacity:
                          deleteWorkflowStatus[workflow.workflowId] ===
                            SubmitStatus.submitting ||
                          duplicateWorkflowStatus[workflow.workflowId] ===
                            SubmitStatus.submitting
                            ? 0.5
                            : 1,
                      }}
                    >
                      <_Cell>
                        {trigger && trigger.triggerId === StandardTrigger ? (
                          <Tooltip
                            style={{
                              verticalAlign: 'middle',
                            }}
                            renderContent={() =>
                              trigger?.triggerId
                                ? definitions.triggersById[trigger.triggerId]
                                    .description
                                : ''
                            }
                          >
                            <ExecButton
                              disabled={
                                hasDeletedAction ||
                                !workflow.permissionPolicy.executable
                              }
                              onClick={() =>
                                handleExec(workflow.workflowId, workflow.name)
                              }
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip
                            style={{
                              verticalAlign: 'middle',
                            }}
                            renderContent={() =>
                              trigger?.triggerId
                                ? definitions.triggersById[trigger.triggerId]
                                    .description
                                : ''
                            }
                          >
                            <Switch
                              value={workflow.isEnabled}
                              disabled={
                                (!workflow.isEnabled && hasDeletedAction) ||
                                !workflow.permissionPolicy.updatable
                              }
                              loading={
                                enableStatuses[workflow.workflowId] ===
                                SubmitStatus.submitting
                              }
                              onChange={(value) => {
                                handleEnabledChange(workflow.workflowId, value)
                              }}
                            />
                          </Tooltip>
                        )}
                      </_Cell>
                      <_Cell>
                        <NameContainer>
                          {hasDeprecatedAction && !hasDeletedAction && (
                            <Tooltip
                              renderContent={() =>
                                '非推奨のアクションが使用されています'
                              }
                              style={{ display: 'inline-flex' }}
                            >
                              <AlertTriangle
                                size={16}
                                color={vars.color.warning}
                                style={{ marginRight: vars.space.s }}
                              />
                            </Tooltip>
                          )}
                          {hasDeletedAction && (
                            <Tooltip
                              renderContent={() =>
                                '無効なアクションが使用されています'
                              }
                              style={{ display: 'inline-flex' }}
                            >
                              <XCircle
                                size={16}
                                color={vars.color.error}
                                style={{ marginRight: vars.space.s }}
                              />
                            </Tooltip>
                          )}
                          <Name
                            to={routes.WORKFLOW_EDIT}
                            params={{ workflowId: workflow.workflowId }}
                            title={workflow.name}
                          >
                            {workflow.name}
                          </Name>
                        </NameContainer>
                        {workflow.description !== '' ? (
                          <Text
                            element="p"
                            fontSize="s"
                            color="secondary"
                            lineHeight="heading"
                            truncated={true}
                            style={{ marginTop: vars.space.xs }}
                          >
                            {workflow.description}
                          </Text>
                        ) : null}
                      </_Cell>
                      <_Cell>
                        <Text
                          element="p"
                          fontSize="s"
                          color="tertiary"
                          lineHeight="heading"
                        >
                          {utils.formatDate(workflow.updatedAt)}
                        </Text>
                      </_Cell>
                      <_Cell>
                        <AppList>
                          {triggerApp ? (
                            <_AppComp
                              key={'trigger' + triggerApp.appId}
                              color={triggerApp.color}
                              icon={triggerApp.iconPath}
                              size="s"
                              onMouseEnter={(e) => {
                                if (!triggerApp) {
                                  return
                                }
                                const rect = e.currentTarget.getBoundingClientRect()
                                const { top, left } = rect
                                dispatch(
                                  tipDuck.actions.show({
                                    top: top + 35,
                                    left,
                                    message: triggerApp.name,
                                  })
                                )
                              }}
                              onMouseLeave={() =>
                                dispatch(tipDuck.actions.hide())
                              }
                            />
                          ) : null}
                          <div
                            style={{
                              marginLeft: vars.space.xs,
                              marginRight: vars.space.xs,
                            }}
                          >
                            <Triangle fill={vars.color.gray} size={10} />
                          </div>
                          {apps.slice(0, 3).map((app, i) => (
                            <_AppComp
                              key={`${app.appId}${i}`}
                              color={app.color}
                              icon={app.iconPath}
                              size="s"
                              onMouseEnter={(e) => {
                                const rect = e.currentTarget.getBoundingClientRect()
                                const { top, left } = rect
                                dispatch(
                                  tipDuck.actions.show({
                                    top: top + 35,
                                    left,
                                    message: app.name,
                                  })
                                )
                              }}
                              onMouseLeave={() =>
                                dispatch(tipDuck.actions.hide())
                              }
                            />
                          ))}
                          <AppRestCount>
                            {apps.length > 3 ? (
                              <div>+{apps.length - 3}</div>
                            ) : null}
                          </AppRestCount>
                        </AppList>
                      </_Cell>
                      <Cell>
                        <WorkflowListMoreActions
                          deletable={workflow.permissionPolicy.deletable}
                          duplicatable={workflow.permissionPolicy.duplicatable}
                          renameable={workflow.permissionPolicy.updatable}
                          onDeleteClick={() => {
                            if (window.confirm(`本当に削除しますか？`)) {
                              deleteWorkflow(workflow.workflowId)
                                .then(() => {
                                  // UI 更新
                                  setPresentationWorkflows((current) => {
                                    return current?.filter(
                                      (it) =>
                                        it.workflowId !== workflow.workflowId
                                    )
                                  })
                                  toaster.showSuccess('削除しました')
                                })
                                .catch((reason) => {
                                  console.error(reason)
                                  toaster.showError('削除に失敗しました')
                                })
                            }
                          }}
                          onDuplicateClick={() => {
                            duplicateWorkflow(workflow)
                              .then((duplicatedWorkflow) => {
                                // UI 更新
                                setPresentationWorkflows((current) => {
                                  if (!current) {
                                    return [duplicatedWorkflow]
                                  } else {
                                    return [duplicatedWorkflow, ...current]
                                  }
                                })
                                toaster.showSuccess('複製しました')
                                history.push(
                                  `/workflows/${duplicatedWorkflow.workflowId}/edit`
                                )
                              })
                              .catch((reason) => {
                                console.error(reason)
                                toaster.showError('複製に失敗しました')
                              })
                          }}
                          onRenameClick={() => renameModal.open(workflow)}
                        />
                      </Cell>
                    </Row>
                  )
                })}
              </Body>
            </_Table>
            {paginationMeta ? (
              <_Pager
                to={routes.WORKFLOW_LIST}
                totalCount={paginationMeta.totalCount}
                currentPageNumber={paginationMeta.currentPageNumber}
                countPerPage={paginationMeta.countPerPage}
              />
            ) : null}
          </>
        ) : fetchStatus === FetchStatus.loaded &&
          presentationWorkflows &&
          presentationWorkflows.length === 0 ? (
          <NoResults>
            <NoResultsMessage>ワークフローがありません</NoResultsMessage>
          </NoResults>
        ) : null}
        <TriggerSelectModal
          isOpened={isTriggerSelectModalOpened}
          onSelect={handleTriggerSelect}
          onClose={() => setIsTriggerSelectModalOpened(false)}
        />
        <RenameModal
          isOpened={renameModal.isOpened}
          workflowName={renameTarget?.name}
          isError={isRenameError}
          onSubmit={handleRenameSubmit}
          onClose={renameModal.close}
        />
      </_Inner>
    </>
  )
}

const _Inner = styled(Inner)({
  padding: vars.space.l,
  margin: 'unset',
  width: '100%',
})

const HeadingContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const Heading = styled('h2')({
  fontSize: vars.fontSize.xxl,
})

const _Table = styled(Table)({
  marginTop: vars.space.l,
  tableLayout: 'fixed',
})

const _HeaderCell = styled(HeaderCell)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const _Cell = styled(Cell)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const _AppComp = styled(AppComp)({
  marginRight: vars.space.xxs,
  '&:last-of-type': {
    marginRight: 0,
  },
})

const AppRestCount = styled('div')({
  marginLeft: vars.space.xs,
  color: vars.fontColor.secondary,
})

const NameContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const Name = styled(Link)({
  color: vars.fontColor.primary,
  fontWeight: vars.fontWeight.bold,
  fontSize: vars.fontSize.m,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
})

const AppList = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const NoResults = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: vars.space.m,
})

const NoResultsMessage = styled('p')({
  color: vars.fontColor.secondary,
  fontSize: vars.fontSize.s,
  textAlign: 'center',
})

const _Pager = styled(Pager)({
  marginTop: vars.space.l,
  marginRight: vars.space.xl,
})

const _RightButton = styled(Button)({
  marginLeft: vars.space.s,
})

export default WorkflowList
