import { baseKeymap } from 'prosemirror-commands'
import { history, redo, undo } from 'prosemirror-history'
import { keymap } from 'prosemirror-keymap'
import { Node } from 'prosemirror-model'
import { EditorState, Selection, TextSelection } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import React, { ReactPortal } from 'react'

import * as utils from '~/common/utils'
import { RenderableEntity } from '~/domain/workflow/source/RenderableElement'
import { EntityNodeView } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/prosemirror/EntityNodeView'
import { ParagraphNodeView } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/prosemirror/ParagraphNodeView'
import {
  RootNode,
  schema,
} from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/prosemirror/schema'
import { EntityType } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/types'

type CursorDirection = 'Left' | 'Right'

export const ZERO_WIDTH_SPACE = '\u200b'

export class EditorViewWrapper {
  private editorView: EditorView

  constructor(
    editorViewDOM: HTMLElement,
    rootNode: RootNode,
    handleAddPortal: (portal: ReactPortal) => void,
    handleRootNodeChange: (rootNode: RootNode) => void,
    disabled = false
  ) {
    const doc = schema.nodeFromJSON(rootNode)
    const editorState = EditorState.create({
      doc,
      plugins: [
        history(),
        keymap({ 'Mod-z': undo, 'Mod-y': redo }),
        keymap(baseKeymap),
      ],
    })

    const editorView = new EditorView(editorViewDOM, {
      state: editorState,
      handleTextInput: this.handleEndParenthesis.bind(this),
      handleKeyDown: this.handleKeyDown.bind(this),
      handleClickOn: this.handleClickOnEntity.bind(this),
      editable: () => !disabled,
      nodeViews: {
        entity(node) {
          const entity = node.attrs['entity']
          const label = node.attrs['label']
          const nodeView = new EntityNodeView(entity, label)
          handleAddPortal(nodeView.portal)
          return nodeView
        },
        paragraph(node) {
          const nodeView = new ParagraphNodeView(node.isLeaf)
          handleAddPortal(nodeView.portal)
          return nodeView
        },
      },
      dispatchTransaction(transaction) {
        const newState = editorView.state.apply(transaction)
        handleRootNodeChange(newState.doc.toJSON() as RootNode)
        editorView.updateState(newState)
      },
    })
    this.editorView = editorView
  }

  public focus(): void {
    this.editorView.focus()
  }

  public moveCursorToEnd(): void {
    const selection = Selection.atEnd(this.editorView.state.doc)
    const tr = this.editorView.state.tr.setSelection(selection)
    this.editorView.dispatch(tr)
  }

  public insertEntity(label: string, entity: RenderableEntity): void {
    this._insertSpace()
    this._insertEntity(label, entity)
  }

  private _insertEntity(label: string, entity: RenderableEntity): void {
    const state = this.editorView.state
    const { $from } = state.selection
    const index = $from.index()
    const entityNodeType = schema.nodes.entity
    if (!$from.parent.canReplaceWith(index, index, entityNodeType)) {
      return
    }
    const entityNode = entityNodeType.create({ label, entity })
    this.editorView.dispatch(state.tr.replaceSelectionWith(entityNode))
  }

  private _insertSpace(): void {
    const state = this.editorView.state
    const { $from } = state.selection
    const index = $from.index()
    const textType = schema.nodes.text
    if (!$from.parent.canReplaceWith(index, index, textType)) {
      return
    }
    const textNode = schema.text(ZERO_WIDTH_SPACE)
    this.editorView.dispatch(state.tr.replaceSelectionWith(textNode))
  }

  /**
   * ProseMirror の handleKeyDown のIFに合わせている
   */
  private handleKeyDown(editorView: EditorView, event: KeyboardEvent): boolean {
    if (event.key === 'ArrowLeft') {
      return this.skipCursor('Left')
    }
    if (event.key === 'ArrowRight') {
      return this.skipCursor('Right')
    }
    if (event.key === 'Backspace') {
      return this.deleteVariableAndZeroWidthSpace('Left')
    }
    if (event.key === 'Delete') {
      return this.deleteVariableAndZeroWidthSpace('Right')
    }
    return false // not handle
  }

  /**
   * カーソルの次がゼロ幅文字なら2つ進む
   */
  private skipCursor(direction: CursorDirection): boolean {
    const selection = this.editorView.state.selection
    if (!selection.empty) {
      return false
    }
    const nextNode = this.findNextNode(selection, direction)
    if (!nextNode) {
      // current in edge
      return false
    }
    if (
      this.isZeroWidthSpaceNode(nextNode) ||
      nextNode.type.name === 'entity'
    ) {
      const nextPos = this.calcNextPosition(selection, direction)
      const nextSelection = TextSelection.near(
        this.editorView.state.doc.resolve(nextPos)
      )
      this.editorView.dispatch(
        this.editorView.state.tr.setSelection(nextSelection)
      )
      return true
    }
    return false
  }

  private calcNextPosition(
    selection: Selection,
    direction: CursorDirection
  ): number {
    if (direction === 'Right') {
      const max = this.editorView.state.doc.nodeSize
      return Math.min(max, selection.$from.pos + 2)
    }
    if (direction === 'Left') {
      return Math.max(0, selection.$from.pos - 2)
    }
    return utils.assertNever(direction)
  }

  private findNextNode(
    selection: Selection,
    direction: CursorDirection
  ): Node | undefined | null {
    const nextNode =
      direction === 'Right'
        ? selection.$anchor.nodeAfter
        : selection.$anchor.nodeBefore
    return nextNode
  }

  /**
   * 消す対象が (variable | ZERO_WIDTH_SPACE) のとき、隣の (variable | ZERO_WIDTH_SPACE) も一緒に消す
   */
  private deleteVariableAndZeroWidthSpace(direction: CursorDirection): boolean {
    const selection = this.editorView.state.selection
    if (!selection.empty) {
      return false
    }
    const nextNode = this.findNextNode(selection, direction)
    if (!nextNode) {
      // current in edge
      return false
    }
    const fromPos = selection.$from.pos
    if (this.isZeroWidthSpaceNode(nextNode)) {
      // text の右隣に variable があるのでそれも消す
      const transaction = this.editorView.state.tr.deleteRange(
        fromPos,
        fromPos + 2
      )
      this.editorView.dispatch(transaction)
      return true
    } else if (nextNode.type.name === 'entity') {
      // variable の左隣にゼロ幅文字があるのでそれも消す
      const transaction = this.editorView.state.tr.deleteRange(
        fromPos - 2,
        fromPos
      )
      this.editorView.dispatch(transaction)
      return true
    }
    return false
  }

  private isZeroWidthSpaceNode(node: Node): boolean {
    return node.isText && node.text === ZERO_WIDTH_SPACE
  }

  /**
   * ユーザ入力で閉じカッコがあった場合、コンポーネントとして表示させる
   * ProseMirrorのhandleTextInputのIFに合わせている
   */
  private handleEndParenthesis(
    editorView: EditorView,
    from: number,
    to: number,
    text: string
  ): boolean {
    if (text !== ')') {
      return false
    }

    // 現在位置の paragraph node を取得
    const { $anchor } = editorView.state.selection
    const paragraph = $anchor.parent
    if (paragraph.type.name !== 'paragraph') {
      return false
    }
    const counter = { start: 0, end: 0 }
    paragraph.forEach((node) => {
      if (node.type.name === 'entity') {
        const entity = node.attrs.entity
        if (
          entity.type === EntityType.FUNCTION_CALL_START ||
          entity.type === EntityType.METHOD_CALL_START
        ) {
          counter.start += 1
        } else if (entity.type === EntityType.CALL_END) {
          counter.end += 1
        }
      }
    })
    // 関数呼び出しの綴じカッコが不足していたら ')' の代わりに CALL_END を挿入
    if (counter.start > counter.end) {
      const entity: RenderableEntity = {
        type: EntityType.CALL_END,
      }
      this.insertEntity(')', entity)
      return true
    }

    return false
  }

  private handleClickOnEntity(
    _view: EditorView,
    pos: number,
    node: Node,
    _nodePos: number,
    _event: React.MouseEvent,
    direct: boolean
  ): boolean {
    if (node.type.name !== 'entity') {
      return direct
    }
    // エンティティがクリックされるとゼロ幅文字とエンティティの間にカーソルが入ってしまうので、ひとつ戻す
    const transaction = this.editorView.state.tr.setSelection(
      TextSelection.near(this.editorView.state.doc.resolve(pos - 1))
    )
    this.editorView.dispatch(transaction)
    return true
  }
}
