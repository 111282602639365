import {
  SalesforceOperandType,
  SalesforceSearchConditionOperator,
} from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchConditionOperator'

export interface SalesforcePicklistItem {
  label: string
  value: string
}

export type SalesforceDataType =
  | SalesforceDataType.String
  | SalesforceDataType.Number
  | SalesforceDataType.Boolean
  | SalesforceDataType.Date
  | SalesforceDataType.Picklist
  | SalesforceDataType.MultiPicklist
  | SalesforceDataType.Reference

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace SalesforceDataType {
  export class String {
    public readonly name = 'string'

    get operators(): SalesforceSearchConditionOperator[] {
      return [
        {
          id: 'is_empty',
          label: '空である',
          operandType: new SalesforceOperandType.None(),
        },
        {
          id: 'is_not_empty',
          label: '空でない',
          operandType: new SalesforceOperandType.None(),
        },
        {
          id: '==',
          label: '等しい',
          operandType: new SalesforceOperandType.Text(),
        },
        {
          id: '!=',
          label: '等しくない',
          operandType: new SalesforceOperandType.Text(),
        },
        {
          id: 'contains',
          label: '含む',
          operandType: new SalesforceOperandType.Text(),
        },
        {
          id: 'does_not_contain',
          label: '含まない',
          operandType: new SalesforceOperandType.Text(),
        },
        {
          id: 'starts_with',
          label: 'から始まる',
          operandType: new SalesforceOperandType.Text(),
        },
        {
          id: 'ends_with',
          label: 'で終わる',
          operandType: new SalesforceOperandType.Text(),
        },
        {
          id: 'has_changed',
          label: '変わったら',
          operandType: new SalesforceOperandType.None(),
          isStateOperator: true,
        },
      ]
    }
  }

  export class Number {
    public readonly name = 'number'

    get operators(): SalesforceSearchConditionOperator[] {
      return [
        {
          id: 'is_empty',
          label: '空である',
          operandType: new SalesforceOperandType.None(),
        },
        {
          id: 'is_not_empty',
          label: '空でない',
          operandType: new SalesforceOperandType.None(),
        },
        {
          id: '==',
          label: '等しい',
          operandType: new SalesforceOperandType.Number(),
        },
        {
          id: '!=',
          label: '等しくない',
          operandType: new SalesforceOperandType.Number(),
        },
        {
          id: '>',
          label: 'より大きい',
          operandType: new SalesforceOperandType.Number(),
        },
        {
          id: '<',
          label: 'より小さい',
          operandType: new SalesforceOperandType.Number(),
        },
        {
          id: '>=',
          label: '以上',
          operandType: new SalesforceOperandType.Number(),
        },
        {
          id: '<=',
          label: '以下',
          operandType: new SalesforceOperandType.Number(),
        },
        {
          id: 'has_changed',
          label: '変わったら',
          operandType: new SalesforceOperandType.None(),
          isStateOperator: true,
        },
        {
          id: 'has_increased',
          label: '増えたら',
          operandType: new SalesforceOperandType.None(),
          isStateOperator: true,
        },
        {
          id: 'has_decreased',
          label: '減ったら',
          operandType: new SalesforceOperandType.None(),
          isStateOperator: true,
        },
      ]
    }
  }

  export class Boolean {
    public readonly name = 'boolean'

    get operators(): SalesforceSearchConditionOperator[] {
      return [
        {
          id: 'is_empty',
          label: '空である',
          operandType: new SalesforceOperandType.None(),
        },
        {
          id: 'is_not_empty',
          label: '空でない',
          operandType: new SalesforceOperandType.None(),
        },
        {
          id: '==',
          label: '等しい',
          operandType: new SalesforceOperandType.Boolean(),
        },
        {
          id: '!=',
          label: '等しくない',
          operandType: new SalesforceOperandType.Boolean(),
        },
        {
          id: 'has_changed',
          label: '変わったら',
          operandType: new SalesforceOperandType.None(),
          isStateOperator: true,
        },
      ]
    }
  }

  export class Date {
    public readonly name = 'date'

    get operators(): SalesforceSearchConditionOperator[] {
      return [
        {
          id: 'is_empty',
          label: '空である',
          operandType: new SalesforceOperandType.None(),
        },
        {
          id: 'is_not_empty',
          label: '空でない',
          operandType: new SalesforceOperandType.None(),
        },
        {
          id: 'has_changed',
          label: '変わったら',
          operandType: new SalesforceOperandType.None(),
          isStateOperator: true,
        },
      ]
    }
  }

  export class Picklist {
    public readonly name = 'picklist'
    public readonly items: SalesforcePicklistItem[]

    constructor(items: SalesforcePicklistItem[]) {
      this.items = items
    }

    get operators(): SalesforceSearchConditionOperator[] {
      return [
        {
          id: 'is_empty',
          label: '空である',
          operandType: new SalesforceOperandType.None(),
        },
        {
          id: 'is_not_empty',
          label: '空でない',
          operandType: new SalesforceOperandType.None(),
        },
        {
          id: '==',
          label: '等しい',
          operandType: new SalesforceOperandType.Select(this.items),
        },
        {
          id: '!=',
          label: '等しくない',
          operandType: new SalesforceOperandType.Select(this.items),
        },
        {
          id: 'is_one_of',
          label: 'いずれかである',
          operandType: new SalesforceOperandType.MultiSelect(this.items),
        },
        {
          id: 'is_not_one_of',
          label: 'いずれでもない',
          operandType: new SalesforceOperandType.MultiSelect(this.items),
        },
        {
          id: 'has_changed',
          label: '変わったら',
          operandType: new SalesforceOperandType.None(),
          isStateOperator: true,
        },
      ]
    }
  }

  export class MultiPicklist {
    public readonly name = 'multi_picklist'
    public readonly items: SalesforcePicklistItem[]

    constructor(items: SalesforcePicklistItem[]) {
      this.items = items
    }

    get operators(): SalesforceSearchConditionOperator[] {
      return [
        {
          id: 'is_empty',
          label: '空である',
          operandType: new SalesforceOperandType.None(),
        },
        {
          id: 'is_not_empty',
          label: '空でない',
          operandType: new SalesforceOperandType.None(),
        },
        {
          id: '==',
          label: '等しい',
          operandType: new SalesforceOperandType.MultiSelect(this.items),
        },
        {
          id: '!=',
          label: '等しくない',
          operandType: new SalesforceOperandType.MultiSelect(this.items),
        },
        {
          id: 'is_one_of',
          label: 'いずれかである',
          operandType: new SalesforceOperandType.MultiSelect(this.items),
        },
        {
          id: 'is_not_one_of',
          label: 'いずれでもない',
          operandType: new SalesforceOperandType.MultiSelect(this.items),
        },
        {
          id: 'has_changed',
          label: '変わったら',
          operandType: new SalesforceOperandType.None(),
          isStateOperator: true,
        },
      ]
    }
  }

  export class Reference {
    public readonly name = 'reference'

    get operators(): SalesforceSearchConditionOperator[] {
      return [
        {
          id: 'is_empty',
          label: '空である',
          operandType: new SalesforceOperandType.None(),
        },
        {
          id: 'is_not_empty',
          label: '空でない',
          operandType: new SalesforceOperandType.None(),
        },
        {
          id: '==',
          label: '等しい',
          operandType: new SalesforceOperandType.Assist(),
        },
        {
          id: '!=',
          label: '等しくない',
          operandType: new SalesforceOperandType.Assist(),
        },
        {
          id: 'is_one_of',
          label: 'いずれかである',
          operandType: new SalesforceOperandType.MultiAssist(),
        },
        {
          id: 'is_not_one_of',
          label: 'いずれでもない',
          operandType: new SalesforceOperandType.MultiAssist(),
        },
        {
          id: 'has_changed',
          label: '変わったら',
          operandType: new SalesforceOperandType.None(),
          isStateOperator: true,
        },
      ]
    }
  }
}
