import { Tson } from '~/data/Tson'
import { mapToGroupMetaJson } from '~/data/group/mapToGroupJson'
import { MeJson } from '~/data/user/MeJson'
import { mapToOrganizationJson } from '~/data/user/organization/mapToOrganizationJson'

export function mapToMeJson(tson: Tson): MeJson {
  const organizationTson = tson.getValue('organization', false)
  const organization =
    organizationTson === null || organizationTson === undefined
      ? null
      : mapToOrganizationJson(organizationTson)
  const currentGroupTson = tson.getValue('currentGroup', false)
  const currentGroup =
    currentGroupTson === null || currentGroupTson === undefined
      ? null
      : mapToGroupMetaJson(currentGroupTson)
  return {
    email: tson.getString('email'),
    organization: organization,
    slug: tson.getString('slug'),
    username: tson.getString('username'),
    isAdmin: tson.getBoolean('isAdmin'),
    currentGroup: currentGroup,
    belongingGroups: tson.getArray('belongingGroups').map(mapToGroupMetaJson),
  }
}
