import * as React from 'react'

import { apiClients } from '~/common/apiClients'
import { GroupService } from '~/domain/group/GroupService'
import { GroupSummary } from '~/domain/group/GroupSummary'

const service: GroupService = apiClients.groupService

export function useCreateGroup(): {
  creating: boolean
  create: (groupName: string) => Promise<GroupSummary>
} {
  const [creating, setCreating] = React.useState<boolean>(false)
  const create = React.useCallback(async (groupName: string) => {
    setCreating(true)
    try {
      return await service.createGroup(groupName)
    } finally {
      setCreating(false)
    }
  }, [])
  return { creating, create }
}
