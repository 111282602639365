import styled from '@emotion/styled'
import * as React from 'react'

import { assertNever } from '~/common/utils'
import ErrorMessage from '~/components/atoms/ErrorMessage'
import NumberField from '~/components/atoms/NumberField'
import Text from '~/components/atoms/Text'
import SelectField from '~/components/molecules/SelectField'
import { CronEditorMode } from '~/presentation/workflow/detail/editor/form/inputWidget/cron/CronEditorMode'
import {
  useHours,
  useMinutes,
  useMode,
} from '~/presentation/workflow/detail/editor/form/inputWidget/cron/hooks'
import * as vars from '~/styles/variables'

interface Props {
  value: string | undefined
  hasError?: boolean
  disabled?: boolean
  onChange: (cronExpression: string | undefined) => void
}

const CronEditor: React.FC<Props> = (props) => {
  const [minuteErrorMessage, setMinuteErrorMessage] = React.useState<string>()
  const [hourErrorMessage, setHourErrorMessage] = React.useState<string>()

  const [mode, setMode] = useMode(props.value)
  const [hours, setHours] = useHours(props.value)
  const [minutes, setMinutes] = useMinutes(props.value)

  return (
    <Container>
      <SelectField<CronEditorMode>
        value={mode}
        hasError={props.hasError}
        disabled={props.disabled}
        unselectedOption={{
          label: 'スケジュールの指定方法を選択してください',
          value: CronEditorMode.unselected,
        }}
        options={[
          {
            label: '一定間隔で繰り返し (分)',
            value: CronEditorMode.repeatMinute,
          },
          {
            label: '一定間隔で繰り返し (時間)',
            value: CronEditorMode.repeatHour,
          },
        ]}
        onChange={(_mode) => {
          setMode(_mode)
          setHours(undefined)
          setMinutes(undefined)
          // 初期値で初期化
          switch (_mode) {
            case CronEditorMode.repeatHour:
              props.onChange('0 */1 * * *')
              return
            case CronEditorMode.repeatMinute:
              props.onChange('*/5 * * * *')
              return
            case CronEditorMode.unselected:
              props.onChange(undefined)
              return
            default:
              assertNever(_mode)
          }
        }}
      />
      {mode === CronEditorMode.repeatMinute ? (
        <div style={{ marginTop: vars.space.m }}>
          <Text
            element="p"
            lineHeight="heading"
            fontSize="s"
            style={{
              marginBottom: vars.space.s,
            }}
          >
            繰り返し間隔（分）
          </Text>
          <NumberField
            // hours の NumberField と区別させるために key を付与
            key="minutes"
            value={minutes}
            min={5}
            max={59}
            hasError={props.hasError}
            disabled={props.disabled}
            onBlur={() => {
              if (minutes === undefined) {
                props.onChange(undefined)
                return
              }
              if (minutes > 59 || minutes < 5) {
                setMinuteErrorMessage('5以上59未満の値を指定してください')
                return
              }
              props.onChange(`*/${minutes} * * * *`)
            }}
            onChange={(e) => {
              setMinuteErrorMessage(undefined)
              setMinutes(Number(e.currentTarget.value))
            }}
          />
          {minuteErrorMessage !== undefined && (
            <_ErrorMessage>{minuteErrorMessage}</_ErrorMessage>
          )}
        </div>
      ) : mode === CronEditorMode.repeatHour ? (
        <div style={{ marginTop: vars.space.m }}>
          <Text
            element="p"
            lineHeight="heading"
            fontSize="s"
            style={{
              marginBottom: vars.space.s,
            }}
          >
            繰り返し間隔（時間）
          </Text>
          <NumberField
            // minutes の NumberField と区別させるために key を付与
            key="hours"
            value={hours}
            min={1}
            max={23}
            hasError={props.hasError}
            disabled={props.disabled}
            onBlur={() => {
              if (hours === undefined) {
                props.onChange(undefined)
                return
              }
              if (hours > 23 || hours < 1) {
                setHourErrorMessage('1以上23未満の値を指定してください')
                return
              }
              props.onChange(`0 */${hours} * * *`)
            }}
            onChange={(e) => {
              setHourErrorMessage(undefined)
              setHours(Number(e.currentTarget.value))
            }}
          />
          {hourErrorMessage !== undefined && (
            <_ErrorMessage>{hourErrorMessage}</_ErrorMessage>
          )}
        </div>
      ) : null}
    </Container>
  )
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const _ErrorMessage = styled(ErrorMessage)({
  marginTop: vars.space.s,
})

export default CronEditor
