import * as React from 'react'

import { ensure } from '~/common/utils'
import { ExpectedObjects } from '~/domain/workflow/expectedObject/ExpectedObjects'

export interface ExpectedObjectsContextInterface {
  expectedObjects?: ExpectedObjects
}

export const ExpectedObjectsContext = React.createContext<
  ExpectedObjectsContextInterface
>({})

export function useExpectedObjectsContext(): Required<
  ExpectedObjectsContextInterface
> {
  const context = React.useContext<ExpectedObjectsContextInterface>(
    ExpectedObjectsContext
  )
  return {
    expectedObjects: ensure(
      context.expectedObjects,
      'expectedObjects is undefined. Out of context scope?'
    ),
  }
}
