import { Definitions } from '~/domain/Definitions'
import { ValueType } from '~/domain/ValueType'
import { ExpectedObjects } from '~/domain/workflow/expectedObject/ExpectedObjects'
import { InputValue } from '~/domain/workflow/source/InputValue'
import {
  RenderInputValueTypeMatcher,
  TypeMatchResult,
} from '~/domain/workflow/source/RenderInputValueTypeMatcher'
import { ValidationResult } from '~/domain/workflow/validator/ValidationResult'

export class RenderValueValidator {
  private readonly typeMatcher: RenderInputValueTypeMatcher

  constructor(
    valueType: ValueType,
    definitions: Definitions,
    expectedObjects: ExpectedObjects
  ) {
    this.typeMatcher = new RenderInputValueTypeMatcher(
      valueType,
      definitions,
      expectedObjects
    )
  }

  validate(input: InputValue.Render, useBulk: boolean): ValidationResult {
    const typeMatches = this.typeMatcher.getTypeMatches(input, useBulk)

    // 互換性のない型が一つでもあれば
    if (typeMatches.some((it) => it.kind === 'mismatch')) {
      console.warn(
        '使用できない変数が入力されています',
        typeMatches,
        input,
        useBulk
      )
      return ValidationResult.invalid(
        new ValidationResult.BadFormat('使用できない変数が入力されています')
      )
    }

    // バルク系チェック
    const bulkTypeMatches = typeMatches.filter<TypeMatchResult.BulkMatch>(
      (it): it is TypeMatchResult.BulkMatch => it.kind === 'bulk'
    )

    // バルク変数を使用していなければ、このウィジェットのバルクサイズは undefined
    if (bulkTypeMatches.length === 0) {
      return ValidationResult.valid(undefined)
    }

    // 一番初めに現れる、長さが undefined ではないバルク変数の長さを基準にする
    const firstBulkSize = bulkTypeMatches.find(
      (it) => it.bulkType.length !== undefined
    )?.bulkType.length

    // サイズを持つバルク変数が1つも存在しなければ、このウィジェットのバルクサイズは n
    if (firstBulkSize === undefined) {
      return ValidationResult.valid('n')
    }

    // 全て同じサイズであれば、このウィジェットのバルクサイズはそのサイズ
    if (bulkTypeMatches.every((it) => it.bulkType.length === firstBulkSize)) {
      return ValidationResult.valid(firstBulkSize)
    } else {
      // バルクサイズの異なるものがあればエラー
      return ValidationResult.invalid(
        new ValidationResult.BadFormat(
          '長さの異なるバルク変数が入力されています'
        )
      )
    }
  }
}
