import * as React from 'react'

import * as vars from '~/styles/variables'

const MEDIA_QUERY_TABLET = vars.media.tablet.replace(/^@media /, '')

export function useIsTablet(): boolean {
  const [isTablet, setIsTablet] = React.useState<boolean>(
    window.matchMedia(MEDIA_QUERY_TABLET).matches
  )

  React.useEffect(() => {
    const callback = (e: MediaQueryListEvent) => {
      setIsTablet(e.matches)
    }
    const mediaQueryList = window.matchMedia(MEDIA_QUERY_TABLET)
    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener('change', callback)
    } else {
      mediaQueryList.addListener(callback)
    }
    return () => {
      if (mediaQueryList.removeEventListener) {
        mediaQueryList.removeEventListener('change', callback)
      } else {
        mediaQueryList.removeListener(callback)
      }
    }
  }, [])

  return isTablet
}
