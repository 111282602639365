import styled from '@emotion/styled'
import * as React from 'react'
import * as Icon from 'react-feather'

import Button from '~/components/atoms/Button'
import TextField from '~/components/atoms/TextField'
import Focusable from '~/components/common/Focusable'
import * as vars from '~/styles/variables'

interface Props {
  editorInitialText: string
  readonly?: boolean
  onSubmit: (newText: string) => void
}

const SingleLineEditor: React.FC<Props> = (props) => {
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false)
  const [isHovering, setIsHovering] = React.useState<boolean>(false)

  const handleOnClick = () => {
    if (props.readonly || isEditMode) {
      return
    }
    setIsEditMode(true)
    setIsHovering(false)
  }

  const handleMouseEnter = () => {
    if (props.readonly || isEditMode) {
      return
    }
    setIsHovering(true)
  }

  const handleMouseLeave = () => {
    if (isEditMode) {
      return
    }
    setIsHovering(false)
  }

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.nativeEvent.isComposing) {
      return
    }
    if (e.key === 'Escape') {
      setIsEditMode(false)
    }
  }

  const handleSubmit = (newText: string) => {
    setIsEditMode(false)
    props.onSubmit(newText)
  }

  const handleCancelClick = () => {
    setIsEditMode(false)
  }

  return (
    <Focusable onKeyUp={handleKeyUp}>
      {isEditMode ? (
        <Editor
          initialText={props.editorInitialText}
          onSubmit={handleSubmit}
          onCancelClick={handleCancelClick}
        />
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            onClick={handleOnClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
              flexShrink: 1,
              backgroundColor: isHovering
                ? vars.colorPalette.blue50
                : 'transparent',
              cursor: props.readonly ? 'unset' : 'pointer',
            }}
          >
            {props.children}
          </div>
          {isHovering && (
            <Icon.Edit2
              size={14}
              color={vars.color.icon}
              style={{ marginLeft: vars.space.xs }}
            />
          )}
        </div>
      )}
    </Focusable>
  )
}

const Editor: React.FC<{
  initialText: string
  onSubmit: (newText: string) => void
  onCancelClick: () => void
}> = (props) => {
  const [text, setText] = React.useState<string>(props.initialText)
  return (
    <form
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      }}
      onSubmit={(e) => {
        e.preventDefault()
        props.onSubmit(text)
      }}
    >
      <TextField
        value={text}
        autoFocus={true}
        onChange={(e) => setText(e.currentTarget.value)}
      />
      <ButtonContainer>
        <SubmitButton nativeType="submit" type="primary">
          保存
        </SubmitButton>
        <CancelButton
          nativeType="button"
          type="tertiary"
          onClick={props.onCancelClick}
        >
          キャンセル
        </CancelButton>
      </ButtonContainer>
    </form>
  )
}

const ButtonContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginLeft: vars.space.m,
})

const SubmitButton = styled(Button)({
  minWidth: 50,
})

const CancelButton = styled(Button)({
  paddingRight: vars.space.m,
  paddingLeft: vars.space.m,
  minWidth: 40,
})

export default SingleLineEditor
