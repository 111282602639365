/* https://palx.jxnblk.com/2D7CC9 */

import { assertNever } from '~/common/utils'

export const colorPalette = {
  white: '#fff',
  gray0: '#f9f9f9',
  gray1: '#ebedef',
  gray2: '#dde1e4',
  gray3: '#ced3d8',
  gray4: '#bdc4cb',
  gray5: '#abb3bc',
  gray6: '#96a1ab',
  gray7: '#7d8a97',
  gray8: '#606d7a',
  gray9: '#384047',

  blue900: '#0948C2',
  blue800: '#005FED',
  blue700: '#0072FF',
  blue600: '#0085FF',
  blue500: '#0948C2',
  blue400: '#00A5FF',
  blue300: '#4CB7FF',
  blue200: '#8ACCFF',
  blue100: '#C2E3FF',
  blue50: '#F0F8FF',
}

export const color = {
  white: colorPalette.white,
  offWhite: colorPalette.gray0,
  lightGray: colorPalette.gray1,
  gray: colorPalette.gray3,
  darkGray: colorPalette.gray9,
  border: colorPalette.gray2,
  icon: colorPalette.gray5,
  /* status */
  running: '#00BDFF',
  success: '#26CC1E',
  error: '#F84B4B',
  warning: '#FFC200',
  /* theme */
  theme: colorPalette.blue800,
  accent: '#FF5DBE',
  blue: colorPalette.blue800,
}

export const fontColor = {
  primary: colorPalette.gray9,
  secondary: colorPalette.gray8,
  tertiary: colorPalette.gray6,
  invertedPrimary: colorPalette.white,
  // invertedSecondary も今後追加される可能性あり
}

export const fontFamily = {
  sansSerif: '"Helvetica Neue", Hiragino Sans, sans-serif',
  monospace: 'Consolas, Monaco, Menlo, Courier, "Ubuntu Mono", monospace',
}

export const fontSize = {
  xxs: 10,
  xs: 12,
  s: 14,
  m: 16,
  l: 18,
  xl: 21,
  xxl: 26,
  xxxl: 34,
  xxxxl: 42,
  xxxxxl: 55,
}

export function decreaseFontSize(
  baseSize: keyof typeof fontSize
): keyof typeof fontSize {
  switch (baseSize) {
    case 'xxs':
      return 'xxs' // これより小さいサイズはない
    case 'xs':
      return 'xxs'
    case 's':
      return 'xs'
    case 'm':
      return 's'
    case 'l':
      return 'm'
    case 'xl':
      return 'l'
    case 'xxl':
      return 'xl'
    case 'xxxl':
      return 'xxl'
    case 'xxxxl':
      return 'xxxl'
    case 'xxxxxl':
      return 'xxxxl'
    default:
      assertNever(baseSize)
  }
}

export const fontWeight = {
  normal: 400,
  semiBold: 600,
  bold: 700,
}

export const lineHeight = {
  just: 1,
  heading: 1.4,
  body: 1.8,
}

export const space = {
  xxs: 2,
  xs: 4,
  s: 8,
  m: 16,
  l: 32,
  xl: 64,
  xxl: 128,
}

export const borderRadius = {
  xs: 2,
  s: 4,
  m: 8,
  l: 16,
}

export const shadow = {
  none: `0 0px 0px rgba(0, 0, 0, 0), 0 0px 0px rgba(0, 0, 0, 0)`,
  s: `0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.02)`,
  m: `0 8px 8px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.03)`,
  l: `0 16px 16px rgba(0, 0 ,0, 0.04), 0 16px 32px rgba(0, 0, 0, 0.04)`,
}

export const width = {
  sidebar: 270,
  sidebarCollapsed: 67,
  xxs: 400,
  xs: 500,
  s: 700,
  m: 1100,
}

export const height = {
  lpHeader: 60,
  header: 50,
  breadcrumbs: 50,
  messageViewer: 250,
  field: 50,
  button: 40,
  pill: 27,
  switch: 35,
}

export const media = {
  tablet: '@media (max-width: 768px)',
  sp: '@media (max-width: 480px)',
}

export const zIndex = {
  toaster: 10001,
  sentryErrorDialog: 10001,
  hoverCard: 10000,
  modal: 10000,
  dropdown: 9000,
  tip: 9000,
  form: 1002,
  variableFinder: 1002,
  // TODO: variableFinder や form も window に統一する
  window: 1002,
  formOverlay: 1001,
  sidebar: 1000,
}
