import { Tson } from '~/data/Tson'
import {
  InvitationErrorJson,
  InvitationMailErrorsJson,
} from '~/data/user/invitation/InvitationErrorJson'

export function mapToInvitationsErrorJson(tson: Tson): InvitationErrorJson {
  if (Array.isArray(tson.asIs())) {
    return tson.asArray().map(mapToInvitationMailErrorsJson)
  }
  const body = tson.getArray('body')
  return { body: body.map((it) => it.asString()) }
}

function mapToInvitationMailErrorsJson(tson: Tson): InvitationMailErrorsJson {
  const email = tson.getArray('email', false)
  if (email === undefined || email === null) {
    return {}
  }
  return {
    email: email.map((e) => e.asString()),
  }
}
