import * as React from 'react'

import Text from '~/components/atoms/Text'
import { Container } from '~/presentation/workflow/detail/editor/form/viewWidget/triggerInstruction/TriggerInstructionWidget'
import * as vars from '~/styles/variables'

const UnavailableTriggerInstructionWidget: React.FC = () => {
  return (
    <Container>
      <Text
        element="h1"
        fontSize="s"
        lineHeight="heading"
        style={{ marginBottom: vars.space.s }}
      >
        このトリガーを実行するには設定が必要です。
      </Text>
      <Text element="p" fontSize="s">
        設定内容を確認するには、
        <strong>
          ワークフロー作成後、このトリガーの入力フォームを開いてください。
        </strong>
        ここに設定内容が表示されます。
      </Text>
    </Container>
  )
}

export default UnavailableTriggerInstructionWidget
