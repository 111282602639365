import styled from '@emotion/styled'
import Color from 'color'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { animated, useSpring } from 'react-spring'

import { useEscapeKey } from '~/hooks/useEscapeKey'
import * as vars from '~/styles/variables'

interface Props {
  isOpened: boolean
  noDimmer?: boolean
  mountOnOpen?: boolean
  children: React.ReactNode
  className?: string
  onClose: () => void
  lightDimmer?: boolean
}

const Modal: React.FC<Props> = ({
  isOpened,
  noDimmer = false,
  mountOnOpen = false,
  children,
  className,
  onClose,
  lightDimmer = false,
}) => {
  const [showing, setShowing] = React.useState<boolean>(false)
  const containerProps = useSpring({
    to: async (next: any) => {
      if (isOpened) {
        await next({ display: 'flex' })
        await next({ opacity: 1 })
      } else {
        await next({
          opacity: 0,
        })
        await next({ display: 'none' })
      }
    },
    from: {
      opacity: 0,
      display: 'none',
    },
    config: {
      duration: 200,
    },
    onStart: () => {
      if (isOpened) {
        setShowing(true)
      }
    },
    onRest: () => {
      if (!isOpened) {
        setShowing(false)
      }
    },
  })

  useEscapeKey(() => {
    if (isOpened) {
      onClose()
    }
  }, [isOpened, onClose])

  const target = document.getElementById('portal-root')
  if (!target) {
    return null
  }
  if (mountOnOpen && !showing) {
    return null
  }
  return ReactDOM.createPortal(
    <Container style={containerProps}>
      <Overlay
        onClick={onClose}
        noDimmer={noDimmer}
        lightDimmer={lightDimmer}
      />
      <Content className={className}>{children}</Content>
    </Container>,
    target
  )
}

const Container = styled(animated.div)({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  zIndex: vars.zIndex.modal,
})

const Overlay = styled('div')(
  {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
  },
  (props: { noDimmer: boolean; lightDimmer: boolean }) => ({
    backgroundColor: props.noDimmer
      ? 'none'
      : props.lightDimmer
      ? Color(vars.colorPalette.gray0).alpha(0.7).toString()
      : 'rgba(0, 0, 0, 0.7)',
  })
)

const Content = styled('div')({
  position: 'relative',
  marginTop: -70,
  // TODO
  minWidth: 400,
  borderRadius: vars.borderRadius.m,
  backgroundColor: vars.color.white,
  boxShadow: vars.shadow.m,
  zIndex: 1,
  [vars.media.sp]: {
    minWidth: 'unset',
    maxWidth: '90%',
  },
})

export default Modal
