import * as React from 'react'

/**
 * エレメントの境界情報を取得
 * width や height は padding を含まないので注意 (ResizeObserver の仕様)
 */
export function useMeasure<T extends Element>(): {
  ref: React.MutableRefObject<T | null>
  bounds: { top: number; left: number; width: number; height: number }
} {
  const ref = React.useRef<T | null>(null)
  const [bounds, set] = React.useState({ left: 0, top: 0, width: 0, height: 0 })
  const [ro] = React.useState(
    () => new ResizeObserver(([entry]) => set(entry.contentRect))
  )

  React.useEffect(() => {
    if (ref && ref.current) {
      ro.observe(ref.current)
    }
    return () => ro.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { ref, bounds }
}
