import styled from '@emotion/styled'
import * as _ from 'lodash'
import * as React from 'react'
import { ChevronLeft } from 'react-feather'
import { useSelector } from 'react-redux'
import { animated, useSpring, useTransition } from 'react-spring'

import { actionNodeSize } from '~/components/molecules/ActionNode'
import App from '~/components/molecules/App'
import { TriggerDefinition } from '~/domain/workflow/trigger/TriggerDefinition'
import * as editorDuck from '~/ducks/ui/editor'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import * as vars from '~/styles/variables'

interface OwnProps {
  isShown: boolean
  top: number
  left: number
  onSelect: (selectedTrigger: TriggerDefinition) => void
  onClose: () => void
}

type Props = OwnProps

const TriggerSelector: React.FC<Props> = ({
  isShown,
  top,
  left,
  onSelect,
  onClose,
}) => {
  const definitions = useDefinitions()
  const scale = useSelector(editorDuck.selectors.getScale)

  const target = document.getElementById('portal-root')
  const [view, setView] = React.useState<'app' | 'action'>('app')
  const [selectedAppId, setSelectedAppId] = React.useState('')
  const [lastIsShown, setLastIsShown] = React.useState(false)
  const transitions = useTransition(view, null, {
    from: { opacity: 0, transform: 'translate3d(50%, 0, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0%, 0, 0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%, 0, 0)' },
  })

  React.useEffect(() => {
    if (isShown && !lastIsShown) {
      // オープンした瞬間
      document.addEventListener('click', handleDocumentClick)
      // PanZoomViewをドラッグしたときに消えるように
      document.addEventListener('mousedown', handleDocumentClick)
      setLastIsShown(isShown)
    } else if (!isShown && lastIsShown) {
      // クローズした瞬間
      document.removeEventListener('click', handleDocumentClick)
      document.removeEventListener('mousedown', handleDocumentClick)
      setLastIsShown(isShown)
    }
    return () => {
      document.removeEventListener('click', handleDocumentClick)
      document.removeEventListener('mousedown', handleDocumentClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShown])

  function resetState() {
    setView('app')
    setLastIsShown(false)
  }

  function handleDocumentClick() {
    resetState()
    onClose()
  }

  function handleSelect(trigger: TriggerDefinition) {
    onSelect(trigger)
    resetState()
  }

  // PanZoomViewがドラッグしたときにAppSelectorが消えるようにするために、
  // documentにmousedownイベントがつけてあるが、それをすべて無効にする
  function handleMouseDown(e: React.MouseEvent) {
    e.nativeEvent.stopImmediatePropagation()
  }

  const animationProps = useSpring({
    opacity: isShown ? 1 : 0,
    transform: isShown ? 'scale(1)' : 'scale(0.9)',
  })

  if (!target) {
    return null
  }

  const triggerAppIds: Set<string> = new Set()
  Object.values(definitions.triggersById).forEach((it) =>
    triggerAppIds.add(it.appId)
  )
  const availableApps = Object.values(definitions.appsById).filter((app) => {
    return triggerAppIds.has(app.appId)
  })

  function render() {
    return (
      <Container
        style={{
          ...animationProps,
          top,
          left: left + actionNodeSize * scale,
        }}
      >
        {transitions.map(({ item, props, key }) => {
          return item === 'app' ? (
            <AppList style={props} key={key}>
              {availableApps.map((app) => {
                return (
                  <AppItem
                    key={app.appId}
                    available={true}
                    onClick={(e) => {
                      // https://gist.github.com/ggregoire/ce7bc946212920c0a6bad8125567d001
                      e.nativeEvent.stopImmediatePropagation()
                      setView('action')
                      setSelectedAppId(app.appId)
                    }}
                    onMouseDown={handleMouseDown}
                  >
                    <App icon={app.iconPath} color={app.color} size="m" />
                    <AppName>{app.name}</AppName>
                  </AppItem>
                )
              })}
            </AppList>
          ) : (
            (() => {
              const _triggers = _.filter(
                Object.values(definitions.triggersById),
                (o) => o.appId === selectedAppId
              )
              const app = definitions.getApp(selectedAppId)
              if (_triggers.length === 0) {
                return (
                  <OperatorContainer style={props} key={key}>
                    <Header>
                      <ChevronLeft
                        onClick={(e) => {
                          e.nativeEvent.stopImmediatePropagation()
                          resetState()
                        }}
                        onMouseDown={handleMouseDown}
                      />
                      <AppName>{app.name}</AppName>
                    </Header>
                    <NoOperators>アクションがありません</NoOperators>
                  </OperatorContainer>
                )
              }
              return (
                <OperatorContainer style={props} key={key}>
                  <Header>
                    <ChevronLeft
                      onClick={(e) => {
                        e.nativeEvent.stopImmediatePropagation()
                        resetState()
                      }}
                      onMouseDown={handleMouseDown}
                    />
                    <AppName>{app.name}</AppName>
                  </Header>
                  <OperatorList>
                    {_triggers.map((trigger) => {
                      return (
                        <OperatorItem
                          key={trigger.triggerId}
                          onClick={(e) => {
                            // https://gist.github.com/ggregoire/ce7bc946212920c0a6bad8125567d001
                            e.nativeEvent.stopImmediatePropagation()
                            handleSelect(trigger)
                          }}
                          onMouseDown={handleMouseDown}
                        >
                          <App icon={app.iconPath} color={app.color} size="m" />
                          <OperatorName>{trigger.name}</OperatorName>
                        </OperatorItem>
                      )
                    })}
                  </OperatorList>
                </OperatorContainer>
              )
            })()
          )
        })}
      </Container>
    )
  }
  return isShown ? render() : null
}

const Container = styled(animated.div)({
  position: 'fixed',
  maxHeight: 500,
  minHeight: 500,
  width: 300,
  padding: vars.space.m * 1.3,
  backgroundColor: vars.color.white,
  borderRadius: vars.borderRadius.m,
  boxShadow: vars.shadow.m,
  overflowY: 'scroll',
  overflowX: 'hidden',
  zIndex: 2,
})

const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: vars.space.s,
})

const NoOperators = styled('div')({
  padding: vars.space.s,
  fontSize: vars.fontSize.s,
})

const AppList = styled(animated.div)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  padding: vars.space.m * 1.3,
  willChange: 'transform, opacity',
})

const AppItem = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    paddingTop: vars.space.xs * 1.5,
    paddingBottom: vars.space.xs * 1.5,
    cursor: 'pointer',
    '&:hover': {
      transform: 'translateY(-2px)',
    },
    '&:first-of-type': {
      marginTop: 0,
    },
  },
  (props: { available: boolean }) => ({
    opacity: props.available ? 1 : 0.5,
  })
)

const AppName = styled('div')({
  marginLeft: vars.space.s,
  fontSize: vars.fontSize.s,
  fontWeight: vars.fontWeight.bold,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const OperatorContainer = styled(animated.div)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
})

const OperatorList = styled('div')({
  paddingRight: vars.space.m * 1.3,
  paddingLeft: vars.space.m * 1.3,
  paddingBottom: vars.space.m * 1.3,
})

const OperatorItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  paddingTop: vars.space.xs * 1.5,
  paddingBottom: vars.space.xs * 1.5,
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-2px)',
  },
  '&:first-of-type': {
    marginTop: 0,
  },
})

const OperatorName = styled('div')({
  marginLeft: vars.space.s,
  fontSize: vars.fontSize.s,
  fontWeight: vars.fontWeight.bold,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

export default TriggerSelector
