import { mapJsonToUser } from '~/data/user/mapJsonToUser'
import { WorkflowInstanceJson } from '~/data/workflow/instance/json'
import { mapToRunningStatus } from '~/data/workflow/instance/mapToRunningStatus'
import { mapJsonToWorkflowSourceBody } from '~/data/workflow/source/mapJsonToWorkflowSourceBody'
import { WorkflowInstance } from '~/domain/workflow/instance/WorkflowInstance'

export async function mapJsonToWorkflowInstance(
  json: WorkflowInstanceJson
): Promise<WorkflowInstance> {
  return {
    instanceId: json.slug,
    runningStatus: mapToRunningStatus(json.state),
    updatedAt: json.updatedAt,
    runner: mapJsonToUser(json.runner),
    frozenSource: await mapJsonToWorkflowSourceBody(json.frozenSource),
  }
}
