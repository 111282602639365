import { Tson } from '~/data/Tson'
import { InvitationJson } from '~/data/user/invitation/InvitationJson'

export function mapToInvitationJson(tson: Tson): InvitationJson {
  return {
    id: tson.getString('slug'),
    email: tson.getString('email'),
    expireAt: tson.getString('expireAt'),
    updatedAt: tson.getString('updatedAt'),
  }
}
