import { ScheduleTrigger } from '~/common/constants'
import { InputSource } from '~/domain/workflow/source/WorkflowSourceBody'

export function getDefaultTriggerInputs(
  triggerId?: string,
  userId?: string
): InputSource[] {
  const defaultTriggerInputs: InputSource[] = [
    {
      fieldKey: 'default_runner_slug',
      value:
        userId !== undefined
          ? {
              mode: 'raw',
              raw: userId,
            }
          : undefined,
    },
    {
      fieldKey: 'is_always_default_runner',
      value: {
        mode: 'raw',
        raw: true,
      },
    },
  ]
  if (triggerId === ScheduleTrigger) {
    defaultTriggerInputs.push({
      fieldKey: 'interval',
      value: {
        mode: 'raw',
        raw: '*/5 * * * *',
      },
    })
  }
  return defaultTriggerInputs
}
