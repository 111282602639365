import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import * as _ from 'lodash'
import * as React from 'react'
import { ChevronRight } from 'react-feather'
import { animated, useSpring } from 'react-spring'

import * as vars from '~/styles/variables'

export interface Tab {
  id: string
  label: string
  nested: boolean
  renderTab?: (label: string) => React.ReactNode
}

interface Props {
  tabs: Tab[]
  selectedTabId: string
  tabHeight?: number
  className?: string
  renderContent?: (tabId: string) => React.ReactNode
  onTabClick?: (tabId: string) => void
}

const Tabs: React.FC<Props> = ({
  tabs,
  selectedTabId,
  renderContent,
  tabHeight = 50,
  className,
  onTabClick,
}) => {
  const [barRect, setBarRect] = React.useState<{ x: number; width: number }>({
    x: 0,
    width: 0,
  })
  const containerRef = React.useRef<HTMLDivElement | null>(null)
  const tabsRef = React.useRef<(HTMLDivElement | null)[]>([])

  const barProps = useSpring({
    left: barRect.x,
    width: barRect.width,
  })

  React.useEffect(() => {
    const index = _.findIndex(tabs, (tab) => tab.id === selectedTabId)
    if (tabsRef && tabsRef.current) {
      const ref = tabsRef.current[index]
      if (ref) {
        const rect = ref.getBoundingClientRect()
        changeBarRect(rect)
      }
    }
  }, [tabs, selectedTabId])

  const handleTabClick = (e: React.MouseEvent) => {
    const rect = e.currentTarget.getBoundingClientRect()
    changeBarRect(rect)
  }

  const changeBarRect = (rect: DOMRect) => {
    if (containerRef && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect()
      setBarRect({
        x: rect.x - containerRect.x,
        width: rect.width,
      })
    }
  }

  return (
    <Container className={className} ref={containerRef}>
      <Bar style={barProps} tabHeight={tabHeight} />
      <TabList>
        {tabs.map((tab, i) => {
          const selected = tab.id === selectedTabId
          return (
            <React.Fragment key={tab.id}>
              {tab.nested ? (
                <ChevronRight
                  size={14}
                  color={vars.fontColor.tertiary}
                  style={{
                    marginRight: vars.space.xs,
                    marginLeft: vars.space.xs,
                  }}
                />
              ) : null}
              <TabHeader
                ref={(el) => (tabsRef.current[i] = el)}
                selected={selected}
                tabHeight={tabHeight}
                onClick={(e) => {
                  handleTabClick(e)
                  if (onTabClick) {
                    onTabClick(tab.id)
                  }
                }}
              >
                {tab.renderTab ? tab.renderTab(tab.label) : tab.label}
              </TabHeader>
            </React.Fragment>
          )
        })}
      </TabList>
      {renderContent ? renderContent(selectedTabId) : null}
    </Container>
  )
}

const barHeight = 3

const Container = styled('div')({
  position: 'relative',
})

const TabList = styled('div')({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'flex-end',
})

const TabHeader = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: vars.space.m,
    paddingLeft: vars.space.m,
    fontSize: vars.fontSize.s,
    fontWeight: vars.fontWeight.bold,
    lineHeight: 1,
    borderTopRightRadius: vars.borderRadius.s,
    borderTopLeftRadius: vars.borderRadius.s,
    cursor: 'pointer',
  },
  (props: { selected: boolean; tabHeight: number }) => ({
    height: props.tabHeight,
    color: props.selected ? vars.fontColor.primary : vars.fontColor.tertiary,
  })
)

const Bar = styled(animated.div, {
  shouldForwardProp: isPropValid,
})(
  {
    position: 'absolute',
    height: barHeight,
    backgroundColor: vars.colorPalette.blue600,
  },
  (props: { tabHeight: number }) => ({
    top: props.tabHeight - barHeight,
  })
)

export default Tabs
