import {
  BooleanValueType,
  ListValueType,
  NumberValueType,
  StringValueType,
} from '~/domain/ValueType'
import { AssistWidgetDefinition } from '~/domain/workflow/widget/assist'
import { MultiAssistWidgetDefinition } from '~/domain/workflow/widget/multi_assist'
import { NumberWidgetDefinition } from '~/domain/workflow/widget/number'
import {
  MultiSelectWidgetDefinition,
  SelectWidgetDefinition,
} from '~/domain/workflow/widget/select'
import { TextWidgetDefinition } from '~/domain/workflow/widget/text'

export interface SalesforceSearchConditionOperator {
  id: string
  label: string
  operandType: SalesforceOperandType
  isStateOperator?: boolean
}

interface SelectOption {
  label: string
  value: string
}

export type SalesforceOperandType =
  | SalesforceOperandType.None
  | SalesforceOperandType.Text
  | SalesforceOperandType.Number
  | SalesforceOperandType.Boolean
  | SalesforceOperandType.Select
  | SalesforceOperandType.MultiSelect
  | SalesforceOperandType.Assist
  | SalesforceOperandType.MultiAssist

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace SalesforceOperandType {
  export class None {
    public readonly name = 'none'
  }

  export class Text {
    public readonly name = 'text'

    toWidgetDefinition(): TextWidgetDefinition {
      return {
        formType: 'text',
        placeholder: '値を入力してください',
      }
    }

    toValueType(): StringValueType {
      return {
        typeName: 'string',
        nullable: false,
      }
    }
  }

  export class Number {
    public readonly name = 'number'

    toWidgetDefinition(): NumberWidgetDefinition {
      return {
        formType: 'number',
      }
    }

    toValueType(): NumberValueType {
      return {
        typeName: 'number',
        canDecimal: true,
        nullable: false,
      }
    }
  }

  export class Boolean {
    public readonly name = 'boolean'

    toWidgetDefinition(): SelectWidgetDefinition {
      // NOTE: select ウィジェットを文字列しか扱わないようにする場合、 boolean select widget を実装するのがいいかも？
      return {
        formType: 'select',
        options: [
          { label: 'true', value: true },
          { label: 'false', value: false },
        ],
      }
    }

    toValueType(): BooleanValueType {
      return {
        typeName: 'boolean',
        nullable: false,
      }
    }
  }

  export class Select {
    public readonly name = 'select'
    public readonly options: SelectOption[]

    constructor(options: SelectOption[]) {
      this.options = options
    }

    toWidgetDefinition(): SelectWidgetDefinition {
      return {
        formType: 'select',
        options: this.options,
      }
    }

    toValueType(): StringValueType {
      return {
        typeName: 'string',
        nullable: false,
      }
    }
  }

  export class MultiSelect {
    public readonly name = 'multi_select'
    public readonly options: SelectOption[]

    constructor(options: SelectOption[]) {
      this.options = options
    }

    toWidgetDefinition(): MultiSelectWidgetDefinition {
      return {
        formType: 'multi_select',
        options: this.options,
      }
    }

    toValueType(): ListValueType {
      return {
        typeName: 'list',
        itemType: {
          typeName: 'string',
          nullable: false,
        },
        nullable: false,
      }
    }
  }

  export class Assist {
    public readonly name = 'assist'

    toWidgetDefinition(): AssistWidgetDefinition {
      return {
        formType: 'assist',
        fallbackForm: {
          formType: 'text',
        },
        assist: {
          assistId: 'SalesforceApp__sobject_reference_field_value',
          parameterFieldKeys: ['account_uid', 'sobject_name', 'field_name'],
          queryPlaceholder: '入力して検索...',
        },
      }
    }

    toValueType(): StringValueType {
      return {
        typeName: 'string',
        nullable: false,
      }
    }
  }

  export class MultiAssist {
    public readonly name = 'multi_assist'

    toWidgetDefinition(): MultiAssistWidgetDefinition {
      return {
        formType: 'multi_assist',
        fallbackForm: {
          formType: 'text',
        },
        assist: {
          assistId: 'SalesforceApp__sobject_reference_field_value',
          parameterFieldKeys: ['account_uid', 'sobject_name', 'field_name'],
          queryPlaceholder: '入力して検索...',
        },
      }
    }

    toValueType(): ListValueType {
      return {
        typeName: 'list',
        itemType: {
          typeName: 'string',
          nullable: false,
        },
        nullable: false,
      }
    }
  }
}
