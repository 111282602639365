import * as React from 'react'

import { apiClients } from '~/common/apiClients'
import { Workflow } from '~/domain/workflow/Workflow'
import { WorkflowService } from '~/domain/workflow/WorkflowService'
import { WorkflowSourceBody } from '~/domain/workflow/source/WorkflowSourceBody'

const service: WorkflowService = apiClients.workflowService

export function useCreateWorkflow(): {
  createWorkflow: (
    sourceBody: WorkflowSourceBody,
    workflowName?: string
  ) => Promise<Workflow>
} {
  const create = React.useCallback(
    (sourceBody: WorkflowSourceBody, workflowName?: string) => {
      return service.create(workflowName ?? '無題のワークフロー', sourceBody)
    },
    []
  )
  return { createWorkflow: create }
}
