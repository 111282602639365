import styled from '@emotion/styled'
import * as React from 'react'

import Button from '~/components/atoms/Button'
import CheckField from '~/components/atoms/CheckField'
import Text from '~/components/atoms/Text'
import Inner from '~/components/organisms/Inner'
import Modal from '~/components/organisms/Modal'
import SalesforceForm from '~/containers/Contact/SalesforceForm'
import { FormValues } from '~/containers/RecipeLp/Form'
import { useRecaptcha } from '~/hooks/useRecaptcha'
import * as vars from '~/styles/variables'

interface Props {
  isShown: boolean
  values?: FormValues
  onClose: () => void
}

const ConfirmModal: React.FC<Props> = (props) => {
  const [recaptchaElement, timestamp, recaptchaVerified] = useRecaptcha(
    '6Ldr8NAUAAAAALB3KYSpmQsoHk52NspJU_F3Q7UW'
  )
  const [agreed, setAgreed] = React.useState(false)
  const [errorModalIsOpened, setErrorModalIsOpened] = React.useState<boolean>(
    false
  )
  const submitEnabled = recaptchaVerified && agreed

  return (
    <>
      <Modal isOpened={props.isShown} onClose={props.onClose}>
        <Container>
          <Text
            element="h1"
            fontSize="l"
            fontWeight="bold"
            lineHeight="heading"
          >
            個人情報取扱の確認
          </Text>
          <SalesforceForm
            timestamp={timestamp}
            callbackUrl="https://anyflow.jp/contact/thankyou"
            source="レシピLP"
            onError={() => {
              setErrorModalIsOpened(true)
            }}
          >
            {props.values === undefined
              ? '入力されていません'
              : Object.entries(props.values).map(([key, value]) => (
                  <input type="hidden" key={key} name={key} value={value} />
                ))}
            <Text element="p" fontSize="s" style={{ marginTop: vars.space.m }}>
              <a href="https://anyflow.jp/privacy" target="_blank">
                個人情報の取り扱い
              </a>
              に同意しますか
            </Text>
            <CheckField
              id="00N2v00000UwDd0"
              name="00N2v00000UwDd0"
              label="同意する"
              value="同意する"
              title="個人情報の取り扱いへの同意"
              checked={agreed}
              onChange={(e) => setAgreed(e.currentTarget.checked)}
              required={true}
              style={{
                marginTop: vars.space.s,
              }}
            />
            <div style={{ marginTop: vars.space.m }}>{recaptchaElement}</div>
            <Button
              disabled={!submitEnabled}
              style={{ marginTop: vars.space.m, width: '100%' }}
            >
              お問い合わせ
            </Button>
          </SalesforceForm>
        </Container>
      </Modal>
      <ErrorModal
        isOpened={errorModalIsOpened}
        onClose={() => {
          setErrorModalIsOpened(false)
        }}
      >
        <Inner size="xs" responsive={true}>
          <Text element="h2" fontSize="l" fontWeight="bold">
            エラーが発生しました
          </Text>
          <ModalDescription
            element="p"
            fontSize="s"
            color={vars.fontColor.secondary}
          >
            エラーが発生し、お問い合わせ内容を正しく送信できませんでした。
            <br />
            時間をおいてから再度お試しください。それでも解決しない場合、お手数ですが
            <a href="https://anyflow.jp/contact" target="_blank">
              お問合せフォーム
            </a>
            よりお知らせください。
          </ModalDescription>
          <ModalFooter>
            <ModalButton
              type="tertiary"
              onClick={() => setErrorModalIsOpened(false)}
            >
              閉じる
            </ModalButton>
          </ModalFooter>
        </Inner>
      </ErrorModal>
    </>
  )
}

const Container = styled('div')({
  padding: vars.space.l,
  width: '100%',
  maxWidth: 400,
  backgroundColor: vars.color.white,
  borderRadius: vars.borderRadius.m,
  [vars.media.tablet]: {
    padding: vars.space.m,
  },
})

const ErrorModal = styled(Modal)({
  padding: vars.space.l,
})

const ModalDescription = styled(Text)({
  marginTop: vars.space.m,
})

const ModalFooter = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: vars.space.m,
})

const ModalButton = styled(Button)({
  color: vars.fontColor.secondary,
})

export default ConfirmModal
