import styled from '@emotion/styled'
import * as React from 'react'

import Button from '~/components/atoms/Button'
import Text from '~/components/atoms/Text'
import TextField from '~/components/atoms/TextField'
import Modal from '~/components/organisms/Modal'
import * as vars from '~/styles/variables'

interface Props {
  isOpened: boolean
  workflowName?: string
  isError: boolean
  onSubmit: (newName: string) => void
  onClose: () => void
}

const RenameModal: React.FC<Props> = ({
  isOpened,
  workflowName,
  isError,
  onClose,
  onSubmit,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const [name, setName] = React.useState(workflowName)

  React.useEffect(() => {
    if (workflowName !== undefined) {
      setName(workflowName)
    }
  }, [workflowName])

  // move focus to text input
  React.useEffect(() => {
    if (isOpened && inputRef.current !== null) {
      // HACK: run asynchronously to wait for parent modal's display to change from 'none'
      setTimeout(() => {
        inputRef.current && inputRef.current.focus()
      }, 0)
    }
  }, [isOpened])

  const handleSubmit = () => {
    if (name !== undefined) {
      onSubmit(name)
    }
  }

  const isOpen = isOpened && workflowName !== undefined
  return (
    <_Modal isOpened={isOpen} onClose={onClose}>
      <Container>
        <Text
          element="h1"
          fontSize="l"
          fontWeight="bold"
          lineHeight="heading"
          style={{
            paddingLeft: vars.space.m,
          }}
        >
          ワークフロー名を編集
        </Text>
        <EditForm
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit()
          }}
        >
          <TextField
            ref={inputRef}
            value={name}
            hasError={isError}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <ButtonContainer>
            <Button nativeType="button" type="tertiary" onClick={onClose}>
              キャンセル
            </Button>
            <Button nativeType="submit" type="primary">
              変更
            </Button>
          </ButtonContainer>
        </EditForm>
      </Container>
    </_Modal>
  )
}

const _Modal = styled(Modal)({
  width: 600,
})

const Container = styled('div')({
  paddingTop: vars.space.l,
  paddingRight: vars.space.m,
  paddingBottom: vars.space.m,
  paddingLeft: vars.space.m,
})

const EditForm = styled('form')({
  marginTop: vars.space.m,
})

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: vars.space.m,
})

export default RenameModal
