import React from 'react'

export interface WorkflowEditorContextValue {
  workflowId: string
  isEditorLoading: boolean
  addEmptyTask: (parentTaskId: string) => string
  removeTask: (taskId: string) => void
}

export const WorkflowEditorContext = React.createContext<
  WorkflowEditorContextValue | undefined
>(undefined)

export function useWorkflowEditorContext(): WorkflowEditorContextValue {
  const context = React.useContext(WorkflowEditorContext)
  if (context === undefined) {
    throw new Error('WorkflowEditorContext is undefined. Out of context scope?')
  }
  return context
}
