import styled from '@emotion/styled'
import * as React from 'react'

import { assertNever } from '~/common/utils'
import TypeSign from '~/components/atoms/TypeSign'
import Tooltip from '~/components/utils/Tooltip'
import { ValueType } from '~/domain/ValueType'
import * as vars from '~/styles/variables'
import { borderWidth, widgetBorderStyle } from '~/styles/widget'

interface Props {
  valueType: ValueType
  className?: string
  style?: React.CSSProperties
}

const WidgetTypeSign: React.FC<Props> = (props) => {
  return (
    <Container className={props.className} style={props.style}>
      <Tooltip
        renderContent={() => {
          const prefix = '「'
          const suffix = '」型が入力できます。'
          switch (props.valueType.typeName) {
            case 'string':
              return prefix + '文字列' + suffix
            case 'number':
              return prefix + '数値' + suffix
            case 'boolean':
              return prefix + '真偽値' + suffix
            case 'file':
              return prefix + 'ファイル' + suffix
            case 'list':
              return prefix + 'リスト' + suffix
            case 'struct':
              return prefix + '構造体' + suffix
            case 'dict':
              return prefix + '辞書' + suffix
            case 'any':
              return 'どんな型でも入力できます。'
            case 'never':
              return 'どんな型も入力できません。'
            default:
              return assertNever(props.valueType)
          }
        }}
      >
        <TypeSign valueType={props.valueType} color={vars.fontColor.tertiary} />
      </Tooltip>
    </Container>
  )
}
const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: 11,
  width: 50,
  backgroundColor: vars.color.offWhite,
  // borderRadius をそのまま使うと余白が空いてしまうので borderWidth を引く
  borderTopLeftRadius: widgetBorderStyle.borderRadius - borderWidth,
  borderBottomLeftRadius: widgetBorderStyle.borderRadius - borderWidth,
})

export default WidgetTypeSign
