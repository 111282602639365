import styled from '@emotion/styled'
import * as React from 'react'

import { RenderableEntity } from '~/domain/workflow/source/RenderableElement'
import { EditorViewWrapper } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/prosemirror/EditorViewWrapper'
import { useProseMirrorNodeViewPortals } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/prosemirror/ProseMirrorNodeViewPortals'
import {
  RootNode,
  isEmpty,
} from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/prosemirror/schema'
import * as vars from '~/styles/variables'

interface Props {
  value: RootNode
  className?: string
  onChange: (value: RootNode) => void
  onFocus: () => void
  onBlur: () => void
  placeholder?: string
  disabled?: boolean
}

// 外部に公開する命令を限定しておく
export interface IEditor {
  focus: () => void
  insertEntity: (label: string, entity: RenderableEntity) => void
}

const EntityFieldComponent: React.ForwardRefRenderFunction<IEditor, Props> = (
  props,
  ref
) => {
  const domRef = React.useRef<HTMLDivElement>(null)
  const editorViewRef = React.useRef<EditorViewWrapper | null>(null)

  const handleChange = React.useCallback(
    (rootNode: RootNode) => props.onChange(rootNode),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChange]
  )

  const initialPlaceholder = React.useMemo(() => {
    return isEmpty(props.value) ? props.placeholder : ''
  }, [props.placeholder, props.value])

  const [placeholder, setPlaceholder] = React.useState(initialPlaceholder)

  const handleFocus = React.useCallback(() => {
    setPlaceholder('')
    props.onFocus()
  }, [props])

  const handleBlur = React.useCallback(() => {
    const text = isEmpty(props.value) ? props.placeholder : ''
    setPlaceholder(text)
    props.onBlur()
  }, [props])

  const { addPortal } = useProseMirrorNodeViewPortals()

  React.useImperativeHandle(ref, () => ({
    focus: () => {
      editorViewRef.current?.focus()
    },
    insertEntity: (label: string, entity: RenderableEntity) => {
      if (editorViewRef.current) {
        editorViewRef.current.insertEntity(label, entity)
      }
    },
  }))

  const handleRootNodeChange = React.useCallback(
    (newRootNode: RootNode) => {
      handleChange(newRootNode)
    },
    [handleChange]
  )

  React.useEffect(() => {
    const dom = domRef.current
    if (dom) {
      const editorView = new EditorViewWrapper(
        dom,
        props.value,
        addPortal,
        handleRootNodeChange,
        props.disabled
      )
      editorView.moveCursorToEnd()
      editorViewRef.current = editorView
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <EditorContainer className={props.className}>
        <Editor
          ref={domRef}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder={placeholder ?? ''}
        />
      </EditorContainer>
    </Container>
  )
}

const Container = styled('div')({
  position: 'relative',
  width: '100%',
})

const EditorContainer = styled('div')({
  paddingTop: vars.space.s,
  paddingRight: vars.space.m,
  paddingBottom: vars.space.s,
  paddingLeft: vars.space.m,
  display: 'block',
  width: '100%',
  minHeight: vars.height.field,
  fontSize: vars.fontSize.m,
  lineHeight: 1.8,
  wordBreak: 'break-word',
  whiteSpace: 'pre-wrap',
  fontVariantLigatures: 'none',
  '& *:focus': {
    outline: 'none',
  },
})

const Editor = styled('div')(
  {
    '&::before': {
      color: vars.colorPalette.gray6,
      float: 'left',
      pointerEvents: 'none',
    },
  },
  (props: { placeholder: string }) => ({
    '&::before': {
      content: `'${props.placeholder}'`,
    },
  })
)

export default React.forwardRef(EntityFieldComponent)
