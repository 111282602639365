import { Tson } from '~/data/Tson'
import {
  TriggerInstructionWidgetJson,
  ViewWidgetJson,
} from '~/data/workflow/widget/json'

export function mapToViewWidgetJson(tson: Tson): ViewWidgetJson {
  const formType = tson.getString('formType')
  switch (formType) {
    case 'trigger_instruction':
      return mapToTriggerInstructionWidgetJson(tson)
    default:
      throw new Error(`Unknown form type: ${formType}`)
  }
}

function mapToTriggerInstructionWidgetJson(
  tson: Tson
): TriggerInstructionWidgetJson {
  const triggerInstruction = tson.getValue('triggerInstruction')
  return {
    formType: 'trigger_instruction',
    instructionText: tson.getString('instructionText'),
    triggerInstruction: {
      triggerInstructionId: triggerInstruction.getString(
        'triggerInstructionId'
      ),
      parameterFieldKeys: triggerInstruction
        .getArray('parameterFieldKeys')
        .map((it) => it.asString()),
    },
  }
}
