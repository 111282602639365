import * as utils from '~/common/utils'
import { FieldJson } from '~/data/workflow/field/FieldJson'
import { mapJsonToValueType } from '~/data/workflow/valueType/mapJsonToValueType'
import { mapJsonToInputWidgetDefinition } from '~/data/workflow/widget/mapJsonToInputWidgetDefinition'
import { mapJsonToViewWidgetDefinition } from '~/data/workflow/widget/mapJsonToViewWidgetDefinition'
import { FieldDefinition } from '~/domain/workflow/field/FieldDefinition'

export function mapJsonToFieldDefinition(json: FieldJson): FieldDefinition {
  switch (json.fieldType) {
    case 'static': {
      return {
        fieldType: utils.ensure(
          json.fieldType,
          'fieldType is undefined or null'
        ),
        key: json.key,
        bulkable: json.bulkable,
        required: json.required,
        renderable: json.renderable,
        dependencies: json.dependencies,
        label: json.label,
        note: json.note,
        valueType: mapJsonToValueType(json.valueType),
        form: mapJsonToInputWidgetDefinition(json.form),
      }
    }
    case 'dynamic': {
      return {
        fieldType: json.fieldType,
        key: json.key,
        label: json.label,
        note: json.note,
        bulkable: json.bulkable,
        required: json.required,
        renderable: json.renderable,
        dependencies: json.dependencies,
        dynamicField: {
          dynamicFieldId: json.dynamicField.dynamicFieldId,
          parameterFieldKeys: json.dynamicField.parameterFieldKeys,
        },
      }
    }
    case 'info_view': {
      return {
        fieldType: json.fieldType,
        key: json.key,
        dependencies: json.dependencies,
        form: mapJsonToViewWidgetDefinition(json.form),
      }
    }
    default: {
      return utils.assertNever(json)
    }
  }
}
