import styled from '@emotion/styled'
import Color from 'color'
import React from 'react'
import * as Icon from 'react-feather'
import { animated, useSpring } from 'react-spring'

import { assertNever } from '~/common/utils'
import Loader from '~/components/atoms/Loader'
import Text from '~/components/atoms/Text'
import App from '~/components/molecules/App'
import { Recipe } from '~/domain/recipe/Recipe'
import { RecipeStep } from '~/domain/recipe/RecipeWizard'
import { AppDefinition } from '~/domain/workflow/app/AppDefinition'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import { getTargetDefinition } from '~/presentation/recipe/create/utils'
import { MapLike } from '~/presentation/useMapState'
import { scrollbarStyle } from '~/styles/scrollbar'
import * as vars from '~/styles/variables'

export type RecipeWizardValidationStatus = 'pending' | 'invalid' | 'valid'

const RecipeWizardNavigator: React.FC<{
  recipe: Recipe
  steps: RecipeStep[]
  currentStepNumber: number
  validationResults: MapLike<RecipeWizardValidationStatus>
  onStepClick: (stepNumber: number) => void
}> = (props) => {
  const definitions = useDefinitions()
  const ref = React.useRef<HTMLDivElement>(null)
  const currentItemRef = React.useRef<HTMLDivElement>(null)

  // currentStepNumber が変わった時に currentItem までスクロールする
  React.useEffect(() => {
    if (ref.current === null) {
      return
    }
    if (currentItemRef.current === null) {
      return
    }
    ref.current.scrollTo({
      top: currentItemRef.current.offsetTop,
      behavior: 'smooth',
    })
  }, [props.currentStepNumber])

  return (
    <Container ref={ref}>
      {props.steps.map((step, i) => {
        const target = getTargetDefinition(props.recipe, step, definitions)
        const app = definitions.getApp(target.appId)
        return (
          // ステップは動的に追加されたり削除されたりしないため、ステップ番号である i を key として使用できる
          <div
            ref={props.currentStepNumber === i ? currentItemRef : undefined}
            key={i}
            style={{
              position: 'relative',
              padding: vars.space.m,
            }}
          >
            <RecipeWizardNavigatorItem
              app={app}
              label={target.name}
              active={i === props.currentStepNumber}
              validationStatus={props.validationResults[String(i)]}
              onClick={() => props.onStepClick(i)}
            />
            {i < props.steps.length - 1 && (
              // 細かいスタイルのため決め打ち（周囲の余白等変更したときはここも変更すること）
              <div
                style={{
                  position: 'absolute',
                  width: 2,
                  height: 32,
                  backgroundColor: vars.colorPalette.gray3,
                  right: 34,
                  bottom: -16,
                }}
              />
            )}
          </div>
        )
      })}
    </Container>
  )
}

export default RecipeWizardNavigator

const Container = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',
  overflowY: 'scroll',
  ...scrollbarStyle,
})

const RecipeWizardNavigatorItem: React.FC<{
  app: AppDefinition
  label: string
  active: boolean
  validationStatus?: RecipeWizardValidationStatus
  onClick: () => void
}> = (props) => {
  const shadowColor = React.useMemo(() => Color(props.app.color).alpha(0.3), [
    props.app.color,
  ])
  const appIconShadowProps = useSpring({
    boxShadow: props.active
      ? `0px 0px 0px 4px ${shadowColor}`
      : `0px 0px 0px 0px ${shadowColor}`,
    config: {
      tension: 500,
    },
  })
  return (
    <ItemContainer onClick={() => props.onClick()}>
      <ItemLabelContainer>
        <Text
          element="span"
          fontSize="xs"
          textAlign="right"
          fontWeight="bold"
          lineHeight="heading"
        >
          {props.app.name}
        </Text>
        <Text
          element="span"
          fontSize="xs"
          color="tertiary"
          textAlign="right"
          lineHeight="heading"
        >
          {props.label}
        </Text>
      </ItemLabelContainer>
      <AppIconContainer>
        <animated.div
          style={{
            borderRadius: '50%',
            overflow: 'hidden',
            ...appIconShadowProps,
          }}
        >
          <App icon={props.app.iconPath} color={props.app.color} />
        </animated.div>
        {props.validationStatus !== undefined && (
          <ValidationBadge status={props.validationStatus} />
        )}
      </AppIconContainer>
    </ItemContainer>
  )
}

const ValidationBadge: React.FC<{ status: RecipeWizardValidationStatus }> = (
  props
) => {
  switch (props.status) {
    case 'pending':
      return <ValidationLoadingBadge />
    case 'invalid':
      return <ValidationInvalidBadge />
    case 'valid':
      return <ValidationValidBadge />
    default:
      assertNever(props.status)
  }
}

const ValidationLoadingBadge = () => {
  return (
    <ValidationBadgeContainer color={vars.color.offWhite}>
      <Loader size={12} />
    </ValidationBadgeContainer>
  )
}

const ValidationInvalidBadge = () => {
  return (
    <ValidationBadgeContainer color={vars.color.error}>
      <Icon.X color={vars.color.white} size={12} strokeWidth={4} />
    </ValidationBadgeContainer>
  )
}

const ValidationValidBadge = () => {
  return (
    <ValidationBadgeContainer color={vars.color.success}>
      <Icon.Check color={vars.color.white} size={12} strokeWidth={4} />
    </ValidationBadgeContainer>
  )
}

const ValidationBadgeContainer = styled('div')(
  {
    width: 20,
    height: 20,
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate(35%, -35%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },
  (props: { color: string }) => ({
    backgroundColor: props.color,
  })
)

const ItemContainer = styled('div')({
  height: 48,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  justifyContent: 'flex-end',
})

const ItemLabelContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontSize: vars.fontSize.s,
  marginRight: vars.space.m,
})

const AppIconContainer = styled('div')({
  position: 'relative',
})
