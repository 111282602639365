import styled from '@emotion/styled'
import * as React from 'react'

import { assertNever } from '~/common/utils'
import MiniSwitch from '~/components/atoms/MiniSwitch'
import Text from '~/components/atoms/Text'
import Tooltip from '~/components/utils/Tooltip'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { InputWidgetProps } from '~/presentation/workflow/detail/editor/form/inputWidget/InputWidget'
import { RawInputWidget } from '~/presentation/workflow/detail/editor/form/inputWidget/RawInputWidget'
import RenderWidget from '~/presentation/workflow/detail/editor/form/inputWidget/render/RenderWidget'
import * as vars from '~/styles/variables'

const RenderableInputWidget: React.FC<InputWidgetProps> = (props) => {
  // 特定の formType は強制的に RenderWidget を使う
  const forceUseRenderWidget = React.useMemo(() => {
    return (
      props.definition.formType === 'text' ||
      props.definition.formType === 'number' ||
      props.definition.formType === 'multiline_text'
    )
  }, [props.definition.formType])

  // 強制的に mode: 'render' にコンバートした値
  const convertedRenderValue = React.useMemo<InputValue.Render>(() => {
    if (props.value === undefined) {
      return {
        mode: 'render',
        template: [],
      }
    }
    if (props.value.mode === 'render') {
      return props.value
    }
    switch (typeof props.value.raw) {
      case 'string':
        return { mode: 'render', template: [props.value.raw] }
      case 'number':
        return { mode: 'render', template: [String(props.value.raw)] }
      case 'boolean':
        // TODO: True, False に変換
        // 2020年5月14日現在、forceUseRenderWidget に boolean が入力できるウィジェットがないので
        // ここには入らないはず
        return { mode: 'render', template: [] }
      default:
        // List や Dict のケースは RenderableElement への変換が容易ではないため一旦無視
        return { mode: 'render', template: [] }
    }
  }, [props.value])

  const handleRenderModeChange = (changeToEnable: boolean) => {
    if (changeToEnable) {
      props.onChange({
        mode: 'render',
        template: [],
      })
    } else {
      props.onChange(undefined)
    }
  }

  const renderWidget = () => {
    if (forceUseRenderWidget) {
      return <RenderWidget {...props} value={convertedRenderValue} />
    }
    if (props.value !== undefined && props.value.mode === 'render') {
      return <RenderWidget {...props} value={props.value} />
    }
    if (props.value === undefined || props.value.mode === 'raw') {
      return <RawInputWidget {...props} value={props.value} />
    }
    assertNever(props.value)
  }

  return (
    <Container>
      {!forceUseRenderWidget ? (
        <SwitchContainer
          disabled={props.readonly}
          onClick={() => {
            if (props.readonly) {
              return
            }
            handleRenderModeChange(!(props.value?.mode === 'render'))
          }}
        >
          <_Tooltip renderContent={() => '変数を使用するかどうか'}>
            <Text
              element="span"
              fontSize="xs"
              color="secondary"
              lineHeight="just"
              style={{ marginRight: vars.space.xs }}
            >
              変数
            </Text>
            <MiniSwitch value={props.value?.mode === 'render'} />
          </_Tooltip>
        </SwitchContainer>
      ) : null}
      {renderWidget()}
    </Container>
  )
}

const Container = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const SwitchContainer = styled('div')(
  {
    position: 'absolute',
    top: -26,
    right: 0,
  },
  (props: { disabled: boolean }) => ({
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    opacity: props.disabled ? 0.5 : 1,
  })
)

const _Tooltip = styled(Tooltip)({
  display: 'flex',
  alignItems: 'center',
})

export default RenderableInputWidget
