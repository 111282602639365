import React from 'react'
import { X } from 'react-feather'

import Loader from '~/components/atoms/Loader'
import MiniButton from '~/components/atoms/MiniButton'
import Text from '~/components/atoms/Text'
import SearchField from '~/components/molecules/SearchField'
import UserComponent from '~/components/molecules/User'
import Modal from '~/components/organisms/Modal'
import {
  Table,
  Body as Tbody,
  Cell as Td,
  Row as Tr,
} from '~/components/organisms/Table'
import { User } from '~/domain/user/User'
import { useMe } from '~/presentation/AnyflowAppContext'
import { useGroupMemberCandidates } from '~/presentation/group/useGroupMemberCandidates'
import { scrollbarStyle } from '~/styles/scrollbar'
import * as vars from '~/styles/variables'

export const AddMemberModal: React.FC<{
  members: User[]
  isOpen: boolean
  addingUserIds: string[]
  handleClose: () => void
  handleAddMember: (userId: string) => void
}> = (props) => {
  const { handleClose } = props
  const me = useMe()
  const { result } = useGroupMemberCandidates(me.organization.id)
  const [query, setQuery] = React.useState<string>('')
  return (
    <Modal isOpened={props.isOpen} onClose={handleClose} lightDimmer={true}>
      <div
        style={{
          position: 'relative',
          paddingTop: vars.space.l,
          paddingRight: vars.space.l,
          paddingBottom: vars.space.xl,
          paddingLeft: vars.space.l,
          width: 800,
        }}
      >
        <Text element="h1" fontSize="xl" lineHeight="heading" fontWeight="bold">
          ユーザーをグループに追加
        </Text>
        <X
          color={vars.color.icon}
          size={22}
          onClick={handleClose}
          style={{
            position: 'absolute',
            top: vars.space.m * 1.5,
            right: vars.space.m * 1.5,
            cursor: 'pointer',
          }}
        />
        <div style={{ marginTop: vars.space.m * 1.5 }}>
          <SearchField
            value={query}
            placeholder="ユーザーを検索"
            onChange={(newValue) => setQuery(newValue)}
          />
        </div>
        <div
          style={{
            height: 300,
            marginTop: vars.space.l,
            overflowY: 'scroll',
            ...scrollbarStyle,
          }}
        >
          {result.loading ? (
            <Loading />
          ) : result.error ? (
            <Error message="組織内のユーザーの取得に失敗しました" />
          ) : (
            <Candidates
              query={query}
              members={props.members}
              candidates={result.data}
              addingUserIds={props.addingUserIds}
              handleAdd={props.handleAddMember}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}

const Candidates: React.FC<{
  members: User[]
  candidates: User[]
  query: string
  addingUserIds: string[]
  handleAdd: (userId: string) => void
}> = (props) => {
  const filteredCandidates = React.useMemo(() => {
    return props.candidates.filter(
      (user) =>
        user.username.includes(props.query) || user.email?.includes(props.query)
    )
  }, [props.candidates, props.query])

  const memberIdSet = React.useMemo(
    () => new Set(props.members.map((it) => it.id)),
    [props.members]
  )

  if (props.candidates.length === 0) {
    return <Error message="組織内に追加できるユーザーはいません" />
  }

  if (filteredCandidates.length === 0) {
    return <Error message="条件に一致するユーザーはいません" />
  }

  return (
    <Table>
      <Tbody>
        {filteredCandidates.map((user) => (
          <Tr key={user.id}>
            <Td>
              <div style={{ paddingLeft: vars.space.s }}>
                <UserComponent username={user.username} fontWeight="bold" />
              </div>
            </Td>
            <Td style={{ width: 380 }}>{user.email ?? '-'}</Td>
            <Td style={{ width: 74 }}>
              <AddButton
                userId={user.id}
                isMember={memberIdSet.has(user.id)}
                isAdding={props.addingUserIds.includes(user.id)}
                handleClick={props.handleAdd}
              />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

const AddButton: React.FC<{
  userId: string
  isMember: boolean
  isAdding: boolean
  handleClick: (userId: string) => void
}> = (props) => {
  const [hover, setHover] = React.useState<boolean>(false)

  if (props.isMember) {
    return <MiniButton style={{ width: 74 }}>追加済</MiniButton>
  }

  if (props.isAdding) {
    return (
      <MiniButton
        isLoading={true}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{ width: 74 }}
      />
    )
  }

  if (hover) {
    return (
      <MiniButton
        onClick={() => props.handleClick(props.userId)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{ width: 74 }}
      >
        追加
      </MiniButton>
    )
  }

  return (
    <MiniButton
      role="secondary"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ width: 74 }}
    >
      未追加
    </MiniButton>
  )
}

const Loading: React.FC = () => {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Loader />
    </div>
  )
}

const Error: React.FC<{ message: string }> = (props) => {
  return (
    <div>
      <Text element="p" color="tertiary" textAlign="center">
        {props.message}
      </Text>
    </div>
  )
}
