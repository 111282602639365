import styled from '@emotion/styled'
import React from 'react'
import Helmet from 'react-helmet'
import { useParams } from 'react-router-dom'

import Button from '~/components/atoms/Button'
import { DropdownMenuItem } from '~/components/atoms/DropdownMenu'
import Loader from '~/components/atoms/Loader'
import MoreActionsButtonWithDropdownMenu from '~/components/molecules/MoreActionsButtonWithDropdownMenu'
import UserComponent from '~/components/molecules/User'
import Inner from '~/components/organisms/Inner'
import NoResults from '~/components/organisms/NoResults'
import SingleLineEditor from '~/components/organisms/SingleLineEditor'
import {
  Table,
  Body as Tbody,
  Cell as Td,
  HeaderCell as Th,
  Header as Thead,
  Row as Tr,
} from '~/components/organisms/Table'
import SidebarTemplate from '~/components/templates/SidebarTemplate'
import { Group } from '~/domain/group/Group'
import { User } from '~/domain/user/User'
import { useAnyflowAppContext } from '~/presentation/AnyflowAppContext'
import { useToaster } from '~/presentation/ToasterContext'
import { AddMemberModal } from '~/presentation/group/AddMemberModal'
import { useGroup } from '~/presentation/group/useGroup'
import { GROUP_LIST } from '~/routes'
import * as vars from '~/styles/variables'

const actions: DropdownMenuItem[] = [
  {
    id: 'remove',
    role: 'danger',
    label: 'グループから外す',
  },
]

const GroupDetailPage: React.FC = () => {
  const params = useParams<{ groupId: string }>()
  return (
    <SidebarTemplate>
      <_Inner>
        <Content groupId={params.groupId} />
      </_Inner>
    </SidebarTemplate>
  )
}

const Content: React.FC<{ groupId: string }> = (props) => {
  const { me, refreshMe } = useAnyflowAppContext()
  const toaster = useToaster()
  const [isAddModalOpen, setIsAddModalOpen] = React.useState<boolean>(false)
  const {
    result,
    addMember,
    addingUserIds,
    removeMember,
    removingUserIds,
    updateName,
  } = useGroup(props.groupId)

  const handleAddMember = React.useCallback(
    async (userId: string) => {
      try {
        await addMember(userId)
        if (userId === me.id) {
          // 所属グループ一覧を更新するために Me を取得し直す
          await refreshMe()
        }
      } catch (e) {
        console.error(e)
        toaster.showError('メンバーの追加に失敗しました')
      }
    },
    [addMember, toaster, me, refreshMe]
  )

  const handleRemoveMember = React.useCallback(
    async (userId: string) => {
      if (!confirm('メンバーを削除してもよろしいですか？')) {
        return
      }
      try {
        await removeMember(userId)
        if (userId === me.id) {
          // 所属グループ一覧を更新するために Me を取得し直す
          await refreshMe()
        }
      } catch (e) {
        console.error(e)
        toaster.showError('メンバーの削除に失敗しました')
      }
    },
    [removeMember, toaster, me, refreshMe]
  )

  const handleCloseModal = React.useCallback(() => setIsAddModalOpen(false), [])

  const handleUpdateGroupName = React.useCallback(
    async (newName: string) => {
      try {
        await updateName(newName)
      } catch (e) {
        console.error(e)
        toaster.showError('グループ名の変更に失敗しました')
      }
    },
    [updateName, toaster]
  )

  if (result.loading) {
    return <MyLoader />
  }

  if (result.error) {
    return (
      <Error
        heading="エラーが発生しました"
        description="時間をおいてから再度お試しください。それでも解決しない場合、お手数ですが画面右下のボタンよりお問い合わせください。"
      />
    )
  }

  if (result.data === undefined) {
    return (
      <Error
        heading="グループが見つかりませんでした"
        description="削除されたか URL が間違っている可能性があります"
      />
    )
  }

  return (
    <div>
      <Helmet>
        <title>{result.data.name}</title>
      </Helmet>
      <Header>
        <div style={{ flex: 1 }}>
          <SingleLineEditor
            editorInitialText={result.data.name}
            onSubmit={handleUpdateGroupName}
          >
            <Heading>{result.data.name}</Heading>
          </SingleLineEditor>
        </div>
        <Button onClick={() => setIsAddModalOpen(true)}>ユーザーを追加</Button>
      </Header>
      <div style={{ marginTop: vars.space.l }}>
        <GroupTable
          group={result.data}
          removingUserIds={removingUserIds}
          handleRemoveMember={handleRemoveMember}
        />
        <AddMemberModal
          isOpen={isAddModalOpen}
          members={result.data.members}
          addingUserIds={addingUserIds}
          handleAddMember={handleAddMember}
          handleClose={handleCloseModal}
        />
      </div>
    </div>
  )
}

const MyLoader: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Loader />
    </div>
  )
}

const Error: React.FC<{ heading: string; description: string }> = (props) => {
  return (
    <div>
      <NoResults
        heading={props.heading}
        description={props.description}
        renderFooter={() => (
          <Button to={GROUP_LIST} style={{ marginTop: vars.space.m }}>
            グループ一覧へ
          </Button>
        )}
      />
    </div>
  )
}

const GroupTable: React.FC<{
  group: Group
  removingUserIds: string[]
  handleRemoveMember: (userId: string) => void
}> = (props) => {
  if (props.group.members.length === 0) {
    return (
      <NoResults
        heading="メンバーはまだいません"
        description="最初のメンバーを追加してみましょう"
      />
    )
  }

  return (
    <_Table>
      <Thead>
        <Tr>
          <_Th>所属ユーザー</_Th>
          <_Th style={{ width: 50 }}>{null}</_Th>
        </Tr>
      </Thead>
      <Tbody>
        {props.group.members.map((member) => (
          <GroupTableRow
            key={member.id}
            user={member}
            handleRemoveMember={props.handleRemoveMember}
            removing={props.removingUserIds.includes(member.id)}
          />
        ))}
      </Tbody>
    </_Table>
  )
}

const GroupTableRow: React.FC<{
  user: User
  handleRemoveMember: (userId: string) => void
  removing: boolean
}> = (props) => {
  const { handleRemoveMember } = props

  const handleMenuItemClick = React.useCallback(
    (item: DropdownMenuItem) => {
      if (item.id === 'remove') {
        handleRemoveMember(props.user.id)
        return
      }
      console.error(`Unknown menu item: ${item.id}`)
    },
    [handleRemoveMember, props.user.id]
  )

  return (
    <Tr style={{ opacity: props.removing ? 0.5 : 1 }}>
      <_Td>
        <div style={{ marginLeft: vars.space.s, fontWeight: 'bold' }}>
          <UserComponent fontWeight="bold" username={props.user.username} />
        </div>
      </_Td>
      <Td>
        <MoreActionsButtonWithDropdownMenu
          handleMenuItemClick={handleMenuItemClick}
          horizontalAlign={'left'}
          items={actions}
        />
      </Td>
    </Tr>
  )
}

const _Inner = styled(Inner)({
  padding: vars.space.l,
  margin: 'unset',
  width: '100%',
})

const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const Heading = styled('h1')({
  margin: 0,
  fontSize: vars.fontSize.xxl,
})

const _Table = styled(Table)({
  tableLayout: 'fixed',
})

const _Th = styled(Th)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const _Td = styled(Td)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

export default GroupDetailPage
