import { Pagination } from '~/common/Pagination'
import { delay } from '~/common/utils'
import { PaginationJson } from '~/data/PaginationJson'
import { mapJsonToPaginationMeta } from '~/data/mapJsonToPaginationMeta'
import {
  WorkflowInstanceJson,
  WorkflowInstanceSummaryJson,
} from '~/data/workflow/instance/json'
import { mapJsonToWorkflowInstance } from '~/data/workflow/instance/mapJsonToWorkflowInstance'
import { mapJsonToWorkflowInstanceSummary } from '~/data/workflow/instance/mapJsonToWorkflowInstanceSummary'
import { TaskSourceJson, TriggerSourceJson } from '~/data/workflow/source/json'
import { WorkflowInstance } from '~/domain/workflow/instance/WorkflowInstance'
import { WorkflowInstanceService } from '~/domain/workflow/instance/WorkflowInstanceService'
import { WorkflowInstanceSummary } from '~/domain/workflow/instance/WorkflowInstanceSummary'

export class MockWorkflowInstanceService implements WorkflowInstanceService {
  async get(): Promise<WorkflowInstance> {
    return mapJsonToWorkflowInstance(detailData)
  }

  async getList(): Promise<Pagination<WorkflowInstanceSummary>> {
    await delay(1000)
    return {
      paginationMeta: mapJsonToPaginationMeta(listData),
      items: listData.results.map((workflowInstanceSummaryJson) => {
        return mapJsonToWorkflowInstanceSummary(workflowInstanceSummaryJson)
      }),
    }
  }
}

const trigger: TriggerSourceJson = {
  triggerId: 'GenericApp__StandardTrigger',
  inputs: [
    {
      key: 'default_runner_slug',
      value: {
        mode: 'raw',
        raw: '',
      },
    },
    {
      key: 'is_always_default_runner',
      value: {
        mode: 'raw',
        raw: true,
      },
    },
  ],
}

const task: TaskSourceJson = {
  taskId: 'aaa',
  actionId: 'GoogleSpreadsheetApp__Read',
  inputs: [],
  tasks: [],
}

const detailData: WorkflowInstanceJson = {
  slug: 'history1',
  state: 'running',
  updatedAt: '2020-02-25T18:55:00+9:00',
  runner: {
    slug: 'abc',
    username: 'nakanishy',
    organization: {
      slug: 'anyflow',
      name: 'Anyflow',
    },
    isAdmin: false,
  },
  frozenSource: {
    trigger,
    task,
  },
  workflow: {
    slug: 'foo',
    name: '',
    description: '',
    isEnabled: true,
    updatedAt: '2020-02-25T18:55:00+9:00',
    permissionPolicy: 'ownable',
    source: {
      slug: 'source',
      name: 'source',
      editor: {
        slug: 'user1',
        username: '',
        organization: {
          slug: 'anyflow',
          name: 'Anyflow',
        },
        isAdmin: false,
      },
      prevVersion: null,
      formatVersion: '1.0.0',
      body: {},
    },
  },
}

const listData: PaginationJson<WorkflowInstanceSummaryJson> = {
  count: 100,
  current: 1,
  limit: 10,
  results: [
    {
      slug: 'history1',
      state: 'running',
      updatedAt: '2020-02-25T18:55:00+9:00',
      runner: {
        slug: 'abc',
        username: 'nakanishy',
        organization: {
          slug: 'anyflow',
          name: 'Anyflow',
        },
        isAdmin: false,
      },
    },
  ],
}
