import styled from '@emotion/styled'
import * as React from 'react'
import { Helmet } from 'react-helmet'

import Footer from '~/components/organisms/Footer'
import Header from '~/components/organisms/Header'
import * as vars from '~/styles/variables'

interface Props {
  headerMargin?: boolean
  invertedHeader?: boolean
}

export const StaticTemplate: React.FC<Props> = ({
  headerMargin = true,
  invertedHeader = false,
  children,
}) => {
  return (
    <Container>
      <Helmet
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
        ]}
      />
      <Header invert={invertedHeader} />
      <Contents headerMargin={headerMargin}>{children}</Contents>
      <_Footer />
    </Container>
  )
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: vars.color.white,
})

const Contents = styled('div')(
  {
    flex: 'auto',
  },
  (props: { headerMargin: boolean }) => ({
    marginTop: props.headerMargin ? vars.height.lpHeader : 0,
  })
)

const _Footer = styled(Footer)({
  marginTop: 'auto',
})
