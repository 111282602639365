import {
  StructValueTypeJson,
  ValueTypeJson,
} from '~/data/workflow/valueType/ValueTypeJson'
import { StructValueType, ValueType } from '~/domain/ValueType'

export function mapJsonToValueType(json: ValueTypeJson): ValueType {
  switch (json.typeName) {
    case 'string': {
      return {
        typeName: json.typeName,
        nullable: json.nullable,
      }
    }
    case 'number': {
      return {
        typeName: json.typeName,
        nullable: json.nullable,
        canDecimal: json.canDecimal,
      }
    }
    case 'boolean': {
      return {
        typeName: json.typeName,
        nullable: json.nullable,
      }
    }
    case 'file': {
      return {
        typeName: json.typeName,
        nullable: json.nullable,
      }
    }
    case 'any': {
      return {
        typeName: json.typeName,
        nullable: json.nullable,
      }
    }
    case 'list': {
      return {
        typeName: json.typeName,
        nullable: json.nullable,
        itemType: mapJsonToValueType(json.itemType),
        length: json.length ?? undefined,
      }
    }
    case 'struct': {
      return {
        typeName: json.typeName,
        nullable: json.nullable,
        entries: json.entries.map(mapJsonToStructValueTypeEntry),
      }
    }
    case 'dict': {
      return {
        typeName: json.typeName,
        nullable: json.nullable,
        valueType: mapJsonToValueType(json.valueType),
      }
    }
  }
}

function mapJsonToStructValueTypeEntry(
  json: StructValueTypeJson.Entry
): StructValueType.Entry {
  return {
    key: json.key,
    valueType: mapJsonToValueType(json.valueType),
  }
}
