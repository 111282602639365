import styled from '@emotion/styled'
import * as React from 'react'
import { formatRoute } from 'react-router-named-routes'

import Button from '~/components/atoms/Button'
import * as routes from '~/routes'
import * as vars from '~/styles/variables'

const NoResults: React.FC = () => (
  <Container>
    <Heading>404 Not Found</Heading>
    <Description>
      お探しのワークフローは見つかりませんでした。
      <br />
      削除されたか、URLが間違っている可能性があります。
    </Description>
    <_Button to={formatRoute(routes.WORKFLOW_LIST)}>
      ワークフローリストへ
    </_Button>
  </Container>
)

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: -50,
  width: '100%',
  height: '100%',
})

const Heading = styled('h2')({
  fontSize: vars.fontSize.xxxl,
})

const Description = styled('p')({
  marginTop: vars.space.m,
  textAlign: 'center',
  lineHeight: 2,
})

const _Button = styled(Button)({
  marginTop: vars.space.l,
})

export default NoResults
