import dayjs from 'dayjs'

import { Pagination } from '~/common/Pagination'
import { LookupTableApi } from '~/data/lookupTable/LookupTableApi'
import { LookupTableColumnSchemaJson } from '~/data/lookupTable/LookupTableJson'
import {
  mapJsonToLookupTableRow,
  mapJsonToLookupTableSummary,
} from '~/data/lookupTable/mapJsonToLookupTable'
import { mapModelToLookupTableCellJson } from '~/data/lookupTable/mapModelToLookupTableJson'
import { Definitions } from '~/domain/Definitions'
import { LookupTable } from '~/domain/lookupTable/LookupTable'
import { LookupTableCell } from '~/domain/lookupTable/LookupTableCell'
import { LookupTableColumnSchema } from '~/domain/lookupTable/LookupTableColumnSchema'
import { LookupTableRow } from '~/domain/lookupTable/LookupTableRow'
import { LookupTableService } from '~/domain/lookupTable/LookupTableService'
import { LookupTableSummary } from '~/domain/lookupTable/LookupTableSummary'

export class LookupTableServiceImpl implements LookupTableService {
  constructor(
    private readonly api: LookupTableApi,
    private readonly definitions: Definitions
  ) {}

  async get(id: string, pageNumber: number): Promise<LookupTable | undefined> {
    const tableJson = await this.api.get(id)
    if (tableJson === undefined) {
      return undefined
    }
    const schemasJson = await this.api.getColumnSchemas(id)
    const rows = await this.getRows(id, pageNumber)
    return new LookupTable(
      id,
      tableJson.name,
      tableJson.description,
      dayjs(tableJson.updatedAt).toDate(),
      schemasJson.map((json) => this.mapJsonToLookupTableColumnSchema(json)),
      rows.items,
      rows.paginationMeta
    )
  }

  private async getRows(
    id: string,
    pageNumber: number
  ): Promise<Pagination<LookupTableRow>> {
    const jsons = await this.api.getRows(id, pageNumber)
    return {
      paginationMeta: jsons.paginationMeta,
      items: jsons.items.map(mapJsonToLookupTableRow),
    }
  }

  async getList(pageNumber: number): Promise<Pagination<LookupTableSummary>> {
    const json = await this.api.getList(pageNumber)
    return {
      paginationMeta: json.paginationMeta,
      items: json.items.map(mapJsonToLookupTableSummary),
    }
  }

  async create(name: string): Promise<string> {
    const tableJson = await this.api.create(name)
    return tableJson.slug
  }

  async updateColumnSchema(
    lookupTableId: string,
    columnSchema: LookupTableColumnSchema
  ): Promise<LookupTableColumnSchema> {
    const schemaJson = await this.api.updateColumnSchema(lookupTableId, {
      id: columnSchema.id,
      label: columnSchema.label,
      appId: columnSchema.app?.appId ?? '',
    })
    return this.mapJsonToLookupTableColumnSchema(schemaJson)
  }

  async updateTableName(
    lookupTableId: string,
    tableName: string
  ): Promise<string> {
    const json = await this.api.updateTableName(lookupTableId, tableName)
    return json.name
  }

  async insertRow(
    lookupTableId: string,
    cells: LookupTableCell[]
  ): Promise<LookupTableRow> {
    const reqJson = cells.map(mapModelToLookupTableCellJson)
    const resJson = await this.api.insertRow(lookupTableId, reqJson)
    return mapJsonToLookupTableRow(resJson)
  }

  async deleteRow(lookupTableId: string, rowId: number): Promise<void> {
    await this.api.deleteRow(lookupTableId, rowId)
  }

  private mapJsonToLookupTableColumnSchema(
    json: LookupTableColumnSchemaJson
  ): LookupTableColumnSchema {
    return {
      id: json.id,
      label: json.label,
      app: json.appId !== '' ? this.definitions.getApp(json.appId) : undefined,
    }
  }
}
