import styled from '@emotion/styled'
import React from 'react'
import * as Icon from 'react-feather'

import Button from '~/components/atoms/Button'
import Text from '~/components/atoms/Text'
import MessageItem from '~/components/molecules/MessageItem'
import NoResults from '~/components/organisms/NoResults'
import { TestResult } from '~/domain/workflow/test/TestResult'
import { scrollbarStyle } from '~/styles/scrollbar'
import * as vars from '~/styles/variables'

interface Props {
  result: TestResult
  onCloseClick: () => void
  onRetestClick: () => void
}

const NodeTestFailure: React.FC<Props> = (props) => {
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            width: 40,
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: vars.space.m,
            borderRadius: '50%',
            backgroundColor: vars.color.error,
          }}
        >
          <Icon.X color={vars.color.white} strokeWidth={4} size={22} />
        </div>
        <Text element="p" fontSize="l" fontWeight="bold" lineHeight="heading">
          テスト実行に失敗しました
        </Text>
      </div>
      <Text element="p" fontSize="s" style={{ marginTop: vars.space.m }}>
        テスト実行中にエラーが発生しました。エラー内容を確認してください。
      </Text>
      <ResultMessagesBox>
        {props.result.messages.length === 0 ? (
          <NoResults
            heading="メッセージがありません"
            description="メッセージがある場合ここに表示されます"
          />
        ) : (
          props.result.messages.map((message, i) => (
            <MessageRow key={i}>
              <MessageItem message={message} />
            </MessageRow>
          ))
        )}
      </ResultMessagesBox>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: vars.space.m * 1.5,
        }}
      >
        <Button type="secondary" onClick={props.onRetestClick}>
          再実行
        </Button>
        <Button type="tertiary" onClick={props.onCloseClick}>
          閉じる
        </Button>
      </div>
    </div>
  )
}

const ResultMessagesBox = styled('div')({
  height: 230,
  marginTop: vars.space.m,
  padding: vars.space.m,
  backgroundColor: vars.colorPalette.gray0,
  borderRadius: vars.borderRadius.m,
  overflowY: 'scroll',
  ...scrollbarStyle,
})

const MessageRow = styled('div')({
  marginTop: vars.space.m,
  '&:first-of-type': {
    marginTop: 0,
  },
})

export default NodeTestFailure
