import _ from 'lodash'
import * as uuid from 'uuid'

import { Pagination } from '~/common/Pagination'
import { delay, failRandomly } from '~/common/utils'
import { Group } from '~/domain/group/Group'
import { GroupService } from '~/domain/group/GroupService'
import { GroupSummary } from '~/domain/group/GroupSummary'
import { Organization } from '~/domain/user/Organization'
import { User } from '~/domain/user/User'

const mockOrganization: Organization = {
  id: 'mock_organization',
  name: 'モック組織',
}

const groups: Group[] = [
  new Group({
    id: 'group1',
    name: 'モックグループ1',
    workflowCount: 8,
    members: [
      {
        id: 'user_1',
        username: 'モックユーザー1',
        organization: mockOrganization,
        isAdmin: true,
      },
      {
        id: 'user_2',
        username: 'モックユーザー2',
        organization: mockOrganization,
        isAdmin: false,
      },
    ],
    updatedAt: new Date(),
  }),
  new Group({
    id: 'group2',
    name: 'モックグループ2',
    workflowCount: 8,
    members: [],
    updatedAt: new Date(),
  }),
]

const countPerPage = 1

export class MockGroupService implements GroupService {
  async getGroups(page: number): Promise<Pagination<GroupSummary>> {
    await delay(250)
    failRandomly(0.2)
    return {
      items: _.chunk(groups, countPerPage)[page - 1] ?? [],
      paginationMeta: {
        totalCount: groups.length,
        countPerPage: countPerPage,
        currentPageNumber: page,
      },
    }
  }

  async getAllGroups(): Promise<GroupSummary[]> {
    await delay(3000)
    failRandomly(0.2)
    return [...groups]
  }

  async getGroup(id: string): Promise<Group | undefined> {
    await delay(1500)
    failRandomly(0.2)
    return groups.find((it) => it.id === id)
  }

  async createGroup(name: string): Promise<GroupSummary> {
    console.log(`[API] createGroup called\nname: ${name}`)
    await delay(1200)
    failRandomly(0.7)
    const group = new Group({
      id: uuid.v4(),
      name,
      members: [],
      workflowCount: 0,
      updatedAt: new Date(),
    })
    groups.push(group)
    return group
  }

  async updateGroupName(id: string, name: string): Promise<void> {
    await delay(1500)
    const i = groups.findIndex((it) => it.id === id)
    if (i < 0) {
      throw new Error(`No such group: ${id}`)
    }
    groups[i] = new Group({ ...groups[i], name })
  }

  async deleteGroup(id: string): Promise<void> {
    await delay(2000)
    const i = groups.findIndex((it) => it.id === id)
    if (i < 0) {
      throw new Error(`No such group: ${id}`)
    }
    groups.splice(i, 1)
  }

  async addUser(groupId: string, userId: string): Promise<User> {
    await delay(1500)
    const i = groups.findIndex((it) => it.id === groupId)
    if (i < 0) {
      throw new Error(`No such group: ${groupId}`)
    }
    const user = {
      id: userId,
      username: `モックユーザー (${userId})`,
      organization: { id: 'mock_organization', name: 'モック組織' },
      isAdmin: false,
    }
    groups[i] = groups[i].addMember(user)
    return user
  }

  async removeUser(groupId: string, userId: string): Promise<void> {
    await delay(1500)
    const i = groups.findIndex((it) => it.id === groupId)
    if (i < 0) {
      throw new Error(`No such group: ${groupId}`)
    }
    groups[i] = groups[i].removeMember(userId)
  }
}
