import produce from 'immer'
import { createSelector } from 'reselect'
import { actionCreatorFactory } from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { RootState } from '~/ducks'

const actionCreator = actionCreatorFactory('anyflow/triggerSelector')

export const types = {
  RESET: 'RESET',
  SHOW: 'SHOW',
  CHANGE_POSITION: 'CHANGE_POSITION',
  HIDE: 'HIDE',
}

interface ShowRequest {
  top: number
  left: number
}

export const actions = {
  reset: actionCreator(types.RESET),
  show: actionCreator<ShowRequest>(types.SHOW),
  changePosition: actionCreator<{ top: number; left: number }>(
    types.CHANGE_POSITION
  ),
  hide: actionCreator(types.HIDE),
}

export const selectors = {
  getState: createSelector(
    (state: RootState) => state.ui.triggerSelector,
    (state) => state
  ),
}

export type State = {
  isShown: boolean
  top: number
  left: number
}

const initialState: State = {
  isShown: false,
  top: 0,
  left: 0,
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.show, (state, payload) =>
    produce(state, (draft) => {
      draft.isShown = true
      draft.top = payload.top
      draft.left = payload.left
    })
  )
  .case(actions.changePosition, (state, payload) =>
    produce(state, (draft) => {
      draft.top = payload.top
      draft.left = payload.left
    })
  )
  .case(actions.hide, () => initialState)
