import { Middleware, applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga'

import { Env, targetEnv } from '~/common/config'
import rootState from '~/ducks'
import rootSaga from '~/ducks/sagas'

const logger = createLogger({
  diff: true,
  collapsed: true,
})
const sagaMiddleware = createSagaMiddleware()
const middlewares: (Middleware | SagaMiddleware)[] = [sagaMiddleware]

if (targetEnv === Env.local) {
  middlewares.push(logger)
}

const store = createStore(
  rootState,
  targetEnv === Env.local
    ? composeWithDevTools(applyMiddleware(...middlewares))
    : applyMiddleware(...middlewares)
)

sagaMiddleware.run(rootSaga)

export default store
