import styled from '@emotion/styled'
import * as React from 'react'
import ContentLoader from 'react-content-loader'

interface Props {
  className?: string
}

const titleHeight = 16
const labelHeight = 14
const labelMargin = 9

const Loader: React.FC<Props> = ({ className }) => (
  <ContentLoader className={className} speed={1} width={1000} height={42}>
    {/* アイコン */}
    <rect x={0} y={0} rx="4" ry="4" width="40" height="40" />
    {/* タイトル */}
    <rect
      x={8 + 40 + 40}
      y="2"
      rx="2"
      ry="2"
      width="300"
      height={titleHeight}
    />
    {/* ラベル */}
    <rect
      x={8 + 40 + 40}
      y={2 + titleHeight + labelMargin}
      rx="2"
      ry="2"
      width="200"
      height={labelHeight}
    />
  </ContentLoader>
)

const LoaderList: React.FC = () => (
  <>
    <ContentLoader speed={1} width={1000} height={14}>
      <rect x={0} y={0} rx="2" ry="2" width="35" height="14" />
      <rect x={88} y={0} rx="2" ry="2" width="60" height="14" />
    </ContentLoader>
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
  </>
)

const _Loader = styled(Loader)({
  marginTop: 15,
  '&:first-of-type': {
    marginTop: 0,
  },
})

export default LoaderList
