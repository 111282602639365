import styled from '@emotion/styled'
import React from 'react'

import Modal from '~/components/organisms/Modal'
import { TestResult } from '~/domain/workflow/test/TestResult'
import NodeTestFailure from '~/presentation/workflow/detail/editor/test/NodeTestFailure'
import NodeTestSuccess from '~/presentation/workflow/detail/editor/test/NodeTestSuccess'
import * as vars from '~/styles/variables'

interface Props {
  isOpen: boolean
  result: TestResult | undefined
  onCloseClick: () => void
  onRetestClick: () => void
}

const NodeTestResultModal: React.FC<Props> = (props) => {
  return (
    <_Modal
      isOpened={props.isOpen}
      noDimmer={true}
      mountOnOpen={true}
      onClose={props.onCloseClick}
    >
      {props.result === undefined ? (
        <p>テストがまだ実行されていません</p>
      ) : props.result.success ? (
        <NodeTestSuccess
          result={props.result}
          onCloseClick={props.onCloseClick}
        />
      ) : (
        <NodeTestFailure
          result={props.result}
          onCloseClick={props.onCloseClick}
          onRetestClick={props.onRetestClick}
        />
      )}
    </_Modal>
  )
}

const _Modal = styled(Modal)({
  width: 550,
  padding: vars.space.l,
})

export default NodeTestResultModal
