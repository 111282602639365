import styled from '@emotion/styled'
import * as hash from 'object-hash'
import * as React from 'react'

import App from '~/components/molecules/App'
import { ValueType } from '~/domain/ValueType'
import { RenderableEntity } from '~/domain/workflow/source/RenderableElement'
import { VariablesTab } from '~/domain/workflow/variableFinder/VariablesTab'
import ExpectedObjectList from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/ExpectedObjectList'
import * as vars from '~/styles/variables'

interface Props {
  data: VariablesTab
  targetValueTypes: ValueType[]
  onSelect: (entities: RenderableEntity[]) => void
}

const VariablesTabContent: React.FC<Props> = (props) => {
  return (
    <>
      {props.data.sections.map((section, i) => (
        <Section key={hash([section.app.appId, section.label, i])}>
          <Heading>
            <App
              icon={section.app.iconPath}
              color={section.app.color}
              size="s"
            />
            <OperatorName>{section.label}</OperatorName>
          </Heading>
          {section.objects.length > 0 ? (
            <ExpectedObjectList
              expectedObjects={section.objects}
              targetValueTypes={props.targetValueTypes}
              onSelect={props.onSelect}
            />
          ) : (
            <NoContentMessage>使用できる変数はありません</NoContentMessage>
          )}
        </Section>
      ))}
      {props.data.sections.length === 0 && (
        <Section>
          <NoContentMessage>使用できる変数はありません</NoContentMessage>
        </Section>
      )}
    </>
  )
}

const Section = styled('div')({
  marginTop: vars.space.l,
  '&:first-of-type': {
    marginTop: 0,
  },
})

const Heading = styled('h3')({
  display: 'flex',
  alignItems: 'center',
  fontSize: vars.fontSize.s,
  fontWeight: vars.fontWeight.bold,
  marginBottom: vars.space.m,
})

const OperatorName = styled('div')({
  marginLeft: vars.space.s,
})

const NoContentMessage = styled('p')({
  marginTop: vars.space.s,
  color: vars.fontColor.tertiary,
  fontSize: vars.fontSize.s,
})

export default VariablesTabContent
