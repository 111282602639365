import { delay } from '~/common/utils'
import { serverData } from '~/data/workflow/MockWorkflowService'
import { Recipe } from '~/domain/recipe/Recipe'
import { RecipeService } from '~/domain/recipe/RecipeService'

export class MockRecipeService implements RecipeService {
  async get(recipeId: string): Promise<Recipe> {
    await delay(1000)
    return new Recipe(
      recipeId,
      'モックレシピ1',
      'モックdescription1',
      [{ id: '1', label: 'sales' }],
      serverData.workflows[0].source.body
    )
  }

  async getList(): Promise<Recipe[]> {
    await delay(1000)
    return recipes
  }
}

const mockSourceBody = serverData.workflows[0].source.body
const recipes = [
  new Recipe(
    'id_1',
    'モックレシピ1',
    'モックdescription_1(sales)',
    [{ id: 'sales', label: 'sales' }],
    mockSourceBody
  ),
  new Recipe(
    'id_2',
    'モックレシピ2',
    'モックdescription_2(sales)',
    [{ id: 'sales', label: 'sales' }],
    mockSourceBody
  ),
  new Recipe(
    'id_3',
    'モックレシピ3',
    'モックdescription_3',
    [],
    mockSourceBody
  ),
  new Recipe(
    'id_4',
    'モックレシピ4',
    'モックdescription_4(sales)',
    [{ id: 'sales', label: 'sales' }],
    mockSourceBody
  ),
  new Recipe(
    'id_5',
    'モックレシピ5',
    'モックdescription_5',
    [],
    mockSourceBody
  ),
]
