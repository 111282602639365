import * as _ from 'lodash'

import { PaginationMeta } from '~/common/Pagination'
import { LookupTableCell } from '~/domain/lookupTable/LookupTableCell'
import { LookupTableColumnSchema } from '~/domain/lookupTable/LookupTableColumnSchema'
import { LookupTableRow } from '~/domain/lookupTable/LookupTableRow'
import { LookupTableSummary } from '~/domain/lookupTable/LookupTableSummary'
import { AppDefinition } from '~/domain/workflow/app/AppDefinition'

export class LookupTable implements LookupTableSummary {
  private readonly _rows: LookupTableRow[]

  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly description: string,
    public readonly updatedAt: Date,
    public readonly columnSchemas: LookupTableColumnSchema[],
    rows: LookupTableRow[],
    public readonly rowsPagination: PaginationMeta
  ) {
    this._rows = rows.map((row) => ({
      id: row.id,
      cells: sortCells(columnSchemas, row.cells),
    }))
  }

  setColumnSchema(columnSchema: LookupTableColumnSchema): LookupTable {
    return new LookupTable(
      this.id,
      this.name,
      this.description,
      this.updatedAt,
      this.columnSchemas.map((it) => {
        if (it.id === columnSchema.id) {
          return columnSchema
        }
        return it
      }),
      this._rows,
      this.rowsPagination
    )
  }

  addRow(row: LookupTableRow): LookupTable {
    return new LookupTable(
      this.id,
      this.name,
      this.description,
      this.updatedAt,
      this.columnSchemas,
      [row, ...this._rows],
      this.rowsPagination
    )
  }

  removeRow(rowId: number): LookupTable {
    return new LookupTable(
      this.id,
      this.name,
      this.description,
      this.updatedAt,
      this.columnSchemas,
      _.reject(this._rows, (it) => it.id === rowId),
      this.rowsPagination
    )
  }

  get rows(): LookupTableRow[] {
    return this._rows
  }

  get apps(): AppDefinition[] {
    return _.uniqBy(
      _.reject(
        this.columnSchemas.map((it) => it.app),
        _.isUndefined
      ),
      (it) => it.appId
    )
  }
}

function sortCells(
  columnSchemas: LookupTableColumnSchema[],
  cells: LookupTableCell[]
): LookupTableCell[] {
  const map = new Map<string, number>()
  columnSchemas.forEach((it, i) => {
    map.set(String(it.id), i)
  })
  cells.sort((a, b) => {
    return (
      (map.get(String(a.columnSchemaId)) ?? 0) -
      (map.get(String(b.columnSchemaId)) ?? 0)
    )
  })
  return _.sortBy(cells, (cell) => map.get(String(cell.columnSchemaId)) ?? 0)
}
