import { mapJsonToExpectedObject } from '~/data/workflow/expectedObject/mapJsonToExpectedObjects'
import { mapToMessageType } from '~/data/workflow/instance/message/TempMessageService'
import { mapJsonToObjectPropertyValue } from '~/data/workflow/object/mapJsonToObjectDefinition'
import {
  ExpectedObjectSnapshotJson,
  SimplifiedMessageJson,
  TestResultJson,
} from '~/data/workflow/test/json'
import { SimplifiedMessage } from '~/domain/workflow/instance/message/Message'
import { ExpectedObjectKey } from '~/domain/workflow/object/ExpectedObjectKey'
import {
  ExpectedObjectSnapshot,
  TestResult,
} from '~/domain/workflow/test/TestResult'

export function mapJsonToTestResult(json: TestResultJson): TestResult {
  return {
    snapshots: json.snapshots.map(mapJsonToExpectedObjectSnapshot),
    messages: json.messages.map(mapJsonToSimplifiedMessage),
    expectedObjects: json.expectedObjects.map(mapJsonToExpectedObject),
    success: json.isSuccess,
    testedAt: new Date(json.testedAt),
  }
}

function mapJsonToExpectedObjectSnapshot(
  json: ExpectedObjectSnapshotJson
): ExpectedObjectSnapshot {
  return {
    expectedObjectKey: new ExpectedObjectKey(json.expectedObjectKey),
    values: json.values.map(mapJsonToObjectPropertyValue),
  }
}

export function mapJsonToSimplifiedMessage(
  json: SimplifiedMessageJson
): SimplifiedMessage {
  return {
    body: json.body.text,
    type: mapToMessageType(json.typ),
  }
}
