import * as React from 'react'

import { useComposingRef } from '~/hooks/useComposingRef'

interface Props {
  handleEnter: () => void
}

const EnterKeyTrap: React.FC<Props> = (props) => {
  const { handleEnter } = props
  const composingRef = useComposingRef()

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      // 日本語入力中など、 composition 中は無視
      if (composingRef.current) {
        return
      }
      if (e.key === 'Enter') {
        e.preventDefault()
        e.stopPropagation()
        handleEnter()
      }
    },
    [handleEnter, composingRef]
  )

  return (
    <div tabIndex={-1} style={{ outline: 'none' }} onKeyDown={handleKeyDown}>
      {props.children}
    </div>
  )
}

export default EnterKeyTrap
