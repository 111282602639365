import { NodeSpec, Schema } from 'prosemirror-model'

import { RenderableEntity } from '~/domain/workflow/source/RenderableElement'

export type TextNode = {
  type: 'text'
  text: string
}
export type EntityNode = {
  type: 'entity'
  attrs: {
    label: string
    entity: RenderableEntity
  }
}

export type ParagraphContentNode = TextNode | EntityNode

export type ParagraphNode = {
  type: 'paragraph'
  content?: Array<ParagraphContentNode>
}
export type RootNode = {
  type: 'doc'
  content: ParagraphNode[]
}

export function isEntityNode(node: ParagraphContentNode): node is EntityNode {
  return node.type === 'entity'
}

export function isEmpty(node: RootNode): boolean {
  if (node.content.length > 1) {
    return false
  }
  const paragraph = node.content[0]
  if (paragraph.content === undefined || paragraph.content.length === 0) {
    return true
  }
  return false
}

const nodes: { [name: string]: NodeSpec } = {
  doc: {
    content: 'paragraph+',
  },
  paragraph: {
    content: 'inline*',
    group: 'block',
    parseDOM: [{ tag: 'div' }],
    toDOM() {
      return ['div', 0] as const
    },
  },
  text: {
    group: 'inline',
  },
  entity: {
    inline: true,
    group: 'inline',
    selectable: false,
    attrs: {
      label: {},
      entity: {},
    },
  },
}

export const schema = new Schema({ nodes })
