import * as React from 'react'

import { apiClients } from '~/common/apiClients'
import { PendingResult } from '~/common/types'
import { Recipe } from '~/domain/recipe/Recipe'
import { RecipeService } from '~/domain/recipe/RecipeService'

const service: RecipeService = apiClients.recipeService

export function useRecipe(recipeId: string): PendingResult<Recipe> {
  const [result, setResult] = React.useState<PendingResult<Recipe>>(
    PendingResult.pending()
  )

  React.useEffect(() => {
    setResult(PendingResult.pending())
    service
      .get(recipeId)
      .then((res) => {
        setResult(PendingResult.success(res))
      })
      .catch((reason) => {
        setResult(
          PendingResult.failure(
            'URL が間違っているか、削除された可能性があります。',
            reason
          )
        )
      })
  }, [recipeId])

  return result
}
