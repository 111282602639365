import { JsonError } from '~/data/JsonError'
import { mapJsonToArgumentDefinition } from '~/data/workflow/mapJsonToArgumentDefinition'
import {
  ObjectJson,
  ObjectPropertyValueJson,
} from '~/data/workflow/object/json'
import { mapJsonToValueType } from '~/data/workflow/valueType/mapJsonToValueType'
import { ObjectPropertyValue } from '~/domain/workflow/expectedObject/ObjectPropertyValue'
import { ObjectDefinition } from '~/domain/workflow/object/ObjectDefinition'
import { InputValue } from '~/domain/workflow/source/InputValue'

export function mapJsonToObjectDefinition(json: ObjectJson): ObjectDefinition {
  return new ObjectDefinition(
    json.objId,
    json.appId,
    json.name,
    json.description,
    json.info.map((attrInfo) => {
      if (attrInfo.isProperty) {
        return {
          type: 'property',
          key: attrInfo.key,
          name: attrInfo.name,
          description: attrInfo.description,
          valueType: mapJsonToValueType(attrInfo.returnType),
        }
      }
      return {
        type: 'method',
        key: attrInfo.key,
        name: attrInfo.name,
        description: attrInfo.description,
        arguments: attrInfo.argsInfo.map((it) =>
          mapJsonToArgumentDefinition(it)
        ),
        returnType: mapJsonToValueType(attrInfo.returnType),
      }
    })
  )
}

export function mapJsonToObjectPropertyValue(
  json: ObjectPropertyValueJson
): ObjectPropertyValue {
  const value = json.value
  if (!InputValue.isPureObject(value)) {
    throw new JsonError(
      `ObjectPropertyValue value is must be string, number, boolean, object, array or null. But was: ${typeof value}`
    )
  }
  return {
    propertyKey: json.propertyKey,
    value,
  }
}
