import styled from '@emotion/styled'
import React, { CSSProperties } from 'react'

import Link from '~/components/atoms/Link'
import Loader from '~/components/atoms/Loader'
import * as vars from '~/styles/variables'

interface Props {
  to?: string
  params?: {}
  search?: string
  type?: 'primary' | 'secondary' | 'tertiary' | 'danger'
  nativeType?: 'button' | 'submit' | 'reset'
  loading?: boolean
  disabled?: boolean
  className?: string
  style?: CSSProperties
  onClick?: (e: React.FormEvent) => void
  children: React.ReactNode
}

const Button: React.FunctionComponent<Props> = ({
  to,
  params,
  search,
  type = 'primary',
  nativeType,
  loading,
  disabled,
  onClick,
  style,
  className,
  children,
}) => (
  <React.Fragment>
    {to ? (
      <LinkButton
        className={className}
        style={style}
        to={to}
        params={params}
        search={search}
        appearanceType={type}
        isLoading={loading || undefined}
        disabled={disabled || loading}
      >
        {children}
        {loading && <_Loader reverse={true} size="s" />}
      </LinkButton>
    ) : (
      <NativeButton
        className={className}
        style={style}
        onClick={onClick}
        type={nativeType}
        appearanceType={type}
        isLoading={loading || undefined}
        disabled={disabled || loading}
      >
        {children}
        {loading && <_Loader reverse={true} size="s" />}
      </NativeButton>
    )}
  </React.Fragment>
)

const typeHandler = (props: {
  appearanceType?: string
  isLoading?: boolean
  disabled?: boolean
}) => ({
  backgroundColor:
    props.appearanceType === 'secondary' || props.appearanceType === 'tertiary'
      ? 'transparent'
      : props.appearanceType === 'primary'
      ? vars.color.theme
      : props.appearanceType === 'danger'
      ? vars.color.error
      : '',
  border:
    props.appearanceType === 'tertiary'
      ? 'none'
      : props.appearanceType === 'secondary'
      ? `2px solid ${vars.color.theme}`
      : 'none',
  color:
    props.appearanceType === 'tertiary'
      ? vars.fontColor.tertiary
      : props.appearanceType === 'secondary'
      ? vars.color.theme
      : vars.color.white,
  fontWeight: vars.fontWeight.bold,
  opacity: props.isLoading || props.disabled ? 0.5 : 1,
  cursor: props.disabled ? 'not-allowed' : 'pointer',
  '&:hover': {
    opacity: props.disabled ? 0.5 : 0.9,
  },
})

const LinkButton = styled(Link)(
  {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: vars.height.button,
    paddingRight: vars.space.m,
    paddingLeft: vars.space.m,
    minWidth: 80,
    fontSize: vars.fontSize.s,
    lineHeight: 1,
    textAlign: 'center',
    borderRadius: vars.borderRadius.m,
    border: 'none',
    cursor: 'pointer',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textDecoration: 'none',
    boxShadow: 'none',
    transition: 'all .14s ease',
  },
  typeHandler
)

const NativeButton = styled('button')(
  {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: vars.height.button,
    paddingRight: vars.space.m,
    paddingLeft: vars.space.m,
    minWidth: 80,
    fontSize: vars.fontSize.s,
    lineHeight: 1,
    textAlign: 'center',
    borderRadius: vars.borderRadius.m,
    border: 'none',
    cursor: 'pointer',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    boxShadow: 'none',
    transition: 'all .14s ease',
  },
  typeHandler
)

const _Loader = styled(Loader)({
  marginLeft: vars.space.s,
})

export default React.memo(Button)
