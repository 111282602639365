import { ListValueType } from '~/domain/ValueType'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { ValidationResult } from '~/domain/workflow/validator/ValidationResult'
import {
  BaseWidgetDefinition,
  InputWidgetDefinition,
} from '~/domain/workflow/widget/WidgetDefinition'
import { InputWidgetValidator } from '~/domain/workflow/widget/validator/InputWidgetValidator'
import { RawInputWidgetValidator } from '~/domain/workflow/widget/validator/RawInputWidgetValidator'
import { SubWidgetsValidator } from '~/domain/workflow/widget/validator/SubWidgetsValidator'

export interface ListWidgetDefinition extends BaseWidgetDefinition {
  formType: 'list'
  itemForm: InputWidgetDefinition
  minSize?: number
  maxSize?: number
}

export class ListWidgetValidator extends RawInputWidgetValidator<
  ListWidgetDefinition,
  ListValueType
> {
  async validate(inputValue: InputValue.Raw): Promise<ValidationResult> {
    // inputValue が InputValue[] でなければ BadFormat
    if (!InputValue.isInputValueList(inputValue.raw)) {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat('不正な値が入力されています')
      )
    }
    // required なのに要素数が 0 なら Required
    if (this.context.required && inputValue.raw.length === 0) {
      return ValidationResult.invalid(new ValidationResult.Required())
    }
    // minSize より少ない場合は BadFormat
    if (
      this.context.widgetDefinition.minSize !== undefined &&
      inputValue.raw.length < this.context.widgetDefinition.minSize
    ) {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat(
          `リストの要素数は ${this.context.widgetDefinition.minSize} 個以上でなければなりません`
        )
      )
    }
    // maxSize より多い場合は BadFormat
    if (
      this.context.widgetDefinition.maxSize !== undefined &&
      inputValue.raw.length > this.context.widgetDefinition.maxSize
    ) {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat(
          `リストの要素数は ${this.context.widgetDefinition.maxSize} 個以下でなければなりません`
        )
      )
    }
    // サブウィジェットをチェック
    const itemValidator = new InputWidgetValidator({
      ...this.context,
      widgetDefinition: this.context.widgetDefinition.itemForm,
      valueType: this.context.valueType.itemType,
    })
    const validators = new Array(inputValue.raw.length).fill(itemValidator)
    const subValidator = new SubWidgetsValidator(validators)
    return subValidator.validate(inputValue.raw)
  }
}
