import _ from 'lodash'
import * as uuid from 'uuid'

import { Pagination } from '~/common/Pagination'
import { HttpError } from '~/data/request'
import { Connection } from '~/domain/connection/Connection'
import { ConnectionService } from '~/domain/connection/ConnectionService'
import { Permission } from '~/domain/workflow/permission/Permission'
import { PermissionPolicy } from '~/domain/workflow/permission/PermissionPolicy'

const mockConnections: Connection[] = [
  {
    id: '1',
    provider: 'mocked_provider',
    uid: 'mocked_connection_uid',
    label: 'モックコネクション',
    user: {
      username: 'モック太郎',
      id: 'mocked_user',
      organization: {
        id: 'mocked_organization',
        name: '合同会社モック',
      },
      isAdmin: true,
    },
    appId: 'GenericApp',
  },
]

const permissions = new Map<string, Permission[]>()

export class MockConnectionService implements ConnectionService {
  async createSocialAccount(
    provider: string
    // token: string,
    // extraData: { [p: string]: string }
  ): Promise<Connection> {
    return { ...mockConnections[0], provider }
  }

  async getList(page: number): Promise<Pagination<Connection>> {
    return {
      items: _.chunk(mockConnections, 10)[page],
      paginationMeta: {
        totalCount: 1,
        currentPageNumber: page,
        countPerPage: 10,
      },
    }
  }

  async getConnection(id: string): Promise<Connection | undefined> {
    return mockConnections.find((it) => it.id === id)
  }

  async addPermission(
    connectionId: string,
    groupId: string,
    policy: PermissionPolicy
  ): Promise<Permission> {
    const permission: Permission = {
      id: uuid.v4(),
      group: {
        id: groupId,
        name: `Mock Group (${groupId})`,
        updatedAt: new Date(),
      },
      policy,
      updatedAt: new Date(),
    }
    const list = permissions.get(connectionId) ?? []
    permissions.set(connectionId, [...list, permission])
    return permission
  }

  async changePermission(
    connectionId: string,
    permissionId: string,
    policy: PermissionPolicy
  ): Promise<Permission> {
    const list = permissions.get(connectionId)
    if (list === undefined) {
      throw new HttpError(404, 'Mocked 404 Error')
    }
    const permission = list.find((it) => it.id === permissionId)
    if (permission === undefined) {
      throw new HttpError(404, 'Mocked 404 Error')
    }
    const newPermission = new Permission({ ...permission, policy })
    permissions.set(
      connectionId,
      list.map((it) => (it.id === permissionId ? newPermission : it))
    )
    return newPermission
  }

  async getPermissions(connectionId: string): Promise<Permission[]> {
    return permissions.get(connectionId) ?? []
  }

  async removePermission(
    connectionId: string,
    permissionId: string
  ): Promise<void> {
    const list = permissions.get(connectionId)
    if (list === undefined) {
      throw new HttpError(404, 'Mocked 404 Error')
    }
    const permission = list.find((it) => it.id === permissionId)
    if (permission === undefined) {
      throw new HttpError(404, 'Mocked 404 Error')
    }
    permissions.set(
      connectionId,
      _.reject(list, (it) => it.id === permissionId)
    )
  }
}
