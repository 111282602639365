import { JsonError } from '~/data/JsonError'
import { ActionState } from '~/domain/workflow/action/ActionState'

export function mapToActionState(status: string): ActionState {
  switch (status) {
    case 'available':
      return ActionState.available
    case 'deprecated':
      return ActionState.deprecated
    case 'deleted':
      return ActionState.deleted
    default:
      throw new JsonError(`Unknown action state: ${status}`)
  }
}
