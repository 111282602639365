import styled from '@emotion/styled'
import * as React from 'react'
import { Helmet } from 'react-helmet'

import Footer from '~/components/organisms/Footer'
import Bottom from '~/containers/Home/Bottom'
import Header from '~/containers/Home/Header'
import Applications from '~/containers/Home/apps/Applications'
import Customers from '~/containers/Home/customers/Customers'
import Features from '~/containers/Home/features/Features'
import Masthead from '~/containers/Home/hero/Masthead'
import Media from '~/containers/Home/media/Media'
import * as styles from '~/containers/Home/styles'
import UseCases from '~/containers/Home/useCases/UseCases'
import { useIsTablet } from '~/containers/Home/useIsTablet'
import * as vars from '~/styles/variables'

const Home: React.FC = () => {
  const isTablet = useIsTablet()

  // Intercom のボタンを非表示にする
  React.useEffect(() => {
    if (isTablet) {
      window.Intercom &&
        window.Intercom('update', {
          hide_default_launcher: true,
        })
    }
    return () => {
      window.Intercom &&
        window.Intercom('update', {
          hide_default_launcher: false,
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.Intercom, isTablet])

  return (
    <styles.Container>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Header />
      <Masthead />
      <Section id="usecases" style={{ marginTop: vars.space.l }}>
        <UseCases />
      </Section>
      <Section id="features">
        <Features />
      </Section>
      <Section id="apps">
        <Applications />
      </Section>
      <Section>
        <Customers />
      </Section>
      <Section id="media">
        <Media />
      </Section>
      <Section>
        <Bottom />
      </Section>
      <Footer />
    </styles.Container>
  )
}

const Section = styled('div')({
  marginTop: vars.space.xl,
  paddingTop: vars.height.lpHeader,
  [vars.media.tablet]: {
    marginTop: vars.space.xl,
  },
})

export default Home
