import { JsonError } from '~/data/JsonError'
import { AssistedValueJson } from '~/data/workflow/assist/AssistedValueJson'
import { AssistedValue } from '~/domain/workflow/assist/AssistedValue'

export function mapJsonToAssistedValue(json: AssistedValueJson): AssistedValue {
  if (typeof json.value !== 'string' && typeof json.value !== 'number') {
    throw new JsonError(
      `value is not of type string or number. of type ${typeof json.value}`
    )
  }
  return {
    label: json.label,
    value: json.value.toString(),
  }
}
