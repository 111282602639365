import * as React from 'react'

import { Result } from '~/common/Result'
import { apiClients } from '~/common/apiClients'
import { NoOrganizationError } from '~/data/user/NoOrganizationError'

const sessionService = apiClients.sessionService

type MeState = 'no-organization' | 'normal-user'

export function useMeState(): {
  result: Result<MeState>
} {
  const [result, setResult] = React.useState<Result<MeState>>(
    new Result.Loading()
  )

  React.useEffect(() => {
    let stale = false
    setResult(new Result.Loading())
    sessionService
      .getMe()
      .then(() => {
        if (stale) {
          return
        }
        setResult(new Result.Success('normal-user'))
      })
      .catch((error) => {
        if (stale) {
          return
        }
        if (error instanceof NoOrganizationError) {
          setResult(new Result.Success('no-organization'))
          return
        }
        console.error(error)
        setResult(new Result.Failure(error))
      })
    return () => {
      stale = true
    }
  }, [])

  return { result }
}
