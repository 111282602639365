import * as React from 'react'

import { apiClients } from '~/common/apiClients'
import { SubmitStatus } from '~/common/types'
import { WorkflowSource } from '~/domain/workflow/source/WorkflowSource'
import { WorkflowSourceService } from '~/domain/workflow/source/WorkflowSourceService'

const workflowSourceService: WorkflowSourceService =
  apiClients.workflowSourceService

export function useUpdateWorkflowSource(): {
  update: (newSource: WorkflowSource) => void
  submitStatus: SubmitStatus
  submitErrorMessage?: string
} {
  const [submitStatus, setSubmitStatus] = React.useState<SubmitStatus>(
    SubmitStatus.none
  )
  const [submitErrorMessage, setSubmitErrorMessage] = React.useState<string>()

  const update = (newSource: WorkflowSource) => {
    setSubmitErrorMessage(undefined)
    setSubmitStatus(SubmitStatus.submitting)
    return workflowSourceService
      .update(newSource.removeAllEmptyTasks())
      .then((it) => {
        setSubmitStatus(SubmitStatus.submitted)
        return it
      })
      .catch((reason) => {
        console.error(reason)
        setSubmitStatus(SubmitStatus.failed)
        setSubmitErrorMessage(`Failed to update workflow source: ${reason}`)
        throw reason
      })
  }

  return { update, submitStatus, submitErrorMessage }
}
