import styled from '@emotion/styled'
import React from 'react'

import { truncateMiddle } from '~/common/utils'
import PaintedPill from '~/components/molecules/PaintedPill'
import { InputValue } from '~/domain/workflow/source/InputValue'
import * as vars from '~/styles/variables'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  iconPath: string
  color: string
  previewValue?: InputValue.Raw.PureObject
  disabled?: boolean
  className?: string
}

const ObjectPill: React.FC<Props> = ({ previewValue, ...props }) => {
  return (
    <PaintedPill {...props}>
      <WithPreview previewValue={previewValue}>{props.children}</WithPreview>
    </PaintedPill>
  )
}

const WithPreview = styled('span')(
  {},
  (props: { previewValue: InputValue.Raw.PureObject | undefined }) => ({
    '&::after':
      props.previewValue !== undefined
        ? {
            content: `"${truncateMiddle(
              InputValue.stringify(props.previewValue)
            ).replace(/"/g, '\\"')}"`,
            color: vars.fontColor.tertiary,
            fontWeight: 'normal',
            marginLeft: vars.space.xs * 1.5,
            marginRight: vars.space.xs * 1.5,
          }
        : {},
  })
)

export default ObjectPill
