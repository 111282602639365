import { InputValue } from '~/domain/workflow/source/InputValue'
import { ValidationResult } from '~/domain/workflow/validator/ValidationResult'
import { BaseWidgetDefinition } from '~/domain/workflow/widget/WidgetDefinition'
import { RawInputWidgetValidator } from '~/domain/workflow/widget/validator/RawInputWidgetValidator'

export interface SwitchWidgetDefinition extends BaseWidgetDefinition {
  formType: 'switch'
  label: string
}

export class SwitchWidgetValidator extends RawInputWidgetValidator<
  SwitchWidgetDefinition
> {
  async validate(inputValue: InputValue.Raw): Promise<ValidationResult> {
    if (typeof inputValue.raw !== 'boolean') {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat('不正な値が入力されています')
      )
    }
    return ValidationResult.valid()
  }
}
