// TODO: ESLint 誤検知
/* eslint-disable no-use-before-define */
import * as _ from 'lodash'

import { assert, assertNever } from '~/common/utils'
import { Recipe } from '~/domain/recipe/Recipe'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { TaskIterator } from '~/domain/workflow/source/TaskIterator'
import { WorkflowNode } from '~/domain/workflow/source/WorkflowNode'
import {
  InputSource,
  WorkflowSourceBody,
} from '~/domain/workflow/source/WorkflowSourceBody'

export class RecipeWizard {
  private readonly steps: RecipeStep[] = []

  constructor(private readonly recipe: Recipe) {
    const trigger = recipe.source.getTrigger()
    if (trigger !== undefined) {
      this.steps.push({
        targetNode: { kind: 'trigger' },
        inputs: mapInputSourceToRecipeInputs(trigger.inputs),
      })
    }
    const rootTask = recipe.source.getRootTask()
    if (rootTask !== undefined) {
      for (const task of new TaskIterator(rootTask)) {
        this.steps.push({
          targetNode: { kind: 'task', taskId: task.taskId },
          inputs: mapInputSourceToRecipeInputs(task.inputs),
        })
      }
    }
  }

  static clone(original: RecipeWizard): RecipeWizard {
    const newWizard = new RecipeWizard(original.recipe)
    assert(
      original.steps.length === newWizard.steps.length,
      `steps length doesn't match (${original.steps.length} vs ${newWizard.steps.length})`
    )
    for (let i = 0; i < original.steps.length; i++) {
      newWizard.steps[i] = _.cloneDeep(original.steps[i])
    }
    return newWizard
  }

  getStep(stepNumber: number): RecipeStep {
    this.assertStepNumber(stepNumber)
    return this.steps[stepNumber]
  }

  getSteps(): RecipeStep[] {
    return this.steps
  }

  /**
   * 指定したステップ番号のステップの入力を、指定した RecipeStepInputs で置き換えます。
   *
   * @param stepNumber 入力を置き換えるステップの番号
   * @param inputs 置き換えるステップ入力を表す RecipeStepInputs
   */
  setStepInputs(stepNumber: number, inputs: RecipeStepInputs): RecipeWizard {
    this.assertStepNumber(stepNumber)
    const newWizard = RecipeWizard.clone(this)
    newWizard.assertStepNumber(stepNumber)
    newWizard.steps[stepNumber].inputs = inputs
    return newWizard
  }

  /**
   * 現在のステップ入力を使用して、レシピから WorkflowSourceBody を作成します。
   *
   * ステップ入力で明示的に指定されていないフィールドキーの値は、レシピに
   * 指定されている値がそのまま使用されます。一方でステップ入力で明示的に
   * undefined が指定されているフィールドは、たとえレシピソースに初期値が
   * 存在したとしても、作成する WorkflowSourceBody のインプットのリストには
   * 現れません。
   *
   * これは、レシピウィザードで入力できないフィールドの値にはレシピソースの値を
   * そのまま使用したい一方で、レシピウィザードで明示的に未入力にした
   * フィールドについてはレシピソースの値を使用したくないためです。
   */
  createWorkflowSourceBody(): WorkflowSourceBody {
    let result = new WorkflowSourceBody(
      this.recipe.source.getTrigger(),
      this.recipe.source.getRootTask()
    )
    this.steps.forEach((step) => {
      switch (step.targetNode.kind) {
        case 'trigger': {
          const trigger = result.getTrigger()
          assert(
            trigger !== undefined,
            `Can't get a trigger from created WorkflowSourceBody.`
          )
          result = result.setTriggerInputs(
            mergeSourceInputsAndRecipeInputs(trigger.inputs, step.inputs)
          )
          break
        }
        case 'task': {
          const task = this.recipe.source.findTask(step.targetNode.taskId)
          assert(
            task !== undefined,
            `Can't get a task from created WorkflowSourceBody.`
          )
          result = result.setTaskInputs(
            step.targetNode.taskId,
            mergeSourceInputsAndRecipeInputs(task.inputs, step.inputs)
          )
          break
        }
        default:
          assertNever(step.targetNode)
      }
    })
    return result
  }

  get stepCount(): number {
    return this.steps.length
  }

  private assertStepNumber(stepNumber: number) {
    assert(stepNumber >= 0, `Required: stepNumber >= 0 (but ${stepNumber})`)
    assert(
      stepNumber < this.steps.length,
      `Required: stepNumber < ${this.steps.length} (but ${stepNumber})`
    )
  }
}

/**
 * レシピソースのインプットとレシピインプットを合成し、 undefined な value を持つ
 * フィールドを除外した InputValue のリストを返します。
 */
// export for test
export function mergeSourceInputsAndRecipeInputs(
  sourceInputs: InputSource[],
  recipeInputs: RecipeStepInputs
): InputSource[] {
  // レシピソースのインプットで初期化
  const inputs: RecipeStepInputs = mapInputSourceToRecipeInputs(sourceInputs)
  // レシピインプットで上書き
  Object.keys(recipeInputs).forEach((key) => {
    inputs[key] = recipeInputs[key]
  })
  // undefined な値を削除して返す
  return mapRecipeInputsToInputSource(inputs).filter(
    (it) => it.value !== undefined
  )
}

function mapRecipeInputsToInputSource(
  recipeInputs: RecipeStepInputs
): InputSource[] {
  return Object.keys(recipeInputs).map((key) => ({
    fieldKey: key,
    value: recipeInputs[key],
  }))
}

function mapInputSourceToRecipeInputs(
  inputSource: InputSource[]
): RecipeStepInputs {
  const obj: RecipeStepInputs = {}
  inputSource.forEach((input) => {
    obj[input.fieldKey] = input.value
  })
  return obj
}

export interface RecipeStep {
  targetNode: WorkflowNode
  inputs: RecipeStepInputs
}

export interface RecipeStepInputs {
  [recipeFieldKey: string]: InputValue
}
