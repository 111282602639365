import styled from '@emotion/styled'
import _ from 'lodash'
import * as hash from 'object-hash'
import React from 'react'
import * as Icon from 'react-feather'

import Loader from '~/components/atoms/Loader'
import Text from '~/components/atoms/Text'
import Focusable from '~/components/common/Focusable'
import AssistItem from '~/components/molecules/AssistField/AssistItem'
import Dropdown, { DropdownItem } from '~/components/molecules/Dropdown'
import { useDynamicSelectOptions } from '~/components/molecules/DynamicSelectField/hooks'
import * as vars from '~/styles/variables'
import {
  widgetAnimationStyle,
  widgetBorderErrorStyle,
  widgetBorderStyle,
  widgetFocusedStyle,
} from '~/styles/widget'

interface Option {
  label: string
  value: string
}

interface LabelMap {
  // key is value, value is label
  [value: string]: string
}

interface Props {
  loadOptions: (
    query: string,
    pageToken?: string
  ) => Promise<{ options: Option[]; nextPageToken?: string }>
  loadLabels: (values: string[]) => Promise<LabelMap>
  values: string[]
  onChange: (newValues: string[]) => void
  hasBorder?: boolean
  disabled?: boolean
}

const DynamicMultiSelectField: React.FC<Props> = (props) => {
  const [hasFocus, setHasFocus] = React.useState<boolean>(false)
  const [isLabelLoading, setIsLabelLoading] = React.useState<boolean>(false)
  const [isLabelError, setIsLabelError] = React.useState<boolean>(false)
  const [query, setQuery] = React.useState<string>('')
  const [tempQuery, setTempQuery] = React.useState<string>('')
  const [tempValues, setTempValues] = React.useState<string[]>(props.values)
  const [labelOptions, setLabelOptions] = React.useState<Option[]>()

  const dummyFocusableRef = React.useRef<HTMLDivElement>(null)

  const {
    options,
    loading: isOptionsLoading,
    load: loadMoreOptions,
  } = useDynamicSelectOptions(props.loadOptions, query)

  const label = React.useMemo(() => {
    if (labelOptions === undefined || labelOptions.length === 0) {
      return undefined
    }
    return labelOptions.map((it) => it.label).join(', ')
  }, [labelOptions])

  const fixedOptions: Option[] = React.useMemo(() => {
    const map = new Map<string, Option>()
    labelOptions?.forEach((it) => map.set(it.value, it))
    options.forEach((it) => map.delete(it.value))
    return Array.from(map.values())
  }, [labelOptions, options])

  const dropdownItems: DropdownItem[] = React.useMemo(() => {
    return [...fixedOptions, ...options]
    // ハッシュ値に依存することで values が変更されるたびに items が変更されるのではなく、
    // 本当の意味で fixedOptions の中身が変更されたときにのみ items を更新する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(fixedOptions), options])

  const debouncedSetQuery = React.useMemo(() => {
    return _.debounce((q: string) => {
      setQuery(q)
    }, 500)
  }, [])

  const debouncedOnChange = React.useMemo(() => {
    return _.debounce((newValues: string[]) => {
      props.onChange(newValues)
    }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onChange])

  // values が変更された時、 tempValues もそれに合わせる
  React.useEffect(() => {
    setTempValues(props.values)
  }, [props.values])

  // ラベルの取得
  React.useEffect(() => {
    if (props.values.length === 0) {
      setLabelOptions(undefined)
      return
    }
    let disposed = false
    setIsLabelLoading(true)
    setIsLabelError(false)
    props
      .loadLabels(props.values)
      .then((res) => {
        if (disposed) {
          return
        }
        setLabelOptions(
          Object.entries(res).map(([key, value]) => ({
            label: value,
            value: key,
          }))
        )
        setIsLabelError(false)
        setIsLabelLoading(false)
      })
      .catch((e) => {
        if (disposed) {
          return
        }
        console.error(e)
        setLabelOptions(undefined)
        setIsLabelError(true)
        setIsLabelLoading(false)
      })
    return () => {
      disposed = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values, props.loadLabels])

  const handleFocusChange = React.useCallback(
    (newFocus: boolean) => {
      if (props.disabled) {
        return
      }
      setHasFocus(newFocus)
    },
    [props.disabled]
  )

  const handleQueryChanged = React.useCallback(
    (newQuery: string) => {
      setTempQuery(newQuery)
      debouncedSetQuery(newQuery)
    },
    [debouncedSetQuery]
  )

  const handleClearClick = React.useCallback(() => {
    props.onChange([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onChange])

  const handleItemClick = React.useCallback(
    (item: DropdownItem) => {
      // tempValues を使うことで連続でチェックする場合に処理を間引く
      let newValues: string[]
      if (tempValues.includes(item.value)) {
        newValues = _.reject(tempValues, (it) => it === item.value)
      } else {
        newValues = [...tempValues, item.value]
      }
      setTempValues(newValues)
      debouncedOnChange(newValues)
    },
    [tempValues, debouncedOnChange]
  )

  const handleReachBottom = React.useCallback(() => {
    loadMoreOptions()
    // loadMoreOptions は参照が不変
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // TODO: props.values が変更されるたびに renderDropdownItem が変更されるので遅いかも
  const renderDropdownItem = React.useCallback(
    (item: DropdownItem) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CheckBox
            checked={tempValues.includes(item.value)}
            style={{ marginRight: vars.space.m }}
          />
          <AssistItem item={item} />
        </div>
      )
    },
    [tempValues]
  )

  return (
    <Container disabled={props.disabled ?? false}>
      <Focusable tabFocusable={!hasFocus} onFocusChanged={handleFocusChange}>
        <LabelOrQueryField
          values={props.values}
          label={label}
          labelLoading={isLabelLoading}
          labelError={isLabelError}
          queryMode={hasFocus}
          query={tempQuery}
          onQueryChange={handleQueryChanged}
          hasBorder={props.hasBorder ?? true}
          disabled={props.disabled ?? false}
          onClearClick={handleClearClick}
        />
        <Dropdown
          emptyLabel="一致する項目が見つかりませんでした"
          items={dropdownItems}
          isOpen={hasFocus}
          loading={isOptionsLoading}
          renderItem={renderDropdownItem}
          onItemClick={handleItemClick}
          onReachBottom={handleReachBottom}
        />
      </Focusable>
      <Focusable ref={dummyFocusableRef} />
    </Container>
  )
}

const LabelOrQueryField: React.FC<{
  hasBorder: boolean
  disabled: boolean
  onClearClick: () => void
  label: string | undefined
  labelError: boolean
  labelLoading: boolean
  values: string[]
  queryMode: boolean
  query: string
  onQueryChange: (newQuery: string) => void
}> = (props) => {
  if (props.queryMode) {
    return (
      <Input
        value={props.query}
        onChange={(e) => props.onQueryChange(e.currentTarget.value)}
        hasBorder={props.hasBorder}
        hasError={false}
        autoFocus={true}
        disabled={props.disabled}
      />
    )
  }

  if (props.labelError) {
    return (
      <InputLike hasBorder={props.hasBorder} hasError={true}>
        <Text
          element="span"
          fontSize="m"
          lineHeight="heading"
          color={vars.fontColor.primary}
          style={{ flexGrow: 1, fontStyle: 'italic' }}
        >
          ラベルの取得に失敗しました
        </Text>
        {!props.disabled && (
          <Focusable
            onFocus={(e) => e.stopPropagation()}
            onBlur={(e) => e.stopPropagation()}
          >
            <ClearButton onClick={props.onClearClick}>
              <Icon.X size={20} color={vars.color.icon} />
            </ClearButton>
          </Focusable>
        )}
      </InputLike>
    )
  }

  if (props.labelLoading) {
    return (
      <InputLike hasBorder={props.hasBorder} hasError={false}>
        <Loader size="s" />
      </InputLike>
    )
  }

  if (props.values.length === 0) {
    return (
      <InputLike hasBorder={props.hasBorder} hasError={false}>
        <Text
          element="span"
          fontSize="m"
          lineHeight="heading"
          color={vars.fontColor.tertiary}
        >
          選択してください
        </Text>
      </InputLike>
    )
  }

  if (props.label === undefined) {
    return (
      <InputLike hasBorder={props.hasBorder} hasError={true}>
        <Text
          element="span"
          fontSize="m"
          lineHeight="heading"
          color={vars.fontColor.primary}
          style={{ flexGrow: 1, fontStyle: 'italic' }}
        >
          無効な値です
        </Text>
        {!props.disabled && (
          <Focusable
            onFocus={(e) => e.stopPropagation()}
            onBlur={(e) => e.stopPropagation()}
          >
            <ClearButton onClick={props.onClearClick}>
              <Icon.X size={20} color={vars.color.icon} />
            </ClearButton>
          </Focusable>
        )}
      </InputLike>
    )
  }

  return (
    <InputLike hasBorder={props.hasBorder} hasError={false}>
      <Text
        element="span"
        fontSize="m"
        lineHeight="heading"
        truncated={true}
        style={{ flexGrow: 1 }}
      >
        {props.label}
      </Text>
      {!props.disabled && (
        <Focusable
          onFocus={(e) => e.stopPropagation()}
          onBlur={(e) => e.stopPropagation()}
        >
          <ClearButton onClick={props.onClearClick}>
            <Icon.X size={20} color={vars.color.icon} />
          </ClearButton>
        </Focusable>
      )}
    </InputLike>
  )
}

const Container = styled('div')(
  {
    position: 'relative',
    display: 'block',
    width: '100%',
    backgroundColor: vars.color.white,
  },
  (props: { disabled: boolean }) => ({
    cursor: props.disabled ? 'not-allowed' : 'unset',
    opacity: props.disabled ? 0.5 : 1,
  })
)

const InputLike = styled('div')(
  {
    paddingRight: vars.space.m,
    paddingLeft: vars.space.m,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: vars.height.field,
    color: vars.fontColor.primary,
    fontSize: vars.fontSize.m,
    lineHeight: 1.5,
    outline: 'none',
    borderRadius: widgetBorderStyle.borderRadius,
    ...widgetAnimationStyle,
  },
  (props: { hasBorder: boolean; hasError: boolean }) => ({
    border: props.hasBorder
      ? props.hasError
        ? widgetBorderErrorStyle.border
        : widgetBorderStyle.border
      : 'none',
    ...(() =>
      props.hasBorder
        ? {
            '&:focus': {
              ...widgetFocusedStyle,
            },
          }
        : {})(),
  })
)

const Input = styled('input')(
  {
    paddingRight: vars.space.m,
    paddingLeft: vars.space.m,
    display: 'block',
    width: '100%',
    height: vars.height.field,
    color: vars.fontColor.primary,
    fontSize: vars.fontSize.m,
    lineHeight: 1.5,
    outline: 'none',
    borderRadius: widgetBorderStyle.borderRadius,
    ...widgetAnimationStyle,
    '&::placeholder': {
      color: vars.fontColor.tertiary,
    },
  },
  (props: { hasBorder: boolean; hasError: boolean }) => ({
    border: props.hasBorder
      ? props.hasError
        ? widgetBorderErrorStyle.border
        : widgetBorderStyle.border
      : 'none',
    ...(() =>
      props.hasBorder
        ? {
            '&:focus': {
              ...widgetFocusedStyle,
            },
          }
        : {})(),
  })
)

const ClearButton = styled('div')({
  height: vars.height.field,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const CheckBox = styled('span')(
  {
    position: 'relative',
    display: 'inline-block',
    width: 18,
    height: 18,
    borderRadius: vars.borderRadius.s,
  },
  (props: { checked: boolean }) => {
    return props.checked
      ? {
          backgroundColor: vars.color.theme,
          '&::after': {
            position: 'absolute',
            top: 4,
            left: 7,
            width: 5,
            height: 9,
            content: '""',
            cursor: 'pointer',
            transform: 'rotate(45deg)',
            borderStyle: 'none',
            borderRight: `2px solid ${vars.color.white}`,
            borderBottom: `2px solid ${vars.color.white}`,
            backgroundColor: 'transparent',
          },
        }
      : {
          border: `1px solid ${vars.color.border}`,
        }
  }
)

export default DynamicMultiSelectField
