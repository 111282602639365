import hash from 'object-hash'
import * as React from 'react'

import { AssistParameters } from '~/domain/workflow/assist/AssistService'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { DependentFields } from '~/presentation/workflow/detail/editor/form/field/DependentField'

export function useAssistParameters(
  parameterFieldKeys: string[],
  dependentFields: DependentFields
): AssistParameters {
  return React.useMemo(() => {
    const res = {}
    for (const fieldKey of parameterFieldKeys) {
      const found = dependentFields.findField(fieldKey)
      if (found === undefined) {
        throw new Error(
          `This AssistWidget depends on field '${fieldKey}' but its value is not passed. Missing dependencies?`
        )
      }
      if (found.value === undefined) {
        continue
      }
      if (found.value.mode !== 'raw') {
        // raw ではない入力値を持つフィールドに依存している場合はエラー
        throw new Error(
          "Assist parameters can't be non-raw input value. Make sure using ConditionalAssistWidget."
        )
      }
      try {
        res[fieldKey] = InputValue.convertRawInputValueToObject(found.value)
      } catch (e) {
        // convertRawInputValueToObject に失敗した場合はエラー
        console.error(e)
        throw new Error(
          "Assist parameters can't be non-raw input value. Make sure using ConditionalAssistWidget."
        )
      }
    }
    return res
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(parameterFieldKeys), hash(dependentFields)])
}
