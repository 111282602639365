import * as React from 'react'

import { apiClients } from '~/common/apiClients'
import { FunctionDefinition } from '~/domain/workflow/function/FunctionDefinition'
import { FunctionService } from '~/domain/workflow/function/FunctionService'

const functionService: FunctionService = apiClients.functionService

export function useFunctionDefinitions(): {
  functions?: FunctionDefinition[]
  fetching: boolean
  errorMessage?: string
} {
  const [functions, setFunctions] = React.useState<FunctionDefinition[]>()
  const [fetching, setFetching] = React.useState<boolean>(true)
  const [errorMessage, setErrorMessage] = React.useState<string>()
  React.useEffect(() => {
    functionService
      .getAll()
      .finally(() => setFetching(false))
      .then((it) => setFunctions(it))
      .catch((reason) => {
        console.error(`Failed to get functions: ${reason}`)
        setErrorMessage(reason)
      })
  }, [])
  return { functions, fetching, errorMessage }
}
