import * as React from 'react'

import ErrorMessage from '~/components/atoms/ErrorMessage'
import { LegacyAccountWidgetDefinition } from '~/domain/workflow/widget/WidgetDefinition'
import { RawInputWidgetProps } from '~/presentation/workflow/detail/editor/form/inputWidget/RawInputWidget'
import { useInputWidgetValidation } from '~/presentation/workflow/detail/editor/form/validation/useValidation'
import * as vars from '~/styles/variables'

interface Props extends RawInputWidgetProps {
  definition: LegacyAccountWidgetDefinition
}

/**
 * @deprecated アシストで入力するようになりました
 */
const LegacyAccountWidget: React.FC<Props> = (props) => {
  // バリデーション
  const validationResult = useInputWidgetValidation(props)
  return (
    <>
      <p>このウィジェットは使用できません（legacy_account）</p>
      {validationResult?.valid === false && (
        <ErrorMessage style={{ marginTop: vars.space.s }}>
          {validationResult.cause.message}
        </ErrorMessage>
      )}
    </>
  )
}

export default LegacyAccountWidget
