import * as _ from 'lodash'

import { delay } from '~/common/utils'
import { serverData } from '~/data/workflow/MockWorkflowService'
import { WorkflowSource } from '~/domain/workflow/source/WorkflowSource'
import { WorkflowSourceService } from '~/domain/workflow/source/WorkflowSourceService'

export class MockWorkflowSourceService implements WorkflowSourceService {
  async update(workflowSource: WorkflowSource): Promise<void> {
    await delay(1000)
    const index = _.findIndex(
      serverData.workflows,
      (it) => it.source.sourceId === workflowSource.sourceId
    )
    serverData.workflows[index].source = workflowSource
    console.log(
      `[API] update workflow source called.\n${JSON.stringify(workflowSource)}`
    )
  }
}
