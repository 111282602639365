import * as vars from '~/styles/variables'

export const scrollbarStyle = {
  /* スクロールバー全体 */
  '::-webkit-scrollbar': {
    // 縦スクロール時の幅
    width: 8,
    // 横スクロール時の高さ
    height: 8,
  },
  /* スクロールバーの軌道 */
  '::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  /* スクロールバーの動く部分 */
  '::-webkit-scrollbar-thumb': {
    borderRadius: 8,
    backgroundColor: vars.colorPalette.gray3,
    boxShadow: vars.shadow.s,
  },
}
