import styled from '@emotion/styled'
import * as React from 'react'

import { assertNever } from '~/common/utils'
import Pill from '~/components/atoms/Pill'
import {
  FunctionCardProps,
  MethodCardProps,
  PropertyCardProps,
} from '~/components/molecules/HoverCard/types'
import HoverCardTarget from '~/components/organisms/HoverCardTarget/HoverCardTarget'
import ObjectPill from '~/components/organisms/ObjectPill'
import Tooltip from '~/components/utils/Tooltip'
import {
  RenderableElement,
  RenderableEntity,
} from '~/domain/workflow/source/RenderableElement'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import { useExpectedObjectsContext } from '~/presentation/workflow/detail/editor/form/expectedObject/ExpectedObjectsContext'
import { NotFoundObjectTooltip } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/tooltips'
import { EntityType } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/types'
import { useExpectedObjectSnapshots } from '~/presentation/workflow/detail/editor/test/ExpectedObjectSnapshotsContext'

const _Pill = styled(Pill)({
  marginLeft: 3,
  marginRight: 3,
})

const _ObjectPill = styled(ObjectPill)({
  marginLeft: 3,
  marginRight: 3,
})

const EntityWrapper = styled('div')({
  display: 'inline-flex',
  marginLeft: 3,
  marginRight: 3,
})

type EntityComponentProps = {
  label: string
  entity: RenderableEntity
}

/** 変数を描画するコンポーネント */
const Property: React.FC<EntityComponentProps> = (props) => {
  const definitions = useDefinitions()
  const snapshots = useExpectedObjectSnapshots()
  const { expectedObjects } = useExpectedObjectsContext()
  const property = props.entity as RenderableElement.Property
  const snapshotValue = React.useMemo(() => {
    return snapshots
      .find(property.expectedObjectKey)
      ?.values.find((it) => it.propertyKey === property.propertyKey)?.value
  }, [snapshots, property])

  const objectInstance = expectedObjects.findInstance(
    property.expectedObjectKey.value
  )
  if (objectInstance === undefined) {
    return (
      <NotFoundObjectTooltip>
        <_Pill contentEditable={false}>{props.label}</_Pill>
      </NotFoundObjectTooltip>
    )
  }

  const objectDefinition = definitions.getObject(objectInstance.objectId)
  const propertyDefinition = objectDefinition.getProperty(property.propertyKey)
  const appDefinition = definitions.getApp(objectDefinition.appId)

  const cardProps: PropertyCardProps = {
    type: 'property',
    app: appDefinition,
    property: propertyDefinition,
    expectedObjectName: objectInstance.name,
    sampleValue: objectInstance.sampleValues.find(
      (it) => it.propertyKey === property.propertyKey
    )?.value,
    snapshotValue: snapshotValue,
  }

  return (
    <EntityWrapper contentEditable={false}>
      <HoverCardTarget card={cardProps}>
        <_ObjectPill
          iconPath={appDefinition.iconPath}
          color={appDefinition.color}
          previewValue={snapshotValue}
        >
          {props.label}
        </_ObjectPill>
      </HoverCardTarget>
    </EntityWrapper>
  )
}

const Unsupported: React.FC<EntityComponentProps> = (props) => {
  return (
    <Tooltip renderContent={() => '非推奨'} delay={0}>
      <_Pill contentEditable={false}>{props.label}</_Pill>
    </Tooltip>
  )
}

const MethodCall: React.FC<EntityComponentProps> = (props) => {
  const definitions = useDefinitions()
  const { expectedObjects } = useExpectedObjectsContext()

  const method = props.entity as RenderableElement.MethodCallStart
  const objectInstance = expectedObjects.findInstance(
    method.expectedObjectKey.value
  )
  if (objectInstance === undefined) {
    return (
      <NotFoundObjectTooltip>
        <_Pill contentEditable={false}>{props.label}(</_Pill>
      </NotFoundObjectTooltip>
    )
  }
  const objectDefinition = definitions.getObject(objectInstance.objectId)
  const methodDefinition = objectDefinition.getMethod(method.methodKey)
  const appDefinition = definitions.getApp(objectDefinition.appId)

  const cardProps: MethodCardProps = {
    type: 'method',
    method: methodDefinition,
    app: appDefinition,
    expectedObjectName: objectInstance.name,
  }

  return (
    <EntityWrapper contentEditable={false}>
      <HoverCardTarget card={cardProps}>
        <_ObjectPill
          iconPath={appDefinition.iconPath}
          color={appDefinition.color}
        >
          {props.label}
        </_ObjectPill>
      </HoverCardTarget>
    </EntityWrapper>
  )
}

const FunctionCall: React.FC<EntityComponentProps> = (props) => {
  const definitions = useDefinitions()
  const func = props.entity as RenderableElement.FunctionCallStart
  const cardProps = React.useMemo<FunctionCardProps>(
    () => ({
      type: 'function',
      function: definitions.getFunction(func.functionId),
    }),
    [definitions, func]
  )
  return (
    <EntityWrapper contentEditable={false}>
      <HoverCardTarget card={cardProps}>
        <_Pill>{props.label}</_Pill>
      </HoverCardTarget>
    </EntityWrapper>
  )
}

const Value: React.FC<EntityComponentProps> = (props) => {
  // TODO: HoverCard を表示したい
  // ここに到達してる時点で、型情報は失われている。
  // Entity を取得しても、RenderableElement.Primitive しか入っていない
  return <_Pill contentEditable={false}>{props.label}</_Pill>
}

export const EntityComponent: React.FC<EntityComponentProps> = (props) => {
  const { label, entity } = props
  const type = entity.type
  switch (type) {
    case EntityType.PROPERTY:
      return <Property {...props} label={label} entity={entity} />
    case EntityType.PRIMITIVE:
      return <Value {...props} label={label} entity={entity} />
    case EntityType.METHOD_CALL_START:
      return <MethodCall {...props} label={label} entity={entity} />
    case EntityType.FUNCTION_CALL_START:
      return <FunctionCall {...props} label={label} entity={entity} />
    case EntityType.CALL_END:
      return <Value {...props} label={label} entity={entity} />
    case EntityType.UNSUPPORTED:
      return <Unsupported {...props} label={label} entity={entity} />
    default:
      return assertNever(type)
  }
}
