import * as React from 'react'

import { DropdownMenuItem } from '~/components/atoms/DropdownMenu'
import MoreActionsButtonWithDropdownMenu from '~/components/molecules/MoreActionsButtonWithDropdownMenu'

interface Props {
  deletable: boolean
  duplicatable: boolean
  renameable: boolean
  onDeleteClick: () => void
  onDuplicateClick: () => void
  onRenameClick: () => void
}

export const WorkflowListMoreActions: React.FC<Props> = (props) => {
  const { onRenameClick, onDuplicateClick, onDeleteClick } = props

  const items: DropdownMenuItem[] = React.useMemo(() => {
    const res: DropdownMenuItem[] = []
    if (props.renameable) {
      res.push({
        id: 'rename',
        label: '名前を変更',
        role: 'normal',
      })
    }
    if (props.duplicatable) {
      res.push({
        id: 'duplicate',
        label: '複製',
        role: 'normal',
      })
    }
    if (props.deletable) {
      res.push({
        id: 'delete',
        label: '削除',
        role: 'danger',
      })
    }
    return res
  }, [props.renameable, props.duplicatable, props.deletable])

  const handleItemClick = React.useCallback(
    (item: DropdownMenuItem) => {
      switch (item.id) {
        case 'rename':
          onRenameClick()
          return
        case 'duplicate':
          onDuplicateClick()
          return
        case 'delete':
          onDeleteClick()
          return
        default:
          throw new Error(`Unknown item id: ${item.id}`)
      }
    },
    [onDeleteClick, onDuplicateClick, onRenameClick]
  )

  if (items.length === 0) {
    return null
  }

  return (
    <MoreActionsButtonWithDropdownMenu
      handleMenuItemClick={handleItemClick}
      horizontalAlign={'left'}
      items={items}
    />
  )
}
