import styled from '@emotion/styled'
import _ from 'lodash'
import React from 'react'
import * as Icon from 'react-feather'
import { useHistory } from 'react-router'
import { formatRoute } from 'react-router-named-routes'

import Button from '~/components/atoms/Button'
import Text from '~/components/atoms/Text'
import App from '~/components/molecules/App'
import { Recipe } from '~/domain/recipe/Recipe'
import { RecipeWizard } from '~/domain/recipe/RecipeWizard'
import { RecipeWizardStepValidator } from '~/domain/recipe/RecipeWizardStepValidator'
import { AppDefinition } from '~/domain/workflow/app/AppDefinition'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { CompositeValidator } from '~/domain/workflow/validator/CompositeValidator'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import { useToaster } from '~/presentation/ToasterContext'
import RecipeWizardExpectedObjectProvider from '~/presentation/recipe/create/RecipeWizardExpectedObjectProvider'
import RecipeWizardNavigator, {
  RecipeWizardValidationStatus,
} from '~/presentation/recipe/create/RecipeWizardNavigator'
import {
  getFieldLabelSuffix,
  getTargetDefinition,
} from '~/presentation/recipe/create/utils'
import { useMapState } from '~/presentation/useMapState'
import InputForm from '~/presentation/workflow/detail/editor/form/InputForm'
import {
  ValidationContext,
  ValidationContextInterface,
  useValidationContext,
} from '~/presentation/workflow/detail/editor/form/validation/ValidationContext'
import VariableFinderHolder from '~/presentation/workflow/detail/editor/form/variableFinder/VariableFinderHolder'
import { EmptyExpectedObjectSnapshotsProvider } from '~/presentation/workflow/detail/editor/test/ExpectedObjectSnapshotsContext'
import { useCreateWorkflow } from '~/presentation/workflow/useCreateWorkflow'
import * as routes from '~/routes'
import { scrollbarStyle } from '~/styles/scrollbar'
import * as vars from '~/styles/variables'

interface Props {
  recipe: Recipe
}

const RecipeWizardComponent: React.FC<Props> = (props) => {
  const history = useHistory()
  const toaster = useToaster()
  const definitions = useDefinitions()
  const validator = React.useRef(new CompositeValidator())
  const [wizard, setWizard] = React.useState<RecipeWizard>(
    new RecipeWizard(props.recipe)
  )
  const [stepNumber, setStepNumber] = React.useState<number>(0)
  const [stepValidating, setStepValidating] = React.useState<boolean>(false)
  const [workflowCreating, setWorkflowCreating] = React.useState<boolean>(false)
  const [
    isFullValidationRequired,
    setIsFullValidationRequired,
  ] = React.useState<boolean>(false)
  const [
    currentStepInputsChanged,
    setCurrentStepInputsChanged,
  ] = React.useState<boolean>(false)
  const [validationResults, putValidationResult] = useMapState<
    RecipeWizardValidationStatus
  >()
  const [
    currentStepInputs,
    putCurrentStepInputs,
    ,
    ,
    setCurrentStepInputs,
  ] = useMapState<InputValue>()

  const { createWorkflow } = useCreateWorkflow()

  const validationContextValue: ValidationContextInterface = React.useMemo(
    () => ({ validator: validator.current }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validator.current]
  )

  // ステップが切り替わった時に currentStepInputs をセットし直す
  React.useEffect(() => {
    if (wizard === undefined) {
      setCurrentStepInputs({})
      setCurrentStepInputsChanged(false)
      return
    }
    setCurrentStepInputs(wizard.getStep(stepNumber).inputs)
    setCurrentStepInputsChanged(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizard, stepNumber])

  // 全ステップバリデーションチェックが有効な状態で wizard が変更された時にバリデーションチェックを行う
  React.useEffect(() => {
    if (wizard === undefined || !isFullValidationRequired) {
      return
    }
    const stepValidator = new RecipeWizardStepValidator(wizard, definitions)
    _.range(0, wizard.stepCount).forEach((i) => {
      putValidationResult(String(i), 'pending')
      stepValidator
        .validate(i)
        .then((result) => {
          putValidationResult(String(i), result.valid ? 'valid' : 'invalid')
        })
        .catch((reason) => {
          console.error(reason)
          putValidationResult(String(i), 'invalid')
        })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizard, isFullValidationRequired])

  const hasPrev = stepNumber > 0
  const hasNext = stepNumber < wizard.stepCount - 1
  const step = React.useMemo(() => wizard.getStep(stepNumber), [
    wizard,
    stepNumber,
  ])
  const target = React.useMemo(
    () => getTargetDefinition(props.recipe, step, definitions),
    [props.recipe, step, definitions]
  )
  // バルク可能なフィールドが一つでもあれば、自動的にバルクモードにする
  const bulkMode = React.useMemo<boolean>(() => {
    return target.fields.some((it) => {
      if (it.fieldType === 'info_view') {
        return false
      }
      return it.bulkable
    })
  }, [target])
  // 必須フィールドのみ ＆ フィールドのラベルを変更
  const targetFields = React.useMemo(
    () =>
      target.fields
        .filter((it) => it.fieldType === 'info_view' || it.required)
        .map((field) => {
          if (field.fieldType === 'static') {
            return {
              ...field,
              label: `${field.label} ${getFieldLabelSuffix(field.form)}`,
            }
          }
          return field
        }),
    [target.fields]
  )

  const handleHeaderBackClick = () => {
    history.push(
      formatRoute(routes.RECIPE_DETAIL, { recipeId: props.recipe.id })
    )
  }

  const handleNavigatorClick = (targetStepNumber: number) => {
    if (currentStepInputsChanged) {
      setWizard(wizard.setStepInputs(stepNumber, currentStepInputs))
    }
    setStepNumber(targetStepNumber)
  }

  const handleNextClick = async () => {
    try {
      setStepValidating(true)
      if (
        (await validator.current.validate()).some(CompositeValidator.isInvalid)
      ) {
        return
      }
    } catch (e) {
      console.error(e)
      toaster.showError('バリデーションに失敗しました')
      return
    } finally {
      setStepValidating(false)
    }
    if (currentStepInputsChanged) {
      setWizard(wizard.setStepInputs(stepNumber, currentStepInputs))
    }
    setStepNumber(stepNumber + 1)
  }

  const handleCreateClick = async () => {
    try {
      setStepValidating(true)
      if (
        (await validator.current.validate()).some(CompositeValidator.isInvalid)
      ) {
        return
      }
    } catch (e) {
      console.error(e)
      toaster.showError('バリデーションに失敗しました')
      return
    } finally {
      setStepValidating(false)
    }
    let newWizard = wizard
    if (currentStepInputsChanged) {
      newWizard = wizard.setStepInputs(stepNumber, currentStepInputs)
      setWizard(newWizard)
    }
    if (!isFullValidationRequired) {
      setIsFullValidationRequired(true)
    }
    try {
      setStepValidating(true)
      const stepValidator = new RecipeWizardStepValidator(
        newWizard,
        definitions
      )
      const results = await Promise.all(
        _.range(0, newWizard.stepCount).map((i) => stepValidator.validate(i))
      )
      if (results.some(CompositeValidator.isInvalid)) {
        toaster.showError('入力が完了していないステップがあります')
        return
      }
    } catch (e) {
      console.error(e)
      toaster.showError('いずれかのステップのバリデーションに失敗しました')
      return
    } finally {
      setStepValidating(false)
    }

    // ワークフローの作成
    setWorkflowCreating(true)
    try {
      const workflowSourceBody = newWizard.createWorkflowSourceBody()
      const workflow = await createWorkflow(
        workflowSourceBody,
        props.recipe.name
      )
      history.push(
        formatRoute(routes.WORKFLOW_DETAIL, { workflowId: workflow.workflowId })
      )
    } catch (e) {
      console.error(e)
      toaster.showError('ワークフローの作成に失敗しました')
    } finally {
      setWorkflowCreating(false)
    }
  }

  const handlePrevClick = () => {
    if (currentStepInputsChanged) {
      setWizard(wizard.setStepInputs(stepNumber, currentStepInputs))
    }
    setStepNumber(stepNumber - 1)
  }

  if (wizard.stepCount === 0) {
    return <p>ステップがありません</p>
  }

  if (stepNumber < 0 || stepNumber >= wizard.stepCount) {
    setStepNumber(0)
    return <p>不正なステップ番号です</p>
  }

  return (
    <>
      <RootContainer>
        <HeaderLeftContainer>
          <HeaderBackButton onClick={handleHeaderBackClick}>
            <Icon.ChevronLeft size={24} color={vars.color.icon} />
            <Text
              element="span"
              color={vars.fontColor.tertiary}
              fontSize="s"
              fontWeight="bold"
            >
              レシピページへ戻る
            </Text>
          </HeaderBackButton>
        </HeaderLeftContainer>
        <HeaderContainer>
          <RecipeTitle element="h1" fontWeight="bold" fontSize="s">
            {props.recipe.name}
          </RecipeTitle>
        </HeaderContainer>
        <HeaderRightContainer />
        <NavigatorContainer>
          <RecipeWizardNavigator
            recipe={props.recipe}
            steps={wizard.getSteps()}
            currentStepNumber={stepNumber}
            validationResults={validationResults}
            onStepClick={(targetStepNumber) => {
              handleNavigatorClick(targetStepNumber)
            }}
          />
        </NavigatorContainer>
        <ContentContainer>
          <ValidationContext.Provider value={validationContextValue}>
            <RecipeWizardExpectedObjectProvider
              wizard={wizard}
              stepNumber={stepNumber}
              // key を指定することで stepNumber が変更された時にこのコンポーネントを再マウントする
              // コンポーネントが使い回されると RenderWidget などがコンポーネントの再利用を考慮できていなくて、
              // ExpectedObjects が古いままになってしまったりする
              key={stepNumber}
            >
              <EmptyExpectedObjectSnapshotsProvider>
                <RecipeStepContainer>
                  <RecipeStepHeader
                    app={definitions.getApp(target.appId)}
                    title={`${target.name} の設定をしましょう！`}
                  />
                  <RecipeStepForm>
                    <VariableFinderHolder offsetRight={vars.space.m}>
                      <InputForm
                        fields={targetFields}
                        bulkMode={bulkMode}
                        readonly={false}
                        inputValues={currentStepInputs}
                        onInputChange={(fieldKey, newInput) => {
                          if (!currentStepInputsChanged) {
                            setCurrentStepInputsChanged(true)
                          }
                          putCurrentStepInputs(fieldKey, newInput)
                        }}
                      />
                      {/* useEffect の発火タイミングの関係で InputForm よりあとに定義する必要がある */}
                      <AutoValidator enabled={isFullValidationRequired} />
                    </VariableFinderHolder>
                  </RecipeStepForm>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>
                      {hasPrev && (
                        <Button type="tertiary" onClick={handlePrevClick}>
                          戻る
                        </Button>
                      )}
                    </span>
                    <span>
                      {hasNext ? (
                        <Button
                          onClick={handleNextClick}
                          loading={stepValidating}
                        >
                          次へ
                        </Button>
                      ) : (
                        <Button
                          onClick={handleCreateClick}
                          loading={stepValidating || workflowCreating}
                        >
                          作成
                        </Button>
                      )}
                    </span>
                  </div>
                </RecipeStepContainer>
              </EmptyExpectedObjectSnapshotsProvider>
            </RecipeWizardExpectedObjectProvider>
          </ValidationContext.Provider>
        </ContentContainer>
      </RootContainer>
    </>
  )
}

export default RecipeWizardComponent

const AutoValidator: React.FC<{
  enabled: boolean
}> = (props) => {
  const { validator } = useValidationContext()
  React.useEffect(() => {
    if (!props.enabled) {
      return
    }
    validator
      .validate()
      .then((result) => {
        console.debug('AutoValidator: validation result: ', result)
      })
      .catch((reason) => {
        console.error(reason)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return null
}

const RecipeStepHeader: React.FC<{ app: AppDefinition; title: string }> = (
  props
) => {
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <App icon={props.app.iconPath} color={props.app.color} size="m" />
        <Text
          element="h1"
          fontSize="xxl"
          fontWeight="bold"
          style={{ marginLeft: vars.space.m }}
        >
          {props.title}
        </Text>
      </div>
      <Text element="p" fontSize="m" style={{ marginTop: vars.space.m }}>
        項目を入力して設定を完了させましょう！
      </Text>
    </div>
  )
}

const MEDIA_SMALL_HEIGHT = '@media (max-height: 768px)'

const RootContainer = styled('div')({
  height: '100%',
  display: 'grid',
  gridTemplateAreas: `
          'headerLeft header headerRight'
          'left content right'
          'footer footer footer'
        `,
  gridTemplateRows: '60px minmax(0, 1fr) 60px',
  gridTemplateColumns: '1fr 550px 1fr',
  [MEDIA_SMALL_HEIGHT]: {
    gridTemplateRows: '60px minmax(0, 1fr) 30px',
  },
})

const HeaderLeftContainer = styled('div')({
  width: '100%',
  height: '100%',
  gridArea: 'headerLeft',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: vars.colorPalette.gray0,
})

const HeaderContainer = styled('div')({
  gridArea: 'header',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: vars.colorPalette.gray0,
})

const HeaderRightContainer = styled('div')({
  gridArea: 'headerRight',
  backgroundColor: vars.colorPalette.gray0,
})

const NavigatorContainer = styled('div')({
  width: '100%',
  gridArea: 'left',
  paddingTop: 100,
  paddingRight: vars.space.xl,
  justifySelf: 'end',
  [MEDIA_SMALL_HEIGHT]: {
    paddingTop: 50,
  },
})

const ContentContainer = styled('div')({
  gridArea: 'content',
  paddingTop: 100,
  [MEDIA_SMALL_HEIGHT]: {
    paddingTop: 50,
  },
})

const RecipeTitle = styled(Text)({
  textAlign: 'center',
})

const HeaderBackButton = styled('div')({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  marginLeft: vars.space.l,
})

const RecipeStepContainer = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})

const RecipeStepForm = styled('div')({
  marginTop: vars.space.l,
  marginBottom: vars.space.l,
  flexGrow: 1,
  overflowY: 'scroll',
  ...scrollbarStyle,
})
