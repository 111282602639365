import * as React from 'react'

import Text from '~/components/atoms/Text'
import { AssistedValue } from '~/domain/workflow/assist/AssistedValue'
import * as vars from '~/styles/variables'
import { decreaseFontSize } from '~/styles/variables'

interface Props {
  item: AssistedValue
  oneLine?: boolean
  fontSize?: keyof typeof vars.fontSize
}

const AssistItem: React.FC<Props> = (props) => {
  if (props.oneLine === true) {
    return (
      <Text element="span" fontSize={props.fontSize} lineHeight="heading">
        {props.item.label}
        <Text
          element="span"
          color="tertiary"
          fontSize={props.fontSize}
          lineHeight="heading"
          style={{ marginLeft: vars.space.s }}
        >
          &lt;{props.item.value}&gt;
        </Text>
      </Text>
    )
  }
  return (
    <div>
      <Text element="p" fontSize={props.fontSize} lineHeight="heading">
        {props.item.label}
      </Text>
      <Text
        element="span"
        color="tertiary"
        fontSize={decreaseFontSize(props.fontSize ?? 'm')}
        lineHeight="heading"
        style={{ marginTop: vars.space.xxs }}
      >
        {props.item.value}
      </Text>
    </div>
  )
}

export default AssistItem
