import styled from '@emotion/styled'
import * as React from 'react'
import { useHistory } from 'react-router'

import Loader from '~/components/atoms/Loader'
import { CreateOrganizationForm } from '~/presentation/auth/CreateOrganizationForm'
import { useMeState } from '~/presentation/auth/useMeState'
import * as routes from '~/routes'
import * as vars from '~/styles/variables'

export const CreateOrganizationPage: React.FC = () => {
  const history = useHistory()
  const { result } = useMeState()

  const handleOrganizationCreate = React.useCallback(() => {
    history.push(routes.WORKFLOW_LIST)
  }, [history])

  if (result.loading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    )
  }

  if (result.error !== undefined) {
    // me が取れない場合は未ログインとみなす
    history.push(routes.SIGNIN)
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    )
  }

  if (result.data === 'normal-user') {
    // me が取得できた状態
    history.push(routes.WORKFLOW_LIST)
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    )
  }

  return (
    <Container>
      <Card>
        <Header>企業情報を入力</Header>
        <Description>企業名を入力してください。</Description>
        <CreateOrganizationForm
          onOrganizationCreate={handleOrganizationCreate}
        />
      </Card>
    </Container>
  )
}

const Container = styled('div')({
  width: '100%',
  height: '100%',
  backgroundColor: vars.color.offWhite,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const Card = styled('div')({
  width: 650,
  borderRadius: vars.borderRadius.m,
  backgroundColor: vars.color.white,
  boxShadow: vars.shadow.m,
  padding: vars.space.xl,
})

const Header = styled('h5')({
  textAlign: 'center',
  fontSize: vars.fontSize.xl,
  lineHeight: vars.lineHeight.heading,
  position: 'relative',
  '&::before': {
    content: 'url("/static/icons/organization.svg")',
    paddingRight: vars.space.s,
    position: 'absolute',
    transform: 'translateX(-100%)',
  },
})

const Description = styled('div')({
  textAlign: 'center',
  fontSize: vars.fontSize.s,
  marginTop: vars.space.m,
})

const LoaderContainer = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
})
