import styled from '@emotion/styled'
import React from 'react'

import * as vars from '~/styles/variables'

type Media = { imagePath: string } | { videoUrl: string }
interface Props {
  title: string
  description: string
  media: Media
}

const UseCaseCard: React.FC<Props> = (props) => {
  return (
    <Container>
      <div>
        <Heading>{props.title}</Heading>
        <Description>{props.description}</Description>
      </div>
      {isImageMedia(props.media) ? (
        <img
          alt={props.title}
          src={props.media.imagePath}
          width="530"
          height="365"
          style={{ width: '100%', objectFit: 'contain' }}
        />
      ) : (
        <iframe
          title={props.title}
          src={props.media.videoUrl}
          width="530"
          height="365"
          allowFullScreen
          style={{ width: '100%', border: 'none' }}
        />
      )}
    </Container>
  )
}

function isImageMedia(media: Media): media is { imagePath: string } {
  return 'imagePath' in media
}

const Container = styled('div')({
  width: 1000,
  padding: vars.space.l,
  margin: 32,
  display: 'grid',
  gap: vars.space.m,
  gridTemplateColumns: '50% 50%',
  boxShadow: vars.shadow.l,
  borderRadius: vars.borderRadius.m,
  [vars.media.tablet]: {
    width: 'unset',
    padding: 20,
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'none',
  },
})

const Heading = styled('h1')({
  fontSize: 28,
  fontWeight: 800,
  lineHeight: vars.lineHeight.heading,
  marginBottom: vars.space.l * 1.5,
  whiteSpace: 'pre-wrap',
  [vars.media.tablet]: {
    fontSize: 18,
    textAlign: 'center',
  },
})

const Description = styled('p')({
  fontSize: 19,
  fontWeight: 500,
  lineHeight: vars.lineHeight.body,
  [vars.media.tablet]: {
    fontSize: 13,
  },
})

export default UseCaseCard
