import hash from 'object-hash'
import React from 'react'

import { PendingResult } from '~/common/types'
import { TestPreviewServiceImpl } from '~/data/workflow/test/TestPreviewServiceImpl'
import { InputSource } from '~/domain/workflow/source/WorkflowSourceBody'
import { TestPreview } from '~/domain/workflow/test/TestPreview'
import { TestTarget } from '~/domain/workflow/test/TestTarget'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import { useExpectedObjectsContext } from '~/presentation/workflow/detail/editor/form/expectedObject/ExpectedObjectsContext'
import { useExpectedObjectSnapshots } from '~/presentation/workflow/detail/editor/test/ExpectedObjectSnapshotsContext'

const testPreviewService = new TestPreviewServiceImpl()

export function useTestPreviewService(
  target: TestTarget,
  inputs: InputSource[],
  useBulk: boolean
): PendingResult<TestPreview> {
  const definitions = useDefinitions()
  const [result, setResult] = React.useState<PendingResult<TestPreview>>(
    PendingResult.pending()
  )
  const { expectedObjects } = useExpectedObjectsContext()
  const snapshots = useExpectedObjectSnapshots()

  React.useEffect(() => {
    setResult(PendingResult.pending())
    testPreviewService
      .getPreview(
        target,
        inputs,
        useBulk,
        expectedObjects,
        snapshots.getAll(),
        definitions
      )
      .then((res) => {
        setResult(PendingResult.success(res))
      })
      .catch((reason) => {
        console.error(reason)
        setResult(PendingResult.failure(undefined, reason))
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(target), inputs, expectedObjects, snapshots])

  return result
}
