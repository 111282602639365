import React, { useMemo } from 'react'

import * as utils from '~/common/utils'
import Status from '~/components/atoms/Status'
import Tooltip from '~/components/utils/Tooltip'
import { RunningStatus } from '~/domain/workflow/instance/RunningStatus'

type Props = {
  status: RunningStatus
}

const StatusWithTooltip: React.FC<Props> = ({ status }) => {
  const message = useMemo(() => {
    switch (status) {
      case RunningStatus.running: {
        return 'ワークフローを現在実行しています'
      }
      case RunningStatus.success: {
        return 'ワークフローの実行に成功しました'
      }
      case RunningStatus.stop:
      case RunningStatus.queueing: {
        return 'ワークフローを実行する準備をしています'
      }
      case RunningStatus.error: {
        return 'ワークフローの実行に失敗しました'
      }
      default: {
        utils.assertNever(status)
      }
    }
  }, [status])

  return (
    <Tooltip
      style={{
        verticalAlign: 'middle',
        cursor: 'pointer',
      }}
      delay={0}
      renderContent={() => message}
    >
      <Status status={status} />
    </Tooltip>
  )
}

export default StatusWithTooltip
