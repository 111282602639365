import React from 'react'

import { DropdownMenuItem } from '~/components/atoms/DropdownMenu'
import MoreActionsButtonWithDropdownMenu from '~/components/molecules/MoreActionsButtonWithDropdownMenu'

interface Props {
  duplicatable: boolean
  deletable: boolean
  onDuplicateClick: () => void
  onDeleteClick: () => void
}

export const WorkflowDetailMoreActions: React.FC<Props> = (props) => {
  const { onDuplicateClick, onDeleteClick } = props

  const items: DropdownMenuItem[] = React.useMemo(() => {
    const res: DropdownMenuItem[] = []
    if (props.duplicatable) {
      res.push({
        id: 'duplicate',
        label: '複製',
        role: 'normal',
      })
    }
    if (props.deletable) {
      res.push({
        id: 'delete',
        label: '削除',
        role: 'danger',
      })
    }
    return res
  }, [props.deletable, props.duplicatable])

  const handleItemClick = React.useCallback(
    (item: DropdownMenuItem) => {
      switch (item.id) {
        case 'duplicate':
          onDuplicateClick()
          return
        case 'delete':
          onDeleteClick()
          return
        default:
          throw new Error(`Unknown item id: ${item.id}`)
      }
    },
    [onDeleteClick, onDuplicateClick]
  )

  if (items.length === 0) {
    return null
  }

  return (
    <MoreActionsButtonWithDropdownMenu
      items={items}
      horizontalAlign="left"
      handleMenuItemClick={handleItemClick}
    />
  )
}
