import * as React from 'react'

export function useFollowState<T>(
  original: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = React.useState<T>(original)

  React.useEffect(() => {
    setState(original)
  }, [original])

  return [state, setState]
}
