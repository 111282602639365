import * as d3 from 'd3'
import * as _ from 'lodash'
import * as React from 'react'

import ActionNodeComp from '~/components/molecules/ActionNode'
import TriggerNodeComp from '~/components/molecules/TriggerNode'
import { TaskInstanceSummary } from '~/domain/workflow/instance/taskInstance/TaskInstanceSummary'
import { EMPTY_ACTION_ID } from '~/ducks/editor/types'
import { Node } from '~/hooks/useTree'
import { useDefinitions } from '~/presentation/AnyflowAppContext'

interface Props {
  nodes: d3.HierarchyPointNode<Node>[]
  taskInstances?: TaskInstanceSummary[]
  selectedTaskId?: string
  onActionClick?: (
    event: React.MouseEvent<SVGCircleElement, MouseEvent>,
    node: d3.HierarchyPointNode<Node>,
    taskInstance?: TaskInstanceSummary
  ) => void
  onTriggerClick?: (node: d3.HierarchyPointNode<Node>) => void
}

const Nodes: React.FC<Props> = ({
  nodes,
  taskInstances,
  selectedTaskId,
  onActionClick = () => undefined,
  onTriggerClick = () => undefined,
}) => {
  const definitions = useDefinitions()
  const renderTriggerNode = (
    node: d3.HierarchyPointNode<Node>,
    key: React.Key
  ) => {
    if (node.data.type !== 'trigger') {
      return null
    }
    const trigger = definitions.getTrigger(node.data.data.triggerId)
    const app = definitions.getApp(trigger.appId)
    return (
      <TriggerNodeComp
        key={key}
        name={trigger.name}
        app={app}
        x={node.y}
        y={node.x}
        readonly={false}
        onClick={() => onTriggerClick(node)}
      />
    )
  }

  const renderActionNode = React.useCallback(
    (node: d3.HierarchyPointNode<Node>, key: React.Key) => {
      if (node.data.type !== 'task') {
        return null
      }
      const action = definitions.getAction(node.data.data.actionId)
      const app = definitions.getApp(action.appId)
      const foundTaskInstance = _.find(
        taskInstances,
        (ti) => ti.taskId === node.data.nodeId
      )
      return (
        <ActionNodeComp
          key={key}
          name={action.name}
          app={app}
          x={node.y}
          y={node.x}
          supportState={action.state}
          runningStatus={foundTaskInstance?.state}
          selected={selectedTaskId === node.data.nodeId}
          readonly={false}
          onClick={(e) => onActionClick(e, node, foundTaskInstance)}
        />
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [taskInstances, selectedTaskId]
  )

  return (
    <g>
      {nodes.map((node: d3.HierarchyPointNode<Node>, key) => {
        // TODO: なぜかitemがundefinedになることがある
        if (!node) {
          return null
        }
        if (node.data.type === 'trigger') {
          return renderTriggerNode(node, key)
        }
        if (node.data.data.actionId === EMPTY_ACTION_ID) {
          return null
        }
        return renderActionNode(node, key)
      })}
    </g>
  )
}

export default Nodes
