import React from 'react'

interface ToasterContextInterface {
  showSuccess(message: string, duration?: number): void
  showError(message: string, duration?: number): void
}

export const ToasterContext = React.createContext<ToasterContextInterface>({
  showSuccess: (_message, _duration): void => {
    throw new Error(`The ToasterContext isn't initialized`)
  },
  showError: (_message, _duration): void => {
    throw new Error(`The ToasterContext isn't initialized`)
  },
})

export function useToaster(): {
  showSuccess: (message: string, duration?: number) => void
  showError: (message: string, duration?: number) => void
} {
  return React.useContext(ToasterContext)
}
