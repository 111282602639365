import { customAlphabet } from 'nanoid'

import * as utils from '~/common/utils'
import { Definitions } from '~/domain/Definitions'
import { ActionDefinition } from '~/domain/workflow/action/ActionDefinition'
import { ActionState } from '~/domain/workflow/action/ActionState'
import {
  InputSource,
  TaskSource,
  TriggerSource,
  WorkflowSourceBody,
} from '~/domain/workflow/source/WorkflowSourceBody'

const getActions = (
  rootTask: TaskSource | undefined,
  definitions: Definitions
): ActionDefinition[] => {
  if (!rootTask) {
    return []
  }
  return utils
    .flattenTaskSource(rootTask)
    .map((task) => definitions.getAction(task.actionId))
}

export class WorkflowSource {
  constructor(
    public readonly sourceId: string,
    public readonly body: WorkflowSourceBody
  ) {}

  createTaskId(): string {
    for (let i = 0; i < 100; i++) {
      const id = customAlphabet('0123456789abcdef', 10)()
      if (this.findTask(id) === undefined) {
        return id
      }
    }
    throw new Error("Couldn't generate new task id within 100 loops.")
  }

  addEmptyTask(newTaskId: string, parentTaskId?: string): WorkflowSource {
    return new WorkflowSource(
      this.sourceId,
      this.body.addEmptyTask(newTaskId, parentTaskId)
    )
  }

  removeAllEmptyTasks(): WorkflowSource {
    return new WorkflowSource(this.sourceId, this.body.removeAllEmptyTasks())
  }

  replaceTask(taskId: string, actionId: string): WorkflowSource {
    return new WorkflowSource(
      this.sourceId,
      this.body.replaceTask(taskId, actionId)
    )
  }

  replaceTrigger(triggerId: string): WorkflowSource {
    return new WorkflowSource(
      this.sourceId,
      this.body.replaceTrigger(triggerId)
    )
  }

  removeTask(taskId: string): WorkflowSource {
    return new WorkflowSource(this.sourceId, this.body.removeTask(taskId))
  }

  setTaskInputs(taskId: string, inputs: InputSource[]): WorkflowSource {
    return new WorkflowSource(
      this.sourceId,
      this.body.setTaskInputs(taskId, inputs)
    )
  }

  setTriggerInputs(inputs: InputSource[]): WorkflowSource {
    return new WorkflowSource(this.sourceId, this.body.setTriggerInputs(inputs))
  }

  findTask(taskId: string): TaskSource | undefined {
    return this.body.findTask(taskId)
  }

  hasDeprecatedAction(definitions: Definitions): boolean {
    const rootTask = this.getRootTask()
    const actions = getActions(rootTask, definitions)
    return actions.some((action) => action.state === ActionState.deprecated)
  }

  hasDeletedAction(definitions: Definitions): boolean {
    const rootTask = this.getRootTask()
    const actions = getActions(rootTask, definitions)
    return actions.some((action) => action.state === ActionState.deleted)
  }

  getTrigger(): TriggerSource | undefined {
    return this.body.getTrigger()
  }

  getRootTask(): TaskSource | undefined {
    return this.body.getRootTask()
  }

  findParentTaskOf(taskId: string): TaskSource | undefined {
    return this.body.findParentTaskOf(taskId)
  }
}
