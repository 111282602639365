import { JsonError } from '~/data/JsonError'
import { Tson } from '~/data/Tson'
import { ObjectPropertyValueJson } from '~/data/workflow/object/json'

export function mapToObjectPropertyValueJson(
  tson: Tson
): ObjectPropertyValueJson {
  const value = tson.getValue('value', false)
  if (value === undefined) {
    // key がないのはダメ
    throw new JsonError(`ObjectPropertyValue value can't be undefined`)
  }
  return {
    propertyKey: tson.getString('propertyKey'),
    value: value !== null ? value.asIs() : null,
  }
}
