import styled from '@emotion/styled'
import * as React from 'react'
import { useHistory } from 'react-router'

import { assertNever } from '~/common/utils'
import { AuthPageLayout } from '~/presentation/auth/AuthPageLayout'
import { GoogleSignInButton } from '~/presentation/auth/GoogleSignInButton'
import { useGoogleSignUp } from '~/presentation/auth/useGoogleSignUp'
import * as routes from '~/routes'
import * as vars from '~/styles/variables'

export const SignUpPage: React.FC = () => {
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState<string>()
  const { signUp } = useGoogleSignUp()
  const history = useHistory()

  const handleClick = React.useCallback(async () => {
    setLoading(true)
    setErrorMessage(undefined)
    try {
      const userState = await signUp()
      if (userState === 'auth-as-normal-user') {
        history.push(routes.WORKFLOW_LIST)
        return
      }
      if (userState === 'auth-as-new-user') {
        history.push(routes.ORGANIZATION_CREATE)
        return
      }
      if (userState === 'not-authenticated') {
        setErrorMessage('認証に失敗しました')
        return
      }
      assertNever(userState)
    } catch (error) {
      console.error(error)
      setErrorMessage(String(error))
    } finally {
      setLoading(false)
    }
  }, [history, signUp])

  return (
    <AuthPageLayout>
      <Typings>Anyflowを新しく始める</Typings>
      <GoogleSignInButton
        label={'Googleアカウントでサインアップ'}
        loading={loading}
        onClick={handleClick}
      />
      {errorMessage !== undefined && <Error>{errorMessage}</Error>}
    </AuthPageLayout>
  )
}

const Typings = styled('h5')({
  fontSize: vars.fontSize.xl,
  marginTop: vars.space.l,
  marginBottom: vars.space.l,
})

const Error = styled('div')({
  marginTop: vars.space.m,
  color: vars.color.error,
  fontSize: vars.fontSize.s,
})
