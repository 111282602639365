import styled from '@emotion/styled'
import * as Sentry from '@sentry/browser'
import * as React from 'react'

import GlobalStyles from '~/components/common/GlobalStyles'
import * as vars from '~/styles/variables'

interface Props {
  children: React.ReactNode
  showLogo?: boolean
  description?: React.ReactNode
}

interface State {
  hasError: boolean
  eventId: string
}

export default class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hasError: false,
    eventId: '',
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ hasError: true })
    console.error(error)
    console.error(errorInfo)
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children
    }
    return (
      <>
        {this.props.showLogo && (
          <a href="/">
            <Logo src="/static/images/logo.svg" width={140} />
          </a>
        )}
        <Container>
          <Illust
            src="/static/images/ship-illust.png"
            srcSet="/static/images/ship-illust.png 1x, /static/images/ship-illust@2x.png 2x"
            width={390}
          />
          <Heading>エラーが発生しました</Heading>
          {this.props.description ? (
            <Description>{this.props.description}</Description>
          ) : (
            <Description>
              申し訳ございません。エラーが発生しました。
              <br />
              ページを更新するか、しばらく待ってから再度お試しください。
            </Description>
          )}
          <GlobalStyles />
          <Feedback>
            問題が解決しない場合は&nbsp;
            <span
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                Sentry.showReportDialog({ eventId: this.state.eventId })
              }}
            >
              問題を報告
            </span>
            &nbsp;してください。
          </Feedback>
        </Container>
      </>
    )
  }
}

const Logo = styled('img')({
  display: 'block',
  marginTop: vars.space.m,
  marginLeft: vars.space.m,
})

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '90%',
  /* バランス取るため */
  marginTop: -80,
})

const Illust = styled('img')({
  display: 'block',
  margin: '0 auto',
})

const Heading = styled('h2')({
  marginTop: vars.space.l * 1.5,
  color: vars.fontColor.secondary,
  fontSize: vars.fontSize.xxl,
  letterSpacing: 2,
  textAlign: 'center',
})

const Description = styled('p')({
  marginTop: vars.space.s,
  color: vars.fontColor.secondary,
  fontSize: vars.fontSize.m,
  lineHeight: 1.8,
  letterSpacing: 1,
  textAlign: 'center',
})

const Feedback = styled('p')({
  marginTop: vars.space.l,
  color: vars.fontColor.secondary,
  fontSize: vars.fontSize.s,
})
