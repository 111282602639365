import styled from '@emotion/styled'
import * as React from 'react'
import { animated, useSpring } from 'react-spring'

import MyLink from '~/components/atoms/Link'
import { handleSmoothScroll } from '~/containers/RecipeLp'
import CtaButton from '~/containers/RecipeLp/CtaButton'
import Inner from '~/containers/RecipeLp/Inner'
import { HOME } from '~/routes'
import * as vars from '~/styles/variables'

const Header: React.FC = () => {
  const [scrollTop, setScrollTop] = React.useState(0)
  const [isFixed, setIsFixed] = React.useState(false)
  const props = useSpring({
    from: { transform: `translateY(-${height / 2}px)`, opacity: 0 },
    to: {
      transform: isFixed ? 'translateY(0)' : `translateY(-${scrollTop}px)`,
      opacity: 1,
    },
  })

  React.useEffect(() => {
    function handleScroll() {
      const documentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop
      const triggerPosition = window.innerHeight / 2
      const triggered = triggerPosition < documentScrollTop
      setIsFixed(triggered)
      if (!triggered) setScrollTop(documentScrollTop)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <Container style={props}>
      <Inner
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <MyLink to={HOME} style={{ lineHeight: 1 }}>
          <img src="/static/images/logo.svg" alt="Anyflow" width={180} />
        </MyLink>
        <ButtonContainer
          href="#cta1"
          onClick={(e) => {
            handleSmoothScroll(e, 'cta1')
          }}
        >
          <CtaButton>お問い合わせ</CtaButton>
        </ButtonContainer>
      </Inner>
    </Container>
  )
}

export const height = 75

const Container = styled(animated.header)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height,
  backgroundColor: vars.color.white,
  boxShadow: vars.shadow.m,
  zIndex: 1000,
})

const ButtonContainer = styled('a')({
  [vars.media.sp]: {
    display: 'none',
  },
})

export default Header
