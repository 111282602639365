import { assert, assertNever } from '~/common/utils'
import { Definitions } from '~/domain/Definitions'
import { Recipe } from '~/domain/recipe/Recipe'
import { RecipeStep } from '~/domain/recipe/RecipeWizard'
import { ActionDefinition } from '~/domain/workflow/action/ActionDefinition'
import { TriggerDefinition } from '~/domain/workflow/trigger/TriggerDefinition'
import { InputWidgetDefinition } from '~/domain/workflow/widget/WidgetDefinition'

export function getFieldLabelSuffix(widget: InputWidgetDefinition): string {
  switch (widget.formType) {
    case 'account':
    case 'assist':
    case 'multi_assist':
    case 'select':
    case 'multi_select':
    case 'radio':
    case 'check':
    case 'multi_provider_account':
    case 'legacy_account':
      return 'を選択して下さい'
    case 'list':
    case 'dict':
    case 'multiline_text':
    case 'number':
    case 'struct':
    case 'text':
    case 'cron':
    case 'salesforce_search_condition':
    case 'date_range':
      return 'を入力して下さい'
    case 'switch':
      return ''
    default:
      assertNever(widget)
  }
}

export function getTargetDefinition(
  recipe: Recipe,
  step: RecipeStep,
  definitions: Definitions
): TriggerDefinition | ActionDefinition {
  switch (step.targetNode.kind) {
    case 'trigger': {
      const triggerId = recipe.getTriggerId()
      assert(triggerId !== undefined)
      return definitions.getTrigger(triggerId)
    }
    case 'task': {
      const task = recipe.source.findTask(step.targetNode.taskId)
      assert(task !== undefined)
      return definitions.getAction(task.actionId)
    }
    default:
      assertNever(step.targetNode)
  }
}
