import styled from '@emotion/styled'
import * as React from 'react'

import * as vars from '~/styles/variables'

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  className?: string
  disabled?: boolean
}

const Container = styled('span')(
  {
    display: 'inline-flex',
    alignItems: 'center',
    paddingRight: vars.space.xs,
    paddingLeft: vars.space.xs,
    height: vars.height.pill,
    color: vars.fontColor.secondary,
    fontSize: vars.fontSize.s,
    fontWeight: vars.fontWeight.bold,
    lineHeight: 1,
    border: `1px solid ${vars.color.border}`,
    borderRadius: vars.borderRadius.s,
    backgroundColor: vars.color.white,
    whiteSpace: 'pre',
  },
  (props: { disabled: boolean }) => ({
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    opacity: props.disabled ? 0.6 : 1,
  })
)

const Pill: React.FC<Props> = ({
  className,
  disabled = false,
  children,
  ...props
}) => (
  <Container className={className} disabled={disabled} {...props}>
    {children}
  </Container>
)

export default React.memo(Pill)
