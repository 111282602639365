import styled from '@emotion/styled'
import * as React from 'react'
import * as Icon from 'react-feather'

import NoAppIcon from '~/components/atoms/NoAppIcon'
import Text from '~/components/atoms/Text'
import App from '~/components/molecules/App'
import { LookupTable } from '~/domain/lookupTable/LookupTable'
import { LookupTableCell } from '~/domain/lookupTable/LookupTableCell'
import { LookupTableColumnSchema } from '~/domain/lookupTable/LookupTableColumnSchema'
import SchemaEditor from '~/presentation/lookupTable/SchemaEditor'
import { useMapState } from '~/presentation/useMapState'
import * as vars from '~/styles/variables'

const HOVER_ICON_CLASS_NAME = 'hover-icon'

interface Props {
  data: LookupTable
  onRequestSchemaChange: (newColumnSchema: LookupTableColumnSchema) => void
  onRequestInsertRow: (cells: LookupTableCell[]) => Promise<void>
  onRequestDeleteRow: (rowId: number) => Promise<void>
  style?: React.CSSProperties
}

const LookupTableView: React.FC<Props> = (props) => {
  const hasManyColumns = props.data.columnSchemas.length > 6
  const [editingSchemaId, setEditingSchemaId] = React.useState<
    number | undefined
  >()
  const [isRowCreatorOpen, setIsRowCreatorOpen] = React.useState<boolean>(false)

  const handleRowCreate = (cells: LookupTableCell[]) => {
    props.onRequestInsertRow(cells).then(() => {
      setIsRowCreatorOpen(false)
    })
  }

  const handleRowCreateCancel = () => {
    setIsRowCreatorOpen(false)
  }

  return (
    <Table style={props.style}>
      <thead>
        {props.data.columnSchemas.map((columnSchema, index) => {
          return (
            <Th
              key={columnSchema.id}
              style={{
                paddingRight: hasManyColumns ? vars.space.s : vars.space.m,
                paddingLeft: hasManyColumns ? vars.space.s : vars.space.m,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: hasManyColumns ? 'flex-start' : 'center',
                  flexDirection: hasManyColumns ? 'column' : 'row',
                }}
              >
                {columnSchema.app === undefined ? (
                  <NoAppIcon style={{ flexShrink: 0 }} />
                ) : (
                  <App
                    icon={columnSchema.app.iconPath}
                    color={columnSchema.app.color}
                    size="xs"
                  />
                )}
                <Text
                  element="span"
                  fontSize="s"
                  lineHeight="heading"
                  style={{
                    marginTop: hasManyColumns ? vars.space.xs : 0,
                    marginLeft: hasManyColumns ? 0 : vars.space.s,
                  }}
                >
                  {columnSchema.label}
                </Text>
                <div
                  style={{
                    marginLeft: 'auto',
                    cursor: 'pointer',
                    position: hasManyColumns ? 'absolute' : 'relative',
                    top: 0,
                    right: vars.space.s,
                  }}
                  onClick={() => {
                    if (editingSchemaId === columnSchema.id) {
                      setEditingSchemaId(undefined)
                    } else {
                      setEditingSchemaId(columnSchema.id)
                    }
                  }}
                >
                  <Icon.MoreHorizontal
                    color={vars.colorPalette.gray8}
                    style={{ marginTop: 8 }}
                  />
                </div>
                {columnSchema.id === editingSchemaId && (
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      transform:
                        index === props.data.columnSchemas.length - 1
                          ? 'translateY(100%)'
                          : `translateY(100%) translateX(90%)`,
                    }}
                  >
                    <SchemaEditor
                      schema={columnSchema}
                      onSubmitClick={props.onRequestSchemaChange}
                      onCloseClick={() => setEditingSchemaId(undefined)}
                    />
                  </div>
                )}
              </div>
            </Th>
          )
        })}
        <Th style={{ width: 100 }} />
      </thead>
      <tbody>
        {isRowCreatorOpen ? (
          <RowCreator
            columnSchemas={props.data.columnSchemas}
            onRequestCreate={handleRowCreate}
            onRequestCancel={handleRowCreateCancel}
          />
        ) : (
          <Tr>
            <Td
              colSpan={props.data.columnSchemas.length + 1}
              onClick={() => setIsRowCreatorOpen(true)}
              style={{ cursor: 'pointer' }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon.Plus color={vars.color.icon} size={24} strokeWidth={2} />
                <Text
                  element="p"
                  color="tertiary"
                  fontSize="s"
                  lineHeight="heading"
                  style={{ marginLeft: vars.space.xs }}
                >
                  新しい行を追加する
                </Text>
              </div>
            </Td>
          </Tr>
        )}
        {props.data.rows.length === 0 && (
          <tr>
            <Td colSpan={props.data.columnSchemas.length + 1}>
              <Text
                element="span"
                color="secondary"
                fontSize="s"
                lineHeight="heading"
              >
                データがありません
              </Text>
            </Td>
          </tr>
        )}
        {props.data.rows.map((row) => (
          <Tr key={row.id}>
            {row.cells.map((cell) => {
              return (
                <Td key={cell.columnSchemaId}>
                  <Text element="span" fontSize="s" lineHeight="heading">
                    <CellValue>{cell.value}</CellValue>
                  </Text>
                </Td>
              )
            })}
            <Td style={{ textAlign: 'center' }}>
              <p style={{ lineHeight: '100%' }}>
                <Icon.Trash2
                  size={18}
                  color={vars.color.icon}
                  strokeWidth={3}
                  onClick={() => props.onRequestDeleteRow(row.id)}
                  className={HOVER_ICON_CLASS_NAME}
                  style={{ cursor: 'pointer' }}
                />
              </p>
            </Td>
          </Tr>
        ))}
      </tbody>
    </Table>
  )
}

const RowCreator: React.FC<{
  columnSchemas: LookupTableColumnSchema[]
  onRequestCreate: (cells: LookupTableCell[]) => void
  onRequestCancel: () => void
}> = (props) => {
  const [values, putValue] = useMapState<string>()

  const handleCommitClick = () => {
    const cells: LookupTableCell[] = props.columnSchemas.map((it) => ({
      columnSchemaId: it.id,
      value: values[String(it.id)] ?? '',
    }))
    props.onRequestCreate(cells)
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.nativeEvent.isComposing) {
      return
    }
    if (e.key === 'Enter') {
      handleCommitClick()
    }
    if (e.key === 'Escape') {
      props.onRequestCancel()
    }
  }

  return (
    <tr>
      {props.columnSchemas.map((columnSchema, i) => (
        <Td key={columnSchema.id} style={{ padding: vars.space.s }}>
          <input
            type="text"
            value={values[String(columnSchema.id)] ?? ''} // 意図せず uncontrolled input にならないように
            onChange={(e) =>
              putValue(String(columnSchema.id), e.currentTarget.value)
            }
            onKeyDown={handleKeyDown}
            autoFocus={i === 0}
            style={{
              width: '100%',
              height: '100%',
              padding: vars.space.s,
              border: `1px solid ${vars.color.border}`,
              borderRadius: vars.borderRadius.s,
            }}
          />
        </Td>
      ))}
      <Td style={{ textAlign: 'center' }}>
        <p style={{ lineHeight: '100%' }}>
          <Icon.Check
            size={18}
            color={vars.color.success}
            strokeWidth={3}
            onClick={handleCommitClick}
            style={{ cursor: 'pointer' }}
          />{' '}
          <Icon.X
            size={18}
            color={vars.color.icon}
            strokeWidth={3}
            onClick={props.onRequestCancel}
            style={{ cursor: 'pointer' }}
          />
        </p>
      </Td>
    </tr>
  )
}

const Table = styled('table')({
  width: '100%',
  maxWidth: vars.width.m,
  margin: '0 auto',
  borderCollapse: 'collapse',
  border: `1px solid ${vars.color.border}`,
  tableLayout: 'fixed',
})

const Tr = styled('tr')({
  [`& .${HOVER_ICON_CLASS_NAME}`]: {
    display: 'none',
  },
  '&:hover': {
    backgroundColor: vars.color.offWhite,
    [`& .${HOVER_ICON_CLASS_NAME}`]: {
      display: 'unset',
    },
  },
})

const Th = styled('th')({
  position: 'relative',
  paddingTop: vars.space.s,
  paddingRight: vars.space.m,
  paddingBottom: vars.space.s,
  paddingLeft: vars.space.m,
  textAlign: 'left',
  border: `1px solid ${vars.color.border}`,
  backgroundColor: vars.color.offWhite,
})

const Td = styled('td')({
  padding: vars.space.m,
  border: `1px solid ${vars.color.border}`,
})

const CellValue = styled('span')({
  display: 'inline-block',
  minHeight: vars.fontSize.s * vars.lineHeight.heading,
})

export default LookupTableView
