import styled from '@emotion/styled'
import * as React from 'react'
import { ChevronDown } from 'react-feather'
import { animated, useSpring } from 'react-spring'

import Loader from '~/components/atoms/Loader'
import Text from '~/components/atoms/Text'
import Focusable from '~/components/common/Focusable'
import Dropdown, { DropdownItem } from '~/components/molecules/Dropdown'
import * as vars from '~/styles/variables'
import {
  widgetAnimationStyle,
  widgetBorderErrorStyle,
  widgetBorderStyle,
  widgetFocusedStyle,
} from '~/styles/widget'

type OptionValue = string

export interface Option<T extends OptionValue> {
  label: string
  value: T
}

interface Props<T extends OptionValue> {
  options: Option<T>[]
  unselectedOption?: Option<T>
  value: string
  loading?: boolean
  disabled?: boolean
  hasError?: boolean
  renderItem?: (item: Option<T>) => React.ReactNode
  onChange: (value: T) => void
}

function SelectField<T extends OptionValue>(props: Props<T>) {
  const [hasFocus, setHasFocus] = React.useState<boolean>(false)
  const [query, setQuery] = React.useState<string>('')

  const chevronProps = useSpring({
    transform: hasFocus ? 'rotate(180deg)' : 'rotate(0deg)',
  })
  const dummyFocusableRef = React.useRef<HTMLDivElement>(null)

  const dropdownItems = React.useMemo(() => {
    const initialDropdownItems =
      props.unselectedOption !== undefined
        ? [props.unselectedOption, ...props.options]
        : [...props.options]
    if (query === '') {
      return initialDropdownItems
    }
    return initialDropdownItems.filter((item) => {
      return item.label.indexOf(query) > -1
    })
  }, [props.unselectedOption, props.options, query])

  const handleQueryChange = React.useCallback((newQuery: string) => {
    setQuery(newQuery)
  }, [])

  const handleItemClick = React.useCallback(
    (item: DropdownItem<T>) => {
      props.onChange(item.value)
      setQuery('')
      dummyFocusableRef.current?.focus()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChange]
  )

  const selectedOption = props.options.find((item) => {
    return item.value === props.value
  })

  return (
    <Container disabled={props.disabled ?? false}>
      <Focusable
        tabFocusable={true}
        onFocus={() => {
          if (props.disabled || props.loading) {
            return
          }
          setHasFocus(true)
        }}
        onBlur={() => {
          setHasFocus(false)
        }}
      >
        <LabelOrQueryField
          value={props.value}
          hasFocus={hasFocus}
          hasError={props.hasError ?? false}
          loading={props.loading ?? false}
          query={query}
          unselectedOption={props.unselectedOption}
          selectedOption={selectedOption}
          renderItem={props.renderItem}
          onQueryChange={handleQueryChange}
        />
        <ChevronContainer style={chevronProps}>
          <ChevronDown size={iconSize} color={vars.color.icon} />
        </ChevronContainer>
        <Dropdown<T>
          emptyLabel="一致する項目が見つかりませんでした"
          isOpen={hasFocus}
          items={dropdownItems}
          renderItem={props.renderItem}
          onItemClick={handleItemClick}
        />
      </Focusable>
      <Focusable ref={dummyFocusableRef} />
    </Container>
  )
}

const iconSize = 20

const Container = styled('div')(
  {
    position: 'relative',
    display: 'block',
    width: '100%',
  },
  (props: { disabled: boolean }) => ({
    cursor: props.disabled ? 'not-allowed' : 'unset',
    opacity: props.disabled ? 0.5 : 'unset',
  })
)

function LabelOrQueryField<T extends OptionValue>(props: {
  value: string
  loading: boolean
  hasFocus: boolean
  hasError: boolean
  query: string
  unselectedOption?: Option<T>
  selectedOption: Option<T> | undefined
  renderItem?: (item: Option<T>) => React.ReactNode
  onQueryChange: (newQuery: string) => void
}) {
  if (props.hasFocus) {
    return (
      <QueryField
        query={props.query}
        placeholder="入力によって絞り込めます"
        onQueryChange={props.onQueryChange}
      />
    )
  }

  if (props.loading) {
    return <LoadingLabelField />
  }

  if (
    props.unselectedOption !== undefined &&
    props.value === props.unselectedOption.value
  ) {
    return (
      <LabelField
        label={props.unselectedOption.label}
        hasError={props.hasError}
        loading={props.loading}
        color={vars.fontColor.tertiary}
      />
    )
  }

  if (props.selectedOption === undefined) {
    return (
      <LabelField
        label="選択肢に存在しない値です"
        hasError={props.hasError}
        loading={props.loading}
        color={vars.fontColor.secondary}
      />
    )
  }

  if (props.renderItem !== undefined) {
    return (
      <InputLike hasError={false} isLoading={props.loading}>
        {props.renderItem(props.selectedOption)}
      </InputLike>
    )
  }

  return (
    <LabelField
      label={props.selectedOption.label}
      hasError={false}
      loading={props.loading}
      color={vars.fontColor.primary}
    />
  )
}

const LabelField: React.FC<{
  label: string
  hasError: boolean
  loading: boolean
  color: string
}> = (props) => {
  return (
    <InputLike
      hasError={props.hasError}
      isLoading={props.loading}
      title={props.label}
    >
      <Text
        element="span"
        fontSize="m"
        lineHeight="heading"
        color={props.color}
        truncated={true}
      >
        {props.label}
      </Text>
    </InputLike>
  )
}

const LoadingLabelField: React.FC = () => {
  return (
    <InputLike hasError={false} isLoading={true}>
      <Loader size="s" />
    </InputLike>
  )
}

const QueryField: React.FC<{
  query: string
  placeholder: string
  onQueryChange: (newQuery: string) => void
}> = (props) => {
  return (
    <Input
      focused={true}
      value={props.query}
      onChange={(e) => props.onQueryChange(e.currentTarget.value)}
      autoFocus={true}
      placeholder={props.placeholder}
    />
  )
}

const Input = styled('input')(
  {
    paddingRight: vars.space.m,
    paddingLeft: vars.space.m,
    display: 'block',
    width: '100%',
    height: vars.height.field,
    color: vars.fontColor.primary,
    fontSize: vars.fontSize.m,
    lineHeight: 1.5,
    outline: 'none',
    border: widgetBorderStyle.border,
    borderRadius: widgetBorderStyle.borderRadius,
    ...widgetAnimationStyle,
    '&::placeholder': {
      color: vars.fontColor.tertiary,
    },
  },
  (props: { focused: boolean }) => {
    return {
      ...(props.focused ? widgetFocusedStyle : {}),
    }
  }
)

const InputLike = styled('div')(
  {
    paddingRight: vars.space.m,
    paddingLeft: vars.space.m,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: vars.height.field,
    color: vars.fontColor.primary,
    fontSize: vars.fontSize.m,
    lineHeight: 1.5,
    outline: 'none',
    backgroundColor: vars.color.white,
    borderRadius: widgetBorderStyle.borderRadius,
    ...widgetAnimationStyle,
    '&:focus': {
      ...widgetFocusedStyle,
    },
  },
  (props: { hasError: boolean; isLoading: boolean }) => ({
    border: props.hasError
      ? widgetBorderErrorStyle.border
      : widgetBorderStyle.border,
    cursor: props.isLoading ? 'not-allowed' : 'unset',
    opacity: props.isLoading ? 0.6 : 'unset',
  })
)

const ChevronContainer = styled(animated.div)({
  position: 'absolute',
  top: vars.height.field / 2 - iconSize / 2, // 上下中央
  right: vars.space.s * 1.5,
  transformOrigin: `${iconSize / 2}px ${iconSize / 2 - 1}px`, // -1 しないとちょっとずれる
  zIndex: 0, // Select より下に,
})

export default SelectField
