import styled from '@emotion/styled'
import React from 'react'

import { assertNever } from '~/common/utils'
import Text from '~/components/atoms/Text'
import AssistItem from '~/components/molecules/AssistField/AssistItem'
import MessageItem from '~/components/molecules/MessageItem'
import NoResults from '~/components/organisms/NoResults'
import {
  TestPreview,
  TestPreviewValue,
} from '~/domain/workflow/test/TestPreview'
import * as vars from '~/styles/variables'

export const NodeTestPreview: React.FC<{
  testPreview: TestPreview
}> = (props) => {
  if (!props.testPreview.success) {
    // 失敗した時（フィールドの型がマッチしない箇所がある等）
    return (
      <>
        {props.testPreview.messages.length === 0 ? (
          <NoResults
            heading="メッセージがありません"
            description="メッセージがある場合ここに表示されます"
          />
        ) : (
          props.testPreview.messages.map((message, i) => (
            <MessageRow key={i}>
              <MessageItem message={message} />
            </MessageRow>
          ))
        )}
      </>
    )
  }
  // NOTE: 暫定対応
  // https://app.asana.com/0/1173380138527467/1181611504936569
  const filteredFields = props.testPreview.fields.filter((it) => {
    return (
      it.fieldKey !== 'default_runner_slug' &&
      it.fieldKey !== 'is_always_default_runner'
    )
  })
  return (
    <>
      {filteredFields.length === 0 && (
        <NoResults
          heading="入力値はありません"
          description="入力値がある場合はここに表示されます"
        />
      )}
      {filteredFields.map((previewField) => {
        return (
          <InputPreviewItem key={previewField.fieldKey}>
            <Text element="p" fontSize="s" fontWeight="bold" lineHeight="just">
              {previewField.fieldLabel}
            </Text>
            <Text
              element="p"
              fontSize="s"
              lineHeight="just"
              style={{ whiteSpace: 'pre', marginTop: vars.space.s }}
            >
              <TestPreviewValueComponent
                previewValue={previewField.previewValue}
              />
            </Text>
          </InputPreviewItem>
        )
      })}
    </>
  )
}

const TestPreviewValueComponent: React.FC<{
  previewValue: TestPreviewValue
  asChild?: boolean
}> = (props) => {
  if (props.previewValue === undefined) {
    return null
  }

  if (props.previewValue.kind === 'plain') {
    return (
      <Text element="span" lineHeight="just" fontSize="s">
        {props.asChild
          ? `"${props.previewValue.value}"`
          : props.previewValue.value}
      </Text>
    )
  }

  if (props.previewValue.kind === 'labeled') {
    return <AssistItem item={props.previewValue} oneLine={true} fontSize="s" />
  }

  if (props.previewValue.kind === 'object') {
    return (
      <div>
        {'{'}
        {props.previewValue.entries.map((entry, i) => (
          <div
            key={i}
            style={{
              display: 'flex',
              alignItems: 'baseline',
              marginLeft: vars.space.s,
            }}
          >
            <Text element="span" lineHeight="just" fontSize="s">
              {entry.keyLabel}
            </Text>
            {': '}
            <TestPreviewValueComponent previewValue={entry.value} />
          </div>
        ))}
        {'}'}
      </div>
    )
  }

  if (props.previewValue.kind === 'array') {
    return (
      <div>
        [
        {props.previewValue.values.map((value, i) => (
          <div key={i} style={{ marginLeft: vars.space.s }}>
            <TestPreviewValueComponent previewValue={value} />
          </div>
        ))}
        ]
      </div>
    )
  }

  if (props.previewValue.kind === 'none') {
    return (
      <div>
        <Text element="p" fontSize="xs" color="tertiary">
          この項目はプレビュー出来ません
        </Text>
      </div>
    )
  }

  assertNever(props.previewValue)
}

const InputPreviewItem = styled('div')({
  marginTop: vars.space.m * 1.5,
  '&:first-of-type': {
    marginTop: 0,
  },
})

const MessageRow = styled('div')({
  marginTop: vars.space.m,
  '&:first-of-type': {
    marginTop: 0,
  },
})
