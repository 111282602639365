import styled from '@emotion/styled'
import * as hash from 'object-hash'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { X } from 'react-feather'
import { animated, useSpring } from 'react-spring'

import { assert } from '~/common/utils'
import Focusable from '~/components/common/Focusable'
import Tabs from '~/components/organisms/Tabs'
import { ValueType } from '~/domain/ValueType'
import { RenderableEntity } from '~/domain/workflow/source/RenderableElement'
import { VariableFinder } from '~/domain/workflow/variableFinder/VariableFinder'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import { useExpectedObjectsContext } from '~/presentation/workflow/detail/editor/form/expectedObject/ExpectedObjectsContext'
import FunctionsTabContent from '~/presentation/workflow/detail/editor/form/variableFinder/FunctionsTabContent'
import PrimitivesTabContent from '~/presentation/workflow/detail/editor/form/variableFinder/PrimitivesTabContent'
import VariablesTabContent from '~/presentation/workflow/detail/editor/form/variableFinder/VariablesTabContent'
import { scrollbarStyle } from '~/styles/scrollbar'
import * as vars from '~/styles/variables'

type Tab = 'variables' | 'primitives' | 'functions'

interface Props {
  top: number
  right: number
  isShown: boolean
  targetValueTypes: ValueType[]
  onSelect: (entities: RenderableEntity[]) => void
  onRequestClose: () => void
  onFocusChange: (hasFocus: boolean) => void
}

const VariableFinderView: React.FC<Props> = ({
  top,
  right,
  isShown,
  targetValueTypes,
  onSelect,
  onRequestClose,
  onFocusChange,
}) => {
  const definitions = useDefinitions()
  const { expectedObjects } = useExpectedObjectsContext()
  const [currentTab, setCurrentTab] = React.useState<Tab>('variables')
  const [data, setData] = React.useState<VariableFinder>(
    new VariableFinder(definitions, expectedObjects)
  )

  React.useEffect(() => {
    setData(new VariableFinder(definitions, expectedObjects))
  }, [definitions, expectedObjects])

  const containerRef = React.useRef<HTMLDivElement>(null)
  const containerProps = useSpring({
    opacity: isShown ? 1 : 0,
    transform: isShown ? 'translateX(0)' : `translateX(${vars.space.m}px`,
    config: { tension: 500 },
    onStart() {
      // state を使わずに可視状態を切り替えるため
      if (isShown && containerRef.current !== null) {
        containerRef.current.style.display = 'block'
      }
    },
    onRest() {
      if (!isShown && containerRef.current !== null) {
        containerRef.current.style.display = 'none'
      }
    },
  })

  // イベントハンドラは target が変更されるたびに新しくなるが、イベントハンドラが変更されても
  // 画面の見た目を更新する (DOM を再描画する) 必要はないので、不要な再描画が行われないように
  // メモ化する
  const onSelectRef = React.useRef(onSelect)
  if (onSelectRef.current !== onSelect) {
    onSelectRef.current = onSelect
  }
  const memoizedOnSelect = React.useCallback((entities: RenderableEntity[]) => {
    onSelectRef.current(entities)
  }, [])

  // 変数タブの描画が Expected Object の数に比例してめちゃくちゃ遅くなるのでメモ化する
  const variablesTabContent = React.useMemo(
    () => (
      <VariablesTabContent
        data={data.variablesTab}
        onSelect={memoizedOnSelect}
        targetValueTypes={targetValueTypes}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.variablesTab, memoizedOnSelect, hash(targetValueTypes)]
  )

  const target = document.getElementById('portal-root')
  if (!target) {
    return null
  }

  return (
    <Focusable onFocusChanged={(hasFocus) => onFocusChange(hasFocus)}>
      <Container
        ref={containerRef}
        top={top}
        right={right}
        style={containerProps}
      >
        <WindowBar>
          <Tabs
            tabHeight={40}
            selectedTabId={currentTab}
            tabs={[
              {
                id: 'variables',
                label: '変数',
                nested: false,
              },
              {
                id: 'primitives',
                label: '値',
                nested: false,
              },
              {
                id: 'functions',
                label: '関数',
                nested: false,
              },
            ]}
            onTabClick={(id) => {
              assert(
                id === 'variables' || id === 'primitives' || id === 'functions'
              )
              setCurrentTab(id)
            }}
          />
          <CloseButton onClick={onRequestClose}>
            <X color={vars.color.icon} size={20} />
          </CloseButton>
        </WindowBar>
        <Content>
          {/* コンポーネントのマウントごと外しちゃうとマウントした時の DOM 描画がめちゃくちゃ遅い */}
          <div
            style={{ display: currentTab === 'variables' ? 'block' : 'none' }}
          >
            {variablesTabContent}
          </div>
          <div
            style={{ display: currentTab === 'primitives' ? 'block' : 'none' }}
          >
            <PrimitivesTabContent
              data={data.primitivesTab}
              onSelect={memoizedOnSelect}
              targetValueTypes={targetValueTypes}
            />
          </div>
          <div
            style={{ display: currentTab === 'functions' ? 'block' : 'none' }}
          >
            <FunctionsTabContent
              data={data.functionsTab}
              onSelect={memoizedOnSelect}
              targetValueTypes={targetValueTypes}
            />
          </div>
        </Content>
        {/* ポータルにしないと Container からの座標になってしまう */}
        {ReactDOM.createPortal(
          <Triangle top={top} right={right} style={containerProps} />,
          target
        )}
      </Container>
    </Focusable>
  )
}

const containerHeight = 550
const windowBarHeight = 45

const Container = styled(animated.div)(
  {
    position: 'fixed',
    maxWidth: 400,
    minWidth: 400,
    height: containerHeight,
    borderRadius: vars.borderRadius.m,
    boxShadow: vars.shadow.m,
    zIndex: vars.zIndex.variableFinder,
    '@media (max-width: 1400px)': {
      maxWidth: 350,
      minWidth: 350,
    },
  },
  (props: { top: number; right: number }) => {
    if (window.innerHeight < props.top + containerHeight + vars.space.m) {
      return {
        bottom: vars.space.m,
        right: props.right,
      }
    }
    return {
      // Triangle の位置調整のために引き算
      top: props.top - windowBarHeight - vars.space.m,
      right: props.right,
    }
  }
)

const Triangle = styled(animated.div)(
  {
    position: 'fixed',
    width: 0,
    height: 0,
    borderTop: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderLeft: `10px solid ${vars.color.lightGray}`,
    zIndex: vars.zIndex.variableFinder,
  },
  (props: { top: number; right: number }) => ({
    top: props.top + 10,
    right: props.right - 20,
  })
)

const WindowBar = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: windowBarHeight,
  paddingRight: vars.space.s,
  paddingLeft: vars.space.s,
  borderTopLeftRadius: vars.borderRadius.m,
  borderTopRightRadius: vars.borderRadius.m,
  backgroundColor: vars.color.white,
})

const CloseButton = styled('div')({
  marginRight: vars.space.s,
  height: 20,
  cursor: 'pointer',
})

const Content = styled('div')({
  paddingTop: vars.space.m,
  paddingRight: vars.space.m,
  paddingBottom: 200, // 下に余白があったほうが見やすい
  paddingLeft: vars.space.m,
  height: `calc(100% - ${windowBarHeight}px)`,
  backgroundColor: vars.color.white,
  overflowY: 'scroll',
  borderBottomLeftRadius: vars.borderRadius.s,
  borderBottomRightRadius: vars.borderRadius.s,
  ...scrollbarStyle,
})

export default VariableFinderView
