import * as React from 'react'

import { apiClients } from '~/common/apiClients'
import { TriggerDefinition } from '~/domain/workflow/trigger/TriggerDefinition'
import { TriggerService } from '~/domain/workflow/trigger/TriggerService'

const triggerService: TriggerService = apiClients.triggerService

export function useTriggerDefinitions(): {
  triggers?: TriggerDefinition[]
  fetching: boolean
  errorMessage?: string
} {
  const [triggers, setTriggers] = React.useState<TriggerDefinition[]>()
  const [fetching, setFetching] = React.useState<boolean>(true)
  const [errorMessage, setErrorMessage] = React.useState<string>()
  React.useEffect(() => {
    triggerService
      .getAll()
      .finally(() => setFetching(false))
      .then((it) => setTriggers(it))
      .catch((reason) => {
        console.error(`Failed to get triggers: ${reason}`)
        setErrorMessage(reason)
      })
  }, [])
  return { triggers, fetching, errorMessage }
}

export function useTriggerDefinition(
  triggerId?: string
): {
  trigger?: TriggerDefinition
  fetching: boolean
  errorMessage?: string
} {
  const [trigger, setTrigger] = React.useState<TriggerDefinition>()
  const [fetching, setFetching] = React.useState<boolean>(true)
  const [errorMessage, setErrorMessage] = React.useState<string>()
  React.useEffect(() => {
    if (triggerId === undefined) {
      return
    }
    triggerService
      .get(triggerId)
      .finally(() => setFetching(false))
      .then((it) => setTrigger(it))
      .catch((reason) => {
        console.error(`Failed to get triggers: ${reason}`)
        setErrorMessage(reason)
      })
  }, [triggerId])
  return { trigger, fetching, errorMessage }
}
