import { assert } from '~/common/utils'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { CombinationType } from '~/domain/workflow/widget/salesforceSearchCondition/CombinationType'
import { SalesforceSObject } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSObject'
import { SalesforceSearchConditionGroup } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchConditionGroup'

export class SalesforceSearchCriteria {
  public readonly combinationType: CombinationType
  public readonly groups: SalesforceSearchConditionGroup[]

  constructor(
    combinationType: CombinationType = 'and',
    groups: SalesforceSearchConditionGroup[] = []
  ) {
    this.combinationType = combinationType
    this.groups =
      groups.length === 0 ? [new SalesforceSearchConditionGroup()] : groups
  }

  static fromInputValue(
    sObject: SalesforceSObject,
    inputValue: InputValue
  ): SalesforceSearchCriteria {
    const { combinationType, groups: groupInputValues } = unpack(inputValue)
    const groups = groupInputValues?.map((it) =>
      SalesforceSearchConditionGroup.fromInputValue(sObject, it)
    )
    return new SalesforceSearchCriteria(combinationType, groups)
  }

  setCombinationType(
    combinationType: CombinationType
  ): SalesforceSearchCriteria {
    return new SalesforceSearchCriteria(combinationType, this.groups)
  }

  addGroup(group: SalesforceSearchConditionGroup): SalesforceSearchCriteria {
    return new SalesforceSearchCriteria(this.combinationType, [
      ...this.groups,
      group,
    ])
  }

  updateGroup(
    index: number,
    group: SalesforceSearchConditionGroup
  ): SalesforceSearchCriteria {
    return new SalesforceSearchCriteria(
      this.combinationType,
      this.groups.map((old, i) => (i === index ? group : old))
    )
  }

  removeGroup(index: number): SalesforceSearchCriteria {
    return new SalesforceSearchCriteria(
      this.combinationType,
      this.groups.filter((old, i) => i !== index)
    )
  }

  toInputValue(): InputValue.Raw | undefined {
    if (this.groups.length === 0) {
      return undefined
    }
    if (
      this.groups.length === 1 &&
      this.groups[0].toInputValue() === undefined
    ) {
      return undefined
    }
    return {
      mode: 'raw',
      raw: [
        {
          key: 'combination_type',
          value: { mode: 'raw', raw: this.combinationType },
        },
        {
          key: 'condition_groups',
          value: {
            mode: 'raw',
            raw: this.groups.map((it) => it.toInputValue()),
          },
        },
      ],
    }
  }
}

function unpack(
  inputValue: InputValue
): {
  combinationType?: CombinationType
  groups?: InputValue[]
} {
  if (inputValue === undefined) {
    return {
      combinationType: undefined,
      groups: undefined,
    }
  }
  assert(inputValue.mode === 'raw', 'raw input value required')
  assert(
    InputValue.isStructEntryList(inputValue.raw),
    'struct input value required'
  )

  let combinationType: CombinationType | undefined
  const combinationTypeValue = inputValue.raw.find(
    (it) => it.key === 'combination_type'
  )?.value
  if (combinationTypeValue === undefined) {
    combinationType = undefined
  } else {
    assert(combinationTypeValue.mode === 'raw', 'raw input value required')
    assert(
      typeof combinationTypeValue.raw === 'string',
      'string input value required'
    )
    assert(
      combinationTypeValue.raw === 'and' || combinationTypeValue.raw === 'or',
      'combinationType must be one of "and" or "or"'
    )
    combinationType = combinationTypeValue.raw
  }

  let groups: InputValue[] | undefined
  const groupsValue = inputValue.raw.find((it) => it.key === 'condition_groups')
    ?.value
  if (groupsValue === undefined) {
    groups = undefined
  } else {
    assert(groupsValue.mode === 'raw', 'raw input value required')
    assert(
      InputValue.isInputValueList(groupsValue.raw),
      'input value list required'
    )
    groups = groupsValue.raw
  }

  return { combinationType, groups }
}
