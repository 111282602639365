import { Big } from 'big.js'

import { InputValue } from '~/domain/workflow/source/InputValue'
import { ValidationResult } from '~/domain/workflow/validator/ValidationResult'
import { BaseWidgetDefinition } from '~/domain/workflow/widget/WidgetDefinition'
import { RawInputWidgetValidator } from '~/domain/workflow/widget/validator/RawInputWidgetValidator'

export interface NumberWidgetDefinition extends BaseWidgetDefinition {
  formType: 'number'
  minValue?: number
  maxValue?: number
  step?: number
}

export class NumberWidgetValidator extends RawInputWidgetValidator<
  NumberWidgetDefinition
> {
  async validate(inputValue: InputValue.Raw): Promise<ValidationResult> {
    const definition = this.context.widgetDefinition

    // number ではない値が入っていれば BadFormat
    if (typeof inputValue.raw !== 'number') {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat('不正な値が入力されています')
      )
    }

    // min より小さければ BarFormat
    if (
      definition.minValue !== undefined &&
      inputValue.raw < definition.minValue
    ) {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat(
          `${definition.minValue} より小さい値は入力できません`
        )
      )
    }

    // max より大きければ BadFormat
    if (
      definition.maxValue !== undefined &&
      inputValue.raw > definition.maxValue
    ) {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat(
          `${definition.maxValue} より大きい値は入力できません`
        )
      )
    }

    // step で割り切れなければ BadFormat
    if (
      definition.step !== undefined &&
      Big(inputValue.raw)
        .sub(definition.minValue ?? 0) // min が指定されている場合は基準値は min になる
        .mod(definition.step)
        .gt(0)
    ) {
      if (definition.minValue !== undefined) {
        return ValidationResult.invalid(
          new ValidationResult.BadFormat(
            `${definition.minValue} から ${definition.step} 刻みの値しか入力できません`
          )
        )
      } else {
        return ValidationResult.invalid(
          new ValidationResult.BadFormat(
            `${definition.step} で割り切れない値は入力できません`
          )
        )
      }
    }

    return ValidationResult.valid()
  }
}
