import styled from '@emotion/styled'
import * as React from 'react'

import * as vars from '~/styles/variables'

interface Props {
  icon: string
  size?: 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl'
  className?: string
}

const AppIcon: React.FunctionComponent<Props> = ({
  icon,
  size = 'm',
  className,
}) => (
  <Container className={className} size={size}>
    <Image src={icon} size={size} />
  </Container>
)

const Container = styled('div')<Pick<Props, 'size'>>(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: vars.borderRadius.s,
  },
  (props) => ({
    width:
      props.size === 'xl'
        ? 72
        : props.size === 'l'
        ? 60
        : props.size === 'm'
        ? 40
        : props.size === 's'
        ? 25
        : props.size === 'xs'
        ? 20
        : 15,
    height:
      props.size === 'xl'
        ? 72
        : props.size === 'l'
        ? 60
        : props.size === 'm'
        ? 40
        : props.size === 's'
        ? 25
        : props.size === 'xs'
        ? 20
        : 15,
  })
)

const Image = styled('img')(
  {
    display: 'block',
  },
  (props: { size: string }) => ({
    // 黄金比
    width:
      props.size === 'xl'
        ? 51
        : props.size === 'l'
        ? 42
        : props.size === 'm'
        ? 25
        : props.size === 's'
        ? 15
        : props.size === 'xs'
        ? 13
        : 11,
  })
)

export default React.memo(AppIcon)
