import styled from '@emotion/styled'
import React from 'react'

import { FetchStatus } from '~/common/types'
import { assertNever } from '~/common/utils'
import Loader from '~/components/atoms/Loader'
import {
  ExpectedObjectsContext,
  ExpectedObjectsContextInterface,
} from '~/presentation/workflow/detail/editor/form/expectedObject/ExpectedObjectsContext'
import { useExpectedObjects } from '~/presentation/workflow/detail/editor/form/expectedObject/useExpectedObjects'

interface Props {
  workflowId: string
  parentTaskIdOfTarget?: string
  renderLoader?: () => React.ReactElement
  renderError?: (errorMessage?: string) => React.ReactElement
}

const ExpectedObjectProvider: React.FC<Props> = (props) => {
  const { expectedObjects, errorMessage, fetchStatus } = useExpectedObjects(
    props.workflowId,
    props.parentTaskIdOfTarget
  )

  const expectedObjectContextValue: ExpectedObjectsContextInterface = React.useMemo(
    () => ({ expectedObjects }),
    [expectedObjects]
  )

  const renderChildren = () => {
    if (expectedObjects === undefined) {
      console.warn(
        'The fetch status is loaded but expectedObjects is undefined.'
      )
      return null
    }
    return (
      <ExpectedObjectsContext.Provider value={expectedObjectContextValue}>
        {props.children}
      </ExpectedObjectsContext.Provider>
    )
  }

  switch (fetchStatus) {
    case FetchStatus.none:
    case FetchStatus.loading:
      return props.renderLoader !== undefined ? (
        props.renderLoader()
      ) : (
        <Container>
          <Loader />
        </Container>
      )
    case FetchStatus.failed:
      return props.renderError !== undefined ? (
        props.renderError(errorMessage)
      ) : (
        <Container>{errorMessage && <p>{errorMessage}</p>}</Container>
      )
    case FetchStatus.loaded:
      return renderChildren()
    default:
      assertNever(fetchStatus)
  }
}

export default ExpectedObjectProvider

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
