import * as queryString from 'querystring'

import { Pagination } from '~/common/Pagination'
import config from '~/common/config'
import { MessageType } from '~/common/types'
import { JsonError } from '~/data/JsonError'
import { Tson } from '~/data/Tson'
import requestJson from '~/data/request'
import { MessageSummary } from '~/domain/workflow/instance/message/Message'
import { MessageFilter } from '~/domain/workflow/instance/message/MessageFilter'
import { MessageService } from '~/domain/workflow/instance/message/MessageService'

// Json にマッピングしてから、それをドメインモデルにマッピングするようにしたいので名前は Temp

export class TempMessageService implements MessageService {
  async getList(
    filter: MessageFilter,
    page?: number | string,
    limit?: number
  ): Promise<Pagination<MessageSummary>> {
    const qs = queryString.stringify({
      workflow_instances: filter.workflowInstanceIds,
      task_instances: filter.taskInstanceIds,
      typs: filter.types,
      page,
      limit,
    })
    const res = await requestJson(`${config.apiRoot}/messages?${qs}`)
    const tson = new Tson(res)
    return {
      items: tson.getArray('results').map(mapToMessageSummary),
      paginationMeta: {
        currentPageNumber: tson.getNumber('current'),
        totalCount: tson.getNumber('count'),
        countPerPage: tson.getNumber('limit'),
      },
    }
  }
}

function mapToMessageSummary(tson: Tson): MessageSummary {
  return {
    messageId: tson.getString('slug'),
    type: mapToMessageType(tson.getString('typ')),
    body: tson.getValue('body').getString('text'),
    updatedAt: tson.getString('updatedAt'),
  }
}

export function mapToMessageType(type: string): MessageType {
  switch (type) {
    case 'info':
      return MessageType.info
    case 'warning':
      return MessageType.warning
    case 'error':
      return MessageType.error
    default:
      throw new JsonError(`Unknown message type: ${type}`)
  }
}
