import { assert, assertNever } from '~/common/utils'
import { ValidationResult } from '~/domain/workflow/validator/ValidationResult'
import { AccountWidgetValidator } from '~/domain/workflow/widget/account'
import { AssistWidgetValidator } from '~/domain/workflow/widget/assist'
import { CronWidgetValidator } from '~/domain/workflow/widget/cron'
import { DateRangeWidgetValidator } from '~/domain/workflow/widget/dateRange'
import { ListWidgetValidator } from '~/domain/workflow/widget/list'
import { MultiProviderAccountWidgetValidator } from '~/domain/workflow/widget/multiProviderAccount'
import { MultiAssistWidgetValidator } from '~/domain/workflow/widget/multi_assist'
import { MultilineTextWidgetValidator } from '~/domain/workflow/widget/multilineText'
import { NumberWidgetValidator } from '~/domain/workflow/widget/number'
import { SalesforceSearchConditionWidgetValidator } from '~/domain/workflow/widget/salesforceSearchCondition/validator'
import {
  MultiSelectWidgetValidator,
  SelectWidgetValidator,
} from '~/domain/workflow/widget/select'
import { StructWidgetValidator } from '~/domain/workflow/widget/struct'
import { SwitchWidgetValidator } from '~/domain/workflow/widget/switch'
import { TextWidgetValidator } from '~/domain/workflow/widget/text'
import { InputWidgetValidatorContext } from '~/domain/workflow/widget/validator/InputWidgetValidator'
import { RawInputWidgetValidator } from '~/domain/workflow/widget/validator/RawInputWidgetValidator'

export function createRawWidgetValidator(
  context: InputWidgetValidatorContext
): RawInputWidgetValidator {
  switch (context.widgetDefinition.formType) {
    case 'text': {
      return new TextWidgetValidator({
        ...context,
        widgetDefinition: context.widgetDefinition,
      })
    }
    case 'multiline_text': {
      return new MultilineTextWidgetValidator(context)
    }
    case 'list': {
      assert(
        context.valueType.typeName === 'list',
        `Invalid value type for ListWidgetValidator: ${context.valueType.typeName}`
      )
      return new ListWidgetValidator({
        ...context,
        widgetDefinition: context.widgetDefinition,
        valueType: context.valueType,
      })
    }
    case 'assist': {
      return new AssistWidgetValidator({
        ...context,
        widgetDefinition: context.widgetDefinition,
      })
    }
    case 'multi_assist': {
      return new MultiAssistWidgetValidator({
        ...context,
        widgetDefinition: context.widgetDefinition,
      })
    }
    case 'account': {
      return new AccountWidgetValidator({
        ...context,
        widgetDefinition: context.widgetDefinition,
      })
    }
    case 'multi_provider_account': {
      return new MultiProviderAccountWidgetValidator({
        ...context,
        widgetDefinition: context.widgetDefinition,
      })
    }
    case 'select': {
      return new SelectWidgetValidator({
        ...context,
        widgetDefinition: context.widgetDefinition,
      })
    }
    case 'multi_select': {
      return new MultiSelectWidgetValidator({
        ...context,
        widgetDefinition: context.widgetDefinition,
      })
    }
    case 'struct': {
      assert(
        context.valueType.typeName === 'struct',
        `Invalid value type for StructWidgetValidator: ${context.valueType.typeName}`
      )
      return new StructWidgetValidator({
        ...context,
        widgetDefinition: context.widgetDefinition,
        valueType: context.valueType,
      })
    }
    case 'switch': {
      return new SwitchWidgetValidator({
        ...context,
        widgetDefinition: context.widgetDefinition,
      })
    }
    case 'number': {
      return new NumberWidgetValidator({
        ...context,
        widgetDefinition: context.widgetDefinition,
      })
    }
    // eslint-disable-next-line no-fallthrough
    case 'check':
    case 'radio':
    case 'dict':
    case 'legacy_account': {
      // 非対応ウィジェットなので単に invalid を報告する
      return new (class extends RawInputWidgetValidator {
        async validate(): Promise<ValidationResult> {
          return ValidationResult.invalid(
            new ValidationResult.BadFormat('このウィジェットは使用できません')
          )
        }
      })(context)
    }
    case 'cron': {
      return new CronWidgetValidator({
        ...context,
        widgetDefinition: context.widgetDefinition,
      })
    }
    case 'salesforce_search_condition': {
      return new SalesforceSearchConditionWidgetValidator({
        ...context,
        widgetDefinition: context.widgetDefinition,
      })
    }
    case 'date_range': {
      return new DateRangeWidgetValidator({
        ...context,
        widgetDefinition: context.widgetDefinition,
      })
    }
    default: {
      assertNever(context.widgetDefinition)
    }
  }
}
