import styled from '@emotion/styled'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'

import Button from '~/components/atoms/Button'
import Text from '~/components/atoms/Text'
import Inner from '~/components/organisms/Inner'
import { StaticTemplate } from '~/components/templates/StaticTemplate'
import { HOME } from '~/routes'
import * as vars from '~/styles/variables'

interface OwnProps {}

type Props = OwnProps & RouteComponentProps

export const ThankYou: React.FC<Props> = () => {
  return (
    <StaticTemplate>
      <_Inner responsive={true}>
        <Text element="h2" fontSize="xxxl" fontWeight="bold" textAlign="center">
          お問い合わせを受け付けました
        </Text>
        <Description element="p" lineHeight="body" textAlign="center">
          お問い合わせいただき、ありがとうございます。
          <br />
          後日、ご連絡いたしますのでしばらくお待ち下さい。
        </Description>
        <Description element="p" lineHeight="body" textAlign="center">
          3営業日経ってもご連絡がない場合は、
          <br />
          本お問い合わせが届いていない場合があるため、改めてご連絡をお願いいたします。
        </Description>
        <ButtonContainer style={{ textAlign: 'center' }}>
          <Button type="primary" to={HOME}>
            ホームに戻る
          </Button>
        </ButtonContainer>
      </_Inner>
    </StaticTemplate>
  )
}

const _Inner = styled(Inner)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  paddingTop: vars.space.l,
  paddingBottom: vars.space.l,
})

const Description = styled(Text)({
  marginTop: vars.space.m,
})

const ButtonContainer = styled('div')({
  marginTop: vars.space.l,
})
