import {
  AuthErrorJson,
  AuthHeaderErrorJson,
  InvitationAuthTokenErrorJson,
  InvitationSignUpErrorJson,
  InvitationTokenErrorJson,
  SignInErrorJson,
  SignUpErrorJson,
  UserNotFoundErrorJson,
} from '~/data/auth/AuthErrorJson'

export const defaultErrorMessage = {
  signUp: 'サインアップに失敗しました',
  signIn: 'サインインに失敗しました',
  invitationSignUp: 'サインアップに失敗しました',
}

export function mapJsonToSignUpErrorMessage(json: SignUpErrorJson): string {
  return mapJsonToErrorMessage(json) || defaultErrorMessage.signUp
}

export function mapJsonToSignInErrorMessage(json: SignInErrorJson): string {
  if (isUserNotFoundError(json)) {
    return signInErrorMessages[json.notFoundError] || defaultErrorMessage.signIn
  }
  return mapJsonToErrorMessage(json) || defaultErrorMessage.signIn
}

export function mapJsonToInvitationSignUpErrorMessage(
  json: InvitationSignUpErrorJson
): string {
  if (isInvitationTokenError(json)) {
    return (
      invitationSignUpErrorMessages[json.invitationToken[0]] ||
      defaultErrorMessage.invitationSignUp
    )
  }
  if (isInvitationAuthTokenError(json)) {
    return (
      invitationSignUpErrorMessages[json.token[0]] ||
      defaultErrorMessage.invitationSignUp
    )
  }
  return mapJsonToErrorMessage(json) || defaultErrorMessage.invitationSignUp
}

function mapJsonToErrorMessage(json: AuthErrorJson): string | undefined {
  const errorCode = isAuthHeaderError(json) ? json.auth[0] : json.serverError
  return authHeaderErrorMessages[errorCode]
}

const authHeaderErrorMessages: { [errorCode: string]: string | undefined } = {
  /*  eslint-disable prettier/prettier */
  'invalid_authorization_header': '認証ヘッダが確認できませんでした',
  'invalid_token': '認証トークンが正しくありません',
  /*  eslint-enable prettier/prettier */
}

const signInErrorMessages: { [errorCode: string]: string | undefined } = {
  /*  eslint-disable prettier/prettier */
  'user_not_found': 'ユーザーが存在しません',
  /*  eslint-enable prettier/prettier */
}

const invitationSignUpErrorMessages: {
  [errorCode: string]: string | undefined
} = {
  /*  eslint-disable prettier/prettier */
  'invalid_invitation_token': '無効なURLです',
  'invalid_user': 'このユーザーは招待されていないため、サインアップできません',
  /*  eslint-enable prettier/prettier */
}

function isUserNotFoundError(json: unknown): json is UserNotFoundErrorJson {
  if (!isObject(json)) {
    return false
  }
  return typeof json['notFoundError'] === 'string'
}

function isInvitationTokenError(
  json: unknown
): json is InvitationTokenErrorJson {
  if (!isObject(json)) {
    return false
  }
  return isArrayOfString(json['invitationToken'])
}

function isInvitationAuthTokenError(
  json: unknown
): json is InvitationAuthTokenErrorJson {
  if (!isObject(json)) {
    return false
  }
  return isArrayOfString(json['token'])
}

function isAuthHeaderError(json: unknown): json is AuthHeaderErrorJson {
  if (!isObject(json)) {
    return false
  }
  return isArrayOfString(json['auth'])
}

function isObject(json: unknown): json is object {
  return typeof json === 'object' && json !== null
}

function isArrayOfString(json: object): json is string[] {
  return Array.isArray(json) && typeof json[0] === 'string'
}
