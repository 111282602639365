import React from 'react'

// Inspired by:
// - https://github.com/tj/react-click-outside/blob/master/index.js
// - https://github.com/airbnb/react-outside-click-handler/blob/master/src/OutsideClickHandler.jsx

interface Props {
  handleOutsideClick: (e: MouseEvent) => void
}

const OutsideClickTrap: React.FC<Props> = (props) => {
  const { handleOutsideClick } = props
  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (ref.current === null) {
        return
      }
      if (e.target instanceof Node && ref.current.contains(e.target)) {
        return
      }
      handleOutsideClick(e)
    }
    document.addEventListener('click', handler, true)
    return () => {
      document.removeEventListener('click', handler, true)
    }
  }, [handleOutsideClick])

  return <div ref={ref}>{props.children}</div>
}

export default OutsideClickTrap
