import * as React from 'react'

import { ViewFieldDefinition } from '~/domain/workflow/field/FieldDefinition'
import { DependentFields } from '~/presentation/workflow/detail/editor/form/field/DependentField'
import ViewWidget from '~/presentation/workflow/detail/editor/form/viewWidget/ViewWidget'

interface Props {
  definition: ViewFieldDefinition
  dependentFields: DependentFields
}

const ViewField: React.FC<Props> = (props) => {
  if (!props.dependentFields.isFilled()) {
    // ひとつでも未入力の必須な依存フィールドがあればフィールドを描画しない
    return null
  }
  return (
    <ViewWidget
      definition={props.definition.form}
      dependentFields={props.dependentFields}
    />
  )
}

export default ViewField
