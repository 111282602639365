import { extract, extractTid } from '~/common/utils'

export class ExpectedObjectKey {
  static create(friendlyName: string, taskId?: string): ExpectedObjectKey {
    if (taskId !== undefined) {
      return new ExpectedObjectKey(`tid_${taskId}__obj_key_${friendlyName}`)
    } else {
      return new ExpectedObjectKey(`obj_key_${friendlyName}`)
    }
  }

  constructor(public readonly value: string) {}

  getTaskId(): string | null {
    return extractTid(this.value)
  }

  getFriendlyName(): string {
    return extract(this.value, /obj_key_(.+)/) ?? '???'
  }
}
