/* 共通の型 */

export enum FetchStatus {
  none = 'none',
  loading = 'loading',
  loaded = 'loaded',
  failed = 'failed',
}

export enum SubmitStatus {
  none = 'none',
  submitting = 'submitting',
  submitted = 'submitted',
  failed = 'failed',
}

export enum TypeName {
  boolean = 'bool',
  null = 'None',
}

/** 実行履歴のメッセージタイプ */
export enum MessageType {
  info = 'info',
  warning = 'warning',
  error = 'error',
}

export interface Entity<T> {
  [id: string]: T
}

// export interface Pager {
//   count: number
//   current: number
//   limit: number
// }
//
// export interface PagerResponse<T> extends Pager {
//   results: T[]
// }

export interface LegacyPager {
  count: number
  current: number
  limit: number
}

export interface LegacyPagerResponse<T> extends LegacyPager {
  results: T[]
}

export class MyError extends Error {
  constructor(message?: string) {
    super(message)
    // instanceof でエラーを判定するために手動で prototype をセットする必要がある
    // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export type PendingResult<T> =
  | PendingResult.Pending
  | PendingResult.Success<T>
  | PendingResult.Failure

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace PendingResult {
  export enum Status {
    // この enum の中で外部スコープの pending 関数を参照したいケースはないため suppress
    // eslint-disable-next-line @typescript-eslint/no-shadow
    pending = 'pending',
    // この enum の中で外部スコープの success 関数を参照したいケースはないため suppress
    // eslint-disable-next-line @typescript-eslint/no-shadow
    success = 'success',
    // この enum の中で外部スコープの failure 関数を参照したいケースはないため suppress
    // eslint-disable-next-line @typescript-eslint/no-shadow
    failure = 'failure',
  }

  export interface Pending {
    status: Status.pending
  }

  export interface Success<T> {
    status: Status.success
    data: T
  }

  export interface Failure {
    status: Status.failure
    reason?: any
    message?: string
  }

  export function pending(): Pending {
    return { status: Status.pending }
  }

  export function success<T>(data: T): Success<T> {
    return { status: Status.success, data }
  }

  export function failure(message?: string, reason?: any): Failure {
    return { status: Status.failure, reason, message }
  }
}
