import styled from '@emotion/styled'
import * as React from 'react'

import { StandardTrigger } from '~/common/constants'
import Button from '~/components/atoms/Button'
import Text from '~/components/atoms/Text'
import App from '~/components/molecules/App'
import Modal from '~/components/organisms/Modal'
import { useGetAppFromTrigger } from '~/hooks/useGetAppFromTrigger'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import { scrollbarStyle } from '~/styles/scrollbar'
import * as vars from '~/styles/variables'

interface Props {
  isOpened: boolean
  onSelect: (triggerId: string) => void
  onClose: () => void
}

const TriggerSelectModal: React.FC<Props> = ({
  isOpened,
  onClose,
  onSelect,
}) => {
  const definitions = useDefinitions()
  const getAppFromTrigger = useGetAppFromTrigger()
  return (
    <_Modal isOpened={isOpened} onClose={onClose}>
      <Container>
        <Text
          element="h1"
          fontSize="l"
          fontWeight="bold"
          lineHeight="heading"
          style={{
            paddingLeft: vars.space.m,
          }}
        >
          トリガーを選択してください
        </Text>
        <TriggerList>
          {Object.values(definitions.triggersById).map((trigger) => {
            const app = getAppFromTrigger(trigger.triggerId)
            return (
              <TriggerItem
                key={trigger.triggerId}
                onClick={() => onSelect(trigger.triggerId)}
              >
                {app ? (
                  <_App icon={app.iconPath} color={app.color} size="m" />
                ) : null}
                <TextContainer>
                  <Text
                    element="h2"
                    fontSize="s"
                    fontWeight="bold"
                    lineHeight="heading"
                  >
                    {trigger.name}
                  </Text>
                  <Text
                    element="p"
                    color="secondary"
                    fontSize="xs"
                    style={{
                      marginTop: vars.space.xxs,
                    }}
                  >
                    {trigger.description}
                  </Text>
                </TextContainer>
              </TriggerItem>
            )
          })}
        </TriggerList>
        <ButtonContainer>
          <Button type="secondary" onClick={() => onSelect(StandardTrigger)}>
            スキップ
          </Button>
          <Button type="tertiary" onClick={() => onClose()}>
            キャンセル
          </Button>
        </ButtonContainer>
      </Container>
    </_Modal>
  )
}

const _Modal = styled(Modal)({
  width: 600,
})

const Container = styled('div')({
  paddingTop: vars.space.l,
  paddingRight: vars.space.m,
  paddingBottom: vars.space.m,
  paddingLeft: vars.space.m,
})

const TriggerList = styled('div')({
  marginTop: vars.space.m,
  height: 350,
  overflowY: 'scroll',
  ...scrollbarStyle,
})

const TriggerItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  paddingTop: vars.space.s,
  paddingRight: vars.space.m,
  paddingBottom: vars.space.s,
  paddingLeft: vars.space.m,
  borderRadius: vars.borderRadius.s,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: vars.color.lightGray,
  },
})

const _App = styled(App)({
  marginRight: vars.space.m,
})

const TextContainer = styled('div')({})

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: vars.space.m,
})

export default TriggerSelectModal
