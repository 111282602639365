import { combineReducers } from 'redux'

import * as loader from '~/ducks/editor/loader'
import * as selectedNode from '~/ducks/editor/selectedNode'

export interface EditorState {
  loader: loader.State
  selectedNode: selectedNode.State
}

export default combineReducers<EditorState>({
  loader: loader.reducer,
  selectedNode: selectedNode.reducer,
})
