import styled from '@emotion/styled'
import * as React from 'react'

import { ValueType } from '~/domain/ValueType'
import { ExpectedObject } from '~/domain/workflow/expectedObject/ExpectedObjects'
import { RenderableEntity } from '~/domain/workflow/source/RenderableElement'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import ExpectedObjectItem from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/ExpectedObjectItem'
import * as vars from '~/styles/variables'

interface Props {
  expectedObjects: ExpectedObject[]
  targetValueTypes: ValueType[]
  defaultExpanded?: boolean
  onSelect: (entities: RenderableEntity[]) => void
  onCollapse?: () => void
  onExpand?: () => void
}

const ExpectedObjectList: React.FC<Props> = ({
  expectedObjects,
  targetValueTypes,
  defaultExpanded,
  onSelect,
  onCollapse,
  onExpand,
}) => {
  const definitions = useDefinitions()
  return (
    <Container>
      {expectedObjects.map((obj) => {
        return (
          <_ExpectedObjectItem
            key={obj.key.value}
            expectedObject={obj}
            definitions={definitions}
            targetValueTypes={targetValueTypes}
            defaultCollapsed={!defaultExpanded && expectedObjects.length !== 1}
            onSelect={onSelect}
            onCollapse={onCollapse}
            onExpand={onExpand}
          />
        )
      })}
    </Container>
  )
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
})

const _ExpectedObjectItem = styled(ExpectedObjectItem)({
  marginTop: vars.space.m,
  '&:first-of-type': {
    marginTop: 0,
  },
})

export default ExpectedObjectList
