import { combineReducers } from 'redux'

import * as list from '~/ducks/connection/list'

export interface ConnectionState {
  list: list.State
}

export default combineReducers<ConnectionState>({
  list: list.reducer,
})
