import styled from '@emotion/styled'
import React from 'react'
import * as Icon from 'react-feather'
import { animated, useSpring } from 'react-spring'

import Button from '~/components/atoms/Button'
import Focusable from '~/components/common/Focusable'
import * as vars from '~/styles/variables'

interface Option {
  label: string
  onClick: () => void
}

interface Props {
  label: string
  options: Option[]
  disabled?: boolean
  loading?: boolean
  onClick: () => void
}

const DropdownButton: React.FC<Props> = (props) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false)

  const knobProps = useSpring({
    transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  })

  const dropdownProps = useSpring({
    from: { opacity: 0, display: 'none' },
    to: async (next: any) => {
      if (isDropdownOpen) {
        await next({ display: 'block', pointerEvents: 'unset' })
        await next({ opacity: 1 })
      } else {
        await next({ pointerEvents: 'none' })
        await next({ opacity: 0 })
        await next({ display: 'none' })
      }
    },
    config: {
      tension: 500,
    },
  })

  const handleFocusChange = React.useCallback((hasFocus: boolean) => {
    if (!hasFocus) {
      setIsDropdownOpen(false)
    }
  }, [])

  const handleClick = React.useCallback(() => {
    props.onClick()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onClick])

  const handleKnobClick = React.useCallback(() => {
    if (props.disabled) {
      return
    }
    setIsDropdownOpen((it) => !it)
  }, [props.disabled])

  return (
    <Focusable onFocusChanged={handleFocusChange}>
      <div style={{ position: 'relative', display: 'flex' }}>
        <Button
          disabled={props.disabled}
          onClick={handleClick}
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            outline: 'none',
          }}
          loading={props.loading}
        >
          {props.label}
        </Button>
        <DropdownHandle
          disabled={props.disabled ?? false}
          onClick={handleKnobClick}
        >
          <animated.span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              ...knobProps,
            }}
          >
            <Icon.ChevronUp size={16} color={vars.color.white} />
          </animated.span>
        </DropdownHandle>
        <animated.div
          style={{
            position: 'absolute',
            top: -vars.space.s,
            width: '100%',
            padding: vars.space.s,
            backgroundColor: vars.color.white,
            transform: 'translateY(-100%)',
            boxShadow: vars.shadow.l,
            border: `1px solid ${vars.color.border}`,
            borderRadius: vars.borderRadius.m,
            zIndex: vars.zIndex.dropdown,
            ...dropdownProps,
          }}
        >
          {props.options.map((option, i) => (
            <DropdownItem
              key={i}
              onClick={() => {
                setIsDropdownOpen(false)
                option.onClick()
              }}
            >
              {option.label}
            </DropdownItem>
          ))}
        </animated.div>
      </div>
    </Focusable>
  )
}

const DropdownHandle = styled('div')(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: vars.space.s,
    paddingRight: vars.space.s,
    backgroundColor: vars.color.theme,
    borderLeft: `1px solid ${vars.color.gray}`,
    borderTopRightRadius: vars.borderRadius.m,
    borderBottomRightRadius: vars.borderRadius.m,
  },
  (props: { disabled: boolean }) => ({
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    opacity: props.disabled ? 0.5 : 1,
    '&:hover': {
      opacity: props.disabled ? 0.5 : 0.9,
    },
  })
)

const DropdownItem = styled('div')({
  paddingTop: vars.space.s * 1.5,
  paddingRight: vars.space.s,
  paddingBottom: vars.space.s * 1.5,
  paddingLeft: vars.space.s,
  borderRadius: vars.borderRadius.m,
  fontSize: vars.fontSize.s,
  fontWeight: vars.fontWeight.bold,
  lineHeight: vars.lineHeight.just,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: vars.color.offWhite,
  },
})

export default DropdownButton
