import { FunctionJson } from '~/data/workflow/function/FunctionJson'
import { mapJsonToArgumentDefinition } from '~/data/workflow/mapJsonToArgumentDefinition'
import { mapJsonToValueType } from '~/data/workflow/valueType/mapJsonToValueType'
import { FunctionDefinition } from '~/domain/workflow/function/FunctionDefinition'

export function mapJsonToFunctionDefinition(
  json: FunctionJson
): FunctionDefinition {
  return {
    id: json.key,
    name: json.name,
    description: json.description,
    arguments: json.argsInfo.map(mapJsonToArgumentDefinition),
    returnType: mapJsonToValueType(json.returnType),
  }
}
