import { RecipeCategoryJson, RecipeJson } from '~/data/recipe/RecipeJson'
import { mapJsonToWorkflowSourceBody } from '~/data/workflow/source/mapJsonToWorkflowSourceBody'
import { Recipe, RecipeCategory } from '~/domain/recipe/Recipe'

export async function mapJsonToRecipe(json: RecipeJson): Promise<Recipe> {
  return new Recipe(
    json.id,
    json.name,
    json.description,
    json.categories.map(mapJsonToRecipeCategory),
    await mapJsonToWorkflowSourceBody(json.source)
  )
}

function mapJsonToRecipeCategory(json: RecipeCategoryJson): RecipeCategory {
  return {
    id: json.id,
    label: json.label,
  }
}
