import * as React from 'react'

export function useUnloadConfirmDialog(enabled: boolean) {
  React.useEffect(() => {
    if (!enabled) {
      return
    }
    const handler = (e: BeforeUnloadEvent) => {
      e.returnValue = '変更が破棄されますがよろしいですか？'
      e.preventDefault()
    }
    window.addEventListener('beforeunload', handler)
    return () => {
      window.removeEventListener('beforeunload', handler)
    }
  }, [enabled])
}
