import * as _ from 'lodash'
import * as React from 'react'

export function useListState<T>(
  defaultValue?: T[]
): {
  values: T[]
  changeValue: (i: number, value: T) => void
  removeValue: (i: number) => void
  addValues: (values: T[]) => void
  setValues: (values: T[]) => void
  clearValues: () => void
} {
  // 各値が更新されると List 全体の参照が変わる
  // ただし変更された要素以外の要素の参照は変わらない
  // remove された場合、変更をうける要素（削除した要素以降の要素）の参照は変わる
  const [values, setValues] = React.useState<T[]>(
    defaultValue !== undefined ? _.cloneDeep(defaultValue) : []
  )
  const changeValue = React.useRef<(i: number, value: T) => void>(
    (i: number, value: T) => {
      setValues((oldValues) => {
        const newValues: T[] = []
        oldValues.forEach((oldValue, index) => {
          if (index === i) {
            newValues.push(value)
          } else {
            newValues.push(oldValue)
          }
        })
        return newValues
      })
    }
  )
  const removeValue = React.useRef<(i: number) => void>((i: number) => {
    setValues((oldValues) => {
      const newValues: T[] = []
      oldValues.forEach((oldValue, index) => {
        if (index !== i) {
          newValues.push(oldValue)
        }
      })
      return newValues
    })
  })
  const addValues = React.useRef<(values: T[]) => void>((newValues: T[]) => {
    setValues((oldValues) => {
      return [...oldValues, ...newValues]
    })
  })
  const clearValues = React.useRef<() => void>(() => {
    setValues([])
  })
  const replaceValues = React.useRef<(values: T[]) => void>(
    (newValues: T[]) => {
      setValues(newValues)
    }
  )
  return {
    values,
    changeValue: changeValue.current,
    removeValue: removeValue.current,
    addValues: addValues.current,
    setValues: replaceValues.current,
    clearValues: clearValues.current,
  }
}
