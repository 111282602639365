import styled from '@emotion/styled'
import React from 'react'
import * as Icon from 'react-feather'

import Button from '~/components/atoms/Button'
import Text from '~/components/atoms/Text'
import { SalesforceSObject } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSObject'
import { SalesforceSearchConditionGroup } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchConditionGroup'
import { SalesforceSearchCriteria } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchCriteria'
import { CombinationTypeSwitcher } from '~/presentation/workflow/detail/editor/form/inputWidget/salesforceSearchCondition/CombinationTypeSwitcher'
import SalesforceSearchConditionGroupComponent from '~/presentation/workflow/detail/editor/form/inputWidget/salesforceSearchCondition/SalesforceSearchConditionGroupComponent'
import * as vars from '~/styles/variables'

interface Props {
  value: SalesforceSearchCriteria
  onChange: (newValue: SalesforceSearchCriteria) => void
  required: boolean
  readonly: boolean
  canUseStateOperators: boolean
  sObject: SalesforceSObject
}

const SalesforceSearchCriteriaComponent: React.FC<Props> = (props) => {
  return (
    <div>
      {props.value.groups.map((group, i) => (
        <React.Fragment key={i}>
          <GroupContainer>
            <GroupLabel>条件グループ{getCircledNumberString(i + 1)}</GroupLabel>
            {props.value.groups.length > 1 && !props.readonly && (
              <p style={{ textAlign: 'right' }}>
                <Icon.X
                  color={vars.color.icon}
                  size={20}
                  style={{ cursor: 'pointer' }}
                  onClick={() => props.onChange(props.value.removeGroup(i))}
                />
              </p>
            )}
            <SalesforceSearchConditionGroupComponent
              value={group}
              onChange={(newValue) =>
                props.onChange(props.value.updateGroup(i, newValue))
              }
              required={props.required}
              readonly={props.readonly}
              canUseStateOperators={props.canUseStateOperators}
              sObject={props.sObject}
            />
          </GroupContainer>
          {i !== props.value.groups.length - 1 && (
            <CombinationTypeSwitcher
              value={props.value.combinationType}
              disabled={props.readonly}
              onChange={(newValue) =>
                props.onChange(props.value.setCombinationType(newValue))
              }
              style={{
                marginTop: vars.space.m,
                marginBottom: vars.space.m * 1.5,
              }}
            />
          )}
        </React.Fragment>
      ))}
      <div>
        <Button
          type="primary"
          disabled={props.readonly}
          onClick={() =>
            props.onChange(
              props.value.addGroup(new SalesforceSearchConditionGroup())
            )
          }
          style={{ width: '100%', marginTop: vars.space.m }}
        >
          新しい条件グループを追加する
        </Button>
      </div>
    </div>
  )
}

function getCircledNumberString(n: number): string {
  if (n < 1) {
    return String(n)
  }
  if (n < 21) {
    return String.fromCodePoint(9312 + Math.floor(n) - 1)
  }
  if (n < 36) {
    return String.fromCodePoint(12881 + Math.floor(n) - 21)
  }
  if (n < 51) {
    return String.fromCodePoint(12977 + Math.floor(n) - 36)
  }
  return String(n)
}

const GroupContainer = styled('div')({
  position: 'relative',
  marginTop: vars.space.m,
  padding: vars.space.m,
  backgroundColor: vars.color.white,
  borderRadius: vars.borderRadius.m,
  border: `2px solid ${vars.color.theme}`,
})

const GroupLabel: React.FC = (props) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: vars.space.s * 1.5,
        paddingTop: 1,
        paddingRight: vars.space.m,
        paddingBottom: 1,
        paddingLeft: vars.space.m,
        backgroundColor: vars.color.theme,
        borderRadius: 20,
        transform: 'translateY(-50%)',
      }}
    >
      <Text
        element="span"
        color={vars.color.white}
        fontSize="s"
        fontWeight="bold"
        lineHeight="just"
      >
        {props.children}
      </Text>
    </div>
  )
}

export default SalesforceSearchCriteriaComponent
