import styled from '@emotion/styled'
import * as React from 'react'

import Button from '~/components/atoms/Button'
import Text from '~/components/atoms/Text'
import Inner from '~/components/organisms/Inner'
import { LOOKUP_TABLE_LIST } from '~/routes'
import * as vars from '~/styles/variables'

const NotFound: React.FC = () => {
  return (
    <Container>
      <_Inner>
        <Text element="h1" fontSize="l" fontWeight="bold" lineHeight="heading">
          テーブルが見つかりませんでした
        </Text>
        <Text element="p" fontSize="m" style={{ marginTop: vars.space.s }}>
          削除されたか URL が間違っている可能性があります。
        </Text>
        <Button to={LOOKUP_TABLE_LIST} style={{ marginTop: vars.space.m }}>
          ルックアップテーブル一覧へ
        </Button>
      </_Inner>
    </Container>
  )
}

const Container = styled('div')({
  width: '100%',
  height: '100%',
})

const _Inner = styled(Inner)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
})

export default NotFound
