import * as React from 'react'

import { apiClients } from '~/common/apiClients'
import { FetchStatus } from '~/common/types'
import { Recipe } from '~/domain/recipe/Recipe'
import { RecipeService } from '~/domain/recipe/RecipeService'

const service: RecipeService = apiClients.recipeService

export function useRecipes(): {
  fetchStatus: FetchStatus
  errorMessage?: string
  recipes?: Recipe[]
} {
  const [recipes, setRecipes] = React.useState<Recipe[]>()
  const [fetchStatus, setFetchStatus] = React.useState<FetchStatus>(
    FetchStatus.none
  )
  const [errorMessage, setErrorMessage] = React.useState<string>()

  React.useEffect(() => {
    setFetchStatus(FetchStatus.loading)
    service
      .getList()
      .then((res) => {
        setRecipes(Object.values(res))
        setFetchStatus(FetchStatus.loaded)
      })
      .catch((reason) => {
        console.error(reason)
        setErrorMessage('レシピ一覧の取得に失敗しました')
        setFetchStatus(FetchStatus.failed)
      })
  }, [])

  return { fetchStatus, recipes, errorMessage }
}
