import { ensure } from '~/common/utils'
import { GroupMeta } from '~/domain/group/GroupSummary'
import { Organization } from '~/domain/user/Organization'

interface Init {
  id: string
  username: string
  email: string
  organization: Organization
  isAdmin: boolean
  currentGroupId: string
  belongingGroups: GroupMeta[]
}

export class Me {
  public readonly id: string
  public readonly username: string
  public readonly email: string
  public readonly organization: Organization
  public readonly isAdmin: boolean
  public readonly currentGroup: GroupMeta
  public readonly belongingGroups: GroupMeta[]

  constructor({
    id,
    username,
    email,
    organization,
    isAdmin,
    currentGroupId,
    belongingGroups,
  }: Init) {
    this.id = id
    this.username = username
    this.email = email
    this.organization = organization
    this.isAdmin = isAdmin
    this.belongingGroups = belongingGroups
    this.currentGroup = ensure(
      belongingGroups.find((it) => it.id === currentGroupId),
      `${currentGroupId} doesn't exist on belongingGroups`
    )
  }

  changeCurrentGroup(groupId: string): Me {
    return new Me({ ...this, currentGroupId: groupId })
  }
}
