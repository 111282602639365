import styled from '@emotion/styled'
import * as React from 'react'

import * as vars from '~/styles/variables'

interface Props {
  /**
   * サイズ
   * @default 'm'
   */
  size?: 's' | 'm'
  className?: string
}

const Avatar: React.FunctionComponent<Props> = ({ size = 'm', className }) => (
  <Image className={className} size={size} src="/static/images/user.png" />
)

const Image = styled('img')<Pick<Props, 'size'>>(
  {
    display: 'block',
    borderRadius: '50%',
    backgroundColor: vars.color.offWhite,
  },
  (props) => {
    const size = props.size === 'm' ? 40 : 25
    return {
      width: size,
      height: size,
    }
  }
)

export default React.memo(Avatar)
