import { Definitions } from '~/domain/Definitions'
import { ExpectedObjects } from '~/domain/workflow/expectedObject/ExpectedObjects'
import { FunctionsTab } from '~/domain/workflow/variableFinder/FunctionsTab'
import {
  PrimitiveSection,
  PrimitivesTab,
} from '~/domain/workflow/variableFinder/PrimitivesTab'
import {
  VariablesSection,
  VariablesTab,
} from '~/domain/workflow/variableFinder/VariablesTab'

export class VariableFinder {
  readonly variablesTab: VariablesTab
  readonly primitivesTab: PrimitivesTab
  readonly functionsTab: FunctionsTab

  constructor(definitions: Definitions, expectedObjects: ExpectedObjects) {
    const triggerSection: VariablesSection[] =
      expectedObjects.trigger !== undefined
        ? [
            {
              app: definitions.getAppByTriggerId(
                expectedObjects.trigger.triggerId
              ),
              label: definitions.getTrigger(expectedObjects.trigger.triggerId)
                .name,
              objects: expectedObjects.trigger.expectedObjects,
            },
          ]
        : []

    const taskSections: VariablesSection[] =
      expectedObjects.tasks !== undefined
        ? expectedObjects.tasks.map((task) => ({
            app: definitions.getAppByActionId(task.actionId),
            label: definitions.getAction(task.actionId).name,
            objects: task.expectedObjects,
          }))
        : []

    this.variablesTab = {
      sections: [...triggerSection, ...taskSections],
    }
    this.primitivesTab = {
      sections: primitiveSections,
    }
    this.functionsTab = {
      functions: Object.values(definitions.functionsById),
    }
  }
}

const primitiveSections: PrimitiveSection[] = [
  {
    label: '真偽値',
    primitives: [
      {
        label: 'True',
        description: '真を表す値です',
        valueType: { typeName: 'boolean', nullable: false },
      },
      {
        label: 'False',
        description: '偽を表す値です',
        valueType: { typeName: 'boolean', nullable: false },
      },
    ],
  },
  {
    label: 'その他',
    primitives: [
      {
        label: 'None',
        description: '無を表す値です',
        valueType: {
          typeName: 'never',
          nullable: true,
        },
      },
    ],
  },
]
