import config from '~/common/config'
import { assertNever } from '~/common/utils'
import { Tson } from '~/data/Tson'
import { requestPost } from '~/data/request'
import { mapModelToExpectedObjectJson } from '~/data/workflow/expectedObject/mapModelToExpectedObjectJson'
import { mapModelToInputSourceJson } from '~/data/workflow/source/mapModelToWorkflowSourceBodyJson'
import { mapJsonToTestResult } from '~/data/workflow/test/jsonToModelMapper'
import { mapModelToExpectedObjectSnapshotJson } from '~/data/workflow/test/modelToJsonMapper'
import { mapToTestResultJson } from '~/data/workflow/test/toJsonMapper'
import {
  ExpectedObject,
  ExpectedObjects,
} from '~/domain/workflow/expectedObject/ExpectedObjects'
import { InputSource } from '~/domain/workflow/source/WorkflowSourceBody'
import {
  ExpectedObjectSnapshot,
  TestResult,
} from '~/domain/workflow/test/TestResult'
import { TestService } from '~/domain/workflow/test/TestService'
import { TestTarget } from '~/domain/workflow/test/TestTarget'

export class TestServiceImpl implements TestService {
  async test(
    target: TestTarget,
    inputs: InputSource[],
    useBulk: boolean,
    expectedObjects: ExpectedObjects,
    snapshots: ExpectedObjectSnapshot[]
  ): Promise<TestResult> {
    switch (target.kind) {
      case 'trigger':
        return testTrigger(
          target.triggerId,
          inputs,
          expectedObjects.getAllInstances(),
          snapshots
        )
      case 'task':
        return testTask(
          target.taskId,
          target.actionId,
          inputs,
          useBulk,
          expectedObjects.getAllInstances(),
          snapshots
        )
      default:
        assertNever(target)
    }
  }
}

async function testTask(
  taskId: string,
  actionId: string,
  inputs: InputSource[],
  useBulk: boolean,
  expectedObjects: ExpectedObject[],
  snapshots: ExpectedObjectSnapshot[]
): Promise<TestResult> {
  const body = {
    inputs: inputs.map(mapModelToInputSourceJson),
    expectedObjects: expectedObjects.map(mapModelToExpectedObjectJson),
    snapshots: snapshots.map(mapModelToExpectedObjectSnapshotJson),
    useBulk,
    actionId,
    taskId,
  }
  const res = await requestPost(`${config.apiRoot}/test/run/task`, body)
  const json = mapToTestResultJson(new Tson(res))
  return mapJsonToTestResult(json)
}

async function testTrigger(
  triggerId: string,
  inputs: InputSource[],
  expectedObjects: ExpectedObject[],
  snapshots: ExpectedObjectSnapshot[]
): Promise<TestResult> {
  const body = {
    inputs: inputs.map(mapModelToInputSourceJson),
    expectedObjects: expectedObjects.map(mapModelToExpectedObjectJson),
    snapshots: snapshots.map(mapModelToExpectedObjectSnapshotJson),
    triggerId,
  }
  const res = await requestPost(`${config.apiRoot}/test/run/trigger`, body)
  const json = mapToTestResultJson(new Tson(res))
  return mapJsonToTestResult(json)
}
