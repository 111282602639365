import { WorkflowSourceJson } from '~/data/workflow/source/json'
import { mapJsonToWorkflowSourceBody } from '~/data/workflow/source/mapJsonToWorkflowSourceBody'
import { WorkflowSource } from '~/domain/workflow/source/WorkflowSource'

export async function mapJsonToWorkflowSource(
  json: WorkflowSourceJson
): Promise<WorkflowSource> {
  return new WorkflowSource(
    json.slug,
    await mapJsonToWorkflowSourceBody(json.body)
  )
}
