import styled from '@emotion/styled'
import * as hash from 'object-hash'
import React from 'react'

import { apiClients } from '~/common/apiClients'
import ErrorMessage from '~/components/atoms/ErrorMessage'
import Loader from '~/components/atoms/Loader'
import { SalesforceSObject } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSObject'
import { SalesforceSearchConditionService } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchConditionService'
import { SalesforceSearchCriteria } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchCriteria'
import { SalesforceSearchConditionWidgetDefinition } from '~/domain/workflow/widget/salesforceSearchCondition/definition'
import { RawInputWidgetProps } from '~/presentation/workflow/detail/editor/form/inputWidget/RawInputWidget'
import SalesforceSearchCriteriaComponent from '~/presentation/workflow/detail/editor/form/inputWidget/salesforceSearchCondition/SalesforceSearchCriteriaComponent'
import { useInputWidgetValidation } from '~/presentation/workflow/detail/editor/form/validation/useValidation'
import * as vars from '~/styles/variables'

const service: SalesforceSearchConditionService =
  apiClients.salesforceSearchConditionService

interface Props extends RawInputWidgetProps {
  definition: SalesforceSearchConditionWidgetDefinition
}

const SalesforceSearchConditionWidget: React.FC<Props> = (props) => {
  const { onChange } = props

  const initialFlagRef = React.useRef<boolean>(true)
  const [sObject, setSObject] = React.useState<SalesforceSObject>()
  const [error, setError] = React.useState<boolean>(false)

  const validationResult = useInputWidgetValidation(props)

  const accountUid: string | undefined = React.useMemo(() => {
    const field = props.dependentFields.findField(
      props.definition.accountUidFieldKey
    )
    if (field === undefined || field.value === undefined) {
      return undefined
    }
    if (field.value.mode !== 'raw' || typeof field.value.raw !== 'string') {
      console.warn(
        `accountUid is not raw or is not string (${JSON.stringify(
          field.value
        )})`
      )
      return undefined
    }
    return field.value.raw
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(props.dependentFields), props.definition])

  const sObjectName: string | undefined = React.useMemo(() => {
    const field = props.dependentFields.findField(
      props.definition.sobjectNameFieldKey
    )
    if (field === undefined || field.value === undefined) {
      return undefined
    }
    if (field.value.mode !== 'raw' || typeof field.value.raw !== 'string') {
      console.warn(
        `accountUid is not raw or is not string (${JSON.stringify(
          field.value
        )})`
      )
      return undefined
    }
    return field.value.raw
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(props.dependentFields), props.definition])

  React.useEffect(() => {
    if (accountUid === undefined || sObjectName === undefined) {
      return
    }
    let stale = false
    setError(false)
    service
      .getSObject(accountUid, sObjectName)
      .then((res) => {
        if (stale) {
          return
        }
        setSObject(res)
      })
      .catch((e) => {
        if (stale) {
          return
        }
        console.error(e)
        setError(true)
      })
    return () => {
      stale = true
    }
  }, [accountUid, sObjectName])

  // ありえない条件（sObject に存在しないフィールドに対する条件等）になるのを防ぐために
  // accountUid または sObjectName が変更されたときに値を undefined に戻す。
  // 初回は初期読み込みのタイミングなので無視する。
  React.useEffect(() => {
    if (initialFlagRef.current) {
      initialFlagRef.current = false
      return
    }
    onChange(undefined)
    // なんらかの原因で onChange が変更されたときに実行されてほしくないので無効化する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountUid, sObjectName])

  if (error) {
    return (
      <Container>
        <ErrorMessage>エラーが発生しました</ErrorMessage>
      </Container>
    )
  }

  if (sObject === undefined) {
    return (
      <Container>
        <Loader />
      </Container>
    )
  }

  return (
    <Container>
      <SalesforceSearchCriteriaComponent
        value={SalesforceSearchCriteria.fromInputValue(sObject, props.value)}
        onChange={(newValue) => props.onChange(newValue.toInputValue())}
        required={props.required}
        readonly={props.readonly}
        canUseStateOperators={props.definition.canUseStateOperators}
        sObject={sObject}
      />
      {validationResult?.valid === false && (
        <ErrorMessage style={{ marginTop: vars.space.s }}>
          {validationResult.cause.message}
        </ErrorMessage>
      )}
    </Container>
  )
}

const Container = styled('div')({})

export default SalesforceSearchConditionWidget
