import styled from '@emotion/styled'
import * as React from 'react'

import Link from '~/components/atoms/Link'
import * as routes from '~/routes'
import * as vars from '~/styles/variables'

export const AuthPageLayout: React.FC = (props) => {
  return (
    <Container>
      <LeftPane>
        <Hero>
          <img alt="" srcSet="/static/images/auth/hero@2x.png 2x" />
          <Typings>ビジネスの成長は、自動化から生まれる。</Typings>
        </Hero>
      </LeftPane>
      <RightPane>
        <Link to={routes.HOME}>
          <Logo src="/static/images/logo.svg" width={150} />
        </Link>
        {props.children}
      </RightPane>
    </Container>
  )
}

const Container = styled('div')({
  display: 'grid',
  gridTemplateAreas: '"left right"',
  gridTemplateColumns: '50% 1fr',
  height: '100%',
})

const LeftPane = styled('div')({
  gridArea: 'left',
  height: '100%',
})

const RightPane = styled('div')({
  gridArea: 'right',
  height: '100%',
  width: '100%',
  maxWidth: 540,
  margin: '0 auto',
  padding: vars.space.xl,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
})

const Hero = styled('div')({
  backgroundColor: vars.color.offWhite,
  textAlign: 'center',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

const Logo = styled('img')({
  display: 'block',
})

const Typings = styled('h5')({
  marginTop: vars.space.l,
  fontSize: vars.fontSize.xl,
})
