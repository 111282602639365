import styled from '@emotion/styled'
import * as React from 'react'

import * as vars from '~/styles/variables'

interface Feature {
  title: string
  body: string
  imagePath: string
}

const data: Feature[] = [
  {
    title: 'ノーコードで\n業務で使うSaaSを連携',
    body:
      '連携したいSaaSを選び、必要なアクションをGUIで設定するだけ。プログラミング不要のため、エンジニア以外でも連携させることが可能です。',
    imagePath: '/static/images/lp/feature1@2x.png',
  },
  {
    title: '素早く簡単に\n業務プロセスの変更も楽楽',
    body:
      '開発するリソースや時間がないと諦めていた連携も、Anyflowなら簡単に。急な業務プロセスの変更への対応も、クリック＆入力で楽楽。',
    imagePath: '/static/images/lp/feature2@2x.png',
  },
  {
    title: 'API連携だから\nメンテナンスの必要なし',
    body:
      'Anyflowは各SaaSが提供するAPIと連携するiPaaS。仕様変更の影響を受けやすいRPAと違い、ワークフローは止まりません。',
    imagePath: '/static/images/lp/feature3@2x.png',
  },
]

interface Props {
  noButtons?: boolean
}

const Features: React.FC<Props> = () => {
  return (
    <>
      <SectionHeading>Anyflowの特徴</SectionHeading>
      <SubHeading>さあビジネスの自動化を始めよう</SubHeading>
      <Container>
        {data.map((feature, i) => (
          <FeatureContainer key={i}>
            <ImageContainer>
              <img
                src={feature.imagePath}
                alt=""
                style={{
                  width: '100%',
                  objectFit: 'contain',
                }}
              />
            </ImageContainer>
            <TextContainer>
              <HeadingContainer>
                <HeadingNumber>0{i + 1}</HeadingNumber>
                <Heading>{feature.title}</Heading>
              </HeadingContainer>
              <BodyText>{feature.body}</BodyText>
            </TextContainer>
          </FeatureContainer>
        ))}
      </Container>
    </>
  )
}

const SectionHeading = styled('h1')({
  marginBottom: 0,
  fontWeight: 800,
  fontSize: vars.fontSize.xxxxl,
  lineHeight: vars.lineHeight.heading,
  textAlign: 'center',
  [vars.media.tablet]: {
    fontSize: vars.fontSize.xl,
  },
})

const SubHeading = styled('h2')({
  fontWeight: vars.fontWeight.normal,
  fontSize: vars.fontSize.m,
  lineHeight: vars.lineHeight.heading,
  textAlign: 'center',
  [vars.media.tablet]: {
    fontSize: vars.fontSize.xs,
  },
})

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: vars.width.m,
  margin: 'auto',
  [vars.media.tablet]: {
    width: '100%',
  },
})

const FeatureContainer = styled('div')({
  display: 'grid',
  alignItems: 'center',
  gridTemplateAreas: `"image text"`,
  gridTemplateColumns: '50% 1fr',
  gap: vars.space.l,
  '&:not(:first-of-type)': {
    marginTop: vars.space.xl,
  },
  '&:nth-of-type(2n)': {
    gridTemplateAreas: `"text image"`,
  },
  [vars.media.tablet]: {
    marginRight: vars.space.m,
    marginLeft: vars.space.m,
    gridTemplateAreas: `"text" "image"`,
    gridTemplateColumns: '100%',
    '&:nth-of-type(2n)': {
      gridTemplateAreas: `"text" "image"`,
    },
  },
})

const ImageContainer = styled('div')({
  gridArea: 'image',
  padding: vars.space.l,
  [vars.media.tablet]: {
    padding: 0,
  },
})

const TextContainer = styled('div')({
  gridArea: 'text',
})

const HeadingContainer = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  [vars.media.tablet]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
})

const HeadingNumber = styled('span')({
  marginRight: vars.space.m,
  marginBottom: vars.space.s, // 位置調整
  color: vars.color.theme,
  fontSize: 64,
  fontWeight: 500,
  fontStyle: 'italic',
  lineHeight: vars.lineHeight.heading,
  [vars.media.tablet]: {
    marginRight: 0,
    marginBottom: 0,
    fontSize: 42,
  },
})

const Heading = styled('h2')({
  marginTop: '0.6em',
  marginBottom: '0.6em',
  fontSize: 32,
  fontWeight: 800,
  lineHeight: vars.lineHeight.heading,
  whiteSpace: 'pre-wrap',
  [vars.media.tablet]: {
    fontSize: 18,
    textAlign: 'center',
  },
})

const BodyText = styled('p')({
  fontSize: 17,
  fontWeight: 500,
  lineHeight: vars.lineHeight.body,
  [vars.media.tablet]: {
    fontSize: 13,
  },
})

export default Features
