import { Env, targetEnv } from '~/common/config'
import { AppDefinition } from '~/domain/workflow/app/AppDefinition'

const builtInAppDefinitions: AppDefinition[] = []

if (targetEnv === Env.local) {
  builtInAppDefinitions.push({
    appId: '__DevelopApp',
    name: '開発用',
    color: '#666',
    iconPath: '/static/images/apps/generic.png',
  })
}

export const appDefinitions: AppDefinition[] = [
  ...builtInAppDefinitions,
  {
    appId: 'GenericApp',
    name: '標準',
    color: '#666',
    iconPath: '/static/images/apps/generic.png',
  },
  {
    appId: 'GoogleCalendarApp',
    name: 'Googleカレンダー',
    color: '#EFEFEF',
    iconPath: '/static/images/apps/google-calendar.png',
  },
  {
    appId: 'GoogleSpreadsheetApp',
    name: 'Googleスプレッドシート',
    color: '#EFEFEF',
    iconPath: '/static/images/apps/google-spreadsheet.png',
  },
  {
    appId: 'GoogleGmailApp',
    name: 'Gmail',
    color: '#EFEFEF',
    iconPath: '/static/images/apps/gmail.png',
  },
  // google_forms: {
  //   appId: 'google_forms',
  //   name: 'Googleフォーム',
  //   color: '#6439B1',
  //   iconPath: '/static/images/apps/google-forms.png'
  // },
  {
    appId: 'GoogleDriveApp',
    name: 'Googleドライブ',
    color: '#EFEFEF',
    iconPath: '/static/images/apps/google-drive.png',
  },
  // google_slides: {
  //   appId: 'google_slides',
  //   name: 'Googleスライド',
  //   color: '#EEB410',
  //   iconPath: '/static/images/apps/google-slides.png'
  // },
  // google_docs: {
  //   appId: 'google_docs',
  //   name: 'Googleドキュメント',
  //   color: '#4587F4',
  //   iconPath: '/static/images/apps/google-docs.png'
  // },
  {
    appId: 'SalesforceApp',
    name: 'Salesforce',
    color: '#019CDB',
    iconPath: '/static/images/apps/salesforce.png',
  },
  // misoca: {
  //   appId: 'misoca',
  //   name: 'Misoca',
  //   color: '#00A7BC',
  //   iconPath: '/static/images/apps/misoca.png'
  // },
  {
    appId: 'FreeeApp',
    name: '会計 freee',
    color: '#3B6EB0',
    iconPath: '/static/images/apps/freee.png',
  },
  {
    appId: 'FreeeHRApp',
    name: '人事労務 freee',
    color: '#2B643C',
    iconPath: '/static/images/apps/freee.png',
  },
  {
    appId: 'SlackApp',
    name: 'Slack',
    color: '#4A154A',
    iconPath: '/static/images/apps/slack.png',
  },
  // jobcan: {
  //   appId: 'jobcan',
  //   name: 'ジョブカン',
  //   color: '#0279F8',
  //   iconPath: '/static/images/apps/jobcan.png'
  // },
  // talentio: {
  //   appId: 'talentio',
  //   name: 'Talentio',
  //   color: '#3BA4CF',
  //   iconPath: '/static/images/apps/talentio.png'
  // },
  {
    appId: 'SmartHRApp',
    name: 'SmartHR',
    color: '#00BFC6',
    iconPath: '/static/images/apps/smarthr.png',
  },
  {
    appId: 'CloudSignApp',
    name: 'クラウドサイン',
    color: '#0080C6',
    iconPath: '/static/images/apps/cloudsign.png',
  },
  // github: {
  //   appId: 'github',
  //   name: 'GitHub',
  //   color: '#161414',
  //   iconPath: '/static/images/apps/github.png'
  // },
  {
    appId: 'KintoneApp',
    name: 'kintone',
    color: '#F8BA00',
    iconPath: '/static/images/apps/kintone.png',
  },
  // wordpress: {
  //   appId: 'wordpress',
  //   name: 'WordPress',
  //   color: '#31353A',
  //   iconPath: '/static/images/apps/wordpress.png'
  // },
  // hubspot: {
  //   appId: 'hubspot',
  //   name: 'HubSpot',
  //   color: '#F87756',
  //   iconPath: '/static/images/apps/hubspot.png'
  // },
  // service_now: {
  //   appId: 'service_now',
  //   name: 'ServiceNow',
  //   color: '#7DB09D',
  //   iconPath: '/static/images/apps/service-now.png'
  // },
  // money_forward: {
  //   appId: 'money_forward',
  //   name: 'Money Forward',
  //   color: '#E2750F',
  //   iconPath: '/static/images/apps/money-forward.png'
  // },
  // intercom: {
  //   appId: 'intercom',
  //   name: 'Intercom',
  //   color: '#1E8DED',
  //   iconPath: '/static/images/apps/intercom.png'
  // },
  // marketo: {
  //   appId: 'marketo',
  //   name: 'Marketo',
  //   color: '#58499C',
  //   iconPath: '/static/images/apps/marketo.png'
  // },
  // trello: {
  //   appId: 'trello',
  //   name: 'Trello',
  //   color: '#0076BA',
  //   iconPath: '/static/images/apps/trello.png'
  // },
  // asana: {
  //   appId: 'asana',
  //   name: 'Asana',
  //   color: '#F1597F',
  //   iconPath: '/static/images/apps/asana.png'
  // },
  // zendesk: {
  //   appId: 'zendesk',
  //   name: 'Zendesk',
  //   color: '#04353D',
  //   iconPath: '/static/images/apps/zendesk.png'
  // },
  // dropbox: {
  //   appId: 'dropbox',
  //   name: 'Dropbox',
  //   color: '#0D2481',
  //   iconPath: '/static/images/apps/dropbox.png'
  // },
  // adwords: {
  //   appId: 'adwords',
  //   name: 'Google Adwords',
  //   color: '#fff',
  //   iconPath: '/static/images/apps/adwords.png'
  // },
  // esa: {
  //   appId: 'esa',
  //   name: 'esa',
  //   color: '#099C94',
  //   iconPath: '/static/images/apps/esa.png'
  // },
  {
    appId: 'GoogleAdminApp',
    name: 'Google Admin',
    color: '#2F64D9',
    iconPath: '/static/images/apps/google-admin.png',
  },
  {
    appId: 'ChatworkApp',
    name: 'Chatwork',
    color: '#EFEFEF',
    iconPath: '/static/images/apps/chatwork.png',
  },
  // box: {
  //   appId: 'box',
  //   name: 'Box',
  //   color: '#0061D5',
  //   iconPath: '/static/images/apps/box.png'
  // },
  {
    appId: 'ZoomApp',
    name: 'Zoom',
    color: '#2D8CFF',
    iconPath: '/static/images/apps/zoom.png',
  },
  {
    appId: 'SensesApp',
    name: 'Senses',
    color: '#172E37',
    iconPath: '/static/images/apps/senses.png',
  },
  {
    appId: 'CamCardApp',
    name: 'CAMCARD',
    color: '#2D4051',
    iconPath: '/static/images/apps/camcard.png',
  },
  {
    appId: 'SansanApp',
    name: 'Sansan',
    color: '#004E98',
    iconPath: '/static/images/apps/sansan.png',
  },
  {
    appId: 'KingOfTimeApp',
    name: 'KING OF TIME',
    color: '#1D9E48',
    iconPath: '/static/images/apps/king-of-time.png',
  },
  // hataraku_db: {
  //   appId: 'hataraku_db',
  //   name: '働くDB',
  //   color: '#C04251',
  //   iconPath: '/static/images/apps/hataraku-db.png'
  // },
  // herp: {
  //   appId: 'herp',
  //   name: 'HERP',
  //   color: '#121212',
  //   iconPath: '/static/images/apps/herp.png'
  // },
  // kaonavi: {
  //   appId: 'kaonavi',
  //   name: 'カオナビ',
  //   color: '#FFCD45',
  //   iconPath: '/static/images/apps/kaonavi.png'
  // },
  // hrbrain: {
  //   appId: 'hrbrain',
  //   name: 'HRBrain',
  //   color: '#00A860',
  //   iconPath: '/static/images/apps/hrbrain.png'
  // },
  // rakurakuseisan: {
  //   appId: 'rakurakuseisan',
  //   name: '楽楽精算',
  //   color: '#007AC6',
  //   iconPath: '/static/images/apps/rakurakuseisan.png'
  // },
  // hito_link: {
  //   appId: 'hito_link',
  //   name: 'HITO-Link',
  //   color: '#0075B5',
  //   iconPath: '/static/images/apps/hito-link.png'
  // },
  // board: {
  //   appId: 'board',
  //   name: 'board',
  //   color: '#217FAB',
  //   iconPath: '/static/images/apps/board.png'
  // },
  // microsoft_teams: {
  //   appId: 'microsoft_teams',
  //   name: 'Microsoft Teams',
  //   color: '#5557AF',
  //   iconPath: '/static/images/apps/microsoft-teams.png'
  // },
  // g_suite: {
  //   appId: 'g_suite',
  //   name: 'G Suite',
  //   color: '#ffffff',
  //   iconPath: '/static/images/apps/g-suite.png'
  // }
]
