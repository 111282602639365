import _ from 'lodash'
import * as React from 'react'

import { apiClients } from '~/common/apiClients'
import { rejectUndefined } from '~/common/utils'
import ErrorMessage from '~/components/atoms/ErrorMessage'
import DynamicMultiSelectField from '~/components/molecules/DynamicSelectField/DynamicMultiSelectField'
import { AssistService } from '~/domain/workflow/assist/AssistService'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { MultiAssistWidgetDefinition } from '~/domain/workflow/widget/multi_assist'
import { RawInputWidgetProps } from '~/presentation/workflow/detail/editor/form/inputWidget/RawInputWidget'
import { useAssistParameters } from '~/presentation/workflow/detail/editor/form/inputWidget/assist/useAssistParameters'
import { useInputWidgetValidation } from '~/presentation/workflow/detail/editor/form/validation/useValidation'
import * as vars from '~/styles/variables'

interface Props extends RawInputWidgetProps {
  definition: MultiAssistWidgetDefinition
}

const service: AssistService = apiClients.assistService

const MultiAssistWidget: React.FC<Props> = (props) => {
  // バリデーション
  const validationResult = useInputWidgetValidation(props)

  const params = useAssistParameters(
    props.definition.assist.parameterFieldKeys,
    props.dependentFields
  )

  const values: string[] = React.useMemo(() => {
    if (props.value === undefined) {
      return []
    }
    if (!InputValue.isInputValueList(props.value.raw)) {
      return []
    }
    const set = new Set<string>()
    props.value.raw.forEach((it) => {
      if (it === undefined || it.mode === 'render') {
        return
      }
      if (typeof it.raw !== 'string' && typeof it.raw !== 'number') {
        return
      }
      set.add(it.raw.toString())
    })
    return Array.from(set.values())
  }, [props.value])

  const loadOptions = React.useCallback(
    async (query: string, pageToken?: string) => {
      const res = await service.getList(
        props.definition.assist.assistId,
        query,
        params,
        pageToken
      )
      return {
        options: res.items,
        nextPageToken: res.nextPageToken,
      }
    },
    [props.definition.assist.assistId, params]
  )

  const loadLabels = React.useCallback(
    async (_values: string[]) => {
      const labels = await Promise.all(
        _values.map((it) => {
          return service.getLabel(props.definition.assist.assistId, params, it)
        })
      )
      // key is value, value is label (defined by LabelMap)
      return _.fromPairs(
        rejectUndefined(labels).map((res) => [res.value, res.label])
      )
    },
    [props.definition.assist.assistId, params]
  )

  const handleChange = React.useCallback(
    (newValues: string[]) => {
      if (newValues.length === 0) {
        props.onChange(undefined)
        return
      }
      props.onChange(InputValue.createRawInputValue(newValues))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChange]
  )

  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: vars.color.white,
        width: '100%',
      }}
    >
      <DynamicMultiSelectField
        loadOptions={loadOptions}
        loadLabels={loadLabels}
        values={values}
        onChange={handleChange}
        disabled={props.readonly}
      />
      {validationResult?.valid === false && (
        <ErrorMessage style={{ marginTop: vars.space.s }}>
          {validationResult.cause.message}
        </ErrorMessage>
      )}
    </div>
  )
}

export default MultiAssistWidget
