import { apiClients } from '~/common/apiClients'
import { AssertionError } from '~/common/utils'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { ValidationResult } from '~/domain/workflow/validator/ValidationResult'
import { SalesforceSObject } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSObject'
import { SalesforceSearchCondition } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchCondition'
import { SalesforceSearchConditionGroup } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchConditionGroup'
import { SalesforceOperandType } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchConditionOperator'
import { SalesforceSearchConditionService } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchConditionService'
import { SalesforceSearchCriteria } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchCriteria'
import { SalesforceSearchConditionWidgetDefinition } from '~/domain/workflow/widget/salesforceSearchCondition/definition'
import { InputWidgetValidatorContext } from '~/domain/workflow/widget/validator/InputWidgetValidator'
import { RawInputWidgetValidator } from '~/domain/workflow/widget/validator/RawInputWidgetValidator'

const service: SalesforceSearchConditionService =
  apiClients.salesforceSearchConditionService

export class SalesforceSearchConditionWidgetValidator extends RawInputWidgetValidator<
  SalesforceSearchConditionWidgetDefinition
> {
  async validate(inputValue: InputValue.Raw): Promise<ValidationResult> {
    // 依存値のチェック
    const accountUidInputValue = this.context.dependentValues[
      this.context.widgetDefinition.accountUidFieldKey
    ]
    const sObjectNameInputValue = this.context.dependentValues[
      this.context.widgetDefinition.sobjectNameFieldKey
    ]
    if (accountUidInputValue === undefined) {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat('アカウントが指定されていません')
      )
    }
    if (sObjectNameInputValue === undefined) {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat('Sオブジェクトが指定されていません')
      )
    }
    if (
      accountUidInputValue.mode !== 'raw' ||
      typeof accountUidInputValue.raw !== 'string'
    ) {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat('アカウントに不正な値が入力されています')
      )
    }
    if (
      sObjectNameInputValue.mode !== 'raw' ||
      typeof sObjectNameInputValue.raw !== 'string'
    ) {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat(
          'Sオブジェクトに不正な値が入力されています'
        )
      )
    }

    try {
      const sObject = await service.getSObject(
        accountUidInputValue.raw,
        sObjectNameInputValue.raw
      )
      const model = SalesforceSearchCriteria.fromInputValue(sObject, inputValue)
      if (model.groups.length === 0) {
        if (this.context.required) {
          return ValidationResult.invalid(new ValidationResult.Required())
        }
        return ValidationResult.valid()
      }
      const groupValidator = new SalesforceSearchConditionGroupValidator(
        this.context,
        sObject
      )
      const results = await Promise.all(
        model.groups.map((it) => groupValidator.validate(it.toInputValue()))
      )
      for (const result of results) {
        if (!result.valid) {
          return ValidationResult.invalid(
            new ValidationResult.Propagated(result.cause)
          )
        }
      }
      return ValidationResult.valid()
    } catch (e) {
      if (e instanceof AssertionError) {
        return ValidationResult.invalid(
          new ValidationResult.BadFormat('不正な値が入力されています')
        )
      }
      return ValidationResult.invalid(
        new ValidationResult.Failed('エラーが発生しました')
      )
    }
  }
}

class SalesforceSearchConditionGroupValidator {
  constructor(
    private readonly context: InputWidgetValidatorContext<
      SalesforceSearchConditionWidgetDefinition
    >,
    private readonly sObject: SalesforceSObject
  ) {}

  async validate(value: InputValue.Raw | undefined): Promise<ValidationResult> {
    if (value === undefined) {
      if (this.context.required) {
        return ValidationResult.invalid(new ValidationResult.Required())
      }
      return ValidationResult.valid()
    }

    const model = SalesforceSearchConditionGroup.fromInputValue(
      this.sObject,
      value
    )
    if (model.conditions.length === 0) {
      if (this.context.required) {
        return ValidationResult.invalid(new ValidationResult.Required())
      }
      return ValidationResult.valid()
    }

    const conditionValidator = new SalesforceSearchConditionValidator(
      this.context,
      this.sObject
    )
    const results = await Promise.all(
      model.conditions.map((it) =>
        conditionValidator.validate(it.toInputValue())
      )
    )
    for (const result of results) {
      if (!result.valid) {
        return ValidationResult.invalid(
          new ValidationResult.Propagated(result.cause)
        )
      }
    }
    return ValidationResult.valid()
  }
}

class SalesforceSearchConditionValidator {
  constructor(
    private readonly context: InputWidgetValidatorContext<
      SalesforceSearchConditionWidgetDefinition
    >,
    private readonly sObject: SalesforceSObject
  ) {}

  async validate(value: InputValue.Raw | undefined): Promise<ValidationResult> {
    if (value === undefined) {
      if (this.context.required) {
        return ValidationResult.invalid(new ValidationResult.Required())
      }
      return ValidationResult.valid()
    }

    try {
      const model = SalesforceSearchCondition.fromInputValue(
        this.sObject,
        value
      )
      if (this.context.required) {
        if (model.field === undefined || model.operator === undefined) {
          return ValidationResult.invalid(new ValidationResult.Required())
        }
        if (
          !(model.operator.operandType instanceof SalesforceOperandType.None) &&
          model.operand === undefined
        ) {
          // オペランドの種類が None でなければ、オペランドは必須
          return ValidationResult.invalid(new ValidationResult.Required())
        }
      }
      // フィールドが sObject に含まれているか、オペレータがフィールドの DataType と一致しているか、オペランドが正しい型の値かどうかは見ない
      return ValidationResult.valid()
    } catch {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat('不正な値が入力されています')
      )
    }
  }
}
