import { mapJsonToUser } from '~/data/user/mapJsonToUser'
import { WorkflowInstanceSummaryJson } from '~/data/workflow/instance/json'
import { mapToRunningStatus } from '~/data/workflow/instance/mapToRunningStatus'
import { WorkflowInstanceSummary } from '~/domain/workflow/instance/WorkflowInstanceSummary'

export function mapJsonToWorkflowInstanceSummary(
  json: WorkflowInstanceSummaryJson
): WorkflowInstanceSummary {
  return {
    instanceId: json.slug,
    runningStatus: mapToRunningStatus(json.state),
    updatedAt: json.updatedAt,
    runner: mapJsonToUser(json.runner),
  }
}
