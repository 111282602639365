import React from 'react'

import SelectField, { Option } from '~/components/molecules/SelectField'
import { PermissionPolicy } from '~/domain/workflow/permission/PermissionPolicy'

const options: Option<PermissionPolicy['name']>[] = [
  {
    label: '参照',
    value: 'readable',
  },
  {
    label: '参照・更新',
    value: 'updatable',
  },
  {
    label: '参照・更新・削除',
    value: 'deletable',
  },
]

interface Props {
  value: PermissionPolicy['name']
  loading: boolean
  onChange: (newValue: PermissionPolicy['name']) => void
}

export const PermissionSelect: React.FC<Props> = (props) => {
  return (
    <SelectField
      options={options}
      value={props.value}
      loading={props.loading}
      onChange={props.onChange}
    />
  )
}
