import hash from 'object-hash'
import * as React from 'react'

import { ValidationFunction } from '~/domain/workflow/validator/ValidationFunction'
import { ValidationResult } from '~/domain/workflow/validator/ValidationResult'
import { InputWidgetValidator } from '~/domain/workflow/widget/validator/InputWidgetValidator'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import { useExpectedObjectsContext } from '~/presentation/workflow/detail/editor/form/expectedObject/ExpectedObjectsContext'
import { InputWidgetProps } from '~/presentation/workflow/detail/editor/form/inputWidget/InputWidget'
import { useValidationContext } from '~/presentation/workflow/detail/editor/form/validation/ValidationContext'

export function useValidation(
  validation: ValidationFunction,
  additionalDependencies: any[] = []
) {
  const { validator } = useValidationContext()
  React.useEffect(() => {
    validator.register(validation)
    return () => {
      validator.unregister(validation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validator, validation, ...additionalDependencies])
}

export function useInputWidgetValidation(
  props: InputWidgetProps
): ValidationResult | undefined {
  const [validationResult, setValidationResult] = React.useState<
    ValidationResult
  >()
  const { expectedObjects } = useExpectedObjectsContext()
  const definitions = useDefinitions()

  const widgetValidator = React.useMemo(() => {
    return new InputWidgetValidator({
      definitions,
      expectedObjects,
      required: props.required,
      useBulk: props.bulkMode,
      valueType: props.valueType,
      widgetDefinition: props.definition,
      dependentValues: props.dependentFields.toDependentValues(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    definitions,
    expectedObjects,
    props.required,
    props.bulkMode,
    // 参照ではなく値で比較したいものはハッシュ値で比較
    // パフォーマンスに問題があれば参照が変わらないようにしないといけない
    // eslint-disable-next-line react-hooks/exhaustive-deps
    hash(props.valueType),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    hash(props.definition),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    hash(props.dependentFields),
  ])

  const validationFunction = React.useCallback(async (): Promise<
    ValidationResult
  > => {
    const result = await widgetValidator.validate(props.value)
    setValidationResult(result)
    return result
  }, [widgetValidator, props.value])

  useValidation(validationFunction)

  // 定義か値が変更された時にバリデーションエラーメッセージをクリアする
  React.useEffect(() => {
    setValidationResult(undefined)
  }, [validationFunction, props.value])

  return validationResult
}
