import * as _ from 'lodash'

import { flattenTaskSource } from '~/common/utils'
import { WorkflowSourceBody } from '~/domain/workflow/source/WorkflowSourceBody'

export class Recipe {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly description: string,
    public readonly categories: RecipeCategory[],
    public readonly source: WorkflowSourceBody
  ) {}

  /**
   * categoryId のカテゴリに属しているかどうかを返します。
   *
   * @param categoryId 属しているかどうか確認するカテゴリの ID
   */
  belong(categoryId: string): boolean {
    return this.categories.some((it) => it.id === categoryId)
  }

  getTriggerId(): string | undefined {
    return this.source.getTrigger()?.triggerId
  }

  getAllActionIds(): string[] {
    const rootTask = this.source.getRootTask()
    if (rootTask === undefined) {
      return []
    }
    return _.uniq(flattenTaskSource(rootTask).map((it) => it.actionId))
  }
}

export interface RecipeCategory {
  id: string
  label: string
}
