import styled from '@emotion/styled'
import * as React from 'react'
import ReactContentLoader from 'react-content-loader'

interface Props {
  className?: string
}

const rowHeight = 25
const titleHeight = 16

const Loader: React.FC<Props> = ({ className }) => (
  <ReactContentLoader
    className={className}
    speed={1}
    width={1000}
    height={rowHeight}
  >
    {/* タイトル */}
    <rect
      x={0}
      y={(rowHeight - titleHeight) / 2}
      rx="2"
      ry="2"
      width="450"
      height={titleHeight}
    />
    <rect
      x={650}
      y={(rowHeight - titleHeight) / 2}
      rx="2"
      ry="2"
      width="150"
      height={titleHeight}
    />
    {/* アプリアイコン */}
    <rect x="850" y={0} rx="2" ry="2" width="25" height="25" />
    <rect x={850 + 25 + 2} y={0} rx="2" ry="2" width="25" height="25" />
    <rect x={850 + (25 + 2) * 2} y={0} rx="2" ry="2" width="25" height="25" />
    <rect x={850 + (25 + 2) * 3} y={0} rx="2" ry="2" width="25" height="25" />
  </ReactContentLoader>
)

const ContentLoader: React.FC = () => (
  <>
    <ReactContentLoader speed={1} width={1000} height={14}>
      <rect x={0} y={0} rx="2" ry="2" width="70" height="14" />
      <rect x={650} y={0} rx="2" ry="2" width="90" height="14" />
      <rect x={850} y={0} rx="2" ry="2" width="30" height="14" />
    </ReactContentLoader>
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
  </>
)

const _Loader = styled(Loader)({
  marginTop: 12,
  '&:first-of-type': {
    marginTop: 0,
  },
})

export default ContentLoader
