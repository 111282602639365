import { assertNever } from '~/common/utils'
import { Definitions } from '~/domain/Definitions'
import { ValueType } from '~/domain/ValueType'
import { ExpectedObjects } from '~/domain/workflow/expectedObject/ExpectedObjects'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { RenderValueValidator } from '~/domain/workflow/source/RenderValueValidator'
import { ValidationResult } from '~/domain/workflow/validator/ValidationResult'
import { InputWidgetDefinition } from '~/domain/workflow/widget/WidgetDefinition'
import { DependentValues } from '~/domain/workflow/widget/validator/DependentValues'
import { RenderWidgetValidator } from '~/domain/workflow/widget/validator/RenderWidgetValidator'
import { createRawWidgetValidator } from '~/domain/workflow/widget/validator/createRawWidgetValidator'

export interface InputWidgetValidatorContext<
  W extends InputWidgetDefinition = InputWidgetDefinition,
  V extends ValueType = ValueType
> {
  readonly widgetDefinition: W
  readonly valueType: V
  readonly required: boolean
  readonly useBulk: boolean
  readonly definitions: Definitions
  readonly expectedObjects: ExpectedObjects
  readonly dependentValues: DependentValues
}

export class InputWidgetValidator {
  constructor(private readonly context: InputWidgetValidatorContext) {}

  async validate(inputValue: InputValue): Promise<ValidationResult> {
    if (inputValue === undefined) {
      return this.context.required
        ? ValidationResult.invalid(new ValidationResult.Required())
        : ValidationResult.valid()
    }
    switch (inputValue.mode) {
      case 'render':
        return new RenderWidgetValidator(
          new RenderValueValidator(
            this.context.valueType,
            this.context.definitions,
            this.context.expectedObjects
          ),
          this.context.required
        ).validate(inputValue, this.context.useBulk)
      case 'raw':
        return createRawWidgetValidator(this.context).validate(inputValue)
      default:
        assertNever(inputValue)
    }
  }
}
