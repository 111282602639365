import config from '~/common/config'
import { Tson } from '~/data/Tson'
import requestJson from '~/data/request'
import { mapJsonToObjectDefinition } from '~/data/workflow/object/mapJsonToObjectDefinition'
import { mapToObjectJson } from '~/data/workflow/object/mapToObjectJson'
import { ObjectDefinition } from '~/domain/workflow/object/ObjectDefinition'
import { ObjectService } from '~/domain/workflow/object/ObjectService'

export class ObjectServiceImpl implements ObjectService {
  async getAll(): Promise<ObjectDefinition[]> {
    const res = await requestJson(`${config.apiRoot}/objects`)
    const jsons = new Tson(res).asArray().map(mapToObjectJson)
    return jsons.map(mapJsonToObjectDefinition)
  }
}
