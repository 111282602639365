import styled from '@emotion/styled'
import React from 'react'
import * as Icon from 'react-feather'

import { MessageType } from '~/common/types'
import Text from '~/components/atoms/Text'
import { MessageLike } from '~/domain/workflow/instance/message/Message'
import * as vars from '~/styles/variables'

const iconSize = 18

interface Props {
  message: MessageLike
}

const MessageItem: React.FC<Props> = (props) => {
  return (
    <Container>
      {props.message.type !== MessageType.info ? (
        <IconContainer>
          {props.message.type === MessageType.warning ? (
            <Icon.AlertTriangle size={iconSize} color={vars.color.warning} />
          ) : props.message.type === MessageType.error ? (
            <Icon.XCircle size={iconSize} color={vars.color.error} />
          ) : null}
        </IconContainer>
      ) : null}
      <Text
        element="p"
        fontSize="s"
        color={vars.fontColor.primary}
        style={{ marginTop: -3, whiteSpace: 'pre-line' }}
      >
        {props.message.body}
      </Text>
    </Container>
  )
}

const Container = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
})

const IconContainer = styled('div')({
  flexShrink: 0,
  marginRight: vars.space.s,
})

export default MessageItem
