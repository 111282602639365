import * as React from 'react'

import { assertNever } from '~/common/utils'
import { ViewUnitDefinition } from '~/domain/workflow/viewUnit/ViewUnitDefinition'
import CopyViewUnit from '~/presentation/workflow/detail/editor/form/viewWidget/viewUnit/CopyViewUnit'

export interface Props {
  definition: ViewUnitDefinition
}

export function ViewUnit(props: Props) {
  switch (props.definition.type) {
    case 'copy':
      return <CopyViewUnit {...props} definition={props.definition} />
    default:
      assertNever(props.definition.type)
  }
}
