import _ from 'lodash'
import React from 'react'

import { Result } from '~/common/Result'
import { apiClients } from '~/common/apiClients'
import { WorkflowService } from '~/domain/workflow/WorkflowService'
import { Permission } from '~/domain/workflow/permission/Permission'
import { PermissionPolicy } from '~/domain/workflow/permission/PermissionPolicy'

const service: WorkflowService = apiClients.workflowService

export function useWorkflowPermissionList(
  workflowId: string
): {
  result: Result<Permission[]>
  addingGroupIds: string[]
  changingPermissionIds: string[]
  removingPermissionIds: string[]
  addPermission: (groupId: string, policy: PermissionPolicy) => Promise<void>
  changePermissionPolicy: (
    permissionId: string,
    newPolicy: PermissionPolicy
  ) => Promise<void>
  removePermission: (permissionId: string) => Promise<void>
} {
  const [addingGroupIds, setAddingGroupIds] = React.useState<string[]>([])
  const [changingPermissionIds, setChangingPermissionIds] = React.useState<
    string[]
  >([])
  const [removingPermissionIds, setRemovingPermissionIds] = React.useState<
    string[]
  >([])
  const [result, setResult] = React.useState<Result<Permission[]>>(
    new Result.Loading()
  )

  React.useEffect(() => {
    service
      .getPermissions(workflowId)
      .then((res) => setResult(new Result.Success(res)))
      .catch((e) => setResult(new Result.Failure(e)))
  }, [workflowId])

  const addPermission = React.useCallback(
    async (groupId: string, policy: PermissionPolicy) => {
      setAddingGroupIds((prev) => [...prev, groupId])
      try {
        const res = await service.addPermission(workflowId, groupId, policy)
        // キャッシュ更新
        setResult((prev) => {
          if (prev.loading || prev.error) {
            return prev
          }
          return new Result.Success([res, ...prev.data])
        })
      } finally {
        setAddingGroupIds((prev) => _.reject(prev, (it) => it === groupId))
      }
    },
    [workflowId]
  )

  const changePermissionPolicy = React.useCallback(
    async (permissionId: string, newPolicy: PermissionPolicy) => {
      setChangingPermissionIds((prev) => [...prev, permissionId])
      try {
        const res = await service.changePermission(
          workflowId,
          permissionId,
          newPolicy
        )
        // キャッシュ更新
        setResult((prev) => {
          if (prev.loading || prev.error) {
            return prev
          }
          return new Result.Success(
            prev.data.map((permission) => {
              if (permission.id === permissionId) {
                return res
              } else {
                return permission
              }
            })
          )
        })
      } finally {
        setChangingPermissionIds((prev) =>
          _.reject(prev, (it) => it === permissionId)
        )
      }
    },
    [workflowId]
  )

  const removePermission = React.useCallback(
    async (permissionId: string) => {
      setRemovingPermissionIds((prev) => [...prev, permissionId])
      try {
        await service.removePermission(workflowId, permissionId)
        // キャッシュ更新
        setResult((prev) => {
          if (prev.loading || prev.error) {
            return prev
          }
          return new Result.Success(
            _.reject(prev.data, (it) => it.id === permissionId)
          )
        })
      } finally {
        setRemovingPermissionIds((prev) =>
          _.reject(prev, (it) => it === permissionId)
        )
      }
    },
    [workflowId]
  )

  return {
    result,
    addingGroupIds,
    addPermission,
    changingPermissionIds,
    changePermissionPolicy,
    removingPermissionIds,
    removePermission,
  }
}
