import * as utils from '~/common/utils'
import { ViewWidgetJson } from '~/data/workflow/widget/json'
import { ViewWidgetDefinition } from '~/domain/workflow/widget/WidgetDefinition'

export function mapJsonToViewWidgetDefinition(
  json: ViewWidgetJson
): ViewWidgetDefinition {
  switch (json.formType) {
    case 'trigger_instruction': {
      const triggerInstruction = json.triggerInstruction
      return {
        formType: json.formType,
        instructionText: json.instructionText,
        triggerInstruction: {
          triggerInstructionId: triggerInstruction.triggerInstructionId,
          parameterFieldKeys: triggerInstruction.parameterFieldKeys,
        },
      }
    }
    default: {
      return utils.assertNever(json.formType)
    }
  }
}
