import { Tson } from '~/data/Tson'
import {
  AccountWidgetJson,
  AssistWidgetJson,
  CheckWidgetJson,
  CronWidgetJson,
  DateRangeWidgetJson,
  DictWidgetJson,
  InputWidgetJson,
  LegacyAccountWidgetJson,
  ListWidgetJson,
  MultiAssistWidgetJson,
  MultiProviderAccountWidgetJson,
  MultilineTextWidgetJson,
  NumberWidgetJson,
  RadioWidgetJson,
  SalesforceSearchConditionWidgetJson,
  SelectWidgetJson,
  StructWidgetJson,
  SwitchWidgetJson,
  TextWidgetJson,
} from '~/data/workflow/widget/json'

export function mapToInputWidgetJson(tson: Tson): InputWidgetJson {
  const formType = tson.getString('formType')
  switch (formType) {
    case 'text':
      return mapToTextWidgetJson(tson)
    case 'multiline_text':
      return mapToMultilineTextWidgetJson(tson)
    case 'number':
      return mapToNumberWidgetJson(tson)
    case 'radio':
      return mapToRadioWidgetJson(tson)
    case 'check':
      return mapToCheckWidgetJson(tson)
    case 'select':
      return mapToSelectWidgetJson(tson)
    case 'switch':
      return mapToSwitchWidgetJson(tson)
    case 'list':
      return mapToListWidgetJson(tson)
    case 'struct':
      return mapToStructWidgetJson(tson)
    case 'dict':
      return mapToDictWidgetJson(tson)
    case 'assist':
      return mapToAssistWidgetJson(tson)
    case 'multi_assist':
      return mapToMultiAssistWidgetJson(tson)
    case 'account':
      return mapToAccountWidgetJson(tson)
    case 'multi_provider_account':
      return mapToMultiProviderAccountWidgetJson(tson)
    case 'legacy_account':
      return mapToLegacyAccountWidgetJson(tson)
    case 'cron':
      return mapToCronWidgetJson()
    case 'salesforce_search_condition':
      return mapToSalesforceSearchConditionWidgetJson(tson)
    case 'date_range':
      return mapToDateRangeWidgetJson(tson)
    default:
      throw new Error(`Unknown form type: ${formType}`)
  }
}

function mapToTextWidgetJson(tson: Tson): TextWidgetJson {
  return {
    formType: 'text',
    pattern: tson.getString('pattern', false),
    placeholder: tson.getString('placeholder', false),
  }
}

function mapToMultilineTextWidgetJson(tson: Tson): MultilineTextWidgetJson {
  return {
    formType: 'multiline_text',
    placeholder: tson.getString('placeholder', false),
  }
}

function mapToNumberWidgetJson(tson: Tson): NumberWidgetJson {
  return {
    formType: 'number',
    maxValue: tson.getNumber('maxValue', false),
    minValue: tson.getNumber('minValue', false),
    step: tson.getNumber('step', false),
  }
}

function mapToRadioWidgetJson(tson: Tson): RadioWidgetJson {
  return {
    formType: 'radio',
    options: tson.getArray('options').map(mapToInputWidgetOptionJson),
  }
}

function mapToInputWidgetOptionJson(tson: Tson): InputWidgetJson.Option {
  return {
    label: tson.getString('label'),
    value: tson.getValue('value').asIs(),
  }
}

function mapToCheckWidgetJson(tson: Tson): CheckWidgetJson {
  return {
    formType: 'check',
    options: tson.getArray('options').map(mapToInputWidgetOptionJson),
  }
}

function mapToSelectWidgetJson(tson: Tson): SelectWidgetJson {
  return {
    formType: 'select',
    options: tson.getArray('options').map(mapToInputWidgetOptionJson),
  }
}

function mapToSwitchWidgetJson(tson: Tson): SwitchWidgetJson {
  return {
    formType: 'switch',
    label: tson.getString('label'),
  }
}

function mapToListWidgetJson(tson: Tson): ListWidgetJson {
  return {
    formType: 'list',
    itemForm: mapToInputWidgetJson(tson.getValue('itemForm')),
  }
}

function mapToStructWidgetJson(tson: Tson): StructWidgetJson {
  return {
    formType: 'struct',
    entries: tson.getArray('entries').map(mapToStructWidgetEntryJson),
  }
}

function mapToStructWidgetEntryJson(tson: Tson): InputWidgetJson.Entry {
  return {
    key: tson.getString('key'),
    label: tson.getString('label'),
    valueForm: mapToInputWidgetJson(tson.getValue('valueForm')),
  }
}

function mapToDictWidgetJson(tson: Tson): DictWidgetJson {
  return {
    formType: 'dict',
    valueForm: mapToInputWidgetJson(tson.getValue('valueForm')),
  }
}

function mapToAssistWidgetJson(tson: Tson): AssistWidgetJson {
  const assist = tson.getValue('assist')
  return {
    formType: 'assist',
    assist: {
      assistId: assist.getString('assistId'),
      parameterFieldKeys: assist
        .getArray('parameterFieldKeys')
        .map((it) => it.asString()),
      queryPlaceholder: assist.getString('queryPlaceholder'),
    },
    fallbackForm: mapToInputWidgetJson(tson.getValue('fallbackForm')),
  }
}

function mapToMultiAssistWidgetJson(tson: Tson): MultiAssistWidgetJson {
  const assist = tson.getValue('assist')
  return {
    formType: 'multi_assist',
    assist: {
      assistId: assist.getString('assistId'),
      parameterFieldKeys: assist
        .getArray('parameterFieldKeys')
        .map((it) => it.asString()),
      queryPlaceholder: assist.getString('queryPlaceholder'),
    },
    fallbackForm: mapToInputWidgetJson(tson.getValue('fallbackForm')),
  }
}

function mapToAccountWidgetJson(tson: Tson): AccountWidgetJson {
  return {
    formType: 'account',
    provider: tson.getString('provider'),
    valueForm: mapToInputWidgetJson(tson.getValue('valueForm')),
  }
}

function mapToMultiProviderAccountWidgetJson(
  tson: Tson
): MultiProviderAccountWidgetJson {
  return {
    formType: 'multi_provider_account',
    fieldLabel: tson.getString('fieldLabel'),
    providers: tson.getArray('providers').map((it) => {
      return {
        label: it.getString('label'),
        value: it.getString('value'),
      }
    }),
    valueForm: mapToInputWidgetJson(tson.getValue('valueForm')),
  }
}

function mapToLegacyAccountWidgetJson(tson: Tson): LegacyAccountWidgetJson {
  return {
    formType: 'legacy_account',
    provider: tson.getString('provider'),
  }
}

function mapToCronWidgetJson(/* tson: Tson */): CronWidgetJson {
  return {
    formType: 'cron',
  }
}

function mapToSalesforceSearchConditionWidgetJson(
  tson: Tson
): SalesforceSearchConditionWidgetJson {
  return {
    formType: 'salesforce_search_condition',
    accountUidFieldKey: tson.getString('accountUidFieldKey'),
    sobjectNameFieldKey: tson.getString('sobjectNameFieldKey'),
    canUseStateOperators: tson.getBoolean('canUseStateOperators'),
  }
}

function mapToDateRangeWidgetJson(tson: Tson): DateRangeWidgetJson {
  return {
    formType: 'date_range',
    startLimit: tson.getNumber('startLimit'),
    endLimit: tson.getNumber('endLimit'),
  }
}
