import config from '~/common/config'
import { assertNever } from '~/common/utils'
import { Tson } from '~/data/Tson'
import { requestPost } from '~/data/request'
import { mapModelToExpectedObjectJson } from '~/data/workflow/expectedObject/mapModelToExpectedObjectJson'
import { mapModelToInputSourceJson } from '~/data/workflow/source/mapModelToWorkflowSourceBodyJson'
import { TestPreviewApi } from '~/data/workflow/test/TestPreviewApi'
import { TestPreviewJson } from '~/data/workflow/test/json'
import { mapModelToExpectedObjectSnapshotJson } from '~/data/workflow/test/modelToJsonMapper'
import { mapToTestPreviewJson } from '~/data/workflow/test/toJsonMapper'
import {
  ExpectedObject,
  ExpectedObjects,
} from '~/domain/workflow/expectedObject/ExpectedObjects'
import { InputSource } from '~/domain/workflow/source/WorkflowSourceBody'
import { ExpectedObjectSnapshot } from '~/domain/workflow/test/TestResult'
import { TestTarget } from '~/domain/workflow/test/TestTarget'

export class TestPreviewApiImpl implements TestPreviewApi {
  async getPreview(
    target: TestTarget,
    inputs: InputSource[],
    useBulk: boolean,
    expectedObjects: ExpectedObjects,
    snapshots: ExpectedObjectSnapshot[]
  ): Promise<TestPreviewJson> {
    switch (target.kind) {
      case 'trigger':
        return getTriggerPreview(target.triggerId, inputs)
      case 'task':
        return getTaskPreview(
          target.taskId,
          target.actionId,
          inputs,
          useBulk,
          expectedObjects.getAllInstances(),
          snapshots
        )
      default:
        assertNever(target)
    }
  }
}

async function getTriggerPreview(
  triggerId: string,
  inputs: InputSource[]
): Promise<TestPreviewJson> {
  const body = {
    inputs: inputs.map(mapModelToInputSourceJson),
    triggerId,
  }
  const res = await requestPost(`${config.apiRoot}/test/preview/trigger`, body)
  return mapToTestPreviewJson(new Tson(res))
}

async function getTaskPreview(
  taskId: string,
  actionId: string,
  inputs: InputSource[],
  useBulk: boolean,
  expectedObjects: ExpectedObject[],
  snapshots: ExpectedObjectSnapshot[]
): Promise<TestPreviewJson> {
  const body = {
    inputs: inputs.map(mapModelToInputSourceJson),
    expectedObjects: expectedObjects.map(mapModelToExpectedObjectJson),
    snapshots: snapshots.map(mapModelToExpectedObjectSnapshotJson),
    taskId,
    actionId,
    useBulk,
  }
  const res = await requestPost(`${config.apiRoot}/test/preview/task`, body)
  return mapToTestPreviewJson(new Tson(res))
}
