import * as React from 'react'
import { Switch, useHistory } from 'react-router'
import { Route } from 'react-router-dom'

import { useMe } from '~/presentation/AnyflowAppContext'
import GroupDetailPage from '~/presentation/group/GroupDetailPage'
import GroupListPage from '~/presentation/group/GroupListPage'
import { GROUP_DETAIL, GROUP_LIST, WORKFLOW_LIST } from '~/routes'

export const GroupRoute = () => {
  const history = useHistory()
  const me = useMe()

  React.useEffect(() => {
    if (!me.isAdmin) {
      history.push(WORKFLOW_LIST)
    }
  }, [me, history])

  if (!me.isAdmin) {
    return null
  }

  return (
    <Switch>
      <Route exact={true} path={GROUP_LIST} component={GroupListPage} />
      <Route path={GROUP_DETAIL} component={GroupDetailPage} />
    </Switch>
  )
}
