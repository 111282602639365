import * as React from 'react'
import { Zap } from 'react-feather'
import { animated, useSpring } from 'react-spring'

import Text from '~/components/atoms/Text'
import NodeChangeButton from '~/components/molecules/NodeChangeButton'
import ForeignObject from '~/components/utils/ForeignObject'
import { AppDefinition } from '~/domain/workflow/app/AppDefinition'

interface Props {
  name: string
  app: AppDefinition
  x: number
  y: number
  readonly: boolean
  onClick?: React.MouseEventHandler<SVGCircleElement>
  onChangeTriggerClick?: React.MouseEventHandler<HTMLDivElement>
}

export const triggerNodeSize = 100

const TriggerNode: React.FunctionComponent<Props> = ({
  name,
  app,
  x,
  y,
  readonly,
  onClick,
  onChangeTriggerClick,
}) => {
  const [hovering, setHovering] = React.useState(false)
  const removeButtonProps = useSpring({
    to: {
      opacity: hovering ? 1 : 0,
      transform: hovering ? 'scale(1)' : 'scale(0.7)',
    },
    config: { tension: 500 },
  })
  const dropShadowProps = useSpring({
    from: { transform: 'scale(1)' },
    to: {
      transform: hovering ? 'scale(1.13)' : 'scale(1)',
    },
    config: { tension: 500 },
  })
  return (
    <g
      transform={`translate(${x + 25}, ${y + 25})`}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <animated.circle
        cx={triggerNodeSize / 2}
        cy={triggerNodeSize / 2}
        r={triggerNodeSize / 2}
        fill={app.color}
        fillOpacity={0.5}
        style={{
          ...dropShadowProps,
          transformOrigin: `${triggerNodeSize / 2}px ${triggerNodeSize / 2}px`,
        }}
      />
      <circle
        cx={triggerNodeSize / 2}
        cy={triggerNodeSize / 2}
        r={triggerNodeSize / 2}
        fill={app.color}
        cursor={onClick ? 'pointer' : 'auto'}
        // tabIndex={0}
        onClick={onClick}
      />
      <image
        // 画像サイズの半分
        x={(triggerNodeSize - 60) / 2}
        y={(triggerNodeSize - 60) / 2}
        xlinkHref={app.iconPath}
        width={60}
        height={60}
        pointerEvents="none"
      />
      <g
        transform={`translate(${triggerNodeSize - 30}, ${
          triggerNodeSize - 30
        })`}
        cursor={onClick ? 'pointer' : 'auto'}
        onClick={onClick}
      >
        <circle cx={20} cy={20} r={20} fill={'black'} />
        <Zap
          x={7}
          y={7}
          size={25}
          fill={'white'}
          color={'white'}
          pointerEvents="none"
        />
      </g>
      {onChangeTriggerClick ? (
        <g transform={`translate(-18, -18)`}>
          <ForeignObject width={50} height={50}>
            {/* ホバー可能範囲を広げるための div */}
            <div
              style={{
                width: 50,
                height: 50,
              }}
            >
              <animated.div
                style={{
                  transformOrigin: `15px 15px`,
                  ...removeButtonProps,
                }}
              >
                <NodeChangeButton
                  disabled={readonly}
                  onClick={onChangeTriggerClick}
                />
              </animated.div>
            </div>
          </ForeignObject>
        </g>
      ) : null}
      <g transform={`translate(-60, ${triggerNodeSize + 48})`}>
        <ForeignObject width={60 + triggerNodeSize + 60} height={30}>
          <Text
            element="p"
            color="primary"
            fontSize="m"
            fontWeight="bold"
            lineHeight="heading"
            truncated={true}
            style={{
              textAlign: 'center',
            }}
          >
            {app.name}
          </Text>
        </ForeignObject>
      </g>
      <g transform={`translate(-60, ${triggerNodeSize + 73})`}>
        <ForeignObject width={60 + triggerNodeSize + 60} height={55}>
          <Text
            element="p"
            color="secondary"
            fontSize="s"
            fontWeight="normal"
            lineHeight="heading"
            style={{
              textAlign: 'center',
            }}
          >
            {name}
          </Text>
        </ForeignObject>
      </g>
    </g>
  )
}

export default TriggerNode
