import styled from '@emotion/styled'
import React from 'react'

import TypeSign from '~/components/atoms/TypeSign'
import { ValueType } from '~/domain/ValueType'
import { valueTypeToString } from '~/presentation/workflow/detail/editor/valueTypeToString'
import * as vars from '~/styles/variables'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  valueType: ValueType
}

const WithRightTypeSign: React.FC<Props> = ({ valueType, ...props }) => {
  return (
    <Container {...props}>
      <div style={{ marginRight: vars.space.m }}>{props.children}</div>
      <div title={valueTypeToString(valueType)} style={{ width: 25 }}>
        <TypeSign valueType={valueType} />
      </div>
    </Container>
  )
}

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export default WithRightTypeSign
