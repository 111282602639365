import { Pagination } from '~/common/Pagination'
import config from '~/common/config'
import { Tson } from '~/data/Tson'
import { GroupSummaryJson } from '~/data/group/GroupJson'
import {
  mapJsonToGroup,
  mapJsonToGroupSummary,
} from '~/data/group/mapJsonToGroup'
import {
  mapToGroupJson,
  mapToGroupSummaryJson,
} from '~/data/group/mapToGroupJson'
import requestJson, {
  HttpError,
  requestDelete,
  requestPatch,
  requestPost,
} from '~/data/request'
import { mapJsonToUser } from '~/data/user/mapJsonToUser'
import { mapToUserJson } from '~/data/user/mapToUserJson'
import { sanitize } from '~/data/utils'
import { Group } from '~/domain/group/Group'
import { GroupService } from '~/domain/group/GroupService'
import { GroupSummary } from '~/domain/group/GroupSummary'
import { User } from '~/domain/user/User'

export class GroupServiceImpl implements GroupService {
  async getGroups(page: number): Promise<Pagination<GroupSummary>> {
    const res = await requestJson(`${config.apiRoot}/groups?page=${page}`)
    const json = mapToGroupsResponseJson(new Tson(res))
    return {
      paginationMeta: {
        countPerPage: json.limit,
        currentPageNumber: json.current,
        totalCount: json.count,
      },
      items: json.results.map(mapJsonToGroupSummary),
    }
  }

  async getAllGroups(): Promise<GroupSummary[]> {
    const res = await requestJson(`${config.apiRoot}/groups?all=true`)
    const json = new Tson(res).asArray().map(mapToGroupSummaryJson)
    return json.map(mapJsonToGroupSummary)
  }

  async getGroup(id: string): Promise<Group | undefined> {
    try {
      const res = await requestJson(`${config.apiRoot}/groups/${sanitize(id)}`)
      const json = mapToGroupJson(new Tson(res))
      return mapJsonToGroup(json)
    } catch (e) {
      if (e instanceof HttpError && e.statusCode === 404) {
        return undefined
      }
      throw e
    }
  }

  async createGroup(name: string): Promise<GroupSummary> {
    const res = await requestPost(`${config.apiRoot}/groups`, { name })
    const json = mapToGroupSummaryJson(new Tson(res))
    return mapJsonToGroupSummary(json)
  }

  async deleteGroup(id: string): Promise<void> {
    await requestDelete(`${config.apiRoot}/groups/${sanitize(id)}`)
  }

  async addUser(groupId: string, userId: string): Promise<User> {
    const res = await requestPost(
      `${config.apiRoot}/groups/${sanitize(groupId)}/members`,
      {
        user_slug: userId,
      }
    )
    const json = mapToUserJson(new Tson(res))
    return mapJsonToUser(json)
  }

  async removeUser(groupId: string, userId: string): Promise<void> {
    await requestDelete(
      `${config.apiRoot}/groups/${sanitize(groupId)}/members/${sanitize(
        userId
      )}`
    )
  }

  async updateGroupName(id: string, name: string): Promise<void> {
    await requestPatch(`${config.apiRoot}/groups/${sanitize(id)}`, {
      name,
    })
  }
}

interface GroupsResponseJson {
  limit: number
  current: number
  count: number
  results: GroupSummaryJson[]
}

function mapToGroupsResponseJson(tson: Tson): GroupsResponseJson {
  return {
    count: tson.getNumber('count'),
    current: tson.getNumber('current'),
    limit: tson.getNumber('limit'),
    results: tson.getArray('results').map(mapToGroupSummaryJson),
  }
}
