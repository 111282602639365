import { Global, css } from '@emotion/core'
import * as React from 'react'

import normalizeStyles from '~/styles/normalize'
import slickStyles from '~/styles/react-slick'
import * as vars from '~/styles/variables'

const GlobalStyles = () => (
  <Global
    styles={css`
      ${normalizeStyles}
      ${slickStyles}

      /** Sentry のレポートダイアログ*/

      .sentry-error-embed-wrapper {
        z-index: ${vars.zIndex.sentryErrorDialog} !important;
      }

      *,
      *::before,
      *::after {
        margin: 0;
        box-sizing: border-box;
      }

      html,
      body,
      #react-root {
        height: 100%;
      }

      body {
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        color: ${vars.fontColor.primary};
        font-family: ${vars.fontFamily.sansSerif};
        font-feature-settings: 'palt';
        font-weight: ${vars.fontWeight.normal};
        line-height: 1.5;
      }

      a {
        color: ${vars.color.theme};
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      strong {
        font-weight: ${vars.fontWeight.bold};
      }
    `}
  />
)

export default GlobalStyles
