import { NodeView } from 'prosemirror-view'
import React from 'react'
import ReactDOM from 'react-dom'

import { RenderableEntity } from '~/domain/workflow/source/RenderableElement'
import { EntityComponent } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/EntityComponent'

export class EntityNodeView implements NodeView {
  public readonly dom: HTMLElement
  public readonly portal: React.ReactPortal

  constructor(entity: RenderableEntity, label: string) {
    this.dom = document.createElement('span')
    this.dom.classList.add('ProseMirror__dom')

    const Component: React.FC = () => (
      <span className="ProseMirror__reactComponent">
        <EntityComponent entity={entity} label={label} />
      </span>
    )

    this.portal = ReactDOM.createPortal(<Component />, this.dom)
  }

  update(): boolean {
    return true
  }
}
