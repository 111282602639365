import * as React from 'react'

interface ProblemItem {
  title: React.ReactNode
  imagePath: string
}

interface SolutionItem {
  title: React.ReactNode
  fontSize: 'l' | 'm' | 's'
  appIcons: {
    iconName: string
    width?: number
  }[]
}

interface LpData {
  id: string
  problem: {
    title: string
    items: ProblemItem[]
  }
  solution: {
    items: SolutionItem[]
  }
}

export const lpData: LpData[] = [
  {
    id: 't3Opm85z',
    problem: {
      title: 'kintone をお使いの方、例えばこのようなお悩みはありませんか？',
      items: [
        {
          title: (
            <>
              kintone で受注後、
              <br />
              契約書発行フローを自動化したい
            </>
          ),
          imagePath: '/static/images/lp/recipes/illust_B.svg',
        },
        {
          title: (
            <>
              kintone の顧客・商談情報を
              <br />
              手動で更新するのは限界
            </>
          ),
          imagePath: '/static/images/lp/recipes/illust_A.svg',
        },
        {
          title: (
            <>
              請求書の発行業務や
              <br />
              入金状況の確認工数を減らしたい
            </>
          ),
          imagePath: '/static/images/lp/recipes/illust_C.svg',
        },
      ],
    },
    solution: {
      items: [
        {
          title: (
            <>
              kintone の取引先データを
              <br />
              1クリックで会計 freee へ転記
            </>
          ),
          fontSize: 'm',
          appIcons: [
            { iconName: 'kintone.png', width: 120 },
            { iconName: 'freee.svg', width: 100 },
          ],
        },
        {
          title: (
            <>
              kintone の取引先リストに対して
              <br />
              Gmail で一斉メール送信
            </>
          ),
          fontSize: 'm',
          appIcons: [
            { iconName: 'kintone.png', width: 120 },
            { iconName: 'gmail.png', width: 100 },
          ],
        },
        {
          title: (
            <>
              バラバラになった顧客データを
              <br />
              kintone と Senses で統合
            </>
          ),
          fontSize: 'm',
          appIcons: [
            { iconName: 'kintone.png', width: 120 },
            { iconName: 'senses.png', width: 120 },
          ],
        },
        {
          title: (
            <>
              お問い合わせフォームのデータを
              <br />
              kintone にて新規登録後、Slack へ通知
            </>
          ),
          fontSize: 'm',
          appIcons: [
            { iconName: 'google_spread_sheet.png', width: 30 },
            { iconName: 'kintone.png', width: 90 },
            { iconName: 'slack.svg', width: 110 },
          ],
        },
      ],
    },
  },
  {
    id: 'Mt8p54ee',
    problem: {
      title: 'Salesforce をお使いの方、例えばこのようなお悩みはありませんか？',
      items: [
        {
          title: (
            <>
              Salesforce で受注後、
              <br />
              契約書発行フローを自動化したい
            </>
          ),
          imagePath: '/static/images/lp/recipes/illust_B.svg',
        },
        {
          title: (
            <>
              Salesforce の顧客・商談情報を
              <br />
              手動で更新するのは限界
            </>
          ),
          imagePath: '/static/images/lp/recipes/illust_A.svg',
        },
        {
          title: (
            <>
              請求書の発行業務や
              <br />
              入金状況の確認工数を減らしたい
            </>
          ),
          imagePath: '/static/images/lp/recipes/illust_C.svg',
        },
      ],
    },
    solution: {
      items: [
        {
          title: (
            <>
              Salesforce からスプレッドシートへ
              <br />
              顧客データを定期的にインポート
            </>
          ),
          fontSize: 'm',
          appIcons: [
            { iconName: 'salesforce.png' },
            { iconName: 'google_spread_sheet.png', width: 40 },
          ],
        },
        {
          title: (
            <>
              Salesforce からスプレッドシートへ
              <br />
              月の売上データを定期的に出力し予実管理
            </>
          ),
          fontSize: 'm',
          appIcons: [
            { iconName: 'salesforce.png' },
            { iconName: 'google_spread_sheet.png', width: 40 },
          ],
        },
        {
          title: (
            <>
              Salesforce の商談内容や
              <br />
              To Do に関する情報を Slack へ通知
            </>
          ),
          fontSize: 'm',
          appIcons: [
            { iconName: 'salesforce.png' },
            { iconName: 'slack.svg', width: 120 },
          ],
        },
        {
          title: (
            <>
              お問い合わせフォームのデータを
              <br />
              Salesforce にて新規登録後、Slack へ通知
            </>
          ),
          fontSize: 'm',
          appIcons: [
            { iconName: 'google_spread_sheet.png', width: 35 },
            { iconName: 'salesforce.png', width: 60 },
            { iconName: 'slack.svg', width: 110 },
          ],
        },
        {
          title: (
            <>
              Salesforce ↔ クラウドサインで契約書を自動作成
              <br />
              契約締結後、商談フェーズの変更を Slack へ自動通知
            </>
          ),
          fontSize: 's',
          appIcons: [
            { iconName: 'salesforce.png', width: 60 },
            { iconName: 'cloudsign.png', width: 90 },
            { iconName: 'slack.svg', width: 100 },
          ],
        },
        {
          title: (
            <>
              商談～契約書締結～受注の営業プロセスを
              <br />
              Salesforce とクラウドサインで自動化
            </>
          ),
          fontSize: 'm',
          appIcons: [
            { iconName: 'salesforce.png', width: 70 },
            { iconName: 'cloudsign.png', width: 110 },
          ],
        },
      ],
    },
  },
  {
    id: 'be94E3L1',
    problem: {
      title: 'Salesforce をお使いの方、例えばこのようなお悩みはありませんか？',
      items: [
        {
          title: (
            <>
              Salesforce で受注後、
              <br />
              契約書発行フローを自動化したい
            </>
          ),
          imagePath: '/static/images/lp/recipes/illust_B.svg',
        },
        {
          title: (
            <>
              Salesforce の顧客・商談情報を
              <br />
              手動で更新するのは限界
            </>
          ),
          imagePath: '/static/images/lp/recipes/illust_A.svg',
        },
        {
          title: (
            <>
              請求書の発行業務や
              <br />
              入金状況の確認工数を減らしたい
            </>
          ),
          imagePath: '/static/images/lp/recipes/illust_C.svg',
        },
      ],
    },
    solution: {
      items: [
        {
          title: '商談発注から契約書の自動発行',
          fontSize: 'l',
          appIcons: [
            { iconName: 'salesforce.png' },
            { iconName: 'cloudsign.png', width: 100 },
          ],
        },
        {
          title: '契約締結後のフェーズ自動更新',
          fontSize: 'l',
          appIcons: [
            { iconName: 'cloudsign.png', width: 100 },
            { iconName: 'salesforce.png' },
          ],
        },
        {
          title: '毎月の請求書の自動発行',
          fontSize: 'l',
          appIcons: [
            { iconName: 'salesforce.png' },
            { iconName: 'freee.svg', width: 120 },
          ],
        },
      ],
    },
  },
]
