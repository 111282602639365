import * as React from 'react'

import Text from '~/components/atoms/Text'
import * as vars from '~/styles/variables'
import { borderWidth, widgetBorderStyle } from '~/styles/widget'

interface Props {
  className?: string
}

const VariableUnavailableBadge: React.FC<Props> = (props) => {
  return (
    <div
      className={props.className}
      style={{
        paddingTop: vars.space.xxs * 1.5,
        paddingRight: vars.space.s,
        paddingBottom: vars.space.xxs * 1.5,
        paddingLeft: vars.space.s,
        borderTopRightRadius: widgetBorderStyle.borderRadius - borderWidth,
        borderBottomLeftRadius: widgetBorderStyle.borderRadius - borderWidth,
        backgroundColor: vars.color.lightGray,
      }}
    >
      <Text
        element="span"
        fontSize="xs"
        color="secondary"
        lineHeight="just"
        style={{ display: 'block' }}
      >
        変数は使えません
      </Text>
    </div>
  )
}

export default VariableUnavailableBadge
