import * as _ from 'lodash'

import { Pagination } from '~/common/Pagination'
import { delay } from '~/common/utils'
import { LookupTableApi } from '~/data/lookupTable/LookupTableApi'
import {
  LookupTableCellJson,
  LookupTableColumnSchemaJson,
  LookupTableJson,
  LookupTableRowJson,
} from '~/data/lookupTable/LookupTableJson'

export class MockLookupTableApi implements LookupTableApi {
  async get(lookupTableId: string): Promise<LookupTableJson> {
    await delay(1000)
    const index = _.findIndex(lookupTables, (it) => it.slug === lookupTableId)
    const lookupTable = _.cloneDeep(lookupTables[index])
    return lookupTable
  }

  async getList(pageNumber: number): Promise<Pagination<LookupTableJson>> {
    await delay(1000)
    return {
      paginationMeta: {
        countPerPage: 10,
        currentPageNumber: pageNumber,
        totalCount: 100,
      },
      items: summaries,
    }
  }

  async create(): Promise<LookupTableJson> {
    await delay(1000)
    return lookupTables[0]
  }

  async getColumnSchemas(
    lookupTableId: string
  ): Promise<LookupTableColumnSchemaJson[]> {
    await delay(1000)
    const _schemas = schemas[lookupTableId] ?? []
    return _schemas
  }

  async updateColumnSchema(
    lookupTableId: string,
    columnSchema: LookupTableColumnSchemaJson
  ): Promise<LookupTableColumnSchemaJson> {
    console.log(
      `[API] updateSchema is called. lookupTableId: ${lookupTableId}, columnSchema: ${JSON.stringify(
        columnSchema
      )}`
    )
    await delay(1000)
    const _schemas: LookupTableColumnSchemaJson[] = schemas[lookupTableId] ?? []
    const schema = _schemas.find((it) => it.id === columnSchema.id)
    if (schema === undefined) {
      throw new Error(
        `No such lookupTableId: ${lookupTableId} or columnSchemaId: ${columnSchema.id}`
      )
    }
    schemas[lookupTableId] = schemas[lookupTableId].map((it) =>
      it.id === columnSchema.id ? columnSchema : it
    )
    return schema
  }

  async updateTableName(
    lookupTableId: string,
    tableName: string
  ): Promise<LookupTableJson> {
    console.log(
      `[API] updateTableName is called. lookupTableId: ${lookupTableId}, tableName: ${tableName}`
    )
    await delay(1000)
    const table = lookupTables.find((it) => it.slug === lookupTableId)
    if (table === undefined) {
      throw new Error(`No such lookupTableId: ${lookupTableId}`)
    }
    table.name = tableName
    return table
  }

  async getRows(
    lookupTableId: string,
    pageNumber: number
  ): Promise<Pagination<LookupTableRowJson>> {
    await delay(1000)
    const _entries = entries[lookupTableId] ?? []
    return {
      paginationMeta: {
        countPerPage: 10,
        currentPageNumber: pageNumber,
        totalCount: 100,
      },
      items: _entries,
    }
  }

  async insertRow(
    lookupTableId: string,
    cells: LookupTableCellJson[]
  ): Promise<LookupTableRowJson> {
    const rows = entries[lookupTableId]
    const maxId = _.maxBy(rows, (it) => it.id)?.id ?? 0
    const row = {
      id: maxId + 1,
      cells,
    }
    entries[lookupTableId]?.push(row)
    return row
  }

  async deleteRow(lookupTableId: string, rowId: number): Promise<void> {
    if (entries[lookupTableId]?.find((it) => it.id === rowId) === undefined) {
      throw new Error(
        `The row ${rowId} doesn't exist on the table ${lookupTableId}`
      )
    }
    entries[lookupTableId] = _.reject(
      entries[lookupTableId],
      (it) => it.id === rowId
    )
  }
}

const lookupTables: LookupTableJson[] = [
  {
    slug: 'table1',
    name: 'CloudSign と Salesforce の紐付け',
    description: '紐付けするテーブルです',
    updatedAt: '2020-06-17T10:30:09.562712+09:00',
  },
  {
    slug: 'table2',
    name: 'Google と Slack のテーブル',
    description: '',
    updatedAt: '2019-06-17T10:30:09.562712+09:00',
  },
]

const summaries: LookupTableJson[] = [
  {
    slug: 'table1',
    name: 'CloudSign と Salesforce の紐付け',
    description: '紐付けするテーブルです',
    updatedAt: '2020-06-17T10:30:09.562712+09:00',
  },
  {
    slug: 'table2',
    name: 'Google と Slack のテーブル',
    description: '',
    updatedAt: '2019-06-17T10:30:09.562712+09:00',
  },
]

const schemas: { [lookupTableId: string]: LookupTableColumnSchemaJson[] } = {
  table1: [
    {
      id: 1,
      label: '書類ID',
      appId: 'CloudSignApp',
    },
    {
      id: 2,
      label: '商談ID',
      appId: 'SalesforceApp',
    },
    {
      id: 3,
      label: 'Untitield',
      appId: '',
    },
    {
      id: 4,
      label: 'Untitield',
      appId: '',
    },
    {
      id: 5,
      label: 'Untitield',
      appId: '',
    },
    {
      id: 6,
      label: 'Untitield',
      appId: '',
    },
    {
      id: 7,
      label: 'Untitield',
      appId: '',
    },
  ],
  table2: [
    {
      id: 1,
      label: 'SlackのID',
      appId: 'SlackApp',
    },
    {
      id: 2,
      label: 'GoogleのID',
      appId: '',
    },
  ],
}

const entries: { [lookupTableId: string]: LookupTableRowJson[] } = {
  table1: [
    {
      id: 1,
      cells: [
        {
          schemaId: 1,
          value: 'abc',
        },
      ],
    },
  ],
  table2: [],
}
