import styled from '@emotion/styled'
import * as React from 'react'
import { Minus, Plus } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { animated, useSpring } from 'react-spring'

import * as editorDuck from '~/ducks/ui/editor'
import * as vars from '~/styles/variables'

type Props = {
  width: number
  height: number
  isEditingMode: boolean
}

const SCALE_DURATION = 0.4

const ScaleAdjustor: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const scale = useSelector(editorDuck.selectors.getScale)
  const tx = useSelector(editorDuck.selectors.getTx)
  const ty = useSelector(editorDuck.selectors.getTy)

  const containerProps = useSpring({
    opacity: props.isEditingMode ? 1 : 0,
  })

  const scaleTo = (s: number) => {
    dispatch(editorDuck.actions.setScale(scale * s))
    const _tx = tx * s + ((1 - s) * props.width) / 2
    dispatch(editorDuck.actions.setTx(_tx))
    const _ty = ty * s + ((1 - s) * props.height) / 2
    dispatch(editorDuck.actions.setTy(_ty))
  }

  return (
    <Container style={containerProps}>
      <Button onClick={() => scaleTo(1 + SCALE_DURATION)}>
        <Plus color={vars.color.icon}></Plus>
      </Button>
      <Button onClick={() => scaleTo(1 - SCALE_DURATION)}>
        <Minus color={vars.color.icon}></Minus>
      </Button>
    </Container>
  )
}

export default ScaleAdjustor

const Container = styled(animated.div)({
  position: 'fixed',
  bottom: vars.space.l,
  right: vars.space.l,
})

const Button = styled('button')({
  marginTop: vars.space.s,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 45,
  height: 45,
  cursor: 'pointer',
  border: `1px solid ${vars.color.border}`,
  borderRadius: vars.borderRadius.m,
  backgroundColor: vars.color.white,
  transition: 'transform .14s ease',
  boxShadow: vars.shadow.s,
  '&:focus': {
    outline: 'none',
  },
  '&:first-of-type': {
    marginTop: 0,
  },
  '&:hover': {
    transform: 'translateY(-1px)',
  },
  '&:active': {
    backgroundColor: vars.color.offWhite,
  },
})
