import produce from 'immer'
import * as _ from 'lodash'

import { InputValue } from '~/domain/workflow/source/InputValue'
import { EMPTY_ACTION_ID } from '~/ducks/editor/types'

export class WorkflowSourceBody {
  private readonly trigger?: TriggerSource
  private readonly task?: TaskSource

  constructor(trigger?: TriggerSource, task?: TaskSource) {
    this.trigger = _.cloneDeep(trigger)
    this.task = _.cloneDeep(task)
  }

  addEmptyTask(newTaskId: string, parentTaskId?: string): WorkflowSourceBody {
    if (this.task === undefined) {
      return new WorkflowSourceBody(
        this.trigger,
        this.createEmptyTask(newTaskId, [])
      )
    }
    if (parentTaskId === undefined) {
      return new WorkflowSourceBody(
        this.trigger,
        this.createEmptyTask(newTaskId, [this.task])
      )
    }
    const newTask = produce(this.task, (draft) => {
      const stack: TaskSource[] = [draft]
      while (stack.length > 0) {
        const task = stack.pop()
        if (task === undefined) {
          continue
        }
        if (task.taskId === parentTaskId) {
          task.tasks = [this.createEmptyTask(newTaskId, task.tasks)]
          break
        }
        stack.push(...task.tasks)
      }
    })
    return new WorkflowSourceBody(this.trigger, newTask)
  }

  removeAllEmptyTasks(): WorkflowSourceBody {
    if (this.task === undefined) {
      return this
    }
    return new WorkflowSourceBody(this.trigger, removeAllEmptyTasks(this.task))
  }

  replaceTask(taskId: string, actionId: string): WorkflowSourceBody {
    if (this.task === undefined) {
      return this
    }
    const newTask = produce(this.task, (draft) => {
      const stack: TaskSource[] = [draft]
      while (stack.length > 0) {
        const task = stack.pop()
        if (task === undefined) {
          continue
        }
        if (task.taskId === taskId) {
          task.actionId = actionId
          // アクションが変わると入力値も変わるはずなのでクリアする
          task.inputs = []
          break
        }
        stack.push(...task.tasks)
      }
    })
    return new WorkflowSourceBody(this.trigger, newTask)
  }

  replaceTrigger(triggerId: string): WorkflowSourceBody {
    const newTrigger: TriggerSource = {
      triggerId,
      inputs: [],
    }
    return new WorkflowSourceBody(newTrigger, this.task)
  }

  removeTask(taskId: string): WorkflowSourceBody {
    // タスクが存在しなければ何もしない
    if (this.task === undefined) {
      return this
    }
    const newRootTask = removeTask(this.task, taskId)
    return new WorkflowSourceBody(
      this.trigger,
      newRootTask !== undefined ? _.cloneDeep(newRootTask) : undefined
    )
  }

  setTaskInputs(taskId: string, inputs: InputSource[]): WorkflowSourceBody {
    if (this.task === undefined) {
      return this
    }
    const newTask = produce(this.task, (rootTask) => {
      if (rootTask.taskId === taskId) {
        rootTask.inputs = inputs
      } else {
        const stack: TaskSource[] = [rootTask]
        while (stack.length > 0) {
          const task = stack.pop()
          if (task === undefined) {
            continue
          }
          if (task.taskId === taskId) {
            task.inputs = inputs
            break
          }
          stack.push(...task.tasks)
        }
      }
    })
    return new WorkflowSourceBody(this.trigger, newTask)
  }

  setTriggerInputs(inputs: InputSource[]): WorkflowSourceBody {
    if (this.trigger === undefined) {
      return this
    }
    const newTrigger = produce(this.trigger, (trigger) => {
      trigger.inputs = inputs
    })
    return new WorkflowSourceBody(newTrigger, this.task)
  }

  findTask(taskId: string): TaskSource | undefined {
    if (this.task === undefined) {
      return undefined
    }
    return findTask(this.task, taskId)
  }

  getTrigger(): TriggerSource | undefined {
    return _.cloneDeep(this.trigger)
  }

  getRootTask(): TaskSource | undefined {
    return _.cloneDeep(this.task)
  }

  findParentTaskOf(taskId: string): TaskSource | undefined {
    if (this.task === undefined) {
      return undefined
    }
    if (this.task.taskId === taskId) {
      return undefined
    }
    let targetTaskId = taskId
    for (let i = 0; i < 1000; i++) {
      const found = findParentTask(this.task, targetTaskId)
      if (found === undefined) {
        return undefined
      }
      if (found.actionId !== EMPTY_ACTION_ID) {
        return _.cloneDeep(found)
      }
      targetTaskId = found.taskId
    }
    throw new Error("Couldn't find not-empty parent task within 1000 loops")
  }

  private createEmptyTask(taskId: string, children: TaskSource[]): TaskSource {
    return {
      taskId,
      actionId: EMPTY_ACTION_ID,
      inputs: [],
      tasks: children,
    }
  }
}

function findTask(
  rootTask: TaskSource,
  targetTaskId: string
): TaskSource | undefined {
  const stack: TaskSource[] = [rootTask]
  while (stack.length > 0) {
    const task = stack.pop()
    if (task === undefined) {
      continue
    }
    if (task.taskId === targetTaskId) {
      return _.cloneDeep(task)
    }
    stack.push(...task.tasks)
  }
  return undefined
}

function findParentTask(
  parentTask: TaskSource,
  targetTaskId: string
): TaskSource | undefined {
  const found = parentTask.tasks.find((task) => task.taskId === targetTaskId)
  if (found !== undefined) {
    return parentTask
  }
  for (let i = 0; i < parentTask.tasks.length; i++) {
    const childResult = findParentTask(parentTask.tasks[i], targetTaskId)
    if (childResult !== undefined) {
      return childResult
    }
  }
  return undefined
}

function removeTask(
  rootTask: TaskSource,
  targetTaskId: string
): TaskSource | undefined {
  if (rootTask.taskId === targetTaskId) {
    if (rootTask.tasks.length > 1) {
      throw new Error('複数のサブタスクを持つタスクは削除できません')
    }
    return rootTask.tasks[0]
  }
  return {
    ...rootTask,
    tasks: rootTask.tasks
      .map((task) => removeTask(task, targetTaskId))
      .filter<TaskSource>(
        (it?: TaskSource): it is TaskSource => it !== undefined
      ),
  }
}

function removeAllEmptyTasks(rootTask: TaskSource): TaskSource | undefined {
  if (rootTask.actionId === EMPTY_ACTION_ID) {
    if (rootTask.tasks.length > 1) {
      throw new Error('複数のサブタスクを持つタスクは削除できません')
    }
    if (rootTask.tasks.length === 1) {
      return removeAllEmptyTasks(rootTask.tasks[0])
    }
    return undefined
  }
  return {
    ...rootTask,
    tasks: rootTask.tasks
      .map((task) => removeAllEmptyTasks(task))
      .filter<TaskSource>(
        (it?: TaskSource): it is TaskSource => it !== undefined
      ),
  }
}

export interface InputSource {
  fieldKey: string
  value: InputValue
}

export interface TriggerSource {
  triggerId: string
  inputs: InputSource[]
}

export interface TaskSource {
  taskId: string
  actionId: string
  inputs: InputSource[]
  tasks: TaskSource[]
}

export function isTriggerSource(
  source: TriggerSource | TaskSource
): source is TriggerSource {
  return source.hasOwnProperty('triggerId')
}

export function isTaskSource(
  source: TriggerSource | TaskSource
): source is TaskSource {
  return source.hasOwnProperty('taskId')
}
