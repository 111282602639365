import { delay } from '~/common/utils'
import { devAction } from '~/data/workflow/action/ActionServiceImpl'
import { ActionDefinition } from '~/domain/workflow/action/ActionDefinition'
import { ActionService } from '~/domain/workflow/action/ActionService'
import { ActionState } from '~/domain/workflow/action/ActionState'
import { EMPTY_ACTION_ID } from '~/ducks/editor/types'

export class MockActionService implements ActionService {
  async get(actionId: string): Promise<ActionDefinition | undefined> {
    await delay(2000)
    return actions.find((action) => action.actionId === actionId)
  }

  async getAll(): Promise<ActionDefinition[]> {
    await delay(1000)
    return actions
  }
}

const spreadsheetReadAction: ActionDefinition = {
  actionId: 'GoogleSpreadsheetApp__Read',
  name: '読み込み',
  appId: 'GoogleSpreadsheetApp',
  description: 'Google スプレッドシートからセルを読み込みます',
  testable: true,
  state: ActionState.available,
  fields: [
    {
      key: 'account_uid',
      fieldType: 'static',
      label: 'スプレッドシートのアカウント',
      note: '',
      required: true,
      bulkable: false,
      renderable: false,
      dependencies: [],
      valueType: {
        typeName: 'string',
        nullable: false,
      },
      form: {
        formType: 'legacy_account',
        provider: 'google_spreadsheet',
      },
    },
    {
      key: 'spreadsheet_id',
      fieldType: 'static',
      label: 'スプレッドシート',
      note: '',
      required: true,
      bulkable: false,
      renderable: false,
      dependencies: [],
      valueType: {
        typeName: 'string',
        nullable: false,
      },
      form: {
        formType: 'text',
      },
    },
    {
      key: 'sheet_id',
      fieldType: 'static',
      label: 'シート',
      note: '',
      required: true,
      bulkable: false,
      renderable: true,
      dependencies: ['spreadsheet_id'],
      valueType: {
        typeName: 'number',
        canDecimal: false,
        nullable: false,
      },
      form: {
        formType: 'text',
      },
    },
    {
      key: 'range',
      fieldType: 'static',
      label: '範囲',
      note: '',
      required: true,
      bulkable: false,
      renderable: true,
      dependencies: ['spreadsheet_id', 'sheet_id'],
      valueType: {
        typeName: 'string',
        nullable: false,
      },
      form: {
        formType: 'text',
      },
    },
  ],
}

const slackPostMessageAction: ActionDefinition = {
  actionId: 'SlackApp__PostMessage',
  name: 'メッセージ送信',
  appId: 'SlackApp',
  description: 'Slack にメッセージを送信します',
  testable: true,
  state: ActionState.available,
  fields: [
    {
      fieldType: 'static',
      key: 'account_uid',
      label: 'Slackのアカウント',
      note: '',
      required: true,
      renderable: false,
      bulkable: false,
      dependencies: [],
      valueType: {
        typeName: 'string',
        nullable: false,
      },
      form: {
        formType: 'legacy_account',
        provider: 'slack',
      },
    },
    {
      fieldType: 'static',
      key: 'channels',
      label: 'チャンネル',
      note: '',
      required: true,
      renderable: true,
      bulkable: true,
      dependencies: [],
      valueType: {
        typeName: 'list',
        nullable: false,
        itemType: {
          typeName: 'list',
          nullable: false,
          itemType: {
            typeName: 'list',
            nullable: false,
            itemType: {
              typeName: 'string',
              nullable: false,
            },
          },
        },
      },
      form: {
        formType: 'list',
        itemForm: {
          formType: 'list',
          itemForm: {
            formType: 'list',
            itemForm: {
              formType: 'text',
            },
          },
        },
      },
    },
    {
      fieldType: 'static',
      key: 'texts',
      label: 'メッセージ',
      note: '',
      required: true,
      renderable: true,
      bulkable: true,
      dependencies: [],
      valueType: {
        typeName: 'string',
        nullable: false,
      },
      form: {
        formType: 'text',
      },
    },
  ],
}

const googleAdminAppCreateUser: ActionDefinition = {
  actionId: 'GoogleAdminApp__CreateUser',
  appId: 'GoogleAdminApp',
  description: '',
  fields: [],
  name: '',
  testable: true,
  state: ActionState.available,
}
const camCardAppGetCardList: ActionDefinition = {
  actionId: 'CamCardApp__GetCardList',
  appId: 'CamCardApp',
  description: '',
  fields: [],
  name: '',
  testable: true,
  state: ActionState.deleted,
}
const sensesAppCreateContact: ActionDefinition = {
  actionId: 'SensesApp__CreateContact',
  appId: 'SensesApp',
  description: '',
  fields: [],
  name: '',
  testable: true,
  state: ActionState.deleted,
}
const cloudSignAppCheckDocumentStatus: ActionDefinition = {
  actionId: 'CloudSignApp__CheckDocumentStatus',
  appId: 'CloudSignApp',
  description: '',
  fields: [],
  name: '',
  testable: true,
  state: ActionState.available,
}
const salesforceAppSearchRecords: ActionDefinition = {
  actionId: 'SalesforceApp__SearchRecords',
  appId: 'SalesforceApp',
  description: '',
  fields: [],
  name: '',
  testable: true,
  state: ActionState.available,
}
const genericAppRepeat: ActionDefinition = {
  actionId: 'GenericApp__Repeat',
  appId: 'GenericApp',
  description: '',
  fields: [],
  name: '',
  testable: true,
  state: ActionState.available,
}
const salesforceAppUpdateRecords: ActionDefinition = {
  actionId: 'SalesforceApp__UpdateRecords',
  appId: 'SalesforceApp',
  description: '',
  fields: [],
  name: '',
  testable: true,
  state: ActionState.available,
}
const smartHRAppGetCrews: ActionDefinition = {
  actionId: 'SmartHRApp__GetCrews',
  appId: 'SmartHRApp',
  description: '',
  fields: [],
  name: '',
  testable: true,
  state: ActionState.available,
}
const freeeHRAppCreateEmployee: ActionDefinition = {
  actionId: 'FreeeHRApp__CreateEmployee',
  appId: 'FreeeHRApp',
  description: '',
  fields: [],
  name: '',
  testable: true,
  state: ActionState.deprecated,
}
const kintoneAppCreateRecords: ActionDefinition = {
  actionId: 'KintoneApp__CreateRecords',
  appId: 'KintoneApp',
  description: '',
  fields: [],
  name: '',
  testable: true,
  state: ActionState.available,
}
const kintoneAppSearchRecords: ActionDefinition = {
  actionId: 'KintoneApp__SearchRecords',
  appId: 'KintoneApp',
  description: '',
  fields: [],
  name: '',
  testable: true,
  state: ActionState.available,
}
const freeeAppCreateInvoice: ActionDefinition = {
  actionId: 'FreeeApp__CreateInvoice',
  appId: 'FreeeApp',
  description: '',
  fields: [],
  name: '',
  testable: true,
  state: ActionState.available,
}
const googleCalendarAppGetCalendar: ActionDefinition = {
  actionId: 'GoogleCalendarApp__GetCalendar',
  appId: 'GoogleCalendarApp',
  description: '',
  fields: [],
  name: '',
  testable: true,
  state: ActionState.deprecated,
}

// これを削除するには WorkflowSource をそのまま Tree に渡して描画しているのをやめる必要がある
// 本来の WorkflowSource には EmptyAction という概念はないため。
const emptyAction: ActionDefinition = {
  actionId: EMPTY_ACTION_ID,
  appId: 'GenericApp',
  description: '',
  fields: [],
  name: '空',
  testable: false,
  state: ActionState.available,
}

const actions = [
  devAction,
  spreadsheetReadAction,
  slackPostMessageAction,
  emptyAction,
  googleAdminAppCreateUser,
  camCardAppGetCardList,
  sensesAppCreateContact,
  cloudSignAppCheckDocumentStatus,
  salesforceAppSearchRecords,
  genericAppRepeat,
  salesforceAppUpdateRecords,
  smartHRAppGetCrews,
  freeeHRAppCreateEmployee,
  kintoneAppCreateRecords,
  kintoneAppSearchRecords,
  freeeAppCreateInvoice,
  googleCalendarAppGetCalendar,
]
