import config from '~/common/config'
import { Tson } from '~/data/Tson'
import requestJson, {
  HttpError,
  requestDelete,
  requestPost,
} from '~/data/request'
import { mapJsonToInvitation } from '~/data/user/invitation/mapJsonToInvitation'
import { mapJsonToInvitationErrorResult } from '~/data/user/invitation/mapJsonToInvitationError'
import { mapToInvitationJson } from '~/data/user/invitation/mapToInvitationJson'
import { mapToInvitationsErrorJson } from '~/data/user/invitation/mapToInvitationsErrorJson'
import { Invitation } from '~/domain/user/Invitation'
import { InvitationResult } from '~/domain/user/InvitationResult'
import { InvitationService } from '~/domain/user/InvitationService'

export class InvitationServiceImpl implements InvitationService {
  async getInvitations(): Promise<Invitation[]> {
    const res = await requestJson(`${config.apiRoot}/user_invitations?all=true`)
    const json = new Tson(res).asArray().map(mapToInvitationJson)
    return json.map(mapJsonToInvitation)
  }

  async inviteUsers(emails: string[]): Promise<InvitationResult> {
    const body = emails.map((email) => {
      return { email }
    })
    try {
      await requestPost(`${config.apiRoot}/user_invitations`, body)
      return { isSuccess: true }
    } catch (error) {
      if (error instanceof HttpError) {
        const errorBody = await error.responseBody
        const json = mapToInvitationsErrorJson(new Tson(errorBody))
        return mapJsonToInvitationErrorResult(json)
      } else {
        throw error
      }
    }
  }

  async reInvite(id: string): Promise<void> {
    await requestPost(`${config.apiRoot}/user_invitations/${id}/resend`)
  }

  async cancel(id: string): Promise<void> {
    await requestDelete(`${config.apiRoot}/user_invitations/${id}`)
  }

  async verifyToken(token: string): Promise<boolean> {
    const body = {
      token,
    }
    try {
      await requestJson(`${config.apiRoot}/user_invitations/verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      return true
    } catch (error) {
      if (error instanceof HttpError) {
        const responseBody = await error.responseBody
        if (isInvitationTokenError(responseBody)) {
          return false
        }
      }
      throw error
    }
  }
}

function isInvitationTokenError(
  responseBody: any
): responseBody is { token: string[] } {
  return responseBody.token !== undefined
}
