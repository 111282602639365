import { combineReducers } from 'redux'

import * as connection from '~/ducks/connection'
import * as editor from '~/ducks/editor'
import * as message from '~/ducks/message'
import * as taskInstance from '~/ducks/taskInstance'
import * as ui from '~/ducks/ui'

export interface RootState {
  message: message.MessageState
  editor: editor.EditorState
  ui: ui.UiState
  connection: connection.ConnectionState
  taskInstance: taskInstance.TaskInstanceState
}

export default combineReducers<RootState>({
  message: message.default,
  editor: editor.default,
  ui: ui.default,
  connection: connection.default,
  taskInstance: taskInstance.default,
})
