import { LookupTableCellJson } from '~/data/lookupTable/LookupTableJson'
import { LookupTableCell } from '~/domain/lookupTable/LookupTableCell'

export function mapModelToLookupTableCellJson(
  lookupTableCell: LookupTableCell
): LookupTableCellJson {
  return {
    schemaId: lookupTableCell.columnSchemaId,
    value: lookupTableCell.value,
  }
}
