import { Tson } from '~/data/Tson'
import {
  ArgumentInfoJson,
  AttributeInfoJson,
  ObjectJson,
} from '~/data/workflow/object/json'
import { mapToValueTypeJson } from '~/data/workflow/valueType/mapToValueTypeJson'

export function mapToObjectJson(tson: Tson): ObjectJson {
  return {
    appId: tson.getString('appId'),
    description: tson.getString('description'),
    info: tson.getArray('info').map(mapToAttributeInfoJson),
    name: tson.getString('name'),
    objId: tson.getString('objId'),
  }
}

function mapToAttributeInfoJson(tson: Tson): AttributeInfoJson {
  return {
    argsInfo: tson.getArray('argsInfo').map(mapToArgumentInfoJson),
    description: tson.getString('description'),
    isProperty: tson.getBoolean('isProperty'),
    key: tson.getString('key'),
    name: tson.getString('name'),
    returnType: mapToValueTypeJson(tson.getValue('returnType')),
  }
}

function mapToArgumentInfoJson(tson: Tson): ArgumentInfoJson {
  return {
    description: tson.getString('description'),
    isVariableLength: tson.getBoolean('isVariableLength'),
    key: tson.getString('key'),
    name: tson.getString('name'),
    position: tson.getNumber('position'),
    required: tson.getBoolean('required'),
    valueType: mapToValueTypeJson(tson.getValue('valueType')),
  }
}
