import * as _ from 'lodash'
import React from 'react'

import { useListState } from '~/presentation/useListState'

type Option = {
  label: string
  value: string
}

type PagedOptions = {
  options: Option[]
  nextPageToken?: string
}

export function useDynamicSelectOptions(
  loadOptions: (query: string, pageToken?: string) => Promise<PagedOptions>,
  query: string
): { options: Option[]; loading: boolean; load: () => void } {
  const [needLoad, setNeedLoad] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const {
    values: options,
    addValues: addOptions,
    clearValues: clearOptions,
  } = useListState<Option>()
  // null はそれ以上ページがないということ
  const [nextPageToken, setNextPageToken] = React.useState<string | null>()

  const loadFunction = React.useRef(() => changeNeedLoad(true))

  const changeNeedLoad = React.useCallback((isNeedLoad: boolean) => {
    // needLoad が短い間に複数回更新されるとその分 loading も更新され、画面がちらついてしまうのでそれを防ぐ
    _.debounce(() => {
      setNeedLoad(isNeedLoad)
    }, 500)
  }, [])

  React.useEffect(() => {
    if (!needLoad) {
      setLoading(false)
      return
    }
    if (nextPageToken === null) {
      setLoading(false)
      return
    }
    let disposed = false
    setLoading(true)
    loadOptions(query, nextPageToken)
      .then((res) => {
        if (disposed) {
          return
        }
        addOptions(res.options)
        changeNeedLoad(false)
        if (res.nextPageToken === undefined) {
          setNextPageToken(null)
        } else {
          setNextPageToken(res.nextPageToken)
        }
      })
      .catch((e) => {
        if (disposed) {
          return
        }
        console.error(e)
        setLoading(false)
      })
    return () => {
      disposed = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOptions, query, nextPageToken, needLoad])

  React.useEffect(() => {
    clearOptions()
    setNextPageToken(undefined)
    setNeedLoad(true)
    // clearOptions は参照が不変
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  React.useEffect(() => {
    clearOptions()
    setNextPageToken(undefined)
    setNeedLoad(true)
    // clearOptions は参照が不変
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOptions])

  return {
    options,
    loading,
    load: loadFunction.current,
  }
}
