import { GroupMeta } from '~/domain/group/GroupSummary'
import { PermissionPolicy } from '~/domain/workflow/permission/PermissionPolicy'

interface Init {
  id: string
  policy: PermissionPolicy
  group: GroupMeta
  updatedAt: Date
}

export class Permission {
  public readonly id: string
  public readonly policy: PermissionPolicy
  public readonly group: GroupMeta
  public readonly updatedAt: Date

  constructor({ id, policy, group, updatedAt }: Init) {
    this.id = id
    this.policy = policy
    this.group = group
    this.updatedAt = updatedAt
  }
}
