import config from '~/common/config'
import { convertToURIComponent } from '~/common/utils'
import { Tson } from '~/data/Tson'
import requestJson from '~/data/request'
import { sanitize } from '~/data/utils'
import { ViewUnitJson } from '~/data/workflow/triggerInstruction/ViewUnitJson'
import { mapJsonToViewUnit } from '~/data/workflow/triggerInstruction/mapJsonToViewUnit'
import { mapToViewUnitJson } from '~/data/workflow/triggerInstruction/mapToViewUnitJson'
import {
  TriggerInstructionParameters,
  TriggerInstructionService,
} from '~/domain/workflow/triggerInstruction/TriggerInstructionService'
import { ViewUnitDefinition } from '~/domain/workflow/viewUnit/ViewUnitDefinition'

export class TriggerInstructionServiceImpl
  implements TriggerInstructionService {
  async getList(
    triggerInstructionId: string,
    params: TriggerInstructionParameters,
    workflowId: string
  ): Promise<ViewUnitDefinition[]> {
    const res = await requestJson(
      `${config.apiRoot}/trigger_instructions/${sanitize(
        triggerInstructionId
      )}?params=${convertToURIComponent(params)}&workflow_slug=${sanitize(
        workflowId
      )}`
    )
    const json = mapToGetListResponseJson(new Tson(res))
    return json.results.map(mapJsonToViewUnit)
  }
}

interface GetListResponseJson {
  results: ViewUnitJson[]
}

function mapToGetListResponseJson(tson: Tson): GetListResponseJson {
  return {
    results: tson.getArray('results').map(mapToViewUnitJson),
  }
}
