import * as React from 'react'

import { apiClients } from '~/common/apiClients'
import { ObjectDefinition } from '~/domain/workflow/object/ObjectDefinition'
import { ObjectService } from '~/domain/workflow/object/ObjectService'

const objectService: ObjectService = apiClients.objectService

export function useObjectDefinitions(): {
  objects?: ObjectDefinition[]
  fetching: boolean
  errorMessage?: string
} {
  const [objects, setObjects] = React.useState<ObjectDefinition[]>()
  const [fetching, setFetching] = React.useState<boolean>(true)
  const [errorMessage, setErrorMessage] = React.useState<string>()
  React.useEffect(() => {
    objectService
      .getAll()
      .finally(() => setFetching(false))
      .then((it) => setObjects(it))
      .catch((reason) => {
        console.error(`Failed to get objects: ${reason}`)
        setErrorMessage(reason)
      })
  }, [])
  return { objects, fetching, errorMessage }
}
