import styled from '@emotion/styled'
import * as React from 'react'

import { EmailValidationText, isSubmittableEmail } from '~/common/validateEmail'
import Button from '~/components/atoms/Button'
import CheckField from '~/components/atoms/CheckField'
import HiddenField from '~/components/atoms/HiddenField'
import MultilineTextField from '~/components/atoms/MultilineTextField'
import Text from '~/components/atoms/Text'
import TextField from '~/components/atoms/TextField'
import SelectField from '~/components/molecules/SelectField'
import Inner from '~/components/organisms/Inner'
import Modal from '~/components/organisms/Modal'
import { StaticTemplate } from '~/components/templates/StaticTemplate'
import SalesforceForm from '~/containers/Contact/SalesforceForm'
import { useRecaptcha } from '~/hooks/useRecaptcha'
import * as vars from '~/styles/variables'

const occupationOptions: readonly string[] = [
  '経営',
  'セールス',
  'マーケティング',
  'ディレクター',
  'カスタマーサポート',
  'カスタマーサクセス',
  'エンジニア',
  '情報システム',
  '経営企画',
  '人事',
  '経理/労務',
  'その他',
]

const positionOptions: readonly string[] = [
  '代表',
  '役員',
  'マネージャー',
  '一般',
]

interface Props {}

export const ContactForm: React.FC<Props> = () => {
  const [companyName, setCompanyName] = React.useState<string>('')
  const [occupation, setOccupation] = React.useState<string>('')
  const [position, setPosition] = React.useState<string>('')
  const [lastName, setLastName] = React.useState<string>('')
  const [firstName, setFirstName] = React.useState<string>('')
  const [mailAddress, setMailAddress] = React.useState<string>('')
  const [phoneNumber, setPhoneNumber] = React.useState<string>('')
  const [message, setMessage] = React.useState<string>('')
  const [agreed, setAgreed] = React.useState<boolean>(false)
  const [recaptchaElement, timestamp, recaptchaVerified] = useRecaptcha(
    '6Ldr8NAUAAAAALB3KYSpmQsoHk52NspJU_F3Q7UW'
  )
  const [errorModalIsOpened, setErrorModalIsOpened] = React.useState<boolean>(
    false
  )

  const submitEnabled =
    [
      companyName,
      occupation,
      position,
      lastName,
      firstName,
      mailAddress,
      phoneNumber,
    ].every((str) => str !== '') &&
    recaptchaVerified &&
    agreed &&
    isSubmittableEmail(mailAddress)

  return (
    <StaticTemplate>
      <_Modal
        isOpened={errorModalIsOpened}
        onClose={() => {
          setErrorModalIsOpened(false)
        }}
      >
        <Inner size="xs" responsive={true}>
          <Text element="h2" fontSize="l" fontWeight="bold">
            エラーが発生しました
          </Text>
          <ModalDescription
            element="p"
            fontSize="s"
            color={vars.fontColor.secondary}
          >
            エラーが発生し、お問い合わせ内容を正しく送信できませんでした。
            <br />
            時間をおいてから再度お試しください。それでも解決しない場合、お手数ですが
            <a href="https://anyflow.jp/contact" target="_blank">
              お問合せフォーム
            </a>
            よりお知らせください。
          </ModalDescription>
          <ModalFooter>
            <ModalButton
              type="tertiary"
              onClick={() => setErrorModalIsOpened(false)}
            >
              閉じる
            </ModalButton>
          </ModalFooter>
        </Inner>
      </_Modal>
      <_Inner size="s" responsive={true}>
        <Text
          element="h2"
          fontSize="xxl"
          fontWeight="bold"
          lineHeight="heading"
        >
          お問い合わせ入力フォーム
        </Text>
        <SalesforceForm
          timestamp={timestamp}
          callbackUrl="https://app.anyflow.jp/contact/thankyou"
          source="お問い合わせ"
          onError={() => setErrorModalIsOpened(true)}
        >
          <Row>
            <Label required={true} htmlFor="company">
              会社名
            </Label>
            <TextField
              id="company"
              maxLength={40}
              name="company"
              size={20}
              type="text"
              value={companyName}
              placeholder="例: Anyflow株式会社"
              onChange={(e) => setCompanyName(e.currentTarget.value)}
              required={true}
            />
          </Row>
          <Row>
            <Label required={true}>職種</Label>
            {/* SelectField は input 要素ではないので hidden フィールドを使って値を form にわたす */}
            <HiddenField name="00N2u000000K7IP" value={occupation} />
            <SelectField
              options={occupationOptions.map((it) => ({
                label: it,
                value: it,
              }))}
              unselectedOption={{ label: '選択してください', value: '' }}
              value={occupation}
              onChange={(newValue) => setOccupation(newValue)}
            />
          </Row>
          <Row>
            <Label required={true}>役職</Label>
            {/* SelectField は input 要素ではないので hidden フィールドを使って値を form にわたす */}
            <HiddenField name="00N2u000000KPVv" value={position} />
            <SelectField
              options={positionOptions.map((it) => ({
                label: it,
                value: it,
              }))}
              unselectedOption={{ label: '選択してください', value: '' }}
              value={position}
              onChange={(newValue) => setPosition(newValue)}
            />
          </Row>
          <Row>
            <NameContainer>
              <Name>
                <Label required={true} htmlFor="last_name">
                  姓
                </Label>
                <TextField
                  id="last_name"
                  maxLength={80}
                  name="last_name"
                  size={20}
                  type="text"
                  value={lastName}
                  placeholder="例: 田中"
                  onChange={(e) => setLastName(e.currentTarget.value)}
                  required={true}
                />
              </Name>
              <Name>
                <Label required={true} htmlFor="first_name">
                  名
                </Label>
                <TextField
                  id="first_name"
                  maxLength={40}
                  name="first_name"
                  size={20}
                  type="text"
                  value={firstName}
                  placeholder="例: 太郎"
                  onChange={(e) => setFirstName(e.currentTarget.value)}
                  required={true}
                />
              </Name>
            </NameContainer>
          </Row>
          <Row>
            <EmailLabelContainer>
              <Label required={true} htmlFor="email">
                メール
              </Label>
              <Annotation
                element="span"
                fontSize="xs"
                color={vars.fontColor.tertiary}
              >
                ※フリーアドレスはご利用できません
              </Annotation>
            </EmailLabelContainer>
            <TextField
              id="email"
              maxLength={80}
              name="email"
              size={20}
              type="text"
              value={mailAddress}
              placeholder="例: taro@anyflow.co.jp"
              onChange={(e) => setMailAddress(e.currentTarget.value)}
              required={true}
            />
            <EmailValidationText mailAddress={mailAddress} />
          </Row>
          <Row>
            <Label required={true} htmlFor="phone">
              電話
            </Label>
            <TextField
              id="phone"
              maxLength={40}
              name="phone"
              size={20}
              type="text"
              value={phoneNumber}
              placeholder="例: 03-0000-0000"
              onChange={(e) => setPhoneNumber(e.currentTarget.value)}
              required={true}
            />
          </Row>
          <Row>
            <Label required={false} htmlFor="00N2v00000UwDcv">
              ご質問・ご相談はこちらにご記入ください
            </Label>
            <MultilineTextField
              id="00N2v00000UwDcv"
              name="00N2v00000UwDcv"
              minRows={3}
              maxRows={20}
              wrap="soft"
              value={message}
              onChange={(e) => setMessage(e.currentTarget.value)}
              required={false}
            />
          </Row>
          <Row>
            <Text element="p" fontSize="s">
              <a href="https://anyflow.jp/privacy" target="_blank">
                個人情報の取り扱い
              </a>
              に同意しますか
            </Text>
            <CheckField
              id="00N2v00000UwDd0"
              name="00N2v00000UwDd0"
              label="同意する"
              value="同意する"
              title="個人情報の取り扱いへの同意"
              checked={agreed}
              onChange={(e) => setAgreed(e.currentTarget.checked)}
              required={true}
            />
          </Row>
          <Row>{recaptchaElement}</Row>
          <Row>
            <Button
              nativeType="submit"
              type="primary"
              disabled={!submitEnabled}
            >
              入力内容を送信
            </Button>
          </Row>
        </SalesforceForm>
      </_Inner>
    </StaticTemplate>
  )
}

const _Inner = styled(Inner)({
  marginTop: vars.space.m,
  paddingTop: vars.space.l,
  paddingRight: vars.space.xl,
  paddingBottom: vars.space.l,
  paddingLeft: vars.space.xl,
  boxShadow: vars.shadow.m,
  [vars.media.sp]: {
    paddingRight: vars.space.m,
    paddingLeft: vars.space.m,
  },
})

const Row = styled('div')({
  marginTop: vars.space.m,
})

const EmailLabelContainer = styled('div')({
  display: 'flex',
  alignItems: 'baseline',
})

const Annotation = styled(Text)({
  marginLeft: '16px',
})

const Label = styled('label')(
  {
    display: 'block',
    marginBottom: vars.space.s,
    fontSize: vars.fontSize.s,
  },
  (props: { required?: boolean }) =>
    props.required
      ? {
          fontWeight: vars.fontWeight.bold,
          '&::after': {
            content: '"*"',
            color: vars.color.error,
          },
        }
      : {
          fontWeight: vars.fontWeight.normal,
        }
)

const NameContainer = styled('div')({
  display: 'flex',
})

const Name = styled('div')({
  flexGrow: 1,
  '&:first-of-type': {
    marginRight: vars.space.l,
  },
})

const _Modal = styled(Modal)({
  padding: vars.space.l,
})

const ModalDescription = styled(Text)({
  marginTop: vars.space.m,
})

const ModalFooter = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: vars.space.m,
})

const ModalButton = styled(Button)({
  color: vars.fontColor.secondary,
})
