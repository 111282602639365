import styled from '@emotion/styled'
import * as React from 'react'
import { animated, useSpring } from 'react-spring'

import Toast from '~/components/atoms/Toast'
import { ToasterType } from '~/ducks/ui/types'
import { ToasterContext } from '~/presentation/ToasterContext'
import * as vars from '~/styles/variables'

const Toaster: React.FC = (props) => {
  const timer = React.useRef<number>()
  const [shown, setShown] = React.useState<boolean>(false)
  const [message, setMessage] = React.useState<string>('')
  const [type, setType] = React.useState<ToasterType>(ToasterType.success)

  const animationProps = useSpring({
    transform: shown ? 'translateY(0%)' : 'translateY(-100%)',
    opacity: shown ? 1 : 0,
  })

  const show = React.useCallback((duration: number) => {
    setShown(true)
    window.clearTimeout(timer.current)
    timer.current = window.setTimeout(() => {
      setShown(false)
    }, duration)
  }, [])

  const contextValue = React.useMemo<React.ContextType<typeof ToasterContext>>(
    () => ({
      showSuccess: (_message: string, duration: number = 2000) => {
        setMessage(_message)
        setType(ToasterType.success)
        show(duration)
      },
      showError: (_message: string, duration: number = 4000) => {
        setMessage(_message)
        setType(ToasterType.error)
        show(duration)
      },
    }),
    [show]
  )

  return (
    <>
      <ToasterContext.Provider value={contextValue}>
        {props.children}
      </ToasterContext.Provider>
      <ToastContainer style={animationProps}>
        <div style={{ transform: 'translateX(-50%)' }}>
          <Toast message={message} type={type} />
        </div>
      </ToastContainer>
    </>
  )
}

const ToastContainer = styled(animated.div)({
  position: 'fixed',
  top: 0,
  zIndex: vars.zIndex.toaster,
  left: '50%',
})

export default Toaster
