import * as _ from 'lodash'

import { assertNever } from '~/common/utils'

export type ValueType =
  | NumberValueType
  | StringValueType
  | BooleanValueType
  | FileValueType
  | ListValueType
  | StructValueType
  | DictValueType
  | AnyValueType
  | NeverValueType

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace ValueType {
  export function wrapInList(
    type: ValueType,
    nullable: boolean
  ): ListValueType {
    return {
      typeName: 'list',
      itemType: type,
      nullable,
    }
  }

  export function isPrimitive(
    type: ValueType
  ): type is NumberValueType | StringValueType | BooleanValueType {
    return (
      type.typeName === 'number' ||
      type.typeName === 'string' ||
      type.typeName === 'boolean'
    )
  }

  /**
   * JavaScript object を ValueType として扱えるかどうかを判定します。
   *
   * @deprecated 使用していないようなので、メンテナンスコストを下げるために削除します
   */
  export function check(): boolean {
    throw new Error('not implemented')
  }

  /**
   * test が List<definition> にマッチするかどうかを判定します。
   * definition を List で囲う際、 List の nullable は false になります。
   */
  export function matchBulk(
    definition: ValueType,
    test: ValueType,
    matcher: (definition: ValueType, test: ValueType) => boolean
  ): test is ListValueType {
    return matcher(wrapInList(definition, false), test)
  }

  /**
   * test が definition にマッチするかどうかを typeName を使用して判定します。
   * struct 型のチェックでは、 Entry の数もチェックします。
   * length や canDecimal などのメタデータの互換性は確認しませんが、 nullability はチェックします。
   */
  export function matchLoose(definition: ValueType, test: ValueType): boolean {
    if (!definition.nullable && test.nullable) {
      return false // null incompatible
    }
    // test が never の場合は never 以外の型には適合しない
    if (test.typeName === 'never') {
      // ただし、 never? の場合は definition の型を問わず全ての nullable に適合する
      // （never? は null とみなせる）
      if (test.nullable) {
        return definition.nullable
      } else {
        return definition.typeName === 'never'
      }
    }
    // test が any の場合は never 以外の全ての型に適合する
    if (test.typeName === 'any') {
      return definition.typeName !== 'never'
    }
    switch (definition.typeName) {
      case 'number':
        return test.typeName === 'number'
      case 'string':
        return test.typeName === 'string'
      case 'boolean':
        return test.typeName === 'boolean'
      case 'file':
        return test.typeName === 'file'
      case 'list':
        return (
          test.typeName === 'list' &&
          matchLoose(definition.itemType, test.itemType)
        )
      case 'struct': {
        if (
          test.typeName !== 'struct' ||
          test.entries.length !== definition.entries.length
        ) {
          return false
        }
        const entriesByKey = _.keyBy(definition.entries, (entry) => entry.key)
        return test.entries.every((entry) => {
          return matchLoose(entriesByKey[entry.key].valueType, entry.valueType)
        })
      }
      case 'dict':
        return (
          test.typeName === 'dict' && test.valueType === definition.valueType
        )
      case 'any':
        return true
      case 'never':
        // never には never しか代入できないが、そのケースは上で拾ってるためここでは常に false になる
        return false
      default:
        return assertNever(definition)
    }
  }

  /**
   * 2つの ValueType が完全に一致するかどうかをチェックします。
   */
  export function matchExact(first: ValueType, second: ValueType): boolean {
    return _.isEqual(first, second)
  }
}

interface BaseValueType {
  typeName: string
  nullable: boolean
}

export interface NumberValueType extends BaseValueType {
  typeName: 'number'
  canDecimal: boolean
}

export interface StringValueType extends BaseValueType {
  typeName: 'string'
}

export interface BooleanValueType extends BaseValueType {
  typeName: 'boolean'
}

export interface ListValueType extends BaseValueType {
  typeName: 'list'
  itemType: ValueType
  length?: number
}

export interface StructValueType extends BaseValueType {
  typeName: 'struct'
  entries: StructValueType.Entry[]
}

export namespace StructValueType {
  export interface Entry {
    key: string
    valueType: ValueType
  }
}

export interface DictValueType extends BaseValueType {
  typeName: 'dict'
  valueType: ValueType
}

export interface FileValueType extends BaseValueType {
  typeName: 'file'
}

export interface AnyValueType extends BaseValueType {
  typeName: 'any'
}

export interface NeverValueType extends BaseValueType {
  typeName: 'never'
}
