import { mapModelToObjectPropertyValueJson } from '~/data/workflow/expectedObject/mapModelToExpectedObjectJson'
import { ExpectedObjectSnapshotJson } from '~/data/workflow/test/json'
import { ExpectedObjectSnapshot } from '~/domain/workflow/test/TestResult'

export function mapModelToExpectedObjectSnapshotJson(
  expectedObjectSnapshot: ExpectedObjectSnapshot
): ExpectedObjectSnapshotJson {
  return {
    expectedObjectKey: expectedObjectSnapshot.expectedObjectKey.value,
    values: expectedObjectSnapshot.values.map(
      mapModelToObjectPropertyValueJson
    ),
  }
}
