import { InputValue } from '~/domain/workflow/source/InputValue'
import { RenderValueValidator } from '~/domain/workflow/source/RenderValueValidator'
import { ValidationResult } from '~/domain/workflow/validator/ValidationResult'

export class RenderWidgetValidator {
  constructor(
    private readonly renderValueValidator: RenderValueValidator,
    private readonly required: boolean
  ) {}

  validate(inputValue: InputValue.Render, useBulk: boolean): ValidationResult {
    if (inputValue.template.length === 0) {
      return this.required
        ? ValidationResult.invalid(new ValidationResult.Required())
        : ValidationResult.valid()
    }
    return this.renderValueValidator.validate(inputValue, useBulk)
  }
}
