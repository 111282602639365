import * as React from 'react'

import { apiClients } from '~/common/apiClients'
import { SubmitStatus } from '~/common/types'
import { ConnectionService } from '~/domain/connection/ConnectionService'

export type ExtraData = { [key: string]: string }

const service: ConnectionService = apiClients.connectionService

export const useCreateSocialAccount = (
  provider: string
): {
  createdAccountUid?: string
  submit: (token: string, extraData: ExtraData) => void
  submitStatus: SubmitStatus
} => {
  const [createdAccountUid, setCreatedAccountUid] = React.useState<string>()
  const [submitStatus, setSubmitStatus] = React.useState<SubmitStatus>(
    SubmitStatus.none
  )
  const submit = React.useCallback(
    (token: string, extraData: ExtraData) => {
      service
        .createSocialAccount(provider, token, extraData)
        .then((res) => {
          setCreatedAccountUid(res.uid)
          setSubmitStatus(SubmitStatus.submitted)
        })
        .catch(() => {
          setSubmitStatus(SubmitStatus.failed)
        })
    },
    [provider]
  )
  return { createdAccountUid, submit, submitStatus }
}
