import * as _ from 'lodash'

import { Entity } from '~/common/types'
import { ensure } from '~/common/utils'
import { TokenBasedProviderDefinition } from '~/domain/provider/tokenBased/TokenBasedProviderDefinition'
import { ActionDefinition } from '~/domain/workflow/action/ActionDefinition'
import { AppDefinition } from '~/domain/workflow/app/AppDefinition'
import { FunctionDefinition } from '~/domain/workflow/function/FunctionDefinition'
import { ObjectDefinition } from '~/domain/workflow/object/ObjectDefinition'
import { TriggerDefinition } from '~/domain/workflow/trigger/TriggerDefinition'

export class Definitions {
  constructor(
    public readonly triggersById: Entity<TriggerDefinition>,
    public readonly actionsById: Entity<ActionDefinition>,
    public readonly appsById: Entity<AppDefinition>,
    public readonly objectsById: Entity<ObjectDefinition>,
    public readonly functionsById: Entity<FunctionDefinition>,
    public readonly tokenBasedProvidersById: Entity<
      TokenBasedProviderDefinition
    >
  ) {}

  getTrigger(triggerId: string): TriggerDefinition {
    return ensure(
      this.triggersById[triggerId],
      `No such trigger ID: ${triggerId}`
    )
  }

  getAction(actionId: string): ActionDefinition {
    return ensure(this.actionsById[actionId], `No such action ID: ${actionId}`)
  }

  getApps(): AppDefinition[] {
    return Object.values(this.appsById)
  }

  getApp(appId: string): AppDefinition {
    return ensure(this.appsById[appId], `No such app ID: ${appId}`)
  }

  findApp(appId: string): AppDefinition | undefined {
    return _.find(this.appsById, (it) => it.appId === appId)
  }

  getAppByTriggerId(triggerId: string): AppDefinition {
    return this.getApp(this.getTrigger(triggerId).appId)
  }

  getAppByActionId(actionId: string): AppDefinition {
    return this.getApp(this.getAction(actionId).appId)
  }

  getObject(objectId: string): ObjectDefinition {
    return ensure(this.objectsById[objectId], `No such object ID: ${objectId}`)
  }

  findObject(objectId: string): ObjectDefinition | undefined {
    return _.find(this.objectsById, (it) => it.objectId === objectId)
  }

  getFunction(functionId: string): FunctionDefinition {
    return ensure(
      this.functionsById[functionId],
      `No such function ID: ${functionId}`
    )
  }

  findTokenBaseProvider(
    providerId: string
  ): TokenBasedProviderDefinition | undefined {
    return this.tokenBasedProvidersById[providerId]
  }
}
