import request from '~/api/request'
import config from '~/common/config'
import { Tson } from '~/data/Tson'
import { mapJsonToRecipe } from '~/data/recipe/mapJsonToRecipe'
import { mapToRecipeJson } from '~/data/recipe/mapToRecipeJson'
import requestJson from '~/data/request'
import { Recipe } from '~/domain/recipe/Recipe'
import { RecipeService } from '~/domain/recipe/RecipeService'

export class NetlifyRecipeService implements RecipeService {
  async get(recipeId: string): Promise<Recipe> {
    const res = await requestJson(
      `${config.recipeApiRoot}/recipes/${recipeId}.json`,
      { credentials: 'omit' }
    )
    const json = mapToRecipeJson(new Tson(res))
    return mapJsonToRecipe(json)
  }

  async getList(): Promise<Recipe[]> {
    const res = await fetchRecipes()
    const jsons = Object.values(res).map((it) => mapToRecipeJson(new Tson(it)))
    return Promise.all(jsons.map(async (it) => mapJsonToRecipe(it)))
  }
}

interface FetchRecipesResponse {
  [key: string]: unknown
}

function fetchRecipes(): Promise<FetchRecipesResponse> {
  return request<FetchRecipesResponse>(
    `${config.recipeApiRoot}/recipes/index.json`,
    {
      credentials: 'omit',
    }
  )
}
