import produce from 'immer'
import {
  call,
  cancel,
  cancelled,
  delay,
  fork,
  put,
  take,
  takeLatest,
} from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { actionCreatorFactory } from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { Pagination, PaginationMeta } from '~/common/Pagination'
import { apiClients } from '~/common/apiClients'
import { FetchStatus } from '~/common/types'
import { TaskInstanceService } from '~/domain/workflow/instance/taskInstance/TaskInstanceService'
import { TaskInstanceSummary } from '~/domain/workflow/instance/taskInstance/TaskInstanceSummary'
import { RootState } from '~/ducks'

const actionCreator = actionCreatorFactory('anyflow/taskInstance/list')

export const types = {
  RESET: 'RESET',
  FETCH: 'FETCH',
  REFRESH: 'REFRESH',
  START_SYNC: 'START_SYNC',
  STOP_SYNC: 'STOP_SYNC',
}

interface FetchRequest {
  workflowInstanceId: string
  page?: string
  onSuccess?: () => void
}

interface FetchReponse {
  taskInstances: TaskInstanceSummary[]
  pager: PaginationMeta
}

export const actions = {
  reset: actionCreator(types.RESET),
  fetch: actionCreator.async<FetchRequest, FetchReponse>(types.FETCH),
  refresh: actionCreator.async<FetchRequest, FetchReponse, {}>(types.REFRESH),
  startSync: actionCreator<{ workflowInstanceId: string }>(types.START_SYNC),
  stopSync: actionCreator(types.STOP_SYNC),
}

export const selectors = {
  getFetchStatus: createSelector(
    (state: RootState) => state.taskInstance.list.fetchStatus,
    (fetchStatus) => fetchStatus
  ),
  getTaskInstances: createSelector(
    (state: RootState) => state.taskInstance.list.taskInstances,
    (workflows) => workflows
  ),
  getPager: createSelector(
    (state: RootState) => state.taskInstance.list.pager,
    (pager) => pager
  ),
}

export type State = {
  fetchStatus: FetchStatus
  taskInstances: TaskInstanceSummary[]
  pager: PaginationMeta | null
}

const initialState: State = {
  fetchStatus: FetchStatus.none,
  taskInstances: [],
  pager: null,
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.reset, () => Object.assign({}, initialState))
  .case(actions.fetch.started, (state) =>
    produce(state, (draft) => {
      draft.fetchStatus = FetchStatus.loading
      draft.taskInstances = []
      draft.pager = null
    })
  )
  .case(actions.fetch.done, (state, payload) =>
    produce(state, (draft) => {
      draft.fetchStatus = FetchStatus.loaded
      draft.taskInstances = payload.result.taskInstances
      draft.pager = payload.result.pager
    })
  )
  .case(actions.fetch.failed, (state) =>
    produce(state, (draft) => {
      draft.fetchStatus = FetchStatus.failed
    })
  )
  .case(actions.refresh.done, (state, payload) =>
    produce(state, (draft) => {
      draft.taskInstances = payload.result.taskInstances
      draft.pager = payload.result.pager
    })
  )

function* polling(workflowInstanceId: string) {
  try {
    while (true) {
      yield put(actions.refresh.started({ workflowInstanceId }))
      yield delay(2500)
    }
  } catch (e) {
    yield put(actions.refresh.failed(e))
  } finally {
    // @ts-ignore TypeScript4.2 から`noImplicitAny` Errors が投げられるが redux-saga の対応が見込めないため当座は無視する
    if (yield cancelled()) {
      yield put(
        actions.refresh.failed({
          params: { workflowInstanceId },
          error: {},
        })
      )
    }
  }
}

const taskInstanceService: TaskInstanceService = apiClients.taskInstanceService

export const sagas = [
  takeLatest(actions.fetch.started, function* (action) {
    try {
      const { workflowInstanceId, page, onSuccess } = action.payload
      const payload: Pagination<TaskInstanceSummary> = yield call(
        taskInstanceService.getList,
        workflowInstanceId,
        page,
        // TODO
        50
      )
      yield put(
        actions.fetch.done({
          result: {
            taskInstances: payload.items,
            pager: payload.paginationMeta,
          },
          params: action.payload,
        })
      )
      if (onSuccess) {
        onSuccess()
      }
    } catch (e) {
      yield put(actions.fetch.failed(e))
    }
  }),
  takeLatest(actions.refresh.started, function* (action) {
    try {
      const { workflowInstanceId } = action.payload
      const payload: Pagination<TaskInstanceSummary> = yield call(
        taskInstanceService.getList,
        workflowInstanceId,
        1,
        // TODO
        50
      )
      yield put(
        actions.refresh.done({
          result: {
            taskInstances: payload.items,
            pager: payload.paginationMeta,
          },
          params: action.payload,
        })
      )
    } catch (e) {
      yield put(actions.refresh.failed(e))
    }
  }),
  takeLatest(actions.startSync, function* (action) {
    const { workflowInstanceId } = action.payload
    // @ts-ignore TypeScript4.2 から`noImplicitAny` Errors が投げられるが redux-saga の対応が見込めないため当座は無視する
    const pollingTask = yield fork(polling, workflowInstanceId)
    yield take(actions.stopSync)
    yield cancel(pollingTask)
  }),
]
