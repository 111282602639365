import * as React from 'react'

import { apiClients } from '~/common/apiClients'
import { InvitationResult } from '~/domain/user/InvitationResult'

const service = apiClients.invitationService

export function useInvite(): {
  inviting: boolean
  invite: (emails: string[]) => Promise<InvitationResult>
  reInvite: (invitationId: string) => Promise<void>
  isReInviting: (invitationId: string) => boolean
  cancelInvitation: (invitationId: string) => Promise<void>
  isCanceling: (invitationId: string) => boolean
} {
  const [inviting, setInviting] = React.useState<boolean>(false)
  const [reInvitingInvitationIds, setReInvitingInvitationIds] = React.useState<
    string[]
  >([])
  const [cancelingInvitationIds, setCancelingInvitationIds] = React.useState<
    string[]
  >([])

  const invite = React.useCallback(async (emails: string[]) => {
    setInviting(true)
    try {
      return await service.inviteUsers(emails)
    } finally {
      setInviting(false)
    }
  }, [])

  const reInvite = React.useCallback(async (invitationId: string) => {
    setReInvitingInvitationIds((prev) => [...prev, invitationId])
    try {
      return await service.reInvite(invitationId)
    } finally {
      setReInvitingInvitationIds((prev) => {
        return prev.filter((id) => id !== invitationId)
      })
    }
  }, [])

  const isReInviting = React.useCallback(
    (invitationId: string) => {
      return reInvitingInvitationIds.includes(invitationId)
    },
    [reInvitingInvitationIds]
  )

  const cancelInvitation = React.useCallback(async (invitationId: string) => {
    setCancelingInvitationIds((prev) => [...prev, invitationId])
    try {
      return await service.cancel(invitationId)
    } finally {
      setCancelingInvitationIds((prev) => {
        return prev.filter((id) => id !== invitationId)
      })
    }
  }, [])

  const isCanceling = React.useCallback(
    (invitationId: string) => {
      return cancelingInvitationIds.includes(invitationId)
    },
    [cancelingInvitationIds]
  )

  return {
    inviting,
    invite,
    reInvite,
    isReInviting,
    cancelInvitation,
    isCanceling,
  }
}
