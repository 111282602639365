import { Definitions } from '~/domain/Definitions'
import { ListValueType, ValueType } from '~/domain/ValueType'
import { ExpectedObjects } from '~/domain/workflow/expectedObject/ExpectedObjects'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { RenderableElementsTypeDeterminer } from '~/domain/workflow/source/RenderableElementsTypeDeterminer'

export class RenderInputValueTypeMatcher {
  private readonly typeDeterminer: RenderableElementsTypeDeterminer

  constructor(
    private readonly valueType: ValueType,
    definitions: Definitions,
    expectedObjects: ExpectedObjects
  ) {
    this.typeDeterminer = new RenderableElementsTypeDeterminer(
      definitions,
      expectedObjects
    )
  }

  getTypeMatches(
    inputValue: InputValue.Render,
    useBulk: boolean
  ): TypeMatchResult[] {
    const types = this.typeDeterminer.determine(inputValue.template)

    return types.map<TypeMatchResult>((type) => {
      if (
        useBulk &&
        ValueType.matchBulk(this.valueType, type, ValueType.matchLoose)
      ) {
        return { kind: 'bulk', bulkType: type }
      }
      if (ValueType.matchLoose(this.valueType, type)) {
        return { kind: 'match', type }
      }
      return { kind: 'mismatch' }
    })
  }
}

export type TypeMatchResult =
  | TypeMatchResult.Match
  | TypeMatchResult.BulkMatch
  | TypeMatchResult.Mismatch

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace TypeMatchResult {
  export interface Match {
    kind: 'match'
    type: ValueType
  }

  export interface BulkMatch {
    kind: 'bulk'
    bulkType: ListValueType
  }

  export interface Mismatch {
    kind: 'mismatch'
  }
}
