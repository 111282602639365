import * as Sentry from '@sentry/browser'
import * as dayjs from 'dayjs'
import * as React from 'react'

import { MyError } from '~/common/types'
import HiddenField from '~/components/atoms/HiddenField'

class ContactFormError extends MyError {}

interface Props {
  timestamp: string
  source: string
  callbackUrl: string
  onError: () => void
}

const SalesforceForm: React.FC<Props> = (props) => {
  const [date, setDate] = React.useState<string>('')
  React.useEffect(() => {
    const timer = setInterval(() => {
      setDate(dayjs().format('YYYY/MM/DD'))
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  const handleSubmit: (
    e: React.FormEvent<HTMLFormElement>
  ) => void = React.useCallback((e) => {
    // g-recaptcha-response の値が正しく存在しなかった場合、 Salesforce 側にもユーザー側にもエラーが表示されずに
    // お問い合わせしたつもりができていなかったという状況を引き起こす可能性があるので、ここで念の為確認しておく。
    const elem: HTMLTextAreaElement | null = document.getElementById(
      'g-recaptcha-response'
    ) as HTMLTextAreaElement
    if (!elem || !elem.value || elem.value.trim() === '') {
      e.preventDefault()
      Sentry.captureException(
        new ContactFormError('reCAPTCHA response was missing.')
      )
      props.onError()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form
      action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
      method="POST"
      onSubmit={handleSubmit}
    >
      <HiddenField
        name="captcha_settings"
        value={JSON.stringify({
          keyname: 'inquiry2',
          fallback: 'true',
          orgId: '00D2v000002JBke',
          ts: props.timestamp,
        })}
      />
      <HiddenField name="oid" value="00D2v000002JBke" />
      <HiddenField name="retURL" value={props.callbackUrl} />
      <HiddenField name="00N2v00000UwDd5" value={date} />
      <HiddenField name="lead_source" value={props.source} />
      {props.children}
    </form>
  )
}

export default SalesforceForm
