import { FunctionDefinition } from '~/domain/workflow/function/FunctionDefinition'
import { FunctionService } from '~/domain/workflow/function/FunctionService'

export class MockFunctionService implements FunctionService {
  async getAll(): Promise<FunctionDefinition[]> {
    return functions
  }
}

const functions: FunctionDefinition[] = [
  {
    id: 'create_list',
    name: '配列を作成',
    description: '配列を作成します',
    arguments: [
      {
        key: 'values',
        position: 1,
        name: '',
        description: '',
        required: true,
        isVariableLength: true,
        type: {
          typeName: 'string',
          nullable: false,
        },
      },
    ],
    returnType: {
      typeName: 'list',
      nullable: false,
      itemType: {
        typeName: 'string',
        nullable: false,
      },
    },
  },
]
