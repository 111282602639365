import * as Cookies from 'js-cookie'

import 'whatwg-fetch'
import { MyError } from '~/common/types'

export async function requestJson(
  url: string,
  config?: RequestInit
): Promise<unknown> {
  const _config: RequestInit = {
    method: 'GET',
    credentials: 'include',
    ...config,
  }
  return request(url, _config, false)
}

export async function requestPatch(
  url: string,
  body: any,
  contentType: string = 'application/json'
): Promise<unknown> {
  const encodedBody =
    contentType === 'application/json' && typeof body !== 'string'
      ? JSON.stringify(body)
      : body
  const config: RequestInit = {
    method: 'PATCH',
    headers: { 'Content-Type': contentType },
    body: encodedBody,
  }
  return request(url, config, true)
}

export async function requestPost(
  url: string,
  body?: any,
  contentType: string = 'application/json'
): Promise<unknown> {
  const encodedBody =
    contentType === 'application/json' && typeof body !== 'string'
      ? JSON.stringify(body)
      : body
  const config: RequestInit = {
    method: 'POST',
    headers: { 'Content-Type': contentType },
    body: encodedBody,
  }
  return request(url, config, true)
}

export async function requestDelete(url: string): Promise<unknown> {
  const config: RequestInit = {
    method: 'DELETE',
  }
  return request(url, config, true)
}

export async function requestPut(
  url: string,
  body: any,
  contentType: string = 'application/json'
): Promise<unknown> {
  const encodedBody =
    contentType === 'application/json' && typeof body !== 'string'
      ? JSON.stringify(body)
      : body
  const config: RequestInit = {
    method: 'PUT',
    headers: { 'Content-Type': contentType },
    body: encodedBody,
  }
  return request(url, config, true)
}

async function request(
  url: string,
  baseConfig: RequestInit,
  requireCsrfToken: boolean
): Promise<unknown> {
  const config: RequestInit = { credentials: 'include', ...baseConfig }
  if (requireCsrfToken) {
    const csrfToken = Cookies.get('csrftoken')
    if (csrfToken === undefined) {
      throw new NoCsrfTokenError('No CSRF token found on cookies')
    }
    if (config.headers !== undefined) {
      config.headers['X-CSRFToken'] = csrfToken
    } else {
      config.headers = {
        'X-CSRFToken': csrfToken,
      }
    }
  }
  return fetch(url, config).then((res) => {
    if (!res.ok) {
      const errorMessage = `HTTP Error (${res.status}): ${url}`
      if (res.headers.get('Content-Type') === 'application/json') {
        throw new HttpError(res.status, errorMessage, res.json())
      } else {
        throw new HttpError(res.status, errorMessage, res.text())
      }
    }
    if (res.headers.get('Content-Type') === 'application/json') {
      return res.json()
    }
    return res.text()
  })
}

export class HttpError extends MyError {
  constructor(
    public readonly statusCode: number,
    message?: string,
    public readonly responseBody?: Promise<unknown>
  ) {
    super(message)
  }
}

export class NoCsrfTokenError extends MyError {}

export default requestJson
