import styled from '@emotion/styled'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { connect } from 'react-redux'

import { RootState } from '~/ducks'
import { State as TipState, selectors } from '~/ducks/ui/tip'
import * as vars from '~/styles/variables'

interface StateProps {
  tip: TipState
}

interface OwnProps {
  className?: string
}

type Props = OwnProps & StateProps

const Tip: React.FC<Props> = ({ tip }) => {
  const target = document.getElementById('portal-root')
  if (!target) {
    return null
  }
  return ReactDOM.createPortal(
    <>
      {tip.isShown ? (
        <Container top={tip.top} left={tip.left}>
          {tip.message}
        </Container>
      ) : null}
    </>,
    target
  )
}

const Container = styled('div')(
  {
    position: 'fixed',
    paddingTop: vars.space.s,
    paddingRight: vars.space.m,
    paddingBottom: vars.space.s,
    paddingLeft: vars.space.m,
    color: vars.color.white,
    fontSize: vars.fontSize.xs,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    borderRadius: vars.borderRadius.s,
    boxShadow: vars.shadow.s,
    pointerEvents: 'none',
    zIndex: vars.zIndex.tip,
    '&::after': {
      position: 'absolute',
      top: -12,
      left: 9,
      borderWidth: 6,
      borderStyle: 'solid',
      borderTopColor: 'transparent',
      borderRightColor: 'transparent',
      borderBottomColor: 'rgba(0, 0, 0, 0.8)',
      borderLeftColor: 'transparent',
      content: '""',
    },
  },
  (props: { top: number; left: number }) => ({
    top: props.top,
    left: props.left,
  })
)

const mapStateToProps = (state: RootState) => ({
  tip: selectors.getState(state),
})

export default connect<StateProps>(mapStateToProps)(Tip)
