export type ValidationResult = ValidationResult.Valid | ValidationResult.Invalid

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace ValidationResult {
  export abstract class Cause {
    constructor(public readonly message?: string) {}
  }

  export class Required extends Cause {
    constructor() {
      super('必須入力項目です')
    }
  }

  export class BadFormat extends Cause {}

  // サブウィジェットでエラーがあった
  export class Propagated extends Cause {
    constructor(public readonly cause: Cause, message?: string) {
      super(message)
    }
  }

  // なんらかの原因で失敗
  export class Failed extends Cause {
    constructor(public readonly reason?: unknown, message?: string) {
      super(message)
    }
  }

  export interface Valid {
    valid: true
    bulkSize?: number | 'n'
  }

  export interface Invalid {
    valid: false
    cause: Cause
  }

  export function valid(bulkSize?: number | 'n'): Valid {
    return {
      valid: true,
      bulkSize,
    }
  }

  export function invalid(cause: Cause): Invalid {
    return {
      valid: false,
      cause,
    }
  }
}
