import * as React from 'react'

import { PaginationMeta } from '~/common/Pagination'
import { FetchStatus } from '~/common/types'
import { WorkflowServiceImpl } from '~/data/workflow/WorkflowServiceImpl'
import { Workflow } from '~/domain/workflow/Workflow'
import { WorkflowService } from '~/domain/workflow/WorkflowService'

const workflowService: WorkflowService = new WorkflowServiceImpl()

export function useWorkflows(
  pageNumber: number
): {
  workflows?: Workflow[]
  paginationMeta?: PaginationMeta
  refetch: () => void
  fetchStatus: FetchStatus
} {
  const [workflows, setWorkflows] = React.useState<Workflow[]>()
  const [paginationMeta, setPaginationMeta] = React.useState<PaginationMeta>()
  const [fetchStatus, setFetchStatus] = React.useState<FetchStatus>(
    FetchStatus.none
  )

  React.useEffect(() => {
    fetchWorkflows()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber])

  const fetchWorkflows = () => {
    setFetchStatus(FetchStatus.loading)
    workflowService
      .getList(pageNumber)
      .then((it) => {
        setFetchStatus(FetchStatus.loaded)
        setPaginationMeta({
          totalCount: it.paginationMeta.totalCount,
          currentPageNumber: it.paginationMeta.currentPageNumber,
          countPerPage: it.paginationMeta.countPerPage,
        })
        setWorkflows(it.items)
      })
      .catch((reason) => {
        setFetchStatus(FetchStatus.failed)
        console.error(`Failed to fetch workflows: ${reason}`)
      })
  }

  return { workflows, paginationMeta, refetch: fetchWorkflows, fetchStatus }
}
