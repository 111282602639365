import styled from '@emotion/styled'
import * as React from 'react'

import * as vars from '~/styles/variables'

interface OwnProps {
  label: string
}

type Props = OwnProps &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'>

const CheckField: React.FC<Props> = ({ label, ...props }) => (
  <Container>
    <label>
      <Input type="checkbox" {...props} />
      <CheckBoxLabel>{label}</CheckBoxLabel>
    </label>
  </Container>
)

const Container = styled('div')({
  position: 'relative',
  paddingTop: vars.space.xxs,
  paddingBottom: vars.space.xxs,
})

const Input = styled('input')({
  opacity: 0,
  position: 'absolute',
  '&:checked + span': {
    '&::after': {
      position: 'absolute',
      top: 5,
      left: 6,
      width: 5,
      height: 9,
      content: '""',
      cursor: 'pointer',
      transform: 'rotate(45deg)',
      borderStyle: 'none',
      borderRight: `2px solid ${vars.color.white}`,
      borderBottom: `2px solid ${vars.color.white}`,
      backgroundColor: 'transparent',
    },
    '&::before': {
      border: 'none',
      backgroundColor: vars.color.theme,
    },
  },
})

const CheckBoxLabel = styled('span')({
  position: 'relative',
  display: 'block',
  paddingLeft: 24,
  cursor: 'pointer',
  fontSize: vars.fontSize.s,
  '&::before': {
    position: 'absolute',
    top: 'calc(50% - (8px + 1px))',
    left: 0,
    width: 16,
    height: 16,
    content: '""',
    cursor: 'pointer',
    border: `1px solid ${vars.color.border}`,
    backgroundColor: 'transparent',
    borderRadius: vars.borderRadius.s,
  },
})

export default CheckField
