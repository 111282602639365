import * as React from 'react'

interface OwnProps {
  name: string
  value: string
}

type Props = OwnProps &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' & 'name' & 'value'>

const HiddenField: React.FC<Props> = (props) => {
  return (
    <input type="hidden" {...props} name={props.name} value={props.value} />
  )
}

export default HiddenField
