import React, { CSSProperties } from 'react'

import Text from '~/components/atoms/Text'
import * as vars from '~/styles/variables'

interface Props {
  children: React.ReactNode
  className?: string
  style?: CSSProperties
}

const ErrorMessage: React.FC<Props> = (props) => (
  <Text
    className={props.className}
    style={props.style}
    element="p"
    fontSize="s"
    color={vars.color.error}
    lineHeight="body"
  >
    {props.children}
  </Text>
)

export default ErrorMessage
