import styled from '@emotion/styled'
import dayjs from 'dayjs'
import React from 'react'
import * as Icon from 'react-feather'

import Text from '~/components/atoms/Text'
import { TestResult } from '~/domain/workflow/test/TestResult'
import * as vars from '~/styles/variables'

interface Props {
  result: TestResult | undefined
  onClick: () => void
}

const NodeTestStatus: React.FC<Props> = (props) => {
  if (props.result === undefined) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Circle style={{ border: `solid 2px ${vars.color.border}` }} />
        <Text element="p" color="secondary" fontSize="s">
          テストされていません
        </Text>
      </div>
    )
  }
  return (
    <Clickable onClick={props.onClick}>
      <Circle
        style={{
          backgroundColor: props.result.success
            ? vars.color.success
            : vars.color.error,
        }}
      >
        {props.result.success ? (
          <Icon.Check color={vars.color.white} size={12} strokeWidth={4} />
        ) : (
          <Icon.X color={vars.color.white} size={12} strokeWidth={4} />
        )}
      </Circle>
      <Text element="p" color="secondary" fontSize="s">
        {props.result.success
          ? `${dayjs(props.result.testedAt).fromNow()}にテスト済み`
          : 'テストに失敗しました'}
      </Text>
    </Clickable>
  )
}

const Clickable = styled('div')({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
})

const Circle = styled('div')({
  width: 20,
  height: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  marginRight: vars.space.s,
})

export default NodeTestStatus
