import { StandardTrigger } from '~/common/constants'
import { MessageType } from '~/common/types'
import { delay } from '~/common/utils'
import { ExpectedObjectKey } from '~/domain/workflow/object/ExpectedObjectKey'
import {
  ExpectedObjectSnapshot,
  TestResult,
} from '~/domain/workflow/test/TestResult'
import { TestService } from '~/domain/workflow/test/TestService'
import { TestTarget } from '~/domain/workflow/test/TestTarget'

export class MockTestService implements TestService {
  async test(target: TestTarget): Promise<TestResult> {
    console.log(`[API] test api called.\ntarget: ${JSON.stringify(target)}`)
    await delay(1500)
    const success = Math.random() > 0.5
    const snapshots =
      target.kind === 'trigger'
        ? mockSnapshots.triggers[target.triggerId]
        : mockSnapshots.actions[target.actionId]
    return {
      testedAt: new Date(),
      success,
      expectedObjects: [
        {
          key: ExpectedObjectKey.create('foo', '0000000'),
          name: '列',
          objectId: 'GoogleSpreadsheetApp__SpreadsheetColumnObject',
          sampleValues: [],
        },
      ],
      snapshots: snapshots ?? [],
      messages: success
        ? [{ type: MessageType.info, body: '成功したパターンのモックです。' }]
        : [
            { type: MessageType.error, body: '失敗したパターンのモックです。' },
            { type: MessageType.error, body: '失敗したパターンのモックです。' },
            { type: MessageType.error, body: '失敗したパターンのモックです。' },
          ],
    }
  }
}

export const mockSnapshots: {
  triggers: { [triggerId: string]: ExpectedObjectSnapshot[] }
  actions: { [actionId: string]: ExpectedObjectSnapshot[] }
} = {
  triggers: {
    [StandardTrigger]: [
      {
        expectedObjectKey: ExpectedObjectKey.create('runner'),
        values: [
          { propertyKey: 'username', value: 'Kawamura' },
          { propertyKey: 'email', value: 'kawamura@anyflow.co.jp' },
          { propertyKey: 'organization_name', value: 'Anyflow 株式会社' },
        ],
      },
    ],
  },
  actions: {},
}
