import { JsonError } from '~/data/JsonError'
import { Tson } from '~/data/Tson'
import {
  AnyValueTypeJson,
  BooleanValueTypeJson,
  DictValueTypeJson,
  FileValueTypeJson,
  ListValueTypeJson,
  NumberValueTypeJson,
  StringValueTypeJson,
  StructValueTypeJson,
  ValueTypeJson,
} from '~/data/workflow/valueType/ValueTypeJson'

export function mapToValueTypeJson(tson: Tson): ValueTypeJson {
  const typeName = tson.getString('typeName')
  switch (typeName) {
    case 'string':
      return mapToStringValueTypeJson(tson)
    case 'number':
      return mapToNumberValueTypeJson(tson)
    case 'boolean':
      return mapToBooleanValueTypeJson(tson)
    case 'file':
      return mapToFileValueTypeJson(tson)
    case 'list':
      return mapToListValueTypeJson(tson)
    case 'struct':
      return mapToStructValueTypeJson(tson)
    case 'dict':
      return mapToDictValueTypeJson(tson)
    case 'any':
      return mapToAnyValueTypeJson(tson)
    default:
      throw new JsonError(`Unknown type name: ${typeName}`)
  }
}

function mapToStringValueTypeJson(tson: Tson): StringValueTypeJson {
  return {
    typeName: 'string',
    nullable: tson.getBoolean('nullable'),
  }
}

function mapToNumberValueTypeJson(tson: Tson): NumberValueTypeJson {
  return {
    typeName: 'number',
    canDecimal: tson.getBoolean('canDecimal'),
    nullable: tson.getBoolean('nullable'),
  }
}

function mapToBooleanValueTypeJson(tson: Tson): BooleanValueTypeJson {
  return {
    typeName: 'boolean',
    nullable: tson.getBoolean('nullable'),
  }
}

function mapToFileValueTypeJson(tson: Tson): FileValueTypeJson {
  return {
    typeName: 'file',
    nullable: tson.getBoolean('nullable'),
  }
}

function mapToListValueTypeJson(tson: Tson): ListValueTypeJson {
  return {
    typeName: 'list',
    length: tson.getNumber('length', false),
    nullable: tson.getBoolean('nullable'),
    itemType: mapToValueTypeJson(tson.getValue('itemType')),
  }
}

function mapToStructValueTypeJson(tson: Tson): StructValueTypeJson {
  return {
    typeName: 'struct',
    nullable: tson.getBoolean('nullable'),
    entries: tson.getArray('entries').map(mapToStructEntryJson),
  }
}

function mapToStructEntryJson(tson: Tson): StructValueTypeJson.Entry {
  return {
    key: tson.getString('key'),
    valueType: mapToValueTypeJson(tson.getValue('valueType')),
  }
}

function mapToDictValueTypeJson(tson: Tson): DictValueTypeJson {
  return {
    typeName: 'dict',
    nullable: tson.getBoolean('nullable'),
    valueType: mapToValueTypeJson(tson.getValue('valueType')),
  }
}

function mapToAnyValueTypeJson(tson: Tson): AnyValueTypeJson {
  return {
    typeName: 'any',
    nullable: tson.getBoolean('nullable'),
  }
}
