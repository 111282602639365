import React from 'react'

import { PropertyCardProps } from '~/components/molecules/HoverCard/types'
import HoverCardTarget from '~/components/organisms/HoverCardTarget/HoverCardTarget'
import ObjectPill from '~/components/organisms/ObjectPill'
import { AppDefinition } from '~/domain/workflow/app/AppDefinition'
import { ExpectedObject } from '~/domain/workflow/expectedObject/ExpectedObjects'
import { ObjectMember } from '~/domain/workflow/object/ObjectDefinition'
import { useExpectedObjectSnapshots } from '~/presentation/workflow/detail/editor/test/ExpectedObjectSnapshotsContext'

interface Props {
  app: AppDefinition
  object: ExpectedObject
  member: ObjectMember.Property
  enabled: boolean
  onClick: (object: ExpectedObject, member: ObjectMember.Property) => void
}

const ObjectPropertyPill: React.FC<Props> = (props) => {
  const snapshots = useExpectedObjectSnapshots()
  const snapshotValue = React.useMemo(() => {
    return snapshots
      .find(props.object.key)
      ?.values.find((it) => it.propertyKey === props.member.key)?.value
  }, [snapshots, props.object, props.member])

  const cardProps = React.useMemo<PropertyCardProps>(
    () => ({
      type: 'property',
      app: props.app,
      property: props.member,
      expectedObjectName: props.object.name,
      sampleValue: props.object.sampleValues.find(
        (it) => it.propertyKey === props.member.key
      )?.value,
      snapshotValue: snapshotValue,
    }),
    [props.app, props.member, props.object, snapshotValue]
  )

  return (
    <HoverCardTarget card={cardProps} disabled={!props.enabled}>
      <ObjectPill
        color={props.app.color}
        iconPath={props.app.iconPath}
        disabled={!props.enabled}
        previewValue={snapshotValue}
        onClick={() => {
          if (!props.enabled) {
            return
          }
          props.onClick(props.object, props.member)
        }}
      >
        {props.object.name}の{props.member.name}
      </ObjectPill>
    </HoverCardTarget>
  )
}

export default ObjectPropertyPill
