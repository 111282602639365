import { JsonError } from '~/data/JsonError'
import { Tson } from '~/data/Tson'
import { ViewUnitJson } from '~/data/workflow/triggerInstruction/ViewUnitJson'

export function mapToViewUnitJson(tson: Tson): ViewUnitJson {
  const type = tson.getString('type')
  switch (type) {
    case 'copy':
      return {
        type,
        label: tson.getString('label'),
        value: tson.getString('value'),
      }
    default:
      throw new JsonError(`Unknown view unit: ${type}`)
  }
}
