import * as d3 from 'd3'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { AnimatedValue, ForwardedProps, useTransition } from 'react-spring'

import ActionNode, { actionNodeSize } from '~/components/molecules/ActionNode'
import EmptyNode, { emptyNodeSize } from '~/components/molecules/EmptyNode'
import NodeAddButton from '~/components/molecules/NodeAddButton'
import TriggerNode, {
  triggerNodeSize,
} from '~/components/molecules/TriggerNode'
import { EMPTY_ACTION_ID } from '~/ducks/editor/types'
import * as actionSelectorDuck from '~/ducks/ui/actionSelector'
import * as editorUiDuck from '~/ducks/ui/editor'
import * as triggerSelectorDuck from '~/ducks/ui/triggerSelector'
import { Node } from '~/hooks/useTree'
import { useWorkflowEditorContext } from '~/presentation/workflow/detail/editor/WorkflowEditorContext'

interface OwnProps {
  nodes: d3.HierarchyPointNode<Node>[]
  selectedTaskId?: string
  readonly: boolean
  onNodeClick: (nodeId: string, actionId: string) => void
  onTriggerNodeClick: (triggerId: string) => void
}

type Props = OwnProps

const Nodes: React.FC<Props> = ({
  nodes,
  selectedTaskId,
  readonly,
  onNodeClick,
  onTriggerNodeClick,
}) => {
  const dispatch = useDispatch()
  const {
    isEditorLoading,
    removeTask,
    addEmptyTask,
  } = useWorkflowEditorContext()

  /* animations */
  const nodeTransitions = useTransition(nodes, (item) => item.data.nodeId, {
    from: { opacity: 0, transform: 'scale(0.9)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0.9)' },
  })

  function handleNodeClick(nodeId: string, actionId: string) {
    onNodeClick(nodeId, actionId)
  }

  function handleTriggerNodeClick(triggerId: string) {
    onTriggerNodeClick(triggerId)
  }

  function showActionSelector(taskId: string) {
    const centerX = window.innerWidth / 2
    const centerY = window.innerHeight / 2

    dispatch(
      actionSelectorDuck.actions.show({
        taskId,
        top: centerY - actionNodeSize,
        left: centerX - actionNodeSize / 2,
      })
    )
  }

  function centering(e: React.MouseEvent) {
    // クリックしたノードが中央になるようにセンタリング
    const rect = e.currentTarget.getBoundingClientRect()
    const centerX = window.innerWidth / 2
    const centerY = window.innerHeight / 2

    const dx = centerX - (rect.left + actionNodeSize / 2)
    // -50は見た目の微調整
    const dy = centerY - (rect.top + actionNodeSize / 2) - 50

    dispatch(editorUiDuck.actions.addTx(dx))
    dispatch(editorUiDuck.actions.addTy(dy))
  }

  function handleChangeTriggerClick(e: React.MouseEvent) {
    // クリックしたノードが中央になるようにセンタリング
    const rect = e.currentTarget.getBoundingClientRect()
    const centerX = window.innerWidth / 2
    const centerY = window.innerHeight / 2

    const dx = centerX - (rect.left + triggerNodeSize / 2)
    // -50は見た目の微調整
    const dy = centerY - (rect.top + triggerNodeSize / 2) - 50

    dispatch(editorUiDuck.actions.addTx(dx))
    dispatch(editorUiDuck.actions.addTy(dy))

    // 表示
    dispatch(
      triggerSelectorDuck.actions.show({
        top: centerY - triggerNodeSize,
        left: centerX - triggerNodeSize / 2,
      })
    )
  }

  const renderTriggerNode = (
    node: d3.HierarchyPointNode<Node>,
    key: string
  ) => {
    if (node.data.type !== 'trigger') {
      return null
    }
    return (
      <React.Fragment key={key}>
        <g>
          <TriggerNode
            name={node.data.triggerDefinition.name}
            app={node.data.appDefinition}
            x={node.y}
            y={node.x}
            readonly={readonly}
            onClick={(event) => {
              if (node.data.type !== 'trigger') {
                return
              }
              handleTriggerNodeClick(node.data.data.triggerId)
              centering(event)
            }}
            onChangeTriggerClick={handleChangeTriggerClick}
          />
        </g>
      </React.Fragment>
    )
  }

  const renderEmptyNode = (
    node: d3.HierarchyPointNode<Node>,
    animatedValue: AnimatedValue<ForwardedProps<React.CSSProperties>>,
    key: string
  ) => {
    if (node.data.type !== 'task') {
      return null
    }
    const task = node.data.data
    /* グラフを横にするために、xとyが逆になっていることに注意 */
    return (
      <React.Fragment key={key}>
        <g>
          <EmptyNode
            task={task}
            x={node.y}
            y={node.x}
            animatedValue={animatedValue}
            isEditorLoading={isEditorLoading}
            disabled={readonly}
            onRemoveClick={() => {
              removeTask(task.taskId)
            }}
          />
        </g>
      </React.Fragment>
    )
  }

  const renderActionNode = (
    node: d3.HierarchyPointNode<Node>,
    animatedValue: AnimatedValue<ForwardedProps<React.CSSProperties>>,
    key: string
  ) => {
    if (node.data.type !== 'task') {
      return null
    }
    return (
      <React.Fragment key={key}>
        <g>
          <ActionNode
            name={node.data.actionDefinition.name}
            supportState={node.data.actionDefinition.state}
            app={node.data.appDefinition}
            x={node.y}
            y={node.x}
            selected={node.data.data.taskId === selectedTaskId}
            animatedValue={animatedValue}
            showSupportState={true}
            readonly={readonly}
            onClick={(event) => {
              if (node.data.type !== 'task') {
                return
              }
              handleNodeClick(node.data.data.taskId, node.data.data.actionId)
              centering(event)
            }}
            onRemoveClick={() => {
              if (node.data.type === 'trigger') {
                return
              }
              removeTask(node.data.data.taskId)
            }}
            onChangeActionClick={(event) => {
              if (node.data.type === 'trigger') {
                return
              }
              centering(event)
              showActionSelector(node.data.data.taskId)
            }}
          />
        </g>
        {/* 子供がいないとき、追加用のボタンを表示する */}
        {node.data.children.length === 0 ? (
          <NodeAddButton
            node={node}
            disabled={readonly}
            onClick={(e) => {
              if (node.data.type !== 'task') {
                return
              }
              if (!isEditorLoading) {
                // ロード中（削除等のときにtrueになる）でなければ追加可能
                const newTaskId = addEmptyTask(node.data.data.taskId)
                const centerX = window.innerWidth / 2
                const centerY = window.innerHeight / 2
                // センタリング
                const rect = e.currentTarget.getBoundingClientRect()
                const dx = centerX - (rect.left + emptyNodeSize)
                const dy = centerY - (rect.top + emptyNodeSize / 2)
                dispatch(editorUiDuck.actions.addTx(dx))
                dispatch(editorUiDuck.actions.addTy(dy))
                // ActionSelector 表示
                dispatch(
                  actionSelectorDuck.actions.show({
                    taskId: newTaskId,
                    top: centerY - emptyNodeSize,
                    left: centerX - emptyNodeSize / 2,
                  })
                )
              }
            }}
          />
        ) : null}
      </React.Fragment>
    )
  }

  return (
    <>
      {nodeTransitions.map(({ item: node, props, key }) => {
        // なぜかitemがundefinedになることがある
        if (!node) {
          return null
        }

        if (node.data.type === 'trigger') {
          return renderTriggerNode(node, key)
        }

        if (node.data.data.actionId === EMPTY_ACTION_ID) {
          return renderEmptyNode(node, props, key)
        }

        return renderActionNode(node, props, key)
      })}
    </>
  )
}

export default Nodes
