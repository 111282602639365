import { ActionJson } from '~/data/workflow/action/ActionJson'
import { mapToActionState } from '~/data/workflow/action/mapToActionState'
import { mapJsonToFieldDefinition } from '~/data/workflow/field/mapJsonToFieldDefinition'
import { ActionDefinition } from '~/domain/workflow/action/ActionDefinition'

export function mapJsonToActionDefinition(json: ActionJson): ActionDefinition {
  return {
    actionId: json.id,
    name: json.name,
    appId: json.appId,
    description: json.description,
    testable: json.isTestable,
    state: mapToActionState(json.state),
    fields: json.fields.map(mapJsonToFieldDefinition),
  }
}
