import React from 'react'

import { assert } from '~/common/utils'
import { Option } from '~/components/molecules/SelectField'
import { StringValueType, ValueType } from '~/domain/ValueType'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { InputWidgetDefinition } from '~/domain/workflow/widget/WidgetDefinition'
import { SalesforceSObject } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSObject'
import { SalesforceSearchCondition } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchCondition'
import {
  SalesforceOperandType,
  SalesforceSearchConditionOperator,
} from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchConditionOperator'
import { SalesforceSObjectField } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchConditionService'
import { SelectWidgetDefinition } from '~/domain/workflow/widget/select'
import { DependentFields } from '~/presentation/workflow/detail/editor/form/field/DependentField'
import { RawInputWidget } from '~/presentation/workflow/detail/editor/form/inputWidget/RawInputWidget'
import SelectWidget from '~/presentation/workflow/detail/editor/form/inputWidget/select/SelectWidget'
import * as vars from '~/styles/variables'

const STRING_VALUE_TYPE: StringValueType = {
  typeName: 'string',
  nullable: false,
}

const NOOP_BULK_SIZE_CHANGE = () => {}

const EMPTY_DEPENDENT_FIELDS: DependentFields = new DependentFields([])

interface Props {
  value: SalesforceSearchCondition
  onChange: (value: SalesforceSearchCondition) => void
  required: boolean
  readonly: boolean
  canUseStateOperators: boolean
  sObject: SalesforceSObject
}

const SalesforceSearchConditionComponent: React.FC<Props> = (props) => {
  const { onChange } = props

  const handleFieldChange = React.useCallback(
    (fieldName: string | undefined) => {
      if (fieldName === undefined) {
        onChange(props.value.setField(undefined))
        return
      }
      const field = props.sObject.findField(fieldName)
      assert(field !== undefined, `the field doesn't exist on the sObject`)
      onChange(props.value.setField(field))
    },
    [onChange, props.sObject, props.value]
  )

  const handleOperatorChange = React.useCallback(
    (operatorId: string | undefined) => {
      onChange(props.value.setOperator(operatorId))
    },
    [onChange, props.value]
  )

  const handleOperandChange = React.useCallback(
    (operand: InputValue) => {
      onChange(props.value.setOperand(operand))
    },
    [onChange, props.value]
  )

  return (
    <div>
      <div>
        <SObjectFieldSelectField
          value={props.value.field?.id}
          onChange={handleFieldChange}
          fields={props.sObject.fields}
          required={props.required}
          readonly={props.readonly}
        />
      </div>
      {props.value.operators.length > 0 && (
        <div style={{ marginTop: vars.space.s }}>
          <OperatorSelectField
            value={props.value.operator?.id}
            onChange={handleOperatorChange}
            operators={
              props.canUseStateOperators
                ? props.value.operators
                : props.value.operators.filter((it) => !it.isStateOperator)
            }
            required={props.required}
            readonly={props.readonly}
          />
        </div>
      )}
      {props.value.field !== undefined && (
        <div style={{ marginTop: vars.space.s }}>
          <OperandField
            value={props.value.operand}
            onChange={handleOperandChange}
            accountUid={props.sObject.accountUid}
            sObjectName={props.sObject.name}
            fieldName={props.value.field.id}
            operandType={props.value.operandType}
            required={props.required}
            readonly={props.readonly}
          />
        </div>
      )}
    </div>
  )
}

const SObjectFieldSelectField: React.FC<{
  value: string | undefined // field id
  onChange: (newValue: string | undefined) => void
  fields: SalesforceSObjectField[]
  required: boolean
  readonly: boolean
}> = (props) => {
  const options: Option<string>[] = React.useMemo(
    () =>
      props.fields.map((it) => ({
        label: it.label,
        value: it.id,
      })),
    [props.fields]
  )
  const definition: SelectWidgetDefinition = React.useMemo(
    () => ({
      formType: 'select',
      options,
    }),
    [options]
  )
  const value: InputValue.Raw | undefined = React.useMemo(
    () =>
      props.value === undefined ? undefined : { mode: 'raw', raw: props.value },
    [props.value]
  )
  const handleChange = React.useCallback(
    (newValue: InputValue.Raw | undefined) => {
      if (newValue === undefined) {
        props.onChange(undefined)
        return
      }
      assert(typeof newValue.raw === 'string', 'string required')
      props.onChange(newValue.raw)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChange]
  )
  return (
    <SelectWidget
      definition={definition}
      value={value}
      onChange={handleChange}
      valueType={STRING_VALUE_TYPE}
      bulkMode={false}
      bulkable={false}
      required={props.required}
      renderable={false}
      readonly={props.readonly}
      dependentFields={EMPTY_DEPENDENT_FIELDS}
      onBulkSizeChange={NOOP_BULK_SIZE_CHANGE}
    />
  )
}

const OperatorSelectField: React.FC<{
  value: string | undefined // operator id
  onChange: (newValue: string | undefined) => void
  operators: SalesforceSearchConditionOperator[]
  required: boolean
  readonly: boolean
}> = (props) => {
  const options: Option<string>[] = React.useMemo(
    () =>
      props.operators.map((it) => ({
        label: it.label,
        value: it.id,
      })),
    [props.operators]
  )
  const definition: SelectWidgetDefinition = React.useMemo(
    () => ({
      formType: 'select',
      options,
    }),
    [options]
  )
  const value: InputValue.Raw | undefined = React.useMemo(
    () =>
      props.value === undefined ? undefined : { mode: 'raw', raw: props.value },
    [props.value]
  )
  const handleChange = React.useCallback(
    (newValue: InputValue.Raw | undefined) => {
      if (newValue === undefined) {
        props.onChange(undefined)
        return
      }
      assert(typeof newValue.raw === 'string', 'string required')
      props.onChange(newValue.raw)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChange]
  )
  return (
    <SelectWidget
      definition={definition}
      value={value}
      onChange={handleChange}
      valueType={STRING_VALUE_TYPE}
      bulkMode={false}
      bulkable={false}
      required={props.required}
      renderable={false}
      readonly={props.readonly}
      dependentFields={EMPTY_DEPENDENT_FIELDS}
      onBulkSizeChange={NOOP_BULK_SIZE_CHANGE}
    />
  )
}

const OperandField: React.FC<{
  value: InputValue
  onChange: (newValue: InputValue) => void
  operandType: SalesforceOperandType
  accountUid: string
  sObjectName: string
  fieldName: string
  required: boolean
  readonly: boolean
}> = (props) => {
  const dependentFields: DependentFields = React.useMemo(
    () =>
      new DependentFields([
        {
          fieldKey: 'account_uid',
          value: { mode: 'raw', raw: props.accountUid },
          required: true,
        },
        {
          fieldKey: 'sobject_name',
          value: { mode: 'raw', raw: props.sObjectName },
          required: true,
        },
        {
          fieldKey: 'field_name',
          value: { mode: 'raw', raw: props.fieldName },
          required: true,
        },
      ]),
    [props.accountUid, props.sObjectName, props.fieldName]
  )

  const definition:
    | [InputWidgetDefinition, ValueType]
    | undefined = React.useMemo(() => {
    if (props.operandType.name === 'none') {
      return undefined
    }
    return [
      props.operandType.toWidgetDefinition(),
      props.operandType.toValueType(),
    ]
  }, [props.operandType])

  if (props.value !== undefined && props.value?.mode === 'render') {
    throw new Error(`operand field doesn't support render value`)
  }

  if (definition === undefined) {
    return null
  }

  return (
    <RawInputWidget
      definition={definition[0]}
      required={true}
      onChange={props.onChange}
      value={props.value}
      valueType={definition[1]}
      renderable={false}
      bulkable={false}
      bulkMode={false}
      readonly={props.readonly}
      dependentFields={dependentFields}
      onBulkSizeChange={NOOP_BULK_SIZE_CHANGE}
    />
  )
}

export default SalesforceSearchConditionComponent
