import styled from '@emotion/styled'
import * as React from 'react'

import { SubmitStatus } from '~/common/types'
import Button from '~/components/atoms/Button'
import Text from '~/components/atoms/Text'
import { TokenBasedProviderDefinition } from '~/domain/provider/tokenBased/TokenBasedProviderDefinition'
import { CompositeValidator } from '~/domain/workflow/validator/CompositeValidator'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import { useMapState } from '~/presentation/useMapState'
import AccountFormField from '~/presentation/workflow/detail/editor/form/inputWidget/account/AccountFormField'
import {
  ExtraData,
  useCreateSocialAccount,
} from '~/presentation/workflow/detail/editor/form/inputWidget/account/useCreateSocialAccount'
import { ValidationContext } from '~/presentation/workflow/detail/editor/form/validation/ValidationContext'
import * as vars from '~/styles/variables'

interface Props {
  provider: string
  onCancel: () => void
  onAccountCreated: (createdAccountUid: string) => void
}

const AccountForm: React.FC<Props> = ({
  provider,
  onCancel,
  onAccountCreated,
}) => {
  const definitions = useDefinitions()
  const [token, setToken] = React.useState<string>()
  const [errorMessage, setErrorMessage] = React.useState<string>()
  const [validating, setValidating] = React.useState<boolean>(false)
  const [extraData, putExtraData] = useMapState<string | undefined>()
  const { createdAccountUid, submit, submitStatus } = useCreateSocialAccount(
    provider
  )
  const validator = React.useRef<CompositeValidator>(new CompositeValidator())

  // SubmitStatus が変わった時に
  React.useEffect(() => {
    switch (submitStatus) {
      case SubmitStatus.submitting:
        setErrorMessage(undefined)
        break
      case SubmitStatus.submitted:
        if (createdAccountUid !== undefined) {
          onAccountCreated(createdAccountUid)
        }
        break
      case SubmitStatus.failed:
        setErrorMessage('アカウントの作成に失敗しました')
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitStatus])

  const handleSubmitClick = React.useCallback(async () => {
    setValidating(true)
    const validationResults = await validator.current.validate()
    setValidating(false)
    if (validationResults.some((it) => !it.valid)) {
      return
    }
    if (token === undefined) {
      return
    }
    const data: ExtraData = {}
    Object.keys(extraData).forEach((key) => {
      const value = extraData[key]
      if (value !== undefined) {
        data[key] = value
      }
    })
    submit(token, data)
  }, [submit, token, extraData])

  const definition = React.useMemo<
    TokenBasedProviderDefinition | undefined
  >(() => {
    return definitions.findTokenBaseProvider(provider)
  }, [definitions, provider])

  if (definition === undefined) {
    return <p>プロバイダが見つかりません</p>
  }

  return (
    <ValidationContext.Provider value={{ validator: validator.current }}>
      <Heading element="h2" fontSize="l" fontWeight="bold">
        アカウントの登録
      </Heading>
      <AccountFormField
        definition={{
          form: { formType: 'text' },
          key: 'token',
          label: definition.token.label,
          note: definition.token.note,
          required: true,
        }}
        value={token}
        onChange={(value) => setToken(value)}
      />
      {definition.extraDataFields.map((field) => (
        <FieldContainer key={field.key}>
          <AccountFormField
            definition={field}
            value={extraData[field.key]}
            onChange={(newValue) => putExtraData(field.key, newValue)}
          />
        </FieldContainer>
      ))}
      <ButtonContainer>
        <Button nativeType="button" type="tertiary" onClick={onCancel}>
          キャンセル
        </Button>
        <_Button
          nativeType="button"
          loading={submitStatus === SubmitStatus.submitting || validating}
          onClick={handleSubmitClick}
        >
          登録
        </_Button>
      </ButtonContainer>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </ValidationContext.Provider>
  )
}

const Heading = styled(Text)({
  marginBottom: vars.space.m,
})

const FieldContainer = styled('div')({
  marginTop: vars.space.l,
  '&:first-of-type': {
    marginTop: 0,
  },
})

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: vars.space.l,
})

const _Button = styled(Button)({
  marginLeft: vars.space.s,
})

export default AccountForm
