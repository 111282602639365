import { Tson } from '~/data/Tson'
import { UserJson } from '~/data/user/UserJson'
import { mapToOrganizationJson } from '~/data/user/organization/mapToOrganizationJson'

export function mapToUserJson(tson: Tson): UserJson {
  return {
    slug: tson.getString('slug'),
    username: tson.getString('username'),
    organization: mapToOrganizationJson(tson.getValue('organization')),
    isAdmin: tson.getBoolean('isAdmin'),
    email: tson.getString('email', false),
  }
}
