import _ from 'lodash'
import React from 'react'
import { useParams } from 'react-router'

import * as defaultInput from '~/common/defaultInput'
import { PendingResult } from '~/common/types'
import Loader from '~/components/atoms/Loader'
import Text from '~/components/atoms/Text'
import { Recipe } from '~/domain/recipe/Recipe'
import { useRecipe } from '~/hooks/useRecipe'
import { useMe } from '~/presentation/AnyflowAppContext'
import RecipeWizardComponent from '~/presentation/recipe/create/RecipeWizardComponent'
import { RecipeDetailParams } from '~/routes'
import * as vars from '~/styles/variables'

const RecipeWizardPage: React.FC = () => {
  const params = useParams<RecipeDetailParams>()
  const recipeResult = useRecipe(params.recipeId)
  const me = useMe()

  // Intercom のボタンを非表示にする
  React.useEffect(() => {
    window.Intercom &&
      window.Intercom('update', {
        hide_default_launcher: true,
      })
    return () => {
      window.Intercom &&
        window.Intercom('update', {
          hide_default_launcher: false,
        })
    }
  }, [])

  if (recipeResult.status === PendingResult.Status.pending) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader />
        <Text element="p" style={{ marginTop: vars.space.s }}>
          レシピを読み込んでいます...
        </Text>
      </div>
    )
  }

  if (recipeResult.status === PendingResult.Status.failure) {
    return <p>{recipeResult.message || 'レシピの読み込みに失敗しました'}</p>
  }

  // デフォルト入力値をレシピに設定する
  let recipe = recipeResult.data
  const recipeTrigger = recipe.source.getTrigger()
  if (recipeTrigger !== undefined) {
    // レシピに定義されている入力値を優先する
    const newTriggerInputs = _.unionBy(
      recipeTrigger.inputs,
      defaultInput.getDefaultTriggerInputs(recipeTrigger.triggerId, me.id),
      (it) => it.fieldKey
    )
    const newSourceBody = recipe.source.setTriggerInputs(newTriggerInputs)
    recipe = new Recipe(
      recipe.id,
      recipe.name,
      recipe.description,
      recipe.categories,
      newSourceBody
    )
  }
  return <RecipeWizardComponent recipe={recipe} />
}

export default RecipeWizardPage
