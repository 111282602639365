import styled from '@emotion/styled'
import * as React from 'react'

import ErrorMessage from '~/components/atoms/ErrorMessage'
import NumberField from '~/components/atoms/NumberField'
import { NumberWidgetDefinition } from '~/domain/workflow/widget/number'
import { useFollowState } from '~/hooks/useFollowState'
import InputContainer from '~/presentation/workflow/detail/editor/form/InputContainer'
import { RawInputWidgetProps } from '~/presentation/workflow/detail/editor/form/inputWidget/RawInputWidget'
import WidgetTypeSign from '~/presentation/workflow/detail/editor/form/inputWidget/WidgetTypeSign'
import { useInputWidgetValidation } from '~/presentation/workflow/detail/editor/form/validation/useValidation'
import * as vars from '~/styles/variables'
import { borderWidth } from '~/styles/widget'

interface Props extends RawInputWidgetProps {
  definition: NumberWidgetDefinition
}

const NumberWidget: React.FC<Props> = (props) => {
  const value: string = React.useMemo(() => {
    if (props.value === undefined) {
      return ''
    }
    if (typeof props.value.raw === 'string') {
      const v = Number.parseFloat(props.value.raw)
      if (!Number.isFinite(v)) {
        return ''
      }
      return v.toString()
    }
    if (typeof props.value.raw !== 'number') {
      return ''
    }
    return props.value.raw.toString()
  }, [props.value])

  // バリデーション
  const validationResult = useInputWidgetValidation(props)

  const [tempValue, setTempValue] = useFollowState(value)

  const handleValueChange = (newValue: string) => {
    setTempValue(newValue)
  }

  const handleBlur = () => {
    if (tempValue === '') {
      props.onChange(undefined)
      return
    }
    const numberValue = Number(tempValue)
    if (Number.isNaN(numberValue)) {
      // 数値に変換できなければ無視
      return
    }
    props.onChange({
      mode: 'raw',
      raw: numberValue,
    })
  }

  return (
    <>
      <InputContainer
        hasError={validationResult?.valid === false}
        renderable={props.renderable}
        disabled={props.readonly}
      >
        <_WidgetTypeSign valueType={props.valueType} />
        <_NumberField
          value={tempValue}
          min={props.definition.minValue}
          max={props.definition.maxValue}
          step={props.definition.step ?? 'any'}
          hasBorder={false}
          disabled={props.readonly}
          onChange={(e) => handleValueChange(e.currentTarget.value)}
          onBlur={handleBlur}
        />
      </InputContainer>
      {validationResult?.valid === false && (
        <ErrorMessage style={{ marginTop: vars.space.s }}>
          {validationResult.cause.message}
        </ErrorMessage>
      )}
    </>
  )
}

const _WidgetTypeSign = styled(WidgetTypeSign)({
  flexShrink: 0,
})

const _NumberField = styled(NumberField)({
  height: vars.height.field - borderWidth * 2,
})

export default NumberWidget
