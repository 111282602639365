import { Tson } from '~/data/Tson'
import { mapToUserJson } from '~/data/user/mapToUserJson'
import { WorkflowSourceJson } from '~/data/workflow/source/json'
import { mapToWorkflowSourceBodyJson } from '~/data/workflow/source/mapToWorkflowSourceBodyJson'

export function mapToWorkflowSourceJson(tson: Tson): WorkflowSourceJson {
  return {
    body: mapToWorkflowSourceBodyJson(tson.getValue('body')),
    editor: mapToUserJson(tson.getValue('editor')),
    formatVersion: tson.getString('formatVersion'),
    name: tson.getString('name'),
    prevVersion: null,
    slug: tson.getString('slug'),
  }
}
