import config from '~/common/config'
import { requestPatch } from '~/data/request'
import { mapModelToWorkflowSourceBodyJson } from '~/data/workflow/source/mapModelToWorkflowSourceBodyJson'
import { WorkflowSource } from '~/domain/workflow/source/WorkflowSource'
import { WorkflowSourceService } from '~/domain/workflow/source/WorkflowSourceService'

export class WorkflowSourceServiceImpl implements WorkflowSourceService {
  async update(workflowSource: WorkflowSource): Promise<void> {
    const requestBody = {
      body: mapModelToWorkflowSourceBodyJson(workflowSource.body),
    }
    await requestPatch(
      `${config.apiRoot}/sources/${workflowSource.sourceId}`,
      requestBody
    )
  }
}
