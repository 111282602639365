import styled from '@emotion/styled'
import React from 'react'

import Text from '~/components/atoms/Text'
import { ValueCardProps } from '~/components/molecules/HoverCard/types'
import WithRightTypeSign from '~/components/molecules/WithTypeSign/WithRightTypeSign'
import { scrollbarStyle } from '~/styles/scrollbar'
import * as vars from '~/styles/variables'

const ValueHoverCard: React.FC<ValueCardProps> = (props) => {
  return (
    <Container>
      <WithRightTypeSign valueType={props.valueType}>
        <Text element="p" fontSize="xs" fontWeight="bold" lineHeight="heading">
          {props.name}
        </Text>
      </WithRightTypeSign>
      {props.description !== '' && (
        <Text element="p" fontSize="xs" style={{ marginTop: vars.space.m }}>
          {props.description}
        </Text>
      )}
    </Container>
  )
}

const Container = styled('div')({
  padding: vars.space.m,
  backgroundColor: vars.color.white,
  border: `1px solid ${vars.color.border}`,
  borderRadius: vars.borderRadius.m,
  boxShadow: vars.shadow.m,
  maxWidth: 400,
  minWidth: 300,
  maxHeight: 300,
  overflowY: 'scroll',
  ...scrollbarStyle,
})

export default ValueHoverCard
