import { Tson } from '~/data/Tson'
import { mapToFieldJson } from '~/data/workflow/field/mapToFieldJson'
import { TriggerJson } from '~/data/workflow/trigger/TriggerJson'

export function mapToTriggerJson(tson: Tson): TriggerJson {
  return {
    id: tson.getString('id'),
    name: tson.getString('name'),
    appId: tson.getString('appId'),
    description: tson.getString('description'),
    fields: tson.getArray('fields').map(mapToFieldJson),
  }
}
