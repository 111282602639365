import styled from '@emotion/styled'
import React, { CSSProperties } from 'react'

import * as vars from '~/styles/variables'

interface Props {
  disabled?: boolean
  style?: CSSProperties
}

const CtaButton: React.FC<Props> = (props) => {
  return (
    <Container style={props.style} disabled={props.disabled}>
      {props.children}
    </Container>
  )
}

const Container = styled('div')(
  {
    display: 'inline-block',
    paddingTop: vars.space.s * 1.5,
    paddingRight: vars.space.m * 1.5,
    paddingBottom: vars.space.s * 1.5,
    paddingLeft: vars.space.m * 1.5,
    fontSize: vars.fontSize.m,
    fontWeight: vars.fontWeight.bold,
    textAlign: 'center',
    textDecoration: 'none',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
    borderRadius: vars.borderRadius.s,
    border: 'none',
    transition: 'all .14s ease',
  },
  (props: { disabled?: boolean }) => ({
    color: vars.color.white,
    backgroundColor: vars.color.theme,
    opacity: props.disabled ? 0.7 : 1,
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    ...(props.disabled
      ? {}
      : {
          '&:hover': {
            opacity: 0.9,
            boxShadow: '0 7px 14px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.08)',
            transform: 'translateY(-2px)',
          },
        }),
  })
)

export default CtaButton
