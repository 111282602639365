import * as React from 'react'

import { apiClients } from '~/common/apiClients'
import { PendingResult } from '~/common/types'
import { LookupTableServiceImpl } from '~/data/lookupTable/LookupTableServiceImpl'
import { LookupTable } from '~/domain/lookupTable/LookupTable'
import { LookupTableCell } from '~/domain/lookupTable/LookupTableCell'
import { LookupTableColumnSchema } from '~/domain/lookupTable/LookupTableColumnSchema'
import { LookupTableRow } from '~/domain/lookupTable/LookupTableRow'
import { LookupTableService } from '~/domain/lookupTable/LookupTableService'
import { useDefinitions } from '~/presentation/AnyflowAppContext'

export function useLookupTable(
  lookupTableId: string,
  pageNumber: number
): {
  result: PendingResult<LookupTable | undefined>
  updateColumnSchema: (newSchema: LookupTableColumnSchema) => Promise<void>
  updateTableName: (newName: string) => Promise<void>
  insertRow: (cells: LookupTableCell[]) => Promise<void>
  deleteRow: (rowId: number) => Promise<void>
} {
  const definitions = useDefinitions()
  const service = React.useRef<LookupTableService>(
    new LookupTableServiceImpl(apiClients.lookupTableApi, definitions)
  )
  const [result, setResult] = React.useState<
    PendingResult<LookupTable | undefined>
  >(PendingResult.pending())

  const fetch = React.useCallback(() => {
    setResult(PendingResult.pending())
    service.current
      .get(lookupTableId, pageNumber)
      .then((_lookupTable) => {
        setResult(PendingResult.success(_lookupTable))
      })
      .catch((reason) => {
        setResult(PendingResult.failure())
        console.error(`Failed to fetch lookup table: ${reason}`)
      })
  }, [lookupTableId, pageNumber])

  const updateColumnSchema = React.useCallback(
    async (newSchema: LookupTableColumnSchema) => {
      return service.current
        .updateColumnSchema(lookupTableId, newSchema)
        .then(() => {
          fetch()
        })
    },
    [lookupTableId, fetch]
  )

  const updateTableName = React.useCallback(
    async (newName: string) => {
      return service.current
        .updateTableName(lookupTableId, newName)
        .then(() => {
          fetch()
        })
    },
    [lookupTableId, fetch]
  )

  const insertRow = React.useCallback(
    async (cells: LookupTableCell[]) => {
      return service.current
        .insertRow(lookupTableId, cells)
        .then((row: LookupTableRow) => {
          setResult((_result) => {
            if (_result.status === PendingResult.Status.success) {
              return PendingResult.success(_result.data?.addRow(row))
            }
            return _result
          })
        })
    },
    [lookupTableId]
  )

  const deleteRow = React.useCallback(
    async (rowId: number) => {
      return service.current.deleteRow(lookupTableId, rowId).then(() => {
        setResult((_result) => {
          if (_result.status === PendingResult.Status.success) {
            return PendingResult.success(_result.data?.removeRow(rowId))
          }
          return _result
        })
      })
    },
    [lookupTableId]
  )

  React.useEffect(() => {
    fetch()
  }, [fetch])

  return { result, updateColumnSchema, updateTableName, insertRow, deleteRow }
}
