import { Pagination } from '~/common/Pagination'
import config from '~/common/config'
import { Tson } from '~/data/Tson'
import { ConnectionJson } from '~/data/connection/ConnectionJson'
import { mapJsonToConnection } from '~/data/connection/mapJsonToConnection'
import { mapToConnectionJson } from '~/data/connection/mapToConnectionJson'
import { mapJsonToPaginationMeta } from '~/data/mapJsonToPaginationMeta'
import requestJson, {
  HttpError,
  requestDelete,
  requestPatch,
  requestPost,
} from '~/data/request'
import { mapJsonToPermission } from '~/data/workflow/permission/mapJsonToPermission'
import { mapModelToPermissionPolicyJson } from '~/data/workflow/permission/mapModelToPermissionJson'
import { mapToPermissionJson } from '~/data/workflow/permission/mapToPermissionJson'
import { Connection } from '~/domain/connection/Connection'
import { ConnectionService } from '~/domain/connection/ConnectionService'
import { Permission } from '~/domain/workflow/permission/Permission'
import { PermissionPolicy } from '~/domain/workflow/permission/PermissionPolicy'

export class ConnectionServiceImpl implements ConnectionService {
  async createSocialAccount(
    provider: string,
    token: string,
    extraData: { [p: string]: string }
  ): Promise<Connection> {
    const res = await requestPost(`${config.apiRoot}/social_accounts`, {
      provider,
      token,
      extraData,
    })
    const json = mapToConnectionJson(new Tson(res))
    return mapJsonToConnection(json)
  }

  async getList(page: number): Promise<Pagination<Connection>> {
    const res = await requestJson(
      `${config.apiRoot}/social_accounts?page=${page}`
    )
    const json = mapToListResponseJson(new Tson(res))
    return {
      items: json.results.map(mapJsonToConnection),
      paginationMeta: mapJsonToPaginationMeta(json),
    }
  }

  async getConnection(id: string): Promise<Connection | undefined> {
    try {
      const res = await requestJson(`${config.apiRoot}/social_accounts/${id}`)
      const json = mapToConnectionJson(new Tson(res))
      return mapJsonToConnection(json)
    } catch (e) {
      if (e instanceof HttpError && e.statusCode === 404) {
        return undefined
      }
      throw e
    }
  }

  async addPermission(
    connectionId: string,
    groupId: string,
    policy: PermissionPolicy
  ): Promise<Permission> {
    const body = {
      group_slug: groupId,
      permission_policy: mapModelToPermissionPolicyJson(policy),
    }
    const res = await requestPost(
      `${config.apiRoot}/social_accounts/${connectionId}/permissions`,
      body
    )
    const json = mapToPermissionJson(new Tson(res))
    return mapJsonToPermission(json)
  }

  async changePermission(
    connectionId: string,
    permissionId: string,
    policy: PermissionPolicy
  ): Promise<Permission> {
    const body = {
      permission_policy: mapModelToPermissionPolicyJson(policy),
    }
    const res = await requestPatch(
      `${config.apiRoot}/social_accounts/${connectionId}/permissions/${permissionId}`,
      body
    )
    const json = mapToPermissionJson(new Tson(res))
    return mapJsonToPermission(json)
  }

  async getPermissions(connectionId: string): Promise<Permission[]> {
    const res = await requestJson(
      `${config.apiRoot}/social_accounts/${connectionId}/permissions`
    )
    const json = new Tson(res).asArray().map(mapToPermissionJson)
    return json.map(mapJsonToPermission)
  }

  async removePermission(
    connectionId: string,
    permissionId: string
  ): Promise<void> {
    await requestDelete(
      `${config.apiRoot}/social_accounts/${connectionId}/permissions/${permissionId}`
    )
  }
}

interface ListResponseJson {
  limit: number
  current: number
  count: number
  results: ConnectionJson[]
}

function mapToListResponseJson(tson: Tson): ListResponseJson {
  return {
    limit: tson.getNumber('limit'),
    current: tson.getNumber('current'),
    count: tson.getNumber('count'),
    results: tson.getArray('results').map(mapToConnectionJson),
  }
}
