import styled from '@emotion/styled'
import * as React from 'react'

import * as vars from '~/styles/variables'

export const RequiredMark = () => <Required>*</Required>

const Required = styled('span')({
  color: vars.color.error,
})
