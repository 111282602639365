import * as _ from 'lodash'

import { assertNever } from '~/common/utils'
import { Definitions } from '~/domain/Definitions'
import { ActionDefinition } from '~/domain/workflow/action/ActionDefinition'
import { ExpectedObjects } from '~/domain/workflow/expectedObject/ExpectedObjects'
import { InputValue } from '~/domain/workflow/source/InputValue'
import {
  TaskSource,
  TriggerSource,
} from '~/domain/workflow/source/WorkflowSourceBody'
import { TriggerDefinition } from '~/domain/workflow/trigger/TriggerDefinition'
import { ValidationResult } from '~/domain/workflow/validator/ValidationResult'
import { DependentValues } from '~/domain/workflow/widget/validator/DependentValues'
import { InputWidgetValidator } from '~/domain/workflow/widget/validator/InputWidgetValidator'
import { SubWidgetsValidator } from '~/domain/workflow/widget/validator/SubWidgetsValidator'

export class WorkflowNodeInputsValidator {
  constructor(
    private readonly definitions: Definitions,
    private readonly expectedObjects: ExpectedObjects
  ) {}

  async validate(
    nodeDefinition: TriggerDefinition | ActionDefinition,
    nodeSource: TriggerSource | TaskSource
  ): Promise<ValidationResult> {
    // const fieldDefinitions = _.keyBy(nodeDefinition.fields, (it) => it.key)
    const keyedInputs = _.keyBy(nodeSource.inputs, (it) => it.fieldKey)

    const widgetValidators: InputWidgetValidator[] = []
    const inputValues: InputValue[] = []

    nodeDefinition.fields.forEach((fieldDefinition) => {
      switch (fieldDefinition.fieldType) {
        case 'dynamic':
          throw new Error('Dynamic field is not supported yet.')
        case 'info_view':
          // ViewField はバリデーション対象ではない
          return
        case 'static':
          break
        default:
          assertNever(fieldDefinition)
      }
      const dependentValues: DependentValues = {}
      fieldDefinition.dependencies.forEach((fieldKey) => {
        // 見つからない場合は undefined （未入力）扱いで良い
        dependentValues[fieldKey] = keyedInputs[fieldKey]?.value
      })
      widgetValidators.push(
        new InputWidgetValidator({
          widgetDefinition: fieldDefinition.form,
          valueType: fieldDefinition.valueType,
          expectedObjects: this.expectedObjects,
          definitions: this.definitions,
          required: fieldDefinition.required,
          useBulk: fieldDefinition.bulkable, // そのフィールドがバルク可能であれば、自動的にバルクモードにする
          dependentValues,
        })
      )
      // 見つからない場合は undefined （未入力）扱いで良い
      inputValues.push(keyedInputs[fieldDefinition.key]?.value)
    })

    const validator = new SubWidgetsValidator(widgetValidators)
    return validator.validate(inputValues)
  }
}
