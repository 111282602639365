import { assertNever } from '~/common/utils'

export type PermissionPolicy =
  | PermissionPolicy.Readable
  | PermissionPolicy.Updatable
  | PermissionPolicy.Deletable
  | PermissionPolicy.Ownable

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace PermissionPolicy {
  interface Ability {
    readable: boolean
    updatable: boolean
    duplicatable: boolean
    executable: boolean
    deletable: boolean
    shareable: boolean
  }

  export function createByName(
    name: PermissionPolicy['name']
  ): PermissionPolicy {
    switch (name) {
      case 'readable':
        return new PermissionPolicy.Readable()
      case 'updatable':
        return new PermissionPolicy.Updatable()
      case 'deletable':
        return new PermissionPolicy.Deletable()
      case 'ownable':
        return new PermissionPolicy.Ownable()
      default:
        assertNever(name)
    }
  }

  export class Readable implements Ability {
    public readonly name = 'readable'
    public readonly readable = true
    public readonly updatable = false
    public readonly duplicatable = false
    public readonly executable = false
    public readonly deletable = false
    public readonly shareable = false
  }

  export class Updatable implements Ability {
    public readonly name = 'updatable'
    public readonly readable = true
    public readonly updatable = true
    public readonly duplicatable = true
    public readonly executable = true
    public readonly deletable = false
    public readonly shareable = false
  }

  export class Deletable implements Ability {
    public readonly name = 'deletable'
    public readonly readable = true
    public readonly updatable = true
    public readonly duplicatable = true
    public readonly executable = true
    public readonly deletable = true
    public readonly shareable = false
  }

  export class Ownable implements Ability {
    public readonly name = 'ownable'
    public readonly readable = true
    public readonly updatable = true
    public readonly duplicatable = true
    public readonly executable = true
    public readonly deletable = true
    public readonly shareable = true
  }
}
