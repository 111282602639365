import { InputValue } from '~/domain/workflow/source/InputValue'
import { ValidationResult } from '~/domain/workflow/validator/ValidationResult'
import {
  BaseWidgetDefinition,
  InputWidgetDefinition,
} from '~/domain/workflow/widget/WidgetDefinition'
import { RawInputWidgetValidator } from '~/domain/workflow/widget/validator/RawInputWidgetValidator'
import { createRawWidgetValidator } from '~/domain/workflow/widget/validator/createRawWidgetValidator'

export interface MultiProviderAccountWidgetDefinition
  extends BaseWidgetDefinition {
  formType: 'multi_provider_account'
  providers: {
    label: string
    value: string
  }[]
  fieldLabel: string
  valueForm: InputWidgetDefinition
}

export class MultiProviderAccountWidgetValidator extends RawInputWidgetValidator<
  MultiProviderAccountWidgetDefinition
> {
  async validate(inputValue: InputValue.Raw): Promise<ValidationResult> {
    // サブウィジェットの検証結果に依存する
    const subValidator = createRawWidgetValidator({
      ...this.context,
      widgetDefinition: this.context.widgetDefinition.valueForm,
    })
    const subResult = await subValidator.validate(inputValue)
    if (subResult.valid) {
      return ValidationResult.valid(subResult.bulkSize)
    } else {
      return ValidationResult.invalid(
        new ValidationResult.Propagated(subResult.cause)
      )
    }
  }
}
