import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import * as defaultInput from '~/common/defaultInput'
import { FetchStatus, SubmitStatus } from '~/common/types'
import { assert, assertNever, ensure } from '~/common/utils'
import { Workflow } from '~/domain/workflow/Workflow'
import { WorkflowNode } from '~/domain/workflow/source/WorkflowNode'
import { TaskSource } from '~/domain/workflow/source/WorkflowSourceBody'
import * as loaderDuck from '~/ducks/editor/loader'
import * as actionSelectorDuck from '~/ducks/ui/actionSelector'
import * as triggerSelectorDuck from '~/ducks/ui/triggerSelector'
import { useEscapeKey } from '~/hooks/useEscapeKey'
import { useWorkflowTree } from '~/hooks/useTree'
import { useUpdateWorkflow } from '~/hooks/useUpdateWorkflow'
import { useMe } from '~/presentation/AnyflowAppContext'
import { useToaster } from '~/presentation/ToasterContext'
import ActionSelector from '~/presentation/workflow/detail/editor/ActionSelector'
import EditorFooter from '~/presentation/workflow/detail/editor/EditorFooter'
import EditorHeader from '~/presentation/workflow/detail/editor/EditorHeader'
import FormContainer from '~/presentation/workflow/detail/editor/FormContainer'
import PanZoomView from '~/presentation/workflow/detail/editor/PanZoomView'
import ScaleAdjustor from '~/presentation/workflow/detail/editor/ScaleAdjustor'
import Tree from '~/presentation/workflow/detail/editor/Tree'
import TriggerSelector from '~/presentation/workflow/detail/editor/TriggerSelector'
import {
  WorkflowEditorContext,
  WorkflowEditorContextValue,
} from '~/presentation/workflow/detail/editor/WorkflowEditorContext'
import NodeEditor from '~/presentation/workflow/detail/editor/form/NodeEditor'
import {
  WorkflowTestResultProvider,
  useWorkflowTestResult,
} from '~/presentation/workflow/detail/editor/test/WorkflowTestResultContext'
import { useUnloadConfirmDialog } from '~/presentation/workflow/detail/editor/useUnloadConfirmDialog'
import { useUpdateWorkflowSource } from '~/presentation/workflow/detail/editor/useUpdateWorkflowSource'
import { useWorkflow } from '~/presentation/workflow/detail/useWorkflow'
import * as vars from '~/styles/variables'

interface OwnProps {
  isEditingMode: boolean
  headerHeight: number
  onBack: () => void
  onExec: (workflowId: string, name: string) => void
}

type Props = OwnProps & RouteComponentProps<{ workflowId: string }>

const WorkflowEdit: React.FC<Props> = ({
  isEditingMode,
  headerHeight,
  onExec,
  onBack,
  match: {
    params: { workflowId },
  },
}) => {
  useUnloadConfirmDialog(isEditingMode)

  const dispatch = useDispatch()
  const toaster = useToaster()
  const [isFormShown, setIsFormShown] = React.useState<boolean>(false)
  const isEditorLoading = useSelector(loaderDuck.selectors.getIsLoading)
  const actionSelector = useSelector(actionSelectorDuck.selectors.getState)
  const triggerSelector = useSelector(triggerSelectorDuck.selectors.getState)
  const me = useMe()

  const [viewport, setViewport] = React.useState({ width: 0, height: 0 })
  const [selectedNode, setSelectedNode] = React.useState<WorkflowNode>()
  const [workflow, setWorkflow] = React.useState<Workflow>()
  const [autoOpenInputFormTaskId, setAutoOpenInputFormTaskId] = React.useState<
    string
  >()
  const [enableStatus, setEnableStatus] = React.useState<SubmitStatus>(
    SubmitStatus.none
  )

  const {
    workflow: masterWorkflow,
    refetch: refetchWorkflow,
    fetchStatus: workflowFetchStatus,
  } = useWorkflow(workflowId)
  const {
    update: updateWorkflowSource,
    submitStatus: workflowSourceSubmitStatus,
    // submitErrorMessage: workflowSourceSubmitErrorMessage,
  } = useUpdateWorkflowSource()
  const { updateWorkflow, status: workflowSubmitStatus } = useUpdateWorkflow()
  const [nodes, links] = useWorkflowTree(workflow?.source?.body)

  // ワークフローが新しく取得され直す度に
  React.useEffect(() => {
    if (masterWorkflow === undefined) {
      return
    }
    if (masterWorkflow.source.getRootTask() === undefined) {
      // タスクが存在しなければルートタスクを EmptyAction のタスクにする
      const taskId = masterWorkflow.source.createTaskId()
      // setWorkflowSource(masterWorkflow.source.addEmptyTask(taskId))
      setWorkflow({
        ...masterWorkflow,
        source: masterWorkflow.source.addEmptyTask(taskId),
      })
    } else {
      setWorkflow(masterWorkflow)
    }
    if (autoOpenInputFormTaskId !== undefined) {
      const task = masterWorkflow.source.findTask(autoOpenInputFormTaskId)
      if (task !== undefined) {
        handleNodeClick(task.taskId)
      }
      setAutoOpenInputFormTaskId(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterWorkflow])

  // componentDidMount
  React.useEffect(() => {
    calcViewport()
    return () => {
      setIsFormShown(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEscapeKey(() => {
    if (isFormShown) {
      handleFormCancel()
    } else if (actionSelector.isShown) {
      dispatch(actionSelectorDuck.actions.hide())
    } else if (isEditingMode) {
      onBack()
    }
  }, [
    isFormShown,
    actionSelector.isShown,
    isEditingMode,
    handleFormCancel,
    onBack,
  ])

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingMode, headerHeight])

  React.useEffect(() => {
    calcViewport()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingMode])

  // WorkflowSource の更新が完了した時に Workflow を取り直す
  React.useEffect(() => {
    if (workflowSourceSubmitStatus === SubmitStatus.submitted) {
      refetchWorkflow()
      toaster.showSuccess('保存しました')
    }
    if (workflowSourceSubmitStatus === SubmitStatus.failed) {
      toaster.showError('保存に失敗しました')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowSourceSubmitStatus])

  // Workflow の更新が完了した時に Workflow を取り直す
  React.useEffect(() => {
    if (workflowSubmitStatus === SubmitStatus.submitted) {
      refetchWorkflow()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowSubmitStatus])

  // InputForm が非表示になった時に expected object をリセットする
  React.useEffect(() => {
    if (!isFormShown) {
      // dispatch(expectedObjectDuck.actions.reset())
      setSelectedNode(undefined)
    }
  }, [isFormShown])

  // Intercom のボタンを非表示にする
  React.useEffect(() => {
    if (isEditingMode) {
      window.Intercom &&
        window.Intercom('update', {
          hide_default_launcher: true,
        })
    }
    return () => {
      window.Intercom &&
        window.Intercom('update', {
          hide_default_launcher: false,
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.Intercom, isEditingMode])

  const workflowEditorContextValue: WorkflowEditorContextValue = React.useMemo(
    () => ({
      workflowId,
      isEditorLoading:
        isEditorLoading ||
        workflowFetchStatus === FetchStatus.loading ||
        workflowSourceSubmitStatus === SubmitStatus.submitting,
      addEmptyTask: handleNewEmptyTask,
      removeTask: handleRemoveTask,
    }),
    [
      workflowId,
      isEditorLoading,
      workflowFetchStatus,
      workflowSourceSubmitStatus,
      handleNewEmptyTask,
      handleRemoveTask,
    ]
  )

  const handleNodeEditorCloseClick = React.useCallback(() => {
    setIsFormShown(false)
  }, [])

  function calcViewport() {
    // without scrollbar
    const innerWidth = document.body.clientWidth
    const innerHeight = document.body.clientHeight
    setViewport({
      width: isEditingMode
        ? innerWidth - vars.width.sidebarCollapsed
        : innerWidth - vars.width.sidebar,
      height: isEditingMode ? innerHeight : innerHeight - headerHeight,
    })
  }

  function handleResize() {
    calcViewport()
  }

  function handleNodeClick(taskId: string) {
    setIsFormShown(true)
    setSelectedNode({ kind: 'task', taskId })
  }

  function handleTriggerNodeClick() {
    setIsFormShown(true)
    setSelectedNode({ kind: 'trigger' })
  }

  function handleFormCancel() {
    // dispatch(expectedObjectDuck.actions.reset())
    setIsFormShown(false)
  }

  function handleNewEmptyTask(parentTaskId: string): string {
    assert(workflow !== undefined, 'workflow === undefined')
    const newTaskId = workflow.source.createTaskId()
    setWorkflow({
      ...workflow,
      source: workflow.source.addEmptyTask(newTaskId, parentTaskId),
    })
    return newTaskId
  }

  function handleRemoveTask(taskId: string) {
    assert(workflow !== undefined, 'workflow === undefined')
    const newWorkflowSource = workflow.source.removeTask(taskId)
    updateWorkflowSource(newWorkflowSource)
    setWorkflow({ ...workflow, source: newWorkflowSource }) // サーバーから再取得し終わる前に UI に反映させる
  }

  function handleBack() {
    setIsFormShown(false)
    onBack()
  }

  const handleEnabledChange = React.useCallback(
    (isEnabled: boolean) => {
      if (!workflow) {
        return
      }
      setEnableStatus(SubmitStatus.submitting)
      updateWorkflow(workflow.workflowId, { isEnabled })
        .then((newWorkflow) => {
          setWorkflow(newWorkflow)
          setEnableStatus(SubmitStatus.submitted)
        })
        .catch((reason) => {
          console.error(reason)
          setEnableStatus(SubmitStatus.failed)
          toaster.showError(
            isEnabled
              ? 'ワークフローの有効化に失敗しました'
              : 'ワークフローの無効化に失敗しました'
          )
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workflow, enableStatus]
  )

  const renderInputForm = () => {
    if (workflow === undefined || selectedNode === undefined) {
      return null
    }
    switch (selectedNode?.kind) {
      case 'trigger': {
        return renderTriggerInputForm()
      }
      case 'task': {
        const task = ensure(
          workflow.source.findTask(selectedNode.taskId),
          `The task '${selectedNode.taskId}' has been clicked, but the task doesn't exist. This is impossible.`
        )
        return renderTaskInputForm(task)
      }
      default:
        return assertNever(selectedNode)
    }
  }

  const renderTriggerInputForm = () => {
    assert(workflow !== undefined, 'workflow === undefined')
    return (
      <NodeEditor
        workflowId={workflowId}
        source={workflow.source.body}
        targetNode={{ kind: 'trigger' }}
        readonly={!workflow.permissionPolicy.updatable}
        handleSaveClick={async (newInputs) => {
          assert(workflow !== undefined, 'workflow === undefined')
          const newWorkflowSource = workflow.source.setTriggerInputs(newInputs)
          updateWorkflowSource(newWorkflowSource)
          setWorkflow({ ...workflow, source: newWorkflowSource })
        }}
        handleCloseClick={handleNodeEditorCloseClick}
      />
    )
  }

  const renderTaskInputForm = (source: TaskSource) => {
    assert(workflow !== undefined, 'workflow === undefined')
    return (
      <NodeEditor
        workflowId={workflowId}
        source={workflow.source.body}
        targetNode={{ kind: 'task', taskId: source.taskId }}
        readonly={!workflow.permissionPolicy.updatable}
        handleSaveClick={async (newInputs) => {
          const newWorkflowSource = workflow.source.setTaskInputs(
            source.taskId,
            newInputs
          )
          updateWorkflowSource(newWorkflowSource)
          setWorkflow({ ...workflow, source: newWorkflowSource })
        }}
        handleCloseClick={handleNodeEditorCloseClick}
      />
    )
  }

  return (
    <WorkflowEditorContext.Provider value={workflowEditorContextValue}>
      <WorkflowTestResultProvider workflowId={workflowId}>
        {/* // なぜかPanZoomViewの横幅が15pxオーバーしてしまうのでhidden */}
        <div style={{ overflow: 'hidden' }}>
          <PanZoomView width={viewport.width} height={viewport.height}>
            <Tree
              nodes={nodes}
              links={links}
              selectedTaskId={
                selectedNode?.kind === 'task' ? selectedNode.taskId : undefined
              }
              readonly={!workflow?.permissionPolicy.updatable ?? true}
              onNodeClick={handleNodeClick}
              onTriggerNodeClick={handleTriggerNodeClick}
            />
          </PanZoomView>
          <ScaleAdjustor
            width={viewport.width}
            height={viewport.height}
            isEditingMode={isEditingMode}
          />
        </div>
        {workflow ? (
          <EditorHeader
            isEditingMode={isEditingMode}
            workflow={workflow}
            enableStatus={enableStatus}
            onExec={onExec}
            onBack={handleBack}
            onEnabledChange={handleEnabledChange}
          />
        ) : null}
        <FormContainer isShown={isFormShown} onClose={handleFormCancel}>
          {renderInputForm()}
        </FormContainer>
        {/*
         * WorkflowTestResultContext を Provider で提供している都合から、
         * WorkflowEdit コンポーネント内からWorkflowTestResult にアクセスできない。
         * そのためには Provider をもっと上位に配置するか、このコンポーネントで直接
         * Context.Provider を配置するかしなければいけないが、直接 Context.Provider を
         * 置くには非同期で取得する WorkflowTestResult の取得状態に応じて Context の
         * 提供を切り替えるロジック等を書かなければいけなくなり、コードの見通しが悪くなる。
         * そのため苦肉の策として、 render 内で hooks を使用するためにここで createElement する
         */}
        {React.createElement(() => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const { update: updateWorkflowTestResult } = useWorkflowTestResult()
          return (
            <ActionSelector
              isShown={actionSelector.isShown}
              taskId={actionSelector.taskId}
              top={actionSelector.top}
              left={actionSelector.left}
              onSelect={async (action) => {
                assert(workflow !== undefined, 'workflow === undefined')
                // アクションを変更したときにテスト結果も削除する
                // 先にテスト結果の削除を行うことで、「アクションの変更には成功したのにテスト結果が残ってる」状態を防ぐ
                try {
                  await updateWorkflowTestResult((current) =>
                    current.removeTaskResult(actionSelector.taskId)
                  )
                } catch (e) {
                  console.error(e)
                  toaster.showError('エラーが発生しました')
                }
                setAutoOpenInputFormTaskId(actionSelector.taskId)
                const newWorkflowSource = workflow.source.replaceTask(
                  actionSelector.taskId,
                  action.actionId
                )
                updateWorkflowSource(newWorkflowSource)
                dispatch(actionSelectorDuck.actions.hide())
              }}
              onClose={() => {
                dispatch(actionSelectorDuck.actions.hide())
              }}
            />
          )
        })}
        {React.createElement(() => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const { update: updateWorkflowTestResult } = useWorkflowTestResult()
          return (
            <TriggerSelector
              isShown={triggerSelector.isShown}
              top={triggerSelector.top}
              left={triggerSelector.left}
              onSelect={async (trigger) => {
                assert(workflow !== undefined, 'workflow === undefined')
                // トリガーを変更したときにテスト結果も削除する
                // 先にテスト結果の削除を行うことで、「トリガーの変更には成功したのにテスト結果が残ってる」状態を防ぐ
                try {
                  await updateWorkflowTestResult((current) =>
                    current.removeTriggerResult()
                  )
                } catch (e) {
                  console.error(e)
                  toaster.showError('エラーが発生しました')
                }
                const newTriggerInput = defaultInput.getDefaultTriggerInputs(
                  trigger.triggerId,
                  me.id
                )
                const newWorkflowSource = workflow.source
                  .replaceTrigger(trigger.triggerId)
                  .setTriggerInputs(newTriggerInput)
                updateWorkflowSource(newWorkflowSource)
                setWorkflow({ ...workflow, source: newWorkflowSource })
                dispatch(triggerSelectorDuck.actions.hide())
              }}
              onClose={() => {
                dispatch(triggerSelectorDuck.actions.hide())
              }}
            />
          )
        })}
        <EditorFooter
          isEditorLoading={
            isEditorLoading ||
            workflowSourceSubmitStatus === SubmitStatus.submitting
          }
          isEditingMode={isEditingMode}
        />
      </WorkflowTestResultProvider>
    </WorkflowEditorContext.Provider>
  )
}

export default withRouter(WorkflowEdit)
