import styled from '@emotion/styled'
import hash from 'object-hash'
import * as React from 'react'
import { animated, useSpring } from 'react-spring'

import Loader from '~/components/atoms/Loader'
import Text from '~/components/atoms/Text'
import { scrollbarStyle } from '~/styles/scrollbar'
import * as vars from '~/styles/variables'
import { widgetBorderStyle } from '~/styles/widget'

export interface DropdownItem<T extends string = string> {
  label: string
  value: T
}

interface Props<T extends string> {
  items: DropdownItem<T>[]
  isOpen: boolean
  loading?: boolean
  emptyLabel?: string
  renderItem?: (item: DropdownItem<T>) => React.ReactNode
  onItemClick?: (item: DropdownItem<T>) => void
  onReachBottom?: () => void
}

function Dropdown<T extends string>(props: Props<T>) {
  const rootRef = React.useRef<HTMLDivElement>(null)
  const bottomRef = React.useRef<HTMLDivElement>(null)

  const dropdownProps = useSpring({
    to: async (next: any) => {
      if (props.isOpen) {
        await next({ display: 'block' })
        await next({
          opacity: 1,
          transform: 'translateY(0)',
          pointerEvents: 'auto',
        })
      } else {
        await next({
          opacity: 0,
          transform: `translateY(-${vars.space.m}px)`,
          pointerEvents: 'none',
        })
        await next({ display: 'none', pointerEvents: 'auto' })
      }
    },
    from: {
      opacity: 0,
      display: 'none',
      transform: `translateY(-${vars.space.m}px)`,
    },
    config: { tension: 500 },
  })

  // onReachBottom の参照が変更される度に IntersectionObserver を張り直すと
  // その都度交差イベントが発生してしまうので、 onReachBottom は ref から取り出して
  // イベントが発生したタイミングの最新の onReachBottom を使用するようにする。
  const onReachBottomRef = React.useRef<() => void>()
  React.useEffect(() => {
    onReachBottomRef.current = props.onReachBottom
  }, [props.onReachBottom])

  // IntersectionObserver の初期化
  // items が変更された時、 items があまりにも少ない場合最初から
  // bottom が見えていることがある。そのタイミングでも発火させたいので、
  // items が変わったタイミングで IntersectionObserver を張り直す。
  React.useLayoutEffect(() => {
    const root = rootRef.current
    if (root === null) {
      return
    }
    const observer = new IntersectionObserver(
      (entries) => {
        // 交差したタイミングのイベントのみ有用
        if (entries.some((it) => !it.isIntersecting)) {
          return
        }
        const onReachBottom = onReachBottomRef.current
        if (onReachBottom !== undefined) {
          onReachBottom()
        }
      },
      {
        root,
        threshold: 1.0,
      }
    )
    const bottom = bottomRef.current
    if (bottom === null) {
      return
    }
    observer.observe(bottom)
    return () => {
      observer.disconnect()
    }
  }, [props.items])

  const renderItems = () => {
    return props.items.map((item) => (
      <Item
        // item.value が T 型なので hash 値を key にしておく
        // パフォーマンスで問題がありそうであれば考える
        key={hash(item)}
        // 連続でクリックされた場合、onClickが複数回発火するのを抑えたい
        onDoubleClick={() => {}}
        onClick={() => {
          // isOpen ではなくても閉じるアニメーション中だと見えない要素がクリックされてしまう時がある
          // ので isOpen でなければ無視する
          if (props.isOpen && props.onItemClick !== undefined) {
            props.onItemClick(item)
          }
        }}
      >
        {props.renderItem !== undefined
          ? props.renderItem(item)
          : renderItem(item)}
      </Item>
    ))
  }

  const renderItem = (item: DropdownItem) => {
    return (
      <Text element="span" fontSize="m" lineHeight="heading">
        {item.label}
      </Text>
    )
  }

  const renderEmptyLabel = () => {
    if (props.loading === true) {
      return null
    }
    if (props.emptyLabel === undefined) {
      return null
    }
    return (
      <StaticItem>
        <Text element="span" color="tertiary" fontSize="m" lineHeight="heading">
          {props.emptyLabel}
        </Text>
      </StaticItem>
    )
  }

  return (
    <Container ref={rootRef} style={dropdownProps}>
      {props.items.length > 0 ? renderItems() : renderEmptyLabel()}
      <div ref={bottomRef} />
      {props.loading && (
        <StaticItem style={{ textAlign: 'center' }}>
          <Loader size="s" />
        </StaticItem>
      )}
    </Container>
  )
}

export default Dropdown

const Container = styled(animated.div)({
  position: 'absolute',
  top: vars.height.field + vars.space.s,
  left: 0,
  flexDirection: 'column',
  // セレクトフィールドがフォーム内で一番最後にあるケースで
  // ドロップダウンメニューの下に余白がないのでマージンをつける
  marginBottom: vars.space.l,
  padding: vars.space.s,
  width: '100%',
  maxHeight: 300,
  overflowY: 'scroll',
  boxShadow: vars.shadow.m,
  backgroundColor: vars.color.white,
  zIndex: vars.zIndex.dropdown,
  ...widgetBorderStyle,
  ...scrollbarStyle,
})

const Item = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingTop: vars.space.s * 1.5,
  paddingRight: vars.space.m,
  paddingBottom: vars.space.s * 1.5,
  paddingLeft: vars.space.m,
  borderRadius: vars.borderRadius.m,
  wordBreak: 'break-word',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: vars.color.lightGray,
  },
})

const StaticItem = styled('div')({
  display: 'flex',
  width: '100%',
  paddingTop: vars.space.s * 1.5,
  paddingRight: vars.space.m,
  paddingBottom: vars.space.s * 1.5,
  paddingLeft: vars.space.m,
})
