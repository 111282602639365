import styled from '@emotion/styled'
import * as React from 'react'
import Helmet from 'react-helmet'
import { Route, Switch, useHistory, useLocation } from 'react-router'

import { Result } from '~/common/Result'
import Button from '~/components/atoms/Button'
import Tabs, { Tab } from '~/components/organisms/Tabs'
import SidebarTemplate from '~/components/templates/SidebarTemplate'
import { Invitation } from '~/domain/user/Invitation'
import { InvitationResult } from '~/domain/user/InvitationResult'
import { User } from '~/domain/user/User'
import { useMe } from '~/presentation/AnyflowAppContext'
import { useToaster } from '~/presentation/ToasterContext'
import { InvitationTable } from '~/presentation/user/InvitationTable'
import { InviteUsersModal } from '~/presentation/user/InviteUsersModal'
import { UserTable } from '~/presentation/user/UserTable'
import { useInvitationList } from '~/presentation/user/useInvitationList'
import { useInvite } from '~/presentation/user/useInvite'
import { useUserList } from '~/presentation/user/useUserList'
import { USER_LIST, WORKFLOW_LIST } from '~/routes'
import * as vars from '~/styles/variables'

export const UserListPage: React.FC = () => {
  const [isInviteModalOpen, setIsInviteModalOpen] = React.useState(false)
  const me = useMe()
  const toaster = useToaster()
  const history = useHistory()
  const location = useLocation()
  const {
    inviting,
    invite,
    reInvite,
    isReInviting,
    cancelInvitation,
    isCanceling,
  } = useInvite()

  const { result: usersResult } = useUserList(me.organization.id)
  const { result: invitationsResult, refreshInvitations } = useInvitationList()

  React.useEffect(() => {
    if (!me.isAdmin) {
      history.push(WORKFLOW_LIST)
    }
  }, [history, me.isAdmin])

  const currentTabId = React.useMemo(() => {
    if (location.pathname.match(/\/invitations$/)) {
      return 'invitations'
    }
    return 'users'
  }, [location.pathname])

  const handleTabClick = React.useCallback(
    (tabId: string) => {
      switch (tabId) {
        case 'users': {
          history.push(USER_LIST)
          break
        }
        case 'invitations': {
          history.push(`${USER_LIST}/invitations`)
          break
        }
      }
    },
    [history]
  )

  const openInviteModal = React.useCallback(() => {
    setIsInviteModalOpen(true)
  }, [])

  const closeInviteModal = React.useCallback(() => {
    setIsInviteModalOpen(false)
  }, [])

  const tabs: Tab[] = React.useMemo(() => {
    return [
      {
        id: 'users',
        label: '所属ユーザー',
        nested: false,
        renderTab: createRenderTab(usersResult),
      },
      {
        id: 'invitations',
        label: '招待中',
        nested: false,
        renderTab: createRenderTab(invitationsResult),
      },
    ]
  }, [invitationsResult, usersResult])

  const handleInvite = React.useCallback(
    async (emails: string[]): Promise<InvitationResult> => {
      const invitationResult = await invite(emails)
      if (invitationResult.isSuccess) {
        await refreshInvitations()
        setIsInviteModalOpen(false)
        toaster.showSuccess('招待を送信しました')
      }
      return invitationResult
    },
    [invite, refreshInvitations, toaster]
  )

  const handleReInvite = React.useCallback(
    async (invitationId: string): Promise<void> => {
      if (isReInviting(invitationId)) {
        return
      }
      try {
        await reInvite(invitationId)
        await refreshInvitations()
      } catch (error) {
        toaster.showError('招待の再送に失敗しました')
        console.error(error)
      }
    },
    [isReInviting, reInvite, refreshInvitations, toaster]
  )

  const handleCancelInvitation = React.useCallback(
    async (invitationId: string): Promise<void> => {
      if (isCanceling(invitationId)) {
        return
      }
      if (!confirm('招待を取り消してもよろしいですか？')) {
        return
      }
      try {
        await cancelInvitation(invitationId)
        await refreshInvitations()
      } catch (error) {
        toaster.showError('招待の取り消しに失敗しました')
        console.error(error)
      }
    },
    [cancelInvitation, isCanceling, refreshInvitations, toaster]
  )

  return (
    <SidebarTemplate>
      <Helmet>
        <title>ユーザー管理</title>
      </Helmet>
      <div>
        <div
          style={{
            paddingTop: vars.space.l,
            paddingRight: vars.space.l,
            paddingLeft: vars.space.l,
            borderBottom: `1px solid ${vars.color.border}`,
          }}
        >
          <Header>
            <Heading>ユーザー管理</Heading>
            <Button onClick={openInviteModal}>ユーザーを招待する</Button>
          </Header>
          <div style={{ marginTop: vars.space.m }}>
            <Tabs
              tabs={tabs}
              selectedTabId={currentTabId}
              onTabClick={handleTabClick}
            />
          </div>
        </div>
        <div
          style={{
            paddingTop: vars.space.m,
            paddingBottom: vars.space.m,
            paddingLeft: vars.space.l,
            paddingRight: vars.space.l,
          }}
        >
          <Switch>
            <Route
              path={`${USER_LIST}/invitations`}
              exact={true}
              render={() => (
                <InvitationTable
                  result={invitationsResult}
                  handleReInvite={handleReInvite}
                  isReInviting={isReInviting}
                  handleCancelInvitation={handleCancelInvitation}
                  isCanceling={isCanceling}
                />
              )}
            />
            <Route
              path={USER_LIST}
              render={() => <UserTable result={usersResult} />}
            />
          </Switch>
        </div>
        <InviteUsersModal
          isOpen={isInviteModalOpen}
          handleClose={closeInviteModal}
          inviting={inviting}
          handleInvite={handleInvite}
          usersResult={usersResult}
          invitationsResult={invitationsResult}
        />
      </div>
    </SidebarTemplate>
  )
}

function createRenderTab(
  result: Result<User[] | Invitation[]>
): (label: string) => React.ReactNode {
  if (result.loading || result.error !== undefined) {
    return (label: string) => {
      return <>{label}</>
    }
  }

  const count = result.data.length
  const displayCount = count > 99 ? '+99' : count
  return (label: string) => {
    return (
      <>
        {label}
        <Badge>{displayCount}</Badge>
      </>
    )
  }
}

const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const Heading = styled('h1')({
  margin: 0,
  fontSize: vars.fontSize.xxl,
})

const Badge = styled('span')({
  border: `1px solid ${vars.color.border}`,
  borderRadius: vars.borderRadius.l,
  backgroundColor: vars.color.gray,
  color: vars.color.white,
  fontSize: vars.fontSize.xxs,
  fontWeight: vars.fontWeight.bold,
  paddingRight: vars.space.xs,
  paddingLeft: vars.space.xs,
  paddingTop: vars.space.xs / 2,
  paddingBottom: vars.space.xs / 2,
  marginLeft: vars.space.s,
  minWidth: '2.5em',
  textAlign: 'center',
})
