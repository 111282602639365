import { assertNever } from '~/common/utils'
import { ViewUnitJson } from '~/data/workflow/triggerInstruction/ViewUnitJson'
import { ViewUnitDefinition } from '~/domain/workflow/viewUnit/ViewUnitDefinition'

export function mapJsonToViewUnit(json: ViewUnitJson): ViewUnitDefinition {
  switch (json.type) {
    case 'copy': {
      return {
        type: json.type,
        label: json.label,
        value: json.value,
      }
    }
    default: {
      assertNever(json.type)
    }
  }
}
