import styled from '@emotion/styled'
import React, { CSSProperties } from 'react'
import * as ReactDOM from 'react-dom'
import { animated, useSpring } from 'react-spring'

import TooltipComp from '~/components/atoms/Tooltip'
import * as vars from '~/styles/variables'

interface Props {
  children: React.ReactNode
  /** ツールチップ内に表示する内容 */
  renderContent: () => React.ReactNode
  /** 遅延時間 (ms) */
  delay?: number
  /** ツールチップ表示を有効にするかどうか */
  enabled?: boolean
  className?: string
  tooltipClassName?: string
  style?: CSSProperties
}

const Tooltip: React.FC<Props> = ({
  renderContent,
  children,
  enabled = true,
  delay = 500,
  className,
  tooltipClassName,
  style,
}) => {
  const [isShown, setIsShown] = React.useState(false)
  const [rect, setRect] = React.useState<ClientRect | DOMRect | null>(null)
  const targetRef = React.useRef<HTMLDivElement | null>(null)
  const enterTimer = React.useRef<number>()

  const tooltipProps = useSpring({
    to: async (next: any) => {
      if (enabled && isShown && rect !== null) {
        const offset = 6
        await next({
          display: 'block',
          top: rect.top + rect.height + offset,
          left: rect.left,
        })
        await next({ opacity: 1, transform: 'translateY(0)' })
      } else {
        await next({
          top: 0,
          left: 0,
          opacity: 0,
          display: 'none',
          transform: `translateY(-${vars.space.s}px)`,
        })
      }
    },
    from: {
      top: 0,
      left: 0,
      opacity: 0,
      display: 'none',
      transform: `translateY(-${vars.space.m}px)`,
    },
    config: { tension: 500 },
  })

  const handleMouseEnter = React.useCallback(() => {
    clearTimeout(enterTimer.current)
    enterTimer.current = window.setTimeout(() => {
      if (targetRef && targetRef.current) {
        setRect(targetRef.current.getBoundingClientRect())
        setIsShown(true)
      }
    }, delay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetRef, enterTimer])

  const handleMouseLeave = React.useCallback(() => {
    clearTimeout(enterTimer.current)
    setRect(null)
    setIsShown(false)
  }, [])

  const target = document.getElementById('portal-root')
  if (!target) {
    return null
  }
  return (
    <>
      <Target
        ref={targetRef}
        style={style}
        className={className}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </Target>
      {ReactDOM.createPortal(
        <TooltipContainer style={tooltipProps} className={tooltipClassName}>
          <TooltipComp>{renderContent()}</TooltipComp>
        </TooltipContainer>,
        target
      )}
    </>
  )
}

const Target = styled('div')({
  display: 'inline-block',
})

const TooltipContainer = styled(animated.div)({
  position: 'fixed',
  maxWidth: 300,
  pointerEvents: 'none',
  zIndex: vars.zIndex.tip,
})

export default Tooltip
