import React from 'react'

import { assertNever } from '~/common/utils'
import FunctionHoverCard from '~/components/molecules/HoverCard/FunctionHoverCard'
import MethodHoverCard from '~/components/molecules/HoverCard/MethodHoverCard'
import PropertyHoverCard from '~/components/molecules/HoverCard/PropertyHoverCard'
import ValueHoverCard from '~/components/molecules/HoverCard/ValueHoverCard'
import { HoverCardProps } from '~/components/molecules/HoverCard/types'

const HoverCard: React.FC<HoverCardProps> = (props) => {
  switch (props.type) {
    case 'property':
      return <PropertyHoverCard {...props} />
    case 'method':
      return <MethodHoverCard {...props} />
    case 'function':
      return <FunctionHoverCard {...props} />
    case 'value':
      return <ValueHoverCard {...props} />
    default:
      assertNever(props)
  }
}

export default HoverCard
