import * as React from 'react'

import { PaginationMeta } from '~/common/Pagination'
import { apiClients } from '~/common/apiClients'
import { FetchStatus } from '~/common/types'
import { WorkflowInstanceService } from '~/domain/workflow/instance/WorkflowInstanceService'
import { WorkflowInstanceSummary } from '~/domain/workflow/instance/WorkflowInstanceSummary'

const workflowInstanceService: WorkflowInstanceService =
  apiClients.workflowInstanceService

export function useWorkflowInstanceSummaries(
  workflowId: string,
  pageNumber: number,
  countPerPage?: number
): {
  workflowInstanceSummaries?: WorkflowInstanceSummary[]
  paginationMeta?: PaginationMeta
  refetch: (ignoreLoading?: boolean) => void
  fetchStatus: FetchStatus
} {
  const [
    workflowInstanceSummaries,
    setWorkflowInstanceSummaries,
  ] = React.useState<WorkflowInstanceSummary[]>()
  const [paginationMeta, setPaginationMeta] = React.useState<PaginationMeta>()
  const [fetchStatus, setFetchStatus] = React.useState<FetchStatus>(
    FetchStatus.none
  )

  React.useEffect(() => {
    fetchWorkflowInstanceSummaries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowId, pageNumber, countPerPage])

  const fetchWorkflowInstanceSummaries = React.useCallback(
    (ignoreLoading: boolean = false) => {
      if (!ignoreLoading) {
        setFetchStatus(FetchStatus.loading)
      }
      workflowInstanceService
        .getList(workflowId, pageNumber, countPerPage)
        .then((workflowInstanceSummary) => {
          setFetchStatus(FetchStatus.loaded)
          setPaginationMeta(
            workflowInstanceSummary
              ? {
                  totalCount: workflowInstanceSummary.paginationMeta.totalCount,
                  currentPageNumber:
                    workflowInstanceSummary.paginationMeta.currentPageNumber,
                  countPerPage:
                    workflowInstanceSummary.paginationMeta.countPerPage,
                }
              : undefined
          )
          setWorkflowInstanceSummaries(workflowInstanceSummary?.items)
        })
        .catch((reason) => {
          setFetchStatus(FetchStatus.failed)
          console.error(
            `Failed to fetch workflow instance summaries: ${reason}`
          )
        })
    },
    [workflowId, pageNumber, countPerPage]
  )

  return {
    workflowInstanceSummaries,
    paginationMeta,
    refetch: fetchWorkflowInstanceSummaries,
    fetchStatus,
  }
}
