import styled from '@emotion/styled'
import * as d3 from 'd3'
import * as React from 'react'
import { Plus } from 'react-feather'

import { actionNodeSize } from '~/components/molecules/ActionNode'
import ForeignObject from '~/components/utils/ForeignObject'
import { TaskSource } from '~/domain/workflow/source/WorkflowSourceBody'
import { Node } from '~/hooks/useTree'
import * as vars from '~/styles/variables'

// 影を途切れさせないために大きめにとってある
const canvasSize = 100
const buttonSize = 35

interface NodeAddButtonProps {
  node?: d3.HierarchyPointNode<Node>
  link?: d3.HierarchyPointLink<Node>
  parentTask?: TaskSource | null
  disabled?: boolean
  onClick: (e: React.MouseEvent, parentTask: TaskSource | null) => void
}

const NodeAddButton: React.FC<NodeAddButtonProps> = ({
  node,
  link,
  parentTask,
  disabled,
  onClick,
}) => {
  const [isButtonShown, set] = React.useState(false)

  let x: number | null = null
  let y: number | null = null
  // 見た目調整用のオフセット
  const xOffset = -5

  // 末尾の+ボタン
  if (node) {
    x = node.y + actionNodeSize + xOffset
    y = node.x + actionNodeSize / 2 - canvasSize / 2
  }

  // アクション間の+ボタン
  if (link) {
    x = link.source.y + actionNodeSize + xOffset
    y = link.source.x + actionNodeSize / 2 - canvasSize / 2
  }

  if (x === null && y === null) {
    return null
  }

  // x と y が逆なのに注意
  return (
    <Container transform={`translate(${x}, ${y})`}>
      <ForeignObject width={canvasSize} height={canvasSize}>
        <ButtonContainer>
          <_Button
            disabled={disabled ?? false}
            onMouseEnter={() => set(true)}
            onMouseLeave={() => set(false)}
            onClick={(e) => {
              if (disabled) {
                return
              }
              set(false)
              onClick(e, parentTask || null)
            }}
          >
            <Plus
              color={isButtonShown ? vars.fontColor.tertiary : vars.color.gray}
            />
          </_Button>
        </ButtonContainer>
      </ForeignObject>
    </Container>
  )
}

const Container = styled('g')({
  // ドロップシャドウを途切れさせないために大きめにとってある
  width: buttonSize * 2,
  height: buttonSize * 2,
})

const ButtonContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
})

const _Button = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: buttonSize,
    height: buttonSize,
    borderRadius: '50%',
    backgroundColor: vars.color.white,
    boxShadow: vars.shadow.m,
  },
  (props: { disabled: boolean }) => ({
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    opacity: props.disabled ? 0.5 : 1,
  })
)

export default NodeAddButton
