import { delay } from '~/common/utils'
import { SalesforceDataType } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceDataType'
import { SalesforceSObject } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSObject'
import { SalesforceSearchConditionService } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchConditionService'

const fields = [
  {
    id: 'field_1',
    label: '商談名',
    dataType: new SalesforceDataType.String(),
  },
  {
    id: 'field_2',
    label: '金額',
    dataType: new SalesforceDataType.Number(),
  },
  {
    id: 'field_3',
    label: '商談化済み',
    dataType: new SalesforceDataType.Boolean(),
  },
  {
    id: 'field_4',
    label: '種別',
    dataType: new SalesforceDataType.Picklist([
      { label: 'りんご', value: 'apple' },
      { label: 'バナナ', value: 'banana' },
      { label: 'さくらんぼ', value: 'cherry' },
      {
        label:
          '吾輩は猫である。名前はまだ無い。どこで生れたかとんと見当がつかぬ。何でも薄暗いじめじめした所でニャーニャー泣いていた事だけは記憶している。',
        value: 'cat',
      },
    ]),
  },
  {
    id: 'field_5',
    label: '利用予定部署',
    dataType: new SalesforceDataType.MultiPicklist([
      { label: 'セールス', value: 'sales' },
      { label: 'コーポレート', value: 'corporate' },
      { label: 'エンジニア', value: 'engineer' },
      { label: 'カスタマーサクセス', value: 'customer_success' },
    ]),
  },
  {
    id: 'field_6',
    label: '開始日',
    dataType: new SalesforceDataType.Date(),
  },
  {
    id: 'field_7',
    label: '所有者',
    dataType: new SalesforceDataType.Reference(),
  },
]
const sObject = new SalesforceSObject('', '', fields)

export class MockSalesforceSearchConditionService
  implements SalesforceSearchConditionService {
  async getSObject(): Promise<SalesforceSObject> {
    await delay(2000)
    return sObject
  }
}
