import * as React from 'react'

import { apiClients } from '~/common/apiClients'
import { SubmitStatus } from '~/common/types'
import { Workflow } from '~/domain/workflow/Workflow'
import { WorkflowService } from '~/domain/workflow/WorkflowService'

const service: WorkflowService = apiClients.workflowService

export function useUpdateWorkflow(): {
  updateWorkflow: (
    workflowId: string,
    workflow: Partial<Workflow>
  ) => Promise<Workflow>
  status: SubmitStatus
} {
  const [status, setStatus] = React.useState<SubmitStatus>(SubmitStatus.none)
  const update = React.useCallback(
    (workflowId: string, workflow: Partial<Workflow>) => {
      setStatus(SubmitStatus.submitting)
      return service
        .update(workflowId, workflow)
        .then((it) => {
          setStatus(SubmitStatus.submitted)
          return it
        })
        .catch((it) => {
          setStatus(SubmitStatus.failed)
          throw it
        })
    },
    []
  )
  return { updateWorkflow: update, status }
}
