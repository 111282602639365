import { Tson } from '~/data/Tson'
import {
  ExpectedObjectJson,
  ExpectedObjectsJson,
  TaskExpectedObjectsJson,
  TriggerExpectedObjectsJson,
} from '~/data/workflow/expectedObject/json'
import { mapToObjectPropertyValueJson } from '~/data/workflow/object/mapToObjectPropertyValueJson'

export function mapToExpectedObjectsJson(tson: Tson): ExpectedObjectsJson {
  const trigger = tson.getValue('trigger', false)
  return {
    trigger:
      trigger !== undefined && trigger !== null
        ? mapToTriggerExpectedObjectJson(trigger)
        : undefined,
    tasks: tson.getArray('tasks').map(mapToTaskExpectedObjectJson),
  }
}

function mapToTriggerExpectedObjectJson(
  tson: Tson
): TriggerExpectedObjectsJson {
  return {
    triggerId: tson.getString('triggerId'),
    expectedObjects: tson
      .getArray('expectedObjects')
      .map(mapToExpectedObjectJson),
  }
}

function mapToTaskExpectedObjectJson(tson: Tson): TaskExpectedObjectsJson {
  return {
    taskId: tson.getString('taskId'),
    actionId: tson.getString('actionId'),
    expectedObjects: tson
      .getArray('expectedObjects')
      .map(mapToExpectedObjectJson),
  }
}

export function mapToExpectedObjectJson(tson: Tson): ExpectedObjectJson {
  return {
    key: tson.getString('key'),
    name: tson.getString('name'),
    objId: tson.getString('objId'),
    sampleValues: tson
      .getArray('sampleValues')
      .map(mapToObjectPropertyValueJson),
  }
}
