import styled from '@emotion/styled'
import * as React from 'react'
import { animated, useSpring } from 'react-spring'

import * as vars from '~/styles/variables'

interface Props {
  value: boolean
  onChange?: (value: boolean) => void
}

const MiniSwitch: React.FC<Props> = ({ value, onChange }) => {
  const containerProps = useSpring({
    backgroundColor: value
      ? vars.colorPalette.blue600
      : vars.colorPalette.gray3,
  })
  const stickProps = useSpring({
    transform: value ? 'translateX(12px)' : 'translateX(0px)',
  })
  return (
    <Container
      style={containerProps}
      onClick={() => onChange && onChange(!value)}
    >
      <Stick style={stickProps} />
    </Container>
  )
}

const Container = styled(animated.div)({
  position: 'relative',
  width: 28,
  height: 16,
  borderRadius: 8,
})

const Stick = styled(animated.div)({
  position: 'absolute',
  top: 2,
  left: 2,
  backgroundColor: vars.color.white,
  width: 12,
  height: 12,
  borderRadius: '50%',
})

export default MiniSwitch
