import * as _ from 'lodash'

import { JsonError } from '~/data/JsonError'

export class Tson {
  private readonly jsonObject: any
  private pathStack: string[] = []

  constructor(json: any) {
    this.jsonObject = json
    Object.freeze(this.jsonObject)
  }

  asString(): string {
    if (typeof this.jsonObject !== 'string') {
      throw this.createJsonError('A value must be string.')
    }
    return this.jsonObject
  }

  asNumber(): number {
    if (typeof this.jsonObject !== 'number') {
      throw this.createJsonError('A value must be number.')
    }
    return this.jsonObject
  }

  asBoolean(): boolean {
    if (typeof this.jsonObject !== 'boolean') {
      throw this.createJsonError('A value must be boolean.')
    }
    return this.jsonObject
  }

  asArray(): Tson[] {
    if (!_.isArray(this.jsonObject)) {
      throw this.createJsonError('A value must be array.')
    }
    return this.jsonObject.map((it) => this.createSubTson(it))
  }

  asIs(): unknown {
    return this.jsonObject
  }

  getString(key: string, required?: true): string
  getString(key: string, required: false): string | undefined | null
  getString(key: string, required: boolean = true): string | undefined | null {
    if (!this.jsonObject.hasOwnProperty(key)) {
      if (required) {
        throw this.createJsonError(`Required key '${key}' was missed.`)
      }
      return undefined
    }
    const value: unknown = this.jsonObject[key]
    if (value === null) {
      if (required) {
        throw this.createJsonError(`Required key '${key}' had null value.`)
      }
      return null
    }
    if (typeof value !== 'string') {
      throw this.createJsonError(`A value of key '${key}' must be string.`)
    }
    return value
  }

  getBoolean(key: string, required?: true): boolean
  getBoolean(key: string, required: false): boolean | undefined | null
  getBoolean(
    key: string,
    required: boolean = true
  ): boolean | undefined | null {
    if (!this.jsonObject.hasOwnProperty(key)) {
      if (required) {
        throw this.createJsonError(`Required key '${key}' was missed.`)
      }
      return undefined
    }
    const value: unknown = this.jsonObject[key]
    if (value === null) {
      if (required) {
        throw this.createJsonError(`Required key '${key}' had null value.`)
      }
      return null
    }
    if (typeof value !== 'boolean') {
      throw this.createJsonError(`A value of key '${key}' must be boolean.`)
    }
    return value
  }

  getNumber(key: string, required?: true): number
  getNumber(key: string, required: false): number | undefined | null
  getNumber(key: string, required: boolean = true): number | undefined | null {
    if (!this.jsonObject.hasOwnProperty(key)) {
      if (required) {
        throw this.createJsonError(`Required key '${key}' was missed.`)
      }
      return undefined
    }
    const value: unknown = this.jsonObject[key]
    if (value === null) {
      if (required) {
        throw this.createJsonError(`Required key '${key}' had null value.`)
      }
      return null
    }
    if (typeof value !== 'number') {
      throw this.createJsonError(`A value of key '${key}' must be number.`)
    }
    return value
  }

  getArray(key: string, required?: true): Tson[]
  getArray(key: string, required: false): Tson[] | undefined | null
  getArray(key: string, required: boolean = true): Tson[] | undefined | null {
    if (!this.jsonObject.hasOwnProperty(key)) {
      if (required) {
        throw this.createJsonError(`Required key '${key}' was missed.`)
      }
      return undefined
    }
    const value: unknown = this.jsonObject[key]
    if (value === null) {
      if (required) {
        throw this.createJsonError(`Required key '${key}' had null value.`)
      }
      return null
    }
    if (!_.isArray(value)) {
      throw this.createJsonError(`A value of key '${key}' must be array.`)
    }
    return value.map((it) => this.createSubTson(it, key))
  }

  getValue(key: string, required?: true): Tson
  getValue(key: string, required: false): Tson | undefined | null
  getValue(key: string, required: boolean = true): Tson | undefined | null {
    if (!this.jsonObject.hasOwnProperty(key)) {
      if (required) {
        throw this.createJsonError(`Required key '${key}' was missed.`)
      }
      return undefined
    }
    const value: unknown = this.jsonObject[key]
    if (value === null) {
      if (required) {
        throw this.createJsonError(`Required key '${key}' had null value.`)
      }
      return null
    }
    return this.createSubTson(value, key)
  }

  createSubTson(json: unknown, currentKey?: string): Tson {
    const tson = new Tson(json)
    tson.pathStack = [...this.pathStack]
    if (currentKey !== undefined) {
      tson.pathStack.push(currentKey)
    }
    return tson
  }

  createJsonError(message: string, currentKey?: string): JsonError {
    const pathString = this.createPathString(currentKey)
    if (pathString !== '') {
      return new JsonError(`${message}\n Path: ${pathString}`)
    }
    return new JsonError(message)
  }

  createPathString(currentKey?: string): string {
    const paths = [...this.pathStack]
    if (currentKey !== undefined) {
      paths.push(currentKey)
    }
    return paths.join(' > ')
  }
}
