import produce from 'immer'
import { createSelector } from 'reselect'
import { actionCreatorFactory } from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { RootState } from '~/ducks'

const actionCreator = actionCreatorFactory('anyflow/editor/selectedNode')

export const types = {
  RESET: 'RESET',
  SET_TASK_INFO: 'SET_TASK_INFO',
  UPDATE_TASK_INFO: 'UPDATE_TASK_INFO',
  SET_TRIGGER_INFO: 'SET_TRIGGER_INFO',
}

export const actions = {
  reset: actionCreator(types.RESET),
  setTaskInfo: actionCreator<{
    taskId: string
    actionId: string
  }>(types.SET_TASK_INFO),
  updateTaskInfo: actionCreator<{ parentTaskId: string }>(
    types.UPDATE_TASK_INFO
  ),
  setTriggerInfo: actionCreator<{ triggerId: string }>(types.SET_TRIGGER_INFO),
}

export const selectors = {
  getTaskInfo: createSelector(
    (state: RootState) => state.editor.selectedNode.task,
    (task) => task
  ),
  getTriggerInfo: createSelector(
    (state: RootState) => state.editor.selectedNode.trigger,
    (trigger) => trigger
  ),
}

export type State = {
  task: { taskId: string; parentTaskId: string; actionId: string } | null
  trigger: { triggerId: string } | null
}

const initialState: State = {
  task: null,
  trigger: null,
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.reset, () => Object.assign({}, initialState))
  .case(actions.setTaskInfo, (state, payload) =>
    produce(state, (draft) => {
      draft.task = {
        taskId: payload.taskId,
        actionId: payload.actionId,
        parentTaskId: '',
      }
      draft.trigger = null
    })
  )
  .case(actions.updateTaskInfo, (state, payload) =>
    produce(state, (draft) => {
      draft.task = {
        taskId: draft.task ? draft.task.taskId : '',
        actionId: draft.task ? draft.task.actionId : '',
        parentTaskId: payload.parentTaskId,
      }
    })
  )
  .case(actions.setTriggerInfo, (state, payload) =>
    produce(state, (draft) => {
      draft.trigger = {
        triggerId: payload.triggerId,
      }
      draft.task = null
    })
  )

export const sagas = []
