import styled from '@emotion/styled'
import * as React from 'react'
import { Plus } from 'react-feather'

import Inner from '~/components/organisms/Inner'
import Modal from '~/components/organisms/Modal'
import { AccountWidgetDefinition } from '~/domain/workflow/widget/account'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import InputWidget from '~/presentation/workflow/detail/editor/form/inputWidget/InputWidget'
import { RawInputWidgetProps } from '~/presentation/workflow/detail/editor/form/inputWidget/RawInputWidget'
import AccountForm from '~/presentation/workflow/detail/editor/form/inputWidget/account/AccountForm'
import {
  ProcessStatus,
  useLoginSocial,
} from '~/presentation/workflow/detail/editor/form/inputWidget/account/useLoginSocial'
import { useInputWidgetValidation } from '~/presentation/workflow/detail/editor/form/validation/useValidation'
import * as vars from '~/styles/variables'
import { widgetBorderStyle } from '~/styles/widget'

interface Props extends RawInputWidgetProps {
  definition: AccountWidgetDefinition
  /**
   * ログインが完了した時のコールバック
   *
   * RawWidget を経由して使用する場合など汎用的な使い方の場合は使用しません。
   * LegacyAccountWidget がログインされた時にアカウントリストを更新するために定義しています。
   */
  onLoggedIn?: () => void
}

const AccountWidget: React.FC<Props> = (props) => {
  const definitions = useDefinitions()
  const [accountFormShown, setAccountFormShown] = React.useState<boolean>(false)
  const {
    socialAccountUid,
    startProcess: startLoginProcess,
    status: loginProcessStatus,
  } = useLoginSocial()

  // バリデーション
  // バリデーションエラーは表示しない（サブウィジェット側のものが表示されるので問題ない）
  useInputWidgetValidation(props)

  // ログイン処理が終わった時に入力値を変更する
  React.useEffect(() => {
    if (loginProcessStatus !== ProcessStatus.processed) {
      return
    }
    if (socialAccountUid === undefined) {
      return
    }
    changeValueWithAccountUid(socialAccountUid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialAccountUid, loginProcessStatus])

  const changeValueWithAccountUid = (accountUid: string) => {
    props.onChange({
      mode: 'raw',
      raw: accountUid,
    })
    props.onLoggedIn && props.onLoggedIn()
  }

  const handleLoginClick = () => {
    if (props.readonly) {
      return
    }
    const tokenBasedProviderDefinition = definitions.findTokenBaseProvider(
      props.definition.provider
    )
    if (tokenBasedProviderDefinition !== undefined) {
      setAccountFormShown(true)
    } else {
      startLoginProcess(props.definition.provider)
    }
  }

  return (
    <>
      <Modal
        isOpened={accountFormShown}
        onClose={() => setAccountFormShown(false)}
      >
        <_Inner width={550}>
          <AccountForm
            provider={props.definition.provider}
            onCancel={() => setAccountFormShown(false)}
            onAccountCreated={(createdAccountUid) => {
              changeValueWithAccountUid(createdAccountUid)
              setAccountFormShown(false)
            }}
          />
        </_Inner>
      </Modal>
      <div style={{ display: 'flex', width: '100%' }}>
        <WidgetContainer>
          <InputWidget {...props} definition={props.definition.valueForm} />
        </WidgetContainer>
        <AddButton disabled={props.readonly} onClick={handleLoginClick}>
          <Plus size={20} color={vars.color.icon} />
        </AddButton>
      </div>
    </>
  )
}

const _Inner = styled(Inner)({
  padding: vars.space.m,
})

const WidgetContainer = styled('div')({
  flexGrow: 1,
  // minWidth: 0 がないと、SelectWidget のときに truncated が効かない
  minWidth: 0,
})

const AddButton = styled('div')(
  {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: vars.space.s,
    width: vars.height.field,
    height: vars.height.field,
    backgroundColor: vars.color.white,
    ...widgetBorderStyle,
  },
  (props: { disabled: boolean }) => ({
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    opacity: props.disabled ? 0.5 : 1,
  })
)

export default AccountWidget
