import styled from '@emotion/styled'
import React, { CSSProperties } from 'react'
import { X } from 'react-feather'

import * as vars from '~/styles/variables'

const buttonSize = 30

interface Props {
  disabled?: boolean
  onClick: () => void
  style?: CSSProperties
}

const NodeDeleteButton: React.FC<Props> = ({ disabled, onClick, style }) => {
  const handleClick = React.useCallback(() => {
    if (disabled) {
      return
    }
    onClick()
  }, [disabled, onClick])
  return (
    <Container disabled={disabled ?? false} style={style} onClick={handleClick}>
      <X size={16} color={vars.fontColor.tertiary} strokeWidth={3} />
    </Container>
  )
}

const Container = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: buttonSize,
    height: buttonSize,
    borderRadius: buttonSize / 2,
    backgroundColor: vars.color.lightGray,
  },
  (props: { disabled: boolean }) => ({
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    opacity: props.disabled ? 0.5 : 0.7,
    '&:hover': {
      opacity: props.disabled ? 0.5 : 1,
    },
  })
)

export default NodeDeleteButton
