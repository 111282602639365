import styled from '@emotion/styled'
import * as React from 'react'

import Pill from '~/components/atoms/Pill'
import { FunctionCardProps } from '~/components/molecules/HoverCard/types'
import HoverCardTarget from '~/components/organisms/HoverCardTarget/HoverCardTarget'
import { ValueType } from '~/domain/ValueType'
import { RenderableEntity } from '~/domain/workflow/source/RenderableElement'
import { FunctionsTab } from '~/domain/workflow/variableFinder/FunctionsTab'
import { TypeMismatchTooltip } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/tooltips'
import { EntityType } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/types'
import * as vars from '~/styles/variables'

interface Props {
  data: FunctionsTab
  targetValueTypes: ValueType[]
  onSelect: (entities: RenderableEntity[]) => void
}

const FunctionsTabContent: React.FC<Props> = (props) => {
  return (
    <Section>
      <Heading>関数</Heading>
      <PillList>
        {props.data.functions.map((func) => {
          const enabled = props.targetValueTypes.some((targetValueType) =>
            ValueType.matchLoose(targetValueType, func.returnType)
          )
          const cardProps: FunctionCardProps = {
            type: 'function',
            function: func,
          }
          const handleClick = () => {
            if (!enabled) {
              return
            }
            props.onSelect([
              { type: EntityType.FUNCTION_CALL_START, functionId: func.id },
              { type: EntityType.CALL_END },
            ])
          }
          return (
            <PillItem key={func.id}>
              <TypeMismatchTooltip disabled={enabled}>
                <HoverCardTarget card={cardProps} disabled={!enabled}>
                  <Pill disabled={!enabled} onClick={handleClick}>
                    {func.name}()
                  </Pill>
                </HoverCardTarget>
              </TypeMismatchTooltip>
            </PillItem>
          )
        })}
      </PillList>
    </Section>
  )
}

const Section = styled('div')({
  marginTop: vars.space.l,
  '&:first-of-type': {
    marginTop: 0,
  },
})

const Heading = styled('h3')({
  display: 'flex',
  alignItems: 'center',
  fontSize: vars.fontSize.s,
  fontWeight: vars.fontWeight.bold,
  marginBottom: vars.space.m,
})

const PillList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
})

const PillItem = styled('div')({
  marginTop: vars.space.s,
})

export default FunctionsTabContent
