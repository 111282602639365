import { mapJsonToFieldDefinition } from '~/data/workflow/field/mapJsonToFieldDefinition'
import { TriggerJson } from '~/data/workflow/trigger/TriggerJson'
import { TriggerDefinition } from '~/domain/workflow/trigger/TriggerDefinition'

export function mapJsonToTriggerDefinition(
  json: TriggerJson
): TriggerDefinition {
  return {
    triggerId: json.id,
    name: json.name,
    appId: json.appId,
    description: json.description,
    testable: true,
    fields: json.fields.map(mapJsonToFieldDefinition),
  }
}
