import * as d3 from 'd3'
import * as React from 'react'
import { useDispatch } from 'react-redux'

import { emptyNodeSize } from '~/components/molecules/EmptyNode'
import Links from '~/components/organisms/NodeLinks'
import * as actionSelectorDuck from '~/ducks/ui/actionSelector'
import * as editorUiDuck from '~/ducks/ui/editor'
import { Node } from '~/hooks/useTree'
import Nodes from '~/presentation/workflow/detail/editor/Nodes'
import { useWorkflowEditorContext } from '~/presentation/workflow/detail/editor/WorkflowEditorContext'

interface Props {
  nodes: d3.HierarchyPointNode<Node>[]
  links: d3.HierarchyPointLink<Node>[]
  selectedTaskId?: string
  readonly: boolean
  onNodeClick: (nodeId: string, actionId: string) => void
  onTriggerNodeClick: (triggerId: string) => void
}

const Tree: React.FC<Props> = ({
  nodes,
  links,
  selectedTaskId,
  readonly,
  onNodeClick,
  onTriggerNodeClick,
}) => {
  const { isEditorLoading, addEmptyTask } = useWorkflowEditorContext()
  const dispatch = useDispatch()
  const [treeRef, setTreeRef] = React.useState<SVGGElement | null>(null)

  // @see: https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
  React.useEffect(() => {
    // 初回
    setTreeSize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeRef])

  React.useEffect(() => {
    // 次回以降、nodesが変更されたとき
    setTreeSize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes])

  function setTreeSize() {
    if (treeRef) {
      const rect = treeRef.getBoundingClientRect()
      dispatch(
        editorUiDuck.actions.setTreeSize({
          width: rect.width,
          height: rect.height,
        })
      )
    }
  }

  return (
    <g ref={setTreeRef}>
      <Links
        links={links}
        readonly={readonly}
        onAddButtonClick={(e, parentTask) => {
          if (parentTask && !isEditorLoading) {
            const newTaskId = addEmptyTask(parentTask.taskId)
            const centerX = window.innerWidth / 2
            const centerY = window.innerHeight / 2
            // センタリング
            const rect = e.currentTarget.getBoundingClientRect()
            const dx = centerX - (rect.left + emptyNodeSize)
            const dy = centerY - (rect.top + emptyNodeSize / 2)
            dispatch(editorUiDuck.actions.addTx(dx))
            dispatch(editorUiDuck.actions.addTy(dy))
            // ActionSelector 表示
            dispatch(
              actionSelectorDuck.actions.show({
                taskId: newTaskId,
                top: centerY - emptyNodeSize,
                left: centerX - emptyNodeSize / 2,
              })
            )
          }
        }}
      />
      <Nodes
        nodes={nodes}
        selectedTaskId={selectedTaskId}
        readonly={readonly}
        onNodeClick={onNodeClick}
        onTriggerNodeClick={onTriggerNodeClick}
      />
    </g>
  )
}

export default Tree
