import styled from '@emotion/styled'
import * as React from 'react'
import { ChevronLeft } from 'react-feather'
import { animated, useSpring } from 'react-spring'

import { StandardTrigger } from '~/common/constants'
import { SubmitStatus } from '~/common/types'
import SwitchButton from '~/components/atoms/Switch'
import ExecButton from '~/components/molecules/ExecButton'
import { Workflow } from '~/domain/workflow/Workflow'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import * as vars from '~/styles/variables'

interface Props {
  isEditingMode: boolean
  workflow: Workflow
  enableStatus: SubmitStatus
  onExec: (workflowId: string, name: string) => void
  onBack: () => void
  onEnabledChange: (value: boolean) => void
}

const EditorHeader: React.FC<Props> = ({
  isEditingMode,
  workflow,
  enableStatus,
  onExec,
  onBack,
  onEnabledChange,
}) => {
  const definitions = useDefinitions()
  const headerProps = useSpring({
    to: async (next: any) => {
      if (isEditingMode) {
        await next({ display: 'block' })
        await next({
          opacity: 1,
          transform: 'translateX(0)',
        })
      } else {
        await next({
          opacity: 0,
          transform: 'translateX(20px)',
        })
        await next({ display: 'none' })
      }
    },
    from: {
      display: 'none',
      opacity: 0,
      transform: 'translateX(20px)',
    },
  })
  const source = workflow.source
  const trigger = source.body.getTrigger()
  const hasDeletedAction = source?.hasDeletedAction(definitions)

  return (
    <Container>
      <animated.div style={headerProps}>
        <BackButton onClick={onBack}>
          <ChevronLeft color={vars.fontColor.secondary} />
          <Label>{workflow.name}</Label>
        </BackButton>
      </animated.div>
      <animated.div style={headerProps}>
        {trigger &&
          (trigger.triggerId === StandardTrigger
            ? workflow.permissionPolicy.executable && (
                <_ExecButton
                  disabled={hasDeletedAction}
                  onClick={() => {
                    onBack()
                    onExec(workflow.workflowId, workflow.name)
                  }}
                />
              )
            : workflow.permissionPolicy.updatable && (
                <SwitchButton
                  value={workflow.isEnabled}
                  disabled={!workflow.isEnabled && hasDeletedAction}
                  loading={enableStatus === SubmitStatus.submitting}
                  onChange={onEnabledChange}
                />
              ))}
      </animated.div>
    </Container>
  )
}

const Container = styled('div')({
  position: 'fixed',
  top: vars.space.l,
  left: vars.width.sidebarCollapsed + vars.space.l,
  width: `calc(100% - ${vars.width.sidebarCollapsed + vars.space.l}px - ${
    vars.space.l
  }px)`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const BackButton = styled('div')({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  opacity: 0.6,
  '&:hover': {
    opacity: 1,
  },
})

const Label = styled('div')({
  marginLeft: vars.space.s,
  maxWidth: 400,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const _ExecButton = styled(ExecButton)({
  width: 80,
  height: 38,
  borderRadius: 19,
})

export default EditorHeader
