import * as React from 'react'

import { ensure } from '~/common/utils'
import { CompositeValidator } from '~/domain/workflow/validator/CompositeValidator'

export interface ValidationContextInterface {
  validator?: CompositeValidator
}

export const ValidationContext = React.createContext<
  ValidationContextInterface
>({})

export function useValidationContext(): Required<ValidationContextInterface> {
  const context = React.useContext<ValidationContextInterface>(
    ValidationContext
  )
  return {
    validator: ensure(
      context.validator,
      'validator is undefined. Out of context scope?'
    ),
  }
}
