import styled from '@emotion/styled'
import * as React from 'react'
import { animated, useTransition } from 'react-spring'

import Link from '~/components/atoms/Link'
import { Recipe } from '~/domain/recipe/Recipe'
import RecipeItem from '~/presentation/recipe/RecipeItem'
import { RECIPE_DETAIL } from '~/routes'
import * as vars from '~/styles/variables'

interface Props {
  recipes: Recipe[]
}

const Content: React.FC<Props> = ({ recipes }) => {
  const transitions = useTransition(recipes, (recipe) => recipe.id, {
    trail: 100,
    from: { opacity: 0, transform: 'translateY(20px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(20px)' },
  })
  return (
    <Container>
      {transitions.map(({ item, key, props }) => (
        <Item key={key} style={props}>
          <_Link
            key={item.id}
            to={RECIPE_DETAIL}
            params={{
              recipeId: item.id,
            }}
          >
            <RecipeItem
              name={item.name}
              description={item.description}
              available={true}
              sourceBody={item.source}
            />
          </_Link>
        </Item>
      ))}
    </Container>
  )
}

const gap = vars.space.m * 1.5

const Container = styled('div')({
  marginLeft: -1 * gap,
  display: 'flex',
  flexWrap: 'wrap',
})

const Item = styled(animated.div)({
  float: 'left',
  paddingTop: gap,
  paddingLeft: gap,
  width: 340,
})

const _Link = styled(Link)({
  color: vars.fontColor.primary,
  textDecoration: 'none',
})

export default Content
