import { JsonError } from '~/data/JsonError'
import { RunningStatus } from '~/domain/workflow/instance/RunningStatus'

export function mapToRunningStatus(status: string): RunningStatus {
  switch (status) {
    case 'stop':
      return RunningStatus.stop
    case 'queueing':
      return RunningStatus.queueing
    case 'running':
      return RunningStatus.running
    case 'success':
      return RunningStatus.success
    case 'error':
      return RunningStatus.error
    default:
      throw new JsonError(`Unknown running status: ${status}`)
  }
}
