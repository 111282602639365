import styled from '@emotion/styled'

import * as vars from '~/styles/variables'

/* Common */

export const Container = styled('div')({
  backgroundColor: 'white',
})

export const Inner = styled('div')({
  maxWidth: vars.width.m,
  margin: '0 auto',
  paddingRight: vars.space.m,
  paddingLeft: vars.space.m,
})

export const Button = styled('a')(
  {
    display: 'inline-block',
    paddingTop: vars.space.s * 1.3,
    paddingRight: vars.space.l,
    paddingBottom: vars.space.s * 1.3,
    paddingLeft: vars.space.l,
    color: vars.color.white,
    fontSize: vars.fontSize.s,
    fontWeight: vars.fontWeight.bold,
    textAlign: 'center',
    textDecoration: 'none',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
    borderRadius: vars.borderRadius.s,
    border: 'none',
    cursor: 'pointer',
    transition: 'all .14s ease',
    '&:hover': {
      opacity: 0.9,
      boxShadow: '0 7px 14px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.08)',
      transform: 'translateY(-2px)',
    },
  },
  (props: { color?: string }) => ({
    backgroundColor: props.color ? props.color : vars.color.blue,
  })
)
