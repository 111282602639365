import styled from '@emotion/styled'
import React from 'react'

import { PendingResult } from '~/common/types'
import { assertNever } from '~/common/utils'
import Button from '~/components/atoms/Button'
import Loader from '~/components/atoms/Loader'
import Text from '~/components/atoms/Text'
import App from '~/components/molecules/App'
import { InputSource } from '~/domain/workflow/source/WorkflowSourceBody'
import { TestTarget } from '~/domain/workflow/test/TestTarget'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import { NodeTestPreview } from '~/presentation/workflow/detail/editor/test/NodeTestPreview'
import { useTestPreviewService } from '~/presentation/workflow/detail/editor/test/useTestPreviewService'
import { scrollbarStyle } from '~/styles/scrollbar'
import * as vars from '~/styles/variables'

const NodeTestConfirm: React.FC<{
  target: TestTarget
  inputs: InputSource[]
  useBulk: boolean
  running: boolean
  onCloseClick: () => void
  onRunClick: () => void
}> = (props) => {
  const definitions = useDefinitions()
  const testPreviewResult = useTestPreviewService(
    props.target,
    props.inputs,
    props.useBulk
  )

  const triggerOrAction = React.useMemo(() => {
    switch (props.target.kind) {
      case 'trigger':
        return definitions.getTrigger(props.target.triggerId)
      case 'task':
        return definitions.getAction(props.target.actionId)
      default:
        assertNever(props.target)
    }
  }, [props.target, definitions])

  const app = React.useMemo(() => {
    switch (props.target.kind) {
      case 'trigger':
        return definitions.getAppByTriggerId(props.target.triggerId)
      case 'task':
        return definitions.getAppByActionId(props.target.actionId)
      default:
        assertNever(props.target)
    }
  }, [props.target, definitions])

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <App
          icon={app.iconPath}
          color={app.color}
          size="m"
          style={{ marginRight: vars.space.m }}
        />
        <Text element="p" fontSize="l" lineHeight="heading" fontWeight="bold">
          {triggerOrAction.name} が実行できるかテストします
        </Text>
      </div>
      <Text element="p" fontSize="s" style={{ marginTop: vars.space.l }}>
        以下の入力値でテスト実行します。実際に実行されますので、入力値をご確認のうえ実行してください。
      </Text>
      <InputsPreviewBox>
        {testPreviewResult.status === PendingResult.Status.pending ? (
          <Loader />
        ) : testPreviewResult.status === PendingResult.Status.failure ? (
          <Text element="p" fontSize="s" lineHeight="just">
            エラーが発生しました
          </Text>
        ) : (
          <NodeTestPreview testPreview={testPreviewResult.data} />
        )}
      </InputsPreviewBox>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: vars.space.l,
        }}
      >
        <Button type="tertiary" onClick={props.onCloseClick}>
          閉じる
        </Button>
        <Button
          type="primary"
          onClick={props.onRunClick}
          loading={props.running}
          disabled={
            testPreviewResult.status !== PendingResult.Status.success ||
            !testPreviewResult.data.success
          }
        >
          実行
        </Button>
      </div>
    </div>
  )
}

const InputsPreviewBox = styled('div')({
  height: 230,
  marginTop: vars.space.l,
  backgroundColor: vars.colorPalette.gray0,
  borderRadius: vars.borderRadius.m,
  padding: vars.space.m * 1.5,
  overflow: 'scroll',
  ...scrollbarStyle,
})

export default NodeTestConfirm
