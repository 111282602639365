import styled from '@emotion/styled'
import produce from 'immer'
import * as React from 'react'

import { run } from '~/common/utils'
import ErrorMessage from '~/components/atoms/ErrorMessage'
import { ValueType } from '~/domain/ValueType'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { ValidationResult } from '~/domain/workflow/validator/ValidationResult'
import { StructWidgetDefinition } from '~/domain/workflow/widget/struct'
import FieldLabel from '~/presentation/workflow/detail/editor/form/field/FieldLabel'
import InputWidget from '~/presentation/workflow/detail/editor/form/inputWidget/InputWidget'
import { RawInputWidgetProps } from '~/presentation/workflow/detail/editor/form/inputWidget/RawInputWidget'
import { useInputWidgetValidation } from '~/presentation/workflow/detail/editor/form/validation/useValidation'
import * as vars from '~/styles/variables'

interface Props extends RawInputWidgetProps {
  definition: StructWidgetDefinition
}

interface EntryValues {
  [key: string]: InputValue | undefined
}

interface EntryValueTypes {
  [key: string]: ValueType | undefined
}

const StructWidget: React.FC<Props> = (props) => {
  // バリデーション
  const validationResult = useInputWidgetValidation(props)

  const changeEntryValue = (targetKey: string, newValue: InputValue) => {
    const newEntryValues = produce(entryValues, (draft) => {
      draft[targetKey] = newValue
    })
    const rawValue = transformEntryValuesToRawStructEntries(
      newEntryValues
    ).filter((it) => it.value !== undefined)
    if (rawValue.length === 0) {
      props.onChange(undefined)
    } else {
      props.onChange({
        mode: 'raw',
        raw: rawValue,
      })
    }
  }

  const entryValueTypes: EntryValueTypes = run(() => {
    if (props.valueType.typeName !== 'struct') {
      throw new Error(
        `StructWidget requires StructValueType. But was: ${props.valueType.typeName}`
      )
    }
    const types: EntryValueTypes = {}
    props.valueType.entries.forEach((entry) => {
      types[entry.key] = entry.valueType
    })
    return types
  })

  const entryValues: EntryValues = run(() => {
    if (props.value === undefined) {
      return {}
    }
    if (!InputValue.isStructEntryList(props.value.raw)) {
      console.warn(
        `Value of specified value is not StructEntry[]. Using {} instead.`
      )
      return {}
    }
    const values: EntryValues = {}
    props.value.raw.forEach((entry) => {
      values[entry.key] = entry.value
    })
    return values
  })

  return (
    <Container>
      {props.definition.entries.map((entry) => {
        const valueType = entryValueTypes[entry.key]
        if (valueType === undefined) {
          throw new Error(`ValueType for key '${entry.key}' is not defined`)
        }
        return (
          <Item key={entry.key}>
            <_FieldLabel fontWeight="normal">{entry.label}</_FieldLabel>
            <InputWidget
              {...props}
              definition={entry.valueForm}
              valueType={valueType}
              value={entryValues[entry.key]}
              onChange={(newValue) => changeEntryValue(entry.key, newValue)}
              onBulkSizeChange={() => {}}
            />
          </Item>
        )
      })}
      {/* Required エラーの場合はエラーメッセージを表示しない */}
      {validationResult?.valid === false &&
        !(validationResult.cause instanceof ValidationResult.Required) && (
          <ErrorMessage style={{ marginTop: vars.space.s }}>
            {validationResult.cause.message}
          </ErrorMessage>
        )}
    </Container>
  )
}

const Container = styled('div')({
  width: '100%',
  paddingLeft: vars.space.m,
  borderLeft: `2px solid ${vars.color.border}`,
})

const _FieldLabel = styled(FieldLabel)({
  paddingRight: 80, // 変数切り替えスイッチを避けるため
})

const Item = styled('div')({
  marginTop: vars.space.m,
  '&:first-of-type': {
    marginTop: 0,
  },
})

export default StructWidget

function transformEntryValuesToRawStructEntries(
  entryValues: EntryValues
): InputValue.Raw.StructEntry[] {
  return Object.keys(entryValues).map((key) => {
    return { key, value: entryValues[key] }
  })
}
