import styled from '@emotion/styled'
import * as React from 'react'
import { animated, useSpring } from 'react-spring'

import * as styles from '~/containers/Home/styles'
import * as routes from '~/routes'
import * as vars from '~/styles/variables'

const Header = () => {
  const [scrollTop, setScrollTop] = React.useState(0)
  const [isFixed, setIsFixed] = React.useState(false)
  const props = useSpring({
    from: { transform: 'translateY(-30px)', opacity: 0 },
    to: {
      transform: isFixed ? 'translateY(0)' : `translateY(-${scrollTop}px)`,
      boxShadow: isFixed ? vars.shadow.m : vars.shadow.none,
      opacity: 1,
    },
  })

  React.useEffect(() => {
    function handleScroll() {
      const documentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop
      const triggerPosition = window.innerHeight / 2
      const triggered = triggerPosition < documentScrollTop
      setIsFixed(triggered)
      if (!triggered) setScrollTop(documentScrollTop)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Container style={props}>
      <styles.Inner
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
          zIndex: 1,
        }}
      >
        <LogoAnchor href="#">
          <LogoImg src="/static/images/logo.svg" alt="Anyflow" width={155} />
        </LogoAnchor>
        <nav>
          <NavList>
            <NavItem>
              <NavLink href="#usecases">Anyflowとは</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#features">特徴</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#apps">SaaS一覧</NavLink>
            </NavItem>
            <NavItem style={{ marginLeft: vars.space.m }}>
              <HeaderButton
                href={routes.SIGNIN}
                color={vars.color.white}
                style={{ color: vars.color.blue }}
              >
                サインイン
              </HeaderButton>
            </NavItem>
          </NavList>
        </nav>
      </styles.Inner>
    </Container>
  )
}

const Container = styled(animated.header)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: vars.height.lpHeader,
  backgroundColor: vars.color.white,
  zIndex: 1000,
})

const LogoAnchor = styled('a')({})

const LogoImg = styled('img')({
  display: 'block',
})

const NavList = styled('ul')({
  display: 'flex',
  alignItems: 'center',
  margin: 0,
  padding: 0,
  listStyle: 'none',
  [`${vars.media.tablet}`]: {
    display: 'none',
  },
})

const NavItem = styled('li')({
  marginLeft: vars.space.l,
})

const NavLink = styled('a')({
  fontSize: vars.fontSize.s,
  fontWeight: vars.fontWeight.bold,
  color: vars.fontColor.primary,
  textDecoration: 'none',
})

const HeaderButton = styled(styles.Button)({
  paddingTop: vars.space.s,
  paddingRight: vars.space.m,
  paddingBottom: vars.space.s,
  paddingLeft: vars.space.m,
  fontSize: vars.fontSize.xs,
})

export default Header
