import styled from '@emotion/styled'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  AnimatedValue,
  ForwardedProps,
  animated,
  useSpring,
} from 'react-spring'

import { actionNodeSize } from '~/components/molecules/ActionNode'
import NodeDeleteButton from '~/components/molecules/NodeDeleteButton'
import ForeignObject from '~/components/utils/ForeignObject'
import { TaskSource } from '~/domain/workflow/source/WorkflowSourceBody'
import * as actionSelectorDuck from '~/ducks/ui/actionSelector'
import * as editorUiDuck from '~/ducks/ui/editor'
import * as vars from '~/styles/variables'

export const emptyNodeSize = 150

interface Props {
  task: TaskSource
  x: number
  y: number
  animatedValue?: AnimatedValue<ForwardedProps<React.CSSProperties>>
  isEditorLoading?: boolean
  disabled?: boolean
  onRemoveClick?: () => void
}

const EmptyNode: React.FunctionComponent<Props> = ({
  task,
  x,
  y,
  animatedValue,
  isEditorLoading,
  disabled,
  onRemoveClick,
}) => {
  const dispatch = useDispatch()
  const tx = useSelector(editorUiDuck.selectors.getTx)
  const ty = useSelector(editorUiDuck.selectors.getTy)

  const [hovering, setHovering] = React.useState(false)
  const removeButtonProps = useSpring({
    to: {
      opacity: hovering ? 1 : 0,
      transform: hovering ? 'scale(1)' : 'scale(0.7)',
    },
    config: { tension: 500 },
  })

  function show(top: number, left: number) {
    dispatch(
      actionSelectorDuck.actions.show({
        taskId: task.taskId,
        top,
        left,
      })
    )
  }

  function centerNode(e: React.MouseEvent) {
    // クリックしたノードが中央になるようにセンタリング
    const rect = e.currentTarget.getBoundingClientRect()
    const centerX = window.innerWidth / 2
    const centerY = window.innerHeight / 2

    const dx = centerX - (rect.left + emptyNodeSize / 2)
    // -50は見た目の微調整
    const dy = centerY - (rect.top + emptyNodeSize / 2) - 50

    dispatch(editorUiDuck.actions.setTx(tx + dx))
    dispatch(editorUiDuck.actions.setTy(ty + dy))

    // 表示
    show(centerY - emptyNodeSize, centerX - emptyNodeSize / 2)
  }

  return isEditorLoading ? (
    renderLoader(y, x)
  ) : (
    <g
      transform={`translate(${x}, ${y})`}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <animated.g
        style={{
          ...(animatedValue || {}),
          transformOrigin: `${emptyNodeSize / 2}px ${emptyNodeSize / 2}px`,
        }}
      >
        <Node
          cx={emptyNodeSize / 2}
          cy={emptyNodeSize / 2}
          r={emptyNodeSize / 2}
          disabled={disabled ?? false}
          onClick={(e: React.MouseEvent) => {
            if (!disabled && !isEditorLoading) {
              centerNode(e)
            }
          }}
        />
        <QuestionMark x={emptyNodeSize / 2} y={emptyNodeSize / 2 + 26}>
          ?
        </QuestionMark>
        {onRemoveClick ? (
          <g transform={`translate(${emptyNodeSize - 35}, -10)`}>
            <ForeignObject width={50} height={50}>
              {/* ホバー可能範囲を広げるための div */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: 50,
                  height: 50,
                }}
              >
                <animated.div
                  style={{
                    transformOrigin: `15px 15px`,
                    ...removeButtonProps,
                  }}
                >
                  <NodeDeleteButton
                    disabled={disabled}
                    onClick={onRemoveClick}
                  />
                </animated.div>
              </div>
            </ForeignObject>
          </g>
        ) : null}
      </animated.g>
    </g>
  )
}

function renderLoader(top: number, left: number) {
  const _left = left + actionNodeSize / 2 - 50
  const _top = top + actionNodeSize / 2 - 50
  return (
    <g transform={`translate(${_left}, ${_top})`}>
      <path
        fill="rgba(0, 0, 0, 0.2)"
        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1s"
          from="0 50 50"
          to="360 50 50"
          repeatCount="indefinite"
        />
      </path>
    </g>
  )
}

const Node = styled('circle')(
  {
    fill: vars.color.white,
    stroke: vars.color.border,
    strokeWidth: 1,
  },
  (props: { disabled: boolean }) => ({
    cursor: props.disabled ? 'not-allowed' : 'pointer',
  })
)

const QuestionMark = styled('text')({
  fill: vars.fontColor.tertiary,
  fontSize: 80,
  textAnchor: 'middle',
  pointerEvents: 'none',
})

export default EmptyNode
