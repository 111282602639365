import {
  JinjaCall,
  JinjaGetattr,
  JinjaNode,
  JinjaTemplate,
} from '~/common/types/jinja'
import * as utils from '~/common/utils'
import { ExpectedObjectKey } from '~/domain/workflow/object/ExpectedObjectKey'
import { RenderableElement } from '~/domain/workflow/source/RenderableElement'
import { EntityType } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/types'
import {
  getPlainTextFromJinjaNode,
  primitiveToString,
} from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/utils'

/**
 * Jinja の AST から Draft.js のエンティティを適用
 * @param ast
 */
export function parseJinjaTemplate(ast: JinjaTemplate): RenderableElement[] {
  const results: RenderableElement[] = []
  ast.body.forEach((outputNode) => {
    outputNode.nodes.forEach((node) => {
      const res = search(node)
      if (res !== undefined) {
        results.push(...res)
      }
    })
  })
  return results
}

function search(node: JinjaNode): RenderableElement[] | undefined {
  switch (node.typ) {
    case 'Const': {
      if (typeof node.value === 'boolean' || node.value === null) {
        return [
          {
            type: EntityType.PRIMITIVE,
            label: primitiveToString(node.value),
          },
        ]
      } else {
        return [primitiveToString(node.value)]
      }
    }
    case 'Name': {
      return [
        {
          type: EntityType.PRIMITIVE,
          label: node.name,
        },
      ]
    }
    case 'Dict':
    case 'List': {
      return [
        {
          type: EntityType.UNSUPPORTED,
          label: getPlainTextFromJinjaNode(node),
        },
      ]
    }
    case 'Add':
    case 'Sub':
    case 'Mul':
    case 'Div':
    case 'FloorDiv':
    case 'Pow':
    case 'Mod': {
      const operator =
        node.typ === 'Add'
          ? '+'
          : node.typ === 'Sub'
          ? '-'
          : node.typ === 'Mul'
          ? '*'
          : node.typ === 'Div'
          ? '/'
          : node.typ === 'FloorDiv'
          ? '//'
          : node.typ === 'Pow'
          ? '**'
          : node.typ === 'Mod'
          ? '%'
          : ''
      // 基本非推奨なので、Const と Getattr しか対応していない
      const left =
        node.left.typ === 'Const'
          ? node.left.value
          : node.left.typ === 'Getattr'
          ? resolveGetattr(node.left)
          : ''
      const right =
        node.right.typ === 'Const'
          ? node.right.value
          : node.right.typ === 'Getattr'
          ? resolveGetattr(node.right)
          : ''
      return [
        {
          type: EntityType.UNSUPPORTED,
          label: `${left}${operator}${right}`,
        },
      ]
    }
    case 'TemplateData': {
      return [node.data]
    }
    case 'Getattr': {
      const label = resolveGetattr(node)
      const parsed = utils.parseObjectProperty(label)
      return [
        {
          type: EntityType.PROPERTY,
          expectedObjectKey: ExpectedObjectKey.create(
            parsed.objKey ?? '???',
            parsed.tid ?? undefined
          ),
          propertyKey: utils.ensure(
            parsed.property,
            `Junja Getattr node has no propertyId. Parsed label: ${label}`
          ),
        },
      ]
    }
    case 'Call': {
      if (node.node.typ === 'Getattr') {
        // method
        const label = resolveGetattr(node.node)
        const parsed = utils.parseObjectMethod(label)
        return [
          {
            type: EntityType.METHOD_CALL_START,
            expectedObjectKey: ExpectedObjectKey.create(
              parsed.objKey ?? '???',
              parsed.tid ?? undefined
            ),
            methodKey: utils.ensure(
              parsed.methodName,
              `Jinja Getattr node has no methodId. Parsed label: ${label}`
            ),
          },
          ...parseArguments(node),
          {
            type: EntityType.CALL_END,
          },
        ]
      } else if (node.node.typ === 'Name') {
        // function
        return [
          {
            type: EntityType.FUNCTION_CALL_START,
            functionId: node.node.name,
          },
          ...parseArguments(node),
          {
            type: EntityType.CALL_END,
          },
        ]
      } else {
        // unknown, nothing to do
        return undefined
      }
    }
    default:
      return undefined
  }
}

function resolveGetattr(node: JinjaGetattr) {
  let name = ''
  if (node.node.typ === 'Name') {
    name = `${node.node.name}.${node.attr}`
  }
  return name
}

function parseArguments(node: JinjaCall): RenderableElement[] {
  const entities: RenderableElement[] = []
  node.args.forEach((arg, i) => {
    entities.push(...(search(arg) ?? []))
    // 最後のループでなければ
    if (node.args.length !== i + 1) {
      // カンマを追加
      entities.push(', ')
    }
  })
  return entities
}
