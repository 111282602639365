import styled from '@emotion/styled'
import * as React from 'react'

import AppIcon from '~/components/atoms/AppIcon'
import * as vars from '~/styles/variables'

interface OwnProps {
  icon: string
  color: string
  size?: 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl'
  className?: string
}

type Props = OwnProps & React.HTMLAttributes<HTMLDivElement>

const App: React.FunctionComponent<Props> = ({
  icon,
  color,
  size = 'm',
  className,
  ...props
}) => (
  <Container className={className} color={color} size={size} {...props}>
    <AppIcon icon={icon} size={size} />
  </Container>
)

const Container = styled('div')<Pick<Props, 'color' | 'size'>>(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  (props) => ({
    width:
      props.size === 'xl'
        ? 72
        : props.size === 'l'
        ? 60
        : props.size === 'm'
        ? 40
        : props.size === 's'
        ? 25
        : props.size === 'xs'
        ? 20
        : 15,
    height:
      props.size === 'xl'
        ? 72
        : props.size === 'l'
        ? 60
        : props.size === 'm'
        ? 40
        : props.size === 's'
        ? 25
        : props.size === 'xs'
        ? 20
        : 15,
    borderRadius:
      props.size === 'l' || props.size === 'm'
        ? vars.borderRadius.m
        : vars.borderRadius.s,
    backgroundColor: props.color,
  })
)

export default App
