import { assert, assertNever, run } from '~/common/utils'
import { ExpectedObjectApiImpl } from '~/data/workflow/expectedObject/ExpectedObjectApiImpl'
import { Definitions } from '~/domain/Definitions'
import { RecipeWizard } from '~/domain/recipe/RecipeWizard'
import { ActionDefinition } from '~/domain/workflow/action/ActionDefinition'
import { WorkflowNode } from '~/domain/workflow/source/WorkflowNode'
import { WorkflowNodeInputsValidator } from '~/domain/workflow/source/WorkflowNodeInputsValidator'
import {
  TaskSource,
  TriggerSource,
  WorkflowSourceBody,
} from '~/domain/workflow/source/WorkflowSourceBody'
import { TriggerDefinition } from '~/domain/workflow/trigger/TriggerDefinition'
import { ValidationResult } from '~/domain/workflow/validator/ValidationResult'

const expectedObjectApi = new ExpectedObjectApiImpl()

export class RecipeWizardStepValidator {
  constructor(
    private readonly wizard: RecipeWizard,
    private readonly definitions: Definitions
  ) {}

  async validate(stepNumber: number): Promise<ValidationResult> {
    const sourceBody = this.wizard.createWorkflowSourceBody()
    const step = this.wizard.getStep(stepNumber)
    const parentTaskId: string | undefined = run(() => {
      if (step.targetNode.kind === 'trigger') {
        return undefined
      }
      const parent = sourceBody.findParentTaskOf(step.targetNode.taskId)
      if (parent === undefined) {
        return 'trigger'
      }
      return parent.taskId
    })
    const [targetNodeDefinition, targetNodeSource] = getNodeDefinitionAndSource(
      sourceBody,
      step.targetNode,
      this.definitions
    )
    const expectedObjects = await expectedObjectApi.get(
      sourceBody,
      parentTaskId
    )
    const validator = new WorkflowNodeInputsValidator(
      this.definitions,
      expectedObjects
    )
    return validator.validate(targetNodeDefinition, targetNodeSource)
  }
}

function getNodeDefinitionAndSource(
  sourceBody: WorkflowSourceBody,
  targetNode: WorkflowNode,
  definitions: Definitions
): [TriggerDefinition | ActionDefinition, TriggerSource | TaskSource] {
  switch (targetNode.kind) {
    case 'trigger': {
      const trigger = sourceBody.getTrigger()
      assert(
        trigger !== undefined,
        'targetNode is trigger, but source has no trigger.'
      )
      return [definitions.getTrigger(trigger.triggerId), trigger]
    }
    case 'task': {
      const task = sourceBody.findTask(targetNode.taskId)
      assert(
        task !== undefined,
        `targetNode is task (${targetNode.taskId}), but no such task found.`
      )
      return [definitions.getAction(task.actionId), task]
    }
    default: {
      assertNever(targetNode)
    }
  }
}
