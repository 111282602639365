import styled from '@emotion/styled'
import dayjs from 'dayjs'
import React from 'react'
import * as Icon from 'react-feather'

import Button from '~/components/atoms/Button'
import Text from '~/components/atoms/Text'
import NoResults from '~/components/organisms/NoResults'
import { ValueType } from '~/domain/ValueType'
import { TestResult } from '~/domain/workflow/test/TestResult'
import ExpectedObjectList from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/ExpectedObjectList'
import { TestResultSnapshotsProvider } from '~/presentation/workflow/detail/editor/test/ExpectedObjectSnapshotsContext'
import { scrollbarStyle } from '~/styles/scrollbar'
import * as vars from '~/styles/variables'

const dummyValueTypes: ValueType[] = [
  {
    typeName: 'any',
    nullable: true,
  },
]

interface Props {
  result: TestResult
  onCloseClick: () => void
}

const NodeTestSuccess: React.FC<Props> = (props) => {
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            width: 40,
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: vars.space.m,
            borderRadius: '50%',
            backgroundColor: vars.color.success,
          }}
        >
          <Icon.Check color={vars.color.white} strokeWidth={4} size={22} />
        </div>
        <Text element="p" fontSize="l" fontWeight="bold" lineHeight="heading">
          テスト実行に成功しました！
        </Text>
      </div>
      <Text element="p" fontSize="s" style={{ marginTop: vars.space.m }}>
        テスト実行が成功しました！
      </Text>
      <Text element="p" fontSize="s" style={{ marginTop: vars.space.m }}>
        実行したことによって取得された値は以下のとおりです。取得した値は
        <strong>以降のアクションで変数の展開に使われます。</strong>
      </Text>
      <ResultSnapshotsBox>
        <TestResultSnapshotsProvider result={props.result}>
          {props.result.expectedObjects.length === 0 ? (
            <div
              style={{
                height: '100%',
                backgroundColor: vars.colorPalette.gray0,
                borderRadius: vars.borderRadius.m,
              }}
            >
              <NoResults
                heading="変数はありません"
                description="変数がある場合はここに表示されます"
              />
            </div>
          ) : (
            <>
              <ExpectedObjectList
                expectedObjects={props.result.expectedObjects}
                targetValueTypes={dummyValueTypes}
                defaultExpanded={true}
                onSelect={noop}
              />
              <div style={{ height: 100 }} />
            </>
          )}
        </TestResultSnapshotsProvider>
      </ResultSnapshotsBox>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: vars.space.m,
        }}
      >
        <Text element="p" fontSize="s">
          {dayjs(props.result.testedAt).fromNow()}に実行されました
        </Text>
        <Button type="tertiary" onClick={props.onCloseClick}>
          閉じる
        </Button>
      </div>
    </div>
  )
}

function noop() {}

const ResultSnapshotsBox = styled('div')({
  height: 230,
  marginTop: vars.space.m,
  overflowY: 'scroll',
  ...scrollbarStyle,
})

export default NodeTestSuccess
