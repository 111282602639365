import { isValidCron } from 'cron-validator'

import { InputValue } from '~/domain/workflow/source/InputValue'
import { ValidationResult } from '~/domain/workflow/validator/ValidationResult'
import { BaseWidgetDefinition } from '~/domain/workflow/widget/WidgetDefinition'
import { RawInputWidgetValidator } from '~/domain/workflow/widget/validator/RawInputWidgetValidator'

export interface CronWidgetDefinition extends BaseWidgetDefinition {
  formType: 'cron'
}

export class CronWidgetValidator extends RawInputWidgetValidator {
  async validate(inputValue: InputValue.Raw): Promise<ValidationResult> {
    if (typeof inputValue.raw !== 'string') {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat('不正な値が入力されています')
      )
    }
    if (!isValidCron(inputValue.raw)) {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat('不正なフォーマットで入力されています')
      )
    }
    return ValidationResult.valid()
  }
}
