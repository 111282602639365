import { ValueType } from '~/domain/ValueType'

export interface ArgumentDefinition {
  key: string
  position: number
  name: string
  type: ValueType
  description: string
  required: boolean
  // TODO: サーバーの対応が完了したら Optional をはずす
  isVariableLength?: boolean
}

export function argumentPositionComparator(
  left: ArgumentDefinition,
  right: ArgumentDefinition
): number {
  return left.position - right.position
}
