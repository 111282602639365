import styled from '@emotion/styled'
import React from 'react'

import Text from '~/components/atoms/Text'
import App from '~/components/molecules/App'
import { PropertyCardProps } from '~/components/molecules/HoverCard/types'
import WithRightTypeSign from '~/components/molecules/WithTypeSign/WithRightTypeSign'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { scrollbarStyle } from '~/styles/scrollbar'
import * as vars from '~/styles/variables'

const PropertyHoverCard: React.FC<PropertyCardProps> = (props) => {
  return (
    <Container>
      <WithRightTypeSign valueType={props.property.valueType}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {props.app !== undefined && (
            <App icon={props.app.iconPath} color={props.app.color} size="xs" />
          )}
          <Text
            element="p"
            fontSize="xs"
            fontWeight="bold"
            lineHeight="heading"
            style={{ marginLeft: vars.space.s }}
          >
            {props.expectedObjectName}の{props.property.name}
          </Text>
        </div>
      </WithRightTypeSign>
      {props.property.description !== '' && (
        <Text element="p" fontSize="xs" style={{ marginTop: vars.space.m }}>
          {props.property.description}
        </Text>
      )}
      {props.snapshotValue !== undefined && (
        <div style={{ marginTop: vars.space.m }}>
          <Text
            element="p"
            fontSize="xs"
            color="secondary"
            lineHeight="heading"
          >
            テスト実行で取得した値
          </Text>
          <Text element="p" fontSize="xs" style={{ marginTop: vars.space.s }}>
            {InputValue.stringify(props.snapshotValue)}
          </Text>
        </div>
      )}
      {props.sampleValue !== undefined && (
        <div style={{ marginTop: vars.space.m }}>
          <Text
            element="p"
            fontSize="xs"
            color="secondary"
            lineHeight="heading"
          >
            値の例
          </Text>
          <Text element="p" fontSize="xs" style={{ marginTop: vars.space.s }}>
            {InputValue.stringify(props.sampleValue)}
          </Text>
        </div>
      )}
    </Container>
  )
}

const Container = styled('div')({
  padding: vars.space.m,
  backgroundColor: vars.color.white,
  border: `1px solid ${vars.color.border}`,
  borderRadius: vars.borderRadius.m,
  boxShadow: vars.shadow.m,
  maxWidth: 400,
  minWidth: 300,
  maxHeight: 300,
  overflowY: 'scroll',
  ...scrollbarStyle,
})

export default PropertyHoverCard
