import styled from '@emotion/styled'
import produce from 'immer'
import * as React from 'react'
import { X } from 'react-feather'

import { run } from '~/common/utils'
import ErrorMessage from '~/components/atoms/ErrorMessage'
import Text from '~/components/atoms/Text'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { ListWidgetDefinition } from '~/domain/workflow/widget/list'
import InputWidget from '~/presentation/workflow/detail/editor/form/inputWidget/InputWidget'
import { RawInputWidgetProps } from '~/presentation/workflow/detail/editor/form/inputWidget/RawInputWidget'
import { useInputWidgetValidation } from '~/presentation/workflow/detail/editor/form/validation/useValidation'
import * as vars from '~/styles/variables'
import { widgetBorderStyle } from '~/styles/widget'

interface Props extends RawInputWidgetProps {
  definition: ListWidgetDefinition
}

const ListWidget: React.FC<Props> = (props) => {
  // React はリスト要素を描画する際に各子コンポーネントに key を要求するが、
  // このコンポーネントではリスト要素に付与すべき key として利用できる ID が
  // 存在しないため index を key として使用している。
  // しかしそのまま index を key として使用すると要素を追加、削除、並び替えを
  // した際に誤ったコンポーネントが再利用されて DOM の状態が期待するものと
  // 異なったものになってしまう可能性があるため、追加削除時にこの値を更新し、
  // かつこの値を key に使用することでそれを防止している。
  const [listKeyVersion, setListKeyVersion] = React.useState<number>(0)

  // バリデーション
  const validationResult = useInputWidgetValidation(props)

  // valueType のチェック
  const valueType = props.valueType
  if (valueType.typeName !== 'list') {
    throw new Error(
      `ListWidget requires ListValueType. But was: ${props.valueType.typeName}`
    )
  }

  const addNewItem = () => {
    if (props.readonly) {
      return
    }
    setListKeyVersion((old) => old + 1)
    props.onChange({
      mode: 'raw',
      raw: [...value, undefined],
    })
  }

  const removeItem = (i: number) => {
    const newValue = produce(value, (draft) => {
      draft.splice(i, 1)
    })
    setListKeyVersion((old) => old + 1)
    if (newValue.length === 0) {
      props.onChange(undefined)
    } else {
      props.onChange({ mode: 'raw', raw: newValue })
    }
  }

  const changeItemValue = (i: number, replaceValue: InputValue) => {
    const newValue = produce(value, (draft) => {
      draft[i] = replaceValue
    })
    props.onChange({ mode: 'raw', raw: newValue })
  }

  const value: InputValue[] = run(() => {
    if (props.value === undefined) {
      return []
    }
    if (!InputValue.isInputValueList(props.value.raw)) {
      return []
    }
    return props.value.raw
  })

  return (
    <Container hasBorder={value.length !== 0}>
      {value.map((item, i) => {
        return (
          <Item
            key={`${i}#${listKeyVersion}`}
            hasMargin={i !== 0}
            // hasBg={props.definition.itemForm.formType !== 'list'}
            hasBg={true}
          >
            <Text
              element="p"
              fontSize="s"
              lineHeight="heading"
              style={{ marginBottom: vars.space.s }}
            >
              項目 {i + 1}
            </Text>
            <WidgetContainer>
              <InputWidget
                {...props}
                definition={props.definition.itemForm}
                valueType={valueType.itemType}
                value={item}
                onChange={(newValue) => changeItemValue(i, newValue)}
                onBulkSizeChange={() => {
                  /* バルクサイズの確認もバリデーションとして行うようになったため、サブコンポーネントのバルクサイズを監視する必要はない */
                }}
              />
              {!props.readonly && (
                <X
                  color={vars.color.icon}
                  size={25}
                  onClick={() => removeItem(i)}
                  style={{
                    position: 'absolute',
                    top: vars.space.s * 1.5,
                    right: vars.space.s,
                    marginLeft: vars.space.s,
                    cursor: 'pointer',
                  }}
                />
              )}
            </WidgetContainer>
          </Item>
        )
      })}
      <AddButton
        disabled={props.readonly}
        hasMargin={value.length > 0}
        onClick={addNewItem}
      >
        項目を追加
      </AddButton>
      {validationResult?.valid === false && (
        <ErrorMessage style={{ marginTop: vars.space.s }}>
          {validationResult.cause.message}
        </ErrorMessage>
      )}
    </Container>
  )
}

const Container = styled('div')(
  {
    width: '100%',
  },
  (props: { hasBorder: boolean }) => ({
    paddingLeft: props.hasBorder ? vars.space.m : 0,
    borderLeft: props.hasBorder ? `2px solid ${vars.color.border}` : 'none',
  })
)

const Item = styled('div')(
  {
    paddingTop: vars.space.m,
    paddingRight: 40, // X ボタンがあるため
    paddingBottom: vars.space.m,
    paddingLeft: vars.space.m,
    borderRadius: vars.borderRadius.s,
  },
  (props: { hasMargin: boolean; hasBg: boolean }) => ({
    position: 'relative',
    marginTop: props.hasMargin ? vars.space.s : 0,
    backgroundColor: props.hasBg ? 'rgba(0, 0, 0, 0.03)' : 'transparent',
  })
)

const WidgetContainer = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
})

const AddButton = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: vars.height.field,
    color: vars.fontColor.tertiary,
    fontSize: vars.fontSize.s,
    fontWeight: vars.fontWeight.normal,
    backgroundColor: vars.color.white,
    ...widgetBorderStyle,
  },
  (props: { hasMargin: boolean; disabled: boolean }) => ({
    marginTop: props.hasMargin ? vars.space.s : 0,
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    opacity: props.disabled ? 0.5 : 1,
  })
)

export default ListWidget
