import * as React from 'react'

import { run } from '~/common/utils'
import ErrorMessage from '~/components/atoms/ErrorMessage'
import TextField from '~/components/atoms/TextField'
import { CronWidgetDefinition } from '~/domain/workflow/widget/cron'
import { RawInputWidgetProps } from '~/presentation/workflow/detail/editor/form/inputWidget/RawInputWidget'
import CronEditor from '~/presentation/workflow/detail/editor/form/inputWidget/cron/CronEditor'
import { useInputWidgetValidation } from '~/presentation/workflow/detail/editor/form/validation/useValidation'
import * as vars from '~/styles/variables'

// CronWidget が対応する有効なフォーマット
const availableCronPatterns = [
  /^\*\/([0-9]|[1-5][0-9]) \* \* \* \*$/,
  /^0 \*\/([0-9]|1[0-9]|2[0-3]) \* \* \*$/,
]

interface Props extends RawInputWidgetProps {
  definition: CronWidgetDefinition
}

const CronWidget: React.FC<Props> = (props) => {
  const validationResult = useInputWidgetValidation(props)

  const value: string | undefined = run(() => {
    if (props.value === undefined) {
      return undefined
    }
    if (typeof props.value.raw !== 'string') {
      return ''
    }
    return props.value.raw
  })

  const useFallback: boolean = React.useMemo(() => {
    if (value === undefined || value === '') {
      return false
    }
    // 対応している Cron フォーマットかどうかチェック
    if (availableCronPatterns.some((pattern) => pattern.test(value))) {
      return false
    } else {
      // フォーマットに一致しない場合、フォールバックを使う
      return true
    }
  }, [value])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {useFallback ? (
        <TextField
          value={value}
          hasError={validationResult?.valid === false}
          disabled={props.readonly}
          onChange={(e) => {
            props.onChange({
              mode: 'raw',
              raw: e.currentTarget.value,
            })
          }}
        />
      ) : (
        <CronEditor
          value={value}
          hasError={validationResult?.valid === false}
          disabled={props.readonly}
          onChange={(cronExpression) => {
            if (cronExpression === undefined) {
              props.onChange(undefined)
              return
            }
            props.onChange({
              mode: 'raw',
              raw: cronExpression,
            })
          }}
        />
      )}
      {validationResult?.valid === false && (
        <ErrorMessage style={{ marginTop: vars.space.s }}>
          {validationResult.cause.message}
        </ErrorMessage>
      )}
    </div>
  )
}

export default CronWidget
