import _ from 'lodash'
import React from 'react'

import { apiClients } from '~/common/apiClients'
import { FetchStatus } from '~/common/types'
import {
  TriggerInstructionParameters,
  TriggerInstructionService,
} from '~/domain/workflow/triggerInstruction/TriggerInstructionService'
import { ViewUnitDefinition } from '~/domain/workflow/viewUnit/ViewUnitDefinition'

const triggerInstructionService: TriggerInstructionService =
  apiClients.triggerInstructionService

export function useTriggerInstruction(
  triggerInstructionId: string,
  workflowId: string,
  params?: TriggerInstructionParameters
): {
  viewUnitDefinitions: ViewUnitDefinition[]
  fetchStatus: FetchStatus
} {
  const [status, setStatus] = React.useState<FetchStatus>(FetchStatus.none)
  const [values, setValues] = React.useState<ViewUnitDefinition[]>([])

  React.useEffect(() => {
    fetch(triggerInstructionId, params ?? {}, workflowId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerInstructionId, params, workflowId])

  const fetch = React.useMemo(() => {
    return _.debounce(
      (
        _triggerInstructionId: string,
        _params: TriggerInstructionParameters,
        _workflowId: string
      ) => {
        setStatus(FetchStatus.loading)
        triggerInstructionService
          .getList(_triggerInstructionId, _params, _workflowId)
          .then((res) => {
            setValues(res)
            setStatus(FetchStatus.loaded)
          })
          .catch((reason) => {
            console.error(reason)
            setStatus(FetchStatus.failed)
          })
      },
      500
    )
  }, [])

  return {
    viewUnitDefinitions: values,
    fetchStatus: status,
  }
}
