import styled from '@emotion/styled'
import _ from 'lodash'
import React from 'react'
import * as Icon from 'react-feather'

import Loader from '~/components/atoms/Loader'
import Text from '~/components/atoms/Text'
import Focusable from '~/components/common/Focusable'
import AssistItem from '~/components/molecules/AssistField/AssistItem'
import Dropdown, { DropdownItem } from '~/components/molecules/Dropdown'
import { useDynamicSelectOptions } from '~/components/molecules/DynamicSelectField/hooks'
import * as vars from '~/styles/variables'
import {
  widgetAnimationStyle,
  widgetBorderErrorStyle,
  widgetBorderStyle,
  widgetFocusedStyle,
} from '~/styles/widget'

interface Option {
  label: string
  value: string
}

interface Props {
  loadOptions: (
    query: string,
    pageToken?: string
  ) => Promise<{ options: Option[]; nextPageToken?: string }>
  loadLabel: (value: string) => Promise<string | undefined>
  value: string | undefined
  onChange: (newValue: string | undefined) => void
  hasBorder?: boolean
  hasError?: boolean
  disabled?: boolean
}

const DynamicSelectField: React.FC<Props> = (props) => {
  const [hasFocus, setHasFocus] = React.useState<boolean>(false)
  const [isLabelLoading, setIsLabelLoading] = React.useState<boolean>(false)
  const [isLabelError, setIsLabelError] = React.useState<boolean>(false)
  const [label, setLabel] = React.useState<string>()
  const [query, setQuery] = React.useState<string>('')
  const [tempQuery, setTempQuery] = React.useState<string>('')

  const dummyFocusableRef = React.useRef<HTMLDivElement>(null)

  const {
    options,
    loading: isOptionsLoading,
    load: loadMoreOptions,
  } = useDynamicSelectOptions(props.loadOptions, query)

  const debouncedSetQuery = React.useMemo(() => {
    return _.debounce((q: string) => {
      setQuery(q)
    }, 500)
  }, [])

  // ラベルの取得
  React.useEffect(() => {
    setIsLabelLoading(false)
    if (props.value === undefined) {
      return
    }
    let disposed = false
    setLabel(undefined)
    setIsLabelLoading(true)
    setIsLabelError(false)
    props
      .loadLabel(props.value)
      .then((res) => {
        if (disposed) {
          return
        }
        setLabel(res)
        setIsLabelError(false)
        setIsLabelLoading(false)
      })
      .catch((e) => {
        if (disposed) {
          return
        }
        console.error(e)
        setLabel(undefined)
        setIsLabelError(true)
        setIsLabelLoading(false)
      })
    return () => {
      disposed = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, props.loadLabel])

  const handleFocusChange = React.useCallback(
    (newFocus: boolean) => {
      if (props.disabled) {
        return
      }
      setHasFocus(newFocus)
    },
    [props.disabled]
  )

  const handleQueryChanged = React.useCallback(
    (newQuery: string) => {
      setTempQuery(newQuery)
      debouncedSetQuery(newQuery)
    },
    [debouncedSetQuery]
  )

  const handleClearClick = React.useCallback(() => {
    props.onChange(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onChange])

  const handleItemClick = React.useCallback(
    (item: DropdownItem) => {
      props.onChange(item.value)
      dummyFocusableRef.current?.focus()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChange]
  )

  const handleReachBottom = React.useCallback(() => {
    loadMoreOptions()
    // loadMoreOptions は参照が不変
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderDropdownItem = React.useCallback((item: DropdownItem) => {
    return <AssistItem item={item} />
  }, [])

  return (
    <Container disabled={props.disabled ?? false}>
      <Focusable tabFocusable={!hasFocus} onFocusChanged={handleFocusChange}>
        <LabelOrQueryField
          value={props.value}
          label={label}
          labelLoading={isLabelLoading}
          labelError={isLabelError}
          queryMode={hasFocus}
          query={tempQuery}
          onQueryChange={handleQueryChanged}
          hasBorder={props.hasBorder ?? true}
          hasError={props.hasError ?? false}
          disabled={props.disabled ?? false}
          onClearClick={handleClearClick}
        />
        <Dropdown
          emptyLabel="一致する項目が見つかりませんでした"
          items={options}
          isOpen={hasFocus}
          loading={isOptionsLoading}
          renderItem={renderDropdownItem}
          onItemClick={handleItemClick}
          onReachBottom={handleReachBottom}
        />
      </Focusable>
      <Focusable ref={dummyFocusableRef} />
    </Container>
  )
}

const LabelOrQueryField: React.FC<{
  hasBorder: boolean
  hasError: boolean
  disabled: boolean
  onClearClick: () => void
  label: string | undefined
  labelError: boolean
  labelLoading: boolean
  value: string | undefined
  queryMode: boolean
  query: string
  onQueryChange: (newQuery: string) => void
}> = (props) => {
  if (props.queryMode) {
    return (
      <Input
        value={props.query}
        onChange={(e) => props.onQueryChange(e.currentTarget.value)}
        hasBorder={props.hasBorder}
        hasError={false}
        autoFocus={true}
        disabled={props.disabled}
      />
    )
  }

  if (props.labelError) {
    return (
      <InputLike hasBorder={props.hasBorder} hasError={true}>
        <Text
          element="span"
          fontSize="m"
          lineHeight="heading"
          color={vars.fontColor.primary}
          style={{ flexGrow: 1, fontStyle: 'italic' }}
        >
          ラベルの取得に失敗しました
        </Text>
        {!props.disabled && (
          <Focusable
            onFocus={(e) => e.stopPropagation()}
            onBlur={(e) => e.stopPropagation()}
          >
            <ClearButton onClick={props.onClearClick}>
              <Icon.X size={20} color={vars.color.icon} />
            </ClearButton>
          </Focusable>
        )}
      </InputLike>
    )
  }

  if (props.labelLoading) {
    return (
      <InputLike hasBorder={props.hasBorder} hasError={false}>
        <Loader size="s" />
      </InputLike>
    )
  }

  if (props.value === undefined) {
    return (
      <InputLike hasBorder={props.hasBorder} hasError={props.hasError}>
        <Text
          element="span"
          fontSize="m"
          lineHeight="heading"
          color={vars.fontColor.tertiary}
        >
          選択してください
        </Text>
      </InputLike>
    )
  }

  if (props.label === undefined) {
    return (
      <InputLike hasBorder={props.hasBorder} hasError={true}>
        <Text
          element="span"
          fontSize="m"
          lineHeight="heading"
          color={vars.fontColor.primary}
          style={{ flexGrow: 1, fontStyle: 'italic' }}
        >
          無効な値です
        </Text>
        {!props.disabled && (
          <Focusable
            onFocus={(e) => e.stopPropagation()}
            onBlur={(e) => e.stopPropagation()}
          >
            <ClearButton onClick={props.onClearClick}>
              <Icon.X size={20} color={vars.color.icon} />
            </ClearButton>
          </Focusable>
        )}
      </InputLike>
    )
  }

  return (
    <InputLike hasBorder={props.hasBorder} hasError={false}>
      <div
        style={{
          flexGrow: 1,
          color: vars.fontColor.tertiary,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        <AssistItem
          item={{ label: props.label, value: props.value }}
          oneLine={true}
        />
      </div>
      {!props.disabled && (
        <Focusable
          onFocus={(e) => e.stopPropagation()}
          onBlur={(e) => e.stopPropagation()}
        >
          <ClearButton onClick={props.onClearClick}>
            <Icon.X size={20} color={vars.color.icon} />
          </ClearButton>
        </Focusable>
      )}
    </InputLike>
  )
}

const Container = styled('div')(
  {
    position: 'relative',
    display: 'block',
    width: '100%',
    backgroundColor: vars.color.white,
  },
  (props: { disabled: boolean }) => ({
    cursor: props.disabled ? 'not-allowed' : 'unset',
    opacity: props.disabled ? 0.5 : 1,
  })
)

const InputLike = styled('div')(
  {
    paddingRight: vars.space.m,
    paddingLeft: vars.space.m,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: vars.height.field,
    color: vars.fontColor.primary,
    fontSize: vars.fontSize.m,
    lineHeight: 1.5,
    outline: 'none',
    borderRadius: widgetBorderStyle.borderRadius,
    ...widgetAnimationStyle,
  },
  (props: { hasBorder: boolean; hasError: boolean }) => ({
    border: props.hasBorder
      ? props.hasError
        ? widgetBorderErrorStyle.border
        : widgetBorderStyle.border
      : 'none',
    ...(() =>
      props.hasBorder
        ? {
            '&:focus': {
              ...widgetFocusedStyle,
            },
          }
        : {})(),
  })
)

const Input = styled('input')(
  {
    paddingRight: vars.space.m,
    paddingLeft: vars.space.m,
    display: 'block',
    width: '100%',
    height: vars.height.field,
    color: vars.fontColor.primary,
    fontSize: vars.fontSize.m,
    lineHeight: 1.5,
    outline: 'none',
    borderRadius: widgetBorderStyle.borderRadius,
    ...widgetAnimationStyle,
    '&::placeholder': {
      color: vars.fontColor.tertiary,
    },
  },
  (props: { hasBorder: boolean; hasError: boolean }) => ({
    border: props.hasBorder
      ? props.hasError
        ? widgetBorderErrorStyle.border
        : widgetBorderStyle.border
      : 'none',
    ...(() =>
      props.hasBorder
        ? {
            '&:focus': {
              ...widgetFocusedStyle,
            },
          }
        : {})(),
  })
)

const ClearButton = styled('div')({
  height: vars.height.field,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export default DynamicSelectField
