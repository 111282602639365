import React, { CSSProperties } from 'react'

import Tooltip from '~/components/utils/Tooltip'

const isClipboardAvailable = navigator.clipboard !== undefined

interface Props {
  value: string
  onSuccess?: () => void
  className?: string
  style?: CSSProperties
}

const Copiable: React.FC<Props> = (props) => {
  return (
    <Tooltip
      delay={0}
      enabled={isClipboardAvailable}
      renderContent={() => 'クリックしてコピー'}
      style={{ display: 'block' }}
    >
      <div
        className={props.className}
        style={{
          cursor: isClipboardAvailable ? 'pointer' : 'auto',
          ...props.style,
        }}
        onClick={() => {
          if (!isClipboardAvailable) {
            return
          }
          navigator.clipboard
            .writeText(props.value)
            .then(() => props.onSuccess !== undefined && props.onSuccess())
        }}
      >
        {props.children}
      </div>
    </Tooltip>
  )
}

export default Copiable
