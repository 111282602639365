import * as React from 'react'

import { apiClients } from '~/common/apiClients'
import { SubmitStatus } from '~/common/types'
import { Workflow } from '~/domain/workflow/Workflow'
import { WorkflowService } from '~/domain/workflow/WorkflowService'
import { WorkflowSource } from '~/domain/workflow/source/WorkflowSource'
import { useMe } from '~/presentation/AnyflowAppContext'
import { useMapState } from '~/presentation/useMapState'

const service: WorkflowService = apiClients.workflowService

export function useDuplicateWorkflow() {
  const [status, putStatus] = useMapState<SubmitStatus>()
  const me = useMe()

  const duplicateWorkflow = React.useCallback(
    async (originalWorkflow: Workflow) => {
      putStatus(originalWorkflow.workflowId, SubmitStatus.submitting)
      try {
        const newWorkflowSource = duplicateWorkflowSource(
          me.id,
          originalWorkflow.source
        )
        const workflow = await service.create(
          `コピー: ${originalWorkflow.name}`,
          newWorkflowSource.body
        )
        putStatus(originalWorkflow.workflowId, SubmitStatus.submitted)
        return workflow
      } catch (reason) {
        putStatus(originalWorkflow.workflowId, SubmitStatus.failed)
        throw reason
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  return { duplicateWorkflow, status }
}

function duplicateWorkflowSource(
  userSlug: string,
  originalWorkflowSource: WorkflowSource
): WorkflowSource {
  const trigger = originalWorkflowSource.getTrigger()
  if (trigger === undefined) {
    return originalWorkflowSource
  }
  const triggerInputs = trigger.inputs.map((input) => {
    if (input.fieldKey === 'default_runner_slug') {
      return {
        fieldKey: input.fieldKey,
        value: { mode: 'raw' as const, raw: userSlug },
      }
    }
    return input
  })
  return originalWorkflowSource.setTriggerInputs(triggerInputs)
}
