import React from 'react'
import Helmet from 'react-helmet'
import { Switch } from 'react-router'
import { Redirect, Route, useParams } from 'react-router-dom'

import Loader from '~/components/atoms/Loader'
import Text from '~/components/atoms/Text'
import App from '~/components/molecules/App'
import NoResults from '~/components/organisms/NoResults'
import Tabs, { Tab } from '~/components/organisms/Tabs'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import { ConnectionShareTabContent } from '~/presentation/socialAccount/ConnectionShareTabContent'
import { useConnection } from '~/presentation/socialAccount/useConnection'
import { CONNECTION_DETAIL } from '~/routes'
import * as vars from '~/styles/variables'

const tabs: Tab[] = [
  {
    id: 'share',
    label: 'グループ共有',
    nested: false,
  },
]

const ConnectionDetailPage: React.FC = () => {
  const definition = useDefinitions()
  const { connectionId } = useParams<{ connectionId: string }>()
  const { result } = useConnection(connectionId)

  if (result.loading) {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loader />
      </div>
    )
  }

  if (result.error) {
    return (
      <div style={{ marginTop: vars.space.m }}>
        <NoResults
          heading="エラー"
          description="コネクションの取得に失敗しました"
        />
      </div>
    )
  }

  if (result.data === undefined) {
    return (
      <div style={{ marginTop: vars.space.m }}>
        <NoResults
          heading="コネクションが見つかりませんでした"
          description="削除されたか、URL が間違っている可能性があります"
        />
      </div>
    )
  }

  const app = definition.getApp(result.data.appId)
  return (
    <div>
      <Helmet>
        {/* https://github.com/nfl/react-helmet/issues/408#issuecomment-499763409 */}
        <title>{`${app.name}のコネクション`}</title>
      </Helmet>
      <div
        style={{
          paddingTop: vars.space.l,
          paddingRight: vars.space.l,
          paddingLeft: vars.space.l,
          borderBottom: `1px solid ${vars.color.border}`,
        }}
      >
        <div style={{ display: 'flex' }}>
          <div>
            <App size="xl" icon={app.iconPath} color={app.color} />
          </div>
          <div style={{ flex: 1, marginLeft: vars.space.m * 1.5 }}>
            <Text
              element="h1"
              fontSize="xxl"
              lineHeight="heading"
              fontWeight="bold"
            >
              {app.name}のコネクション
            </Text>
            <Text element="p">{result.data.label}</Text>
          </div>
        </div>
        <div style={{ marginTop: vars.space.m }}>
          <Tabs tabs={tabs} selectedTabId="share" />
        </div>
      </div>
      <div>
        <Switch>
          <Redirect
            from={CONNECTION_DETAIL}
            to={`${CONNECTION_DETAIL}/share`}
            exact={true}
          />
          <Route
            path={`${CONNECTION_DETAIL}/share`}
            component={() => (
              <ConnectionShareTabContent connectionId={connectionId} />
            )}
            exact={true}
          />
        </Switch>
      </div>
    </div>
  )
}

export default ConnectionDetailPage
