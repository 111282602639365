import styled from '@emotion/styled'
import * as React from 'react'

import * as vars from '~/styles/variables'

interface Props {
  children: React.ReactNode
  className?: string
}

interface ChildProps {
  id: string
  label: string
  children: React.ReactNode
  disabled?: boolean
  isDefault?: boolean
  onClick?: (id: string) => void
  renderTab?: () => void
}

const Tabs: React.FC<Props> = ({ children, className }) => {
  const [activeTab, setActiveTab] = React.useState('')
  return (
    <div className={className}>
      <TabList>
        {React.Children.map(
          children,
          (child: React.ReactElement<ChildProps>) => {
            const {
              id,
              label,
              isDefault,
              disabled,
              onClick,
              renderTab,
            } = child.props
            if (!activeTab && isDefault) {
              // セットされてなければ、isDefault がついているタブをアクティブに
              setActiveTab(id)
            }
            return (
              <Tab
                selected={id === activeTab}
                onClick={() => {
                  if (disabled) {
                    return
                  }
                  setActiveTab(id)
                  // 子要素のonClickを発火
                  if (onClick) {
                    onClick(id)
                  }
                }}
                disabled={disabled}
              >
                {renderTab ? <>{renderTab()}</> : label}
              </Tab>
            )
          }
        )}
      </TabList>
      {React.Children.map(children, (child: React.ReactElement<ChildProps>) => {
        if (child.props.id !== activeTab) {
          return null
        }
        return child.props.children
      })}
    </div>
  )
}

const TabList = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const Tab = styled('div')(
  {
    display: 'flex',
    justifyContent: 'center',
    padding: vars.space.s,
    minWidth: 100,
    fontSize: vars.fontSize.s,
    borderBottomWidth: 3,
    borderBottomStyle: 'solid',
  },
  (props: { selected: boolean; disabled?: boolean }) => ({
    color: props.selected ? vars.fontColor.primary : vars.fontColor.secondary,
    fontWeight: props.selected ? vars.fontWeight.bold : vars.fontWeight.normal,
    borderBottomColor: props.selected ? vars.color.theme : 'transparent',
    opacity: props.disabled ? 0.6 : 1,
    cursor: props.disabled ? 'not-allowed' : 'pointer',
  })
)

export default Tabs
