import styled from '@emotion/styled'
import * as React from 'react'
import { Calendar } from 'react-feather'
import Helmet from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom'
import { formatRoute } from 'react-router-named-routes'
import { animated, useSpring } from 'react-spring'

import { StandardTrigger } from '~/common/constants'
import { FetchStatus, SubmitStatus } from '~/common/types'
import * as utils from '~/common/utils'
import Loader from '~/components/atoms/Loader'
import SwitchButton from '~/components/atoms/Switch'
import Text from '~/components/atoms/Text'
import { actionNodeSize } from '~/components/molecules/ActionNode'
import ExecButton from '~/components/molecules/ExecButton'
import SingleLineEditor from '~/components/organisms/SingleLineEditor'
import Tabs, { Tab } from '~/components/organisms/Tabs'
import { Workflow } from '~/domain/workflow/Workflow'
import * as editorUiDuck from '~/ducks/ui/editor'
import * as sidebarDuck from '~/ducks/ui/sidebar'
import { useUpdateWorkflow } from '~/hooks/useUpdateWorkflow'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import { useToaster } from '~/presentation/ToasterContext'
import NoResults from '~/presentation/workflow/detail/NoResults'
import { WorkflowDetailMoreActions } from '~/presentation/workflow/detail/WorkflowDetailMoreActions'
import WorkflowEdit from '~/presentation/workflow/detail/editor'
import { useWorkflow } from '~/presentation/workflow/detail/useWorkflow'
import HistoryList from '~/presentation/workflow/history'
import HistoryDetail from '~/presentation/workflow/history/detail'
import WorkflowShareTabContent from '~/presentation/workflow/share/WorkflowShareTabContent'
import { useDeleteWorkflow } from '~/presentation/workflow/useDeleteWorkflow'
import { useDuplicateWorkflow } from '~/presentation/workflow/useDuplicateWorkflow'
import { useExecuteWorkflow } from '~/presentation/workflow/useExecuteWorkflow'
import * as routes from '~/routes'
import { WORKFLOW_DETAIL } from '~/routes'
import * as vars from '~/styles/variables'

const WorkflowDetail: React.FC = () => {
  const dispatch = useDispatch()
  const treeSize = useSelector(editorUiDuck.selectors.getTreeSize)
  const history = useHistory()
  const location = useLocation()
  const params = useParams<routes.WorkflowDetailParams>()
  const toaster = useToaster()

  const { workflow: masterWorkflow, refetch, fetchStatus } = useWorkflow(
    params.workflowId
  )
  const definitions = useDefinitions()
  const { updateWorkflow } = useUpdateWorkflow()
  const { executeWorkflow } = useExecuteWorkflow()
  const { deleteWorkflow, status: deleteWorkflowStatus } = useDeleteWorkflow()
  const { duplicateWorkflow } = useDuplicateWorkflow()
  const [workflow, setWorkflow] = React.useState<Workflow>()
  /** ワークフローが編集可能なモードにあるか */
  const [isEditingMode, setIsEditingMode] = React.useState(false)
  const [headerRef, setHeaderRef] = React.useState<HTMLDivElement | null>(null)
  const [headerHeight, setHeaderHeight] = React.useState(158)
  const [selectedTabId, setSelectedTabId] = React.useState('workflow')
  const [enableStatus, setEnableStatus] = React.useState<SubmitStatus>(
    SubmitStatus.none
  )

  // animations
  const headerProps = useSpring({
    to: async (next: any) => {
      if (isEditingMode) {
        await next({ opacity: 0, transform: `translateY(-${headerHeight}px)` })
      } else {
        await next({ opacity: 1, transform: 'translateY(0px)' })
      }
    },
    from: { opacity: 1, display: 'block', transform: 'translateY(0px)' },
  })
  const contentProps = useSpring({
    paddingTop: isEditingMode ? 0 : headerHeight,
  })

  React.useEffect(() => {
    return () => {
      dispatch(sidebarDuck.actions.expand())
      dispatch(editorUiDuck.actions.reset())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (/\/workflows\/.+?\/histories\/.+/.test(location.pathname)) {
      setSelectedTabId('historyDetail')
    } else if (/\/workflows\/.+?\/histories/.test(location.pathname)) {
      setSelectedTabId('history')
    } else if (/\/workflows\/.+?\/share/.test(location.pathname)) {
      setSelectedTabId('share')
    } else {
      setSelectedTabId('workflow')
    }
  }, [location])

  // @see: https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
  React.useEffect(() => {
    if (headerRef) {
      const rect = headerRef.getBoundingClientRect()
      setHeaderHeight(rect.height)
    }
  }, [headerRef])

  React.useEffect(() => {
    // エディターモードじゃないときにtreeSizeが変わったら
    // センターへアニメーションする
    if (!isEditingMode) {
      initializeTreePosition()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeSize])

  React.useEffect(() => {
    return () => {
      // @ts-ignore
      // overscrollBehaviorX が CSSStyleDeclaration に生えてないので ignore
      document.body.style.overscrollBehaviorX = 'unset'
    }
  }, [])

  // masterWorkflow が変更された時に workflow を変更する
  React.useEffect(() => {
    setWorkflow(masterWorkflow)
  }, [masterWorkflow])

  const tabs: Tab[] = React.useMemo(() => {
    const res: Tab[] = []
    res.push({
      id: 'workflow',
      label: 'ワークフロー',
      nested: false,
    })
    // 共有権限を持っていれば共有タブを追加
    if (workflow?.permissionPolicy.shareable) {
      res.push({
        id: 'share',
        label: '共有',
        nested: false,
      })
    }
    res.push({
      id: 'history',
      label: '実行履歴',
      nested: false,
    })
    // 実行履歴詳細ならタブに履歴詳細を追加
    if (/\/workflows\/.+?\/histories\/.+/.test(location.pathname)) {
      const label = utils.extract(
        location.pathname,
        /\/workflows\/.+?\/histories\/(.+)/
      )
      res.push({
        id: 'historyDetail',
        label: label ? label.slice(0, 10) : 'detail',
        nested: true,
      })
    }
    return res
  }, [workflow, location.pathname])

  const refreshWorkflow = React.useCallback(() => {
    refetch(true)
    // dispatch(
    //   workflowDuck.actions.fetch.started({
    //     workflowId: params.workflowId,
    //     hideLoader: true
    //   })
    // )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params.workflowId])

  function handleBack() {
    turnOffEditingMode()
    refreshWorkflow()
  }

  const handleEnabledChange = React.useCallback(
    (isEnabled: boolean) => {
      if (!workflow) {
        return
      }
      setEnableStatus(SubmitStatus.submitting)
      updateWorkflow(workflow.workflowId, { isEnabled })
        .then((newWorkflow) => {
          setWorkflow(newWorkflow)
          setEnableStatus(SubmitStatus.submitted)
        })
        .catch((reason) => {
          console.error(reason)
          setEnableStatus(SubmitStatus.failed)
          toaster.showError(
            isEnabled
              ? 'ワークフローの有効化に失敗しました'
              : 'ワークフローの無効化に失敗しました'
          )
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, workflow, enableStatus]
  )

  const handleNameChange = React.useCallback(
    (name: string) => {
      if (workflow === undefined) {
        return
      }
      updateWorkflow(workflow.workflowId, { name })
        .then((newWorkflow) => setWorkflow(newWorkflow))
        .catch((reason) => {
          console.error(reason)
          toaster.showError('名前の編集に失敗しました')
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, workflow]
  )

  const handleDescriptionChange = React.useCallback(
    (description: string) => {
      if (workflow === undefined) {
        return
      }
      updateWorkflow(workflow.workflowId, { description })
        .then((newWorkflow) => setWorkflow(newWorkflow))
        .catch((reason) => {
          console.error(reason)
          toaster.showError('説明文の編集に失敗しました')
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, workflow]
  )

  const handleDelete = () => {
    utils.assert(
      workflow !== undefined,
      'cannot delete an "undefined" workflow'
    )
    if (window.confirm(`本当に削除しますか？`)) {
      deleteWorkflow(workflow.workflowId)
        .then(() => {
          toaster.showSuccess('削除しました')
          history.push(routes.WORKFLOW_LIST)
        })
        .catch((reason) => {
          console.error(reason)
          toaster.showError('削除に失敗しました')
        })
    }
  }

  const handleDuplicate = () => {
    utils.assert(
      workflow !== undefined,
      'cannot duplicate an "undefined" workflow'
    )
    duplicateWorkflow(workflow)
      .then((duplicatedWorkflow) => {
        toaster.showSuccess('複製しました')
        history.push(
          formatRoute(routes.WORKFLOW_EDIT, {
            workflowId: duplicatedWorkflow.workflowId,
          })
        )
      })
      .catch((reason) => {
        console.error(reason)
        toaster.showError('複製に失敗しました')
      })
  }

  function turnOnEditingMode() {
    setIsEditingMode(true)
    // @ts-ignore
    // overscrollBehaviorX が CSSStyleDeclaration に生えてないので ignore
    // エディタ内で onWheel 時、Swipe Back を無効化（Trackpad x Chrome で無効化できること確認済み）
    document.body.style.overscrollBehaviorX = 'none'
    dispatch(sidebarDuck.actions.collapse())

    // デフォルトのスケールに戻す
    // TODO: ワークフロー詳細から編集モードに移行時、スケールを 1 に戻す処理とアクションを中央寄せにする処理が干渉するので一旦コメントアウト
    // dispatch(editorUiDuck.actions.setScale(1))
    dispatch(editorUiDuck.actions.addTy(isEditingMode ? 0 : headerHeight))
  }

  function turnOffEditingMode() {
    setIsEditingMode(false)
    // @ts-ignore
    // overscrollBehaviorX が CSSStyleDeclaration に生えてないので ignore
    document.body.style.overscrollBehaviorX = 'unset'
    dispatch(sidebarDuck.actions.expand())
    initializeTreePosition()
  }

  const SCALE_UPPER_LIMIT = 1.5
  const SCALE_LOWER_LIMIT = 0.5
  function getInitialPosition({
    viewportWidth,
    viewportHeight,
  }: {
    viewportWidth: number
    viewportHeight: number
  }) {
    // cx, cy を設定している関係で、Tree全体が下方にずれているので、それをなおす
    const offsetY = -1 * (actionNodeSize / 2)
    // 少し左右にpaddingをもたせたいので viewportWidth - 100
    const actualScale = (viewportWidth - 100) / treeSize.width

    // 中央に来るように
    const getTx = (scale: number) =>
      (viewportWidth - scale * treeSize.width) / 2
    const ty = (viewportHeight - treeSize.height) / 2 + offsetY

    if (actualScale >= SCALE_UPPER_LIMIT) {
      return { tx: getTx(SCALE_UPPER_LIMIT), ty, scale: SCALE_UPPER_LIMIT }
    } else if (actualScale <= SCALE_LOWER_LIMIT) {
      if (viewportWidth < treeSize.width) {
        return { tx: 100, ty, scale: SCALE_LOWER_LIMIT }
      } else {
        return { tx: getTx(SCALE_LOWER_LIMIT), ty, scale: SCALE_LOWER_LIMIT }
      }
    } else {
      return { tx: getTx(actualScale), ty, scale: actualScale }
    }
  }

  /** タスクツリーをセンターに配置 */
  function initializeTreePosition() {
    const innerWidth = window.innerWidth
    const innerHeight = window.innerHeight

    // エディタの表示領域
    const viewportWidth = innerWidth - vars.width.sidebar
    const viewportHeight = innerHeight - 30

    const { tx, ty, scale } = getInitialPosition({
      viewportWidth,
      viewportHeight,
    })

    dispatch(editorUiDuck.actions.setTx(tx))
    dispatch(editorUiDuck.actions.setTy(ty))
    dispatch(editorUiDuck.actions.setScale(scale))
  }

  function handleExec(workflowId: string, name: string) {
    if (window.confirm(`「${name}」を実行してよろしいですか？`)) {
      executeWorkflow(workflowId)
        .then((workflowInstanceId) => {
          history.push(
            formatRoute(routes.HISTORY_DETAIL, {
              workflowId,
              workflowInstanceId,
            })
          )
        })
        .catch((reason) => {
          console.error(reason)
          toaster.showError('実行に失敗しました')
        })
    }
  }

  const source = workflow?.source
  const trigger = source?.body.getTrigger()
  const hasDeletedAction = source?.hasDeletedAction(definitions)

  return (
    <Container>
      {fetchStatus === FetchStatus.loading ? (
        <LoaderCover>
          <Loader />
        </LoaderCover>
      ) : workflow &&
        deleteWorkflowStatus[workflow.workflowId] ===
          SubmitStatus.submitting ? (
        <LoaderCover>
          <Loader />
        </LoaderCover>
      ) : fetchStatus === FetchStatus.loaded && workflow ? (
        <>
          <Helmet>
            <title>{workflow.name}</title>
          </Helmet>
          <Header ref={setHeaderRef} style={headerProps}>
            <HeaderInner>
              {/*
                 FIXME: padding分を高さとして認識してほしいので、空divに高さを与えて入れてある
                 あとでなおしたい
              */}
              <div style={{ height: vars.space.l }} />
              <WorkflowNameContainer>
                <div style={{ flexGrow: 1 }}>
                  <SingleLineEditor
                    editorInitialText={workflow.name}
                    readonly={!workflow.permissionPolicy.updatable}
                    onSubmit={handleNameChange}
                  >
                    <WorkflowName>{workflow.name}</WorkflowName>
                  </SingleLineEditor>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {trigger?.triggerId === StandardTrigger
                    ? workflow.permissionPolicy.executable && (
                        <_ExecButton
                          disabled={hasDeletedAction}
                          onClick={() =>
                            handleExec(workflow.workflowId, workflow.name)
                          }
                        />
                      )
                    : workflow.permissionPolicy.updatable && (
                        <SwitchButton
                          value={workflow.isEnabled}
                          disabled={!workflow.isEnabled && hasDeletedAction}
                          loading={enableStatus === SubmitStatus.submitting}
                          onChange={handleEnabledChange}
                        />
                      )}
                </div>
                <div style={{ marginLeft: vars.space.s }}>
                  <WorkflowDetailMoreActions
                    deletable={workflow.permissionPolicy.deletable}
                    duplicatable={workflow.permissionPolicy.duplicatable}
                    onDeleteClick={handleDelete}
                    onDuplicateClick={handleDuplicate}
                  />
                </div>
              </WorkflowNameContainer>
              {(workflow.permissionPolicy.updatable ||
                workflow.description !== '') && (
                <div style={{ marginTop: vars.space.s }}>
                  <SingleLineEditor
                    editorInitialText={workflow.description}
                    readonly={!workflow.permissionPolicy.updatable}
                    onSubmit={handleDescriptionChange}
                  >
                    <Description text={workflow.description} />
                  </SingleLineEditor>
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: vars.space.m,
                }}
              >
                <Calendar
                  size={14}
                  color={vars.fontColor.secondary}
                  style={{ marginRight: vars.space.s }}
                />
                <Text
                  element="span"
                  fontSize="s"
                  color="secondary"
                  lineHeight="just"
                >
                  {utils.formatDate(workflow.updatedAt)}
                </Text>
              </div>
              <_Tabs
                selectedTabId={selectedTabId}
                tabs={tabs}
                onTabClick={(tabId) => {
                  setSelectedTabId(tabId)
                  if (tabId === 'workflow') {
                    history.push(`/workflows/${params.workflowId}/edit`)
                  } else if (tabId === 'history') {
                    history.push(`/workflows/${params.workflowId}/histories`)
                  } else if (tabId === 'share') {
                    history.push(`/workflows/${params.workflowId}/share`)
                  }
                }}
              />
            </HeaderInner>
          </Header>
          <Content style={contentProps}>
            <Switch>
              <Redirect
                from="/workflows/:workflowId"
                to="/workflows/:workflowId/edit"
                exact={true}
              />
              <Route path={`/workflows/:workflowId/edit`} exact={true}>
                <WorkflowEditContainer>
                  <Cover
                    onClickCapture={() => {
                      if (!isEditingMode) {
                        turnOnEditingMode()
                      }
                    }}
                    tabIndex={0}
                  >
                    <WorkflowEdit
                      isEditingMode={isEditingMode}
                      headerHeight={headerHeight}
                      onBack={handleBack}
                      onExec={handleExec}
                    />
                  </Cover>
                </WorkflowEditContainer>
              </Route>
              <Route path={routes.HISTORY_DETAIL} exact={true}>
                <HistoryDetail />
              </Route>
              <Route path={`/workflows/:workflowId/histories`} exact={true}>
                <HistoryList workflowId={params.workflowId} />
              </Route>
              <Route path="/workflows/:workflowId/share" exact={true}>
                {workflow.permissionPolicy.shareable ? (
                  <WorkflowShareTabContent workflowId={params.workflowId} />
                ) : (
                  <Redirect
                    to={formatRoute(WORKFLOW_DETAIL, {
                      workflowId: workflow.workflowId,
                    })}
                  />
                )}
              </Route>
            </Switch>
          </Content>
        </>
      ) : fetchStatus === FetchStatus.failed || workflow === undefined ? (
        <NoResults />
      ) : null}
    </Container>
  )
}

const Container = styled('div')({})

const Header = styled(animated.div)({
  position: 'fixed',
  top: 0,
  left: vars.width.sidebar,
  width: `calc(100% - ${vars.width.sidebar}px)`,
  backgroundColor: vars.color.white,
  borderBottom: `1px solid ${vars.color.border}`,
})

const HeaderInner = styled('div')({
  paddingRight: vars.space.l,
  paddingLeft: vars.space.l,
  width: '100%',
})

const _ExecButton = styled(ExecButton)({
  width: 80,
  height: 38,
  borderRadius: 19,
})

const Content = styled(animated.div)({})

const WorkflowNameContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const _Tabs = styled(Tabs)({
  marginTop: vars.space.m,
})

const WorkflowEditContainer = styled('div')({
  position: 'relative',
})

const Cover = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  outline: 'none',
})

const Description: React.FC<{ text: string }> = (props) => {
  return (
    <Text
      element="p"
      fontSize="m"
      color={
        props.text !== '' ? vars.fontColor.primary : vars.fontColor.secondary
      }
    >
      {props.text !== '' ? props.text : 'ワークフローの説明文を設定'}
    </Text>
  )
}

const WorkflowName = styled('h1')({
  margin: 0,
  wordBreak: 'break-word',
  fontSize: vars.fontSize.xxl,
  fontWeight: vars.fontWeight.bold,
})

const LoaderCover = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: `calc(100w - ${vars.width.sidebar}px)`,
  height: '100vh',
})

export default WorkflowDetail
