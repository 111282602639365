import styled from '@emotion/styled'
import React, { CSSProperties } from 'react'

import * as vars from '~/styles/variables'

interface Props {
  children?: React.ReactNode
  element: 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'label'
  color?: keyof typeof vars.fontColor | string
  fontSize?: keyof typeof vars.fontSize
  fontWeight?: keyof typeof vars.fontWeight
  fontFamily?: keyof typeof vars.fontFamily
  lineHeight?: 'just' | 'heading' | 'body'
  textAlign?: CSSProperties['textAlign']
  truncated?: boolean
  className?: string
  style?: CSSProperties
  dangerouslySetInnerHTML?: { __html: string }
}

const Text: React.FC<Props> = ({
  element,
  color = 'primary',
  fontSize = 'm',
  fontWeight = 'normal',
  fontFamily = 'sansSerif',
  lineHeight = 'body',
  textAlign = 'left',
  truncated = false,
  children,
  ...props
}) => {
  // 指定されたエレメントでコンポーネントを作成
  // 再レンダーごとに _Container が作り直されると onFocus で setState するような要素の
  // onClick イベントが受け取れなかったりするので、毎回オブジェクトを作り直さないようにする
  const _Container = React.useMemo(() => Container.withComponent(element), [
    element,
  ])
  return (
    <_Container
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      fontFamily={fontFamily}
      lineHeight={lineHeight}
      textAlign={textAlign}
      truncated={truncated}
      {...props}
    >
      {children}
    </_Container>
  )
}

type ContainerProps = Required<
  Pick<
    Props,
    | 'color'
    | 'fontSize'
    | 'fontWeight'
    | 'fontFamily'
    | 'lineHeight'
    | 'textAlign'
    | 'truncated'
  >
>

const Container = styled('p')<ContainerProps>(
  {
    wordBreak: 'break-all',
  },
  (props) => ({
    margin: 0,
    color: vars.fontColor[props.color]
      ? vars.fontColor[props.color]
      : props.color,
    fontSize: vars.fontSize[props.fontSize],
    fontWeight: vars.fontWeight[props.fontWeight],
    fontFamily: vars.fontFamily[props.fontFamily],
    lineHeight: vars.lineHeight[props.lineHeight],
    textAlign: props.textAlign,
    ...(props.truncated
      ? {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }
      : {}),
  })
)

export default Text
