import styled from '@emotion/styled'
import * as React from 'react'

import Link from '~/components/atoms/Link'
import * as vars from '~/styles/variables'

interface Props {
  to: string
  currentPageNumber: number
  totalCount: number
  countPerPage: number
  params?: any
  className?: string
}

const Pager: React.FC<Props> = ({
  to,
  currentPageNumber,
  totalCount,
  countPerPage,
  params,
  className,
}) => {
  return (
    <List className={className}>
      {(() => {
        if (currentPageNumber - 1 > 0) {
          const search =
            currentPageNumber - 1 === 1 ? `` : `?page=${currentPageNumber - 1}`
          return (
            <ListItem>
              <_Link to={to} search={search} params={params}>
                前へ
              </_Link>
            </ListItem>
          )
        }
        return <li />
      })()}
      {(() => {
        const maxPageNum = Math.ceil(totalCount / countPerPage)
        if (currentPageNumber + 1 <= maxPageNum) {
          return (
            <ListItem>
              <_Link
                to={to}
                search={`?page=${currentPageNumber + 1}`}
                params={params}
              >
                次へ
              </_Link>
            </ListItem>
          )
        }
        return <li />
      })()}
    </List>
  )
}

const List = styled('ul')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: 0,
  listStyle: 'none',
})

const ListItem = styled('li')({})

const _Link = styled(Link)({
  paddingTop: vars.space.s,
  paddingRight: vars.space.m,
  paddingBottom: vars.space.s,
  paddingLeft: vars.space.m,
  color: vars.fontColor.tertiary,
  fontSize: vars.fontSize.s,
  border: `1px solid ${vars.color.border}`,
  borderRadius: vars.borderRadius.s,
  textDecoration: 'none',
  '&:hover': {
    color: vars.fontColor.secondary,
    borderColor: vars.fontColor.tertiary,
  },
})

export default Pager
