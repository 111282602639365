import { PaginationMeta } from '~/common/Pagination'
import { PaginationJson } from '~/data/PaginationJson'

export function mapJsonToPaginationMeta(
  json: PaginationJson<unknown>
): PaginationMeta {
  return {
    totalCount: json.count,
    currentPageNumber: json.current,
    countPerPage: json.limit,
  }
}
