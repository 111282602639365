import styled from '@emotion/styled'
import * as React from 'react'
import { X } from 'react-feather'

import { Result } from '~/common/Result'
import Loader from '~/components/atoms/Loader'
import Modal from '~/components/organisms/Modal'
import NoResults from '~/components/organisms/NoResults'
import EscapeTrap from '~/components/utils/EscapeTrap'
import { Invitation } from '~/domain/user/Invitation'
import { InvitationResult } from '~/domain/user/InvitationResult'
import { User } from '~/domain/user/User'
import { InviteUsersForm } from '~/presentation/user/InviteUsersForm'
import * as vars from '~/styles/variables'

interface Props {
  isOpen: boolean
  inviting: boolean
  handleClose: () => void
  handleInvite: (emails: string[]) => Promise<InvitationResult>
  usersResult: Result<User[]>
  invitationsResult: Result<Invitation[]>
}

export const InviteUsersModal: React.FC<Props> = (props) => {
  return (
    <Modal
      isOpened={props.isOpen}
      onClose={props.handleClose}
      lightDimmer={true}
    >
      <EscapeTrap handleEscape={props.handleClose}>
        <ModalContent>
          <Header>ユーザーを招待する</Header>
          <CloseButton
            color={vars.color.icon}
            size={22}
            onClick={props.handleClose}
          />
          <ModalBody>
            <Content
              isActive={props.isOpen}
              inviting={props.inviting}
              handleClose={props.handleClose}
              handleInvite={props.handleInvite}
              usersResult={props.usersResult}
              invitationsResult={props.invitationsResult}
            />
          </ModalBody>
        </ModalContent>
      </EscapeTrap>
    </Modal>
  )
}

interface ContentProps {
  isActive: boolean
  inviting: boolean
  handleClose: () => void
  handleInvite: (emails: string[]) => Promise<InvitationResult>
  usersResult: Result<User[]>
  invitationsResult: Result<Invitation[]>
}
const Content: React.FC<ContentProps> = (props) => {
  if (props.usersResult.loading || props.invitationsResult.loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Loader />
      </div>
    )
  }
  if (
    props.usersResult.error !== undefined ||
    props.invitationsResult.error !== undefined
  ) {
    return (
      <NoResults
        heading="エラー"
        description="時間をおいてから再度お試しください"
      />
    )
  }

  return (
    <>
      <Description>
        <p>招待したいユーザーのメールアドレスを入力してください。</p>
        <p>アカウント作成用URLがメールで送信されます。</p>
      </Description>
      <InviteUsersForm
        isActive={props.isActive}
        inviting={props.inviting}
        handleClose={props.handleClose}
        handleInvite={props.handleInvite}
        users={props.usersResult.data}
        invitations={props.invitationsResult.data}
      />
    </>
  )
}

const ModalContent = styled('div')({
  position: 'relative',
  padding: vars.space.l,
  width: 600,
})

const Header = styled('h1')({
  fontSize: vars.fontSize.xl,
  fontWeight: vars.fontWeight.bold,
  lineHeight: vars.lineHeight.heading,
})

const CloseButton = styled(X)({
  position: 'absolute',
  top: vars.space.m * 1.5,
  right: vars.space.m * 1.5,
  cursor: 'pointer',
})

const Description = styled('div')({
  fontSize: vars.fontSize.s,
  marginBottom: vars.space.m,
})

const ModalBody = styled('div')({
  marginTop: vars.space.m,
})
