import { delay } from '~/common/utils'
import { TestPreviewApi } from '~/data/workflow/test/TestPreviewApi'
import { TestPreviewJson } from '~/data/workflow/test/json'

export class MockTestPreviewApi implements TestPreviewApi {
  async getPreview(): Promise<TestPreviewJson> {
    await delay(1000)
    return {
      isSuccess: true,
      messages: [],
      results: [
        {
          fieldKey: 'account_uid',
          value: 'foooooooo',
        },
        {
          fieldKey: 'cell_range',
          value: {
            name: 'taro',
            age: 18,
          },
        },
      ],
    }
  }
}
