import { Tson } from '~/data/Tson'
import { mapToUserJson } from '~/data/user/mapToUserJson'
import { WorkflowInstanceSummaryJson } from '~/data/workflow/instance/json'

export function mapToWorkflowInstanceSummaryJson(
  tson: Tson
): WorkflowInstanceSummaryJson {
  return {
    runner: mapToUserJson(tson.getValue('runner')),
    slug: tson.getString('slug'),
    state: tson.getString('state'),
    updatedAt: tson.getString('updatedAt'),
  }
}
