import { ArgumentInfoJson } from '~/data/workflow/object/json'
import { mapJsonToValueType } from '~/data/workflow/valueType/mapJsonToValueType'
import { ArgumentDefinition } from '~/domain/workflow/function/ArgumentDefinition'

export function mapJsonToArgumentDefinition(
  json: ArgumentInfoJson
): ArgumentDefinition {
  return {
    key: json.key,
    position: json.position,
    name: json.name,
    type: mapJsonToValueType(json.valueType),
    description: json.description,
    required: json.required,
    isVariableLength: json.isVariableLength,
  }
}
