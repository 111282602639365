import { ensure } from '~/common/utils'
import { ValueType } from '~/domain/ValueType'
import { ArgumentDefinition } from '~/domain/workflow/function/ArgumentDefinition'

export type ObjectMember = ObjectMember.Property | ObjectMember.Method

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace ObjectMember {
  interface Base {
    type: 'property' | 'method'
    key: string
    name: string
    description: string
  }

  export interface Property extends Base {
    type: 'property'
    valueType: ValueType
  }

  export interface Method extends Base {
    type: 'method'
    arguments: ArgumentDefinition[]
    returnType: ValueType
  }

  export function isProperty(member: ObjectMember): member is Property {
    return member.type === 'property'
  }

  export function isMethod(member: ObjectMember): member is Method {
    return member.type === 'method'
  }
}

export class ObjectDefinition {
  constructor(
    public readonly objectId: string,
    public readonly appId: string,
    public readonly name: string,
    public readonly description: string,
    public readonly members: ObjectMember[]
  ) {}

  getProperty(propertyKey: string): ObjectMember.Property {
    return ensure(
      this.findProperty(propertyKey),
      `No such object property: ${this.objectId}.${propertyKey}`
    )
  }

  getMethod(methodKey: string): ObjectMember.Method {
    return ensure(
      this.findMethod(methodKey),
      `No such object method: ${this.objectId}.${methodKey}`
    )
  }

  findProperty(propertyKey: string): ObjectMember.Property | undefined {
    return this.members
      .filter(ObjectMember.isProperty)
      .find((property) => property.key === propertyKey)
  }

  findMethod(methodKey: string): ObjectMember.Method | undefined {
    return this.members
      .filter(ObjectMember.isMethod)
      .find((method) => method.key === methodKey)
  }
}
