import * as React from 'react'

import { apiClients } from '~/common/apiClients'
import { SubmitStatus } from '~/common/types'
import { WorkflowService } from '~/domain/workflow/WorkflowService'
import { useMapState } from '~/presentation/useMapState'

const service: WorkflowService = apiClients.workflowService

export function useDeleteWorkflow(): {
  deleteWorkflow: (workflowId: string) => Promise<void>
  status: { [workflowId: string]: SubmitStatus }
} {
  const [status, putStatus] = useMapState<SubmitStatus>()
  const deleteWorkflow = React.useCallback((workflowId: string): Promise<
    void
  > => {
    putStatus(workflowId, SubmitStatus.submitting)
    return service
      .delete(workflowId)
      .then(() => putStatus(workflowId, SubmitStatus.submitted))
      .catch((reason) => {
        putStatus(workflowId, SubmitStatus.failed)
        throw reason // re-throw
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return { deleteWorkflow, status }
}
