import * as React from 'react'

import { ExpectedObjects } from '~/domain/workflow/expectedObject/ExpectedObjects'
import { ExpectedObjectSnapshotMap } from '~/domain/workflow/object/ExpectedObjectSnapshotMap'
import { TestResult } from '~/domain/workflow/test/TestResult'
import { WorkflowTestResult } from '~/domain/workflow/test/WorkflowTestResult'
import { useExpectedObjectsContext } from '~/presentation/workflow/detail/editor/form/expectedObject/ExpectedObjectsContext'
import { useWorkflowTestResult } from '~/presentation/workflow/detail/editor/test/WorkflowTestResultContext'

const ExpectedObjectSnapshotsContext = React.createContext<
  ExpectedObjectSnapshotMap | undefined
>(undefined)

export function useExpectedObjectSnapshots(): ExpectedObjectSnapshotMap {
  const context = React.useContext(ExpectedObjectSnapshotsContext)
  if (context === undefined) {
    throw new Error(
      'ExpectedObjectSnapshotMapContext is undefined. Out of context scope?'
    )
  }
  return context
}

/**
 * 現在の Context の ExpectedObjects と WorkflowTestResult から利用できる ExpectedObjectSnapshot の
 * 一覧を作成し、 Context として提供します。
 *
 * Context ととして提供せずに、同等の機能を提供する Hook を提供しても目的は達成できますが、
 * 予め利用可能なスナップショットを ExpectedObjectKey でインデックスしておくことで
 * スナップショット利用時のコストを下げる効果があります。スナップショットは特に読み込み回数が
 * 多くなる傾向がある（ExpectedObject の数に比例）ため、このキャッシュは効果的です。
 */
export const ExpectedObjectSnapshotsProvider: React.FC = (props) => {
  const { expectedObjects } = useExpectedObjectsContext()
  const { workflowTestResult } = useWorkflowTestResult()

  const map = React.useMemo(() => {
    return buildSnapshotMap(expectedObjects, workflowTestResult)
  }, [expectedObjects, workflowTestResult])

  return (
    <ExpectedObjectSnapshotsContext.Provider value={map}>
      {props.children}
    </ExpectedObjectSnapshotsContext.Provider>
  )
}

export const EmptyExpectedObjectSnapshotsProvider: React.FC = (props) => {
  const value = React.useMemo(() => new ExpectedObjectSnapshotMap(), [])
  return (
    <ExpectedObjectSnapshotsContext.Provider value={value}>
      {props.children}
    </ExpectedObjectSnapshotsContext.Provider>
  )
}

export const TestResultSnapshotsProvider: React.FC<{
  result: TestResult
}> = (props) => {
  const value = React.useMemo(
    () => new ExpectedObjectSnapshotMap(props.result.snapshots),
    [props.result]
  )
  return (
    <ExpectedObjectSnapshotsContext.Provider value={value}>
      {props.children}
    </ExpectedObjectSnapshotsContext.Provider>
  )
}

function buildSnapshotMap(
  expectedObjects: ExpectedObjects,
  workflowTestResult: WorkflowTestResult
): ExpectedObjectSnapshotMap {
  const map = new ExpectedObjectSnapshotMap()
  if (expectedObjects.trigger !== undefined) {
    workflowTestResult
      .findTriggerResult()
      ?.snapshots.forEach((it) => map.add(it))
  }
  expectedObjects.tasks?.map((task) => {
    workflowTestResult
      .findTaskResult(task.taskId)
      ?.snapshots.forEach((it) => map.add(it))
  })
  return map
}
