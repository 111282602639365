import * as React from 'react'

import { assert } from '~/common/utils'
import { ValueType } from '~/domain/ValueType'
import { RenderableEntity } from '~/domain/workflow/source/RenderableElement'
import VariableFinderView from '~/presentation/workflow/detail/editor/form/variableFinder/VariableFinderView'

const VariableFinderHolderContext = React.createContext<
  VariableFinderHolderInterface | undefined
>(undefined)

interface VariableFinderHolderInterface {
  open: () => void
  close: () => void
  setTarget: (target: VariableFinderTarget | undefined) => void
}

export interface VariableFinderTarget {
  acceptableValueTypes: ValueType[]
  rect: DOMRect
  insertEntities: (entities: RenderableEntity[]) => void
  focus: () => void
}

export function useVariableFinderContext(): VariableFinderHolderInterface {
  const context = React.useContext(VariableFinderHolderContext)
  assert(
    context !== undefined,
    `VariableFinderHolderContext is undefined. Out of context scope?`
  )
  return context
}

// 77 = VariableFinderView.windowBarHeight + vars.space.m + 16
// VariableFinderView は指定した top の値から windowBarHeight と vars.space.m の分だけ上にずれる
// ずれた結果が NodeEditor の上辺 (padding が 16 なので 16) と一致するように計算している。
const minVfTop = 77

interface Props {
  offsetRight?: number
}

const VariableFinderHolder: React.FC<Props> = (props) => {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [right, setRight] = React.useState<number>(0)
  const [vfTarget, setVfTarget] = React.useState<VariableFinderTarget>()
  const [vfHasFocus, setVfHasFocus] = React.useState<boolean>(false)
  const [vfShouldOpen, setVfShouldOpen] = React.useState<boolean>(false)

  const variableFinderHolder: VariableFinderHolderInterface = React.useMemo(
    () => ({
      open: () => {
        setVfShouldOpen(true)
      },
      close: () => {
        setVfShouldOpen(false)
      },
      setTarget: (target) => {
        setVfTarget(target)
      },
    }),
    []
  )

  const handleVariableFinderRequestClose = React.useCallback(() => {
    setVfShouldOpen(false)
    setVfHasFocus(false)
  }, [])

  const handleVariableFinderSelect = React.useCallback(
    (entities) => {
      vfTarget?.insertEntities(entities)
    },
    [vfTarget]
  )

  const handleVariableFinderFocusChange = React.useCallback(
    (hasFocus) => {
      if (hasFocus) {
        setVfHasFocus(true)
        // フォーカスを返す
        vfTarget?.focus()
      } else {
        setVfHasFocus(false)
      }
    },
    [vfTarget]
  )

  React.useEffect(() => {
    if (vfShouldOpen || vfHasFocus) {
      const left = containerRef.current?.getBoundingClientRect().left ?? 0
      setRight(window.innerWidth - left)
    }
  }, [vfShouldOpen, vfHasFocus])

  return (
    <VariableFinderHolderContext.Provider value={variableFinderHolder}>
      <div ref={containerRef}>{props.children}</div>
      <VariableFinderView
        isShown={vfShouldOpen || vfHasFocus}
        right={right + (props.offsetRight ?? 0)}
        top={vfTarget ? Math.max(minVfTop, vfTarget.rect.top) : 0}
        targetValueTypes={vfTarget?.acceptableValueTypes ?? []}
        onSelect={handleVariableFinderSelect}
        onRequestClose={handleVariableFinderRequestClose}
        onFocusChange={handleVariableFinderFocusChange}
      />
    </VariableFinderHolderContext.Provider>
  )
}

export default VariableFinderHolder
