import styled from '@emotion/styled'
import * as React from 'react'

import NodeTree from '~/components/organisms/NodeTree'
import { WorkflowSourceBody } from '~/domain/workflow/source/WorkflowSourceBody'
import { useExtractedLinkLabel } from '~/hooks/useMarkedLink'
import { useWorkflowTree } from '~/hooks/useTree'
import * as vars from '~/styles/variables'

interface Props {
  name: string
  description: string
  available: boolean
  className?: string
  sourceBody: WorkflowSourceBody
}

const RecipeItem: React.FC<Props> = ({
  name,
  description,
  available,
  className,
  sourceBody,
}) => {
  const [nodes, links] = useWorkflowTree(sourceBody)
  // レシピアイテムでは、リンクのラベルのみを使用
  const _description = useExtractedLinkLabel(description)
  return (
    <Container className={className} available={available}>
      <NodeTreeContainer>
        <NodeTree nodes={nodes} links={links} maxHeight={130} />
      </NodeTreeContainer>
      <Content>
        <Name>{name}</Name>
        <Description>{_description}</Description>
      </Content>
    </Container>
  )
}

const Container = styled('div')(
  {
    borderRadius: vars.borderRadius.m,
    boxShadow: vars.shadow.m,
    overflow: 'hidden',
    transition: 'all .14s ease',
  },
  (props: { available: boolean }) => ({
    opacity: props.available ? 1 : 0.5,
    '&:hover': {
      boxShadow: props.available
        ? `0 15px 30px rgba(0, 0, 0, 0.1)`
        : vars.shadow.m,
      transform: props.available ? 'translateY(-2px)' : 'none',
    },
  })
)

const NodeTreeContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  paddingRight: vars.space.m,
  paddingLeft: vars.space.m,
  height: 150,
})

const Content = styled('div')({
  paddingTop: vars.space.m,
  paddingRight: vars.space.m * 1.5,
  paddingBottom: vars.space.m * 1.5,
  paddingLeft: vars.space.m * 1.5,
})

const Name = styled('h3')({
  height: '3em',
  lineHeight: 1.5,
  overflow: 'hidden',
})
const Description = styled('p')({
  marginTop: vars.space.s,
  fontSize: vars.fontSize.s,
  color: vars.fontColor.secondary,
  height: '3.4em',
  lineHeight: 1.7,
  overflow: 'hidden',
})

export default RecipeItem
