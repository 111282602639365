import * as dayjs from 'dayjs'
import * as Cookies from 'js-cookie'
import * as _ from 'lodash'

import { MyError } from '~/common/types'
import { TaskSource } from '~/domain/workflow/source/WorkflowSourceBody'

export function assertNever(x: never): never {
  throw new Error('Unexpected object: ' + x)
}

export function convertToURIComponent(value: unknown): string {
  const json = JSON.stringify(value)
  return encodeURIComponent(json)
}

/**
 * Python の型定義を正規化
 */
export function normalizeTyp(typ: string) {
  return typ.replace(/typing\./g, '')
}

/**
 * WorkflowSource をフラットにする
 */
export function flattenTaskSource(task: TaskSource): TaskSource[] {
  /**
   * 注意: task には draft が入ってくることがあるので
   * task を直接いじるような処理は禁止
   */
  if (task.tasks.length === 0) {
    return [task]
  }
  let result: TaskSource[] = []
  const children: TaskSource[][] = task.tasks.map((childTask) => {
    if (childTask.tasks.length === 0) {
      return [childTask]
    }
    return flattenTaskSource(childTask)
  })
  result.push(task)
  result = [...result, ..._.flatten(children)]
  return result
}

export function extract(str: string, regex: RegExp) {
  const found = str.match(regex)
  if (!found) {
    return null
  }
  return found[1]
}

/**
 * @deprecated {@link ExpectedObjectKey#getFriendlyName} を使用して下さい
 */
export function removePrefix(str: string) {
  return extract(str, /obj_key_(.+)/)
}

export function extractObjKey(str: string) {
  // TODO: オブジェクト名に_がはいることがある
  return extract(str, /obj_key_(.+)(?=\.)/)
}

export function extractTid(str: string) {
  return extract(str, /tid_(.+?)__/)
}

export function extractProperty(str: string) {
  return extract(str, /obj_key_.+\.(.+)/)
}

export function extractMethodName(str: string) {
  return extract(str, /obj_key_.+\.(.+)$/)
}

/**
 * tid_abc__obj_key_foo.property をパース
 * @param str
 */
export function parseObjectProperty(str: string) {
  const tid = extractTid(str)
  const objKey = extractObjKey(str)
  const property = extractProperty(str)
  return {
    tid,
    objKey,
    property,
  }
}

/**
 * tid_abc__obj_key_foo.method をパース
 * @param str
 */
export function parseObjectMethod(str: string) {
  const tid = extractTid(str)
  const objKey = extractObjKey(str)
  const methodName = extractMethodName(str)
  return {
    tid,
    objKey,
    methodName,
  }
}

// // objKey: tid_foo_obj_key_foo.values
// export function getObjIdByTaskIdFromExpectedObject(
//   expectedObject: ExpectedObjectResponse,
//   taskId: string,
//   objKey: string
// ) {
//   const found = _.find(expectedObject.tasks, obj => obj.taskId === taskId)
//   if (!found) {
//     return null
//   }
//   const found2 = _.find(found.expectedObjects, obj => obj.objKey === objKey)
//   if (!found2) {
//     return null
//   }
//   return found2.objId
// }
//
// export function getObjIdFromExpectedObjectOfTrigger(
//   expectedObject: ExpectedObjectResponse,
//   objKey: string
// ) {
//   const found = _.find(
//     expectedObject.trigger.expectedObjects,
//     obj => obj.objKey === objKey
//   )
//   if (!found) {
//     return null
//   }
//   return found.objId
// }

/**
 * 日付をフォーマットして返す
 */
export function formatDate(date: dayjs.ConfigType) {
  const thisYear = dayjs().year()
  const format =
    thisYear !== dayjs(date).year()
      ? 'YYYY年M月D日 HH:mm:ss'
      : 'M月D日 HH:mm:ss'
  const datetime = dayjs(date).format(format)
  const relativeTime = dayjs(date).fromNow()
  return `${datetime} （${relativeTime}）`
}

/**
 * CSRFトークンの取得
 */
export const getCSRFToken = (): string | undefined => {
  return Cookies.get('csrftoken')
}

export const removeCSRFToken = () => {
  Cookies.remove('csrftoken')
}

export async function delay(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export function ensure<T>(value: T | undefined | null, message?: string): T {
  if (value == null) {
    throw new Error(message || 'The value is undefined or null.')
  }
  return value
}

export function assert(
  condition: boolean,
  message?: string
): asserts condition {
  if (!condition) {
    throw new AssertionError(message || "The condition doesn't meet.")
  }
}

export class AssertionError extends MyError {
  constructor(message: string) {
    super(`Assertion failed: ${message}`)
  }
}

export function run<T>(func: () => T): T {
  return func()
}

export function truncateMiddle(text: string, length: number = 10): string {
  if (text.length <= 10) {
    return text
  }
  return `${text.substring(0, length / 2)}…${text.substring(
    text.length - length / 2
  )}`
}

export function rejectUndefined<T>(values: (T | undefined)[]): T[] {
  return values.filter((it): it is T => it !== undefined)
}

export function failRandomly(failRate: number): void {
  if (Math.random() < failRate) {
    throw new Error('Mock Error')
  }
}
