import styled from '@emotion/styled'
import * as React from 'react'

import Inner from '~/components/organisms/Inner'
import * as vars from '~/styles/variables'

interface Props {
  className?: string
}

const Footer: React.FC<Props> = ({ className }) => (
  <_Footer className={className}>
    <_Inner responsive={true}>
      <img src="/static/images/lp/gray-logo.svg" alt="Anyflow" />
      <FooterList>
        <FooterItem>
          <FooterLink href="https://anyflow.jp/about" target="_blank">
            運営会社
          </FooterLink>
        </FooterItem>
        <FooterItem>
          <FooterLink href="https://anyflow.jp/terms" target="_blank">
            利用規約
          </FooterLink>
        </FooterItem>
        <FooterItem>
          <FooterLink href="https://anyflow.jp/privacy" target="_blank">
            プライバシーポリシー
          </FooterLink>
        </FooterItem>
        <FooterItem>
          <FooterLink href="https://anyflow.jp/security-policy">
            情報セキュリティポリシー
          </FooterLink>
        </FooterItem>
      </FooterList>
      <IsmsImageContainer>
        <IsmsImage
          src="/static/images/lp/ISMS_ANAB_ISMS-AC@2x.jpg"
          alt="ISMS認証画像"
        />
        <div>IS 751085 / ISO 27001</div>
      </IsmsImageContainer>
      <Copyright>&copy; 2020 Anyflow Inc.</Copyright>
    </_Inner>
  </_Footer>
)

const _Footer = styled('footer')({
  paddingTop: vars.space.l,
  paddingBottom: vars.space.l,
})

const _Inner = styled(Inner)({
  paddingTop: vars.space.l,
  paddingBottom: vars.space.l,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const FooterList = styled('ul')({
  display: 'flex',
  alignItems: 'center',
  marginTop: vars.space.m,
  marginBottom: 0,
  padding: 0,
  listStyle: 'none',
  [`${vars.media.sp}`]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
})

const FooterItem = styled('li')({
  marginRight: vars.space.m,
  '&:last-of-type': {
    marginRight: 0,
  },
  [`${vars.media.sp}`]: {
    width: '100%',
    marginRight: 0,
    marginBottom: vars.space.m,
    textAlign: 'center',
  },
})

const FooterLink = styled('a')({
  color: vars.fontColor.secondary,
  fontSize: vars.fontSize.s,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
})

const IsmsImageContainer = styled('div')({
  marginTop: vars.space.l,
  color: vars.fontColor.tertiary,
  fontSize: vars.fontSize.xs,
  textAlign: 'center',
})

const IsmsImage = styled('img')({
  padding: vars.space.s,
})

const Copyright = styled('small')({
  display: 'block',
  marginTop: vars.space.m,
  color: vars.fontColor.secondary,
  fontSize: vars.fontSize.s,
  opacity: 0.6,
})

export default Footer
