import styled from '@emotion/styled'
import * as React from 'react'
import { ChevronDown } from 'react-feather'
import { animated, useSpring } from 'react-spring'

import { assertNever } from '~/common/utils'
import Text from '~/components/atoms/Text'
import TypeSign from '~/components/atoms/TypeSign'
import { Definitions } from '~/domain/Definitions'
import { ValueType } from '~/domain/ValueType'
import { ExpectedObject } from '~/domain/workflow/expectedObject/ExpectedObjects'
import { ObjectMember } from '~/domain/workflow/object/ObjectDefinition'
import { RenderableEntity } from '~/domain/workflow/source/RenderableElement'
import { useMeasure } from '~/hooks/useMeasure'
import ObjectMethodPill from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/ObjectMethodPill'
import ObjectPropertyPill from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/ObjectPropertyPill'
import { TypeMismatchTooltip } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/tooltips'
import { EntityType } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/types'
import * as vars from '~/styles/variables'

interface Props {
  expectedObject: ExpectedObject
  definitions: Definitions
  targetValueTypes: ValueType[]
  className?: string
  defaultCollapsed?: boolean
  onSelect: (entities: RenderableEntity[]) => void
  onCollapse?: () => void
  onExpand?: () => void
}

const ExpectedObjectItem: React.FC<Props> = ({
  expectedObject,
  definitions,
  targetValueTypes,
  className,
  defaultCollapsed = true,
  onSelect,
  onCollapse,
  onExpand,
}) => {
  const objectDefinition = definitions.getObject(expectedObject.objectId)
  const appDefinition = definitions.getApp(objectDefinition.appId)

  const { ref, bounds } = useMeasure<HTMLDivElement>()
  const [collapsed, setCollapsed] = React.useState(defaultCollapsed)

  const collapsableProps = useSpring({
    to: {
      // bounds.height には padding が含まれていないので上下分を足す
      height: collapsed ? 0 : bounds.height + vars.space.s + vars.space.m,
    },
  })

  const chevronProps = useSpring({
    transform: collapsed ? 'rotate(0deg)' : 'rotate(180deg)',
  })

  const handleHeadingClick = React.useCallback(() => {
    if (collapsed) {
      onExpand && onExpand()
    } else {
      onCollapse && onCollapse()
    }
    setCollapsed(!collapsed)
  }, [collapsed, onExpand, onCollapse])

  const handlePropertyClick = React.useCallback(
    (object: ExpectedObject, property: ObjectMember.Property) => {
      onSelect([
        {
          type: EntityType.PROPERTY,
          expectedObjectKey: object.key,
          propertyKey: property.key,
        },
      ])
    },
    [onSelect]
  )

  const handleMethodClick = React.useCallback(
    (object: ExpectedObject, method: ObjectMember.Method) => {
      onSelect([
        {
          type: EntityType.METHOD_CALL_START,
          expectedObjectKey: object.key,
          methodKey: method.key,
        },
        {
          type: EntityType.CALL_END,
        },
      ])
    },
    [onSelect]
  )

  return (
    <Container key={expectedObject.key.value} className={className}>
      <Heading onClick={handleHeadingClick}>
        <Text
          element="p"
          fontSize="s"
          fontWeight="bold"
          lineHeight="heading"
          style={{ marginLeft: vars.space.s }}
        >
          {expectedObject.name}
        </Text>
        <ChevronContainer style={chevronProps}>
          <ChevronDown color={vars.color.icon} size={chevronSize} />
        </ChevronContainer>
      </Heading>
      <Collapsable style={collapsableProps}>
        <div
          ref={ref}
          style={{
            paddingTop: vars.space.s,
            paddingRight: vars.space.m,
            paddingBottom: vars.space.m,
            paddingLeft: vars.space.m,
          }}
        >
          {objectDefinition.members.map((member) => {
            switch (member.type) {
              case 'property':
                return (
                  <Item key={`${expectedObject.key.value}.${member.key}`}>
                    <PillContainer>
                      <TypeMismatchTooltip
                        disabled={isTypeCompatible(
                          targetValueTypes,
                          member.valueType
                        )}
                      >
                        <ObjectPropertyPill
                          app={appDefinition}
                          object={expectedObject}
                          member={member}
                          enabled={isTypeCompatible(
                            targetValueTypes,
                            member.valueType
                          )}
                          onClick={handlePropertyClick}
                        />
                      </TypeMismatchTooltip>
                    </PillContainer>
                    <_TypeSign
                      valueType={member.valueType}
                      color={vars.fontColor.tertiary}
                    />
                  </Item>
                )
              case 'method':
                return (
                  <Item key={`${expectedObject.key.value}.${member.key}()`}>
                    <PillContainer>
                      <TypeMismatchTooltip
                        disabled={isTypeCompatible(
                          targetValueTypes,
                          member.returnType
                        )}
                      >
                        <ObjectMethodPill
                          app={appDefinition}
                          object={expectedObject}
                          member={member}
                          enabled={isTypeCompatible(
                            targetValueTypes,
                            member.returnType
                          )}
                          onClick={handleMethodClick}
                        />
                      </TypeMismatchTooltip>
                    </PillContainer>
                    <_TypeSign
                      valueType={member.returnType}
                      color={vars.fontColor.tertiary}
                    />
                  </Item>
                )
              default:
                assertNever(member)
            }
          })}
        </div>
      </Collapsable>
    </Container>
  )
}

function isTypeCompatible(
  definitionTypes: ValueType[],
  testType: ValueType
): boolean {
  return definitionTypes.some((type) => ValueType.matchLoose(type, testType))
}

const chevronSize = 20

const Container = styled('div')({
  width: '100%',
})

const Heading = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: vars.space.s,
  paddingRight: vars.space.s,
  paddingBottom: vars.space.s,
  paddingLeft: vars.space.s,
  width: '100%',
  cursor: 'pointer',
  backgroundColor: vars.color.offWhite,
  borderRadius: vars.borderRadius.m,
})

const Collapsable = styled(animated.div)({
  overflow: 'hidden',
  backgroundColor: vars.color.offWhite,
  borderBottomRightRadius: vars.borderRadius.m,
  borderBottomLeftRadius: vars.borderRadius.m,
})

const ChevronContainer = styled(animated.div)({
  width: chevronSize,
  height: chevronSize,
  transformOrigin: `${chevronSize / 2}px ${chevronSize / 2}px`, // -1 しないとちょっとずれる
  zIndex: 0, // Select より下に,
})

const Item = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: vars.space.s * 1.5,
  width: '100%',
  '&:first-of-type': {
    marginTop: 0,
  },
})

const PillContainer = styled('div')({
  marginRight: vars.space.m,
  overflow: 'hidden',
  minWidth: 0, // flexbox からはみ出さないようにするため
})

const _TypeSign = styled(TypeSign)({
  flexShrink: 0,
})

export default ExpectedObjectItem
