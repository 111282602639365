import styled from '@emotion/styled'
import * as React from 'react'
import { useHistory, useParams } from 'react-router'

import Button from '~/components/atoms/Button'
import Loader from '~/components/atoms/Loader'
import NoResults from '~/components/organisms/NoResults'
import { AuthPageLayout } from '~/presentation/auth/AuthPageLayout'
import { GoogleSignInButton } from '~/presentation/auth/GoogleSignInButton'
import { useGoogleSignUpByInvitation } from '~/presentation/auth/useGoogleSignUpByInvitation'
import { HOME, WORKFLOW_LIST } from '~/routes'
import * as vars from '~/styles/variables'

export const UserInvitationPage: React.FC = () => {
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState<string>()
  const history = useHistory()
  const { token } = useParams<{ token: string }>()
  const { signUp, verifyTokenResult } = useGoogleSignUpByInvitation(token)

  const handleClick = React.useCallback(async () => {
    setLoading(true)
    setErrorMessage(undefined)
    try {
      const success = await signUp()
      if (!success) {
        setErrorMessage('認証に失敗しました')
        return
      }
      history.push(WORKFLOW_LIST)
    } catch (error) {
      console.error(error)
      setErrorMessage(String(error))
    } finally {
      setLoading(false)
    }
  }, [history, signUp])

  if (verifyTokenResult.loading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    )
  }

  if (verifyTokenResult.error === undefined && !verifyTokenResult.data) {
    return (
      <NoResults
        heading="無効なURLです"
        description="URLの期限が切れています"
        renderFooter={() => (
          <Button to={HOME} style={{ marginTop: vars.space.m }}>
            Topに戻る
          </Button>
        )}
      />
    )
  }

  return (
    <AuthPageLayout>
      <Typings>Anyflowを新しく始める</Typings>
      <GoogleSignInButton
        label={'Googleアカウントでサインアップ'}
        loading={loading}
        onClick={handleClick}
      />
      {errorMessage !== undefined && <Error>{errorMessage}</Error>}
    </AuthPageLayout>
  )
}

const Typings = styled('h5')({
  fontSize: vars.fontSize.xl,
  marginTop: vars.space.l,
  marginBottom: vars.space.l,
})

const Error = styled('div')({
  marginTop: vars.space.m,
  color: vars.color.error,
  fontSize: vars.fontSize.s,
})

const LoaderContainer = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
})
