import styled from '@emotion/styled'
import * as React from 'react'

import { FetchStatus } from '~/common/types'
import * as utils from '~/common/utils'
import Link from '~/components/atoms/Link'
import Text from '~/components/atoms/Text'
import Pager from '~/components/molecules/Pager'
import StatusWithTooltip from '~/components/molecules/StatusWithTooltip'
import User from '~/components/molecules/User'
import NoResults from '~/components/organisms/NoResults'
import {
  Body,
  Cell,
  Header,
  HeaderCell,
  Row,
  Table,
} from '~/components/organisms/Table'
import { usePageQuery } from '~/presentation/utilityHooks'
import ContentLoader from '~/presentation/workflow/history/Loader'
import { useWorkflowInstanceSummaries } from '~/presentation/workflow/history/useWorkflowInstanceSummaries'
import * as routes from '~/routes'
import * as vars from '~/styles/variables'

interface Props {
  workflowId: string
}

const HistoryList: React.FC<Props> = ({ workflowId }) => {
  const page = usePageQuery()
  const {
    workflowInstanceSummaries,
    refetch,
    paginationMeta,
    fetchStatus,
  } = useWorkflowInstanceSummaries(workflowId, page)

  React.useEffect(() => {
    const timer = setInterval(() => {
      refetch(true)
    }, 5000)
    return () => {
      clearInterval(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <Container>
      {fetchStatus === FetchStatus.loading ? (
        <div style={{ marginTop: 65 }}>
          <ContentLoader />
        </div>
      ) : fetchStatus === FetchStatus.loaded &&
        workflowInstanceSummaries &&
        workflowInstanceSummaries.length > 0 ? (
        <>
          <_Table>
            <Header>
              <Row>
                <_HeaderCell style={{ width: 100 }}>ステータス</_HeaderCell>
                <_HeaderCell style={{ width: 'auto' }}>実行日時</_HeaderCell>
                <_HeaderCell style={{ width: 200 }}>実行者</_HeaderCell>
              </Row>
            </Header>
            <Body>
              {workflowInstanceSummaries.map((workflowInstanceSummary) => (
                <Row key={workflowInstanceSummary.instanceId}>
                  <_Cell>
                    <StatusWithTooltip
                      status={workflowInstanceSummary.runningStatus}
                    />
                  </_Cell>
                  <_Cell>
                    <_Link
                      to={routes.HISTORY_DETAIL}
                      params={{
                        workflowId,
                        workflowInstanceId: workflowInstanceSummary.instanceId,
                      }}
                    >
                      <Text element="span" fontSize="s" fontWeight="bold">
                        {utils.formatDate(workflowInstanceSummary.updatedAt)}
                      </Text>
                    </_Link>
                  </_Cell>
                  {/* <_Cell><div>{history.runner.username}</div></_Cell> */}
                  <_Cell>
                    <User username={workflowInstanceSummary.runner.username} />
                  </_Cell>
                </Row>
              ))}
            </Body>
          </_Table>
          {paginationMeta ? (
            <_Pager
              to={routes.HISTORY_LIST}
              totalCount={paginationMeta.totalCount}
              currentPageNumber={paginationMeta.currentPageNumber}
              countPerPage={paginationMeta.countPerPage}
              params={{ workflowId }}
            />
          ) : null}
        </>
      ) : fetchStatus === FetchStatus.loaded &&
        workflowInstanceSummaries &&
        workflowInstanceSummaries.length === 0 ? (
        <>
          <NoResults
            heading="実行履歴がありません"
            description="ワークフローが実行されると表示されます"
          />
        </>
      ) : null}
    </Container>
  )
}

const Container = styled('div')({
  paddingRight: vars.space.l,
  paddingLeft: vars.space.l,
  width: '100%',
})

const _Table = styled(Table)({
  marginTop: vars.space.m,
  tableLayout: 'fixed',
})

const _HeaderCell = styled(HeaderCell)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const _Cell = styled(Cell)({
  paddingTop: vars.space.s * 1.5,
  paddingRight: vars.space.s,
  paddingBottom: vars.space.s * 1.5,
  paddingLeft: vars.space.s,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const _Link = styled(Link)({
  color: vars.fontColor.primary,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
})

const _Pager = styled(Pager)({
  marginTop: vars.space.l,
})

export default HistoryList
