import React from 'react'

import { Pagination } from '~/common/Pagination'
import { Result } from '~/common/Result'
import { apiClients } from '~/common/apiClients'
import { Connection } from '~/domain/connection/Connection'
import { ConnectionService } from '~/domain/connection/ConnectionService'

const service: ConnectionService = apiClients.connectionService

export function useConnectionList(
  page: number
): {
  result: Result<Pagination<Connection>>
} {
  const [result, setResult] = React.useState<Result<Pagination<Connection>>>(
    new Result.Loading()
  )

  React.useEffect(() => {
    let stale = false
    service
      .getList(page)
      .then((res) => {
        if (stale) {
          return
        }
        setResult(new Result.Success(res))
      })
      .catch((e) => {
        if (stale) {
          return
        }
        console.error(e)
        setResult(new Result.Failure(e))
      })
    return () => {
      stale = true
    }
  }, [page])

  return { result }
}
