import produce from 'immer'
import { call, put, takeLatest } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { actionCreatorFactory } from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { Pagination, PaginationMeta } from '~/common/Pagination'
import { apiClients } from '~/common/apiClients'
import { FetchStatus } from '~/common/types'
import { MessageSummary } from '~/domain/workflow/instance/message/Message'
import { MessageFilter } from '~/domain/workflow/instance/message/MessageFilter'
import { MessageService } from '~/domain/workflow/instance/message/MessageService'
import { RootState } from '~/ducks'

const actionCreator = actionCreatorFactory('anyflow/message/list')

export const types = {
  RESET: 'RESET',
  FETCH: 'FETCH',
}

interface FetchResponse {
  messages: MessageSummary[]
  pager: PaginationMeta
}

interface FetchRequest {
  filter: MessageFilter
  page: string | number
  onSuccess?: () => void
}

export const actions = {
  reset: actionCreator(types.RESET),
  fetch: actionCreator.async<FetchRequest, FetchResponse, {}>(types.FETCH),
}

export const selectors = {
  getFetchStatus: createSelector(
    (state: RootState) => state.message.list.fetchStatus,
    (fetchStatus) => fetchStatus
  ),
  getMessages: createSelector(
    (state: RootState) => state.message.list.messages,
    (messages) => messages
  ),
  getPager: createSelector(
    (state: RootState) => state.message.list.pager,
    (pager) => pager
  ),
}

export type State = {
  fetchStatus: FetchStatus
  messages: MessageSummary[]
  pager: PaginationMeta | null
}

const initialState: State = {
  fetchStatus: FetchStatus.none,
  messages: [],
  pager: null,
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.reset, () => Object.assign({}, initialState))
  .case(actions.fetch.started, (state) =>
    produce(state, (draft) => {
      draft.fetchStatus = FetchStatus.loading
      draft.messages = []
      draft.pager = null
    })
  )
  .case(actions.fetch.done, (state, payload) =>
    produce(state, (draft) => {
      draft.fetchStatus = FetchStatus.loaded
      draft.messages = payload.result.messages
      draft.pager = payload.result.pager
    })
  )
  .case(actions.fetch.failed, (state) =>
    produce(state, (draft) => {
      draft.fetchStatus = FetchStatus.failed
    })
  )

const messageService: MessageService = apiClients.messageService

export const sagas = [
  takeLatest(actions.fetch.started, function* (action) {
    try {
      const { page, filter, onSuccess } = action.payload
      const payload: Pagination<MessageSummary> = yield call(
        messageService.getList,
        filter,
        page
      )
      yield put(
        actions.fetch.done({
          result: {
            messages: payload.items,
            pager: payload.paginationMeta,
          },
          params: action.payload,
        })
      )
      if (onSuccess) {
        onSuccess()
      }
    } catch (e) {
      console.error(e)
      yield put(actions.fetch.failed(e))
    }
  }),
]
