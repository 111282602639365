import React from 'react'

import ErrorMessage from '~/components/atoms/ErrorMessage'
import RangeSlider from '~/components/atoms/RangeSlider'
import {
  DateRangeValue,
  DateRangeWidgetDefinition,
} from '~/domain/workflow/widget/dateRange'
import { RawInputWidgetProps } from '~/presentation/workflow/detail/editor/form/inputWidget/RawInputWidget'
import { useInputWidgetValidation } from '~/presentation/workflow/detail/editor/form/validation/useValidation'
import * as vars from '~/styles/variables'

interface Range {
  from: number
  to: number
}

interface Props extends RawInputWidgetProps {
  definition: DateRangeWidgetDefinition
}

const DateRangeWidget: React.FC<Props> = (props) => {
  const { onChange } = props
  const validationResult = useInputWidgetValidation(props)

  const value: DateRangeValue = React.useMemo(() => {
    return DateRangeValue.fromInputValue(props.value)
  }, [props.value])

  const handleChange = React.useCallback(
    (newRange: Range) => {
      onChange(DateRangeValue.fromRange(newRange).toInputValue())
    },
    [onChange]
  )

  // このウィジェットは未入力状態がないので、 undefined が指定されていればすぐに onChange を実行する
  React.useEffect(() => {
    if (props.value === undefined) {
      onChange(new DateRangeValue().toInputValue())
    }
  }, [props.value, onChange])

  return (
    <div>
      <RangeSlider
        min={props.definition.startLimit}
        max={props.definition.endLimit}
        origin={0}
        step={1}
        beforeLabel="日前"
        afterLabel="日後"
        originLabel="今日"
        disabled={props.readonly}
        value={value}
        onChange={handleChange}
      />
      {validationResult?.valid === false && (
        <ErrorMessage style={{ marginTop: vars.space.s }}>
          {validationResult.cause.message}
        </ErrorMessage>
      )}
    </div>
  )
}

export default DateRangeWidget
