import { TokenPaged } from '~/common/Pagination'
import { delay } from '~/common/utils'
import { appDefinitions } from '~/data/workflow/app/entities'
import {
  AssistParameters,
  AssistService,
} from '~/domain/workflow/assist/AssistService'
import { AssistedValue } from '~/domain/workflow/assist/AssistedValue'

const limit = 10

export class MockAssistService implements AssistService {
  async getList(
    assistId: string,
    query: string,
    params: AssistParameters,
    pageToken?: string
  ): Promise<TokenPaged<AssistedValue>> {
    console.log(
      `[API] Mock assist API called.\nassistId: ${assistId}\nquery: ${query}\nparams: ${JSON.stringify(
        params
      )}\npageToken: ${pageToken}`
    )
    await delay(500)
    const page = Number.parseInt(pageToken ?? '0', 10)
    const results = appDefinitions.filter((it) => it.name.match(query))
    const items = results.slice(page * limit, (page + 1) * limit).map((app) => {
      return {
        label: app.name,
        value: app.iconPath,
      }
    })
    return {
      nextPageToken:
        (page + 1) * limit >= results.length ? undefined : String(page + 1),
      items,
    }
  }

  async getLabel(
    assistId: string,
    params: AssistParameters,
    value: unknown
  ): Promise<AssistedValue | undefined> {
    console.log(
      `[API] Mock assist API called.\nassistId: ${assistId}\nparams: ${JSON.stringify(
        params
      )}\nvalue: ${JSON.stringify(value)}`
    )
    await delay(1000)
    const found = appDefinitions.find((it) => it.iconPath === value)
    if (found === undefined) {
      return undefined
    }
    return {
      label: found.name,
      value: found.iconPath,
    }
  }
}
