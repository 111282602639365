import { run } from '~/common/utils'
import { AuthServiceImpl } from '~/data/auth/AuthServiceImpl'
import { MockAuthService } from '~/data/auth/MockAuthServiceImpl'
import { ConnectionServiceImpl } from '~/data/connection/ConnectionServiceImpl'
import { MockConnectionService } from '~/data/connection/MockConnectionService'
import { GroupServiceImpl } from '~/data/group/GroupServiceImpl'
import { MockGroupService } from '~/data/group/MockGroupService'
import { LookupTableApi } from '~/data/lookupTable/LookupTableApi'
import { LookupTableApiImpl } from '~/data/lookupTable/LookupTableApiImpl'
import { MockLookupTableApi } from '~/data/lookupTable/MockLookupTableApi'
import { MockRecipeService } from '~/data/recipe/MockRecipeService'
import { NetlifyRecipeService } from '~/data/recipe/NetlifyRecipeService'
import { MockSessionService } from '~/data/session/MockSessionService'
import { SessionServiceImpl } from '~/data/session/SessionServiceImpl'
import { InvitationServiceImpl } from '~/data/user/invitation/InvitationServiceImpl'
import { MockInvitationService } from '~/data/user/invitation/MockInvitationService'
import { MockOrganizationService } from '~/data/user/organization/MockOrganizationService'
import { OrganizationServiceImpl } from '~/data/user/organization/OrganizationServiceImpl'
import { MockWorkflowService } from '~/data/workflow/MockWorkflowService'
import { WorkflowServiceImpl } from '~/data/workflow/WorkflowServiceImpl'
import { ActionServiceImpl } from '~/data/workflow/action/ActionServiceImpl'
import { MockActionService } from '~/data/workflow/action/MockActionService'
import { AssistServiceImpl } from '~/data/workflow/assist/AssistServiceImpl'
import { MockAssistService } from '~/data/workflow/assist/MockAssistService'
import { FunctionServiceImpl } from '~/data/workflow/function/FunctionServiceImpl'
import { MockFunctionService } from '~/data/workflow/function/MockFunctionService'
import { MockWorkflowInstanceService } from '~/data/workflow/instance/MockWorkflowInstanceService'
import { WorkflowInstanceServiceImpl } from '~/data/workflow/instance/WorkflowInstanceServiceImpl'
import { TempMessageService } from '~/data/workflow/instance/message/TempMessageService'
import { TempTaskInstanceService } from '~/data/workflow/instance/taskInstance/TempTaskInstanceService'
import { MockObjectService } from '~/data/workflow/object/MockObjectService'
import { ObjectServiceImpl } from '~/data/workflow/object/ObjectServiceImpl'
import { MockWorkflowSourceService } from '~/data/workflow/source/MockWorkflowSourceService'
import { WorkflowSourceServiceImpl } from '~/data/workflow/source/WorkflowSourceServiceImpl'
import { MockTestPreviewApi } from '~/data/workflow/test/MockTestPreviewApi'
import { MockTestService } from '~/data/workflow/test/MockTestService'
import { TestPreviewApi } from '~/data/workflow/test/TestPreviewApi'
import { TestPreviewApiImpl } from '~/data/workflow/test/TestPreviewApiImpl'
import { TestServiceImpl } from '~/data/workflow/test/TestServiceImpl'
import { MockTriggerService } from '~/data/workflow/trigger/MockTriggerService'
import { TriggerServiceImpl } from '~/data/workflow/trigger/TriggerServiceImpl'
import { MockTriggerInstructionService } from '~/data/workflow/triggerInstruction/MockTriggerInstructionService'
import { TriggerInstructionServiceImpl } from '~/data/workflow/triggerInstruction/TriggerInstructionServiceImpl'
import { MockSalesforceSearchConditionService } from '~/data/workflow/widget/salesforceSearchCondition/MockSalesforceSearchConditionService'
import { SalesforceSearchConditionServiceImpl } from '~/data/workflow/widget/salesforceSearchCondition/SalesforceSearchConditionServiceImpl'
import { AuthService } from '~/domain/auth/AuthService'
import { ConnectionService } from '~/domain/connection/ConnectionService'
import { GroupService } from '~/domain/group/GroupService'
import { RecipeService } from '~/domain/recipe/RecipeService'
import { SessionService } from '~/domain/session/SessionService'
import { InvitationService } from '~/domain/user/InvitationService'
import { OrganizationService } from '~/domain/user/OrganizationService'
import { WorkflowService } from '~/domain/workflow/WorkflowService'
import { ActionService } from '~/domain/workflow/action/ActionService'
import { AssistService } from '~/domain/workflow/assist/AssistService'
import { FunctionService } from '~/domain/workflow/function/FunctionService'
import { WorkflowInstanceService } from '~/domain/workflow/instance/WorkflowInstanceService'
import { MessageService } from '~/domain/workflow/instance/message/MessageService'
import { TaskInstanceService } from '~/domain/workflow/instance/taskInstance/TaskInstanceService'
import { ObjectService } from '~/domain/workflow/object/ObjectService'
import { WorkflowSourceService } from '~/domain/workflow/source/WorkflowSourceService'
import { TestService } from '~/domain/workflow/test/TestService'
import { TriggerService } from '~/domain/workflow/trigger/TriggerService'
import { TriggerInstructionService } from '~/domain/workflow/triggerInstruction/TriggerInstructionService'
import { SalesforceSearchConditionService } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchConditionService'

interface ApiClients {
  workflowService: WorkflowService
  workflowSourceService: WorkflowSourceService
  workflowInstanceService: WorkflowInstanceService
  taskInstanceService: TaskInstanceService
  messageService: MessageService
  objectService: ObjectService
  functionService: FunctionService
  actionService: ActionService
  triggerService: TriggerService
  recipeService: RecipeService
  assistService: AssistService
  testService: TestService
  testPreviewApi: TestPreviewApi
  // TODO: 循環参照を修正して TestPreviewService もここで提供する
  triggerInstructionService: TriggerInstructionService
  lookupTableApi: LookupTableApi
  salesforceSearchConditionService: SalesforceSearchConditionService
  groupService: GroupService
  organizationService: OrganizationService
  connectionService: ConnectionService
  sessionService: SessionService
  authService: AuthService
  invitationService: InvitationService
}

// @ts-ignore
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const mockApiClients: ApiClients = {
  actionService: new MockActionService(),
  functionService: new MockFunctionService(),
  messageService: new TempMessageService(),
  objectService: new MockObjectService(),
  recipeService: new MockRecipeService(),
  taskInstanceService: new TempTaskInstanceService(),
  triggerService: new MockTriggerService(),
  workflowService: new MockWorkflowService(),
  workflowInstanceService: new MockWorkflowInstanceService(),
  workflowSourceService: new MockWorkflowSourceService(),
  assistService: new MockAssistService(),
  testService: new MockTestService(),
  testPreviewApi: new MockTestPreviewApi(),
  triggerInstructionService: new MockTriggerInstructionService(),
  lookupTableApi: new MockLookupTableApi(),
  salesforceSearchConditionService: new MockSalesforceSearchConditionService(),
  groupService: new MockGroupService(),
  organizationService: new MockOrganizationService(),
  connectionService: new MockConnectionService(),
  sessionService: new MockSessionService(),
  authService: new MockAuthService(),
  invitationService: new MockInvitationService(),
}

const realApiClients: ApiClients = {
  actionService: new ActionServiceImpl(),
  functionService: new FunctionServiceImpl(),
  messageService: new TempMessageService(),
  objectService: new ObjectServiceImpl(),
  recipeService: new NetlifyRecipeService(),
  taskInstanceService: new TempTaskInstanceService(),
  triggerService: new TriggerServiceImpl(),
  workflowService: new WorkflowServiceImpl(),
  workflowInstanceService: new WorkflowInstanceServiceImpl(),
  workflowSourceService: new WorkflowSourceServiceImpl(),
  assistService: new AssistServiceImpl(),
  testService: new TestServiceImpl(),
  testPreviewApi: new TestPreviewApiImpl(),
  triggerInstructionService: new TriggerInstructionServiceImpl(),
  lookupTableApi: new LookupTableApiImpl(),
  salesforceSearchConditionService: new SalesforceSearchConditionServiceImpl(),
  groupService: new GroupServiceImpl(),
  organizationService: new OrganizationServiceImpl(),
  connectionService: new ConnectionServiceImpl(),
  sessionService: new SessionServiceImpl(),
  authService: new AuthServiceImpl(),
  invitationService: new InvitationServiceImpl(),
}

export const apiClients: ApiClients = run(() => {
  return realApiClients
})
