import styled from '@emotion/styled'
import * as React from 'react'

import Inner from '~/components/organisms/Inner'
import { useIsTablet } from '~/containers/Home/useIsTablet'
import * as vars from '~/styles/variables'

interface Medium {
  name: string
  imagePath: string
}

export const data: Medium[] = [
  {
    name: '日経',
    imagePath: '/static/images/lp/media/nikkei.svg',
  },
  {
    name: 'TechCrunch',
    imagePath: '/static/images/lp/media/techcrunch.png',
  },
  {
    name: 'BRIDGE',
    imagePath: '/static/images/lp/media/bridge.svg',
  },
  {
    name: 'Forbes',
    imagePath: '/static/images/lp/media/forbes.png',
  },
  {
    name: 'ASCII',
    imagePath: '/static/images/lp/media/ascii.jpeg',
  },
  {
    name: 'Excite',
    imagePath: '/static/images/lp/media/excite.svg',
  },
  {
    name: 'キャリアハック',
    imagePath: '/static/images/lp/media/careerhack.svg',
  },
  {
    name: 'SELECK',
    imagePath: '/static/images/lp/media/seleck.jpeg',
  },
]

const Media: React.FC = () => {
  const isTablet = useIsTablet()
  return (
    <>
      <Heading>メディア掲載</Heading>
      <Inner size="m" responsive={true}>
        <List>
          {data.map((item) => (
            <Item key={item.name}>
              <img
                src={item.imagePath}
                alt={item.name}
                style={{
                  display: 'block',
                  width: isTablet ? '50%' : '70%',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                }}
              />
            </Item>
          ))}
        </List>
      </Inner>
    </>
  )
}

const Heading = styled('h1')({
  fontWeight: 800,
  fontSize: vars.fontSize.xxxxl,
  lineHeight: vars.lineHeight.heading,
  textAlign: 'center',
  [vars.media.tablet]: {
    fontSize: vars.fontSize.xl,
  },
})

const List = styled('ul')({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  paddingLeft: 0,
  listStyle: 'none',
})

const Item = styled('li')({
  marginTop: vars.space.l,
  width: 'calc(100% / 4)',
  [vars.media.tablet]: {
    width: '50%',
  },
})

export default Media
