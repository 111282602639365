import { Tson } from '~/data/Tson'
import { ArgumentJson } from '~/data/workflow/function/ArgumentJson'
import { mapToValueTypeJson } from '~/data/workflow/valueType/mapToValueTypeJson'

export function mapToArgumentJson(tson: Tson): ArgumentJson {
  return {
    key: tson.getString('key'),
    name: tson.getString('name'),
    description: tson.getString('description'),
    position: tson.getNumber('position'),
    required: tson.getBoolean('required'),
    valueType: mapToValueTypeJson(tson.getValue('valueType')),
    isVariableLength: tson.getBoolean('isVariableLength'),
  }
}
