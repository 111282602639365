import styled from '@emotion/styled'
import * as React from 'react'

import { EmailValidationText, isSubmittableEmail } from '~/common/validateEmail'
import Text from '~/components/atoms/Text'
import TextField from '~/components/atoms/TextField'
import CtaButton from '~/containers/RecipeLp/CtaButton'
import * as vars from '~/styles/variables'

export interface FormValues {
  company: string
  last_name: string
  first_name: string
  email: string
  phone?: string
}

interface Props {
  onSubmit: (values: FormValues) => void
}

export const Form: React.FC<Props> = (props) => {
  const [company, setCompanyName] = React.useState<string>('')
  const [lastName, setLastName] = React.useState<string>('')
  const [firstName, setFirstName] = React.useState<string>('')
  const [email, setMailAddress] = React.useState<string>('')
  const [phone, setPhoneNumber] = React.useState<string | undefined>()

  const submitEnabled =
    [company, lastName, firstName, email].every((str) => str !== '') &&
    isSubmittableEmail(email)

  return (
    <>
      <Row>
        <Label htmlFor="company" required={true}>
          会社名
        </Label>
        <_TextField
          id="company"
          maxLength={40}
          name="company"
          size={20}
          type="text"
          value={company}
          placeholder="例: Anyflow株式会社"
          onChange={(e) => setCompanyName(e.currentTarget.value)}
          required={true}
        />
      </Row>
      <Row>
        <Horizontal>
          <HorizontalItem>
            <Label htmlFor="last_name" required={true}>
              姓
            </Label>
            <_TextField
              id="last_name"
              maxLength={80}
              name="last_name"
              size={20}
              type="text"
              value={lastName}
              placeholder="例: 田中"
              onChange={(e) => setLastName(e.currentTarget.value)}
              required={true}
            />
          </HorizontalItem>
          <HorizontalItem>
            <Label htmlFor="first_name" required={true}>
              名
            </Label>
            <_TextField
              id="first_name"
              maxLength={40}
              name="first_name"
              size={20}
              type="text"
              value={firstName}
              placeholder="例: 太郎"
              onChange={(e) => setFirstName(e.currentTarget.value)}
              required={true}
            />
          </HorizontalItem>
        </Horizontal>
      </Row>
      <Row>
        <Horizontal>
          <HorizontalItem>
            <Label htmlFor="email" required={true}>
              メール
            </Label>
            <_TextField
              id="email"
              maxLength={80}
              name="email"
              size={20}
              type="text"
              value={email}
              placeholder="例: taro@anyflow.co.jp"
              onChange={(e) => setMailAddress(e.currentTarget.value)}
              required={true}
            />
            <Annotation
              element="p"
              fontSize="xs"
              color={vars.fontColor.tertiary}
            >
              ※フリーアドレスはご利用できません
            </Annotation>
            <EmailValidationText mailAddress={email} />
          </HorizontalItem>
          <HorizontalItem>
            <Label htmlFor="phone" required={false}>
              電話
            </Label>
            <_TextField
              id="phone"
              maxLength={40}
              name="phone"
              size={20}
              type="text"
              value={phone}
              placeholder="例: 03-0000-0000"
              onChange={(e) => setPhoneNumber(e.currentTarget.value)}
              required={false}
            />
          </HorizontalItem>
        </Horizontal>
      </Row>
      <Row>
        <div
          onClick={(e) => {
            e.preventDefault()
            if (!submitEnabled) {
              return
            }
            props.onSubmit({
              company,
              first_name: firstName,
              last_name: lastName,
              email,
              phone,
            })
          }}
        >
          <CtaButton
            disabled={!submitEnabled}
            style={{
              width: '100%',
              paddingTop: vars.space.m,
              paddingBottom: vars.space.m,
              fontSize: vars.fontSize.l,
            }}
          >
            入力内容を送信
          </CtaButton>
        </div>
      </Row>
    </>
  )
}

const Row = styled('div')({
  marginTop: vars.space.m,
  '&:first-of-type': {
    marginTop: 0,
  },
})

const Annotation = styled(Text)({
  marginTop: vars.space.s,
})

const Label = styled('label')(
  {
    display: 'block',
    marginBottom: vars.space.xs,
    fontSize: vars.fontSize.s,
    fontWeight: 'bold',
  },
  (props: { required: boolean }) => ({
    fontWeight: props.required ? vars.fontWeight.bold : vars.fontWeight.normal,
    ...(props.required
      ? {
          '&::after': {
            content: '"*"',
            color: vars.color.error,
          },
        }
      : {}),
  })
)

const Horizontal = styled('div')({
  display: 'flex',
  [vars.media.tablet]: {
    flexDirection: 'column',
  },
})

const HorizontalItem = styled('div')({
  flexGrow: 1,
  flexBasis: '50%',
  '&:first-of-type': {
    marginRight: vars.space.l,
  },
  [vars.media.tablet]: {
    flexBasis: 'auto',
    '&:first-of-type': {
      marginRight: 0,
      marginBottom: vars.space.m,
    },
  },
})

const _TextField = styled(TextField)({})
