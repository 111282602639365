import { JsonError } from '~/data/JsonError'
import { Tson } from '~/data/Tson'
import {
  DynamicFieldJson,
  FieldJson,
  InfoViewFieldJson,
  StaticFieldJson,
} from '~/data/workflow/field/FieldJson'
import { mapToValueTypeJson } from '~/data/workflow/valueType/mapToValueTypeJson'
import { mapToInputWidgetJson } from '~/data/workflow/widget/mapToInputWidgetJson'
import { mapToViewWidgetJson } from '~/data/workflow/widget/mapToViewWidgetJson'

export function mapToFieldJson(tson: Tson): FieldJson {
  const fieldType = tson.getString('fieldType')
  switch (fieldType) {
    case 'static':
      return mapToStaticFieldJson(tson)
    case 'dynamic':
      return mapToDynamicFieldJson(tson)
    case 'info_view':
      return mapToInfoViewFieldJson(tson)
    default:
      throw new JsonError(`Unknown field type: ${fieldType}`)
  }
}

function mapToStaticFieldJson(tson: Tson): StaticFieldJson {
  return {
    fieldType: 'static',
    key: tson.getString('key'),
    label: tson.getString('label'),
    note: tson.getString('note'),
    required: tson.getBoolean('required'),
    renderable: tson.getBoolean('renderable'),
    bulkable: tson.getBoolean('bulkable'),
    dependencies: tson.getArray('dependencies').map((it) => it.asString()),
    valueType: mapToValueTypeJson(tson.getValue('valueType')),
    form: mapToInputWidgetJson(tson.getValue('form')),
  }
}

function mapToDynamicFieldJson(tson: Tson): DynamicFieldJson {
  const dynamicField = tson.getValue('dynamicField')
  return {
    fieldType: 'dynamic',
    key: tson.getString('key'),
    label: tson.getString('label'),
    note: tson.getString('note'),
    required: tson.getBoolean('required'),
    renderable: tson.getBoolean('renderable'),
    bulkable: tson.getBoolean('bulkable'),
    dependencies: tson.getArray('dependencies').map((it) => it.asString()),
    dynamicField: {
      dynamicFieldId: dynamicField.getString('dynamicFieldId'),
      parameterFieldKeys: dynamicField
        .getArray('parameterFieldKeys')
        .map((it) => it.asString()),
    },
  }
}

function mapToInfoViewFieldJson(tson: Tson): InfoViewFieldJson {
  return {
    fieldType: 'info_view',
    key: tson.getString('key'),
    dependencies: tson.getArray('dependencies').map((it) => it.asString()),
    form: mapToViewWidgetJson(tson.getValue('form')),
  }
}
