import config from '~/common/config'
import { JsonError } from '~/data/JsonError'
import { Tson } from '~/data/Tson'
import requestJson from '~/data/request'
import { SalesforceDataType } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceDataType'
import { SalesforceSObject } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSObject'
import { SalesforceSearchConditionService } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchConditionService'

export class SalesforceSearchConditionServiceImpl
  implements SalesforceSearchConditionService {
  async getSObject(
    accountUid: string,
    sObjectName: string
  ): Promise<SalesforceSObject> {
    const res = await requestJson(
      `${config.apiRoot}/specific_service_apis/salesforce/fields_for_condition_widget?account_uid=${accountUid}&sobject_name=${sObjectName}`
    )
    const json = mapToJson(new Tson(res))
    return new SalesforceSObject(
      accountUid,
      sObjectName,
      json.fields.map((it) => ({
        id: it.id,
        label: it.label,
        dataType: createDataType(it),
      }))
    )
  }
}

interface ResponseJson {
  fields: FieldJson[]
}

interface FieldJson {
  id: string
  label: string
  dataType: string
  picklist?: PickListItemJson[]
}

interface PickListItemJson {
  label: string
  value: string
}

function mapToJson(tson: Tson): ResponseJson {
  return {
    fields: tson.getArray('fields').map(mapToFieldJson),
  }
}

function mapToFieldJson(tson: Tson): FieldJson {
  return {
    id: tson.getString('id'),
    dataType: tson.getString('dataType'),
    label: tson.getString('label'),
    picklist:
      tson.getArray('picklist', false)?.map(mapToPickListItemJson) ?? undefined,
  }
}

function mapToPickListItemJson(tson: Tson): PickListItemJson {
  return {
    label: tson.getString('label'),
    value: tson.getString('value'),
  }
}

function createDataType(json: FieldJson): SalesforceDataType {
  switch (json.dataType) {
    case 'string':
      return new SalesforceDataType.String()
    case 'number':
      return new SalesforceDataType.Number()
    case 'boolean':
      return new SalesforceDataType.Boolean()
    case 'date':
      return new SalesforceDataType.Date()
    case 'picklist': {
      if (json.picklist === undefined) {
        throw new JsonError('picklist required')
      }
      return new SalesforceDataType.Picklist(json.picklist)
    }
    case 'multipicklist': {
      if (json.picklist === undefined) {
        throw new JsonError('picklist required')
      }
      return new SalesforceDataType.MultiPicklist(json.picklist)
    }
    case 'reference':
      return new SalesforceDataType.Reference()
    default:
      throw new JsonError(`Unknown data type: ${json.dataType}`)
  }
}
