import styled from '@emotion/styled'
import * as React from 'react'

import * as vars from '~/styles/variables'
import {
  widgetAnimationStyle,
  widgetBorderErrorStyle,
  widgetBorderStyle,
  widgetFocusedStyle,
} from '~/styles/widget'

interface OwnProps {
  hasError?: boolean
  hasBorder?: boolean
}

type Props = React.InputHTMLAttributes<HTMLInputElement> & OwnProps

const NumberField: React.FC<Props> = ({
  hasError = false,
  hasBorder = true,
  ...props
}) => (
  <Input type="number" hasBorder={hasBorder} hasError={hasError} {...props} />
)

const Input = styled('input')(
  {
    paddingRight: vars.space.m,
    paddingLeft: vars.space.m,
    display: 'block',
    width: '100%',
    height: vars.height.field,
    color: vars.fontColor.primary,
    fontSize: vars.fontSize.m,
    lineHeight: 1.5,
    outline: 'none',
    borderRadius: widgetBorderStyle.borderRadius,
    ...widgetAnimationStyle,
    '&::placeholder': {
      color: vars.fontColor.tertiary,
    },
  },
  (props: { hasBorder: boolean; hasError: boolean }) => ({
    border: props.hasBorder
      ? props.hasError
        ? widgetBorderErrorStyle.border
        : widgetBorderStyle.border
      : 'none',
    ...(() =>
      props.hasBorder
        ? {
            '&:focus': {
              ...widgetFocusedStyle,
            },
          }
        : {})(),
  })
)

export default NumberField
