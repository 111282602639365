import { assert } from '~/common/utils'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { CombinationType } from '~/domain/workflow/widget/salesforceSearchCondition/CombinationType'
import { SalesforceSObject } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSObject'
import { SalesforceSearchCondition } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchCondition'

export class SalesforceSearchConditionGroup {
  public readonly combinationType: CombinationType
  public readonly conditions: SalesforceSearchCondition[]

  constructor(
    combinationType: CombinationType = 'and',
    conditions: SalesforceSearchCondition[] = []
  ) {
    this.combinationType = combinationType
    this.conditions =
      conditions.length === 0 ? [new SalesforceSearchCondition()] : conditions
  }

  static fromInputValue(
    sObject: SalesforceSObject,
    inputValue: InputValue
  ): SalesforceSearchConditionGroup {
    const { combinationType, conditions: conditionInputValues } = unpack(
      inputValue
    )
    const conditions = conditionInputValues?.map((it) =>
      SalesforceSearchCondition.fromInputValue(sObject, it)
    )
    return new SalesforceSearchConditionGroup(combinationType, conditions)
  }

  setCombinationType(
    combinationType: CombinationType
  ): SalesforceSearchConditionGroup {
    return new SalesforceSearchConditionGroup(combinationType, this.conditions)
  }

  addCondition(
    condition: SalesforceSearchCondition
  ): SalesforceSearchConditionGroup {
    return new SalesforceSearchConditionGroup(this.combinationType, [
      ...this.conditions,
      condition,
    ])
  }

  updateCondition(
    index: number,
    condition: SalesforceSearchCondition
  ): SalesforceSearchConditionGroup {
    return new SalesforceSearchConditionGroup(
      this.combinationType,
      this.conditions.map((old, i) => (i === index ? condition : old))
    )
  }

  removeCondition(index: number): SalesforceSearchConditionGroup {
    return new SalesforceSearchConditionGroup(
      this.combinationType,
      this.conditions.filter((old, i) => i !== index)
    )
  }

  toInputValue(): InputValue.Raw | undefined {
    if (this.conditions.length === 0) {
      return undefined
    }
    if (
      this.conditions.length === 1 &&
      this.conditions[0].toInputValue() === undefined
    ) {
      return undefined
    }
    return {
      mode: 'raw',
      raw: [
        {
          key: 'combination_type',
          value: { mode: 'raw', raw: this.combinationType },
        },
        {
          key: 'conditions',
          value: {
            mode: 'raw',
            raw: this.conditions.map((it) => it.toInputValue()),
          },
        },
      ],
    }
  }
}

function unpack(
  inputValue: InputValue
): {
  combinationType?: CombinationType
  conditions?: InputValue[]
} {
  if (inputValue === undefined) {
    return {
      combinationType: undefined,
      conditions: undefined,
    }
  }
  assert(inputValue.mode === 'raw', 'raw input value required')
  assert(
    InputValue.isStructEntryList(inputValue.raw),
    'struct input value required'
  )

  let combinationType: CombinationType | undefined
  const combinationTypeValue = inputValue.raw.find(
    (it) => it.key === 'combination_type'
  )?.value
  if (combinationTypeValue === undefined) {
    combinationType = undefined
  } else {
    assert(combinationTypeValue.mode === 'raw', 'raw input value required')
    assert(
      typeof combinationTypeValue.raw === 'string',
      'string input value required'
    )
    assert(
      combinationTypeValue.raw === 'and' || combinationTypeValue.raw === 'or',
      'combinationType must be one of "and" or "or"'
    )
    combinationType = combinationTypeValue.raw
  }

  let conditions: InputValue[] | undefined
  const conditionsValue = inputValue.raw.find((it) => it.key === 'conditions')
    ?.value
  if (conditionsValue === undefined) {
    conditions = undefined
  } else {
    assert(conditionsValue.mode === 'raw', 'raw input value required')
    assert(
      InputValue.isInputValueList(conditionsValue.raw),
      'input value list required'
    )
    conditions = conditionsValue.raw
  }

  return { combinationType, conditions }
}
