import * as d3 from 'd3'
import * as React from 'react'

import { Entity } from '~/common/types'
import { appDefinitions } from '~/data/workflow/app/entities'
import { ActionDefinition } from '~/domain/workflow/action/ActionDefinition'
import { AppDefinition } from '~/domain/workflow/app/AppDefinition'
import {
  TaskSource,
  TriggerSource,
  WorkflowSourceBody,
} from '~/domain/workflow/source/WorkflowSourceBody'
import { TriggerDefinition } from '~/domain/workflow/trigger/TriggerDefinition'
import { useDefinitions } from '~/presentation/AnyflowAppContext'

const nodeSize = 200

export type Node = TaskNode | TriggerNode

interface BaseNode {
  type: string
  /**
   * ノード固有の ID
   * @example
   * トリガーの場合: "trigger"
   * アクションの場合: taskId
   */
  nodeId: string
  data: any
  appDefinition: AppDefinition
  children: TaskNode[]
}

export interface TaskNode extends BaseNode {
  type: 'task'
  data: TaskSource
  actionDefinition: ActionDefinition
}

export interface TriggerNode extends BaseNode {
  type: 'trigger'
  data: TriggerSource
  triggerDefinition: TriggerDefinition
}

/**
 * Task を Node に変換
 */
export function transformTaskToNode(
  task: TaskSource,
  actionDefinitions: Entity<ActionDefinition>
): TaskNode {
  const action = actionDefinitions[task.actionId]
  if (!action) {
    throw new Error(`No such action ID: ${task.actionId}`)
  }
  const app = appDefinitions.find((it) => it.appId === action.appId)
  if (app === undefined) {
    throw new Error(`No such app ID: ${action.appId}`)
  }
  return {
    type: 'task',
    nodeId: task.taskId,
    data: Object.assign({}, task, { tasks: [] }),
    children: task.tasks.map((childTask) => {
      return transformTaskToNode(childTask, actionDefinitions)
    }),
    actionDefinition: action,
    appDefinition: app,
  }
}

/** Workflowソースからツリーの座標計算 */
export function useWorkflowTree(
  source?: WorkflowSourceBody
): [d3.HierarchyPointNode<Node>[], d3.HierarchyPointLink<Node>[]] {
  const [nodes, setNodes] = React.useState<d3.HierarchyPointNode<Node>[]>([])
  const [links, setLinks] = React.useState<d3.HierarchyPointLink<Node>[]>([])
  const definitions = useDefinitions()

  React.useEffect(() => {
    if (!source) {
      return
    }
    const trigger = source.getTrigger()
    if (trigger === undefined) {
      return
    }
    const triggerDefinition = definitions.getTrigger(trigger.triggerId)
    const triggerAppDefinition = definitions.getApp(triggerDefinition.appId)
    const rootTask = source.getRootTask()
    // Task を Node に変換
    // トリガー Node の下にタスク Node をつける
    const triggerNode: TriggerNode = {
      type: 'trigger',
      nodeId: 'trigger',
      data: trigger,
      triggerDefinition,
      appDefinition: triggerAppDefinition,
      children: rootTask
        ? [transformTaskToNode(rootTask, definitions.actionsById)]
        : [],
    }
    // 再帰構造をパース
    const parsed = d3.hierarchy<Node>(triggerNode, (d) => d.children)
    // ツリーの定義
    const tree = d3.tree<Node>().nodeSize([nodeSize * 1.2, nodeSize * 1.2])
    // パースした構造をツリーに適用
    const rootNode = tree(parsed)
    // flatten
    setNodes(rootNode.descendants())
    setLinks(rootNode.links())
  }, [source, definitions])
  return [nodes, links]
}
