import styled from '@emotion/styled'
import * as React from 'react'

import * as vars from '~/styles/variables'

interface App {
  name: string
  imagePath: string
}

const data: App[] = [
  {
    name: 'Gmail',
    imagePath: '/static/images/lp/apps/gmail@2x.png',
  },
  {
    name: 'スプレッドシート',
    imagePath: '/static/images/lp/apps/g_spreadsheets@2x.png',
  },
  { name: 'GoogleDrive', imagePath: '/static/images/lp/apps/g_drive@2x.png' },
  { name: 'Slack', imagePath: '/static/images/lp/apps/slack@2x.png' },
  { name: 'Salesforce', imagePath: '/static/images/lp/apps/salesforce@2x.png' },
  { name: 'SmartHR', imagePath: '/static/images/lp/apps/smarthr@2x.png' },
  {
    name: 'クラウドサイン',
    imagePath: '/static/images/lp/apps/cloudsign@2x.png',
  },
  {
    name: 'AndMore',
    imagePath: '/static/images/lp/apps/andmore@2x.png',
  },
]

const Applications: React.FC = () => {
  return (
    <>
      <Heading>SaaS一覧</Heading>
      <ApplicationContainer>
        {data.map((app) => (
          <ImageContainer key={app.name}>
            <img
              alt={app.name}
              srcSet={`${app.imagePath} 2x`}
              width="150"
              height="150"
              style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
            />
          </ImageContainer>
        ))}
      </ApplicationContainer>
    </>
  )
}

const Heading = styled('h1')({
  fontWeight: 800,
  fontSize: vars.fontSize.xxxxl,
  lineHeight: vars.lineHeight.heading,
  textAlign: 'center',
  [vars.media.tablet]: {
    fontSize: vars.fontSize.xl,
  },
})

const ApplicationContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
})

const ImageContainer = styled('div')({
  display: 'inline-block',
  width: 150,
  margin: vars.space.m,
  [vars.media.tablet]: {
    width: 120,
  },
})

export default Applications
