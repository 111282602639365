import styled from '@emotion/styled'
import * as React from 'react'
import { Play } from 'react-feather'

import Text from '~/components/atoms/Text'
import * as vars from '~/styles/variables'

interface Props {
  onClick: () => void
  className?: string
  disabled?: boolean
}

const ExecButton: React.FC<Props> = ({
  onClick,
  className,
  disabled = false,
}) => {
  return (
    <Container className={className} onClick={onClick} disabled={disabled}>
      <Play
        fill={vars.color.white}
        size={14}
        color={vars.color.white}
        style={{
          marginRight: vars.space.xs,
        }}
      />
      <Text element="span" fontSize="s" color="white" fontWeight="bold">
        実行
      </Text>
    </Container>
  )
}

const Container = styled('button')(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    width: 70,
    height: vars.height.switch,
    borderRadius: vars.height.switch / 2,
    cursor: 'pointer',
    backgroundColor: vars.color.success,
    pointerEvents: 'auto',
    border: 'none',
  },
  (props: { disabled: boolean }) =>
    props.disabled && {
      cursor: 'not-allowed',
      opacity: 0.5,
    }
)

export default ExecButton
