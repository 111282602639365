import React from 'react'
import * as Icon from 'react-feather'

import Button from '~/components/atoms/Button'
import { SalesforceSObject } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSObject'
import { SalesforceSearchCondition } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchCondition'
import { SalesforceSearchConditionGroup } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchConditionGroup'
import { CombinationTypeSwitcher } from '~/presentation/workflow/detail/editor/form/inputWidget/salesforceSearchCondition/CombinationTypeSwitcher'
import SalesforceSearchConditionComponent from '~/presentation/workflow/detail/editor/form/inputWidget/salesforceSearchCondition/SalesforceSearchConditionComponent'
import * as vars from '~/styles/variables'

interface Props {
  value: SalesforceSearchConditionGroup
  onChange: (newValue: SalesforceSearchConditionGroup) => void
  required: boolean
  readonly: boolean
  canUseStateOperators: boolean
  sObject: SalesforceSObject
}

const SalesforceSearchConditionGroupComponent: React.FC<Props> = (props) => {
  return (
    <div>
      {props.value.conditions.map((condition, i) => (
        <React.Fragment key={i}>
          <div
            style={{
              padding: vars.space.m,
              backgroundColor: vars.colorPalette.gray0,
              borderRadius: vars.borderRadius.m,
            }}
          >
            {props.value.conditions.length > 1 && !props.readonly && (
              <p style={{ textAlign: 'right' }}>
                <Icon.X
                  size={16}
                  color={vars.color.icon}
                  style={{ cursor: 'pointer' }}
                  onClick={() => props.onChange(props.value.removeCondition(i))}
                />
              </p>
            )}
            <SalesforceSearchConditionComponent
              value={condition}
              onChange={(newCondition) =>
                props.onChange(props.value.updateCondition(i, newCondition))
              }
              required={props.required}
              readonly={props.readonly}
              canUseStateOperators={props.canUseStateOperators}
              sObject={props.sObject}
            />
          </div>
          {i !== props.value.conditions.length - 1 && (
            <CombinationTypeSwitcher
              value={props.value.combinationType}
              disabled={props.readonly}
              onChange={(newCombinationType) =>
                props.onChange(
                  props.value.setCombinationType(newCombinationType)
                )
              }
              style={{ marginTop: vars.space.m, marginBottom: vars.space.m }}
            />
          )}
        </React.Fragment>
      ))}
      <div style={{ textAlign: 'right', marginTop: vars.space.m }}>
        <Button
          type="secondary"
          disabled={props.readonly}
          onClick={() =>
            props.onChange(
              props.value.addCondition(new SalesforceSearchCondition())
            )
          }
        >
          項目を追加する
        </Button>
      </div>
    </div>
  )
}

export default SalesforceSearchConditionGroupComponent
