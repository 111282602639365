import 'whatwg-fetch'

const request = <T = any>(
  url: string,
  config: RequestInit = {},
  isResponseText?: boolean
): Promise<T> => {
  const _config = Object.assign(
    {},
    {
      method: 'GET',
      body: undefined,
      headers: {},
      credentials: 'include',
    },
    config
  )

  const requestInit: RequestInit = {
    method: _config.method,
    body: _config.body,
    headers: _config.headers,
    mode: 'cors',
    credentials: _config.credentials,
  }

  return fetch(url, requestInit).then(async (response) => {
    if (!response.ok) {
      throw await response.json()
    }

    if (isResponseText) {
      return response.text()
    }

    return response.json()
  })
}
export default request
