import styled from '@emotion/styled'
import React from 'react'
import ReactDOM from 'react-dom'

import HoverCard from '~/components/molecules/HoverCard/HoverCard'
import { HoverCardProps } from '~/components/molecules/HoverCard/types'
import * as vars from '~/styles/variables'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  card: HoverCardProps
  disabled?: boolean
}

const HoverCardTarget: React.FC<Props> = (props) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const timerRef = React.useRef<number>()
  const [isShown, setIsShown] = React.useState<boolean>(false)
  const [targetRect, setTargetRect] = React.useState<DOMRect>()

  const handleMouseEnter = React.useCallback(() => {
    if (props.disabled) {
      return
    }
    window.clearTimeout(timerRef.current)
    setTargetRect(ref.current?.getBoundingClientRect())
    timerRef.current = window.setTimeout(() => {
      setIsShown(true)
    }, 300)
  }, [props.disabled])

  const handleMouseLeave = React.useCallback(() => {
    if (props.disabled) {
      return
    }
    window.clearTimeout(timerRef.current)
    timerRef.current = window.setTimeout(() => {
      setIsShown(false)
    }, 50)
  }, [props.disabled])

  const portal = React.useMemo(() => document.getElementById('portal-root'), [])
  if (portal === null) {
    return null
  }
  return (
    <div
      ref={ref}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {props.children}
      {isShown &&
        targetRect !== undefined &&
        ReactDOM.createPortal(
          <HoverCardContainer rect={targetRect}>
            <HoverCard {...props.card} />
          </HoverCardContainer>,
          portal
        )}
    </div>
  )
}

const HoverCardContainer = styled('div')(
  {
    position: 'fixed',
    transform: 'translateY(-100%)',
    zIndex: vars.zIndex.hoverCard,
  },
  (props: { rect: DOMRect }) => ({
    top: props.rect.top - vars.space.s,
    left: props.rect.left,
  })
)

export default HoverCardTarget
