import styled from '@emotion/styled'
import * as _ from 'lodash'
import * as React from 'react'
import { X } from 'react-feather'
import { animated, useSpring, useTransition } from 'react-spring'

import SearchField from '~/components/molecules/SearchField'
import Grid from '~/components/organisms/Grid'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import * as vars from '~/styles/variables'

interface Props {
  isShown: boolean
  onSelect: (appId: string) => void
  onClose: () => void
}

const FilterByApp: React.FC<Props> = ({ isShown, onSelect, onClose }) => {
  const [query, setQuery] = React.useState('')
  const definitions = useDefinitions()

  function handleItemClick(appId: string) {
    onSelect(appId)
    handleClose()
  }

  function handleClose() {
    setQuery('')
    onClose()
  }

  const transitions = useTransition(isShown, null, {
    from: { transform: `translateX(550px)` },
    enter: { transform: `translateX(0)` },
    leave: { transform: `translateX(550px)` },
  })

  const overlayProps = useSpring({
    opacity: isShown ? 1 : 0,
  })

  return (
    <>
      {transitions.map(({ item, key, props }) =>
        item ? (
          <Container>
            <Overlay style={overlayProps} onClick={handleClose} />
            <ContentContainer key={key} style={props}>
              <Header>
                <Heading>アプリを選択してフィルター</Heading>
                <CloseButton onClick={handleClose}>
                  <X size={20} color={vars.fontColor.primary} />
                </CloseButton>
              </Header>
              <SearchField
                value={query}
                onChange={(value) => setQuery(value)}
              />
              <_Grid
                items={_.filter(Object.values(definitions.appsById), (app) =>
                  app.name.toUpperCase().includes(query.toUpperCase())
                )}
                column={4}
                keyExtractor={(app) => app.appId}
                renderItem={(app) => (
                  <AppItem onClick={() => handleItemClick(app.appId)}>
                    <AppIconContainer color={app.color}>
                      <AppIcon src={app.iconPath} />
                    </AppIconContainer>
                    <Label>{app.name}</Label>
                  </AppItem>
                )}
              />
            </ContentContainer>
          </Container>
        ) : null
      )}
    </>
  )
}

const Container = styled('div')({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  zIndex: vars.zIndex.modal,
})

const ContentContainer = styled(animated.div)({
  position: 'absolute',
  top: 0,
  right: 0,
  width: 550,
  height: '100%',
  padding: vars.space.l,
  backgroundColor: vars.color.white,
  boxShadow: vars.shadow.m,
  overflowY: 'scroll',
  zIndex: 1,
})

const Overlay = styled(animated.div)({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  zIndex: 0,
})

const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const Heading = styled('div')({
  fontSize: vars.fontSize.m,
  fontWeight: vars.fontWeight.bold,
})

const CloseButton = styled('div')({
  cursor: 'pointer',
  padding: vars.space.m,
  opacity: 0.7,
  '&:hover': {
    opacity: 1,
  },
})

const _Grid = styled(Grid)({
  marginTop: vars.space.m,
})

const AppItem = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  cursor: 'pointer',
})

const AppIconContainer = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 75,
    height: 75,
    borderRadius: vars.borderRadius.m,
  },
  (props: { color: string }) => ({
    backgroundColor: props.color,
  })
)

const AppIcon = styled('img')({
  display: 'block',
  width: 50,
})

const Label = styled('div')({
  marginTop: vars.space.s,
  color: vars.fontColor.secondary,
  fontSize: vars.fontSize.s,
  lineHeight: 1,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
})

export default FilterByApp
