import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import * as React from 'react'

type Size = 'xs' | 's' | 'm' | number

interface Props {
  size?: Size
  /** 色を反転するかどうか */
  reverse?: boolean
  className?: string
}

const Loader: React.FC<React.HTMLAttributes<HTMLDivElement> & Props> = ({
  size = 'm',
  reverse,
  className,
  ...props
}) => <Spin className={className} reverse={reverse} size={size} {...props} />

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const Spin = styled('div')(
  {
    borderStyle: 'solid',
    borderRadius: '50%',
    animation: `${spin} 0.8s linear infinite`,
  },
  (props: { reverse?: boolean; size: Size }) => {
    const pxSize =
      props.size === 'm'
        ? 30
        : props.size === 's'
        ? 20
        : props.size === 'xs'
        ? 15
        : props.size
    return {
      width: pxSize,
      height: pxSize,
      borderWidth: pxSize <= 15 ? 1 : 2,
      borderColor: props.reverse
        ? `rgba(255, 255, 255, 0.8)`
        : `rgba(0, 0, 0, 0.2)`,
      borderTopColor: 'transparent',
    }
  }
)

export default React.memo(Loader)
