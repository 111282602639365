import styled from '@emotion/styled'
import * as React from 'react'

import ErrorMessage from '~/components/atoms/ErrorMessage'
import { ValueType } from '~/domain/ValueType'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { RenderValueValidator } from '~/domain/workflow/source/RenderValueValidator'
import {
  RenderableElement,
  RenderableEntity,
} from '~/domain/workflow/source/RenderableElement'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import InputContainer from '~/presentation/workflow/detail/editor/form/InputContainer'
import { useExpectedObjectsContext } from '~/presentation/workflow/detail/editor/form/expectedObject/ExpectedObjectsContext'
import { InputWidgetProps } from '~/presentation/workflow/detail/editor/form/inputWidget/InputWidget'
import WidgetTypeSign from '~/presentation/workflow/detail/editor/form/inputWidget/WidgetTypeSign'
import EntityFieldComponent from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField'
import { IEditor } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/EntityField'
import { ProseMirrorNodeViewPortalsProvider } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/prosemirror/ProseMirrorNodeViewPortals'
import {
  convertProseMirrorNodeToRenderableElements,
  convertRenderableElementsToProseMirrorNode,
} from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/prosemirror/converters'
import { RootNode } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/prosemirror/schema'
import { flattenRenderableElements } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/utils'
import { useInputWidgetValidation } from '~/presentation/workflow/detail/editor/form/validation/useValidation'
import { useVariableFinderContext } from '~/presentation/workflow/detail/editor/form/variableFinder/VariableFinderHolder'
import * as vars from '~/styles/variables'
import { borderWidth } from '~/styles/widget'

interface Props extends InputWidgetProps {
  value: InputValue.Render
  onChange: (value: InputValue.Render) => void
}

const RenderWidget: React.FC<Props> = (props) => {
  const [typeErrorMessage, setTypeErrorMessage] = React.useState<string>()
  const definitions = useDefinitions()
  const { expectedObjects } = useExpectedObjectsContext()
  const variableFinderHolder = useVariableFinderContext()
  const [value, setValue] = React.useState<RootNode>(
    convertRenderableElementsToProseMirrorNode(
      props.value?.template ?? [],
      definitions,
      // TODO: expected objects が更新された場合に value は最新になるか？
      expectedObjects
    )
  )

  const placeholder = React.useMemo(() => {
    if (
      props.definition.formType === 'text' ||
      props.definition.formType === 'multiline_text'
    ) {
      return props.definition.placeholder
    }
    return undefined
  }, [props.definition])

  const editorRef = React.useRef<IEditor>(null)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [
    shouldVariableFinderShown,
    setShouldVariableFinderShown,
  ] = React.useState<boolean>(false)

  // 型チェック、バルクサイズチェック等を行う
  const renderValueValidator = React.useMemo(
    () =>
      new RenderValueValidator(props.valueType, definitions, expectedObjects),
    [props.valueType, definitions, expectedObjects]
  )

  // バリデーション
  const validationResult = useInputWidgetValidation(props)
  const validationErrorMessage =
    validationResult?.valid === false
      ? validationResult.cause.message
      : undefined

  // 入力値、定義、 expected object またはバルクモードが変更された時に型チェック
  React.useEffect(() => {
    checkValueType()
    return () => {
      // コンポーネントが削除された場合を考慮して、一度自身のバルクサイズを初期化しておく
      props.onBulkSizeChange(undefined)
      // 入力値や型情報が変化した時に型チェックエラーを初期化する
      setTypeErrorMessage(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, props.valueType, props.bulkMode, expectedObjects])

  const checkValueType = () => {
    const result = renderValueValidator.validate(props.value, props.bulkMode)
    if (result.valid) {
      props.onBulkSizeChange(result.bulkSize)
      setTypeErrorMessage(undefined)
    } else {
      setTypeErrorMessage(result.cause.message)
    }
  }

  const targetValueTypes = React.useMemo(
    () =>
      props.bulkMode
        ? [props.valueType, ValueType.wrapInList(props.valueType, false)]
        : [props.valueType],
    [props.bulkMode, props.valueType]
  )

  const insertEntities = React.useCallback((entities: RenderableEntity[]) => {
    entities.forEach((entity) => {
      const label = RenderableElement.toLabel(
        entity,
        definitions,
        expectedObjects
      )
      editorRef.current?.insertEntity(label, entity)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (shouldVariableFinderShown) {
      const rect = containerRef.current?.getBoundingClientRect()
      if (rect === undefined) {
        return
      }
      variableFinderHolder?.setTarget({
        acceptableValueTypes: targetValueTypes,
        rect,
        insertEntities,
        focus: () => {
          editorRef.current?.focus()
        },
      })
      variableFinderHolder?.open()
    } else {
      variableFinderHolder?.close()
    }
  }, [
    shouldVariableFinderShown,
    variableFinderHolder,
    targetValueTypes,
    insertEntities,
  ])

  const handleBlur = React.useCallback(() => {
    setShouldVariableFinderShown(false)
    props.onChange({
      mode: 'render',
      template: flattenRenderableElements(
        convertProseMirrorNodeToRenderableElements(value)
      ),
    })
  }, [props, value])

  const handleFocus = React.useCallback(() => {
    setShouldVariableFinderShown(true)
  }, [])

  const handleChange = React.useCallback((changedValue: RootNode) => {
    setValue(changedValue)
  }, [])

  return (
    <>
      <InputContainer
        ref={containerRef}
        hasError={
          typeErrorMessage !== undefined || validationErrorMessage !== undefined
        }
        renderable={props.renderable}
        disabled={props.readonly}
      >
        <_WidgetTypeSign valueType={props.valueType} />
        <ProseMirrorNodeViewPortalsProvider>
          <_EntityFieldComponent
            ref={editorRef}
            value={value}
            disabled={props.readonly}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={handleChange}
            placeholder={placeholder}
          />
        </ProseMirrorNodeViewPortalsProvider>
      </InputContainer>
      {(validationErrorMessage || typeErrorMessage) && (
        <_ErrorMessage>
          {validationErrorMessage || typeErrorMessage}
        </_ErrorMessage>
      )}
    </>
  )
}

const _WidgetTypeSign = styled(WidgetTypeSign)({
  flexShrink: 0,
})

const _EntityFieldComponent = styled(EntityFieldComponent)({
  minHeight: vars.height.field - borderWidth * 2,
})

const _ErrorMessage = styled(ErrorMessage)({
  marginTop: vars.space.s,
})

export default RenderWidget
