import * as React from 'react'
import isEmail from 'validator/lib/isEmail'

import { Invitation } from '~/domain/user/Invitation'
import { User } from '~/domain/user/User'
import { InvitationEmail } from '~/presentation/user/InviteUsersForm'

export function useInvitationEmails(
  users: User[],
  invitations: Invitation[]
): {
  invitationEmails: InvitationEmail[]
  hasError: boolean
  addEmail: (email: string) => void
  deleteLast: () => void
  deleteEmailAt: (index: number) => void
  clearEmails: () => void
  applyErrors: (errors: Array<string | undefined>) => void
} {
  const [emails, setEmails] = React.useState<InvitationEmail[]>([])

  const hasError = React.useMemo(() => {
    return emails.filter((email) => email.error !== undefined).length > 0
  }, [emails])

  const addEmail = React.useCallback(
    (inputText: string) => {
      const result = validateEmail(inputText, emails, users, invitations)
      setEmails([...emails, { address: inputText, error: result.message }])
    },
    [emails, invitations, users]
  )

  const deleteEmailAt = React.useCallback(
    (index: number) => {
      const newEmails = emails.filter((_, i) => index !== i)
      setEmails(newEmails)
    },
    [emails]
  )

  const deleteLast = React.useCallback(() => {
    if (emails.length > 0) {
      setEmails(emails.slice(0, -1))
    }
  }, [emails])

  const clearEmails = React.useCallback(() => {
    setEmails([])
  }, [])

  const applyErrors = React.useCallback(
    (errors: Array<string | undefined>) => {
      const newEmails = emails.map((email, index) => {
        return {
          address: email.address,
          error: errors[index],
        }
      })
      setEmails(newEmails)
    },
    [emails]
  )

  return {
    invitationEmails: emails,
    hasError,
    addEmail,
    deleteLast,
    deleteEmailAt,
    clearEmails,
    applyErrors,
  }
}

interface ValidationResult {
  isValid: boolean
  message?: string
}

function validateEmail(
  email: string,
  emails: InvitationEmail[],
  users: User[],
  invitations: Invitation[]
): ValidationResult {
  if (!isEmail(email)) {
    return {
      isValid: false,
      message: '正しいメールアドレスを入力してください',
    }
  }
  if (emails.map((e) => e.address).includes(email)) {
    return {
      isValid: false,
      message: '入力済みのメールアドレスです',
    }
  }
  if (emails.length >= 20) {
    // email が21件目
    return {
      isValid: false,
      message: '一度に送信できる招待は20件までです',
    }
  }
  if (
    invitations.find((invitation) => invitation.email === email) !== undefined
  ) {
    return {
      isValid: false,
      message: '既に招待済みのユーザーです',
    }
  }
  if (users.find((user) => user.email === email) !== undefined) {
    return {
      isValid: false,
      message: '既にユーザーとして存在しています。',
    }
  }
  return {
    isValid: true,
  }
}
