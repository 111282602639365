import styled from '@emotion/styled'
import * as React from 'react'
import { connect } from 'react-redux'
import { animated, useSpring } from 'react-spring'

import ErrorBoundary from '~/components/common/ErrorBoundary'
import Sidebar from '~/components/organisms/Sidebar'
import { RootState } from '~/ducks'
import * as sidebarDuck from '~/ducks/ui/sidebar'
import * as vars from '~/styles/variables'

interface OwnProps {
  className?: string
  children: React.ReactNode
}

interface StateProps {
  isCollapsed: boolean
}

type Props = OwnProps & StateProps

const SidebarTemplate: React.FC<Props> = ({
  isCollapsed,
  className,
  children,
}) => {
  const containerProps = useSpring({
    paddingLeft: isCollapsed ? vars.width.sidebarCollapsed : vars.width.sidebar,
  })
  return (
    <Container style={containerProps}>
      <_Sidebar />
      <ErrorBoundary>
        <Content className={className}>{children}</Content>
      </ErrorBoundary>
    </Container>
  )
}

const Container = styled(animated.div)({
  width: '100%',
  height: '100%',
})

const _Sidebar = styled(Sidebar)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: vars.width.sidebar,
  height: '100%',
  zIndex: vars.zIndex.sidebar,
})

const Content = styled('div')({
  height: '100%',
})

const mapStateToProps = (state: RootState) => ({
  isCollapsed: sidebarDuck.selectors.getIsCollapsed(state),
})

export default connect<StateProps>(mapStateToProps)(SidebarTemplate)
