import React from 'react'

import { Result } from '~/common/Result'
import { apiClients } from '~/common/apiClients'
import { Connection } from '~/domain/connection/Connection'
import { ConnectionService } from '~/domain/connection/ConnectionService'

const service: ConnectionService = apiClients.connectionService

export function useConnection(
  connectionId: string
): {
  result: Result<Connection | undefined>
} {
  const [result, setResult] = React.useState<Result<Connection | undefined>>(
    new Result.Loading()
  )

  React.useEffect(() => {
    service
      .getConnection(connectionId)
      .then((res) => {
        setResult(new Result.Success(res))
      })
      .catch((e) => {
        console.error(e)
        setResult(new Result.Failure(e))
      })
  }, [connectionId])

  return { result }
}
