import styled from '@emotion/styled'
import React from 'react'

import { assertNever } from '~/common/utils'
import Text from '~/components/atoms/Text'
import { CombinationType } from '~/domain/workflow/widget/salesforceSearchCondition/CombinationType'
import * as vars from '~/styles/variables'

interface Props {
  value: CombinationType
  onChange: (newValue: CombinationType) => void
  disabled?: boolean
  style?: React.CSSProperties
}

export const CombinationTypeSwitcher: React.FC<Props> = (props) => {
  return (
    <div
      style={{
        height: 32,
        display: 'flex',
        border: `1px solid ${vars.colorPalette.gray1}`,
        borderRadius: vars.borderRadius.s,
        ...props.style,
      }}
    >
      <div
        style={{
          width: 70,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: vars.colorPalette.gray1,
          textAlign: 'center',
        }}
      >
        <Text element="span" fontSize="s" fontWeight="bold" color="secondary">
          {getLabel(props.value)}
        </Text>
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <OptionContainer>
          <Option
            checked={props.value === 'and'}
            disabled={props.disabled ?? false}
            onClick={() => props.onChange('and')}
          >
            かつ
          </Option>
        </OptionContainer>
        <OptionContainer>
          <Option
            checked={props.value === 'or'}
            disabled={props.disabled ?? false}
            onClick={() => props.onChange('or')}
          >
            または
          </Option>
        </OptionContainer>
      </div>
    </div>
  )
}

const Option: React.FC<{
  checked: boolean
  onClick: () => void
  disabled: boolean
}> = (props) => {
  return (
    <label style={{ verticalAlign: 'middle' }}>
      <input
        type="radio"
        checked={props.checked}
        disabled={props.disabled}
        onClick={props.onClick}
      />
      <Text
        element="span"
        fontSize="s"
        color={
          props.checked && !props.disabled
            ? vars.fontColor.primary
            : vars.fontColor.tertiary
        }
        style={{ marginLeft: vars.space.xs, position: 'relative', top: -1 }}
      >
        {props.children}
      </Text>
    </label>
  )
}

const OptionContainer = styled('div')({
  '&:not(:first-of-type)': {
    marginLeft: vars.space.m,
  },
})

function getLabel(combinationType: CombinationType): string {
  if (combinationType === 'and') {
    return 'かつ'
  }
  if (combinationType === 'or') {
    return 'または'
  }
  assertNever(combinationType)
}
