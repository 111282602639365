import dayjs from 'dayjs'

import { delay } from '~/common/utils'
import { Invitation } from '~/domain/user/Invitation'
import { InvitationResult } from '~/domain/user/InvitationResult'
import { InvitationService } from '~/domain/user/InvitationService'

export class MockInvitationService implements InvitationService {
  async getInvitations(): Promise<Invitation[]> {
    await delay(1500)
    return [...invitations]
  }

  async inviteUsers(_emails: string[]): Promise<InvitationResult> {
    await delay(1500)
    return { isSuccess: true }
  }

  async reInvite(_id: string): Promise<void> {
    await delay(1500)
  }

  async cancel(_id: string): Promise<void> {
    await delay(1500)
  }

  async verifyToken(_token: string): Promise<boolean> {
    await delay(1500)
    return true
  }
}

const invitations: Invitation[] = [
  {
    id: 'aaa',
    email: 'invite1@example.com',
    expireAt: dayjs().toDate(),
    updatedAt: dayjs().toDate(),
  },
  {
    id: 'bbb',
    email: 'invite2@example.com',
    expireAt: dayjs().toDate(),
    updatedAt: dayjs().toDate(),
  },
  {
    id: 'ccc',
    email: 'invite3@example.com',
    expireAt: dayjs().toDate(),
    updatedAt: dayjs().toDate(),
  },
  {
    id: 'ddd',
    email: 'invite4@example.com',
    expireAt: dayjs().toDate(),
    updatedAt: dayjs().toDate(),
  },
]
