import { Tson } from '~/data/Tson'
import { ActionJson } from '~/data/workflow/action/ActionJson'
import { mapToFieldJson } from '~/data/workflow/field/mapToFieldJson'

export function mapToActionJson(tson: Tson): ActionJson {
  return {
    id: tson.getString('id'),
    name: tson.getString('name'),
    appId: tson.getString('appId'),
    description: tson.getString('description'),
    isTestable: tson.getBoolean('isTestable'),
    fields: tson.getArray('fields').map(mapToFieldJson),
    state: tson.getString('state'),
  }
}
