import * as _ from 'lodash'

import { ensure } from '~/common/utils'
import { ObjectPropertyValue } from '~/domain/workflow/expectedObject/ObjectPropertyValue'
import { ExpectedObjectKey } from '~/domain/workflow/object/ExpectedObjectKey'

export class ExpectedObjects {
  constructor(
    public readonly trigger?: TriggerExpectedObjects,
    public readonly tasks?: TaskExpectedObjects[]
  ) {}

  getInstance(key: string): ExpectedObject {
    return ensure(this.findInstance(key), `No such Expected Object: ${key}`)
  }

  findInstance(key: string): ExpectedObject | undefined {
    if (this.trigger !== undefined) {
      const found = this.trigger.expectedObjects.find(
        (it) => it.key.value === key
      )
      if (found !== undefined) {
        return found
      }
    }
    if (this.tasks !== undefined) {
      for (let i = 0; i < this.tasks.length; i++) {
        const found = this.tasks[i].expectedObjects.find(
          (it) => it.key.value === key
        )
        if (found !== undefined) {
          return found
        }
      }
    }
    return undefined
  }

  getAllInstances(): ExpectedObject[] {
    return [
      ...(this.trigger?.expectedObjects ?? []),
      ..._.reduce(
        this.tasks,
        (acc, task) => {
          return [...acc, ...task.expectedObjects]
        },
        []
      ),
    ]
  }
}

export interface ExpectedObject {
  key: ExpectedObjectKey
  name: string
  objectId: string
  sampleValues: ObjectPropertyValue[]
}

export interface TriggerExpectedObjects {
  triggerId: string
  expectedObjects: ExpectedObject[]
}

export interface TaskExpectedObjects {
  taskId: string
  actionId: string
  expectedObjects: ExpectedObject[]
}
