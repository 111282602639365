import * as _ from 'lodash'
import * as React from 'react'

export interface MapLike<T> {
  [key: string]: T
}

/**
 * 各要素の値を変更しても変更した要素以外の値の参照が変更されない MapLike を使用します。
 * MapLike オブジェクト自体はいずれかの要素が変更されるたびに新しく生成されますが、
 * 変更された要素以外の要素はすべて変更前のオブジェクトの参照をそのまま維持します。
 *
 * MapLike 自体を依存に追加しておくことでいずれかの要素が変更された時に処理を実行したり、
 * 特定の要素への参照を依存に追加しておくことで他の要素が変更されても処理が実行されないように
 * することができます。
 */
export function useMapState<T>(
  defaultValue?: MapLike<T>
): [
  MapLike<T>,
  (key: string, value: T) => void,
  (key: string) => void,
  () => void,
  (values: MapLike<T>) => void
] {
  const [map, setMap] = React.useState<MapLike<T>>(
    defaultValue !== undefined ? _.cloneDeep(defaultValue) : {}
  )
  const updater = React.useRef<(key: string, value: T) => void>(
    (key: string, value: T) => {
      setMap((oldMap) => {
        if (oldMap[key] === value) {
          // 同じ値を put した時に render が走るのを防止
          return oldMap
        }
        const newMap = {}
        Object.keys(oldMap).forEach((k) => {
          if (k !== key) {
            newMap[k] = oldMap[k]
          }
        })
        newMap[key] = value
        return newMap
      })
    }
  )
  const deleter = React.useRef<(key: string) => void>((key: string) => {
    setMap((oldMap) => {
      const newMap = {}
      Object.keys(oldMap).forEach((k) => {
        if (k !== key) {
          newMap[k] = oldMap[k]
        }
      })
      return newMap
    })
  })
  const allDeleter = React.useRef<() => void>(() => {
    setMap((oldMap) => {
      if (Object.keys(oldMap).length === 0) {
        return oldMap
      }
      return {}
    })
  })
  const setter = React.useRef<(values: MapLike<T>) => void>((values) => {
    setMap(values)
  })
  return [
    map,
    updater.current,
    deleter.current,
    allDeleter.current,
    setter.current,
  ]
}
