import produce from 'immer'
import { createSelector } from 'reselect'
import { actionCreatorFactory } from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { RootState } from '~/ducks'

const actionCreator = actionCreatorFactory('anyflow/tip')

export const types = {
  RESET: 'RESET',
  SHOW: 'SHOW',
  HIDE: 'HIDE',
}

interface ShowRequest {
  top: number
  left: number
  message: string
}

export const actions = {
  reset: actionCreator(types.RESET),
  show: actionCreator<ShowRequest>(types.SHOW),
  hide: actionCreator(types.HIDE),
}

export const selectors = {
  getState: createSelector(
    (state: RootState) => state.ui.tip,
    (tip) => tip
  ),
}

export type State = {
  isShown: boolean
  top: number
  left: number
  message: string
}

const initialState: State = {
  isShown: false,
  top: 0,
  left: 0,
  message: '',
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.reset, () => initialState)
  .case(actions.show, (state, payload) =>
    produce(state, (draft) => {
      draft.isShown = true
      draft.top = payload.top
      draft.left = payload.left
      draft.message = payload.message
    })
  )
  .case(actions.hide, (state) =>
    produce(state, (draft) => {
      draft.isShown = false
      draft.top = 0
      draft.left = 0
      draft.message = ''
    })
  )
