import * as React from 'react'

import { useComposingRef } from '~/hooks/useComposingRef'

interface Props {
  handleEscape: () => void
  handleEnter: () => void
}

/**
 * Enter と Escape を補足するコンポーネント
 */
const FormKeysTrap: React.FC<Props> = (props) => {
  const { handleEscape, handleEnter } = props
  const composingRef = useComposingRef()

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      // 日本語入力中など、 composition 中は無視
      if (composingRef.current) {
        return
      }
      if (e.key === 'Escape') {
        handleEscape()
      }
      if (e.key === 'Enter') {
        handleEnter()
      }
    },
    [handleEscape, handleEnter, composingRef]
  )

  return (
    <div tabIndex={-1} style={{ outline: 'none' }} onKeyDown={handleKeyDown}>
      {props.children}
    </div>
  )
}

export default FormKeysTrap
