import styled from '@emotion/styled'
import * as React from 'react'
import { CheckCircle, XCircle } from 'react-feather'

import { ToasterType } from '~/ducks/ui/types'
import * as vars from '~/styles/variables'

interface Props {
  type: ToasterType
  message: string
  className?: string
}

const Toast: React.FC<Props> = ({ className, type, message }) => {
  return (
    <Container className={className} type={type}>
      {type === ToasterType.success ? (
        <CheckCircle size={20} color={vars.color.white} />
      ) : (
        <XCircle size={20} color={vars.color.white} />
      )}
      <Message>{message}</Message>
    </Container>
  )
}

const Container = styled('div')(
  {
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: vars.space.s,
    paddingRight: vars.space.m,
    paddingLeft: vars.space.m,
    minWidth: 300,
    height: 45,
    color: vars.color.white,
    fontSize: vars.fontSize.s,
    fontWeight: vars.fontWeight.bold,
    borderRadius: vars.borderRadius.s,
    boxShadow: vars.shadow.m,
  },
  (props: { type: ToasterType }) => ({
    backgroundColor:
      props.type === 'error' ? vars.color.error : vars.color.success,
  })
)

const Message = styled('span')({
  marginLeft: vars.space.s,
})

export default Toast
