import config from '~/common/config'
import { Tson } from '~/data/Tson'
import requestJson, { requestPut } from '~/data/request'
import { mapJsonToMe } from '~/data/user/mapJsonToMe'
import { mapToMeJson } from '~/data/user/mapToMeJson'
import { SessionService } from '~/domain/session/SessionService'
import { Me } from '~/domain/user/Me'

export class SessionServiceImpl implements SessionService {
  async getMe(): Promise<Me> {
    const res = await requestJson(`${config.apiRoot}/auth/me`)
    const json = mapToMeJson(new Tson(res))
    return mapJsonToMe(json)
  }

  async changeCurrentGroup(groupId: string): Promise<void> {
    await requestPut(`${config.apiRoot}/auth/current_group`, {
      slug: groupId,
    })
  }
}
