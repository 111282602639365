import styled from '@emotion/styled'
import * as React from 'react'

import AppIcon from '~/components/atoms/AppIcon'
import Pill from '~/components/atoms/Pill'
import * as vars from '~/styles/variables'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  iconPath: string
  color: string
  disabled?: boolean
  className?: string
}

const PaintedPill: React.FC<Props> = ({
  iconPath,
  color,
  disabled = false,
  className,
  children,
  ...props
}) => (
  <Container className={className} disabled={disabled} {...props}>
    <IconContainer color={color}>
      <_AppIcon size="s" icon={iconPath} />
    </IconContainer>
    <_Pill disabled={disabled}>{children}</_Pill>
  </Container>
)

const Container = styled('div')(
  {
    display: 'inline-flex',
  },
  (props: { disabled: boolean }) => ({
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    opacity: props.disabled ? 0.6 : 1,
  })
)

const IconContainer = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: vars.height.pill,
    height: vars.height.pill,
    borderTopLeftRadius: vars.borderRadius.s,
    borderBottomLeftRadius: vars.borderRadius.s,
  },
  (props: { color: string }) => ({
    backgroundColor: props.color,
  })
)

const _AppIcon = styled(AppIcon)({
  flexShrink: 0,
})

const _Pill = styled(Pill)({
  borderLeft: 'none',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  backgroundColor: vars.color.white,
})

export default PaintedPill
