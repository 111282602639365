import { buildPagerQuery } from '~/api'
import { Pagination } from '~/common/Pagination'
import config from '~/common/config'
import { Tson } from '~/data/Tson'
import requestJson, { HttpError } from '~/data/request'
import { sanitize } from '~/data/utils'
import { WorkflowInstanceSummaryJson } from '~/data/workflow/instance/json'
import { mapJsonToWorkflowInstance } from '~/data/workflow/instance/mapJsonToWorkflowInstance'
import { mapJsonToWorkflowInstanceSummary } from '~/data/workflow/instance/mapJsonToWorkflowInstanceSummary'
import { mapToWorkflowInstanceJson } from '~/data/workflow/instance/mapToWorkflowInstanceJson'
import { mapToWorkflowInstanceSummaryJson } from '~/data/workflow/instance/mapToWorkflowInstanceSummaryJson'
import { WorkflowInstance } from '~/domain/workflow/instance/WorkflowInstance'
import { WorkflowInstanceService } from '~/domain/workflow/instance/WorkflowInstanceService'
import { WorkflowInstanceSummary } from '~/domain/workflow/instance/WorkflowInstanceSummary'

export class WorkflowInstanceServiceImpl implements WorkflowInstanceService {
  async get(instanceId: string): Promise<WorkflowInstance | undefined> {
    try {
      const res = await requestJson(
        `${config.apiRoot}/workflow_instances/${sanitize(instanceId)}`
      )
      const json = mapToWorkflowInstanceJson(new Tson(res))
      return mapJsonToWorkflowInstance(json)
    } catch (e) {
      if (e instanceof HttpError && e.statusCode === 404) {
        return undefined
      }
      throw e
    }
  }

  async getList(
    workflowId: string,
    pageNumber: number,
    limit?: number
  ): Promise<Pagination<WorkflowInstanceSummary>> {
    const query = buildPagerQuery(pageNumber, limit)
    const res = await requestJson(
      `${config.apiRoot}/workflow_instances?workflows=${workflowId}&${query}`
    )
    const json = mapToListResponseJson(new Tson(res))
    return {
      paginationMeta: {
        currentPageNumber: json.current,
        totalCount: json.count,
        countPerPage: json.limit,
      },
      items: json.results.map(mapJsonToWorkflowInstanceSummary),
    }
  }
}

interface ListResponseJson {
  limit: number
  current: number
  count: number
  results: WorkflowInstanceSummaryJson[]
}

function mapToListResponseJson(tson: Tson): ListResponseJson {
  return {
    count: tson.getNumber('count'),
    current: tson.getNumber('current'),
    limit: tson.getNumber('limit'),
    results: tson.getArray('results').map(mapToWorkflowInstanceSummaryJson),
  }
}
