import * as d3 from 'd3'
import * as React from 'react'

import Triangle from '~/components/atoms/Triangle'
import { actionNodeSize } from '~/components/molecules/ActionNode'
import NodeAddButton from '~/components/molecules/NodeAddButton'
import { triggerNodeSize } from '~/components/molecules/TriggerNode'
import { TaskSource } from '~/domain/workflow/source/WorkflowSourceBody'
import { Node } from '~/hooks/useTree'
import * as vars from '~/styles/variables'

interface Props {
  links: d3.HierarchyPointLink<Node>[]
  readonly: boolean
  onAddButtonClick?: (
    e: React.MouseEvent,
    parentTask: TaskSource | null
  ) => void
}

const Links: React.FunctionComponent<Props> = ({
  links,
  readonly,
  onAddButtonClick,
}) => {
  const renderTriangle = (link: d3.HierarchyPointLink<Node>, key: any) => {
    const size = 16
    const triangleX = link.source.y + triggerNodeSize + actionNodeSize / 2
    const triangleY = link.source.x + actionNodeSize / 2 - size / 2
    const fill = vars.color.gray

    return (
      <Triangle key={key} size={size} x={triangleX} y={triangleY} fill={fill} />
    )
  }

  const renderLine = (link: d3.HierarchyPointLink<Node>, key: any) => {
    return (
      <React.Fragment key={key}>
        <line
          x1={link.source.y + actionNodeSize / 2}
          y1={link.source.x + actionNodeSize / 2}
          x2={link.target.y + actionNodeSize / 2}
          y2={link.target.x + actionNodeSize / 2}
          stroke="#eee"
          strokeWidth={2}
          strokeDasharray="5, 5"
        />
        {renderAddButton(link)}
      </React.Fragment>
    )
  }

  const renderAddButton = (link: d3.HierarchyPointLink<Node>) => {
    if (!onAddButtonClick) {
      return null
    }

    const parentTask =
      link.source.data.type === 'task' ? link.source.data.data : null
    return (
      <NodeAddButton
        link={link}
        parentTask={parentTask}
        disabled={readonly}
        onClick={onAddButtonClick}
      />
    )
  }

  return (
    <g>
      {links.map((link, i) => {
        if (link.source.data.type === 'trigger') {
          return renderTriangle(link, i)
        }

        return renderLine(link, i)
      })}
    </g>
  )
}

export default Links
