import styled from '@emotion/styled'
import React from 'react'

import { apiClients } from '~/common/apiClients'
import { assert, assertNever } from '~/common/utils'
import Modal from '~/components/organisms/Modal'
import { WorkflowNode } from '~/domain/workflow/source/WorkflowNode'
import { WorkflowSourceBody } from '~/domain/workflow/source/WorkflowSourceBody'
import { TestResult } from '~/domain/workflow/test/TestResult'
import { TestService } from '~/domain/workflow/test/TestService'
import { TestTarget } from '~/domain/workflow/test/TestTarget'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import { useToaster } from '~/presentation/ToasterContext'
import { useExpectedObjectsContext } from '~/presentation/workflow/detail/editor/form/expectedObject/ExpectedObjectsContext'
import { useExpectedObjectSnapshots } from '~/presentation/workflow/detail/editor/test/ExpectedObjectSnapshotsContext'
import NodeTestConfirm from '~/presentation/workflow/detail/editor/test/NodeTestConfirm'
import { useWorkflowTestResult } from '~/presentation/workflow/detail/editor/test/WorkflowTestResultContext'
import * as vars from '~/styles/variables'

const service: TestService = apiClients.testService

interface Props {
  target: WorkflowNode
  source: WorkflowSourceBody
  useBulk: boolean
  isOpen: boolean
  onTestComplete: (result: TestResult) => void
  onCloseClick: () => void
}

const NodeTestModal: React.FC<Props> = (props) => {
  const toaster = useToaster()
  const definitions = useDefinitions()
  const { update: updateWorkflowTestResult } = useWorkflowTestResult()
  const { expectedObjects } = useExpectedObjectsContext()
  const snapshots = useExpectedObjectSnapshots()
  const [running, setRunning] = React.useState<boolean>(false)

  const inputSources = React.useMemo(() => {
    return props.target.kind === 'trigger'
      ? props.source.getTrigger()?.inputs ?? []
      : props.source.findTask(props.target.taskId)?.inputs ?? []
  }, [props.target, props.source])

  const testTarget = React.useMemo<TestTarget>(() => {
    switch (props.target.kind) {
      case 'trigger': {
        const triggerId = props.source.getTrigger()?.triggerId
        assert(
          triggerId !== undefined,
          `This workflow source doesn't have trigger, but targetNode is trigger.`
        )
        return { kind: 'trigger', triggerId }
      }
      case 'task': {
        const actionId = props.source.findTask(props.target.taskId)?.actionId
        assert(
          actionId !== undefined,
          `This workflow source doesn't have such task (${props.target.taskId}), but targetNode is that task.`
        )
        return { kind: 'task', taskId: props.target.taskId, actionId }
      }
      default:
        assertNever(props.target)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.target, props.source, definitions])

  const runTest = React.useCallback(async () => {
    const result = await service.test(
      testTarget,
      inputSources,
      props.useBulk,
      expectedObjects,
      snapshots.getAll()
    )
    await updateWorkflowTestResult((current) =>
      current.setTestResult(testTarget, result)
    )
    return result
  }, [
    testTarget,
    inputSources,
    props.useBulk,
    expectedObjects,
    snapshots,
    updateWorkflowTestResult,
  ])

  const handleClose = React.useCallback(() => {
    props.onCloseClick()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onCloseClick])

  const handleRunClick = React.useCallback(async () => {
    setRunning(true)
    try {
      const result = await runTest()
      props.onTestComplete(result)
    } catch (e) {
      console.error(e)
      toaster.showError('エラーが発生しました')
    } finally {
      setRunning(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runTest, props.onTestComplete])

  return (
    <_Modal
      noDimmer={true}
      isOpened={props.isOpen}
      onClose={handleClose}
      mountOnOpen={true}
    >
      <NodeTestConfirm
        target={testTarget}
        inputs={inputSources}
        useBulk={props.useBulk}
        running={running}
        onCloseClick={handleClose}
        onRunClick={handleRunClick}
      />
    </_Modal>
  )
}

const _Modal = styled(Modal)({
  width: 550,
  padding: vars.space.l,
})

export default NodeTestModal
