import * as React from 'react'

import { PaginationMeta } from '~/common/Pagination'
import { apiClients } from '~/common/apiClients'
import { PendingResult } from '~/common/types'
import { LookupTableServiceImpl } from '~/data/lookupTable/LookupTableServiceImpl'
import { LookupTableService } from '~/domain/lookupTable/LookupTableService'
import { LookupTableSummary } from '~/domain/lookupTable/LookupTableSummary'
import { useDefinitions } from '~/presentation/AnyflowAppContext'

export function useLookupTables(
  pageNumber: number
): {
  paginationMeta?: PaginationMeta
  result: PendingResult<LookupTableSummary[]>
} {
  const definitions = useDefinitions()
  const service = React.useRef<LookupTableService>(
    new LookupTableServiceImpl(apiClients.lookupTableApi, definitions)
  )
  const [paginationMeta, setPaginationMeta] = React.useState<PaginationMeta>()
  const [result, setResult] = React.useState<
    PendingResult<LookupTableSummary[]>
  >(PendingResult.pending())

  React.useEffect(() => {
    setResult(PendingResult.pending())
    service.current
      .getList(pageNumber)
      .then((it) => {
        setPaginationMeta({
          totalCount: it.paginationMeta.totalCount,
          currentPageNumber: it.paginationMeta.currentPageNumber,
          countPerPage: it.paginationMeta.countPerPage,
        })
        setResult(PendingResult.success(it.items))
      })
      .catch((reason) => {
        setResult(PendingResult.failure())
        console.error(`Failed to fetch lookup tables: ${reason}`)
      })
  }, [pageNumber])

  return { result, paginationMeta }
}
