import * as React from 'react'

interface Props {
  appID: string
}

export const Intercom: React.FC<Props> = (props) => {
  React.useEffect(() => {
    if (typeof window.Intercom === 'function') {
      window.Intercom('reattach_activator')
      window.Intercom('update', window.intercomSettings)
      return
    }
    const intercomFunction: {
      (...args: any): () => void
      q: unknown[]
      c: (arg: unknown) => void
    } = (() => {
      const _intercom: any = function (...args: any) {
        _intercom.c(args)
      }
      _intercom.q = []
      _intercom.c = function (arg: unknown) {
        _intercom.q.push(arg)
      }
      return _intercom
    })()
    window.Intercom = intercomFunction
    const script = document.createElement('script')
    const scriptId = 'intercom-script'
    script.id = scriptId
    script.type = 'text/javascript'
    script.async = true
    script.src = `https://widget.intercom.io/widget/${props.appID}`
    document.head.appendChild(script)

    window.intercomSettings = { app_id: props.appID }

    window.Intercom('boot')
    return () => {
      document.getElementById(scriptId)?.remove()
      if (window.Intercom) {
        window.Intercom('shutdown')
        delete window.Intercom
        delete window.intercomSettings
      }
    }
  }, [props.appID])

  return null
}
