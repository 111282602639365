import styled from '@emotion/styled'
import * as React from 'react'
import { animated, useSpring } from 'react-spring'

import { assertNever } from '~/common/utils'
import * as vars from '~/styles/variables'

type ItemRole = 'normal' | 'danger'

export interface DropdownMenuItem {
  id: string
  label: string
  role: ItemRole
}

interface Props {
  isOpen: boolean
  items: DropdownMenuItem[]
  className?: string
  handleItemClick: (item: DropdownMenuItem) => void
}

const DropdownMenu: React.FC<Props> = (props) => {
  const { handleItemClick } = props
  const ContainerProps = useSpring({
    display: props.isOpen ? 'block' : 'none',
    opacity: props.isOpen ? 1 : 0,
  })

  return (
    <List className={props.className} style={ContainerProps}>
      {props.items.map((item, i) => (
        <ListItem key={i} onClick={() => handleItemClick(item)}>
          <Label role={item.role}>{item.label}</Label>
        </ListItem>
      ))}
    </List>
  )
}

function getColor(role: ItemRole): string {
  if (role === 'normal') {
    return vars.fontColor.primary
  }
  if (role === 'danger') {
    return vars.color.error
  }
  assertNever(role)
}

const List = styled(animated.ul)({
  paddingTop: vars.space.s,
  paddingBottom: vars.space.s,
  paddingLeft: 0,
  minWidth: 150,
  backgroundColor: vars.color.white,
  border: `1px solid ${vars.color.border}`,
  borderRadius: vars.borderRadius.s,
  listStyle: 'none',
  boxShadow: vars.shadow.m,
})

const ListItem = styled('li')({
  paddingTop: vars.space.s,
  paddingRight: vars.space.m,
  paddingBottom: vars.space.s,
  paddingLeft: vars.space.m,
  fontSize: vars.fontSize.s,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: vars.color.offWhite,
  },
})

const Label = styled('span')({}, (props: { role: ItemRole }) => ({
  color: getColor(props.role),
}))

export default DropdownMenu
