import { Tson } from '~/data/Tson'
import { MeWithoutRoleJson } from '~/data/auth/MeWithoutRoleJson'
import { mapToGroupMetaJson } from '~/data/group/mapToGroupJson'
import { mapToOrganizationJson } from '~/data/user/organization/mapToOrganizationJson'

export function mapToMeWithoutRoleJson(tson: Tson): MeWithoutRoleJson {
  const organizationTson = tson.getValue('organization', false)
  const organization =
    organizationTson === null || organizationTson === undefined
      ? null
      : mapToOrganizationJson(organizationTson)
  return {
    email: tson.getString('email'),
    organization: organization,
    slug: tson.getString('slug'),
    username: tson.getString('username'),
    belongingGroups: tson.getArray('belongingGroups').map(mapToGroupMetaJson),
  }
}
