import styled from '@emotion/styled'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { RouteComponentProps } from 'react-router'

import Text from '~/components/atoms/Text'
import Footer from '~/components/organisms/Footer'
import Features from '~/containers/Home/features/Features'
import Media from '~/containers/Home/media/Media'
import ConfirmModal from '~/containers/RecipeLp/ConfirmModal'
import CtaButton from '~/containers/RecipeLp/CtaButton'
import { Form, FormValues } from '~/containers/RecipeLp/Form'
import Header, { height as headerHeight } from '~/containers/RecipeLp/Header'
import Inner from '~/containers/RecipeLp/Inner'
import { lpData } from '~/containers/RecipeLp/data'
import * as vars from '~/styles/variables'

interface OwnProps {}

export interface RecipeLpParams {
  recipeId: string
}

type Props = OwnProps & RouteComponentProps<RecipeLpParams>

const RecipeLp: React.FC<Props> = ({ match }) => {
  const [values, setValues] = React.useState<FormValues>()
  const [isModalShown, setIsModalShown] = React.useState(false)
  const recipe = lpData.find((it) => it.id === match.params.recipeId)
  if (recipe === undefined) {
    return null
  }
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <ConfirmModal
        isShown={isModalShown}
        values={values}
        onClose={() => {
          setIsModalShown(false)
        }}
      />
      <Header />
      <MastheadSection style={{ marginTop: headerHeight }}>
        <Inner style={{ position: 'relative', minHeight: 500 }}>
          <MastheadContent>
            <MastheadLeft>
              <Text
                element="h1"
                fontSize="xxxxl"
                fontWeight="bold"
                lineHeight="heading"
              >
                ビジネスの成長は、
                <br />
                自動化から生まれる。
              </Text>
              <Text
                element="p"
                fontSize="l"
                color="secondary"
                fontWeight="normal"
                lineHeight="body"
                style={{ marginTop: vars.space.m * 1.5 }}
              >
                Anyflowは、SaaS
                間を連携するオートメーションプラットフォームです。
                <Br />
                誰でも簡単に煩雑な業務を自動化し、本来集中したい業務に
                <Br />
                時間を割けるようになります。
              </Text>
              <a
                href="#cta1"
                style={{
                  display: 'block',
                  marginTop: vars.space.l,
                }}
                onClick={(e) => handleSmoothScroll(e, 'cta1')}
              >
                <CtaButton>お問い合わせ</CtaButton>
              </a>
              <a
                href="#feature"
                style={{
                  display: 'block',
                  marginTop: vars.space.m,
                  color: vars.fontColor.secondary,
                  fontSize: vars.fontSize.m,
                  textDecoration: 'none',
                }}
                onClick={(e) => handleSmoothScroll(e, 'feature')}
              >
                詳しく見る
              </a>
            </MastheadLeft>
            <MastheadRight>
              <Hero src="/static/images/lp/recipes/hero.png" />
            </MastheadRight>
          </MastheadContent>
        </Inner>
      </MastheadSection>
      <ProblemSection>
        <Inner>
          <Text
            element="h1"
            color="white"
            fontSize="xxxl"
            fontWeight="bold"
            lineHeight="heading"
            textAlign="center"
          >
            {recipe.problem.title}
          </Text>
          <ProblemList style={{ marginTop: vars.space.xl }}>
            {recipe.problem.items.map((item) => (
              <ProblemItem key={item.title?.toString()}>
                <img src={item.imagePath} height="90" />
                <Text
                  element="h2"
                  fontSize="l"
                  color="secondary"
                  fontWeight="bold"
                  textAlign="center"
                  style={{ marginTop: vars.space.l }}
                >
                  {item.title}
                </Text>
              </ProblemItem>
            ))}
          </ProblemList>
        </Inner>
      </ProblemSection>
      <SolutionSection>
        <Inner>
          <SolutionHeading
            element="h1"
            fontSize="xxxl"
            fontWeight="bold"
            lineHeight="heading"
          >
            <SolutionLogo
              src="/static/images/logo.svg"
              width="200"
              alt="Anyflow"
            />
            なら、数クリックで解決
          </SolutionHeading>
          <SolutionList style={{ marginTop: vars.space.l }}>
            {recipe.solution.items.map((solution) => (
              <SolutionItem key={solution.title?.toString()}>
                <Text
                  element="h2"
                  color="white"
                  fontSize={solution.fontSize}
                  fontWeight="bold"
                  lineHeight="body"
                  textAlign="center"
                  style={{
                    width: '100%',
                    paddingTop: vars.space.m * 1.5,
                    paddingRight: vars.space.s,
                    paddingBottom: vars.space.m * 1.5,
                    paddingLeft: vars.space.s,
                    backgroundColor: vars.color.theme,
                  }}
                >
                  {solution.title}
                </Text>
                <SolutionImageContainer>
                  {solution.appIcons.map((appIcon, i) => (
                    <>
                      <img
                        src={`/static/images/lp/recipes/icons/${appIcon.iconName}`}
                        width={appIcon.width !== undefined ? appIcon.width : 70}
                      />
                      {solution.appIcons.length - 1 !== i ? (
                        <img
                          src={`/static/images/lp/recipes/icons/arrow.svg`}
                          style={{
                            marginLeft: vars.space.s,
                            marginRight: vars.space.s,
                          }}
                        />
                      ) : null}
                    </>
                  ))}
                </SolutionImageContainer>
              </SolutionItem>
            ))}
          </SolutionList>
        </Inner>
      </SolutionSection>
      <Cta
        id="cta1"
        onSubmit={(newValues) => {
          setValues(newValues)
          setIsModalShown(true)
        }}
      />
      <Section
        style={{
          paddingTop: vars.space.xl,
          paddingBottom: vars.space.xl,
        }}
      >
        <Features noButtons={true} />
      </Section>
      <Section
        style={{
          paddingTop: vars.space.xl,
          paddingBottom: vars.space.xl,
        }}
      >
        <Inner style={{ maxWidth: 1000 }}>
          <Media />
        </Inner>
      </Section>
      <Cta
        id="cta2"
        onSubmit={(newValues) => {
          setValues(newValues)
          setIsModalShown(true)
        }}
      />
      <Section
        style={{
          paddingTop: vars.space.l * 1.5,
          paddingBottom: vars.space.l * 1.5,
        }}
      >
        <Inner>
          <Text
            element="h1"
            color="primary"
            fontSize="xxxxl"
            fontWeight="bold"
            lineHeight="heading"
            textAlign="center"
            style={{ marginBottom: vars.space.s }}
          >
            Anyflow は<br />
            人の"時間"を創ります
          </Text>
          <Text
            element="p"
            color="primary"
            fontSize="l"
            textAlign="center"
            style={{ marginTop: vars.space.m }}
          >
            Anyflow は単純業務の時間を減らし、人にしかできない仕事の
            <br />
            時間を創ることで、ビジネスの成長を加速させます。
          </Text>
          <img
            alt=""
            srcSet="/static/images/lp/footer_illust@2x.png 2x"
            style={{
              display: 'block',
              maxWidth: 500,
              width: '100%',
              marginTop: vars.space.l,
              marginRight: 'auto',
              marginLeft: 'auto',
            }}
          />
        </Inner>
      </Section>
      <Footer />
    </>
  )
}

export function handleSmoothScroll(e: React.MouseEvent, id: string) {
  e.preventDefault()
  const element = document.getElementById(id)
  if (!element) {
    return
  }
  window.scrollTo({
    top: window.scrollY + element.getBoundingClientRect().top - headerHeight,
    behavior: 'smooth',
  })
}

interface CtaProps {
  id: string
  onSubmit: (values: FormValues) => void
}

const Cta: React.FC<CtaProps> = (props) => {
  return (
    <CtaSection id={props.id}>
      <Inner>
        <Text
          element="h1"
          color="white"
          fontSize="xxxl"
          fontWeight="bold"
          lineHeight="heading"
          textAlign="center"
        >
          お問い合わせ
        </Text>
        <Text
          element="p"
          color="white"
          fontSize="m"
          fontWeight="bold"
          textAlign="center"
          style={{ marginTop: vars.space.m }}
        >
          Anyflow についてのお問い合わせはこちらのフォームよりご連絡ください。
        </Text>
        <CtaFormContainer>
          <Form
            onSubmit={(newValues) => {
              props.onSubmit(newValues)
            }}
          />
        </CtaFormContainer>
      </Inner>
    </CtaSection>
  )
}

export const Br = styled('div')({
  display: 'block',
  [`${vars.media.tablet}`]: {
    display: 'none',
  },
})

const Section = styled('section')({})

const MastheadSection = styled(Section)({
  display: 'flex',
  paddingTop: vars.space.l * 1.5,
  paddingBottom: vars.space.l * 1.5,
  overflow: 'hidden',
})

const MastheadContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  [`${vars.media.tablet}`]: {
    flexDirection: 'column',
  },
})

const MastheadLeft = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  width: '45%',
  height: '100%',
  [`${vars.media.tablet}`]: {
    width: '100%',
  },
})

const MastheadRight = styled('div')({
  width: '55%',
  [`${vars.media.tablet}`]: {
    width: '100%',
  },
})

const Hero = styled('img')({
  display: 'block',
  width: '110%',
  marginLeft: vars.space.l,
  borderRadius: vars.borderRadius.m,
  boxShadow: vars.shadow.m,
  [`${vars.media.tablet}`]: {
    width: '100%',
    marginTop: vars.space.m,
    marginLeft: 0,
  },
})

const ProblemSection = styled(Section)({
  paddingTop: vars.space.xl,
  paddingBottom: vars.space.xl,
  backgroundColor: vars.color.theme,
})

const ProblemList = styled('ul')({
  display: 'flex',
  justifyContent: 'space-between',
  listStyle: 'none',
  paddingLeft: 0,
  [vars.media.tablet]: {
    flexDirection: 'column',
  },
})

const ProblemItem = styled('li')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: vars.space.l,
  width: `calc(100% / 3 - ${vars.space.m}px * 2)`,
  borderRadius: vars.borderRadius.m,
  backgroundColor: vars.color.white,
  [vars.media.tablet]: {
    width: '100%',
    marginTop: vars.space.m,
    '&:first-of-type': {
      marginTop: 0,
    },
  },
})

const SolutionSection = styled(Section)({
  paddingTop: vars.space.xl,
  paddingBottom: vars.space.xl,
})

const SolutionHeading = styled(Text)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [vars.media.tablet]: {
    alignItems: 'center',
    flexDirection: 'column',
  },
})

const SolutionLogo = styled('img')({
  marginRight: vars.space.s,
  [vars.media.tablet]: {
    marginRight: 0,
    marginBottom: vars.space.s,
  },
})

const SolutionList = styled('ul')({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  listStyle: 'none',
  marginLeft: -1 * vars.space.l,
  paddingLeft: 0,
  [vars.media.tablet]: {
    flexDirection: 'column',
  },
})

const SolutionItem = styled('li')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: `calc(100% / 3 - ${vars.space.l}px)`,
  marginTop: vars.space.l,
  marginLeft: vars.space.l,
  borderRadius: vars.borderRadius.m,
  backgroundColor: vars.color.white,
  overflow: 'hidden',
  boxShadow: vars.shadow.m,
  [vars.media.tablet]: {
    width: '100%',
    marginTop: vars.space.m,
    '&:first-of-type': {
      marginTop: 0,
    },
  },
})

const SolutionImageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: vars.space.m,
  height: '100%',
  minHeight: 135,
})

const CtaSection = styled(Section)({
  paddingTop: vars.space.xl,
  paddingBottom: vars.space.xl,
  backgroundColor: vars.color.theme,
})

const CtaFormContainer = styled('div')({
  padding: vars.space.l,
  width: '100%',
  maxWidth: 650,
  marginTop: vars.space.l,
  marginRight: 'auto',
  marginLeft: 'auto',
  borderRadius: vars.borderRadius.m,
  backgroundColor: vars.color.white,
  boxShadow: vars.shadow.m,
})

export default RecipeLp
