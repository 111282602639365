import dayjs from 'dayjs'

import { assertNever } from '~/common/utils'
import { mapJsonToGroupMeta } from '~/data/group/mapJsonToGroup'
import { PermissionJson } from '~/data/workflow/permission/PermissionJson'
import { PermissionPolicyJson } from '~/data/workflow/permission/PermissionPolicyJson'
import { Permission } from '~/domain/workflow/permission/Permission'
import { PermissionPolicy } from '~/domain/workflow/permission/PermissionPolicy'

export function mapJsonToPermission(json: PermissionJson): Permission {
  return new Permission({
    id: json.slug,
    policy: mapJsonToPermissionPolicy(json.permissionPolicy),
    group: mapJsonToGroupMeta(json.group),
    updatedAt: dayjs(json.updatedAt).toDate(),
  })
}

export function mapJsonToPermissionPolicy(
  json: PermissionPolicyJson
): PermissionPolicy {
  switch (json) {
    case 'readable':
      return new PermissionPolicy.Readable()
    case 'updatable':
      return new PermissionPolicy.Updatable()
    case 'deletable':
      return new PermissionPolicy.Deletable()
    case 'ownable':
      return new PermissionPolicy.Ownable()
    default:
      assertNever(json)
  }
}
