import * as _ from 'lodash'

import { assertNever } from '~/common/utils'
import { Definitions } from '~/domain/Definitions'
import { ExpectedObjects } from '~/domain/workflow/expectedObject/ExpectedObjects'
import { ExpectedObjectKey } from '~/domain/workflow/object/ExpectedObjectKey'
import { EntityType } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/types'

export type RenderableElement = string | RenderableEntity

export type RenderableEntity =
  | RenderableElement.Primitive
  | RenderableElement.Property
  | RenderableElement.MethodCallStart
  | RenderableElement.FunctionCallStart
  | RenderableElement.CallEnd
  | RenderableElement.Unsupported

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace RenderableElement {
  interface Base {
    type: EntityType
  }

  export interface Primitive extends Base {
    type: EntityType.PRIMITIVE
    label: string
  }

  export interface Property extends Base {
    type: EntityType.PROPERTY
    expectedObjectKey: ExpectedObjectKey
    propertyKey: string
  }

  export interface MethodCallStart extends Base {
    type: EntityType.METHOD_CALL_START
    expectedObjectKey: ExpectedObjectKey
    methodKey: string
  }

  export interface FunctionCallStart extends Base {
    type: EntityType.FUNCTION_CALL_START
    functionId: string
  }

  export interface CallEnd extends Base {
    type: EntityType.CALL_END
  }

  export interface Unsupported extends Base {
    type: EntityType.UNSUPPORTED
    label: string
  }

  export function toLabel(
    entity: RenderableElement,
    definitions: Definitions,
    expectedObjects: ExpectedObjects
  ): string {
    if (_.isString(entity)) {
      return entity
    }
    switch (entity.type) {
      case EntityType.PROPERTY: {
        const fallback = `${entity.expectedObjectKey.getFriendlyName()}の${
          entity.propertyKey
        }`
        const objectInstance = expectedObjects.findInstance(
          entity.expectedObjectKey.value
        )
        if (objectInstance === undefined) {
          return '削除された変数'
        }
        const object = definitions.findObject(objectInstance.objectId)
        if (object === undefined) {
          return fallback
        }
        const property = object.findProperty(entity.propertyKey)
        if (property === undefined) {
          return fallback
        }
        return `${objectInstance.name}の${property.name}`
      }
      case EntityType.METHOD_CALL_START: {
        const fallback = `${entity.expectedObjectKey.getFriendlyName()}の${
          entity.methodKey
        }(`
        const objectInstance = expectedObjects.findInstance(
          entity.expectedObjectKey.value
        )
        if (objectInstance === undefined) {
          return '削除された関数('
        }
        const object = definitions.findObject(objectInstance.objectId)
        if (object === undefined) {
          return fallback
        }
        const method = object.findMethod(entity.methodKey)
        if (method === undefined) {
          return fallback
        }
        return `${objectInstance.name}の${method.name}(`
      }
      case EntityType.FUNCTION_CALL_START: {
        const fallback = `${entity.functionId}(`
        const func = _.find(
          definitions.functionsById,
          (it) => it.id === entity.functionId
        )
        if (func === undefined) {
          return fallback
        }
        return `${func.name}(`
      }
      case EntityType.CALL_END: {
        return `)`
      }
      case EntityType.PRIMITIVE:
      case EntityType.UNSUPPORTED: {
        return entity.label
      }
      default: {
        assertNever(entity)
      }
    }
  }
}

export function isRenderableEntity(entity: any): entity is RenderableEntity {
  const { type } = entity as RenderableEntity
  if (type !== undefined) {
    return Object.entries(EntityType).some(([typeValue]) => typeValue === type)
  }
  return false
}
