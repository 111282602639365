import * as React from 'react'

import { apiClients } from '~/common/apiClients'
import { SubmitStatus } from '~/common/types'
import { LookupTableServiceImpl } from '~/data/lookupTable/LookupTableServiceImpl'
import { LookupTableService } from '~/domain/lookupTable/LookupTableService'
import { useDefinitions } from '~/presentation/AnyflowAppContext'

export function useCreateLookupTable(): {
  create: (name: string) => Promise<string>
  status: SubmitStatus
} {
  const definitions = useDefinitions()
  const service = React.useRef<LookupTableService>(
    new LookupTableServiceImpl(apiClients.lookupTableApi, definitions)
  )
  const [status, setStatus] = React.useState<SubmitStatus>(SubmitStatus.none)
  const create = React.useCallback((name: string) => {
    setStatus(SubmitStatus.submitting)
    return service.current
      .create(name)
      .then((it) => {
        setStatus(SubmitStatus.submitted)
        return it
      })
      .catch((reason) => {
        setStatus(SubmitStatus.failed)
        throw reason
      })
  }, [])
  return { create, status }
}
