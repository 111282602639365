import styled from '@emotion/styled'
import * as React from 'react'

import LoopMarquee from '~/components/utils/LoopMarquee'
import * as vars from '~/styles/variables'

interface CustomerLogo {
  name: string
  imagePath: string
}

const customerLogos: CustomerLogo[] = [
  {
    name: 'basic',
    imagePath: '/static/images/lp/logos/basic@2x.png',
  },
  {
    name: '弁護士ドットコム',
    imagePath: '/static/images/lp/logos/bengoshi@2x.png',
  },
  {
    name: 'Chatwork',
    imagePath: '/static/images/lp/logos/chatwork@2x.png',
  },
  {
    name: 'CrowdWorks',
    imagePath: '/static/images/lp/logos/crowdworks@2x.png',
  },
  {
    name: 'Cykinso',
    imagePath: '/static/images/lp/logos/cykinso@2x.png',
  },
  {
    name: 'HERP',
    imagePath: '/static/images/lp/logos/herp@2x.png',
  },
  {
    name: 'KiRAMEX',
    imagePath: '/static/images/lp/logos/kiramex@2x.png',
  },
  {
    name: 'mazrica',
    imagePath: '/static/images/lp/logos/mazrica@2x.png',
  },
  {
    name: 'InfoMart',
    imagePath: '/static/images/lp/logos/infomart@2x.png',
  },
  {
    name: 'ROXX',
    imagePath: '/static/images/lp/logos/roxx@2x.png',
  },
  {
    name: 'Timee',
    imagePath: '/static/images/lp/logos/timee@2x.png',
  },
  {
    name: 'WANTEDLY',
    imagePath: '/static/images/lp/logos/wantedly@2x.png',
  },
  {
    name: 'NewsPicks',
    imagePath: '/static/images/lp/logos/newspicks@2x.png',
  },
  {
    name: 'globis',
    imagePath: '/static/images/lp/logos/globis@2x.png',
  },
  {
    name: 'flamingo',
    imagePath: '/static/images/lp/logos/flamingo@2x.png',
  },
  {
    name: 'kaizen_platform',
    imagePath: '/static/images/lp/logos/kaizen_platform@2x.png',
  },
]

const Customers: React.FC = () => {
  return (
    <div>
      <Heading>導入企業</Heading>
      <LoopMarquee tileCount={3}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {customerLogos.map((logo) => (
            <img
              key={logo.name}
              alt={logo.name}
              srcSet={`${logo.imagePath} 2x`}
              style={{
                marginRight: vars.space.l,
                width: 'auto',
                height: 76,
                objectFit: 'contain',
              }}
            />
          ))}
        </div>
      </LoopMarquee>
    </div>
  )
}

const Heading = styled('h1')({
  fontWeight: 800,
  fontSize: vars.fontSize.xxxxl,
  lineHeight: vars.lineHeight.heading,
  textAlign: 'center',
  [vars.media.tablet]: {
    fontSize: vars.fontSize.xl,
  },
})

export default Customers
