import * as React from 'react'

import { assert } from '~/common/utils'
import ErrorMessage from '~/components/atoms/ErrorMessage'
import MultiSelectField from '~/components/molecules/MultiSelectField'
import { Option as SelectFieldOption } from '~/components/molecules/SelectField'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { MultiSelectWidgetDefinition } from '~/domain/workflow/widget/select'
import { RawInputWidgetProps } from '~/presentation/workflow/detail/editor/form/inputWidget/RawInputWidget'
import { useInputWidgetValidation } from '~/presentation/workflow/detail/editor/form/validation/useValidation'
import * as vars from '~/styles/variables'

interface Props extends RawInputWidgetProps {
  definition: MultiSelectWidgetDefinition
}

const MultiSelectWidget: React.FC<Props> = (props) => {
  // バリデーション
  const validationResult = useInputWidgetValidation(props)

  const options: SelectFieldOption<string>[] = React.useMemo(() => {
    return props.definition.options.map((option) => {
      const json = JSON.stringify(option.value)
      return {
        label: option.label,
        value: json,
      }
    })
  }, [props.definition])

  const values: string[] = React.useMemo(() => {
    if (props.value === undefined) {
      return []
    }
    assert(
      InputValue.isInputValueList(props.value.raw),
      `This operator requires value list, but the value is of type: ${typeof props
        .value.raw}`
    )
    const list: string[] = []
    props.value.raw.forEach((it) => {
      if (it === undefined) {
        return
      }
      assert(it.mode === 'raw', `you can not use render value here`)
      list.push(JSON.stringify(it.raw))
    })
    return list
  }, [props.value])

  const handleChange = React.useCallback(
    (newValues: string[]) => {
      if (newValues.length === 0) {
        props.onChange(undefined)
        return
      }
      props.onChange({
        mode: 'raw',
        raw: newValues.map((it) => ({
          mode: 'raw',
          raw: JSON.parse(it),
        })),
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChange]
  )

  return (
    <>
      <MultiSelectField
        values={values}
        options={options}
        unselectedOptionLabel="選択してください"
        disabled={props.readonly}
        onChange={handleChange}
      />
      {validationResult?.valid === false && (
        <ErrorMessage style={{ marginTop: vars.space.s }}>
          {validationResult.cause.message}
        </ErrorMessage>
      )}
    </>
  )
}

export default MultiSelectWidget
