import React from 'react'

import { MethodCardProps } from '~/components/molecules/HoverCard/types'
import PaintedPill from '~/components/molecules/PaintedPill'
import HoverCardTarget from '~/components/organisms/HoverCardTarget/HoverCardTarget'
import { AppDefinition } from '~/domain/workflow/app/AppDefinition'
import { ExpectedObject } from '~/domain/workflow/expectedObject/ExpectedObjects'
import { ObjectMember } from '~/domain/workflow/object/ObjectDefinition'

interface Props {
  app: AppDefinition
  object: ExpectedObject
  member: ObjectMember.Method
  enabled: boolean
  onClick: (object: ExpectedObject, member: ObjectMember.Method) => void
}

const ObjectMethodPill: React.FC<Props> = (props) => {
  const cardProps = React.useMemo<MethodCardProps>(
    () => ({
      type: 'method',
      app: props.app,
      method: props.member,
      expectedObjectName: props.object.name,
    }),
    [props.app, props.member, props.object]
  )
  return (
    <HoverCardTarget card={cardProps} disabled={!props.enabled}>
      <PaintedPill
        color={props.app.color}
        iconPath={props.app.iconPath}
        disabled={!props.enabled}
        onClick={() => {
          if (!props.enabled) {
            return
          }
          props.onClick(props.object, props.member)
        }}
      >
        {props.object.name}の{props.member.name} ( )
      </PaintedPill>
    </HoverCardTarget>
  )
}

export default ObjectMethodPill
