import styled from '@emotion/styled'
import * as React from 'react'
import { X } from 'react-feather'

import Button from '~/components/atoms/Button'
import NoAppIcon from '~/components/atoms/NoAppIcon'
import Text from '~/components/atoms/Text'
import TextField from '~/components/atoms/TextField'
import Focusable from '~/components/common/Focusable'
import App from '~/components/molecules/App'
import SelectField, { Option } from '~/components/molecules/SelectField'
import { LookupTableColumnSchema } from '~/domain/lookupTable/LookupTableColumnSchema'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import * as vars from '~/styles/variables'

interface Props {
  schema: LookupTableColumnSchema
  onSubmitClick: (newColumnSchema: LookupTableColumnSchema) => void
  onCloseClick: () => void
}

const SchemaEditor: React.FC<Props> = (props) => {
  const definitions = useDefinitions()
  const [label, setLabel] = React.useState<string>(props.schema.label)
  const [appId, setAppId] = React.useState<string | undefined>(
    props.schema.app?.appId
  )

  const selectOptions: Option<string>[] = React.useMemo(
    () =>
      definitions
        .getApps()
        .map((app) => ({ label: app.name, value: app.appId })),
    [definitions]
  )

  const handleSaveClick = React.useCallback(() => {
    props.onSubmitClick({
      id: props.schema.id,
      label,
      app: appId !== undefined ? definitions.getApp(appId) : undefined,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onSubmitClick, props.schema.id, label, appId, definitions])

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.nativeEvent.isComposing) {
        return
      }
      if (e.key === 'Enter') {
        handleSaveClick()
      } else if (e.key === 'Escape') {
        props.onCloseClick()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleSaveClick, props.onCloseClick]
  )

  React.useEffect(() => {
    document.addEventListener('click', props.onCloseClick)
    return () => {
      document.removeEventListener('click', props.onCloseClick)
    }
  }, [props.onCloseClick])

  return (
    <Focusable
      onKeyDown={handleKeyDown}
      onClick={(e) => {
        // クリックイベントが document まで貫通してエディタを閉じないようにする
        e.nativeEvent.stopImmediatePropagation()
      }}
    >
      <Container>
        <div
          style={{
            marginLeft: 'auto',
            width: 20,
            height: 20,
            cursor: 'pointer',
          }}
          onClick={props.onCloseClick}
        >
          <X color={vars.color.icon} size={20} />
        </div>
        <Text
          element="h1"
          fontSize="s"
          fontWeight="bold"
          lineHeight="just"
          style={{
            marginBottom: vars.space.s,
          }}
        >
          アプリ
        </Text>
        <SelectField
          value={appId ?? ''}
          unselectedOption={{ label: '選択しない', value: '' }}
          options={selectOptions}
          renderItem={(item) => {
            const app = definitions.findApp(item.value)
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {app === undefined ? (
                  <NoAppIcon />
                ) : (
                  <App color={app.color} icon={app.iconPath} size="s" />
                )}
                <Text
                  element="span"
                  lineHeight="heading"
                  fontSize="s"
                  style={{ marginLeft: vars.space.s }}
                >
                  {item.label}
                </Text>
              </div>
            )
          }}
          onChange={(value) => setAppId(value !== '' ? value : undefined)}
        />
        <Text
          element="h1"
          fontSize="s"
          fontWeight="bold"
          lineHeight="just"
          style={{
            marginTop: vars.space.m,
            marginBottom: vars.space.s,
          }}
        >
          列名
        </Text>
        <TextField
          autoFocus={true}
          value={label}
          onChange={(e) => {
            setLabel(e.currentTarget.value)
          }}
          style={{ fontSize: vars.fontSize.s }}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginTop: vars.space.m,
          }}
        >
          <Button type="tertiary" onClick={props.onCloseClick}>
            閉じる
          </Button>
          <Button onClick={handleSaveClick}>更新する</Button>
        </div>
      </Container>
    </Focusable>
  )
}

const schemaEditorWidth = 350

const Container = styled('div')({
  padding: vars.space.m,
  width: schemaEditorWidth,
  backgroundColor: vars.color.white,
  borderRadius: vars.borderRadius.m,
  boxShadow: vars.shadow.m,
})

export default SchemaEditor
