import * as React from 'react'

import { apiClients } from '~/common/apiClients'
import { FetchStatus } from '~/common/types'
import { WorkflowService } from '~/domain/workflow/WorkflowService'
import { ExpectedObjects } from '~/domain/workflow/expectedObject/ExpectedObjects'

const workflowService: WorkflowService = apiClients.workflowService

export function useExpectedObjects(
  workflowId: string,
  parentTaskId?: string
): {
  expectedObjects?: ExpectedObjects
  errorMessage?: string
  fetchStatus: FetchStatus
} {
  const [status, setStatus] = React.useState<FetchStatus>(FetchStatus.none)
  const [expectedObjects, setExpectedObjects] = React.useState<
    ExpectedObjects
  >()
  const [errorMessage, setErrorMessage] = React.useState<string>()

  React.useEffect(() => {
    setStatus(FetchStatus.loading)
    setExpectedObjects(undefined)
    workflowService
      .getExpectedObjects(workflowId, parentTaskId)
      .then((it) => {
        setExpectedObjects(it)
        setStatus(FetchStatus.loaded)
      })
      .catch((reason) => {
        console.error(reason)
        setErrorMessage('変数一覧の取得に失敗しました')
        setStatus(FetchStatus.failed)
      })
  }, [workflowId, parentTaskId])

  return { expectedObjects, errorMessage, fetchStatus: status }
}
