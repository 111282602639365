import * as React from 'react'

interface Props extends React.SVGAttributes<SVGForeignObjectElement> {
  width: number
  height: number
  children: React.ReactNode
}

const ForeignObject: React.FC<Props> = ({
  width,
  height,
  children,
  ...props
}) => (
  <foreignObject width={width} height={height} {...props}>
    {/* 
    // @ts-ignore */}
    <div xmlns="http://www.w3.org/1999/xhtml" style={{ width, height }}>
      {children}
    </div>
  </foreignObject>
)

export default ForeignObject
