import dayjs from 'dayjs'

import {
  LookupTableCellJson,
  LookupTableJson,
  LookupTableRowJson,
} from '~/data/lookupTable/LookupTableJson'
import { LookupTableCell } from '~/domain/lookupTable/LookupTableCell'
import { LookupTableRow } from '~/domain/lookupTable/LookupTableRow'
import { LookupTableSummary } from '~/domain/lookupTable/LookupTableSummary'

export function mapJsonToLookupTableSummary(
  json: LookupTableJson
): LookupTableSummary {
  return {
    id: json.slug,
    name: json.name,
    updatedAt: dayjs(json.updatedAt).toDate(),
  }
}

export function mapJsonToLookupTableRow(
  json: LookupTableRowJson
): LookupTableRow {
  return {
    id: json.id,
    cells: json.cells.map(mapJsonToLookupTableCell),
  }
}

export function mapJsonToLookupTableCell(
  json: LookupTableCellJson
): LookupTableCell {
  return {
    columnSchemaId: json.schemaId,
    value: json.value,
  }
}
