import * as hash from 'object-hash'
import React from 'react'

import { StaticFieldDefinition } from '~/domain/workflow/field/FieldDefinition'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { DependentFields } from '~/presentation/workflow/detail/editor/form/field/DependentField'
import StaticField from '~/presentation/workflow/detail/editor/form/field/StaticField'

interface Props {
  field: StaticFieldDefinition
  value: InputValue
  bulkMode: boolean
  readonly: boolean
  dependentFields: DependentFields
  putInputValue: (key: string, value: InputValue) => void
  putBulkSize: (key: string, bulkSize?: number | 'n') => void
}

const MemoizedStaticField: React.FC<Props> = (props) => {
  const handleChange = React.useCallback(
    (value: InputValue) => {
      props.putInputValue(props.field.key, value)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.field.key, props.putInputValue]
  )
  const handleBulkSizeChange = React.useCallback(
    (bulkSize?: number | 'n') => {
      props.putBulkSize(props.field.key, bulkSize)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.field.key, props.putBulkSize]
  )
  return React.useMemo(
    () => (
      <StaticField
        definition={props.field}
        value={props.value}
        bulkMode={props.bulkMode}
        readonly={props.readonly}
        dependentFields={props.dependentFields}
        onChange={handleChange}
        onBulkSizeChange={handleBulkSizeChange}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      props.field,
      props.value,
      props.bulkMode,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      hash(props.dependentFields),
      handleChange,
      handleBulkSizeChange,
    ]
  )
}

export default MemoizedStaticField
