import styled from '@emotion/styled'
import React from 'react'
import * as Icons from 'react-feather'

import Loader from '~/components/atoms/Loader'
import MiniButton from '~/components/atoms/MiniButton'
import Text from '~/components/atoms/Text'
import SearchField from '~/components/molecules/SearchField'
import Modal from '~/components/organisms/Modal'
import NoResults from '~/components/organisms/NoResults'
import {
  Table,
  Body as Tbody,
  Cell as Td,
  HeaderCell as Th,
  Header as Thead,
  Row as Tr,
} from '~/components/organisms/Table'
import { GroupSummary } from '~/domain/group/GroupSummary'
import { useAllGroups } from '~/presentation/workflow/share/useAllGroups'
import * as vars from '~/styles/variables'

interface Props {
  isOpen: boolean
  ownerGroupIds: string[]
  addingGroupIds: string[]
  addedGroupIds: string[]
  handleClose: () => void
  handleAdd: (groupId: string) => void
}

export const ConnectionAddPermissionModal: React.FC<Props> = (props) => {
  const { result } = useAllGroups()

  const [query, setQuery] = React.useState<string>('')

  const filteredGroups: GroupSummary[] = React.useMemo(() => {
    if (result.loading || result.error) {
      return []
    }
    return result.data.filter(
      (it) => it.name.includes(query) && !props.ownerGroupIds.includes(it.id)
    )
  }, [result, query, props.ownerGroupIds])

  const handleQueryChange = React.useCallback((newQuery: string) => {
    setQuery(newQuery)
  }, [])

  return (
    <_Modal
      isOpened={props.isOpen}
      onClose={props.handleClose}
      lightDimmer={true}
    >
      <Icons.X
        size={22}
        color={vars.color.icon}
        style={{
          position: 'absolute',
          top: vars.space.l,
          right: vars.space.l,
          cursor: 'pointer',
        }}
        onClick={props.handleClose}
      />
      <Title>共有するグループを追加</Title>
      <div style={{ marginTop: vars.space.m * 1.5 }}>
        <SearchField
          value={query}
          placeholder="グループ名を検索"
          onChange={handleQueryChange}
        />
      </div>
      <div
        style={{ height: 390, marginTop: vars.space.m, overflowY: 'scroll' }}
      >
        {result.loading ? (
          <LoadingTable />
        ) : result.error ? (
          <ErrorTable />
        ) : filteredGroups.length === 0 ? (
          query === '' ? (
            <NoGroupsTable />
          ) : (
            <NoMatchGroupsTable />
          )
        ) : (
          <GroupTable
            groups={filteredGroups}
            addingGroupIds={props.addingGroupIds}
            addedGroupIds={props.addedGroupIds}
            handleAdd={props.handleAdd}
          />
        )}
      </div>
    </_Modal>
  )
}

const LoadingTable = () => (
  <div
    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
  >
    <Loader />
  </div>
)

const ErrorTable = () => (
  <NoResults heading="エラー" description="グループ一覧の取得に失敗しました" />
)

const NoMatchGroupsTable = () => (
  <Text element="p" fontSize="s" color="tertiary" textAlign="center">
    条件に一致するグループはありません
  </Text>
)

const NoGroupsTable = () => (
  <Text element="p" fontSize="s" color="tertiary" textAlign="center">
    共有できるグループはありません
  </Text>
)

const GroupTable: React.FC<{
  groups: GroupSummary[]
  addingGroupIds: string[]
  addedGroupIds: string[]
  handleAdd: (groupId: string) => void
}> = (props) => {
  return (
    <Table style={{ tableLayout: 'fixed' }}>
      <Thead>
        <Th>グループ名</Th>
        <Th style={{ width: 200 }}>人数</Th>
        <Th style={{ width: 120 }}>{null}</Th>
      </Thead>
      <Tbody>
        {props.groups.map((group) => (
          <GroupRow
            key={group.id}
            group={group}
            adding={props.addingGroupIds.includes(group.id)}
            added={props.addedGroupIds.includes(group.id)}
            handleAdd={props.handleAdd}
          />
        ))}
      </Tbody>
    </Table>
  )
}

const GroupRow: React.FC<{
  group: GroupSummary
  adding: boolean
  added: boolean
  handleAdd: (groupId: string) => void
}> = (props) => {
  const { handleAdd } = props
  const handleAddButtonClick = React.useCallback(() => {
    handleAdd(props.group.id)
  }, [handleAdd, props.group.id])
  return (
    <Tr>
      <Td>
        <Text element="span" fontWeight="bold">
          {props.group.name}
        </Text>
      </Td>
      <Td>{props.group.memberCount}人</Td>
      <Td style={{ textAlign: 'right' }}>
        {props.added ? (
          <AddedButton />
        ) : props.adding ? (
          <AddingButton />
        ) : (
          <AddButton onClick={handleAddButtonClick} />
        )}
      </Td>
    </Tr>
  )
}

const AddButton: React.FC<{
  onClick: () => void
}> = (props) => {
  const [hover, setHover] = React.useState<boolean>(false)
  const handleMouseEnter = React.useCallback(() => {
    setHover(true)
  }, [])
  const handleMouseLeave = React.useCallback(() => {
    setHover(false)
  }, [])
  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {hover ? (
        <ActiveAddButton onClick={props.onClick} />
      ) : (
        <InactiveAddButton />
      )}
    </div>
  )
}

const InactiveAddButton: React.FC = () => (
  <MiniButton role="secondary" style={{ width: 80 }}>
    未追加
  </MiniButton>
)

const ActiveAddButton: React.FC<{ onClick: () => void }> = (props) => (
  <MiniButton style={{ width: 80 }} onClick={props.onClick}>
    追加
  </MiniButton>
)

const AddingButton: React.FC = () => (
  <MiniButton style={{ width: 80 }} isLoading={true} />
)

const AddedButton: React.FC = () => (
  <MiniButton style={{ width: 80 }}>追加済</MiniButton>
)

const _Modal = styled(Modal)({
  width: 800,
  padding: vars.space.l,
})

const Title = styled('h1')({
  margin: 0,
  fontSize: vars.fontSize.xl,
  fontWeight: 'bold',
})
