import * as React from 'react'

import { run } from '~/common/utils'
import ErrorMessage from '~/components/atoms/ErrorMessage'
import Switch from '~/components/atoms/Switch'
import { SwitchWidgetDefinition } from '~/domain/workflow/widget/switch'
import { RawInputWidgetProps } from '~/presentation/workflow/detail/editor/form/inputWidget/RawInputWidget'
import { useInputWidgetValidation } from '~/presentation/workflow/detail/editor/form/validation/useValidation'
import * as vars from '~/styles/variables'

interface Props extends RawInputWidgetProps {
  definition: SwitchWidgetDefinition
}

const SwitchWidget: React.FC<Props> = (props) => {
  // バリデーション
  const validationResult = useInputWidgetValidation(props)

  const handleChange = (newValue: boolean) => {
    props.onChange({
      mode: 'raw',
      raw: newValue,
    })
  }

  const value: boolean = run(() => {
    if (props.value === undefined) {
      return false
    }
    if (typeof props.value.raw !== 'boolean') {
      return false
    }
    return props.value.raw
  })

  return (
    <>
      <Switch
        value={value}
        disabled={props.readonly}
        onChange={(newValue) => handleChange(newValue)}
      />
      {validationResult?.valid === false && (
        <ErrorMessage style={{ marginTop: vars.space.s }}>
          {validationResult.cause.message}
        </ErrorMessage>
      )}
    </>
  )
}

export default SwitchWidget
