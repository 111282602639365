import * as _ from 'lodash'

import { JinjaNode, JinjaPrimitive } from '~/common/types/jinja'
import * as utils from '~/common/utils'
import { RenderableElement } from '~/domain/workflow/source/RenderableElement'
import { EntityType } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/types'

/**
 * プリミティブな値を文字列に変換
 */
export function primitiveToString(value: JinjaPrimitive) {
  if (typeof value === 'string') {
    return `"${value}"`
  }
  if (typeof value === 'number') {
    return String(value)
  }
  if (typeof value === 'boolean') {
    return value ? 'True' : 'False'
  }
  // if value === null
  return 'None'
}

export function getPlainTextFromJinjaNode(node: JinjaNode): string {
  switch (node.typ) {
    case 'Const': {
      return primitiveToString(node.value)
    }
    case 'Name': {
      return node.name
    }
    case 'Pair': {
      const key =
        node.key.typ === 'Name'
          ? node.key.name
          : primitiveToString(node.key.value)
      let result = ''
      if (node.value.typ === 'Const') {
        result = `${key}: ${primitiveToString(node.value.value)}`
      } else if (node.value.typ === 'Name') {
        result = `${key}: ${node.value.name}`
      } else {
        // Dict や List のケース
        result = `${key}: ${getPlainTextFromJinjaNode(node.value)}`
      }
      return result
    }
    case 'List': {
      const a = node.items.map((item) => {
        return getPlainTextFromJinjaNode(item)
      })
      return `[${a.join(', ')}]`
    }
    case 'Dict': {
      const a = node.items.map((item) => {
        return getPlainTextFromJinjaNode(item)
      })
      return `{${a.join(', ')}}`
    }
  }
  return ''
}

export function convertRenderableElementsToJinjaTemplateText(
  renderableElements: RenderableElement[]
): string {
  let finalText = ''
  // <<が挿入されているかどうか
  let isOpeningDelimiter = false
  // <<を挿入するのを何回スキップしたか
  let skipCount = 0
  renderableElements.forEach((res) => {
    if (_.isString(res)) {
      finalText += res
    } else if (res.type === EntityType.FUNCTION_CALL_START) {
      if (!isOpeningDelimiter) {
        finalText += `<<${res.functionId}(`
        isOpeningDelimiter = true
      } else {
        finalText += `${res.functionId}(`
        skipCount++
      }
    } else if (res.type === EntityType.METHOD_CALL_START) {
      const _text = `${res.expectedObjectKey.value}.${res.methodKey}(`
      if (!isOpeningDelimiter) {
        finalText += `<<${_text}`
        isOpeningDelimiter = true
      } else {
        finalText += _text
        skipCount++
      }
    } else if (res.type === EntityType.CALL_END) {
      if (skipCount > 0) {
        finalText += ')'
        skipCount--
      } else {
        finalText += ')>>'
        isOpeningDelimiter = false
      }
    } else if (res.type === EntityType.PROPERTY) {
      const _text = `${res.expectedObjectKey.value}.${res.propertyKey}`
      if (!isOpeningDelimiter) {
        finalText += `<<${_text}>>`
      } else {
        finalText += _text
      }
    } else if (res.type === EntityType.PRIMITIVE) {
      const _text = res.label
      if (!isOpeningDelimiter) {
        finalText += `<<${_text}>>`
      } else {
        finalText += _text
      }
    } else if (res.type === EntityType.UNSUPPORTED) {
      const _text = res.label
      if (!isOpeningDelimiter) {
        finalText += `<<${_text}>>`
      } else {
        finalText += _text
      }
    } else {
      utils.assertNever(res)
    }
  })
  return finalText
}

export function flattenRenderableElements(
  renderableElements: RenderableElement[][]
): RenderableElement[] {
  const flattened: RenderableElement[] = []
  renderableElements.forEach((it) => flattened.push(...[...it, '\n']))
  return _.dropRight(flattened, 1)
}
