import styled from '@emotion/styled'
import * as _ from 'lodash'
import * as React from 'react'
import { useHistory } from 'react-router'

import { apiClients } from '~/common/apiClients'
import Text from '~/components/atoms/Text'
import AppLoader from '~/components/common/AppLoader'
import { tokenBasedProviderDefinitions } from '~/data/provider/tokenBasedProviderDefinitions'
import { NoOrganizationError } from '~/data/user/NoOrganizationError'
import { appDefinitions } from '~/data/workflow/app/entities'
import { Definitions } from '~/domain/Definitions'
import { SessionService } from '~/domain/session/SessionService'
import { Me } from '~/domain/user/Me'
import { AnyflowAppContext } from '~/presentation/AnyflowAppContext'
import { useToaster } from '~/presentation/ToasterContext'
import { useActionDefinitions } from '~/presentation/useActionDefinitions'
import { useFunctionDefinitions } from '~/presentation/useFunctionDefinitions'
import { useObjectDefinitions } from '~/presentation/useObjectDefinitions'
import { useTriggerDefinitions } from '~/presentation/useTriggerDefinitions'
import { ORGANIZATION_CREATE, SIGNIN } from '~/routes'
import * as vars from '~/styles/variables'

interface Props {}

const takingTimeThreshold = 2000

const sessionService: SessionService = apiClients.sessionService

const AnyflowApp: React.FC<Props> = (props) => {
  const history = useHistory()
  const toaster = useToaster()
  const [takingTime, setTakingTime] = React.useState<boolean>(false)
  const [me, setMe] = React.useState<Me>()

  React.useEffect(() => {
    sessionService
      .getMe()
      .then((res) => {
        setMe(res)
      })
      .catch((e) => {
        if (e instanceof NoOrganizationError) {
          history.push(ORGANIZATION_CREATE)
          return
        }
        console.error(e)
        history.push(SIGNIN)
      })
  }, [history])

  React.useEffect(() => {
    if (me === undefined || window.Intercom === undefined) {
      return
    }
    window.Intercom('update', {
      user_id: me.id,
      name: me.username,
      email: me.email,
      company: {
        company_id: me.organization.id,
        name: me.organization.name,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.Intercom, me])

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTakingTime(true)
    }, takingTimeThreshold)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  const { actions, errorMessage: actionsError } = useActionDefinitions()
  const { triggers, errorMessage: triggersError } = useTriggerDefinitions()
  const { objects, errorMessage: objectsError } = useObjectDefinitions()
  const { functions, errorMessage: functionsError } = useFunctionDefinitions()

  const definitions = React.useMemo<Definitions | undefined>(() => {
    if (
      actions === undefined ||
      triggers === undefined ||
      objects === undefined ||
      functions === undefined
    ) {
      return undefined
    }
    return new Definitions(
      _.keyBy(triggers, (it) => it.triggerId),
      _.keyBy(actions, (it) => it.actionId),
      _.keyBy(appDefinitions, (it) => it.appId),
      _.keyBy(objects, (it) => it.objectId),
      _.keyBy(functions, (it) => it.id),
      _.keyBy(tokenBasedProviderDefinitions, (it) => it.providerId)
    )
  }, [actions, triggers, objects, functions])

  const hasError =
    actionsError !== undefined ||
    triggersError !== undefined ||
    objectsError !== undefined ||
    functionsError !== undefined

  const handleGroupChange = React.useCallback(
    async (newGroupId: string) => {
      try {
        await sessionService.changeCurrentGroup(newGroupId)
        window.location.reload()
      } catch {
        toaster.showError('グループの切り替えに失敗しました')
      }
    },
    [toaster]
  )

  const handleRefreshMe = React.useCallback(async () => {
    try {
      const newMe = await sessionService.getMe()
      setMe(newMe)
    } catch {
      console.error(`Failed to refresh me`)
    }
  }, [])

  const contextValue = React.useMemo<
    React.ContextType<typeof AnyflowAppContext> | undefined
  >(() => {
    if (definitions === undefined || me === undefined) {
      return undefined
    }
    return {
      definitions,
      me,
      changeGroup: handleGroupChange,
      refreshMe: handleRefreshMe,
    }
  }, [definitions, me, handleGroupChange, handleRefreshMe])

  if (contextValue === undefined) {
    return <GreatLoader takingTime={takingTime} error={hasError} />
  }

  return (
    <AnyflowAppContext.Provider value={contextValue}>
      {props.children}
    </AnyflowAppContext.Provider>
  )
}

const GreatLoader: React.FC<{
  takingTime: boolean
  error: boolean
}> = (props) => {
  return (
    <Cover>
      <AppLoader />
      {props.takingTime && !props.error && (
        <Text
          element="p"
          color="secondary"
          fontSize="s"
          style={{
            marginTop: vars.space.m,
          }}
        >
          通常よりも読み込みに時間がかかっています...
        </Text>
      )}
      {props.error && (
        <Text
          element="p"
          color="secondary"
          fontSize="s"
          style={{
            textAlign: 'center',
            marginTop: vars.space.m,
          }}
        >
          データの取得中に問題が発生しました。
          <br />
          しばらく経ってからもう一度お試しください。
        </Text>
      )}
    </Cover>
  )
}

const Cover = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: vars.color.white,
})

export default AnyflowApp
