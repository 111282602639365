export type Result<T> = Result.Failure | Result.Success<T> | Result.Loading

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Result {
  export class Failure {
    public readonly loading = false
    public readonly error: Error

    constructor(error: Error) {
      this.error = error
    }
  }

  export class Success<T> {
    public readonly loading = false
    public readonly error = undefined
    public readonly data: T

    constructor(data: T) {
      this.data = data
    }
  }

  export class Loading {
    public readonly loading = true
  }
}
