import styled from '@emotion/styled'
import * as React from 'react'
import { X } from 'react-feather'

import Text from '~/components/atoms/Text'
import App from '~/components/molecules/App'
import { AppDefinition } from '~/domain/workflow/app/AppDefinition'
import * as vars from '~/styles/variables'

interface Props {
  title: string
  app: AppDefinition
  onCloseClick: () => void
}

const InputFormHeader: React.FC<Props> = (props) => {
  return (
    <Heading>
      <App icon={props.app.iconPath} color={props.app.color} size="s" />
      <Text
        element="p"
        fontSize="m"
        fontWeight="bold"
        lineHeight="heading"
        style={{
          marginTop: 1,
          marginLeft: vars.space.s,
        }}
      >
        {props.title}
      </Text>
      <CloseButton onClick={props.onCloseClick}>
        <X color={vars.color.icon} size={22} />
      </CloseButton>
    </Heading>
  )
}

const Heading = styled('h2')({
  display: 'flex',
  alignItems: 'flex-start',
  paddingTop: vars.space.m,
  paddingRight: vars.space.m,
  paddingBottom: vars.space.m,
  paddingLeft: vars.space.m,
  color: vars.fontColor.primary,
  fontSize: vars.fontSize.s,
})

const CloseButton = styled('div')({
  marginTop: 1,
  marginLeft: 'auto',
  height: 25,
  cursor: 'pointer',
})

export default InputFormHeader
