import styled from '@emotion/styled'
import * as React from 'react'
import { X } from 'react-feather'

import Text from '~/components/atoms/Text'
import * as vars from '~/styles/variables'

interface Props {
  tabs: { id: string; label: string }[]
  color: string
  height?: number
  onClose?: () => void
  onTabClick?: () => void
  renderContent: (tabId: string) => React.ReactNode
}

const Window: React.FC<Props> = ({
  tabs,
  color,
  height = 400,
  renderContent,
  onClose,
  onTabClick,
}) => {
  const [selectedTabId, setSelectedTabId] = React.useState<string>('')

  React.useEffect(() => {
    if (tabs.length > 0) {
      // 初期状態として0番目のタブを選択
      setSelectedTabId(tabs[0].id)
    }
  }, [tabs])

  const handleClose = React.useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  return (
    <Container height={height}>
      <WindowBar color={color}>
        <Tabs>
          {tabs.map((tab) => {
            return (
              <Tab
                key={tab.id}
                color={color}
                selected={tab.id === selectedTabId}
                onClick={() => {
                  setSelectedTabId(tab.id)
                  if (onTabClick) {
                    onTabClick()
                  }
                }}
              >
                <Text
                  element="span"
                  color={tab.id === selectedTabId ? color : vars.color.white}
                  fontSize="xs"
                  fontWeight="bold"
                  lineHeight="just"
                >
                  {tab.label}
                </Text>
              </Tab>
            )
          })}
        </Tabs>
        <CloseButton onClick={handleClose}>
          <X color="white" size="18" strokeWidth={3} />
        </CloseButton>
      </WindowBar>
      <Content>{renderContent(selectedTabId)}</Content>
    </Container>
  )
}

const windowBarHeight = 40

const Container = styled('div')<{ height?: number }>(
  {
    minWidth: 300,
    borderRadius: vars.borderRadius.s,
    boxShadow: vars.shadow.m,
  },
  (props) => ({
    height: props.height,
  })
)

const WindowBar = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: windowBarHeight,
    paddingRight: vars.space.m,
    paddingLeft: vars.space.m,
    borderTopLeftRadius: vars.borderRadius.s,
    borderTopRightRadius: vars.borderRadius.s,
  },
  (props: { color?: string }) => ({
    backgroundColor: props.color ? props.color : vars.color.theme,
  })
)

const Tabs = styled('div')({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'flex-end',
})

const Tab = styled('div')<{ selected: boolean; color?: string }>(
  {
    display: 'flex',
    alignItems: 'center',
    marginRight: vars.space.s,
    paddingTop: vars.space.s,
    paddingRight: vars.space.m,
    paddingBottom: vars.space.s,
    paddingLeft: vars.space.m,
    borderTopRightRadius: vars.borderRadius.s,
    borderTopLeftRadius: vars.borderRadius.s,
    cursor: 'pointer',
  },
  (props) => ({
    backgroundColor: props.selected ? vars.color.white : 'transparent',
  })
)

const CloseButton = styled('div')({
  cursor: 'pointer',
  height: 18,
})

const Content = styled('div')({
  height: `calc(100% - ${windowBarHeight}px)`,
  backgroundColor: vars.color.white,
  overflowY: 'scroll',
  borderBottomLeftRadius: vars.borderRadius.s,
  borderBottomRightRadius: vars.borderRadius.s,
})

export default Window
