import firebase from 'firebase/app'
import 'firebase/auth'

import config from '~/common/config'

if (firebase.apps.length === 0) {
  firebase.initializeApp({
    apiKey: config.firebase.apiKey,
    authDomain: config.firebase.authDomain,
  })
}

export const firebaseAuth = firebase.auth

export function googleSignInWithPopup(): Promise<firebase.auth.UserCredential> {
  const provider = new firebase.auth.GoogleAuthProvider()

  // 毎回アカウント選択画面を表示する
  provider.setCustomParameters({
    prompt: 'select_account',
  })

  return firebase.auth().signInWithPopup(provider)
}
