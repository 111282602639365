import styled from '@emotion/styled'
import * as React from 'react'

import * as vars from '~/styles/variables'

interface Props {
  size?: 'xs' | 's' | 'm'
  width?: number
  responsive?: boolean
  children: React.ReactNode
  className?: string
}

const Inner: React.FunctionComponent<Props> = ({
  size = 'm',
  width,
  responsive = false,
  className,
  children,
}) => (
  <Container
    className={className}
    size={size}
    width={width}
    responsive={responsive}
  >
    {children}
  </Container>
)

const Container = styled('div')(
  {
    margin: '0 auto',
    paddingRight: vars.space.m,
    paddingLeft: vars.space.m,
    width: '100%',
  },
  (props: { size: string; width?: number; responsive: boolean }) => {
    // width 優先
    const width = props.width
      ? props.width
      : props.size === 'm'
      ? vars.width.m
      : props.size === 's'
      ? vars.width.s
      : vars.width.xs
    return props.responsive
      ? {
          maxWidth: width,
        }
      : {
          width,
        }
  }
)

export default Inner
