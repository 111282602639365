import styled from '@emotion/styled'
import * as React from 'react'

import * as vars from '~/styles/variables'

interface Props {
  items: any[]
  keyExtractor: (item: any) => any
  renderItem: (item: any) => React.ReactNode
  className?: string
  /** カラム数 */
  column?: number
  /** 最大表示するアイテム数 */
  maxLength?: number
  /** 各グリッド間の余白 */
  gap?: number
  /** レスポンシブ対応するかどうか */
  responsive?: boolean
}

const Grid: React.FunctionComponent<Props> = ({
  className,
  items,
  column = 3,
  gap = vars.space.s,
  maxLength,
  keyExtractor,
  renderItem,
  responsive = false,
}) => (
  <Container className={className} gap={gap}>
    {(() => {
      const components = []
      const end = maxLength || items.length
      for (let i = 0; i < end; i++) {
        const item = items[i]
        components.push(
          <GridItem
            key={keyExtractor(item)}
            column={column}
            gap={gap}
            responsive={responsive}
          >
            {renderItem(item)}
          </GridItem>
        )
      }
      return components
    })()}
  </Container>
)

const Container = styled('div')(
  {
    '&::after': {
      content: '""',
      display: 'block',
      clear: 'both',
    },
  },
  (props: { gap: number }) => ({
    marginTop: -1 * props.gap,
    marginLeft: -1 * props.gap,
  })
)

const GridItem = styled('div')(
  {
    float: 'left',
  },
  (props: { column: number; gap: number; responsive: boolean }) => {
    const style = {
      paddingTop: props.gap,
      paddingLeft: props.gap,
      width: `calc(100% / ${props.column})`,
    }
    return props.responsive
      ? {
          ...style,
          [vars.media.sp]: {
            width: '100%',
            float: 'unset',
          },
        }
      : style
  }
)

export default Grid
