import * as React from 'react'

import DropdownMenu, { DropdownMenuItem } from '~/components/atoms/DropdownMenu'
import MoreActionsButton from '~/components/atoms/MoreActionsButton'
import OutsideClickTrap from '~/components/utils/OutsideClickTrap'
import * as vars from '~/styles/variables'

const MoreActionsButtonWithDropdownMenu: React.FC<{
  handleMenuItemClick: (item: DropdownMenuItem) => void
  items: DropdownMenuItem[]
  horizontalAlign?: 'left' | 'right' // default is 'right'
}> = (props) => {
  const { handleMenuItemClick } = props
  const buttonRef = React.useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const positionStyles = React.useMemo(() => {
    if (props.horizontalAlign === 'left') {
      return { right: 0, bottom: 0 }
    } else {
      return { left: 0, bottom: 0 }
    }
  }, [props.horizontalAlign])

  const handleItemClick = React.useCallback(
    (item) => {
      setIsOpen(false)
      handleMenuItemClick(item)
    },
    [handleMenuItemClick]
  )

  return (
    <div style={{ position: 'relative' }}>
      <OutsideClickTrap handleOutsideClick={() => setIsOpen(false)}>
        <MoreActionsButton
          ref={buttonRef}
          active={isOpen}
          handleClick={() => setIsOpen((it) => !it)}
        />
        <div
          style={{
            position: 'absolute',
            zIndex: vars.zIndex.dropdown,
            transform: 'translateY(100%)',
            ...positionStyles,
          }}
        >
          <DropdownMenu
            isOpen={isOpen}
            handleItemClick={handleItemClick}
            items={props.items}
          />
        </div>
      </OutsideClickTrap>
    </div>
  )
}

export default MoreActionsButtonWithDropdownMenu
