import * as React from 'react'

import Tooltip from '~/components/utils/Tooltip'

export const NotFoundObjectTooltip: React.FC = (props) => {
  return (
    <Tooltip
      delay={0}
      renderContent={() => 'このオブジェクトは使用できません。'}
    >
      {props.children}
    </Tooltip>
  )
}

export const TypeMismatchTooltip: React.FC<{ disabled?: boolean }> = (
  props
) => (
  <Tooltip
    delay={0}
    enabled={!props.disabled}
    renderContent={() => '入力フィールドと型が一致しないため使用できません'}
  >
    {props.children}
  </Tooltip>
)
