import { combineReducers } from 'redux'

import * as actionSelector from '~/ducks/ui/actionSelector'
import * as editor from '~/ducks/ui/editor'
import * as messageViewer from '~/ducks/ui/messageViewer'
import * as sidebar from '~/ducks/ui/sidebar'
import * as tip from '~/ducks/ui/tip'
import * as triggerSelector from '~/ducks/ui/triggerSelector'

export interface UiState {
  tip: tip.State
  sidebar: sidebar.State
  actionSelector: actionSelector.State
  triggerSelector: triggerSelector.State
  messageViewer: messageViewer.State
  editor: editor.State
}

export default combineReducers<UiState>({
  tip: tip.reducer,
  sidebar: sidebar.reducer,
  actionSelector: actionSelector.reducer,
  triggerSelector: triggerSelector.reducer,
  messageViewer: messageViewer.reducer,
  editor: editor.reducer,
})
