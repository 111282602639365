import * as React from 'react'

import { RequiredMark } from '~/components/atoms/RequiredMark'
import Text from '~/components/atoms/Text'
import { StaticFieldDefinition } from '~/domain/workflow/field/FieldDefinition'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { useMarkedLink } from '~/hooks/useMarkedLink'
import { DependentFields } from '~/presentation/workflow/detail/editor/form/field/DependentField'
import InputWidget from '~/presentation/workflow/detail/editor/form/inputWidget/InputWidget'
import * as vars from '~/styles/variables'

interface Props {
  definition: StaticFieldDefinition
  dependentFields: DependentFields
  value: InputValue
  bulkMode: boolean
  readonly: boolean
  onChange: (value?: InputValue) => void
  onBulkSizeChange: (newBulkSize?: 'n' | number) => void
}

const StaticField: React.FC<Props> = (props) => {
  const { onChange } = props
  const linkedNote = useMarkedLink(props.definition.note)

  const handleDependentFieldsChanged = React.useCallback(() => {
    onChange(undefined)
  }, [onChange])

  // 依存値が変更されたら値をクリアする
  useDependentFieldsChangeHandler(
    props.dependentFields,
    handleDependentFieldsChanged
  )

  // ひとつでも未入力の必須な依存フィールドがあればフィールドを描画しない
  if (!props.dependentFields.isFilled()) {
    return null
  }

  return (
    <>
      <Text
        element="p"
        fontSize="s"
        fontWeight={props.definition.required ? 'bold' : 'normal'}
        lineHeight="heading"
        style={{ marginBottom: vars.space.s, paddingRight: 80 }}
      >
        {props.definition.label}
        {props.definition.required ? <RequiredMark /> : null}
      </Text>
      {/* <div style={{ fontSize: 12, color: 'gray' }}>
        <p>definition: {JSON.stringify(props.definition.form)}</p>
        <p>valueType: {JSON.stringify(props.definition.valueType)}</p>
        <p>value: {JSON.stringify(props.value)}</p>
      </div> */}
      <InputWidget
        definition={props.definition.form}
        valueType={props.definition.valueType}
        bulkMode={props.bulkMode}
        value={props.value}
        required={props.definition.required}
        renderable={props.definition.renderable}
        bulkable={props.definition.bulkable}
        readonly={props.readonly}
        dependentFields={props.dependentFields}
        onChange={props.onChange}
        onBulkSizeChange={props.onBulkSizeChange}
      />
      <Text
        element="p"
        color="tertiary"
        fontSize="s"
        lineHeight="body"
        style={{ marginTop: vars.space.s }}
        dangerouslySetInnerHTML={{ __html: linkedNote }}
      />
    </>
  )
}

function useDependentFieldsChangeHandler(
  dependentFields: DependentFields,
  handler: () => void
): void {
  const cacheRef = React.useRef<DependentFields>()

  React.useEffect(() => {
    const cache = cacheRef.current
    // 初回は記録して終了
    if (cache === undefined) {
      cacheRef.current = dependentFields
      return
    }
    // 2回目以降は比較し、変更があれば handler を実行
    if (!dependentFields.equals(cache)) {
      handler()
    }
    cacheRef.current = dependentFields
  }, [dependentFields, handler])
}

export default StaticField
