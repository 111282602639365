import styled from '@emotion/styled'
import * as React from 'react'
import { useHistory } from 'react-router'

import { assertNever } from '~/common/utils'
import { AuthPageLayout } from '~/presentation/auth/AuthPageLayout'
import { GoogleSignInButton } from '~/presentation/auth/GoogleSignInButton'
import { SignInForm } from '~/presentation/auth/SignInForm'
import { useGoogleSignIn } from '~/presentation/auth/useGoogleSignIn'
import * as routes from '~/routes'
import * as vars from '~/styles/variables'

export const SignInPage: React.FC = () => {
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState<string>()
  const { signIn } = useGoogleSignIn()
  const history = useHistory()

  const handleClick = React.useCallback(async () => {
    setLoading(true)
    setErrorMessage(undefined)
    try {
      const userState = await signIn()
      if (userState === 'auth-as-normal-user') {
        history.push(routes.WORKFLOW_LIST)
        return
      }
      if (userState === 'not-authenticated') {
        setErrorMessage('認証に失敗しました')
        return
      }
      assertNever(userState)
    } catch (error) {
      console.error(error)
      setErrorMessage(String(error))
    } finally {
      setLoading(false)
    }
  }, [history, signIn])

  const handleFormSubmit = React.useCallback(async () => {
    history.push(routes.WORKFLOW_LIST)
  }, [history])

  return (
    <AuthPageLayout>
      <Typings>Anyflowにサインインする</Typings>
      <GoogleSignInButton
        label={'Googleアカウントでサインイン'}
        loading={loading}
        onClick={handleClick}
      />
      {errorMessage !== undefined && <Error>{errorMessage}</Error>}
      <Divider />
      <SignInForm onSubmit={handleFormSubmit} />
    </AuthPageLayout>
  )
}

const Typings = styled('h5')({
  fontSize: vars.fontSize.xl,
  marginTop: vars.space.l,
  marginBottom: vars.space.l,
})

const Error = styled('div')({
  marginTop: vars.space.m,
  color: vars.color.error,
  fontSize: vars.fontSize.s,
})

const Divider = styled('hr')({
  marginTop: vars.space.l,
  backgroundColor: vars.color.gray,
  width: '100%',
  height: '1px',
  border: 0,
  textAlign: 'center',
  '&::after': {
    content: '"または"',
    position: 'relative',
    top: '-0.5em',
    color: vars.color.gray,
    paddingRight: vars.space.m,
    paddingLeft: vars.space.m,
    backgroundColor: vars.color.white,
  },
})
