import styled from '@emotion/styled'
import React from 'react'

import { RequiredMark } from '~/components/atoms/RequiredMark'
import Text from '~/components/atoms/Text'
import { FunctionCardProps } from '~/components/molecules/HoverCard/types'
import WithRightTypeSign from '~/components/molecules/WithTypeSign/WithRightTypeSign'
import {
  ArgumentDefinition,
  argumentPositionComparator,
} from '~/domain/workflow/function/ArgumentDefinition'
import { scrollbarStyle } from '~/styles/scrollbar'
import * as vars from '~/styles/variables'

const FunctionHoverCard: React.FC<FunctionCardProps> = (props) => {
  return (
    <Container>
      <Section>
        <WithRightTypeSign valueType={props.function.returnType}>
          <Text
            element="p"
            fontSize="xs"
            fontWeight="bold"
            lineHeight="heading"
          >
            {props.function.name}
            {' ( '}
            {props.function.arguments
              .sort(argumentPositionComparator)
              .map((it) => it.name)
              .join(', ')}
            {' )'}
          </Text>
        </WithRightTypeSign>
        {props.function.description !== '' && (
          <Text element="p" fontSize="xs" style={{ marginTop: vars.space.m }}>
            {props.function.description}
          </Text>
        )}
      </Section>
      <Section>
        {props.function.arguments
          .sort(argumentPositionComparator)
          .map((argument) => (
            <ArgumentItem argument={argument} key={argument.key} />
          ))}
      </Section>
    </Container>
  )
}

const ArgumentItem: React.FC<{ argument: ArgumentDefinition }> = (props) => {
  return (
    <ArgumentContainer>
      <WithRightTypeSign valueType={props.argument.type}>
        <Text
          element="span"
          fontSize="xs"
          fontWeight="bold"
          lineHeight="heading"
        >
          {props.argument.name}
        </Text>
        {props.argument.required && <RequiredMark />}
        {props.argument.isVariableLength && (
          <Text element="span" color="secondary" fontSize="xs">
            {' '}
            [複数入力可]
          </Text>
        )}
      </WithRightTypeSign>
      <Text element="p" fontSize="xs" style={{ marginTop: vars.space.s }}>
        {props.argument.description}
      </Text>
    </ArgumentContainer>
  )
}

const Container = styled('div')({
  backgroundColor: vars.color.white,
  border: `1px solid ${vars.color.border}`,
  borderRadius: vars.borderRadius.m,
  boxShadow: vars.shadow.m,
  maxWidth: 400,
  minWidth: 300,
  maxHeight: 300,
  overflowY: 'scroll',
  ...scrollbarStyle,
})

const Section = styled('div')({
  width: '100%',
  padding: vars.space.m,
  borderTop: `1px solid ${vars.color.border}`,
  '&:first-of-type': {
    borderTop: 'none',
  },
})

const ArgumentContainer = styled('div')({
  marginTop: vars.space.m,
  '&:first-of-type': {
    marginTop: 0,
  },
})

export default FunctionHoverCard
