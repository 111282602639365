import * as _ from 'lodash'

import { Definitions } from '~/domain/Definitions'
import { ExpectedObjects } from '~/domain/workflow/expectedObject/ExpectedObjects'
import {
  RenderableElement,
  RenderableEntity,
} from '~/domain/workflow/source/RenderableElement'
import { ZERO_WIDTH_SPACE } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/prosemirror/EditorViewWrapper'
import {
  EntityNode,
  ParagraphContentNode,
  ParagraphNode,
  RootNode,
  TextNode,
  isEntityNode,
} from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/prosemirror/schema'

/**
 * RenderableElement の配列から ProseMirror の Node を生成
 */
export function convertRenderableElementsToProseMirrorNode(
  elements: RenderableElement[],
  definitions: Definitions,
  expectedObjects: ExpectedObjects
): RootNode {
  const nestedElements = elements.reduce<RenderableElement[][]>(
    (acc, element) => {
      if (_.isString(element)) {
        element.split('\n').forEach((stringElement, index) => {
          if (index === 0) {
            acc[acc.length - 1].push(stringElement)
          } else {
            // 2個目の改行から配列を追加
            acc.push([])
            acc[acc.length - 1].push(stringElement)
          }
        })
      } else {
        acc[acc.length - 1].push(element)
      }
      return acc
    },
    [[]]
  )

  const paragraphNodes = nestedElements
    .map((nestedElement) => {
      return createParagraphContent(nestedElement, definitions, expectedObjects)
    })
    .map((paragraphContents) => {
      return createParagraphNode(paragraphContents)
    })

  return {
    type: 'doc',
    content: paragraphNodes,
  }
}

function createParagraphContent(
  elements: RenderableElement[],
  definitions: Definitions,
  expectedObjects: ExpectedObjects
): Array<ParagraphContentNode> {
  return elements.reduce<Array<ParagraphContentNode>>(
    (accumulator, element) => {
      if (_.isString(element)) {
        // empty text node not allowed
        if (element !== '') {
          const textNode = createTextNode(element)
          accumulator.push(textNode)
        }
      } else {
        const textNode = createTextNode(ZERO_WIDTH_SPACE)
        accumulator.push(textNode)

        const label = RenderableElement.toLabel(
          element,
          definitions,
          expectedObjects
        )
        const variableNode = createVariableNode(label, element)
        accumulator.push(variableNode)
      }
      return accumulator
    },
    []
  )
}

function createParagraphNode(
  nodes: Array<ParagraphContentNode>
): ParagraphNode {
  return {
    type: 'paragraph',
    content: nodes,
  }
}

function createTextNode(text: string): TextNode {
  return {
    type: 'text',
    text,
  }
}

function createVariableNode(
  label: string,
  entity: RenderableEntity
): EntityNode {
  return {
    type: 'entity',
    attrs: {
      label,
      entity,
    },
  }
}

export function convertProseMirrorNodeToRenderableElements(
  rootNode: RootNode
): RenderableElement[][] {
  const elements: RenderableElement[][] = []
  const docContent = rootNode.content

  docContent.forEach((paragraph) => {
    const result: RenderableElement[] = []
    if (paragraph.content === undefined) {
      // 改行のみの場合、content は undefined (text node は含まれない)
    } else {
      paragraph.content.forEach((node) => {
        if (isEntityNode(node)) {
          result.push(node.attrs.entity)
        } else {
          const text = node.text
          if (text !== '') {
            // 空文字であれば追加しない
            result.push(text.replace(zeroWidthSpacePattern, ''))
          }
        }
      })
    }
    elements.push(result)
  })
  return elements
}

const zeroWidthSpacePattern = new RegExp(ZERO_WIDTH_SPACE, 'g')
