import * as _ from 'lodash'

import * as api from '~/api'
import * as utils from '~/common/utils'
import {
  InputSourceJson,
  InputValueJson,
  RawInputValueJsonTypes,
  RawStructEntryJson,
  TaskSourceJson,
  TriggerSourceJson,
  WorkflowSourceBodyJson,
} from '~/data/workflow/source/json'
import { parseJinjaTemplate } from '~/data/workflow/source/parseJinjaTemplate'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { RenderableElement } from '~/domain/workflow/source/RenderableElement'
import {
  InputSource,
  TaskSource,
  TriggerSource,
  WorkflowSourceBody,
} from '~/domain/workflow/source/WorkflowSourceBody'

export async function mapJsonToWorkflowSourceBody(
  json: WorkflowSourceBodyJson
): Promise<WorkflowSourceBody> {
  return new WorkflowSourceBody(
    json.trigger ? await mapToTriggerSource(json.trigger) : undefined,
    json.task ? await mapToTaskSource(json.task) : undefined
  )
}

function isInputValueListJson(
  json: RawInputValueJsonTypes
): json is InputValueJson[] {
  if (typeof json !== 'object') {
    return false
  }
  return _.every(json, isInputValueJson)
}

function isInputValueJson(
  json: InputValueJson | RawStructEntryJson
): json is InputValueJson {
  return !isStructEntryJson(json)
}

function isStructEntryListJson(
  json: RawInputValueJsonTypes
): json is RawStructEntryJson[] {
  if (typeof json !== 'object') {
    return false
  }
  return _.every(json, isStructEntryJson)
}

function isStructEntryJson(
  json: InputValueJson | RawStructEntryJson
): json is RawStructEntryJson {
  return (
    json !== null && json.hasOwnProperty('key') && json.hasOwnProperty('value')
  )
}

async function mapTemplateToRenderableElement(
  template: string
): Promise<RenderableElement[]> {
  const res = await api.parseJinja(template)
  return parseJinjaTemplate(res.ast)
}

async function mapToRawStructEntry(
  json: RawStructEntryJson
): Promise<InputValue.Raw.StructEntry> {
  return {
    key: json.key,
    value: await mapToInputValue(json.value),
  }
}

async function mapToInputValue(json: InputValueJson): Promise<InputValue> {
  if (json === null) {
    return undefined
  }
  switch (json.mode) {
    case 'raw': {
      return {
        mode: 'raw',
        raw: await (async () => {
          if (
            typeof json.raw === 'string' ||
            typeof json.raw === 'number' ||
            typeof json.raw === 'boolean'
          ) {
            return json.raw
          }
          if (isInputValueListJson(json.raw)) {
            return Promise.all(json.raw.map(mapToInputValue))
          }
          if (isStructEntryListJson(json.raw)) {
            return Promise.all(json.raw.map(mapToRawStructEntry))
          }
          utils.assertNever(json.raw)
        })(),
      }
    }
    case 'render': {
      try {
        const template: RenderableElement[] = await mapTemplateToRenderableElement(
          json.template
        )
        return {
          mode: 'render',
          template,
        }
      } catch {
        // Jinja の展開に失敗した場合は raw ということにしておく
        return {
          mode: 'raw',
          raw: json.template,
        }
      }
    }
    default: {
      return utils.assertNever(json)
    }
  }
}

async function mapToInputSource(json: InputSourceJson): Promise<InputSource> {
  return {
    fieldKey: json.key,
    value: await mapToInputValue(json.value),
  }
}

async function mapToTriggerSource(
  json: TriggerSourceJson
): Promise<TriggerSource> {
  return {
    triggerId: json.triggerId,
    inputs: await Promise.all(
      json.inputs.map(async (it) => mapToInputSource(it))
    ),
  }
}

async function mapToTaskSource(json: TaskSourceJson): Promise<TaskSource> {
  return {
    taskId: json.taskId,
    actionId: json.actionId,
    inputs: await Promise.all(
      json.inputs.map(async (it) => mapToInputSource(it))
    ),
    tasks: await Promise.all(json.tasks.map(async (it) => mapToTaskSource(it))),
  }
}
