import * as React from 'react'

import Text from '~/components/atoms/Text'
import * as vars from '~/styles/variables'

interface Props {
  children: React.ReactNode
  fontWeight: 'normal' | 'bold'
  className?: string
}

const FieldLabel: React.FC<Props> = ({ children, fontWeight, className }) => {
  return (
    <Text
      className={className}
      element="p"
      fontSize="s"
      lineHeight="heading"
      fontWeight={fontWeight}
      style={{ marginBottom: vars.space.s }}
    >
      {children}
    </Text>
  )
}

export default FieldLabel
