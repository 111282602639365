import React from 'react'

import { Result } from '~/common/Result'
import { apiClients } from '~/common/apiClients'
import { Invitation } from '~/domain/user/Invitation'

const service = apiClients.invitationService

export function useInvitationList(): {
  result: Result<Invitation[]>
  refreshInvitations: () => Promise<void>
} {
  const [result, setResult] = React.useState<Result<Invitation[]>>(
    new Result.Loading()
  )
  React.useEffect(() => {
    let stale = false
    setResult(new Result.Loading())
    service
      .getInvitations()
      .then((res) => {
        if (stale) {
          return
        }
        setResult(new Result.Success(res))
      })
      .catch((e) => {
        if (stale) {
          return
        }
        console.error(e)
        setResult(new Result.Failure(e))
      })
    return () => {
      stale = true
    }
  }, [])

  const refreshInvitations: () => Promise<
    void
  > = React.useCallback(async () => {
    setResult(new Result.Loading())
    try {
      const invitations = await service.getInvitations()
      setResult(new Result.Success(invitations))
    } catch (error) {
      console.error(error)
      setResult(new Result.Failure(error))
    }
  }, [])

  return { result, refreshInvitations }
}
