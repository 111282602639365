import * as React from 'react'

import ErrorMessage from '~/components/atoms/ErrorMessage'
import SelectField, {
  Option as SelectFieldOption,
} from '~/components/molecules/SelectField'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { InputWidgetDefinition } from '~/domain/workflow/widget/WidgetDefinition'
import { SelectWidgetDefinition } from '~/domain/workflow/widget/select'
import { RawInputWidgetProps } from '~/presentation/workflow/detail/editor/form/inputWidget/RawInputWidget'
import { useInputWidgetValidation } from '~/presentation/workflow/detail/editor/form/validation/useValidation'
import * as vars from '~/styles/variables'

const UNSELECTED = ''

interface Props extends RawInputWidgetProps {
  definition: SelectWidgetDefinition
}

const SelectWidget: React.FC<Props> = (props) => {
  const valueMap = React.useMemo<
    Map<string, InputWidgetDefinition.Option>
  >(() => {
    const map = new Map()
    props.definition.options.forEach((option) => {
      const rawInputValue = InputValue.createRawInputValue(option.value)
      map.set(JSON.stringify(rawInputValue), option)
    })
    return map
  }, [props.definition])

  // バリデーション
  const validationResult = useInputWidgetValidation(props)

  const options: SelectFieldOption<string>[] = React.useMemo(() => {
    return props.definition.options.map((option) => {
      const rawInputValue = InputValue.createRawInputValue(option.value)
      const json = JSON.stringify(rawInputValue)
      return {
        value: json,
        label: option.label,
      }
    })
  }, [props.definition])

  const selectedValue = React.useMemo(() => {
    if (props.value === undefined) {
      return UNSELECTED
    }
    const valueJson = JSON.stringify(props.value)
    if (valueMap.has(valueJson)) {
      return valueJson
    }
    // 定義に存在しない値の場合は未選択
    return UNSELECTED
  }, [valueMap, props.value])

  const handleSelect = (valueJson: string) => {
    // 「選択してください」の場合未選択
    if (valueJson === UNSELECTED) {
      props.onChange(undefined)
    }
    const option = valueMap.get(valueJson)
    // 定義に存在しない値の場合は未選択
    if (option === undefined) {
      props.onChange(undefined)
      return
    }
    props.onChange(InputValue.createRawInputValue(option.value))
  }

  return (
    <>
      <SelectField
        options={options}
        unselectedOption={{
          value: UNSELECTED,
          label: '選択してください',
        }}
        value={selectedValue}
        onChange={(value) => handleSelect(value)}
        hasError={validationResult?.valid === false}
        disabled={props.readonly}
      />
      {validationResult?.valid === false && (
        <ErrorMessage style={{ marginTop: vars.space.s }}>
          {validationResult.cause.message}
        </ErrorMessage>
      )}
    </>
  )
}

export default SelectWidget
