import { Tson } from '~/data/Tson'
import { ConnectionJson } from '~/data/connection/ConnectionJson'
import { mapToUserJson } from '~/data/user/mapToUserJson'

export function mapToConnectionJson(tson: Tson): ConnectionJson {
  return {
    id: tson.getNumber('id'),
    provider: tson.getString('provider'),
    uid: tson.getString('uid'),
    label: tson.getString('label'),
    user: mapToUserJson(tson.getValue('user')),
    appId: tson.getString('appId'),
  }
}
