import styled from '@emotion/styled'
import * as React from 'react'
import { animated, useSpring } from 'react-spring'

import Loader from '~/components/atoms/Loader'
import Text from '~/components/atoms/Text'
import * as vars from '~/styles/variables'

interface Props {
  /** エディタがローディング状態にあるか */
  isEditorLoading: boolean
  isEditingMode: boolean
}

const EditorFooter: React.FC<Props> = ({ isEditorLoading, isEditingMode }) => {
  const footerProps = useSpring({
    to: async (next: any) => {
      if (isEditingMode) {
        await next({ display: 'block' })
        await next({
          opacity: 1,
          transform: 'translateX(0)',
        })
      } else {
        await next({
          opacity: 0,
          transform: 'translateX(20px)',
        })
        await next({ display: 'none' })
      }
    },
    from: {
      display: 'none',
      opacity: 0,
      transform: 'translateX(20px)',
    },
  })

  return (
    <Container>
      <animated.div style={footerProps}>
        {isEditorLoading ? (
          <LoadingMessage>
            <_Loader size="xs" />
            <Text element="span" fontSize="s" color="secondary">
              保存中...
            </Text>
          </LoadingMessage>
        ) : null}
      </animated.div>
    </Container>
  )
}

const Container = styled('div')({
  position: 'fixed',
  bottom: vars.space.m,
  right: vars.space.m,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
})

const _Loader = styled(Loader)({
  marginRight: vars.space.s,
})

const LoadingMessage = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

export default EditorFooter
