import * as React from 'react'
import { Route, Switch } from 'react-router'

import { ContactForm } from '~/containers/Contact/ContactForm'
import { ThankYou } from '~/containers/Contact/ThankYou'
import { CONTACT, CONTACT_THANK_YOU } from '~/routes'

export const Contact: React.FC = () => {
  return (
    <Switch>
      <Route path={CONTACT} exact={true} component={ContactForm} />
      <Route path={CONTACT_THANK_YOU} component={ThankYou} />
    </Switch>
  )
}
