import styled from '@emotion/styled'
import * as _ from 'lodash'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { animated, useSpring } from 'react-spring'

import { FetchStatus } from '~/common/types'
import Loader from '~/components/atoms/Loader'
import MessageItem from '~/components/molecules/MessageItem'
import NoResults from '~/components/organisms/NoResults'
import Window from '~/components/organisms/Window'
import { AppDefinition } from '~/domain/workflow/app/AppDefinition'
import { WorkflowInstance } from '~/domain/workflow/instance/WorkflowInstance'
import * as messageListDuck from '~/ducks/message/list'
import * as messageViewerDuck from '~/ducks/ui/messageViewer'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import * as vars from '~/styles/variables'

interface Props {
  workflowInstance: WorkflowInstance
}

const MessageWindow: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const definitions = useDefinitions()
  const messages = useSelector(messageListDuck.selectors.getMessages)
  const fetchStatus = useSelector(messageListDuck.selectors.getFetchStatus)
  const messageViewer = useSelector(messageViewerDuck.selectors.getState)
  const [app, setApp] = React.useState<AppDefinition | null>(null)

  React.useEffect(() => {
    if (messageViewer.taskId && props.workflowInstance) {
      const task = props.workflowInstance.frozenSource.findTask(
        messageViewer.taskId
      )
      if (task === undefined) {
        return
      }
      setApp(definitions.getApp(definitions.getAction(task.actionId).appId))
    }
  }, [definitions, messageViewer, props.workflowInstance])

  const containerProps = useSpring({
    to: async (next: any) => {
      if (messageViewer.isShown) {
        await next({ display: 'block' })
        await next({ opacity: 1, transform: 'translateY(0)' })
      } else {
        await next({
          opacity: 0,
          transform: `translateY(${vars.height.messageViewer}px)`,
        })
        await next({ display: 'none' })
      }
    },
    from: {
      opacity: 0,
      display: 'none',
      transform: `translateY(${vars.height.messageViewer}px)`,
    },
  })

  const handleClose = () => dispatch(messageViewerDuck.actions.hide())

  const target = document.getElementById('portal-root')
  if (!target) {
    return null
  }
  return ReactDOM.createPortal(
    <Container style={containerProps}>
      <Window
        height={vars.height.messageViewer}
        // TODO
        color={app ? app.color : vars.color.theme}
        tabs={[
          {
            id: 'message',
            label: 'メッセージ',
          },
        ]}
        // TODO: タブが増えたら tabId でコンテンツ出し分け
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        renderContent={(tabId) => {
          return (
            <Content>
              {fetchStatus === FetchStatus.loading ? (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              ) : fetchStatus === FetchStatus.loaded && messages.length > 0 ? (
                <>
                  {_.sortBy(messages, ['updatedAt']).map((message) => (
                    <MessageItemRow key={message.messageId}>
                      <MessageItem message={message} />
                    </MessageItemRow>
                  ))}
                </>
              ) : (
                <NoResults
                  heading="メッセージがありません"
                  description="メッセージがある場合ここに表示されます"
                />
              )}
            </Content>
          )
        }}
        onClose={handleClose}
      />
    </Container>,
    target
  )
}

const Container = styled(animated.div)({
  position: 'fixed',
  left: vars.width.sidebar,
  bottom: 0,
  width: `calc(100% - ${vars.width.sidebar}px)`,
  paddingRight: vars.space.l,
  paddingBottom: vars.space.l,
  paddingLeft: vars.space.l,
})

const LoaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
})

const Content = styled('div')({
  padding: vars.space.m,
})

const MessageItemRow = styled('div')({
  marginTop: vars.space.xs,
  '&:first-of-type': {
    marginTop: 0,
  },
})

export default MessageWindow
