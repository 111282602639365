import _ from 'lodash'

import { assertNever } from '~/common/utils'
import { TestResult } from '~/domain/workflow/test/TestResult'
import { TestTarget } from '~/domain/workflow/test/TestTarget'

interface TasksTestResult {
  [taskId: string]: TestResult | undefined
}

export class WorkflowTestResult {
  readonly workflowId: string
  private readonly trigger?: TestResult
  private readonly tasks: TasksTestResult

  constructor(
    workflowId: string,
    trigger?: TestResult,
    tasks?: TasksTestResult
  ) {
    this.workflowId = workflowId
    this.trigger = trigger
    this.tasks = tasks ?? {}
  }

  findTriggerResult(): TestResult | undefined {
    return this.trigger
  }

  findTaskResult(taskId: string): TestResult | undefined {
    return this.tasks[taskId]
  }

  setTestResult(target: TestTarget, result: TestResult): WorkflowTestResult {
    switch (target.kind) {
      case 'trigger':
        return this.setTriggerResult(result)
      case 'task':
        return this.setTaskResult(target.taskId, result)
      default:
        assertNever(target)
    }
  }

  removeTriggerResult(): WorkflowTestResult {
    return new WorkflowTestResult(this.workflowId, undefined, this.tasks)
  }

  removeTaskResult(taskId: string): WorkflowTestResult {
    return new WorkflowTestResult(
      this.workflowId,
      this.trigger,
      _.omit(this.tasks, taskId)
    )
  }

  private setTriggerResult(result: TestResult): WorkflowTestResult {
    return new WorkflowTestResult(this.workflowId, result, this.tasks)
  }

  private setTaskResult(
    taskId: string,
    result: TestResult
  ): WorkflowTestResult {
    return new WorkflowTestResult(this.workflowId, this.trigger, {
      ...this.tasks,
      [taskId]: result,
    })
  }
}
