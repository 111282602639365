import * as React from 'react'
import { Search } from 'react-feather'

import TextField from '~/components/atoms/TextField'
import * as vars from '~/styles/variables'

interface Props {
  value: string
  placeholder?: string
  onChange: (value: string) => void
  className?: string
}

const SearchField: React.FC<Props> = (props) => (
  <div style={{ position: 'relative' }} className={props.className}>
    <TextField
      value={props.value}
      placeholder={props.placeholder}
      onChange={(e) => props.onChange(e.target.value)}
      style={{ width: '100%', paddingLeft: 48 }}
    />
    <Search
      color={vars.color.icon}
      size={24}
      style={{
        position: 'absolute',
        top: '50%',
        left: vars.space.m,
        transform: 'translateY(-50%)',
      }}
    />
  </div>
)

export default SearchField
