import * as React from 'react'

import { assertNever } from '~/common/utils'
import { ViewWidgetDefinition } from '~/domain/workflow/widget/WidgetDefinition'
import { DependentFields } from '~/presentation/workflow/detail/editor/form/field/DependentField'
import ConditionalTriggerInstructionWidget from '~/presentation/workflow/detail/editor/form/viewWidget/triggerInstruction/ConditionalTriggerInstructionWidget'

export interface ViewWidgetProps {
  definition: ViewWidgetDefinition
  dependentFields: DependentFields
}

const ViewWidget: React.FC<ViewWidgetProps> = (props) => {
  switch (props.definition.formType) {
    case 'trigger_instruction':
      return (
        <ConditionalTriggerInstructionWidget
          {...props}
          definition={props.definition}
        />
      )
    default:
      assertNever(props.definition.formType)
  }
}

export default ViewWidget
