import styled from '@emotion/styled'
import * as React from 'react'

import { assertNever } from '~/common/utils'
import NoResults from '~/components/organisms/NoResults'
import { FieldDefinition } from '~/domain/workflow/field/FieldDefinition'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { MapLike, useMapState } from '~/presentation/useMapState'
import { DependentFields } from '~/presentation/workflow/detail/editor/form/field/DependentField'
import MemoizedStaticField from '~/presentation/workflow/detail/editor/form/field/MemoizedStaticField'
import ViewField from '~/presentation/workflow/detail/editor/form/field/ViewField'
import * as vars from '~/styles/variables'

interface Props {
  fields: FieldDefinition[]
  bulkMode: boolean
  readonly: boolean
  inputValues: MapLike<InputValue>
  onInputChange: (fieldKey: string, newInput: InputValue) => void
  onBulkSizeError?: (errorMessage?: string) => void
}

const InputForm: React.FC<Props> = (props) => {
  const [bulkSizeMap, putBulkSize] = useMapState<number | 'n' | undefined>()

  // バルクサイズの一致確認
  React.useEffect(() => {
    const bulkSizes = Object.values(bulkSizeMap).filter(
      (size) => size !== undefined && size !== 'n'
    )
    if (
      bulkSizes.length >= 2 &&
      bulkSizes.some((size) => size !== bulkSizes[0])
    ) {
      props.onBulkSizeError?.(
        'バルクサイズの異なる変数が使用されているフィールドがあります'
      )
    } else {
      props.onBulkSizeError?.(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkSizeMap])

  // NOTE: 暫定対応
  // https://app.asana.com/0/1173380138527467/1181611504936569
  const filteredFields = props.fields.filter((field) => {
    return (
      field.key !== 'default_runner_slug' &&
      field.key !== 'is_always_default_runner'
    )
  })

  return (
    <>
      {filteredFields.length === 0 && (
        <NoResults
          heading="入力項目はありません"
          description="入力項目がある場合はここに表示されます"
        />
      )}
      {filteredFields.map<React.ReactNode>((field) => {
        const dependentFields: DependentFields = new DependentFields(
          field.dependencies.map((dependentFieldKey) => {
            const dependentField = props.fields.find(
              (it) => it.key === dependentFieldKey
            )
            if (dependentField === undefined) {
              throw new Error(
                `Can't find the dependent field key in fields of InputForm: ${dependentFieldKey}`
              )
            }
            if (dependentField.fieldType === 'info_view') {
              throw new Error(
                `Not allowed to specify "info_view" field as dependency.`
              )
            }
            return {
              fieldKey: dependentField.key,
              value: props.inputValues[dependentField.key],
              required: dependentField.required,
            }
          })
        )
        switch (field.fieldType) {
          case 'static':
            return (
              <FieldContainer key={field.key}>
                <MemoizedStaticField
                  field={field}
                  value={props.inputValues[field.key]}
                  bulkMode={props.bulkMode}
                  readonly={props.readonly}
                  dependentFields={dependentFields}
                  putInputValue={props.onInputChange}
                  putBulkSize={putBulkSize}
                />
              </FieldContainer>
            )
          case 'dynamic':
            throw new Error('DynamicField is not implemented yet.')
          case 'info_view':
            return (
              <FieldContainer key={field.key}>
                <ViewField
                  definition={field}
                  dependentFields={dependentFields}
                />
              </FieldContainer>
            )
          default:
            assertNever(field)
        }
      })}
    </>
  )
}

export default InputForm

const FieldContainer = styled('div')({
  marginTop: vars.space.l,
  '&:first-of-type': {
    marginTop: 0,
  },
})
