import _ from 'lodash'
import React from 'react'

import { Pagination, PaginationMeta } from '~/common/Pagination'
import { Result } from '~/common/Result'
import { apiClients } from '~/common/apiClients'
import { GroupService } from '~/domain/group/GroupService'
import { GroupSummary } from '~/domain/group/GroupSummary'

const service: GroupService = apiClients.groupService

export function useGroupList(
  page: number
): {
  result: Result<Pagination<GroupSummary>>
  pageMeta?: PaginationMeta
  deleteGroup: (groupId: string) => Promise<void>
  deletingGroupIds: string[]
} {
  const [result, setResult] = React.useState<Result<Pagination<GroupSummary>>>(
    new Result.Loading()
  )
  const [deletingGroupIds, setDeletingGroupIds] = React.useState<string[]>([])

  React.useEffect(() => {
    let stale = false
    setResult(new Result.Loading())
    service
      .getGroups(page)
      .then((res) => {
        if (stale) {
          return
        }
        setResult(new Result.Success(res))
      })
      .catch((e) => {
        if (stale) {
          return
        }
        console.error(e)
        setResult(new Result.Failure(e))
      })
    return () => {
      stale = true
    }
  }, [page])

  const deleteGroup = React.useCallback(async (groupId: string) => {
    setDeletingGroupIds((prev) => [...prev, groupId])
    try {
      await service.deleteGroup(groupId)

      // 今削除したグループを result からも削除する
      setResult((prev) => {
        if (prev.loading || prev.error !== undefined) {
          return prev
        }
        return new Result.Success({
          ...prev.data,
          items: _.reject(prev.data.items, (it) => it.id === groupId),
        })
      })
    } finally {
      setDeletingGroupIds((prev) => _.reject(prev, (it) => it === groupId))
    }
  }, [])

  return { result, deleteGroup, deletingGroupIds }
}
