import styled from '@emotion/styled'
import * as React from 'react'

import Button from '~/components/atoms/Button'
import TextField from '~/components/atoms/TextField'
import { useSignInWithEmail } from '~/presentation/auth/useSignInWithEmail'
import * as vars from '~/styles/variables'

interface Props {
  onSubmit: () => void
}

export const SignInForm: React.FC<Props> = (props) => {
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState<string>()
  const { signingIn, signInWithEmail } = useSignInWithEmail()

  const handleEmailChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.currentTarget.value)
    },
    []
  )

  const handlePasswordChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(event.currentTarget.value)
    },
    []
  )

  const handleSubmit = React.useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault()
      try {
        await signInWithEmail(email, password)
        props.onSubmit()
      } catch (e) {
        setErrorMessage('メールアドレスまたはパスワードが間違っています')
        console.error(e)
      }
    },
    [email, password, props, signInWithEmail]
  )

  return (
    <Form onSubmit={handleSubmit}>
      {errorMessage && <Error>{errorMessage}</Error>}
      <TextFieldContainer>
        <Icon type={'mail'} />
        <_TextField
          type="email"
          value={email}
          placeholder="メールアドレス"
          required={true}
          onChange={handleEmailChange}
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <Icon type={'lock'} />
        <_TextField
          type="password"
          value={password}
          placeholder="パスワード"
          required={true}
          onChange={handlePasswordChange}
        />
      </TextFieldContainer>
      <_Button loading={signingIn}> サインイン </_Button>
    </Form>
  )
}

const Form = styled('form')({
  marginTop: vars.space.l,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
})

const TextFieldContainer = styled('div')({
  marginTop: vars.space.m,
  position: 'relative',
  width: '100%',
  '&:first-of-type': {
    marginTop: 0,
  },
})

const _TextField = styled(TextField)({
  textIndent: vars.space.l,
})

const Icon = styled('span')(
  {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: `${vars.space.s}px 50%`,
    paddingLeft: vars.space.l * 1.5,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
  },
  (props: { type: 'mail' | 'lock' }) => {
    if (props.type === 'mail') {
      return { backgroundImage: 'url("/static/icons/mail.svg")' }
    } else {
      return { backgroundImage: 'url("/static/icons/lock.svg")' }
    }
  }
)

const Error = styled('div')({
  marginTop: vars.space.m,
  color: vars.color.error,
  fontSize: vars.fontSize.s,
})

const _Button = styled(Button)({
  marginTop: vars.space.m,
  width: '100%',
  height: 45,
  fontSize: vars.fontSize.s,
})
