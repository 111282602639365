import * as React from 'react'

import { AssertionError } from '~/common/utils'
import { Definitions } from '~/domain/Definitions'
import { Me } from '~/domain/user/Me'

interface AnyflowAppContextInterface {
  definitions: Definitions
  me: Me
  changeGroup: (newGroupId: string) => Promise<void>
  refreshMe: () => Promise<void>
}

export const AnyflowAppContext = React.createContext<
  AnyflowAppContextInterface
>({
  get definitions(): never {
    throw new AssertionError('AnyflowAppContext is not initialized properly')
  },
  get me(): never {
    throw new AssertionError('AnyflowAppContext is not initialized properly')
  },
  get changeGroup(): never {
    throw new AssertionError('AnyflowAppContext is not initialized properly')
  },
  get refreshMe(): never {
    throw new AssertionError('AnyflowAppContext is not initialized properly')
  },
})

export function useDefinitions(): Definitions {
  return useAnyflowAppContext().definitions
}

export function useMe(): Me {
  return useAnyflowAppContext().me
}

export function useAnyflowAppContext(): Required<AnyflowAppContextInterface> {
  return React.useContext<AnyflowAppContextInterface>(AnyflowAppContext)
}
