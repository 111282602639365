import styled from '@emotion/styled'
import * as React from 'react'
import ContentLoader from 'react-content-loader'

interface Props {
  className?: string
}

const titleHeight = 16
const dateHeight = 14
const dateMargin = 8

const Loader: React.FC<Props> = ({ className }) => (
  <ContentLoader
    className={className}
    speed={1}
    width={1000}
    height={titleHeight + dateHeight + dateMargin}
  >
    {/* 実行アイコン */}
    <rect x={0} y={6} rx="25" ry="25" width="30" height="30" />
    {/* タイトル */}
    <rect x={28 + 65} y="0" rx="2" ry="2" width="450" height={titleHeight} />
    {/* 日付 */}
    <rect
      x={28 + 65}
      y={titleHeight + dateMargin}
      rx="2"
      ry="2"
      width="130"
      height={dateHeight}
    />
    {/* アプリアイコン */}
    <rect x="750" y={10} rx="2" ry="2" width="25" height="25" />
    <rect x={750 + 25 + 2} y={10} rx="2" ry="2" width="25" height="25" />
    <rect x={750 + (25 + 2) * 2} y={10} rx="2" ry="2" width="25" height="25" />
    <rect x={750 + (25 + 2) * 3} y={10} rx="2" ry="2" width="25" height="25" />
  </ContentLoader>
)

const LoaderList: React.FC = () => (
  <>
    <ContentLoader speed={1} width={1000} height={14}>
      <rect x={0} y={0} rx="2" ry="2" width="25" height="14" />
      <rect x={93} y={0} rx="2" ry="2" width="80" height="14" />
    </ContentLoader>
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
  </>
)

const _Loader = styled(Loader)({
  marginTop: 18,
  '&:first-of-type': {
    marginTop: 0,
  },
})

export default LoaderList
