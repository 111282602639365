import {
  InvitationErrorJson,
  InvitationMailErrorsJson,
} from '~/data/user/invitation/InvitationErrorJson'
import { InvitationResult } from '~/domain/user/InvitationResult'

export function mapJsonToInvitationErrorResult(
  json: InvitationErrorJson
): InvitationResult {
  if (Array.isArray(json)) {
    const errors = json.map(mapJsonToEmailError)
    return {
      isSuccess: false,
      errors,
    }
  }
  const key = json.body[0]
  const wholeError = wholeErrorMessages[key] || defaultWholeErrorMessage
  return {
    isSuccess: false,
    errors: [],
    wholeError,
  }
}

function mapJsonToEmailError(
  json: InvitationMailErrorsJson
): string | undefined {
  if (json.email === undefined) {
    return undefined
  }
  const errorCode = json.email[0]
  return emailErrorMessages[errorCode] || defaultEmailErrorMessage
}

const defaultEmailErrorMessage = 'この入力はエラーです'
const emailErrorMessages: { [errorCode: string]: string | undefined } = {
  /*  eslint-disable prettier/prettier */
  'same_email_user_exists': '既にユーザーとして存在しています',
  'invalid_email_address': '正しいメールアドレスを入力してください',
  'duplication_error': '既に招待済みのユーザーです',
  /*  eslint-enable prettier/prettier */
}
const defaultWholeErrorMessage = '招待メールの送信に失敗しました'
const wholeErrorMessages: { [errorCode: string]: string | undefined } = {
  /*  eslint-disable prettier/prettier */
  'over_bulk_request_limit': '一度に送信できる招待は20件までです'
  /*  eslint-enable prettier/prettier */
}
