import _ from 'lodash'
import React from 'react'

import { Result } from '~/common/Result'
import { apiClients } from '~/common/apiClients'
import { Group } from '~/domain/group/Group'
import { GroupService } from '~/domain/group/GroupService'

const service: GroupService = apiClients.groupService

export function useGroup(
  groupId: string
): {
  result: Result<Group | undefined>
  nameUpdating: boolean
  addingUserIds: string[]
  removingUserIds: string[]
  updateName: (newName: string) => Promise<void>
  addMember: (userId: string) => Promise<void>
  removeMember: (userId: string) => Promise<void>
} {
  const [result, setResult] = React.useState<Result<Group | undefined>>(
    new Result.Loading()
  )
  const [nameUpdating, setNameUpdating] = React.useState<boolean>(false)
  const [removingUserIds, setRemovingUserIds] = React.useState<string[]>([])
  const [addingUserIds, setAddingUserIds] = React.useState<string[]>([])

  React.useEffect(() => {
    let stale = false
    setResult(new Result.Loading())
    service
      .getGroup(groupId)
      .then((res) => {
        if (stale) {
          return
        }
        setResult(new Result.Success(res))
      })
      .catch((e) => {
        if (stale) {
          return
        }
        console.error(e)
        setResult(new Result.Failure(e))
      })
    return () => {
      stale = true
    }
  }, [groupId])

  const updateName = React.useCallback(
    async (newName: string) => {
      try {
        setNameUpdating(true)
        await service.updateGroupName(groupId, newName)

        // 見た目を合わせるために result も変更する
        setResult((prev) => {
          if (prev.loading || prev.error) {
            return prev
          }
          return new Result.Success(prev.data?.updateName(newName))
        })
      } finally {
        setNameUpdating(false)
      }
    },
    [groupId]
  )

  const addMember = React.useCallback(
    async (userId: string) => {
      try {
        setAddingUserIds((prev) => [...prev, userId])
        const addedUser = await service.addUser(groupId, userId)

        // 見た目を合わせるために result にも追加する
        setResult((prev) => {
          if (prev.loading || prev.error) {
            return prev
          }
          return new Result.Success(prev.data?.addMember(addedUser))
        })
      } finally {
        setAddingUserIds((prev) => _.reject(prev, (it) => it === userId))
      }
    },
    [groupId]
  )

  const removeMember = React.useCallback(
    async (userId: string) => {
      try {
        setRemovingUserIds((prev) => [...prev, userId])
        await service.removeUser(groupId, userId)

        // 見た目を合わせるために result からも削除する
        setResult((prev) => {
          if (prev.loading || prev.error) {
            return prev
          }
          return new Result.Success(prev.data?.removeMember(userId))
        })
      } finally {
        setRemovingUserIds((prev) => _.reject(prev, (it) => it === userId))
      }
    },
    [groupId]
  )

  return {
    result,
    nameUpdating,
    removingUserIds,
    addingUserIds,
    updateName,
    addMember,
    removeMember,
  }
}
