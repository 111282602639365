import { delay } from '~/common/utils'
import { devTrigger } from '~/data/workflow/trigger/TriggerServiceImpl'
import { TriggerDefinition } from '~/domain/workflow/trigger/TriggerDefinition'
import { TriggerService } from '~/domain/workflow/trigger/TriggerService'

export class MockTriggerService implements TriggerService {
  async get(triggerId: string): Promise<TriggerDefinition | undefined> {
    await delay(1500)
    return triggers.find((trigger) => trigger.triggerId === triggerId)
  }

  async getAll(): Promise<TriggerDefinition[]> {
    await delay(1000)
    return triggers
  }
}

const standardTrigger: TriggerDefinition = {
  triggerId: 'GenericApp__StandardTrigger',
  name: 'ボタンを押して実行',
  description: '手動で実行ボタンを押した際に実行します',
  appId: 'GenericApp',
  testable: true,
  fields: [
    {
      fieldType: 'static',
      key: 'default_runner_slug',
      label: 'デフォルトの実行者',
      note: '実行者が組織に存在しない場合に使用するユーザー',
      required: true,
      bulkable: false,
      renderable: false,
      dependencies: [],
      valueType: {
        typeName: 'string',
        nullable: false,
      },
      form: {
        formType: 'text',
      },
    },
    {
      fieldType: 'static',
      key: 'is_always_default_runner',
      label: '常にデフォルトの実行者で指定する',
      note: '',
      required: false,
      bulkable: false,
      renderable: false,
      dependencies: [],
      valueType: {
        typeName: 'boolean',
        nullable: false,
      },
      form: {
        formType: 'switch',
        label: 'はい',
      },
    },
  ],
}

const slackButtonTrigger: TriggerDefinition = {
  triggerId: 'SlackApp__SlackButtonTrigger',
  name: 'ボタンが押されたときに実行',
  description: 'Slack のメッセージ中のボタンが押されたときに実行します',
  appId: 'SlackApp',
  testable: true,
  fields: [
    {
      fieldType: 'static',
      key: 'default_runner_slug',
      label: 'デフォルトの実行者',
      note: '実行者が組織に存在しない場合に使用するユーザー',
      required: true,
      renderable: false,
      bulkable: false,
      dependencies: [],
      valueType: {
        typeName: 'string',
        nullable: false,
      },
      form: {
        formType: 'text',
      },
    },
    {
      fieldType: 'static',
      key: 'is_always_default_runner',
      label: '常にデフォルトの実行者で指定する',
      note: '',
      required: true,
      renderable: false,
      bulkable: false,
      dependencies: [],
      valueType: {
        typeName: 'boolean',
        nullable: false,
      },
      form: {
        formType: 'switch',
        label: 'はい',
      },
    },
    {
      fieldType: 'static',
      key: 'required_generic_object_names',
      label: '必須な汎用オブジェクト名一覧',
      note: 'ワークフロー実行時に必要な汎用オブジェクト名',
      required: true,
      renderable: false,
      bulkable: false,
      dependencies: [],
      valueType: {
        typeName: 'list',
        nullable: false,
        itemType: {
          typeName: 'string',
          nullable: false,
        },
      },
      form: {
        formType: 'list',
        itemForm: {
          formType: 'text',
        },
      },
    },
  ],
}

const scheduleTrigger: TriggerDefinition = {
  appId: 'GenericApp',
  description: '',
  fields: [],
  name: '',
  triggerId: 'GenericApp__ScheduleTrigger',
  testable: true,
}

const salesforceCreateRecordTrigger: TriggerDefinition = {
  appId: 'SalesforceApp',
  description: '',
  fields: [],
  name: '',
  triggerId: 'SalesforceApp__CreateRecordTrigger',
  testable: true,
}

const triggers = [
  devTrigger,
  standardTrigger,
  slackButtonTrigger,
  scheduleTrigger,
  salesforceCreateRecordTrigger,
]
