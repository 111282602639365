import { Tson } from '~/data/Tson'
import {
  GroupJson,
  GroupMetaJson,
  GroupSummaryJson,
} from '~/data/group/GroupJson'
import { mapToUserJson } from '~/data/user/mapToUserJson'

export function mapToGroupJson(tson: Tson): GroupJson {
  return {
    slug: tson.getString('slug'),
    name: tson.getString('name'),
    updatedAt: tson.getString('updatedAt'),
    memberCount: tson.getNumber('memberCount'),
    workflowCount: tson.getNumber('workflowCount'),
    members: tson.getArray('members').map(mapToUserJson),
  }
}

export function mapToGroupSummaryJson(tson: Tson): GroupSummaryJson {
  return {
    slug: tson.getString('slug'),
    name: tson.getString('name'),
    memberCount: tson.getNumber('memberCount'),
    workflowCount: tson.getNumber('workflowCount'),
    updatedAt: tson.getString('updatedAt'),
  }
}

export function mapToGroupMetaJson(tson: Tson): GroupMetaJson {
  return {
    slug: tson.getString('slug'),
    name: tson.getString('name'),
    updatedAt: tson.getString('updatedAt'),
  }
}
