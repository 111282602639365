import styled from '@emotion/styled'
import * as React from 'react'
import { animated, useSpring } from 'react-spring'

import Link from '~/components/atoms/Link'
import * as vars from '~/styles/variables'

interface Props {
  to: string
  label: string
  selected: boolean
  collapsed?: boolean
  className?: string
  Icon: React.ComponentType<{
    color?: string
    size?: string | number
  }>
}

const SidebarItem: React.FC<Props> = ({
  selected,
  to,
  label,
  collapsed,
  className,
  Icon,
}) => {
  const labelProps = useSpring({
    // FIXME
    display: collapsed ? 'none' : 'block',
    transform: collapsed ? 'translateX(10px)' : 'translateX(0)',
    opacity: collapsed ? 0 : 1,
  })
  return (
    <Container className={className} selected={selected}>
      <_Link to={to} collapsed={collapsed}>
        <IconContainer>
          <Icon
            size={20}
            color={selected ? vars.fontColor.primary : vars.fontColor.secondary}
          />
        </IconContainer>
        <Label selected={selected} style={labelProps}>
          {label}
        </Label>
      </_Link>
    </Container>
  )
}

const Container = styled('div')(
  {
    position: 'relative',
    borderRadius: vars.borderRadius.m,
    transition: 'all .14s ease',
    '&:hover': {
      backgroundColor: vars.color.lightGray,
    },
  },
  (props: { selected: boolean }) => ({
    backgroundColor: props.selected ? vars.color.lightGray : vars.color.white,
  })
)

const IconContainer = styled('div')({
  flex: '0 0 auto',
  width: 20,
  height: 20,
})

const _Link = styled(Link)<{ collapsed?: boolean }>({
  display: 'flex',
  alignItems: 'center',
  paddingTop: vars.space.s * 1.5,
  paddingRight: vars.space.m,
  paddingBottom: vars.space.s * 1.5,
  paddingLeft: vars.space.m,
  width: '100%',
  textDecoration: 'none',
})

const Label = styled(animated.span)(
  {
    marginLeft: vars.space.s * 1.5,
    fontSize: vars.fontSize.s,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  (props: { selected: boolean }) => ({
    fontWeight: props.selected
      ? vars.fontWeight.bold
      : vars.fontWeight.semiBold,
    color: props.selected ? vars.fontColor.primary : vars.fontColor.secondary,
  })
)

export default SidebarItem
