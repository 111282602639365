import styled from '@emotion/styled'
import * as React from 'react'
import ReactContentLoader from 'react-content-loader'
import { X } from 'react-feather'
import Helmet from 'react-helmet'
import { useHistory } from 'react-router'
import { formatRoute } from 'react-router-named-routes'

import { formatDate } from '~/common/utils'
import Button from '~/components/atoms/Button'
import { DropdownMenuItem } from '~/components/atoms/DropdownMenu'
import MyLink from '~/components/atoms/Link'
import Text from '~/components/atoms/Text'
import TextField from '~/components/atoms/TextField'
import MoreActionsButtonWithDropdownMenu from '~/components/molecules/MoreActionsButtonWithDropdownMenu'
import Pager from '~/components/molecules/Pager'
import Inner from '~/components/organisms/Inner'
import Modal from '~/components/organisms/Modal'
import NoResults from '~/components/organisms/NoResults'
import {
  Table,
  Body as Tbody,
  Cell as Td,
  HeaderCell as Th,
  Header as Thead,
  Row as Tr,
} from '~/components/organisms/Table'
import SidebarTemplate from '~/components/templates/SidebarTemplate'
import FormKeysTrap from '~/components/utils/FormKeysTrap'
import { GroupSummary } from '~/domain/group/GroupSummary'
import { useAnyflowAppContext } from '~/presentation/AnyflowAppContext'
import { useToaster } from '~/presentation/ToasterContext'
import { useCreateGroup } from '~/presentation/group/useCreateGroup'
import { useGroupList } from '~/presentation/group/useGroupList'
import { usePageQuery } from '~/presentation/utilityHooks'
import { GROUP_DETAIL, GROUP_LIST } from '~/routes'
import * as vars from '~/styles/variables'

const actions: DropdownMenuItem[] = [
  { id: 'delete', label: '削除', role: 'danger' },
]

const GroupListPage: React.FC = () => {
  const toaster = useToaster()
  const page = usePageQuery()
  const history = useHistory()
  const { creating, create } = useCreateGroup()
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState<boolean>(
    false
  )

  const handleCreateGroup = React.useCallback(
    async (groupName: string) => {
      try {
        const group = await create(groupName)
        setIsCreateModalOpen(false)
        history.push(formatRoute(GROUP_DETAIL, { groupId: group.id }))
      } catch (e) {
        console.error(e)
        toaster.showError('グループの作成に失敗しました')
      }
    },
    [create, toaster, history]
  )

  return (
    <SidebarTemplate>
      <Helmet>
        <title>グループ一覧</title>
      </Helmet>
      <_Inner>
        <Header>
          <Heading>グループ一覧</Heading>
          <Button onClick={() => setIsCreateModalOpen(true)}>
            グループを作成
          </Button>
        </Header>
        <div style={{ marginTop: vars.space.l }}>
          <Content page={page} />
          <CreateGroupModal
            isOpen={isCreateModalOpen}
            creating={creating}
            handleClose={() => setIsCreateModalOpen(false)}
            handleCreate={handleCreateGroup}
          />
        </div>
      </_Inner>
    </SidebarTemplate>
  )
}

const Content: React.FC<{ page: number }> = (props) => {
  const toaster = useToaster()
  const { refreshMe } = useAnyflowAppContext()
  const { result, deleteGroup, deletingGroupIds } = useGroupList(props.page)

  const handleDeleteGroup = React.useCallback(
    async (groupId: string) => {
      if (!confirm('グループを削除してもよろしいですか？')) {
        return
      }
      try {
        await deleteGroup(groupId)
        // 自分が所属しているグループだった場合、
        // 所属しているグループの一覧も更新したいので Me を再取得
        await refreshMe()
      } catch (e) {
        console.error(e)
        toaster.showError('グループの削除に失敗しました')
      }
    },
    [deleteGroup, toaster, refreshMe]
  )

  if (result.loading) {
    return <LoadingTable />
  }

  if (result.error !== undefined) {
    return (
      <NoResults
        heading="エラー"
        description="グループ一覧の取得に失敗しました"
      />
    )
  }

  return (
    <>
      <GroupListTable
        groups={result.data.items}
        deletingGroupIds={deletingGroupIds}
        handleDeleteGroup={handleDeleteGroup}
      />
      <div style={{ marginTop: vars.space.l }}>
        <_Pager
          to={GROUP_LIST}
          countPerPage={result.data.paginationMeta.countPerPage}
          currentPageNumber={result.data.paginationMeta.currentPageNumber}
          totalCount={result.data.paginationMeta.totalCount}
        />
      </div>
    </>
  )
}

const LoadingTable: React.FC = () => {
  return (
    <div>
      <div style={{ display: 'flex', height: 37, alignItems: 'center' }}>
        <ReactContentLoader speed={1} width={1100} height={14}>
          <rect x={0} y={0} rx="2" ry="2" width="150" height="14" />
          <rect x={400} y={0} rx="2" ry="2" width="50" height="14" />
          <rect x={500} y={0} rx="2" ry="2" width="100" height="14" />
          <rect x={650} y={0} rx="2" ry="2" width="100" height="14" />
        </ReactContentLoader>
      </div>
      {new Array(10).fill(null).map((__, i) => (
        <div
          key={i}
          style={{ display: 'flex', height: 58, alignItems: 'center' }}
        >
          <ReactContentLoader speed={1} width={1100} height={14}>
            <rect x={0} y={0} rx="2" ry="2" width="350" height="14" />
            <rect x={400} y={0} rx="2" ry="2" width="50" height="14" />
            <rect x={500} y={0} rx="2" ry="2" width="100" height="14" />
            <rect x={650} y={0} rx="2" ry="2" width="200" height="14" />
            <rect x={900} y={0} rx="2" ry="2" width="30" height="14" />
          </ReactContentLoader>
        </div>
      ))}
    </div>
  )
}

const GroupListTable: React.FC<{
  groups: GroupSummary[]
  deletingGroupIds: string[]
  handleDeleteGroup: (groupId: string) => void
}> = (props) => {
  if (props.groups.length === 0) {
    return (
      <NoResults
        heading="グループはまだありません"
        description="最初のグループを作ってみましょう"
      />
    )
  }

  return (
    <_Table>
      <Thead>
        <Tr>
          <_Th>グループ名</_Th>
          <_Th style={{ width: 100 }}>人数</_Th>
          <_Th style={{ width: 150 }}>ワークフロー数</_Th>
          <_Th style={{ width: 250 }}>更新日時</_Th>
          <_Th style={{ width: 50 }}>{null}</_Th>
        </Tr>
      </Thead>
      <Tbody>
        {props.groups.map((group) => (
          <GroupListTableRow
            key={group.id}
            group={group}
            deleting={props.deletingGroupIds.includes(group.id)}
            handleDeleteGroup={props.handleDeleteGroup}
          />
        ))}
      </Tbody>
    </_Table>
  )
}

const GroupListTableRow: React.FC<{
  group: GroupSummary
  deleting: boolean
  handleDeleteGroup: (groupId: string) => void
}> = (props) => {
  const { handleDeleteGroup } = props
  const handleMenuItemClick = React.useCallback(
    (item: DropdownMenuItem) => {
      if (item.id === 'delete') {
        handleDeleteGroup(props.group.id)
      }
      console.error(`No such item in then menu: ${item.id}`)
    },
    [handleDeleteGroup, props.group.id]
  )
  return (
    <Tr style={{ opacity: props.deleting ? 0.5 : 1 }}>
      <_Td style={{ fontSize: vars.fontSize.m, fontWeight: 'bold' }}>
        <GroupName to={formatRoute(GROUP_DETAIL, { groupId: props.group.id })}>
          {props.group.name}
        </GroupName>
      </_Td>
      <_Td>{props.group.memberCount}人</_Td>
      <_Td>{props.group.workflowCount}ワークフロー</_Td>
      <_Td style={{ color: vars.fontColor.tertiary }}>
        {formatDate(props.group.updatedAt)}
      </_Td>
      <Td style={{ color: vars.fontColor.tertiary }}>
        <MoreActionsButtonWithDropdownMenu
          handleMenuItemClick={handleMenuItemClick}
          horizontalAlign={'left'}
          items={actions}
        />
      </Td>
    </Tr>
  )
}

const CreateGroupModal: React.FC<{
  isOpen: boolean
  creating: boolean
  handleClose: () => void
  handleCreate: (groupName: string) => void
}> = (props) => {
  const { handleCreate } = props
  const [text, setText] = React.useState<string>('')
  const inputRef = React.useRef<HTMLInputElement>(null)

  // モーダルを開いた時にフォーカスを与える
  // autoFocus 属性ではアニメーションとの兼ね合いでうまくフォーカスできない
  React.useEffect(() => {
    if (!props.isOpen) return
    const timer = window.setTimeout(() => {
      if (inputRef.current !== null) {
        inputRef.current.focus()
      }
    }, 50)
    return () => {
      window.clearTimeout(timer)
    }
  }, [props.isOpen])

  // モーダルを閉じた時に text を削除する
  React.useEffect(() => {
    if (!props.isOpen) {
      setText('')
    }
  }, [props.isOpen])

  const create = React.useCallback(() => {
    if (props.creating) {
      return
    }
    if (text === '') {
      return
    }
    handleCreate(text)
  }, [props.creating, handleCreate, text])

  return (
    <Modal
      isOpened={props.isOpen}
      onClose={props.handleClose}
      lightDimmer={true}
    >
      <FormKeysTrap handleEscape={props.handleClose} handleEnter={create}>
        <div
          style={{
            position: 'relative',
            paddingTop: vars.space.l,
            paddingRight: vars.space.l * 1.5,
            paddingBottom: vars.space.xl,
            paddingLeft: vars.space.l * 1.5,
            width: 600,
          }}
        >
          <Text
            element="h1"
            fontSize="xl"
            lineHeight="heading"
            fontWeight="bold"
          >
            グループを新規作成
          </Text>
          <X
            color={vars.color.icon}
            size={22}
            onClick={props.handleClose}
            style={{
              position: 'absolute',
              top: vars.space.m * 1.5,
              right: vars.space.m * 1.5,
              cursor: 'pointer',
            }}
          />
          <div style={{ marginTop: vars.space.xl }}>
            <TextField
              ref={inputRef}
              value={text}
              autoFocus={true}
              placeholder="グループ名を入力してください"
              onChange={(e) => setText(e.target.value)}
            />
          </div>
          <div style={{ marginTop: vars.space.s * 1.5, textAlign: 'right' }}>
            <Button
              type="secondary"
              onClick={props.handleClose}
              style={{ marginRight: vars.space.s }}
            >
              閉じる
            </Button>
            <Button
              type="primary"
              onClick={create}
              loading={props.creating}
              disabled={text === ''}
            >
              グループを作成
            </Button>
          </div>
        </div>
      </FormKeysTrap>
    </Modal>
  )
}

const _Inner = styled(Inner)({
  padding: vars.space.l,
  margin: 'unset',
  width: '100%',
})

const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const Heading = styled('h1')({
  margin: 0,
  fontSize: vars.fontSize.xxl,
})

const _Table = styled(Table)({
  tableLayout: 'fixed',
})

const _Th = styled(Th)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const _Td = styled(Td)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const GroupName = styled(MyLink)({
  color: vars.fontColor.primary,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
})

const _Pager = styled(Pager)({
  marginTop: vars.space.l,
})

export default GroupListPage
