import styled from '@emotion/styled'
import hash from 'object-hash'
import * as React from 'react'

import { FetchStatus } from '~/common/types'
import Loader from '~/components/atoms/Loader'
import Text from '~/components/atoms/Text'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { TriggerInstructionParameters } from '~/domain/workflow/triggerInstruction/TriggerInstructionService'
import { TriggerInstructionWidgetDefinition } from '~/domain/workflow/widget/WidgetDefinition'
import { useMarkedLink } from '~/hooks/useMarkedLink'
import { ViewWidgetProps } from '~/presentation/workflow/detail/editor/form/viewWidget/ViewWidget'
import { useTriggerInstruction } from '~/presentation/workflow/detail/editor/form/viewWidget/useTriggerInstruction'
import { ViewUnit } from '~/presentation/workflow/detail/editor/form/viewWidget/viewUnit/ViewUnit'
import * as vars from '~/styles/variables'

interface Props extends ViewWidgetProps {
  workflowId: string
  definition: TriggerInstructionWidgetDefinition
}

const TriggerInstructionWidget: React.FC<Props> = (props) => {
  const instructionText = useMarkedLink(props.definition.instructionText)
  const dependentFieldsHash = hash(props.dependentFields)

  // ビューアシストが使用できないようなパラメータ（変数が含まれている等）が使用されている場合はエラーが発生します。
  // そうならないように、 params に raw 以外が含まれているかどうかを確認します。
  const params: TriggerInstructionParameters = React.useMemo(() => {
    const res = {}
    for (const fieldKey of props.definition.triggerInstruction
      .parameterFieldKeys) {
      const found = props.dependentFields.findField(fieldKey)
      if (found === undefined) {
        throw new Error(
          `This TriggerInstructionWidget depends on field '${fieldKey}' but its value is not passed. Missing dependencies?`
        )
      }
      if (found.value === undefined) {
        continue
      }
      if (found.value.mode !== 'raw') {
        // raw ではない入力値を持つフィールドに依存している場合はエラー
        throw new Error(
          "TriggerInstruction parameters can't be non-raw input value."
        )
      }
      try {
        res[fieldKey] = InputValue.convertRawInputValueToObject(found.value)
      } catch (e) {
        // convertRawInputValueToObject に失敗した場合はエラー
        console.error(e)
        throw new Error(
          "TriggerInstruction parameters can't be non-raw input value."
        )
      }
    }
    return res
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.definition, dependentFieldsHash])

  const { viewUnitDefinitions, fetchStatus } = useTriggerInstruction(
    props.definition.triggerInstruction.triggerInstructionId,
    props.workflowId,
    params
  )
  return (
    <Container>
      <Text
        element="h1"
        fontSize="s"
        lineHeight="heading"
        style={{ marginBottom: vars.space.s }}
      >
        このトリガーを実行するには以下の設定が必要です。
      </Text>
      <Text
        element="p"
        fontSize="s"
        style={{ marginBottom: vars.space.s }}
        dangerouslySetInnerHTML={{
          __html: instructionText,
        }}
      />
      {fetchStatus === FetchStatus.loading ? (
        <Loader size="s" />
      ) : fetchStatus === FetchStatus.failed ? (
        <Text element="p" color={vars.color.error} fontSize="s">
          設定値の読み込み中にエラーが発生しました。
          <br />
          トリガーの入力値が正しいかどうかご確認ください。
        </Text>
      ) : (
        viewUnitDefinitions.map((definition, i) => (
          <ViewUnitContainer key={i}>
            <ViewUnit definition={definition} />
          </ViewUnitContainer>
        ))
      )}
    </Container>
  )
}

export const Container = styled('div')({
  paddingTop: vars.space.m,
  paddingRight: vars.space.m,
  paddingBottom: vars.space.m,
  paddingLeft: vars.space.m,
  maxWidth: 600,
  backgroundColor: vars.colorPalette.blue50,
  borderLeft: `4px solid ${vars.colorPalette.blue600}`,
})

const ViewUnitContainer = styled('div')({
  marginTop: vars.space.s,
  '&:first-of-type': {
    marginTop: 0,
  },
})

export default TriggerInstructionWidget
