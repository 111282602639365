import styled from '@emotion/styled'
import * as React from 'react'
import { Check, MoreHorizontal, Square, X } from 'react-feather'

import Loader from '~/components/atoms/Loader'
import { RunningStatus } from '~/domain/workflow/instance/RunningStatus'
import * as vars from '~/styles/variables'

interface Props {
  status: RunningStatus
  className?: string
}

const StatusIcon: React.FC<Props> = ({ status, className }) => (
  <Container className={className} status={status}>
    {status === RunningStatus.running ? (
      <Loader size="s" reverse={true} />
    ) : status === RunningStatus.success ? (
      <Check strokeWidth={3} color={vars.color.white} size={22} />
    ) : status === RunningStatus.queueing ? (
      <MoreHorizontal strokeWidth={3} color={vars.color.white} size={22} />
    ) : status === RunningStatus.stop ? (
      <Square strokeWidth={3} color={vars.color.white} size={20} />
    ) : (
      <X strokeWidth={3} color={vars.color.white} size={22} />
    )}
  </Container>
)

const Container = styled('div')<{ status: RunningStatus }>(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: '50%',
  },
  (props) => ({
    backgroundColor:
      props.status === RunningStatus.running ||
      props.status === RunningStatus.queueing
        ? vars.color.running
        : props.status === RunningStatus.success
        ? vars.color.success
        : props.status === RunningStatus.stop
        ? vars.color.warning
        : vars.color.error,
  })
)

export default StatusIcon
