import styled from '@emotion/styled'
import * as React from 'react'
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize'

import * as vars from '~/styles/variables'
import {
  widgetAnimationStyle,
  widgetBorderErrorStyle,
  widgetBorderStyle,
  widgetFocusedStyle,
} from '~/styles/widget'

interface OwnProps {
  hasError?: boolean
  hasBorder?: boolean
  className?: string
}

// ref を omit しないとタイプエラー
type Props = Omit<TextareaAutosizeProps, 'ref'> & OwnProps

const MultilineTextField: React.FC<Props> = ({
  hasError = false,
  hasBorder = true,
  className,
  ...props
}) => (
  <Container className={className} hasError={hasError} hasBorder={hasBorder}>
    <_TextareaAutosize {...props} />
  </Container>
)

const _TextareaAutosize = styled(TextareaAutosize)({
  paddingTop: 10, // TextField と見かけの位置合わせ
  paddingRight: vars.space.m,
  paddingBottom: 10,
  paddingLeft: vars.space.m,
  width: '100% !important', // !important つけることで左右方向のりサイズを制限
  minHeight: vars.height.field,
  color: vars.fontColor.primary,
  fontSize: vars.fontSize.m,
  lineHeight: 1.5,
  border: 'none',
  outline: 'none',
  '&:not(:disabled)': {
    backgroundColor: 'transparent',
  },
})

const Container = styled('div')(
  {
    width: '100%',
    backgroundColor: vars.color.white,
    borderRadius: widgetBorderStyle.borderRadius,
    ...widgetAnimationStyle,
  },
  (props: { hasBorder: boolean; hasError: boolean }) => ({
    border: props.hasBorder
      ? props.hasError
        ? widgetBorderErrorStyle.border
        : widgetBorderStyle.border
      : 'none',
    ...(() =>
      props.hasBorder
        ? {
            '&:focus': {
              ...widgetFocusedStyle,
            },
          }
        : {})(),
  })
)

export default MultilineTextField
