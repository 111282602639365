import styled from '@emotion/styled'
import Color from 'color'
import React from 'react'
import * as Icons from 'react-feather'

import Text from '~/components/atoms/Text'
import * as vars from '~/styles/variables'

interface Props {
  message: string
}

const AlertBar: React.FC<Props> = (props) => {
  return (
    <Container>
      <Icons.Bell color={vars.color.error} size={18} />
      <div style={{ flex: 1, marginLeft: vars.space.s }}>
        <Text
          element="p"
          color={vars.color.error}
          fontSize="s"
          fontWeight="bold"
          lineHeight="just"
        >
          {props.message}
        </Text>
      </div>
    </Container>
  )
}

const Container = styled('div')({
  padding: vars.space.m,
  display: 'flex',
  alignItems: 'center',
  backgroundColor: Color(vars.color.error).alpha(0.15).toString(),
  borderRadius: vars.borderRadius.s * 1.5,
})

export default AlertBar
