import styled from '@emotion/styled'
import * as hash from 'object-hash'
import * as React from 'react'

import Pill from '~/components/atoms/Pill'
import { ValueCardProps } from '~/components/molecules/HoverCard/types'
import HoverCardTarget from '~/components/organisms/HoverCardTarget/HoverCardTarget'
import { ValueType } from '~/domain/ValueType'
import { RenderableEntity } from '~/domain/workflow/source/RenderableElement'
import { PrimitivesTab } from '~/domain/workflow/variableFinder/PrimitivesTab'
import { TypeMismatchTooltip } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/tooltips'
import { EntityType } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/types'
import * as vars from '~/styles/variables'

interface Props {
  data: PrimitivesTab
  targetValueTypes: ValueType[]
  onSelect: (entities: RenderableEntity[]) => void
}

const PrimitivesTabContent: React.FC<Props> = (props) => {
  return (
    <>
      {props.data.sections.map((section, i) => (
        <Section key={hash([section.label, i])}>
          <Heading>{section.label}</Heading>
          <PillList>
            {section.primitives.map((primitive) => {
              const enabled = props.targetValueTypes.some((targetValueType) =>
                ValueType.matchLoose(targetValueType, primitive.valueType)
              )
              const cardProps: ValueCardProps = {
                type: 'value',
                name: primitive.label,
                description: primitive.description,
                valueType: primitive.valueType,
              }
              const handleClick = () => {
                if (!enabled) {
                  return
                }
                props.onSelect([
                  { type: EntityType.PRIMITIVE, label: primitive.label },
                ])
              }
              return (
                <PillItem key={primitive.label}>
                  <TypeMismatchTooltip disabled={enabled}>
                    <HoverCardTarget card={cardProps} disabled={!enabled}>
                      <Pill disabled={!enabled} onClick={handleClick}>
                        {primitive.label}
                      </Pill>
                    </HoverCardTarget>
                  </TypeMismatchTooltip>
                </PillItem>
              )
            })}
          </PillList>
        </Section>
      ))}
    </>
  )
}

const PillList = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const Section = styled('div')({
  marginTop: vars.space.l,
  '&:first-of-type': {
    marginTop: 0,
  },
})

const Heading = styled('h3')({
  display: 'flex',
  alignItems: 'center',
  fontSize: vars.fontSize.s,
  fontWeight: vars.fontWeight.bold,
  marginBottom: vars.space.m,
})

const PillItem = styled('div')({
  marginTop: vars.space.s,
  marginRight: vars.space.s,
})

export default PrimitivesTabContent
