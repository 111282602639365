import styled from '@emotion/styled'
import * as React from 'react'

import { PendingResult } from '~/common/types'
import Loader from '~/components/atoms/Loader'
import { MemoryTestResultRepository } from '~/data/workflow/test/MemoryTestResultRepository'
import { TestResultRepository } from '~/domain/workflow/test/TestResultRepository'
import { WorkflowTestResult } from '~/domain/workflow/test/WorkflowTestResult'

interface WorkflowTestResultContextInterface {
  workflowTestResult: WorkflowTestResult
  update: (
    updater: (current: WorkflowTestResult) => WorkflowTestResult
  ) => Promise<void>
}

export const WorkflowTestResultContext = React.createContext<
  WorkflowTestResultContextInterface | undefined
>(undefined)

export function useWorkflowTestResult(): WorkflowTestResultContextInterface {
  const context = React.useContext(WorkflowTestResultContext)
  if (context === undefined) {
    throw new Error(
      'WorkflowTestResultContext is undefined. Out of context scope?'
    )
  }
  return context
}

interface Props {
  workflowId: string
}

const repository: TestResultRepository = MemoryTestResultRepository.INSTANCE

export const WorkflowTestResultProvider: React.FC<Props> = (props) => {
  const [result, setResult] = React.useState<PendingResult<WorkflowTestResult>>(
    PendingResult.pending()
  )

  React.useEffect(() => {
    repository
      .find(props.workflowId)
      .then((it) => {
        setResult(
          PendingResult.success(it ?? new WorkflowTestResult(props.workflowId))
        )
      })
      .catch((reason) => {
        console.error(reason)
        setResult(PendingResult.failure(undefined, reason))
      })
  }, [props.workflowId])

  switch (result.status) {
    case PendingResult.Status.pending:
      return (
        <Container>
          <Loader />
        </Container>
      )
    case PendingResult.Status.failure:
      return (
        <Container>
          <p>エラー</p>
        </Container>
      )
    case PendingResult.Status.success:
      return (
        <Provider workflowTestResult={result.data} repository={repository}>
          {props.children}
        </Provider>
      )
  }
}

const Provider: React.FC<{
  workflowTestResult: WorkflowTestResult
  repository: TestResultRepository
}> = (props) => {
  // 初回だけ Repository から取得した WorkflowTestResult を使用して、あとはローカルで
  // そのキャッシュを更新して使用する
  const [cache, setCache] = React.useState<WorkflowTestResult>(
    props.workflowTestResult
  )

  const update = React.useCallback(
    async (updater: (current: WorkflowTestResult) => WorkflowTestResult) => {
      const newOne = updater(cache)
      await props.repository.save(newOne)
      setCache(newOne)
    },
    [cache, props.repository]
  )

  const value: WorkflowTestResultContextInterface = React.useMemo(
    () => ({
      workflowTestResult: cache,
      update,
    }),
    [cache, update]
  )

  return (
    <WorkflowTestResultContext.Provider value={value}>
      {props.children}
    </WorkflowTestResultContext.Provider>
  )
}

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
