import styled from '@emotion/styled'
import * as React from 'react'

import Text from '~/components/atoms/Text'
import * as vars from '~/styles/variables'

interface Props {
  heading: string
  description: string
  renderFooter?: () => React.ReactNode
  className?: string
}

const NoResults: React.FC<Props> = ({
  heading,
  description,
  renderFooter,
  className,
}) => {
  return (
    <Container className={className}>
      <Heading element="h2" fontWeight="bold" fontSize="m" lineHeight="heading">
        {heading}
      </Heading>
      <Description
        element="p"
        color="secondary"
        fontSize="s"
        fontWeight="normal"
        lineHeight="body"
      >
        {description}
      </Description>
      {renderFooter ? <Footer>{renderFooter()}</Footer> : null}
    </Container>
  )
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: vars.space.l,
})

const Heading = styled(Text)({})
const Description = styled(Text)({
  marginTop: vars.space.s,
})

const Footer = styled('div')({
  marginTop: vars.space.m,
})

export default NoResults
