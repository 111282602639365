import { isValidCron } from 'cron-validator'
import * as React from 'react'

import { CronEditorMode } from '~/presentation/workflow/detail/editor/form/inputWidget/cron/CronEditorMode'

export function useHours(
  value: string | undefined
): [
  number | undefined,
  React.Dispatch<React.SetStateAction<number | undefined>>
] {
  const [hours, setHours] = React.useState<number | undefined>(undefined)

  React.useEffect(() => {
    if (value === undefined) {
      setHours(undefined)
      return
    }
    if (!isValidCron(value)) {
      setHours(undefined)
      return
    }
    const [, _hours] = value.split(' ')
    // */0 ~ */23 に一致するか
    const found = _hours.match(/^\*\/([0-9]|1[0-9]|2[0-3])$/)
    if (found === null) {
      setHours(undefined)
      return
    }
    setHours(Number(found[1]))
  }, [value])

  return [hours, setHours]
}

export function useMinutes(
  value: string | undefined
): [
  number | undefined,
  React.Dispatch<React.SetStateAction<number | undefined>>
] {
  const [minutes, setMinutes] = React.useState<number | undefined>(undefined)

  React.useEffect(() => {
    if (value === undefined) {
      setMinutes(undefined)
      return
    }
    if (!isValidCron(value)) {
      setMinutes(undefined)
      return
    }
    const [_minutes] = value.split(' ')
    // */0 ~ */59 に一致するか
    const found = _minutes.match(/^\*\/([0-9]|[1-5][0-9])$/)
    if (found === null) {
      setMinutes(undefined)
      return
    }
    setMinutes(Number(found[1]))
  }, [value])

  return [minutes, setMinutes]
}

export function useMode(
  value: string | undefined
): [CronEditorMode, React.Dispatch<React.SetStateAction<CronEditorMode>>] {
  const [mode, setMode] = React.useState<CronEditorMode>(
    CronEditorMode.unselected
  )

  React.useEffect(() => {
    if (value === undefined) {
      setMode(CronEditorMode.unselected)
      return
    }
    if (!isValidCron(value)) {
      setMode(CronEditorMode.unselected)
      return
    }
    const [_minutes, _hours] = value.split(' ')
    if (/\*\/([0-9]|[1-5][0-9])/.test(_minutes)) {
      setMode(CronEditorMode.repeatMinute)
      return
    }
    if (/\*\/[0-23]/.test(_hours)) {
      setMode(CronEditorMode.repeatHour)
      return
    }
    setMode(CronEditorMode.unselected)
  }, [value])

  return [mode, setMode]
}
