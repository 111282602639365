import { delay } from '~/common/utils'
import { AuthService } from '~/domain/auth/AuthService'
import { MeWithoutRole } from '~/domain/auth/MeWithoutRole'

export class MockAuthService implements AuthService {
  async signUpWithGoogle(_idToken: string): Promise<MeWithoutRole> {
    delay(1500)
    return {
      id: 'mocked_user_slug',
      email: 'mock@example.com',
      username: 'モックパーソン',
      organization: {
        id: 'mocked_organization_slug',
        name: '合同会社モック',
      },
      belongingGroups: [],
    }
  }

  async signInWithGoogle(_idToken: string): Promise<MeWithoutRole> {
    delay(1500)
    return {
      id: 'mocked_user_slug',
      email: 'mock@example.com',
      username: 'モックパーソン',
      organization: {
        id: 'mocked_organization_slug',
        name: '合同会社モック',
      },
      belongingGroups: [],
    }
  }

  async signUpByInvitationWithGoogle(
    _idToken: string,
    _invitationToken: string
  ): Promise<MeWithoutRole> {
    delay(1500)
    return {
      id: 'mocked_user_slug',
      email: 'mock@example.com',
      username: 'モックパーソン',
      organization: {
        id: 'mocked_organization_slug',
        name: '合同会社モック',
      },
      belongingGroups: [],
    }
  }

  async signInWithEmail(_email: string, _password: string): Promise<void> {}

  async signOut(): Promise<void> {}
}
