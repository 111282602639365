import dayjs from 'dayjs'

import {
  GroupJson,
  GroupMetaJson,
  GroupSummaryJson,
} from '~/data/group/GroupJson'
import { mapJsonToUser } from '~/data/user/mapJsonToUser'
import { Group } from '~/domain/group/Group'
import { GroupMeta, GroupSummary } from '~/domain/group/GroupSummary'

export function mapJsonToGroup(json: GroupJson): Group {
  return new Group({
    id: json.slug,
    name: json.name,
    workflowCount: json.workflowCount,
    updatedAt: dayjs(json.updatedAt).toDate(),
    members: json.members.map(mapJsonToUser),
  })
}

export function mapJsonToGroupSummary(json: GroupSummaryJson): GroupSummary {
  return {
    id: json.slug,
    name: json.name,
    memberCount: json.memberCount,
    workflowCount: json.workflowCount,
    updatedAt: dayjs(json.updatedAt).toDate(),
  }
}

export function mapJsonToGroupMeta(json: GroupMetaJson): GroupMeta {
  return {
    id: json.slug,
    name: json.name,
    updatedAt: dayjs(json.updatedAt).toDate(),
  }
}
