import * as utils from '~/common/utils'
import { InputWidgetJson } from '~/data/workflow/widget/json'
import { InputWidgetDefinition } from '~/domain/workflow/widget/WidgetDefinition'

export function mapJsonToInputWidgetDefinitionOption(
  json: InputWidgetJson.Option
): InputWidgetDefinition.Option {
  return {
    label: json.label,
    value: json.value,
  }
}

export function mapJsonToInputWidgetDefinitionEntry(
  json: InputWidgetJson.Entry
): InputWidgetDefinition.Entry {
  return {
    label: json.label,
    key: json.key,
    valueForm: mapJsonToInputWidgetDefinition(json.valueForm),
  }
}

export function mapJsonToInputWidgetDefinition(
  json: InputWidgetJson
): InputWidgetDefinition {
  switch (json.formType) {
    case 'text': {
      return {
        formType: json.formType,
        placeholder: json.placeholder !== null ? json.placeholder : undefined,
        pattern: json.pattern !== null ? json.pattern : undefined,
      }
    }
    case 'multiline_text': {
      return {
        formType: json.formType,
        placeholder: json.placeholder !== null ? json.placeholder : undefined,
      }
    }
    case 'number': {
      return {
        formType: json.formType,
        minValue: json.minValue !== null ? json.minValue : undefined,
        maxValue: json.maxValue !== null ? json.maxValue : undefined,
        step: json.step !== null ? json.step : undefined,
      }
    }
    case 'radio': {
      return {
        formType: json.formType,
        options: json.options.map(mapJsonToInputWidgetDefinitionOption),
      }
    }
    case 'check': {
      return {
        formType: json.formType,
        options: json.options.map(mapJsonToInputWidgetDefinitionOption),
      }
    }
    case 'select': {
      return {
        formType: json.formType,
        options: json.options.map(mapJsonToInputWidgetDefinitionOption),
      }
    }
    case 'switch': {
      return {
        formType: json.formType,
        label: json.label,
      }
    }
    case 'list': {
      return {
        formType: json.formType,
        itemForm: mapJsonToInputWidgetDefinition(json.itemForm),
      }
    }
    case 'struct': {
      return {
        formType: json.formType,
        entries: json.entries.map(mapJsonToInputWidgetDefinitionEntry),
      }
    }
    case 'dict': {
      return {
        formType: json.formType,
        valueForm: mapJsonToInputWidgetDefinition(json.valueForm),
      }
    }
    case 'assist': {
      const assist = json.assist
      return {
        formType: json.formType,
        assist: {
          assistId: assist.assistId,
          queryPlaceholder: assist.queryPlaceholder,
          parameterFieldKeys: assist.parameterFieldKeys,
        },
        fallbackForm: mapJsonToInputWidgetDefinition(json.fallbackForm),
      }
    }
    case 'multi_assist': {
      const assist = json.assist
      return {
        formType: json.formType,
        assist: {
          assistId: assist.assistId,
          queryPlaceholder: assist.queryPlaceholder,
          parameterFieldKeys: assist.parameterFieldKeys,
        },
        fallbackForm: mapJsonToInputWidgetDefinition(json.fallbackForm),
      }
    }
    case 'account': {
      return {
        formType: json.formType,
        provider: json.provider,
        valueForm: mapJsonToInputWidgetDefinition(json.valueForm),
      }
    }
    case 'multi_provider_account': {
      return {
        formType: json.formType,
        fieldLabel: json.fieldLabel,
        providers: json.providers,
        valueForm: mapJsonToInputWidgetDefinition(json.valueForm),
      }
    }
    case 'legacy_account': {
      return {
        formType: json.formType,
        provider: json.provider,
      }
    }
    case 'cron': {
      return {
        formType: json.formType,
      }
    }
    case 'salesforce_search_condition': {
      return {
        formType: json.formType,
        accountUidFieldKey: json.accountUidFieldKey,
        sobjectNameFieldKey: json.sobjectNameFieldKey,
        canUseStateOperators: json.canUseStateOperators,
      }
    }
    case 'date_range': {
      return {
        formType: json.formType,
        startLimit: json.startLimit,
        endLimit: json.endLimit,
      }
    }
    default: {
      return utils.assertNever(json)
    }
  }
}
