import * as vars from '~/styles/variables'

export const borderWidth = 2

export const widgetBorderStyle = {
  border: `${borderWidth}px solid ${vars.color.border}`,
  borderRadius: vars.borderRadius.m,
}

export const widgetBorderErrorStyle = {
  border: `${borderWidth}px solid ${vars.color.error}`,
}

export const widgetAnimationStyle = {
  transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
}

export const widgetFocusedStyle = {
  border: `${borderWidth}px solid ${vars.colorPalette.blue600}`,
  boxShadow: `0 0 0 2px ${vars.colorPalette.blue50}`,
}
