import styled from '@emotion/styled'
import * as React from 'react'
import { animated, useSpring } from 'react-spring'

import MyLink from '~/components/atoms/Link'
import Inner from '~/components/organisms/Inner'
import { HOME } from '~/routes'
import * as vars from '~/styles/variables'

interface Props {
  invert?: boolean
}

const Header: React.FC<Props> = ({ invert = false }) => {
  const props = useSpring({
    from: { transform: 'translateY(-30px)', opacity: 0 },
    to: { transform: 'translateY(0)', opacity: 1 },
  })
  return (
    <Container style={props}>
      <_Inner responsive={true}>
        <MyLink to={HOME}>
          <Logo
            src={
              invert
                ? '/static/images/logo-white.svg'
                : '/static/images/logo.svg'
            }
            alt="Anyflow"
            width={155}
          />
        </MyLink>
      </_Inner>
    </Container>
  )
}

const Container = styled(animated.header)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: vars.height.lpHeader,
  backgroundColor: 'transparent',
  zIndex: 1000,
})

const _Inner = styled(Inner)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '100%',
  zIndex: 1,
})

const Logo = styled('img')({})

export default Header
