import { TokenPaged } from '~/common/Pagination'
import config from '~/common/config'
import { convertToURIComponent } from '~/common/utils'
import { Tson } from '~/data/Tson'
import requestJson, { HttpError } from '~/data/request'
import { sanitize } from '~/data/utils'
import { AssistedValueJson } from '~/data/workflow/assist/AssistedValueJson'
import { mapJsonToAssistedValue } from '~/data/workflow/assist/mapJsonToAssistedValue'
import { mapToAssistedValueJson } from '~/data/workflow/assist/mapToAssistedValueJson'
import {
  AssistParameters,
  AssistService,
} from '~/domain/workflow/assist/AssistService'
import { AssistedValue } from '~/domain/workflow/assist/AssistedValue'

export class AssistServiceImpl implements AssistService {
  async getLabel(
    assistId: string,
    params: AssistParameters,
    value: unknown
  ): Promise<AssistedValue | undefined> {
    try {
      const res = await requestJson(
        `${config.apiRoot}/assists/${sanitize(
          assistId
        )}/${convertToURIComponent(value)}?params=${convertToURIComponent(
          params
        )}`
      )
      const json = mapToAssistedValueJson(new Tson(res))
      return mapJsonToAssistedValue(json)
    } catch (e) {
      // TODO: 一時的に 400 エラーの場合も見つからなかったものとして扱う
      // https://anyflowinc.slack.com/archives/CLS7U483G/p1594187005179100
      if (
        e instanceof HttpError &&
        (e.statusCode === 404 || e.statusCode === 400)
      ) {
        return undefined
      }
      throw e
    }
  }

  async getList(
    assistId: string,
    query: string,
    params: AssistParameters,
    pageToken?: string
  ): Promise<TokenPaged<AssistedValue>> {
    const queryParams: string[] = [
      `query=${encodeURIComponent(query)}`,
      `params=${convertToURIComponent(params)}`,
    ]
    if (pageToken !== undefined) {
      queryParams.push(`pageToken=${sanitize(pageToken)}`)
    }
    const res = await requestJson(
      `${config.apiRoot}/assists/${sanitize(assistId)}?${queryParams.join('&')}`
    )
    const json = mapToGetListResponseJson(new Tson(res))
    return {
      nextPageToken: json.nextPageToken,
      items: json.results.map(mapJsonToAssistedValue),
    }
  }
}

interface GetListResponseJson {
  nextPageToken?: string
  results: AssistedValueJson[]
}

function mapToGetListResponseJson(tson: Tson): GetListResponseJson {
  return {
    nextPageToken: tson.getString('nextPageToken', false) ?? undefined,
    results: tson.getArray('results').map(mapToAssistedValueJson),
  }
}
