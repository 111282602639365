import { ValueType } from '~/domain/ValueType'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { ValidationResult } from '~/domain/workflow/validator/ValidationResult'
import { InputWidgetDefinition } from '~/domain/workflow/widget/WidgetDefinition'
import { InputWidgetValidatorContext } from '~/domain/workflow/widget/validator/InputWidgetValidator'

export abstract class RawInputWidgetValidator<
  T extends InputWidgetDefinition = InputWidgetDefinition,
  V extends ValueType = ValueType
> {
  constructor(protected readonly context: InputWidgetValidatorContext<T, V>) {}

  abstract validate(inputValue: InputValue.Raw): Promise<ValidationResult>
}
