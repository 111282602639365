import * as React from 'react'

import { ValueType } from '~/domain/ValueType'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { InputWidgetDefinition } from '~/domain/workflow/widget/WidgetDefinition'
import { DependentFields } from '~/presentation/workflow/detail/editor/form/field/DependentField'
import { RawInputWidget } from '~/presentation/workflow/detail/editor/form/inputWidget/RawInputWidget'
import RenderableInputWidget from '~/presentation/workflow/detail/editor/form/inputWidget/RenderableInputWidget'

export interface InputWidgetProps {
  definition: InputWidgetDefinition
  valueType: ValueType
  value: InputValue
  bulkMode: boolean
  bulkable: boolean
  required: boolean
  renderable: boolean
  readonly: boolean
  dependentFields: DependentFields
  onChange: (value: InputValue) => void
  onBulkSizeChange: (newBulkSize?: 'n' | number) => void
}

const InputWidget: React.FC<InputWidgetProps> = (props) => {
  return props.renderable ? (
    <RenderableInputWidget {...props} />
  ) : (
    <RawInputWidget
      {...props}
      value={
        // renderable が false の時に mode: render の値は来ないはずなので、
        // もし来た場合は未入力 (undefined) として扱う
        props.value && props.value.mode === 'render' ? undefined : props.value
      }
    />
  )
}

export default InputWidget
