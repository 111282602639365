import styled from '@emotion/styled'
import * as d3 from 'd3'
import * as React from 'react'

import Links from '~/components/organisms/NodeLinks'
import Nodes from '~/components/organisms/Nodes'
import { TaskInstanceSummary } from '~/domain/workflow/instance/taskInstance/TaskInstanceSummary'
import { Node } from '~/hooks/useTree'
import * as vars from '~/styles/variables'

/**
 * Nodeのツリーを表示する
 */

interface Props {
  nodes: d3.HierarchyPointNode<Node>[]
  links: d3.HierarchyPointLink<Node>[]
  taskInstances?: TaskInstanceSummary[]
  maxHeight?: number
  selectedTaskId?: string
  onActionClick?: (
    event: React.MouseEvent<SVGCircleElement, MouseEvent>,
    node: d3.HierarchyPointNode<Node>,
    taskInstance?: TaskInstanceSummary
  ) => void
  onTriggerClick?: (node: d3.HierarchyPointNode<Node>) => void
}

const NodeTree: React.FunctionComponent<Props> = ({
  nodes,
  links,
  taskInstances,
  maxHeight,
  selectedTaskId,
  onActionClick,
  onTriggerClick,
}) => {
  const [treeRef, setTreeRef] = React.useState<SVGGElement | null>(null)
  const [width, setWidth] = React.useState<number>(0)
  const [height, setHeight] = React.useState<number>(0)

  // @see: https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
  React.useEffect(() => {
    // サイズ変更時
    setTreeSize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeRef])

  function setTreeSize() {
    if (treeRef) {
      const rect = treeRef.getBoundingClientRect()
      setWidth(rect.width + vars.space.l * 2)
      setHeight(rect.height + vars.space.l * 2)
    }
  }

  return (
    <SVG maxHeight={maxHeight || height} viewBox={`0 0 ${width} ${height}`}>
      <g ref={setTreeRef}>
        <g transform={`translate(${vars.space.l}, ${vars.space.l})`}>
          <Links links={links} readonly={false} />
          <Nodes
            nodes={nodes}
            taskInstances={taskInstances}
            selectedTaskId={selectedTaskId}
            onActionClick={onActionClick}
            onTriggerClick={onTriggerClick}
          />
        </g>
      </g>
    </SVG>
  )
}

const SVG = styled('svg')((props: { maxHeight: number }) => ({
  display: 'block',
  width: '100%',
  maxWidth: '100%',
  maxHeight: props.maxHeight,
}))

export default NodeTree
