import * as React from 'react'

import { apiClients } from '~/common/apiClients'
import { FetchStatus } from '~/common/types'
import { Workflow } from '~/domain/workflow/Workflow'
import { WorkflowService } from '~/domain/workflow/WorkflowService'

const workflowService: WorkflowService = apiClients.workflowService

export function useWorkflow(
  workflowId: string
): {
  workflow?: Workflow
  refetch: (suppress?: boolean) => void
  fetchStatus: FetchStatus
} {
  const [workflow, setWorkflow] = React.useState<Workflow>()
  const [fetchStatus, setFetchStatus] = React.useState<FetchStatus>(
    FetchStatus.none
  )

  React.useEffect(() => {
    fetchWorkflow()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowId])

  const fetchWorkflow = (suppress: boolean = false) => {
    if (!suppress) {
      setFetchStatus(FetchStatus.loading)
    }
    workflowService
      .get(workflowId)
      .then((it) => {
        setFetchStatus(FetchStatus.loaded)
        setWorkflow(it)
      })
      .catch((reason) => {
        setFetchStatus(FetchStatus.failed)
        console.error(`Failed to fetch workflow: ${reason}`)
      })
  }

  return { workflow, refetch: fetchWorkflow, fetchStatus }
}
