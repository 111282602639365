import _ from 'lodash'

import { User } from '~/domain/user/User'

interface Init {
  id: string
  name: string
  workflowCount: number
  updatedAt: Date
  members: User[]
}

export class Group {
  public readonly id: string
  public readonly name: string
  public readonly workflowCount: number
  public readonly updatedAt: Date
  public readonly members: User[]

  constructor({ id, name, workflowCount, updatedAt, members }: Init) {
    this.id = id
    this.name = name
    this.workflowCount = workflowCount
    this.updatedAt = updatedAt
    this.members = members
  }

  updateName(newName: string): Group {
    return new Group({ ...this, name: newName })
  }

  addMember(user: User): Group {
    return new Group({ ...this, members: [...this.members, user] })
  }

  removeMember(userId: String): Group {
    return new Group({
      ...this,
      members: _.reject(this.members, (it) => it.id === userId),
    })
  }

  get memberCount(): number {
    return this.members.length
  }
}
