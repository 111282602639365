import * as React from 'react'
import { AlertTriangle, XCircle } from 'react-feather'

import * as utils from '~/common/utils'
import { ActionState } from '~/domain/workflow/action/ActionState'
import * as vars from '~/styles/variables'

interface Props {
  supportState: ActionState
}

const ActionStateTip: React.FC<Props> = (props) => {
  if (props.supportState === ActionState.available) {
    return null
  }
  return (
    <div
      style={{
        position: 'relative',
        paddingTop: vars.space.s,
        paddingRight: vars.space.m,
        paddingBottom: vars.space.s,
        paddingLeft: vars.space.m,
        color: vars.color.white,
        fontSize: vars.fontSize.xs,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderRadius: vars.borderRadius.s,
        boxShadow: vars.shadow.s,
        pointerEvents: 'none',
      }}
    >
      <span
        style={{
          position: 'absolute',
          bottom: -12,
          left: '50%',
          transform: 'translateX(-50%)',
          borderWidth: 6,
          borderStyle: 'solid',
          borderBottomColor: 'transparent',
          borderRightColor: 'transparent',
          borderTopColor: 'rgba(0, 0, 0, 0.8)',
          borderLeftColor: 'transparent',
          content: '""',
        }}
      />
      {(() => {
        switch (props.supportState) {
          case ActionState.deprecated:
            return renderDeprecatedContent()
          case ActionState.deleted:
            return renderDeletedContent()
          default:
            utils.assertNever(props.supportState)
        }
      })()}
    </div>
  )
}

const renderDeprecatedContent = () => (
  <>
    <p
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        fontWeight: 'bold',
      }}
    >
      <AlertTriangle
        size={16}
        color={vars.color.warning}
        style={{ marginRight: vars.space.xs }}
      />
      非推奨アクション
    </p>
    <p style={{ fontSize: vars.fontSize.xxs }}>
      このアクションは2ヶ月以内にサポートが終了されます
    </p>
  </>
)

const renderDeletedContent = () => (
  <>
    <p
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        fontWeight: 'bold',
      }}
    >
      <XCircle
        size={16}
        color={vars.color.error}
        style={{ marginRight: vars.space.xs }}
      />
      廃止済アクション
    </p>
    <p style={{ fontSize: vars.fontSize.xxs }}>
      このアクションはサポートされていません
    </p>
  </>
)

export default ActionStateTip
