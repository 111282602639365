import * as _ from 'lodash'
import * as React from 'react'

import * as utils from '~/common/utils'
import { AppDefinition } from '~/domain/workflow/app/AppDefinition'
import { WorkflowSourceBody } from '~/domain/workflow/source/WorkflowSourceBody'
import { useDefinitions } from '~/presentation/AnyflowAppContext'

/** Workflow Source でトリガーおよびアクションとして使用されているアプリの一覧を取得するフック */
export function useAppsFromSource(
  source?: WorkflowSourceBody
): [AppDefinition | null, AppDefinition[]] {
  const definitions = useDefinitions()
  const triggerApp = React.useMemo(() => {
    if (!source) {
      return null
    }
    const triggerSource = source?.getTrigger()
    if (triggerSource === undefined) {
      return null
    }
    const trigger = definitions.getTrigger(triggerSource.triggerId)
    return trigger ? definitions.getApp(trigger.appId) : null
  }, [definitions, source])
  const actionApps = React.useMemo(() => {
    if (!source) {
      return []
    }
    const taskSource = source.getRootTask()
    if (taskSource === undefined) {
      return []
    }
    const flattenTasks = utils.flattenTaskSource(taskSource)
    const actionIds = flattenTasks.map((task) => task.actionId)
    const actionAppIds = actionIds.map((id) => definitions.getAction(id).appId)
    const uniqueAppIds = _.uniq(
      triggerApp === null ? actionAppIds : [triggerApp.appId, ...actionAppIds]
    )
    return uniqueAppIds.map((appId) => definitions.getApp(appId))
  }, [triggerApp, definitions, source])
  return [triggerApp, actionApps]
}
