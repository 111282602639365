import request from '~/api/request'
import config from '~/common/config'
import { JinjaTemplate } from '~/common/types/jinja'
import { getCSRFToken } from '~/common/utils'

/**
 * ページャー用クエリを生成
 * @example
 * buildPagerQuery(1)
 * // page=1
 * @example
 * buildPagerQuery(1, 10)
 * // page=1&limit=30
 */
export function buildPagerQuery(
  page?: string | number,
  limit?: number
): string {
  if (!page && !limit) {
    return ''
  }
  const arr = []
  if (page) {
    arr.push(`page=${page}`)
  }
  if (limit) {
    arr.push(`limit=${limit}`)
  }
  return arr.join('&')
}

interface ParseJinjaResponse {
  ast: JinjaTemplate
}

export function parseJinja(text: string, renderId: string = 'LoopRender') {
  const CSRFToken = getCSRFToken()
  if (!CSRFToken) {
    throw new Error('CSRFトークンがありません')
  }
  return request<ParseJinjaResponse>(
    `${config.apiRoot}/renders/${renderId}/parse`,
    {
      method: 'POST',
      headers: {
        'X-CSRFToken': CSRFToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        template: text,
      }),
    }
  )
}
