import styled from '@emotion/styled'
import * as React from 'react'
import ReactContentLoader from 'react-content-loader'

import { Result } from '~/common/Result'
import { formatDate } from '~/common/utils'
import { DropdownMenuItem } from '~/components/atoms/DropdownMenu'
import MoreActionsButtonWithDropdownMenu from '~/components/molecules/MoreActionsButtonWithDropdownMenu'
import UserComponent from '~/components/molecules/User'
import NoResults from '~/components/organisms/NoResults'
import {
  Table,
  Body as Tbody,
  Cell as Td,
  HeaderCell as Th,
  Header as Thead,
  Row as Tr,
} from '~/components/organisms/Table'
import { Invitation } from '~/domain/user/Invitation'
import * as vars from '~/styles/variables'

const actions: DropdownMenuItem[] = [
  { id: 'reInvite', label: '再度招待する', role: 'normal' },
  { id: 'cancel', label: '招待取り消し', role: 'danger' },
]

interface Props {
  result: Result<Invitation[]>
  handleReInvite: (invitationId: string) => Promise<void>
  isReInviting: (invitationId: string) => boolean
  handleCancelInvitation: (invitationId: string) => Promise<void>
  isCanceling: (invitationId: string) => boolean
}

export const InvitationTable: React.FC<Props> = (props) => {
  if (props.result.loading) {
    return <ContentLoader />
  }

  if (props.result.error !== undefined) {
    return (
      <NoResults
        heading="エラー"
        description="招待中のユーザーの取得に失敗しました"
      />
    )
  }

  if (props.result.data.length === 0) {
    return (
      <NoResults
        heading="招待中のユーザーがいません。"
        description="ユーザーを招待しましょう。"
      />
    )
  }

  return (
    <Table style={{ tableLayout: 'fixed' }}>
      <Thead>
        <Tr>
          <_Th>{''}</_Th>
          <_Th style={{ width: 250 }}>招待した日時</_Th>
          <_Th style={{ width: 250 }}>有効期限</_Th>
          <_Th style={{ width: 50 }}>編集</_Th>
        </Tr>
      </Thead>
      <Tbody>
        {props.result.data.map((invitation) => (
          <InvitationTableRow
            key={invitation.id}
            invitation={invitation}
            handleReInvite={props.handleReInvite}
            reInviting={props.isReInviting(invitation.id)}
            handleCancelInvitation={props.handleCancelInvitation}
            canceling={props.isCanceling(invitation.id)}
          />
        ))}
      </Tbody>
    </Table>
  )
}

const InvitationTableRow: React.FC<{
  invitation: Invitation
  handleReInvite: (invitationId: string) => Promise<void>
  reInviting: boolean
  handleCancelInvitation: (invitationId: string) => Promise<void>
  canceling: boolean
}> = (props) => {
  const { handleReInvite, handleCancelInvitation } = props

  const handleMenuItemClick = React.useCallback(
    (item: DropdownMenuItem) => {
      if (item.id === 'reInvite') {
        handleReInvite(props.invitation.id)
        return
      }
      if (item.id === 'cancel') {
        handleCancelInvitation(props.invitation.id)
        return
      }
      console.error(`No such item in then menu: ${item.id}`)
    },
    [handleCancelInvitation, handleReInvite, props.invitation.id]
  )

  return (
    <Tr style={{ opacity: props.reInviting || props.canceling ? 0.5 : 1 }}>
      <_Td>
        <UserComponent username={props.invitation.email} fontWeight={'bold'} />
      </_Td>
      <_Td>{formatDate(props.invitation.updatedAt)}</_Td>
      <_Td>{formatDate(props.invitation.expireAt)}</_Td>
      <_Td style={{ color: vars.fontColor.tertiary }}>
        <MoreActionsButtonWithDropdownMenu
          handleMenuItemClick={handleMenuItemClick}
          horizontalAlign={'left'}
          items={actions}
        />
      </_Td>
    </Tr>
  )
}

const _Th = styled(Th)({
  whiteSpace: 'nowrap',
})

const _Td = styled(Td)({
  whiteSpace: 'nowrap',
})

const ContentLoader: React.FC = () => {
  return (
    <>
      <div style={{ display: 'flex', height: 37, alignItems: 'center' }}>
        <ReactContentLoader speed={1} width={1100} height={14}>
          <rect x={0} y={0} rx="2" ry="2" width="150" height="14" />
          <rect x={408} y={0} rx="2" ry="2" width="120" height="14" />
          <rect x={658} y={0} rx="2" ry="2" width="120" height="14" />
          <rect x={900} y={0} rx="2" ry="2" width="50" height="14" />
        </ReactContentLoader>
      </div>
      {new Array(10).fill(null).map((__, i) => (
        <div
          key={i}
          style={{ display: 'flex', height: 58, alignItems: 'center' }}
        >
          <ReactContentLoader speed={1} width={1100} height={14}>
            <rect x={0} y={0} rx="2" ry="2" width="392" height="14" />
            <rect x={408} y={0} rx="2" ry="2" width="198" height="14" />
            <rect x={658} y={0} rx="2" ry="2" width="198" height="14" />
            <rect x={900} rx="2" ry="2" width="50" height="14" />
          </ReactContentLoader>
        </div>
      ))}
    </>
  )
}
