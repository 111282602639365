import produce from 'immer'
import { call, put, takeLatest } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { actionCreatorFactory } from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { Pagination, PaginationMeta } from '~/common/Pagination'
import { apiClients } from '~/common/apiClients'
import { FetchStatus } from '~/common/types'
import { Connection } from '~/domain/connection/Connection'
import { ConnectionService } from '~/domain/connection/ConnectionService'
import { RootState } from '~/ducks'

const service: ConnectionService = apiClients.connectionService
const actionCreator = actionCreatorFactory('anyflow/connection/list')

export const types = {
  RESET: 'RESET',
  FETCH: 'FETCH',
}

interface FetchRequest {
  page: string
}

interface FetchResponse {
  connections: Connection[]
  pager: PaginationMeta
}

export const actions = {
  reset: actionCreator(types.RESET),
  fetch: actionCreator.async<FetchRequest, FetchResponse>(types.FETCH),
}

export const selectors = {
  getFetchStatus: createSelector(
    (state: RootState) => state.connection.list.fetchStatus,
    (fetchStatus) => fetchStatus
  ),
  getConnections: createSelector(
    (state: RootState) => state.connection.list.connections,
    (connections) => connections
  ),
  getPager: createSelector(
    (state: RootState) => state.connection.list.pager,
    (pager) => pager
  ),
}

export type State = {
  fetchStatus: FetchStatus
  connections: Connection[]
  pager: PaginationMeta | null
}

const initialState: State = {
  fetchStatus: FetchStatus.none,
  connections: [],
  pager: null,
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.reset, () => Object.assign({}, initialState))
  .case(actions.fetch.started, (state) =>
    produce(state, (draft) => {
      draft.fetchStatus = FetchStatus.loading
      draft.connections = []
      draft.pager = null
    })
  )
  .case(actions.fetch.done, (state, payload) =>
    produce(state, (draft) => {
      draft.fetchStatus = FetchStatus.loaded
      draft.connections = payload.result.connections
      draft.pager = payload.result.pager
    })
  )
  .case(actions.fetch.failed, (state) =>
    produce(state, (draft) => {
      draft.fetchStatus = FetchStatus.failed
    })
  )

export const sagas = [
  takeLatest(actions.fetch.started, function* (action) {
    try {
      const { page } = action.payload
      const payload: Pagination<Connection> = yield call(
        (_page: number) => service.getList(_page),
        Number.parseInt(page)
      )
      yield put(
        actions.fetch.done({
          result: {
            connections: payload.items,
            pager: payload.paginationMeta,
          },
          params: action.payload,
        })
      )
    } catch (e) {
      yield put(actions.fetch.failed(e))
    }
  }),
]
