import * as React from 'react'

import { apiClients } from '~/common/apiClients'

const organizationService = apiClients.organizationService

export function useCreateOrganization() {
  const [creating, setCreating] = React.useState(false)

  const createOrganization = React.useCallback(async (name: string) => {
    setCreating(true)
    try {
      await organizationService.create(name)
    } finally {
      setCreating(false)
    }
  }, [])
  return { creating, createOrganization }
}
