import styled from '@emotion/styled'
import React, { CSSProperties } from 'react'
import { Repeat } from 'react-feather'

import * as vars from '~/styles/variables'

const buttonSize = 30

interface Props {
  disabled?: boolean
  onClick: React.MouseEventHandler<HTMLDivElement>
  style?: CSSProperties
}

const NodeChangeButton: React.FC<Props> = ({ disabled, onClick, style }) => {
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (disabled) {
        return
      }
      onClick(event)
    },
    [disabled, onClick]
  )
  return (
    <Container disabled={disabled ?? false} style={style} onClick={handleClick}>
      <Repeat size={13} color={vars.fontColor.tertiary} strokeWidth={3} />
    </Container>
  )
}

const Container = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: buttonSize,
    height: buttonSize,
    borderRadius: buttonSize / 2,
    backgroundColor: vars.color.lightGray,
  },
  (props: { disabled: boolean }) => ({
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    opacity: props.disabled ? 0.5 : 0.7,
    '&:hover': {
      opacity: props.disabled ? 0.5 : 1,
    },
  })
)

export default NodeChangeButton
