import { ValidationFunction } from '~/domain/workflow/validator/ValidationFunction'
import { ValidationResult } from '~/domain/workflow/validator/ValidationResult'

export class CompositeValidator {
  private validations: Set<ValidationFunction> = new Set()

  static isInvalid(result: ValidationResult): boolean {
    return !result.valid
  }

  async validate(): Promise<ValidationResult[]> {
    const results: Promise<ValidationResult>[] = []
    this.validations.forEach((validation) => {
      results.push(validation())
    })
    return Promise.all(results)
  }

  register(validation: ValidationFunction) {
    this.validations.add(validation)
  }

  unregister(validation: ValidationFunction) {
    this.validations.delete(validation)
  }
}
