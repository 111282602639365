import * as React from 'react'

import { apiClients } from '~/common/apiClients'
import { FetchStatus } from '~/common/types'
import { WorkflowInstance } from '~/domain/workflow/instance/WorkflowInstance'
import { WorkflowInstanceService } from '~/domain/workflow/instance/WorkflowInstanceService'

const workflowInstanceService: WorkflowInstanceService =
  apiClients.workflowInstanceService

export function useWorkflowInstance(
  instanceId: string
): {
  workflowInstance?: WorkflowInstance
  refetch: (suppress?: boolean) => void
  fetchStatus: FetchStatus
} {
  const [workflowInstance, setWorkflowInstance] = React.useState<
    WorkflowInstance
  >()
  const [fetchStatus, setFetchStatus] = React.useState<FetchStatus>(
    FetchStatus.none
  )

  React.useEffect(() => {
    fetchWorkflowInstanceSummaries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceId])

  const fetchWorkflowInstanceSummaries = React.useCallback(
    (suppress: boolean = false) => {
      if (!suppress) {
        setFetchStatus(FetchStatus.loading)
      }
      workflowInstanceService
        .get(instanceId)
        .then((it) => {
          setFetchStatus(FetchStatus.loaded)
          setWorkflowInstance(it)
        })
        .catch((reason) => {
          setFetchStatus(FetchStatus.failed)
          console.error(`Failed to fetch workflow instance: ${reason}`)
        })
    },
    [instanceId]
  )

  return {
    workflowInstance,
    refetch: fetchWorkflowInstanceSummaries,
    fetchStatus,
  }
}
