import { apiClients } from '~/common/apiClients'
import { WorkflowService } from '~/domain/workflow/WorkflowService'

const service: WorkflowService = apiClients.workflowService

export function useExecuteWorkflow(): {
  executeWorkflow: (workflowId: string) => Promise<string>
} {
  const executeWorkflow = (workflowId: string): Promise<string> => {
    return service
      .update(workflowId, { isEnabled: true })
      .then(() => service.execute(workflowId))
  }
  return { executeWorkflow }
}
