import * as _ from 'lodash'

import { JsonError } from '~/data/JsonError'
import { Tson } from '~/data/Tson'
import {
  InputSourceJson,
  InputValueJson,
  RawInputValueJson,
  RawStructEntryJson,
  RenderInputValueJson,
  TaskSourceJson,
  TriggerSourceJson,
  WorkflowSourceBodyJson,
} from '~/data/workflow/source/json'

export function mapToWorkflowSourceBodyJson(
  tson: Tson
): WorkflowSourceBodyJson {
  const rootTask = tson.getValue('task', false)
  const trigger = tson.getValue('trigger', false)
  return {
    task: rootTask ? mapToTaskSourceJson(rootTask) : undefined,
    trigger: trigger ? mapToTriggerSourceJson(trigger) : undefined,
  }
}

function mapToTaskSourceJson(tson: Tson): TaskSourceJson {
  return {
    taskId: tson.getString('taskId'),
    actionId: tson.getString('actionId'),
    inputs: tson.getArray('inputs').map(mapToInputSourceJson),
    tasks: tson.getArray('tasks').map(mapToTaskSourceJson),
  }
}

function mapToTriggerSourceJson(tson: Tson): TriggerSourceJson {
  return {
    triggerId: tson.getString('triggerId'),
    inputs: tson.getArray('inputs').map(mapToInputSourceJson),
  }
}

function mapToInputSourceJson(tson: Tson): InputSourceJson {
  const value = tson.getValue('value', false)
  return {
    key: tson.getString('key'),
    value:
      value !== undefined && value !== null ? mapToInputValueJson(value) : null,
  }
}

function mapToInputValueJson(tson: Tson): InputValueJson {
  if (tson.asIs() === undefined || tson.asIs() === null) {
    return null
  }
  const mode = tson.getString('mode')
  switch (mode) {
    case 'raw':
      return mapToRawInputValueJson(tson)
    case 'render':
      return mapToRenderInputValueJson(tson)
    default:
      throw new JsonError(`Unknown input value mode: ${mode}`)
  }
}

function mapToRawInputValueJson(tson: Tson): RawInputValueJson {
  const raw = tson.getValue('raw').asIs()
  if (
    typeof raw !== 'string' &&
    typeof raw !== 'number' &&
    typeof raw !== 'boolean' &&
    typeof raw !== 'object'
  ) {
    throw new JsonError(`Unexpected raw value type: ${typeof raw}`)
  }
  if (raw === null) {
    throw new JsonError(`Unexpected raw value: null`)
  }
  if (typeof raw !== 'object') {
    // string | number | boolean
    return {
      mode: 'raw',
      raw: raw,
    }
  }
  if (!_.isArray(raw)) {
    throw new JsonError(
      'Unexpected raw value: Raw Value must be an array if it is not a string, number or boolean.'
    )
  }
  try {
    return {
      mode: 'raw',
      raw: raw.map((it) => mapToInputValueJson(new Tson(it))),
    }
  } catch {
    // エラーなら RawValueEntryJson に変換できるか試してみる
  }
  try {
    return {
      mode: 'raw',
      raw: raw.map((it) => mapToRawStructEntryJson(new Tson(it))),
    }
  } catch {
    // どっちでもエラーになればエラー
    throw new JsonError(
      `Unexpected raw value: Raw value must be an array of InputValue or an array of keyed InputValue`
    )
  }
}

function mapToRawStructEntryJson(tson: Tson): RawStructEntryJson {
  const value = tson.getValue('value', false)
  return {
    key: tson.getString('key'),
    value:
      value === undefined || value === null ? null : mapToInputValueJson(value),
  }
}

function mapToRenderInputValueJson(tson: Tson): RenderInputValueJson {
  return {
    mode: 'render',
    template: tson.getString('template'),
  }
}
