import { buildPagerQuery } from '~/api'
import { Pagination } from '~/common/Pagination'
import config from '~/common/config'
import { Tson } from '~/data/Tson'
import requestJson from '~/data/request'
import { mapToRunningStatus } from '~/data/workflow/instance/mapToRunningStatus'
import { TaskInstanceService } from '~/domain/workflow/instance/taskInstance/TaskInstanceService'
import { TaskInstanceSummary } from '~/domain/workflow/instance/taskInstance/TaskInstanceSummary'

// Json にマッピングしてから、それをドメインモデルにマッピングするようにしたいので名前は Temp

export class TempTaskInstanceService implements TaskInstanceService {
  async getList(
    workflowInstanceId: string,
    page?: number | string,
    limit?: number
  ): Promise<Pagination<TaskInstanceSummary>> {
    const pagerQuery = buildPagerQuery(page, limit)
    const res = await requestJson(
      `${
        config.apiRoot
      }/task_instances?workflow_instances=${workflowInstanceId}${
        pagerQuery ? '&' + pagerQuery : ''
      }`
    )
    const tson = new Tson(res)
    return {
      items: tson.getArray('results').map(mapToTaskInstance),
      paginationMeta: {
        currentPageNumber: tson.getNumber('current'),
        totalCount: tson.getNumber('count'),
        countPerPage: tson.getNumber('limit'),
      },
    }
  }
}

function mapToTaskInstance(tson: Tson): TaskInstanceSummary {
  return {
    state: mapToRunningStatus(tson.getString('state')),
    taskId: tson.getString('taskId'),
    taskInstanceId: tson.getString('slug'),
  }
}
