import produce from 'immer'
import { createSelector } from 'reselect'
import { actionCreatorFactory } from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { RootState } from '~/ducks'

const actionCreator = actionCreatorFactory('anyflow/ui/editor')

export const types = {
  RESET: 'RESET',
  SET_SCALE: 'SET_SCALE',
  SET_TX: 'SET_TX',
  SET_TY: 'SET_TY',
  ADD_TX: 'ADD_TX',
  ADD_TY: 'ADD_TY',
  SET_TREE_SIZE: 'SET_TREE_SIZE',
}

export const actions = {
  reset: actionCreator(types.RESET),
  setScale: actionCreator<number>(types.SET_SCALE),
  setTx: actionCreator<number>(types.SET_TX),
  setTy: actionCreator<number>(types.SET_TY),
  addTx: actionCreator<number>(types.ADD_TX),
  addTy: actionCreator<number>(types.ADD_TY),
  setTreeSize: actionCreator<{ width: number; height: number }>(
    types.SET_TREE_SIZE
  ),
}

export const selectors = {
  getScale: createSelector(
    (state: RootState) => state.ui.editor.scale,
    (scale) => scale
  ),
  getTx: createSelector(
    (state: RootState) => state.ui.editor.tx,
    (tx) => tx
  ),
  getTy: createSelector(
    (state: RootState) => state.ui.editor.ty,
    (ty) => ty
  ),
  getTreeSize: createSelector(
    (state: RootState) => state.ui.editor.treeSize,
    (treeSize) => treeSize
  ),
}

export type State = {
  /** エディタ全体の拡大比率 */
  scale: number
  /** エディタ全体のXの移動量 */
  tx: number
  /** エディタ全体のYの移動量 */
  ty: number
  /** タスクツリーの全体サイズ */
  treeSize: {
    width: number
    height: number
  }
}

const initialState: State = {
  scale: 1,
  tx: 0,
  ty: 0,
  treeSize: {
    width: 500,
    height: 300,
  },
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.reset, () => initialState)
  .case(actions.setScale, (state, payload) =>
    produce(state, (draft) => {
      draft.scale = payload
    })
  )
  .case(actions.setTx, (state, payload) =>
    produce(state, (draft) => {
      draft.tx = payload
    })
  )
  .case(actions.setTy, (state, payload) =>
    produce(state, (draft) => {
      draft.ty = payload
    })
  )
  .case(actions.addTx, (state, payload) =>
    produce(state, (draft) => {
      draft.tx = draft.tx + payload
    })
  )
  .case(actions.addTy, (state, payload) =>
    produce(state, (draft) => {
      draft.ty = draft.ty + payload
    })
  )
  .case(actions.setTreeSize, (state, payload) =>
    produce(state, (draft) => {
      draft.treeSize = payload
    })
  )
