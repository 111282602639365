import { TestResultRepository } from '~/domain/workflow/test/TestResultRepository'
import { WorkflowTestResult } from '~/domain/workflow/test/WorkflowTestResult'

export class MemoryTestResultRepository implements TestResultRepository {
  static readonly INSTANCE = new MemoryTestResultRepository()

  private data: { [workflowId: string]: WorkflowTestResult } = {}

  private constructor() {}

  async find(workflowId: string): Promise<WorkflowTestResult | undefined> {
    return this.data[workflowId]
  }

  async remove(workflowId: string): Promise<void> {
    delete this.data[workflowId]
  }

  async save(workflowTestResult: WorkflowTestResult): Promise<void> {
    this.data[workflowTestResult.workflowId] = workflowTestResult
  }
}
