import styled from '@emotion/styled'
import * as React from 'react'

import Avatar from '~/components/atoms/Avatar'
import Text from '~/components/atoms/Text'
import * as vars from '~/styles/variables'

interface Props {
  username: string
  fontWeight?: keyof typeof vars.fontWeight
  className?: string
}

const User: React.FC<Props> = ({ username, fontWeight = 'normal' }) => (
  <Container>
    <Avatar size="s" />
    <Text
      element="span"
      fontSize="s"
      fontWeight={fontWeight}
      lineHeight="just"
      style={{
        marginLeft: vars.space.s,
      }}
    >
      {username}
    </Text>
  </Container>
)

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

export default User
