import styled from '@emotion/styled'
import * as React from 'react'
import ContentLoader from 'react-content-loader'

import * as vars from '~/styles/variables'

const BadgeTitle: React.FC = () => (
  <ContentLoader
    style={{ width: 100 + 300 }}
    speed={1}
    width={100 + 300}
    height={22}
  >
    {/* バッジ */}
    <rect x={0} y={0} rx="4" ry="4" width="56" height="22" />
    {/* タイトル */}
    <rect x={100} y="2" rx="2" ry="2" width="300" height={18} />
  </ContentLoader>
)

const RunnerDate: React.FC<{ className?: string }> = ({ className }) => (
  <ContentLoader
    className={className}
    // 右に空白を50あける
    style={{ width: 160 + 140 + 50 }}
    speed={1}
    width={160 + 140 + 50}
    height={22}
  >
    {/* 日時 */}
    <rect x={160} y="4" rx="2" ry="2" width="140" height={16} />
  </ContentLoader>
)

interface Props {
  className?: string
}

const Loader: React.FC<Props> = ({ className }) => (
  <div
    className={className}
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      height: 22,
    }}
  >
    <BadgeTitle />
    <_RunnerDate />
  </div>
)

const _RunnerDate = styled(RunnerDate)({
  marginLeft: vars.space.m,
})

const LoaderList: React.FC = () => (
  <>
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
    <_Loader />
  </>
)

const _Loader = styled(Loader)({
  marginTop: 17,
  '&:first-of-type': {
    marginTop: 0,
  },
})

export default LoaderList
