import React from 'react'
import Helmet from 'react-helmet'

import * as vars from '~/styles/variables'

const DEFAULT_TILE_COUNT = 2
const DEFAULT_SPEED = 30 // [px/s]

interface Props {
  tileCount?: number
  speed?: number // [px/s]
  reverse?: boolean
  style?: React.CSSProperties
}

const LoopMarquee: React.FC<Props> = ({
  speed = DEFAULT_SPEED,
  tileCount = DEFAULT_TILE_COUNT,
  ...props
}) => {
  const salt = React.useRef<string>(
    Math.floor(Math.random() * 100000).toString(10)
  )
  const containerRef = React.useRef<HTMLDivElement>(null)
  const ref = React.useRef<HTMLDivElement>(null)
  const [animationDuration, setAnimationDuration] = React.useState<number>(0)
  const [animationPause, setAnimationPause] = React.useState(false)

  // HACK: safariアニメーションバグ対応
  // safariの場合要素がリサイズされた際に発生するレイアウト処理が割り込まれず，
  // 一度アニメーションのキューを解消するために一時的にアニメーションを停止する．
  React.useEffect(() => {
    if (window.matchMedia === undefined) {
      return
    }
    const { sp, tablet } = vars.media
    const spMediaQuery = window.matchMedia(sp.replace('@media ', ''))
    const tabletMediaQuery = window.matchMedia(tablet.replace('@media ', ''))
    // NOTE: safariではMediaQueryList. addEventListener/removeEventListener がサポートされていないため，
    // deprecatedではあるが addListener / removeListener を使用
    if (
      spMediaQuery.addListener === undefined ||
      spMediaQuery.removeListener === undefined ||
      tabletMediaQuery.addListener === undefined ||
      tabletMediaQuery.removeListener === undefined
    ) {
      return
    }
    const refreshAnimation = () => {
      setAnimationPause(true)
      setTimeout(() => setAnimationPause(false), 100)
    }
    spMediaQuery.addListener(refreshAnimation)
    tabletMediaQuery.addListener(refreshAnimation)
    return () => {
      spMediaQuery.removeListener(refreshAnimation)
      tabletMediaQuery.removeListener(refreshAnimation)
    }
  }, [])

  React.useEffect(() => {
    if (ref.current === null) {
      return
    }
    const observer = new ResizeObserver((entries) => {
      if (entries.length < 1) {
        return
      }
      const element = entries[0].target
      const distance = element.getBoundingClientRect().width / tileCount
      const duration = distance / speed
      setAnimationDuration(duration)
    })
    observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
  }, [tileCount, speed])

  return (
    <div
      ref={containerRef}
      style={{
        ...props.style,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '100%',
      }}
    >
      <Helmet>
        <style>{`
          @keyframes loop_marquee_${salt.current} {
            0% { transform: translateX(0px) }
            100% { transform: translateX(-${100 / tileCount}%) }
          }
        `}</style>
      </Helmet>
      <div
        ref={ref}
        style={{
          display: 'inline-flex',
          width: 'auto',
          animation: `loop_marquee_${
            salt.current
          } ${animationDuration}s infinite linear ${
            props.reverse ? 'reverse' : ''
          } ${animationPause ? 'paused' : ''}`,
        }}
      >
        {new Array(tileCount).fill(0).map((value, i) => (
          <div key={i}>{props.children}</div>
        ))}
      </div>
    </div>
  )
}

export default LoopMarquee
