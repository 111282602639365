import { JsonError } from '~/data/JsonError'
import { Tson } from '~/data/Tson'
import { mapToGroupMetaJson } from '~/data/group/mapToGroupJson'
import { PermissionJson } from '~/data/workflow/permission/PermissionJson'
import { PermissionPolicyJson } from '~/data/workflow/permission/PermissionPolicyJson'

export function mapToPermissionJson(tson: Tson): PermissionJson {
  return {
    slug: tson.getString('slug'),
    permissionPolicy: mapToPermissionPolicyJson(
      tson.getValue('permissionPolicy')
    ),
    group: mapToGroupMetaJson(tson.getValue('group')),
    updatedAt: tson.getString('updatedAt'),
  }
}

export function mapToPermissionPolicyJson(tson: Tson): PermissionPolicyJson {
  switch (tson.asString()) {
    case 'readable':
      return 'readable'
    case 'updatable':
      return 'updatable'
    case 'deletable':
      return 'deletable'
    case 'ownable':
      return 'ownable'
    default:
      throw new JsonError(`Unknown policy: ${tson.asString()}`)
  }
}
