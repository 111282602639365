import styled from '@emotion/styled'
import * as React from 'react'

import ErrorMessage from '~/components/atoms/ErrorMessage'
import TextField from '~/components/atoms/TextField'
import { TextWidgetDefinition } from '~/domain/workflow/widget/text'
import { useFollowState } from '~/hooks/useFollowState'
import InputContainer from '~/presentation/workflow/detail/editor/form/InputContainer'
import { RawInputWidgetProps } from '~/presentation/workflow/detail/editor/form/inputWidget/RawInputWidget'
import WidgetTypeSign from '~/presentation/workflow/detail/editor/form/inputWidget/WidgetTypeSign'
import { useInputWidgetValidation } from '~/presentation/workflow/detail/editor/form/validation/useValidation'
import * as vars from '~/styles/variables'
import { borderWidth } from '~/styles/widget'

interface Props extends RawInputWidgetProps {
  definition: TextWidgetDefinition
}

const TextWidget: React.FC<Props> = (props) => {
  const value: string = React.useMemo(() => {
    if (props.value === undefined) {
      return ''
    }
    if (typeof props.value.raw === 'number') {
      return String(props.value.raw)
    }
    if (typeof props.value.raw !== 'string') {
      return ''
    }
    return props.value.raw
  }, [props.value])

  const [tempText, setTempText] = useFollowState(value)

  // バリデーション
  const validationResult = useInputWidgetValidation(props)

  const handleTextChange = (newText: string) => {
    setTempText(newText)
  }

  const handleBlur = () => {
    if (tempText === '') {
      props.onChange(undefined)
    } else {
      props.onChange({
        mode: 'raw',
        raw: tempText,
      })
    }
  }

  return (
    <Container>
      <InputContainer
        hasError={validationResult?.valid === false}
        renderable={props.renderable}
        disabled={props.readonly}
      >
        <_WidgetTypeSign valueType={props.valueType} />
        <_TextField
          pattern={props.definition.pattern}
          placeholder={props.definition.placeholder}
          disabled={props.readonly}
          value={tempText}
          hasBorder={false}
          onChange={(e) => handleTextChange(e.currentTarget.value)}
          onBlur={handleBlur}
        />
      </InputContainer>
      {validationResult?.valid === false && (
        <ErrorMessage style={{ marginTop: vars.space.s }}>
          {validationResult.cause.message}
        </ErrorMessage>
      )}
    </Container>
  )
}

const Container = styled('div')({
  width: '100%',
})

const _WidgetTypeSign = styled(WidgetTypeSign)({
  flexShrink: 0,
})

const _TextField = styled(TextField)({
  height: vars.height.field - borderWidth * 2,
})

export default TextWidget
