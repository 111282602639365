import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import React, { CSSProperties } from 'react'
import { animated, useSpring } from 'react-spring'

import { delay } from '~/common/utils'
import * as vars from '~/styles/variables'

interface Props {
  value: boolean
  loading?: boolean
  disabled?: boolean
  style?: CSSProperties
  onChange: (value: boolean) => void
}

const Switch: React.FC<Props> = (props) => {
  const containerProps = useSpring({
    backgroundColor: props.value
      ? vars.colorPalette.blue600
      : vars.colorPalette.gray3,
  })
  const stickProps = useSpring({
    transform: props.value
      ? `translateX(${width - 30 - 6}px)`
      : 'translateX(0px)',
  })
  const twinkleProps = useSpring({
    from: {
      backgroundColor: vars.color.white,
    },
    to: async (next: any) => {
      if (props.loading) {
        for (let i = 0; i < 20; i++) {
          await next({
            backgroundColor: props.value
              ? vars.colorPalette.blue100
              : vars.colorPalette.gray1,
          })
          await next({
            backgroundColor: vars.color.white,
          })
          await delay(100)
        }
      } else {
        await next({
          backgroundColor: vars.color.white,
        })
      }
    },
    config: {
      tension: 600,
    },
  })
  const onLabelProps = useSpring({
    opacity: props.value ? 1 : 0,
  })
  const offLabelProps = useSpring({
    opacity: props.value ? 0 : 1,
  })
  return (
    <Container
      role="switch"
      aria-checked={props.value}
      style={{ ...props.style, ...containerProps }}
      isLoading={props.loading ?? false}
      disabled={props.disabled ?? false}
      onClick={() => {
        if (props.disabled || props.loading) {
          return
        }
        props.onChange(!props.value)
      }}
    >
      <OnLabel style={onLabelProps}>On</OnLabel>
      <OffLabel style={offLabelProps}>Off</OffLabel>
      <Stick style={{ ...stickProps, ...twinkleProps }} />
    </Container>
  )
}

const width = 70
const height = vars.height.switch

const Container = styled(animated.button, {
  shouldForwardProp: isPropValid,
})(
  {
    position: 'relative',
    width: width,
    height: height,
    borderRadius: height / 2,
    border: 'none',
  },
  (props: { isLoading: boolean; disabled: boolean }) => ({
    cursor: props.disabled || props.isLoading ? 'not-allowed' : 'pointer',
    opacity: props.disabled || props.isLoading ? 0.5 : 1,
  })
)

const OnLabel = styled(animated.div)({
  position: 'absolute',
  top: 10,
  left: 12,
  color: vars.color.white,
  fontSize: vars.fontSize.xs,
  fontWeight: vars.fontWeight.bold,
  textTransform: 'uppercase',
  userSelect: 'none',
})

const OffLabel = styled(animated.div)({
  position: 'absolute',
  top: 10,
  right: 9,
  color: vars.color.white,
  fontSize: vars.fontSize.xs,
  fontWeight: vars.fontWeight.bold,
  textTransform: 'uppercase',
  userSelect: 'none',
})

const Stick = styled(animated.div)({
  position: 'absolute',
  top: 3,
  left: 3,
  width: height - 6,
  height: height - 6,
  borderRadius: '50%',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
  backgroundColor: vars.color.white,
})

export default Switch
