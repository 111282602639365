import * as queryString from 'query-string'
import { useLocation } from 'react-router-dom'

export function usePageQuery(): number {
  const location = useLocation()
  const parsedQuery = queryString.parse(location.search)
  if (
    parsedQuery.page !== undefined &&
    Number.isInteger(Number(parsedQuery.page))
  ) {
    return Number(parsedQuery.page)
  } else {
    return 1
  }
}
