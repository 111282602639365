import escapeHtml from 'escape-html'
import * as React from 'react'

const MARKDOWN_STYLE_LINK_REGEX = /\[([^[\]]*)\]\(([^()]*)\)/g // This regex replace `[$1]($2)` to `<a href=$2>$1</a>`

export const useMarkedLink = (markdown: string): string => {
  const html = React.useMemo(() => {
    return escapeHtml(markdown).replace(
      MARKDOWN_STYLE_LINK_REGEX,
      "<a href='$2' target='_blank'>$1</a>"
    )
  }, [markdown])
  return html
}

export const useExtractedLinkLabel = (markdown: string): string => {
  const text = React.useMemo(() => {
    return escapeHtml(markdown).replace(MARKDOWN_STYLE_LINK_REGEX, '$1')
  }, [markdown])
  return text
}
