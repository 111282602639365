import styled from '@emotion/styled'
import * as React from 'react'

import * as vars from '~/styles/variables'

interface Props {
  className?: string
  children: React.ReactNode
}

const Tooltip: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { className, children },
  ref
) => {
  return (
    <Container ref={ref} className={className}>
      {children}
    </Container>
  )
}

const Container = styled('div')({
  paddingTop: vars.space.s,
  paddingRight: vars.space.m,
  paddingBottom: vars.space.s,
  paddingLeft: vars.space.m,
  color: vars.color.white,
  fontSize: vars.fontSize.xs,
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  borderRadius: vars.borderRadius.s,
  boxShadow: vars.shadow.s,
})

export default React.forwardRef(Tooltip)
