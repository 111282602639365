import * as React from 'react'
import isEmail from 'validator/lib/isEmail'

import Text from '~/components/atoms/Text'
import * as vars from '~/styles/variables'

const FREE_MAIL_DOMAINS = [
  /@gmail\.com/,
  /@yahoo\.co\.jp/,
  /@outlook\.jp/,
  /@outlook\.com/,
  /@hotmail\.co\.jp/,
  /@live\.jp/,
  /@mail\.ru/,
  /@tutanota\.com/,
  /@tutanota\.de/,
  /@tutamail\.com/,
  /@tuta\.io/,
  /@keemail\.me/,
  /@cock\.li/,
  /@protonmail\.ch/,
  /@protonmail\.com/,
  /@pm\.me/,
  /@icloud\.com/,
  // https://creazy.net/2019/08/mobile-carrier-domains.html
  /@ocn\.ne\.jp/,
  /@.+\.ocn\.ne\.jp/,
  /@ezweb\.ne\.jp/,
  /@biz\.ezweb\.ne\.jp/,
  /@augps\.ezweb\.ne\.jp/,
  /@ido\.ne\.jp/,
  /@uqmobile\.jp/,
  /@au\.com/,
  /@biz\.au\.com/,
  /@docomo\.ne\.jp/,
  /@mopera\.net/,
  /@disney\.ne\.jp/,
  /@disneymobile\.ne\.jp/,
  /@i\.softbank\.jp/,
  /@softbank\.ne\.jp/,
  /@vodafone\.ne\.jp/,
  /@emnet\.ne\.jp/,
  /@emobile\.ne\.jp/,
  /@emobile-s\.ne\.jp/,
  /@ymobile1\.ne\.jp/,
  /@ymobile\.ne\.jp/,
  /@yahoo\.ne\.jp/,
  /@pdx\.ne\.jp/,
  /@willcom\.com/,
  /@wcm\.ne\.jp/,
  /@y-mobile\.ne\.jp/,
  /@jp-c\.ne\.jp/,
  /@jp-d\.ne\.jp/,
  /@jp-h\.ne\.jp/,
  /@jp-k\.ne\.jp/,
  /@jp-n\.ne\.jp/,
  /@jp-q\.ne\.jp/,
  /@jp-r\.ne\.jp/,
  /@jp-s\.ne\.jp/,
  /@jp-t\.ne\.jp/,
  /@sky\.tkc\.ne\.jp/,
  /@sky\.tkk\.ne\.jp/,
  /@sky\.tu-ka\.ne\.jp/,
]

export const isNotFreeMail = (email: string) =>
  FREE_MAIL_DOMAINS.every((regex) => !email.match(regex))

export const isSubmittableEmail = (email: string) =>
  isEmail(email) && isNotFreeMail(email)

export const EmailValidationText: React.FC<{ mailAddress: string }> = ({
  mailAddress,
}) => {
  const message = React.useMemo(() => {
    if (!isEmail(mailAddress)) {
      return '有効なメールアドレスではありません。'
    }
    if (!isNotFreeMail(mailAddress)) {
      return 'フリーアドレスはご利用できません'
    }
    return null
  }, [mailAddress])

  if (!mailAddress) {
    return null
  }
  return (
    <>
      {message && (
        <Text
          element="p"
          fontSize="s"
          color={vars.color.error}
          style={{ marginTop: vars.space.s }}
        >
          {message}
        </Text>
      )}
    </>
  )
}
