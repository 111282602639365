import config, { Env, targetEnv } from '~/common/config'
import { Tson } from '~/data/Tson'
import requestJson, { HttpError } from '~/data/request'
import { sanitize } from '~/data/utils'
import { mapJsonToActionDefinition } from '~/data/workflow/action/mapJsonToActionDefinition'
import { mapToActionJson } from '~/data/workflow/action/mapToActionJson'
import { ActionDefinition } from '~/domain/workflow/action/ActionDefinition'
import { ActionService } from '~/domain/workflow/action/ActionService'
import { ActionState } from '~/domain/workflow/action/ActionState'
import { EMPTY_ACTION_ID } from '~/ducks/editor/types'

export class ActionServiceImpl implements ActionService {
  async get(actionId: string): Promise<ActionDefinition | undefined> {
    try {
      const res = await requestJson(
        `${config.apiRoot}/actions/${sanitize(actionId)}`
      )
      const json = mapToActionJson(new Tson(res))
      return mapJsonToActionDefinition(json)
    } catch (e) {
      if (e instanceof HttpError && e.statusCode === 404) {
        return undefined
      }
      throw e
    }
  }

  async getAll(): Promise<ActionDefinition[]> {
    const res = await requestJson(`${config.apiRoot}/actions`)
    const jsons = new Tson(res).asArray().map(mapToActionJson)
    const definitions = [...jsons.map(mapJsonToActionDefinition), emptyAction]
    if (targetEnv === Env.local) {
      definitions.push(devAction)
    }
    return definitions
  }
}

// これを削除するには WorkflowSource をそのまま Tree に渡して描画しているのをやめる必要がある
// 本来の WorkflowSource には EmptyAction という概念はないため。
const emptyAction: ActionDefinition = {
  actionId: EMPTY_ACTION_ID,
  appId: 'GenericApp',
  description: '',
  fields: [],
  name: '空',
  testable: false,
  state: ActionState.available,
}

export const devAction: ActionDefinition = {
  actionId: '__dev',
  name: '開発用アクション',
  appId: '__DevelopApp',
  description: '開発用アクション',
  testable: true,
  state: ActionState.available,
  fields: [
    {
      key: 'salesforce_account_uid',
      fieldType: 'static',
      dependencies: [],
      required: true,
      renderable: false,
      bulkable: false,
      label: 'Salesforce アカウント',
      note: '',
      valueType: {
        typeName: 'string',
        nullable: false,
      },
      form: {
        formType: 'text',
      },
    },
    {
      key: 'salesforce_sobject_name',
      fieldType: 'static',
      dependencies: [],
      required: true,
      renderable: false,
      bulkable: false,
      label: 'sObject 名',
      note: '',
      valueType: {
        typeName: 'string',
        nullable: false,
      },
      form: {
        formType: 'text',
      },
    },
    {
      key: 'salesforce_search_condition',
      fieldType: 'static',
      bulkable: false,
      dependencies: ['salesforce_account_uid', 'salesforce_sobject_name'],
      renderable: false,
      required: true,
      label: 'Salesforce 検索条件',
      note: 'Salesforce 検索条件ウィジェットです',
      valueType: {
        typeName: 'struct',
        nullable: false,
        entries: [
          {
            key: 'combination_type',
            valueType: {
              typeName: 'string', // "and" or "or"
              nullable: false,
            },
          },
          {
            key: 'condition_groups',
            valueType: {
              typeName: 'list',
              nullable: false,
              itemType: {
                typeName: 'struct',
                nullable: false,
                entries: [
                  {
                    key: 'combination_type',
                    valueType: {
                      typeName: 'string', // "and" or "or"
                      nullable: false,
                    },
                  },
                  {
                    key: 'conditions',
                    valueType: {
                      typeName: 'list',
                      nullable: false,
                      itemType: {
                        typeName: 'struct',
                        nullable: false,
                        entries: [
                          {
                            key: 'field',
                            valueType: {
                              typeName: 'string',
                              nullable: false,
                            },
                          },
                          {
                            key: 'operator',
                            valueType: {
                              typeName: 'string', // "==" | "!=" | "<" | "<=" | ">" | ...
                              nullable: false,
                            },
                          },
                          {
                            key: 'operand',
                            valueType: {
                              typeName: 'any', // オペレータによって何が入るか変わるので any
                              nullable: false,
                            },
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            },
          },
        ],
      },
      form: {
        formType: 'salesforce_search_condition',
        accountUidFieldKey: 'salesforce_account_uid',
        sobjectNameFieldKey: 'salesforce_sobject_name',
        canUseStateOperators: true,
      },
    },
    {
      key: 'date_range',
      fieldType: 'static',
      bulkable: false,
      dependencies: [],
      renderable: false,
      required: true,
      label: '日付の範囲',
      note: '日付の範囲用ウィジェットです',
      valueType: {
        typeName: 'struct',
        nullable: false,
        entries: [
          {
            key: 'start',
            valueType: {
              typeName: 'number',
              canDecimal: false,
              nullable: false,
            },
          },
          {
            key: 'end',
            valueType: {
              typeName: 'number',
              canDecimal: false,
              nullable: false,
            },
          },
        ],
      },
      form: {
        formType: 'date_range',
        startLimit: -7,
        endLimit: 7,
      },
    },
    {
      key: 'file',
      fieldType: 'static',
      bulkable: false,
      dependencies: [],
      renderable: true,
      required: false,
      label: 'ファイル',
      note: 'ファイル入力用テキストウィジェットです',
      valueType: {
        typeName: 'file',
        nullable: false,
      },
      form: {
        formType: 'text',
      },
    },
    {
      key: 'struct',
      fieldType: 'static',
      bulkable: true,
      dependencies: [],
      renderable: true,
      required: true,
      label: '構造ウィジェット',
      note: 'ストラクトウィジェットです',
      valueType: {
        typeName: 'struct',
        nullable: false,
        entries: [
          {
            key: 'name',
            valueType: {
              typeName: 'string',
              nullable: false,
            },
          },
          {
            key: 'age',
            valueType: {
              typeName: 'number',
              canDecimal: false,
              nullable: false,
            },
          },
          {
            key: 'smoking',
            valueType: {
              typeName: 'boolean',
              nullable: false,
            },
          },
        ],
      },
      form: {
        formType: 'struct',
        entries: [
          {
            key: 'name',
            label: '名前',
            valueForm: {
              formType: 'text',
            },
          },
          {
            key: 'age',
            label: '年齢',
            valueForm: {
              formType: 'number',
              minValue: 0,
              step: 1,
            },
          },
          {
            key: 'smoking',
            label: '喫煙',
            valueForm: {
              formType: 'switch',
              label: 'はい',
            },
          },
        ],
      },
    },
    {
      key: 'select',
      fieldType: 'static',
      bulkable: false,
      dependencies: [],
      renderable: false,
      required: true,
      label: 'セレクトボックス',
      note: 'セレクトボックスです',
      valueType: {
        typeName: 'list',
        nullable: true,
        itemType: {
          typeName: 'number',
          canDecimal: false,
          nullable: false,
        },
      },
      form: {
        formType: 'select',
        options: [
          { label: 'one', value: null },
          { label: 'two', value: [1, 2, 3] },
          { label: 'three', value: [1, 2, 3, 4, 5] },
        ],
      },
    },
    {
      key: 'list',
      fieldType: 'static',
      bulkable: true,
      dependencies: [],
      renderable: true,
      required: true,
      label: 'リスト',
      note: 'リストです',
      valueType: {
        typeName: 'list',
        nullable: true,
        itemType: {
          typeName: 'string',
          nullable: false,
        },
      },
      form: {
        formType: 'list',
        itemForm: {
          formType: 'text',
        },
      },
    },
    {
      key: 'number',
      fieldType: 'static',
      bulkable: false,
      dependencies: [],
      renderable: false,
      required: true,
      label: '数値',
      note: '数値です',
      valueType: {
        typeName: 'number',
        canDecimal: true,
        nullable: false,
      },
      form: {
        formType: 'number',
      },
    },
    {
      key: 'multiline_text',
      fieldType: 'static',
      label: '複数行テキスト',
      note: 'マルチラインテキストです',
      required: true,
      renderable: true,
      bulkable: true,
      dependencies: [],
      valueType: {
        typeName: 'string',
        nullable: false,
      },
      form: {
        formType: 'multiline_text',
      },
    },
    {
      key: 'text',
      fieldType: 'static',
      label: 'テキスト',
      note: 'テキストです',
      required: true,
      renderable: true,
      bulkable: true,
      dependencies: [],
      valueType: {
        typeName: 'string',
        nullable: false,
      },
      form: {
        formType: 'text',
      },
    },
    {
      key: 'multiline_text_2',
      fieldType: 'static',
      label: '複数行テキスト（変数不可）',
      note: '変数不可のマルチラインテキストです',
      required: true,
      renderable: false,
      bulkable: true,
      dependencies: [],
      valueType: {
        typeName: 'string',
        nullable: false,
      },
      form: {
        formType: 'multiline_text',
      },
    },
    {
      key: 'text_2',
      fieldType: 'static',
      label: 'テキスト（変数不可）',
      note: '変数不可のテキストです',
      required: true,
      renderable: false,
      bulkable: true,
      dependencies: [],
      valueType: {
        typeName: 'string',
        nullable: false,
      },
      form: {
        formType: 'text',
      },
    },
    {
      key: 'switch',
      fieldType: 'static',
      label: 'スイッチ',
      note: 'スイッチです',
      required: true,
      renderable: false,
      bulkable: false,
      dependencies: [],
      valueType: {
        typeName: 'boolean',
        nullable: false,
      },
      form: {
        formType: 'switch',
        label: 'ラベル',
      },
    },
    {
      key: 'assist',
      fieldType: 'static',
      label: 'アシスト',
      note: 'アシストです',
      required: true,
      renderable: true,
      bulkable: true,
      dependencies: [
        'struct',
        'select',
        'list',
        'number',
        'multiline_text',
        'text',
        'switch',
      ],
      valueType: {
        typeName: 'string',
        nullable: false,
      },
      form: {
        formType: 'assist',
        assist: {
          assistId: 'foo',
          parameterFieldKeys: [
            'struct',
            'select',
            'list',
            'number',
            'multiline_text',
            'text',
            'switch',
          ],
          queryPlaceholder: '入力して下さい...',
        },
        fallbackForm: {
          formType: 'text',
        },
      },
    },
    {
      key: 'cron',
      fieldType: 'static',
      label: 'スケジュール',
      note: 'Cron ウィジェットです',
      required: true,
      renderable: false,
      bulkable: false,
      dependencies: [],
      valueType: {
        typeName: 'string',
        nullable: false,
      },
      form: {
        formType: 'cron',
      },
    },
    {
      key: 'list-cron',
      fieldType: 'static',
      bulkable: false,
      dependencies: [],
      renderable: false,
      required: true,
      label: 'リスト cron',
      note: 'リストです',
      valueType: {
        typeName: 'list',
        nullable: true,
        itemType: {
          typeName: 'string',
          nullable: false,
        },
      },
      form: {
        formType: 'list',
        itemForm: {
          formType: 'cron',
        },
      },
    },
  ],
}
