import styled from '@emotion/styled'
import * as React from 'react'
import { animated, useChain, useSpring } from 'react-spring'

import MyLink from '~/components/atoms/Link'
import ArrowButton from '~/containers/Home/ArrowButton'
import * as routes from '~/routes'
import * as vars from '~/styles/variables'

const Masthead: React.FC = () => {
  const leftRef = React.useRef(null)
  const rightRef = React.useRef(null)
  const leftProps = useSpring({
    ref: leftRef,
    from: { transform: 'translateY(30px)', opacity: 0 },
    to: { transform: 'translateY(0)', opacity: 1 },
  })
  const rightProps = useSpring({
    ref: rightRef,
    from: { transform: 'translateY(30px)', opacity: 0 },
    to: { transform: 'translateY(0)', opacity: 1 },
  })
  useChain([leftRef, rightRef], [0.5, 0.9])

  return (
    <Container>
      <Content>
        <HeadingArea style={leftProps}>
          <Heading>
            ビジネスの成長は、
            <br />
            自動化から生まれる。
          </Heading>
          <Subheading>
            人にしかできない仕事にフォーカスを当てる。
            <TabletBr />
            業務を最適化し、自動化します。
          </Subheading>
        </HeadingArea>
        <ImageArea style={rightProps}>
          <img
            alt=""
            src="/static/images/lp/hero@2x.png"
            style={{ width: '100%' }}
          />
        </ImageArea>
        <ButtonsArea style={leftProps}>
          <MyLink to={routes.SIGNIN} style={{ textDecoration: 'none' }}>
            <ArrowButton style={{ width: 280, height: 60 }}>
              サインイン
            </ArrowButton>
          </MyLink>
          <ButtonsAreaDescription>
            ※一般企業向けのAnyflowは現在新規受付を停止しております。
            <br />
            SaaS事業者向けiPaaSの
            <DescriptionLink
              href="https://anyflow.jp/products/embed"
              target="_blank"
              rel="noopener"
            >
              Anyflow Embed
            </DescriptionLink>
            をご検討ください。
          </ButtonsAreaDescription>
        </ButtonsArea>
      </Content>
    </Container>
  )
}

const Container = styled('div')({
  position: 'relative',
  marginTop: vars.height.lpHeader,
  paddingTop: vars.space.xl,
  '--content-width': '1200px',
  [vars.media.tablet]: {
    paddingTop: vars.space.l,
  },
})

const Content = styled('div')({
  display: 'grid',
  gridTemplateAreas: `
    "heading image"
    "buttons image"
  `,
  gridTemplateRows: '55% 45%',
  gridTemplateColumns: '1fr 48%',
  [vars.media.tablet]: {
    gridTemplateAreas: `
      "heading"
      "image"
      "buttons"
    `,
    gridTemplateRows: 'auto',
    gridTemplateColumns: '1fr',
  },
})

const HeadingArea = styled(animated.div)({
  gridArea: 'heading',
  marginLeft: 'max(0px, calc((100vw - var(--content-width)) / 2))',
  paddingLeft: vars.space.m,
  alignSelf: 'end',
  [vars.media.tablet]: {
    marginLeft: 0,
    paddingLeft: 0,
  },
})

const Heading = styled('h1')({
  margin: 0,
  fontSize: vars.fontSize.xxxxxl,
  fontWeight: 800,
  lineHeight: 1.4,
  [vars.media.tablet]: {
    fontSize: 30,
    textAlign: 'center',
  },
})

const Subheading = styled('p')({
  marginTop: 10,
  fontSize: 18,
  fontWeight: 500,
  lineHeight: 1.4,
  [vars.media.tablet]: {
    marginTop: 6,
    fontSize: 15,
    textAlign: 'center',
  },
})

const TabletBr = styled('br')({
  display: 'none',
  [vars.media.tablet]: {
    display: 'unset',
  },
})

const ImageArea = styled(animated.div)({
  gridArea: 'image',
  [vars.media.tablet]: {
    marginTop: vars.space.l,
  },
})

const ButtonsArea = styled(animated.div)({
  gridArea: 'buttons',
  marginLeft: 'max(0px, calc((100vw - var(--content-width)) / 2))',
  paddingLeft: vars.space.m,
  paddingTop: 50,
  [vars.media.tablet]: {
    marginLeft: 0,
    paddingLeft: 0,
    paddingTop: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
})

const ButtonsAreaDescription = styled('div')({
  marginTop: 10,
  color: vars.colorPalette.gray7,
  fontSize: vars.fontSize.m,
})

const DescriptionLink = styled('a')({
  color: vars.colorPalette.gray7,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
})

export default Masthead
