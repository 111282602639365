import * as React from 'react'

import { InputValue } from '~/domain/workflow/source/InputValue'
import { InputWidgetProps } from '~/presentation/workflow/detail/editor/form/inputWidget/InputWidget'
import AccountWidget from '~/presentation/workflow/detail/editor/form/inputWidget/account/AccountWidget'
import LegacyAccountWidget from '~/presentation/workflow/detail/editor/form/inputWidget/account/LegacyAccountWidget'
import { MultiProviderAccountWidget } from '~/presentation/workflow/detail/editor/form/inputWidget/account/MultiProviderAccountWidget'
import ConditionalAssistWidget from '~/presentation/workflow/detail/editor/form/inputWidget/assist/ConditionalAssistWidget'
import ConditionalMultiAssistWidget from '~/presentation/workflow/detail/editor/form/inputWidget/assist/ConditionalMultiAssistWidget'
import CronWidget from '~/presentation/workflow/detail/editor/form/inputWidget/cron/CronWidget'
import DateRangeWidget from '~/presentation/workflow/detail/editor/form/inputWidget/dateRange/DateRangeWidget'
import ListWidget from '~/presentation/workflow/detail/editor/form/inputWidget/list/ListWidget'
import NumberWidget from '~/presentation/workflow/detail/editor/form/inputWidget/number/NumberWidget'
import SalesforceSearchConditionWidget from '~/presentation/workflow/detail/editor/form/inputWidget/salesforceSearchCondition/SalesforceSearchConditionWidget'
import MultiSelectWidget from '~/presentation/workflow/detail/editor/form/inputWidget/select/MultiSelectWidget'
import SelectWidget from '~/presentation/workflow/detail/editor/form/inputWidget/select/SelectWidget'
import StructWidget from '~/presentation/workflow/detail/editor/form/inputWidget/struct/StructWidget'
import SwitchWidget from '~/presentation/workflow/detail/editor/form/inputWidget/switch/SwitchWidget'
import MultilineTextWidget from '~/presentation/workflow/detail/editor/form/inputWidget/text/MultilineTextWidget'
import TextWidget from '~/presentation/workflow/detail/editor/form/inputWidget/text/TextWidget'

export interface RawInputWidgetProps extends InputWidgetProps {
  value: InputValue.Raw | undefined
  onChange: (value: InputValue.Raw | undefined) => void
}

export function RawInputWidget(props: RawInputWidgetProps) {
  switch (props.definition.formType) {
    case 'text':
      return <TextWidget {...props} definition={props.definition} />
    case 'multiline_text':
      return <MultilineTextWidget {...props} definition={props.definition} />
    case 'list':
      return <ListWidget {...props} definition={props.definition} />
    case 'legacy_account':
      return <LegacyAccountWidget {...props} definition={props.definition} />
    case 'account':
      return <AccountWidget {...props} definition={props.definition} />
    case 'multi_provider_account':
      return (
        <MultiProviderAccountWidget {...props} definition={props.definition} />
      )
    case 'select':
      return <SelectWidget {...props} definition={props.definition} />
    case 'multi_select':
      return <MultiSelectWidget {...props} definition={props.definition} />
    case 'number':
      return <NumberWidget {...props} definition={props.definition} />
    case 'switch':
      return <SwitchWidget {...props} definition={props.definition} />
    case 'assist':
      return (
        <ConditionalAssistWidget {...props} definition={props.definition} />
      )
    case 'multi_assist':
      return (
        <ConditionalMultiAssistWidget
          {...props}
          definition={props.definition}
        />
      )
    case 'struct':
      return <StructWidget {...props} definition={props.definition} />
    case 'cron':
      return <CronWidget {...props} definition={props.definition} />
    case 'salesforce_search_condition':
      return (
        <SalesforceSearchConditionWidget
          {...props}
          definition={props.definition}
        />
      )
    case 'date_range':
      return <DateRangeWidget {...props} definition={props.definition} />
    default:
      return <p>未定義ウィジェット ({props.definition.formType})</p>
  }
}
