import { MeWithoutRoleJson } from '~/data/auth/MeWithoutRoleJson'
import { mapJsonToGroupMeta } from '~/data/group/mapJsonToGroup'
import { NoOrganizationError } from '~/data/user/NoOrganizationError'
import { mapJsonToOrganization } from '~/data/user/organization/mapJsonToOrganization'
import { MeWithoutRole } from '~/domain/auth/MeWithoutRole'

export function mapJsonToMeWithoutRole(json: MeWithoutRoleJson): MeWithoutRole {
  if (json.organization === null) {
    throw new NoOrganizationError()
  }
  return {
    email: json.email,
    id: json.slug,
    organization: mapJsonToOrganization(json.organization),
    username: json.username,
    belongingGroups: json.belongingGroups.map(mapJsonToGroupMeta),
  }
}
