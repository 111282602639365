import { Tson } from '~/data/Tson'
import { RecipeCategoryJson, RecipeJson } from '~/data/recipe/RecipeJson'
import { mapToWorkflowSourceBodyJson } from '~/data/workflow/source/mapToWorkflowSourceBodyJson'

export function mapToRecipeJson(tson: Tson): RecipeJson {
  return {
    categories: tson.getArray('categories').map(mapToRecipeCategoryJson),
    description: tson.getString('description'),
    id: tson.getString('id'),
    name: tson.getString('name'),
    source: mapToWorkflowSourceBodyJson(tson.getValue('source')),
  }
}

function mapToRecipeCategoryJson(tson: Tson): RecipeCategoryJson {
  return {
    id: tson.getString('id'),
    label: tson.getString('label'),
  }
}
