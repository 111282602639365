import * as React from 'react'
import { Check, Clipboard } from 'react-feather'

import Text from '~/components/atoms/Text'
import Copiable from '~/components/common/Copiable'
import { ViewUnitDefinition } from '~/domain/workflow/viewUnit/ViewUnitDefinition'
import * as vars from '~/styles/variables'

interface Props {
  definition: ViewUnitDefinition
}

const CopyViewUnit: React.FC<Props> = (props) => {
  const [showCheckMark, setShowCheckMark] = React.useState<boolean>(false)

  React.useEffect(() => {
    let timer: number
    if (showCheckMark) {
      timer = window.setTimeout(() => {
        setShowCheckMark(false)
      }, 2000)
    }
    return () => {
      window.clearTimeout(timer)
    }
  }, [showCheckMark])

  return (
    <>
      <Text element="h2" fontSize="s" fontWeight="bold" lineHeight="heading">
        {props.definition.label}
      </Text>
      <Copiable
        style={{
          marginTop: vars.space.s,
          padding: vars.space.s,
          display: 'flex',
          alignItems: 'center',
          backgroundColor: vars.color.white,
          borderRadius: vars.borderRadius.s,
        }}
        value={props.definition.value}
        onSuccess={() => setShowCheckMark(true)}
      >
        <div
          style={{
            flexShrink: 0,
            marginRight: vars.space.s,
            height: 18,
          }}
        >
          {showCheckMark ? (
            <Check size={18} strokeWidth={3} color={vars.color.success} />
          ) : (
            <Clipboard size={18} color={vars.fontColor.primary} />
          )}
        </div>
        <Text element="span" fontSize="s" lineHeight="heading">
          {props.definition.value}
        </Text>
      </Copiable>
    </>
  )
}

export default CopyViewUnit
