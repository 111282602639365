import * as React from 'react'
import { Link } from 'react-router-dom'
import { formatRoute } from 'react-router-named-routes'

interface Props {
  to: string
  children: React.ReactNode
  search?: string
  params?: {}
  title?: string
  className?: string
  style?: {}
  target?: string
}

const MyLink: React.FC<Props> = ({
  to,
  params,
  search,
  title,
  children,
  className,
  style,
  target,
}) => (
  <Link
    className={className}
    to={{
      pathname: formatRoute(to, params),
      search,
    }}
    style={style}
    title={title}
    target={target}
  >
    {children}
  </Link>
)

export default MyLink
