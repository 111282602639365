import { delay } from '~/common/utils'
import { Organization } from '~/domain/user/Organization'
import { OrganizationService } from '~/domain/user/OrganizationService'
import { User } from '~/domain/user/User'

const mockOrganization: Organization = {
  id: 'mock_organization',
  name: 'モック組織',
}

const users: User[] = [
  {
    id: 'user_1',
    username: 'ユーザー1',
    email: 'user1@example.com',
    organization: mockOrganization,
    isAdmin: true,
  },
  {
    id: 'user_2',
    username: 'ユーザー2',
    email: 'user2@example.com',
    organization: mockOrganization,
    isAdmin: false,
  },
  {
    id: 'user_3',
    username: 'ユーザー3',
    email: 'user3@example.com',
    organization: mockOrganization,
    isAdmin: false,
  },
  {
    id: 'user_4',
    username: 'ユーザー4',
    email: 'user4@example.com',
    organization: mockOrganization,
    isAdmin: false,
  },
  {
    id: 'user_5',
    username: 'ユーザー5',
    email: 'user5@example.com',
    organization: mockOrganization,
    isAdmin: false,
  },
  {
    id: 'user_6',
    username: 'ユーザー6',
    email: 'user6@example.com',
    organization: mockOrganization,
    isAdmin: false,
  },
  {
    id: 'user_7',
    username: 'ユーザー7',
    email: 'user7@example.com',
    organization: mockOrganization,
    isAdmin: false,
  },
  {
    id: 'user_8',
    username: 'ユーザー8',
    email: 'user8@example.com',
    organization: mockOrganization,
    isAdmin: false,
  },
  {
    id: 'user_9',
    username: 'ユーザー9',
    email: 'user9@example.com',
    organization: mockOrganization,
    isAdmin: false,
  },
  {
    id: 'user_10',
    username: 'ユーザー10',
    email: 'user10@example.com',
    organization: mockOrganization,
    isAdmin: false,
  },
  {
    id: 'user_11',
    username: 'ユーザー11',
    email: 'user11@example.com',
    organization: mockOrganization,
    isAdmin: false,
  },
  {
    id: 'user_12',
    username: 'ユーザー12',
    email: 'user12@example.com',
    organization: mockOrganization,
    isAdmin: false,
  },
  {
    id: 'user_13',
    username: 'ユーザー13',
    email: 'user13@example.com',
    organization: mockOrganization,
    isAdmin: false,
  },
  {
    id: 'user_14',
    username: 'ユーザー14',
    email: 'user14@example.com',
    organization: mockOrganization,
    isAdmin: false,
  },
  {
    id: 'user_15',
    username: 'ユーザー15',
    email: 'user15@example.com',
    organization: mockOrganization,
    isAdmin: false,
  },
]

export class MockOrganizationService implements OrganizationService {
  async getUsers(_organizationId: string): Promise<User[]> {
    await delay(1500)
    if (Math.random() > 0.8) {
      throw new Error('モックエラー')
    }
    return [...users]
  }

  async create(_name: string): Promise<void> {
    await delay(1500)
  }
}
