// ネームスペースを汚さないようにアンダースコアをつけておく
export const EMPTY_ACTION_ID = '_EmptyAction'

// export interface ExpectedObjectResponse {
//   tasks: {
//     taskId: string
//     actionId: string
//     expectedObjects: ExpectedObject[]
//   }[]
//   trigger: {
//     triggerId: string
//     expectedObjects?: ExpectedObject[]
//   }
// }
//
// export interface ExpectedObject {
//   objId: string
//   objKey: string
// }
