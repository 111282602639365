import { Tson } from '~/data/Tson'
import {
  AuthErrorJson,
  InvitationSignUpErrorJson,
  SignInErrorJson,
  SignUpErrorJson,
} from '~/data/auth/AuthErrorJson'
import { defaultErrorMessage } from '~/data/auth/mapJsonToErrorMessage'

export function mapToSignUpErrorJson(tson: Tson): SignUpErrorJson {
  const json = mapToAuthErrorJson(tson)
  if (json !== undefined) {
    return json
  }
  return { serverError: defaultErrorMessage.signUp }
}

export function mapToSignInErrorJson(tson: Tson): SignInErrorJson {
  const json = mapToAuthErrorJson(tson)
  if (json !== undefined) {
    return json
  }
  const notFoundError = tson.getString('notFoundError', false)
  if (notFoundError !== null && notFoundError !== undefined) {
    return { notFoundError }
  }
  return { serverError: defaultErrorMessage.signIn }
}

export function mapToInvitationSignUpErrorJson(
  tson: Tson
): InvitationSignUpErrorJson {
  const json = mapToAuthErrorJson(tson)
  if (json !== undefined) {
    return json
  }
  const invitationToken = tson.getArray('invitationToken', false)
  if (invitationToken !== null && invitationToken !== undefined) {
    return { invitationToken: invitationToken.map((it) => it.asString()) }
  }
  return { serverError: defaultErrorMessage.invitationSignUp }
}

function mapToAuthErrorJson(tson: Tson): AuthErrorJson | undefined {
  const auth = tson.getArray('auth', false)
  if (auth !== null && auth !== undefined) {
    return { auth: auth.map((it) => it.asString()) }
  }
  const serverError = tson.getString('serverError', false)
  if (serverError !== null && serverError !== undefined) {
    return { serverError }
  }
  return undefined
}
