import styled from '@emotion/styled'
import * as React from 'react'
import {
  Book,
  ChevronRight,
  Grid,
  HelpCircle,
  Home,
  Link as LinkIcon,
  User as UserIcon,
  Users,
} from 'react-feather'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { animated, useSpring } from 'react-spring'

import Avatar from '~/components/atoms/Avatar'
import Link from '~/components/atoms/Link'
import Loader from '~/components/atoms/Loader'
import Text from '~/components/atoms/Text'
import SelectField from '~/components/molecules/SelectField'
import Dropdown from '~/components/organisms/Sidebar/Dropdown'
import SidebarItem from '~/components/organisms/Sidebar/SidebarItem'
import * as sidebarDuck from '~/ducks/ui/sidebar'
import { useAnyflowAppContext } from '~/presentation/AnyflowAppContext'
import * as routes from '~/routes'
import * as vars from '~/styles/variables'

const SYMBOL_SIZE = 35

interface Props {
  className?: string
}

const Sidebar: React.FC<Props> = ({ className }) => {
  const { me, changeGroup } = useAnyflowAppContext()
  const location = useLocation()
  const collapsed = useSelector(sidebarDuck.selectors.getIsCollapsed)
  const [isDropdownShown, setIsDropdownShown] = React.useState(false)

  const isWorkflow = location.pathname.startsWith('/workflows')
  const isRecipe = location.pathname.startsWith('/recipes')
  const isConnection = location.pathname.startsWith('/connections')
  const isLookupTable = location.pathname.startsWith('/lookup_tables')
  const isGroup = location.pathname.startsWith('/groups')
  const isUser = location.pathname.startsWith('/users')

  const props = useSpring({
    width: collapsed ? vars.width.sidebarCollapsed : vars.width.sidebar,
  })
  const chevronProps = useSpring({
    transform: isDropdownShown ? 'rotate(180deg)' : 'rotate(0deg)',
  })
  const collapseProps = useSpring({
    display: collapsed ? 'none' : 'block',
    transform: collapsed ? 'translateX(10px)' : 'translateX(0)',
    opacity: collapsed ? 0 : 1,
  })
  const logoProps = useSpring({
    marginLeft: collapsed ? -8 : 0,
  })
  const userProps = useSpring({
    marginLeft: collapsed ? -11 : 0,
  })
  const groupSelectorProps = useSpring({
    pointerEvents: collapsed ? 'none' : 'unset',
    opacity: collapsed ? 0 : 1,
  })

  const groupOptions = React.useMemo(() => {
    return me.belongingGroups.map((group) => ({
      label: group.name,
      value: group.id,
    }))
  }, [me.belongingGroups])

  const handleChangeGroup = React.useCallback(
    (newGroupId: string) => {
      if (newGroupId === me.currentGroup.id) {
        return
      }
      changeGroup(newGroupId)
    },
    [me, changeGroup]
  )

  return (
    <Container className={className} style={props}>
      <Link to={routes.WORKFLOW_LIST}>
        <Logo style={logoProps}>
          <Symbol src="/static/images/symbol.svg" width={SYMBOL_SIZE} />
          <Logotype
            src="/static/images/logotype.svg"
            style={collapseProps}
            width={SYMBOL_SIZE * 2.6}
          />
        </Logo>
      </Link>
      <animated.div
        style={{
          marginTop: vars.space.xl,
          marginRight: -vars.space.m,
          marginLeft: -vars.space.m,
          ...groupSelectorProps,
        }}
      >
        <SelectField
          options={groupOptions}
          value={me.currentGroup.id}
          onChange={handleChangeGroup}
        />
      </animated.div>
      <MenuList>
        <_SidebarItem
          to={routes.WORKFLOW_LIST}
          label="ワークフロー"
          selected={isWorkflow}
          collapsed={collapsed}
          Icon={Home}
        />
        <_SidebarItem
          to={routes.RECIPE_LIST}
          label="レシピ (β)"
          selected={isRecipe}
          collapsed={collapsed}
          Icon={Book}
        />
        <_SidebarItem
          to={routes.CONNECTION_LIST}
          label="コネクション"
          selected={isConnection}
          collapsed={collapsed}
          Icon={LinkIcon}
        />
        <_SidebarItem
          to={routes.LOOKUP_TABLE_LIST}
          label="ルックアップテーブル"
          selected={isLookupTable}
          collapsed={collapsed}
          Icon={Grid}
        />
        {me.isAdmin && (
          <>
            <_SidebarItem
              to={routes.GROUP_LIST}
              label="グループ管理"
              selected={isGroup}
              collapsed={collapsed}
              Icon={Users}
            />
            <_SidebarItem
              to={routes.USER_LIST}
              label="ユーザー管理"
              selected={isUser}
              collapsed={collapsed}
              Icon={UserIcon}
            />
          </>
        )}
      </MenuList>
      <Footer>
        <animated.div style={{ marginBottom: vars.space.m, ...collapseProps }}>
          <a
            href="https://help.anyflow.jp"
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              padding: vars.space.s,
            }}
            target="_blank"
          >
            <HelpCircle size={20} color={vars.fontColor.primary} />
            <Text
              element="span"
              fontSize="s"
              color="primary"
              style={{
                marginLeft: vars.space.m,
              }}
            >
              ヘルプ
            </Text>
          </a>
        </animated.div>
        <User style={userProps} onClick={() => setIsDropdownShown(true)}>
          <AvatarContainer>
            <Avatar />
          </AvatarContainer>
          <NameContainer>
            {me ? (
              <>
                <Username style={collapseProps}>{me.username}</Username>
                <Organization style={collapseProps}>
                  {me.organization.name !== ''
                    ? me.organization.name
                    : '組織名'}
                </Organization>
              </>
            ) : (
              <Loader />
            )}
          </NameContainer>
          <ChevronContainer style={{ ...collapseProps, ...chevronProps }}>
            <ChevronRight size={chevronSize} color={vars.color.icon} />
          </ChevronContainer>
          <Dropdown
            isShown={isDropdownShown}
            collapsed={collapsed}
            onClose={() => setIsDropdownShown(false)}
          />
        </User>
      </Footer>
    </Container>
  )
}

const Container = styled(animated.div)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: vars.space.l,
  paddingRight: vars.space.m * 1.5,
  paddingBottom: vars.space.m * 1.5,
  paddingLeft: vars.space.m * 1.5,
  borderRight: `1px solid ${vars.color.border}`,
  backgroundColor: vars.color.white,
})

const Logo = styled(animated.div)({
  display: 'flex',
  alignItems: 'center',
  height: '38px',
})
const Symbol = styled('img')({})
const Logotype = styled(animated.img)({
  // ロゴ規約に従う
  marginLeft: SYMBOL_SIZE * 0.23,
})

const MenuList = styled('div')({
  marginTop: vars.space.m,
  paddingLeft: 0,
  width: '100%',
  listStyle: 'none',
})

const _SidebarItem = styled(SidebarItem)({
  marginTop: vars.space.m,
  marginLeft: -1 * vars.space.m,
  marginRight: -1 * vars.space.m,
  '&:first-of-type': {
    marginTop: 0,
  },
})

const Footer = styled('div')({
  marginTop: 'auto',
})

const User = styled(animated.div)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  cursor: 'pointer',
})

const NameContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 40 + vars.space.s * 1.5,
  display: 'flex',
  flexDirection: 'column',
  paddingRight: vars.space.l,
  // FIXME
  width: 'calc(100% - 40px)',
})

const Username = styled(animated.p)({
  fontSize: vars.fontSize.m,
  lineHeight: 1,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const Organization = styled(animated.p)({
  marginTop: vars.space.xs,
  fontSize: vars.fontSize.s,
  color: vars.fontColor.secondary,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const AvatarContainer = styled('div')({
  width: '100%',
})

const chevronSize = 18

const ChevronContainer = styled(animated.div)({
  width: chevronSize,
  height: chevronSize,
  transformOrigin: `${chevronSize / 2}px ${chevronSize / 2}px`,
})

export default Sidebar
