import * as React from 'react'

interface OwnProps {
  tabFocusable?: boolean
  onFocusChanged?: (hasFocus: boolean) => void
}

type Props = OwnProps & React.HTMLAttributes<HTMLDivElement>

const Focusable: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { tabFocusable = false, onFocusChanged, ...props },
  ref
) => {
  const handleFocusChanged = (hasFocus: boolean) => {
    if (onFocusChanged !== undefined) {
      onFocusChanged(hasFocus)
    }
  }

  return (
    <div
      ref={ref}
      {...props}
      onFocus={(event) => {
        if (
          event.relatedTarget instanceof Node &&
          event.currentTarget.contains(event.relatedTarget)
        ) {
          // 子孫コンポーネント間のFocusイベントは取り扱わない
          return
        }
        handleFocusChanged(true)
        if (props.onFocus !== undefined) {
          props.onFocus(event)
        }
      }}
      onBlur={(event) => {
        if (
          event.relatedTarget instanceof Node &&
          event.currentTarget.contains(event.relatedTarget)
        ) {
          // 子孫コンポーネント間のFocusイベントは取り扱わない
          return
        }
        handleFocusChanged(false)
        if (props.onBlur !== undefined) {
          props.onBlur(event)
        }
      }}
      tabIndex={tabFocusable ? 0 : -1}
      style={{ outline: 'none', ...props.style }}
    >
      {props.children}
    </div>
  )
}

export default React.forwardRef(Focusable)
