import { delay } from '~/common/utils'
import { ObjectDefinition } from '~/domain/workflow/object/ObjectDefinition'
import { ObjectService } from '~/domain/workflow/object/ObjectService'

export class MockObjectService implements ObjectService {
  async getAll(): Promise<ObjectDefinition[]> {
    await delay(1000)
    return objects
  }
}

const spreadsheetColumn: ObjectDefinition = new ObjectDefinition(
  'GoogleSpreadsheetApp__SpreadsheetColumnObject',
  'GoogleSpreadsheetApp',
  'スプレッドシートカラム',
  '',
  [
    {
      type: 'property',
      key: 'values',
      name: '値一覧',
      description: '',
      valueType: {
        typeName: 'list',
        nullable: false,
        itemType: {
          typeName: 'string',
          nullable: false,
        },
      },
    },
    {
      type: 'property',
      key: 'sheet_title',
      name: 'スプレッドシートタイトル',
      description: '',
      valueType: {
        typeName: 'string',
        nullable: false,
      },
    },
    {
      type: 'property',
      key: 'sheet_name',
      name: 'シート名',
      description: '',
      valueType: {
        typeName: 'string',
        nullable: false,
      },
    },
  ]
)

const testObject = new ObjectDefinition(
  'GenericApp__UserObject',
  'GenericApp',
  '検証用オブジェクト',
  '検証用（リスト）',
  [
    {
      type: 'property',
      key: 'list',
      name: '検証用リスト',
      description: '',
      valueType: {
        typeName: 'list',
        nullable: false,
        itemType: {
          typeName: 'string',
          nullable: false,
        },
      },
    },
    {
      type: 'property',
      key: 'list2-1',
      name: '検証用リスト',
      description: 'サイズが 5 固定の string 型のリストです',
      valueType: {
        typeName: 'list',
        length: 5,
        nullable: false,
        itemType: {
          typeName: 'string',
          nullable: false,
        },
      },
    },
    {
      type: 'property',
      key: 'list2-2',
      name: '検証用リスト',
      description: 'サイズが 8 固定の string 型のリストです',
      valueType: {
        typeName: 'list',
        length: 8,
        nullable: false,
        itemType: {
          typeName: 'string',
          nullable: false,
        },
      },
    },
    {
      type: 'property',
      key: 'list3',
      name: '検証用リスト',
      description: 'number 型のリストです',
      valueType: {
        typeName: 'list',
        length: 12,
        nullable: false,
        itemType: {
          typeName: 'number',
          canDecimal: false,
          nullable: false,
        },
      },
    },
    {
      type: 'property',
      key: 'string',
      name: '検証用文字列',
      description: '',
      valueType: {
        typeName: 'string',
        nullable: false,
      },
    },
    {
      type: 'property',
      key: 'number',
      name: '検証用数値',
      description: '数値です',
      valueType: {
        typeName: 'number',
        canDecimal: false,
        nullable: false,
      },
    },
    {
      type: 'property',
      key: 'dict',
      name: '検証用dict',
      description: 'dictです',
      valueType: {
        typeName: 'dict',
        nullable: false,
        valueType: {
          typeName: 'string',
          nullable: false,
        },
      },
    },
    {
      type: 'property',
      key: 'struct',
      name: '検証用struct',
      description: 'structです',
      valueType: {
        typeName: 'struct',
        nullable: false,
        entries: [
          {
            key: 'foo',
            valueType: {
              typeName: 'string',
              nullable: false,
            },
          },
          {
            key: 'bar',
            valueType: {
              typeName: 'list',
              nullable: false,
              itemType: {
                typeName: 'number',
                canDecimal: false,
                nullable: false,
              },
            },
          },
        ],
      },
    },
    {
      type: 'method',
      key: 'doSomething',
      name: '検証用メソッド',
      description: 'これは検証用メソッドです',
      arguments: [],
      returnType: {
        typeName: 'string',
        nullable: false,
      },
    },
  ]
)

const objects = [testObject, spreadsheetColumn]
