import { useCallback } from 'react'

import { apiClients } from '~/common/apiClients'
import {
  firebaseAuth,
  googleSignInWithPopup,
} from '~/presentation/auth/firebaseAuth'

const authService = apiClients.authService

type UserState = 'not-authenticated' | 'auth-as-normal-user'

export function useGoogleSignIn(): {
  signIn: () => Promise<UserState>
} {
  const signIn = useCallback(async () => {
    try {
      const { user } = await googleSignInWithPopup()
      if (user === null) {
        return 'not-authenticated'
      }
      const idToken = await user.getIdToken()
      await authService.signInWithGoogle(idToken)
      return 'auth-as-normal-user'
    } finally {
      await firebaseAuth().signOut()
    }
  }, [])

  return { signIn }
}
