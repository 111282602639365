import * as _ from 'lodash'
import * as uuid from 'uuid'

import { Pagination } from '~/common/Pagination'
import { StandardTrigger } from '~/common/constants'
import { delay, failRandomly } from '~/common/utils'
import { HttpError } from '~/data/request'
import { Workflow } from '~/domain/workflow/Workflow'
import { WorkflowService } from '~/domain/workflow/WorkflowService'
import { ExpectedObjects } from '~/domain/workflow/expectedObject/ExpectedObjects'
import { ExpectedObjectKey } from '~/domain/workflow/object/ExpectedObjectKey'
import { Permission } from '~/domain/workflow/permission/Permission'
import { PermissionPolicy } from '~/domain/workflow/permission/PermissionPolicy'
import { WorkflowSource } from '~/domain/workflow/source/WorkflowSource'
import {
  TaskSource,
  TriggerSource,
  WorkflowSourceBody,
} from '~/domain/workflow/source/WorkflowSourceBody'
import { EntityType } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/types'

export class MockWorkflowService implements WorkflowService {
  async get(workflowId: string): Promise<Workflow | undefined> {
    await delay(1300)
    const index = _.findIndex(
      serverData.workflows,
      (it) => it.workflowId === workflowId
    )
    const workflow = _.cloneDeep(serverData.workflows[index])
    console.log(
      `[API] get workflow called. Workflow: ${JSON.stringify(workflow)}`
    )
    // 毎回参照が変わる必要がある（そうじゃないと WorkflowEdit 側で更新されたと認識されない）
    return workflow
    // return {
    //   workflowId,
    //   name: 'ダミー',
    //   description: 'MockWorkflowService により作成されたダミーワークフロー',
    //   isEnabled: false,
    //   updatedAt: '2020-02-25T18:55:00+9:00',
    //   source: new WorkflowSource(
    //     'dummy_workflow',
    //     defaultTrigger,
    //     spreadsheetTask2
    //   )
    // }
  }

  async getList(): Promise<Pagination<Workflow>> {
    await delay(1300)
    return {
      paginationMeta: {
        currentPageNumber: 1,
        totalCount: 100,
        countPerPage: 10,
      },
      items: serverData.workflows.slice(),
    }
  }

  async getExpectedObjects(
    workflowId: string,
    parentTaskId?: string
  ): Promise<ExpectedObjects> {
    await delay(1200)
    console.log(
      `[API] getExpectedObjects called. workflowId: ${workflowId}, parentTaskId: ${parentTaskId}`
    )
    return new ExpectedObjects({
      triggerId: StandardTrigger,
      expectedObjects: [
        {
          key: new ExpectedObjectKey('runner'),
          name: '実行者',
          objectId: 'GenericApp__UserObject',
          sampleValues: [],
        },
      ],
    })
  }

  async getPermissions(workflowId: string): Promise<Permission[]> {
    console.log(
      `[API] Workflow get permissions called. workflowId: ${workflowId}`
    )
    await delay(2000)
    failRandomly(0.15)
    return [...(serverData.permissions[workflowId] ?? [])]
  }

  async create(
    workflowName: string,
    sourceBody: WorkflowSourceBody
  ): Promise<Workflow> {
    console.log(
      `[API] Workflow delete called. workflowName: ${workflowName}, sourceBody: ${JSON.stringify(
        sourceBody
      )}`
    )
    const workflow: Workflow = {
      workflowId: String(serverData.workflows.length + 1),
      name: workflowName,
      description: '',
      isEnabled: false,
      source: new WorkflowSource('source1', sourceBody),
      updatedAt: '',
      permissionPolicy: new PermissionPolicy.Ownable(),
    }
    serverData.workflows.push(workflow)
    return workflow
  }

  async delete(workflowId: string): Promise<void> {
    console.log(`[API] Workflow delete called: ${workflowId}`)
    let found: boolean = false
    serverData.workflows = serverData.workflows.filter((it) => {
      if (it.workflowId === workflowId) {
        found = true
        return false
      }
      return true
    })
    if (!found) {
      throw new Error(`[API] No such workflow ID: ${workflowId}`)
    }
  }

  async execute(workflowId: string): Promise<string> {
    console.log(`[API] Workflow execute called: ${workflowId}`)
    return `dummy_workflow_instance_id_${Math.floor(Math.random() * 1000000)}`
  }

  async update(
    workflowId: string,
    workflow: Partial<Workflow>
  ): Promise<Workflow> {
    console.log(
      `[API] Workflow update called. Workflow ID: ${workflowId}, data: ${JSON.stringify(
        workflow
      )}`
    )
    const found = serverData.workflows.find(
      (it) => it.workflowId === workflowId
    )
    if (found === undefined) {
      throw new Error(`[API] No such workflow ID: ${workflowId}`)
    }
    Object.keys(workflow).forEach((key) => {
      found[key] = workflow[key]
    })
    return found
  }

  async addPermission(
    workflowId: string,
    groupId: string,
    policy: PermissionPolicy
  ): Promise<Permission> {
    console.log(
      `[API] add permission called. workflowId: ${workflowId}, groupId: ${groupId}, policy: ${policy.name}`
    )
    await delay(500)
    failRandomly(0.05)
    const permission = new Permission({
      id: uuid.v4(),
      group: {
        id: groupId,
        name: `モックグループ (${groupId})`,
        updatedAt: new Date(),
      },
      policy,
      updatedAt: new Date(),
    })
    serverData.permissions[workflowId] = [
      ...(serverData.permissions[workflowId] ?? []),
      permission,
    ]
    return permission
  }

  async changePermission(
    workflowId: string,
    permissionId: string,
    policy: PermissionPolicy
  ): Promise<Permission> {
    console.log(
      `[API] change permission called. workflowId: ${workflowId}, permissionId: ${permissionId}, policy: ${policy.name}`
    )
    await delay(500)
    failRandomly(0.05)
    const permissions: Permission[] = serverData.permissions[workflowId] ?? []
    const data = permissions.find((it) => it.id === permissionId)
    if (data === undefined) {
      throw new HttpError(404)
    }
    const newData = new Permission({ ...data, policy })
    serverData.permissions[workflowId] = permissions.map((it) =>
      it.id === permissionId ? newData : it
    )
    return newData
  }

  async removePermission(
    workflowId: string,
    permissionId: string
  ): Promise<void> {
    await delay(500)
    failRandomly(0.05)
    serverData.permissions = _.mapValues(
      serverData.permissions,
      (permissions, key) => {
        return key === workflowId
          ? _.reject(
              permissions,
              (permission) => permission.id === permissionId
            )
          : permissions
      }
    )
  }
}

const defaultTrigger: TriggerSource = {
  triggerId: 'GenericApp__StandardTrigger',
  inputs: [
    {
      fieldKey: 'default_runner_slug',
      value: {
        mode: 'raw',
        raw: '',
      },
    },
    {
      fieldKey: 'is_always_default_runner',
      value: {
        mode: 'raw',
        raw: true,
      },
    },
  ],
}

const spreadsheetTask: TaskSource = {
  actionId: 'GoogleSpreadsheetApp__Read',
  inputs: [
    {
      fieldKey: 'sheet_id',
      value: {
        mode: 'render',
        template: [
          {
            type: EntityType.FUNCTION_CALL_START,
            functionId: 'create_list',
          },
          {
            type: EntityType.CALL_END,
          },
        ],
      },
    },
  ],
  taskId: 'aaa',
  tasks: [],
}

const spreadsheetTask2: TaskSource = {
  actionId: 'GoogleSpreadsheetApp__Read',
  inputs: [
    {
      fieldKey: 'sheet_id',
      value: {
        mode: 'render',
        template: [
          {
            type: EntityType.FUNCTION_CALL_START,
            functionId: 'create_list',
          },
          {
            type: EntityType.CALL_END,
          },
        ],
      },
    },
  ],
  taskId: 'bbb',
  tasks: [spreadsheetTask],
}

export const serverData: {
  workflows: Workflow[]
  permissions: { [workflowId: string]: Permission[] }
} = {
  workflows: [
    {
      workflowId: '1',
      name: 'ダミー',
      description: 'MockWorkflowService により作成されたダミーワークフロー',
      isEnabled: false,
      updatedAt: '2020-02-25T18:55:00+9:00',
      source: new WorkflowSource(
        'dummy1',
        new WorkflowSourceBody(defaultTrigger, spreadsheetTask2)
      ),
      permissionPolicy: new PermissionPolicy.Ownable(),
    },
    {
      workflowId: '2',
      name: 'ダミー2',
      description: 'MockWorkflowService により作成されたダミーワークフロー2',
      isEnabled: false,
      updatedAt: '2020-02-256T18:55:00+9:00',
      source: new WorkflowSource(
        'dummy2',
        new WorkflowSourceBody(defaultTrigger, spreadsheetTask2)
      ),
      permissionPolicy: new PermissionPolicy.Ownable(),
    },
  ],
  permissions: {
    '1': [
      new Permission({
        id: 'permission1',
        group: { id: 'group1', name: 'グループ1', updatedAt: new Date() },
        policy: new PermissionPolicy.Deletable(),
        updatedAt: new Date(),
      }),
    ],
  },
}
