import { WorkflowJson } from '~/data/workflow/WorkflowJson'
import { mapJsonToPermissionPolicy } from '~/data/workflow/permission/mapJsonToPermission'
import { mapJsonToWorkflowSource } from '~/data/workflow/source/mapJsonToWorkflowSource'
import { Workflow } from '~/domain/workflow/Workflow'

export async function mapJsonToWorkflow(json: WorkflowJson): Promise<Workflow> {
  return {
    workflowId: json.slug,
    name: json.name,
    description: json.description,
    isEnabled: json.isEnabled,
    updatedAt: json.updatedAt,
    source: await mapJsonToWorkflowSource(json.source),
    permissionPolicy: mapJsonToPermissionPolicy(json.permissionPolicy),
  }
}
