import styled from '@emotion/styled'
import _ from 'lodash'
import * as React from 'react'
import { ChevronDown } from 'react-feather'
import { animated, useSpring } from 'react-spring'

import Text from '~/components/atoms/Text'
import Focusable from '~/components/common/Focusable'
import Dropdown, { DropdownItem } from '~/components/molecules/Dropdown'
import * as vars from '~/styles/variables'
import {
  widgetAnimationStyle,
  widgetBorderErrorStyle,
  widgetBorderStyle,
  widgetFocusedStyle,
} from '~/styles/widget'

export interface Option<T extends string = string> {
  label: string
  value: T
}

interface Props<T extends string = string> {
  options: Option<T>[]
  unselectedOptionLabel: T
  values: T[]
  hasError?: boolean
  disabled?: boolean
  renderItem?: (item: Option<T>, checked: boolean) => React.ReactNode
  onChange: (values: T[]) => void
}

function MultiSelectField<T extends string = string>({
  options,
  unselectedOptionLabel,
  values,
  renderItem,
  hasError,
  disabled = false,
  onChange,
}: Props<T>) {
  const [hasFocus, setHasFocus] = React.useState<boolean>(false)
  const [isDropdownOpened, setIsDropdownOpened] = React.useState(false)

  const chevronProps = useSpring({
    transform: isDropdownOpened ? 'rotate(180deg)' : 'rotate(0deg)',
  })

  React.useEffect(() => {
    if (!hasFocus) {
      setIsDropdownOpened(false)
    }
  }, [hasFocus])

  const toggleIsDropdownOpened = () => {
    setIsDropdownOpened(!isDropdownOpened)
  }

  const renderDropdownItem = React.useCallback(
    (item: DropdownItem<T>) => {
      const selected = values.includes(item.value)
      if (renderItem === undefined) {
        return (
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            title={item.label}
          >
            <CheckBox
              checked={selected}
              style={{ marginRight: vars.space.s }}
            />
            <_Label color={vars.fontColor.primary}>{item.label}</_Label>
          </div>
        )
      }
      return renderItem(item, selected)
    },
    [values, renderItem]
  )

  const handleItemClick = React.useCallback(
    (item: DropdownItem<T>) => {
      if (values.includes(item.value)) {
        onChange(_.reject(values, (it) => it === item.value))
        return
      }
      onChange([...values, item.value])
    },
    [values, onChange]
  )

  const selectedOptions = options.filter((it) => values.includes(it.value))

  return (
    <Container>
      <Focusable
        tabFocusable={true}
        onFocus={() => {
          if (disabled) {
            return
          }
          setHasFocus(true)
        }}
        onBlur={() => {
          setHasFocus(false)
        }}
        onKeyDown={(event) => {
          if (event.key === ' ') {
            toggleIsDropdownOpened()
            event.preventDefault()
          }
        }}
      >
        <DummySelectField
          focused={hasFocus}
          hasError={hasError}
          disabled={disabled}
          onClick={() => {
            if (disabled) {
              return
            }
            toggleIsDropdownOpened()
          }}
        >
          {values.length === 0 ? (
            <_Label color={vars.fontColor.tertiary}>
              {unselectedOptionLabel}
            </_Label>
          ) : (
            <_Label color={vars.fontColor.primary}>
              {selectedOptions.map((it) => it.label).join(', ')}
            </_Label>
          )}
          <ChevronContainer style={chevronProps}>
            <ChevronDown size={iconSize} color={vars.color.icon} />
          </ChevronContainer>
        </DummySelectField>
        <Dropdown<T>
          isOpen={isDropdownOpened}
          items={options}
          renderItem={renderDropdownItem}
          onItemClick={handleItemClick}
        />
      </Focusable>
    </Container>
  )
}

const iconSize = 20

const Container = styled('div')({
  position: 'relative',
  display: 'block',
  width: '100%',
  backgroundColor: vars.color.white,
})

const DummySelectField = styled('div')(
  {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    paddingRight: vars.space.m,
    paddingLeft: vars.space.m,
    width: '100%',
    height: vars.height.field,
    userSelect: 'none',
    borderRadius: widgetBorderStyle.borderRadius,
    ...widgetAnimationStyle,
  },
  (props: { focused: boolean; hasError?: boolean; disabled: boolean }) => {
    return {
      border: props.hasError
        ? widgetBorderErrorStyle.border
        : widgetBorderStyle.border,
      ...(props.focused ? widgetFocusedStyle : {}),
      cursor: props.disabled ? 'not-allowed' : 'pointer',
      opacity: props.disabled ? 0.5 : 1,
    }
  }
)

const Label = styled(Text)({
  // Text の truncated を効かせるため
  width: `calc(100% - ${iconSize}px)`,
})

const _Label: React.FC<{ color: string }> = (props) => (
  <Label
    element="p"
    color={props.color}
    fontSize="m"
    lineHeight="just"
    truncated={true}
  >
    {props.children}
  </Label>
)

const CheckBox = styled('span')(
  {
    position: 'relative',
    display: 'inline-block',
    width: 18,
    height: 18,
    borderRadius: vars.borderRadius.s,
  },
  (props: { checked: boolean }) => {
    return props.checked
      ? {
          backgroundColor: vars.color.theme,
          '&::after': {
            position: 'absolute',
            top: 4,
            left: 7,
            width: 5,
            height: 9,
            content: '""',
            cursor: 'pointer',
            transform: 'rotate(45deg)',
            borderStyle: 'none',
            borderRight: `2px solid ${vars.color.white}`,
            borderBottom: `2px solid ${vars.color.white}`,
            backgroundColor: 'transparent',
          },
        }
      : {
          border: `1px solid ${vars.color.border}`,
        }
  }
)

const ChevronContainer = styled(animated.div)({
  position: 'absolute',
  top: vars.height.field / 2 - iconSize / 2, // 上下中央
  right: vars.space.s * 1.5,
  transformOrigin: `${iconSize / 2}px ${iconSize / 2 - 1}px`, // -1 しないとちょっとずれる
  zIndex: 0, // Select より下に,
})

export default MultiSelectField
