import { assert } from '~/common/utils'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { ValidationResult } from '~/domain/workflow/validator/ValidationResult'
import { InputWidgetValidator } from '~/domain/workflow/widget/validator/InputWidgetValidator'

export class SubWidgetsValidator {
  constructor(private readonly validators: InputWidgetValidator[]) {}

  async validate(inputValues: InputValue[]): Promise<ValidationResult> {
    assert(
      this.validators.length === inputValues.length,
      `SubWidgetsValidator: The length of validators and values must be same. ${this.validators.length} vs ${inputValues.length}`
    )

    const itemValidationResults = await Promise.all(
      this.validators.map((validator, i) => validator.validate(inputValues[i]))
    )

    let hasNBulkSize: boolean = false
    const subBulkSizes: number[] = []
    for (let i = 0; i < itemValidationResults.length; i++) {
      const result = itemValidationResults[i]
      if (result.valid) {
        if (result.bulkSize !== undefined) {
          if (result.bulkSize === 'n') {
            hasNBulkSize = true
          } else {
            // 固有のバルクサイズを持っていれば記録
            subBulkSizes.push(result.bulkSize)
          }
        }
      } else {
        // サブウィジェットのうち1つでも invalid であれば invalid
        return ValidationResult.invalid(
          new ValidationResult.Propagated(result.cause)
        )
      }
    }

    // サブウィジェットのバルクサイズをチェック
    if (
      subBulkSizes.length > 1 &&
      subBulkSizes.some((size) => size !== subBulkSizes[0])
    ) {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat(
          'バルクサイズの異なる変数が使用されている箇所があります'
        )
      )
    }

    if (subBulkSizes.length > 0) {
      // 固有のバルクサイズがある場合はその値がサブウィジェット全体のバルクサイズとなる
      return ValidationResult.valid(subBulkSizes[0])
    }

    // この時点で subBulkSizes は空
    assert(subBulkSizes.length === 0, 'subBulkSizes.length !== 0')
    if (hasNBulkSize) {
      // バルクサイズが n のものがあれば、サブウィジェット全体のバルクサイズも n
      return ValidationResult.valid('n')
    }

    // どのサブウィジェットにもバルクサイズがない場合は、サブウィジェット全体のバルクサイズもない
    return ValidationResult.valid()
  }
}
