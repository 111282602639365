import * as React from 'react'

import { apiClients } from '~/common/apiClients'
import ErrorMessage from '~/components/atoms/ErrorMessage'
import DynamicSelectField from '~/components/molecules/DynamicSelectField/DynamicSelectField'
import { AssistService } from '~/domain/workflow/assist/AssistService'
import { AssistWidgetDefinition } from '~/domain/workflow/widget/assist'
import { RawInputWidgetProps } from '~/presentation/workflow/detail/editor/form/inputWidget/RawInputWidget'
import { useAssistParameters } from '~/presentation/workflow/detail/editor/form/inputWidget/assist/useAssistParameters'
import { useInputWidgetValidation } from '~/presentation/workflow/detail/editor/form/validation/useValidation'
import * as vars from '~/styles/variables'

interface Props extends RawInputWidgetProps {
  definition: AssistWidgetDefinition
}

const service: AssistService = apiClients.assistService

const AssistWidget: React.FC<Props> = (props) => {
  // バリデーション
  const validationResult = useInputWidgetValidation(props)

  const params = useAssistParameters(
    props.definition.assist.parameterFieldKeys,
    props.dependentFields
  )

  const value: string | undefined = React.useMemo(() => {
    if (props.value === undefined) {
      return undefined
    }
    if (
      typeof props.value.raw !== 'string' &&
      typeof props.value.raw !== 'number'
    ) {
      throw new Error('AssistWidget requires string or number value')
    }
    return props.value.raw.toString()
  }, [props.value])

  const loadOptions = React.useCallback(
    async (query: string, pageToken?: string) => {
      const res = await service.getList(
        props.definition.assist.assistId,
        query,
        params,
        pageToken
      )
      return {
        options: res.items,
        nextPageToken: res.nextPageToken,
      }
    },
    [props.definition.assist.assistId, params]
  )

  const loadLabel = React.useCallback(
    async (_value: string) => {
      const res = await service.getLabel(
        props.definition.assist.assistId,
        params,
        _value
      )
      return res?.label
    },
    [props.definition.assist.assistId, params]
  )

  const handleChange = React.useCallback(
    (newValue: string | undefined) => {
      if (newValue === undefined) {
        props.onChange(undefined)
        return
      }
      props.onChange({ mode: 'raw', raw: newValue })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChange]
  )

  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: vars.color.white,
        width: '100%',
      }}
    >
      <DynamicSelectField
        loadOptions={loadOptions}
        loadLabel={loadLabel}
        value={value}
        onChange={handleChange}
        hasError={validationResult?.valid === false}
        disabled={props.readonly}
      />
      {validationResult?.valid === false && (
        <ErrorMessage style={{ marginTop: vars.space.s }}>
          {validationResult.cause.message}
        </ErrorMessage>
      )}
    </div>
  )
}

export default AssistWidget
