import { Tson } from '~/data/Tson'
import {
  LookupTableCellJson,
  LookupTableColumnSchemaJson,
  LookupTableJson,
  LookupTableRowJson,
} from '~/data/lookupTable/LookupTableJson'

export function mapToLookupTableJson(tson: Tson): LookupTableJson {
  return {
    slug: tson.getString('slug'),
    name: tson.getString('name'),
    description: tson.getString('description'),
    updatedAt: tson.getString('updatedAt'),
  }
}

export function mapToLookupTableColumnSchemaJson(
  tson: Tson
): LookupTableColumnSchemaJson {
  return {
    id: tson.getNumber('id'),
    label: tson.getString('label'),
    appId: tson.getString('appId'),
  }
}

export function mapToLookupTableRowJson(tson: Tson): LookupTableRowJson {
  return {
    id: tson.getNumber('id'),
    cells: tson.getArray('cells').map(mapToLookupTableCellJson),
  }
}

export function mapToLookupTableCellJson(tson: Tson): LookupTableCellJson {
  return {
    schemaId: tson.getNumber('schemaId'),
    value: tson.getString('value'),
  }
}
