import { Tson } from '~/data/Tson'
import { WorkflowJson } from '~/data/workflow/WorkflowJson'
import { mapToPermissionPolicyJson } from '~/data/workflow/permission/mapToPermissionJson'
import { mapToWorkflowSourceJson } from '~/data/workflow/source/mapToWorkflowSourceJson'

export function mapToWorkflowJson(tson: Tson): WorkflowJson {
  return {
    description: tson.getString('description'),
    isEnabled: tson.getBoolean('isEnabled'),
    name: tson.getString('name'),
    slug: tson.getString('slug'),
    source: mapToWorkflowSourceJson(tson.getValue('source')),
    updatedAt: tson.getString('updatedAt'),
    permissionPolicy: mapToPermissionPolicyJson(
      tson.getValue('permissionPolicy')
    ),
  }
}
