import * as _ from 'lodash'

import { assertNever } from '~/common/utils'
import { Definitions } from '~/domain/Definitions'
import { NeverValueType, ValueType } from '~/domain/ValueType'
import { ExpectedObjects } from '~/domain/workflow/expectedObject/ExpectedObjects'
import {
  RenderableElement,
  RenderableEntity,
} from '~/domain/workflow/source/RenderableElement'
import { EntityType } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/types'

const neverType: NeverValueType = {
  typeName: 'never',
  nullable: false,
}

export class RenderableElementsTypeDeterminer {
  constructor(
    private readonly definitions: Definitions,
    private readonly expectedObjects: ExpectedObjects
  ) {}

  determine(renderableElements: RenderableElement[]): ValueType[] {
    let argumentDepth = 0
    return renderableElements
      .filter((element) => {
        // ルート要素のみを残す（メソッドや関数の引数としている値は型判定に使用しない）
        if (_.isString(element)) {
          return argumentDepth === 0
        }
        switch (element.type) {
          case EntityType.PRIMITIVE:
            return argumentDepth === 0
          case EntityType.PROPERTY:
            return argumentDepth === 0
          case EntityType.METHOD_CALL_START:
            return argumentDepth++ === 0
          case EntityType.FUNCTION_CALL_START:
            return argumentDepth++ === 0
          case EntityType.CALL_END:
            argumentDepth -= 1
            return false
          case EntityType.UNSUPPORTED:
            return false
          default:
            assertNever(element)
        }
      })
      .filter((element): element is RenderableEntity => {
        // 直接入力箇所は無視する
        return !_.isString(element)
      })
      .map<ValueType>((element) => {
        switch (element.type) {
          case EntityType.PRIMITIVE:
            return getPrimitiveType(element.label)
          case EntityType.PROPERTY: {
            const expObj = this.expectedObjects.findInstance(
              element.expectedObjectKey.value
            )
            if (expObj === undefined) {
              return neverType
            }
            const object = this.definitions.getObject(expObj.objectId)
            return object.getProperty(element.propertyKey).valueType
          }
          case EntityType.METHOD_CALL_START: {
            const expObj = this.expectedObjects.findInstance(
              element.expectedObjectKey.value
            )
            if (expObj === undefined) {
              return neverType
            }
            const object = this.definitions.getObject(expObj.objectId)
            return object.getMethod(element.methodKey).returnType
          }
          case EntityType.FUNCTION_CALL_START: {
            const func = this.definitions.getFunction(element.functionId)
            return func.returnType
          }
          case EntityType.CALL_END:
          case EntityType.UNSUPPORTED:
            throw new Error(`CALL_END or UNSUPPORTED should not be included`)
          default:
            assertNever(element)
        }
      })
  }
}

function getPrimitiveType(primitiveLabel: string): ValueType {
  if (primitiveLabel.match(/true/i)) {
    return { typeName: 'boolean', nullable: false }
  }
  if (primitiveLabel.match(/false/i)) {
    return { typeName: 'boolean', nullable: false }
  }
  if (primitiveLabel.match(/none/i)) {
    return { typeName: 'any', nullable: true }
  }
  throw new Error(`Unknown primitive type: ${primitiveLabel}`)
}
