import styled from '@emotion/styled'
import * as React from 'react'

import Button from '~/components/atoms/Button'
import TextField from '~/components/atoms/TextField'
import { HttpError } from '~/data/request'
import { useCreateOrganization } from '~/presentation/auth/useCreateOrganization'
import * as vars from '~/styles/variables'

interface Props {
  onOrganizationCreate: () => void
}

export const CreateOrganizationForm: React.FC<Props> = (props) => {
  const [organizationName, setOrganizationName] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState<string>()
  const { creating, createOrganization } = useCreateOrganization()

  const handleOrganizationNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value
      setOrganizationName(value)
      if (value.length >= 250) {
        setErrorMessage('250文字以下で入力してください')
      } else {
        setErrorMessage(undefined)
      }
    },
    []
  )

  const handleSubmit = React.useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault()
      if (errorMessage !== undefined) {
        return
      }
      setErrorMessage(undefined)
      try {
        await createOrganization(organizationName)
        props.onOrganizationCreate()
      } catch (error) {
        if (error instanceof HttpError) {
          setErrorMessage('既に同一名の会社が存在します')
        } else {
          setErrorMessage('企業名の登録に失敗しました')
        }
        console.error(error)
      }
    },
    [createOrganization, errorMessage, organizationName, props]
  )

  return (
    <Form onSubmit={handleSubmit}>
      <_TextField
        type="text"
        value={organizationName}
        placeholder="企業名を入力してください"
        required={true}
        onChange={handleOrganizationNameChange}
        hasError={errorMessage !== undefined}
      />
      {errorMessage && <Error>{errorMessage}</Error>}
      <_Button loading={creating}>Anyflowを使い始める</_Button>
    </Form>
  )
}

const Form = styled('form')({
  marginTop: vars.space.l,
  width: '100%',
})

const _TextField = styled(TextField)({
  marginTop: 0,
  backgroundImage: `url("/static/icons/organization_gray.svg")`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: `${vars.space.m}px 50%`,
  paddingLeft: vars.space.l * 1.5,
})

const Error = styled('div')({
  marginTop: vars.space.s,
  color: vars.color.error,
  fontSize: vars.fontSize.xs,
})

const _Button = styled(Button)({
  marginTop: vars.space.m,
  width: '100%',
  height: 45,
  fontSize: vars.fontSize.m,
})
