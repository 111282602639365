import * as React from 'react'
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom'

import { Intercom } from '~/components/common/Intercom'
import Root from '~/components/common/Root'
import SidebarTemplate from '~/components/templates/SidebarTemplate'
import { Contact } from '~/containers/Contact'
import Home from '~/containers/Home'
import RecipeLp from '~/containers/RecipeLp'
import useAnalytics from '~/hooks/useAnalytics'
import AnyflowApp from '~/presentation/AnyflowApp'
import { CreateOrganizationPage } from '~/presentation/auth/CreateOrganizationPage'
import { SignInPage } from '~/presentation/auth/SignInPage'
import { SignUpPage } from '~/presentation/auth/SignUpPage'
import { UserInvitationPage } from '~/presentation/auth/UserInvitationPage'
import { GroupRoute } from '~/presentation/group/GroupRoute'
import LookupTableDetail from '~/presentation/lookupTable/LookupTableDetail'
import LookupTableList from '~/presentation/lookupTable/LookupTableList'
import RecipeList from '~/presentation/recipe'
import RecipeWizardPage from '~/presentation/recipe/create/RecipeWizardPage'
import RecipeDetail from '~/presentation/recipe/detail'
import ConnectionDetailPage from '~/presentation/socialAccount/ConnectionDetailPage'
import ConnectionListPage from '~/presentation/socialAccount/ConnectionListPage'
import { UserListPage } from '~/presentation/user/UserListPage'
import WorkflowList from '~/presentation/workflow/WorkflowList'
import WorkflowDetail from '~/presentation/workflow/detail'

export const HOME = '/'
const LOGIN = '/login'
export const SIGNIN = '/signin'
export const SIGNUP = '/signup'
export const ORGANIZATION_CREATE = '/organization/create'
export const USER_INVITATION = '/user_invitations/:token'
export const LP_PREFIX = '/lp'
export const LP_RECIPE = `${LP_PREFIX}/recipes/:recipeId`
export const CONTACT = '/contact'
export const CONTACT_THANK_YOU = '/contact/thankyou'

/* Workflow */
export const HISTORY_LIST = '/workflows/:workflowId/histories'
export const HISTORY_DETAIL =
  '/workflows/:workflowId/histories/:workflowInstanceId'
export const WORKFLOW_EDIT = '/workflows/:workflowId/edit'
export const WORKFLOW_DETAIL = '/workflows/:workflowId'
export const WORKFLOW_LIST = '/workflows'
/* Recipe */
export const RECIPE_CREATE = '/recipes/:recipeId/create'
export const RECIPE_DETAIL = '/recipes/:recipeId'
export const RECIPE_LIST = '/recipes'
/* Connection */
export const CONNECTION_LIST = '/connections'
export const CONNECTION_DETAIL = '/connections/:connectionId'
/* Lookup Table */
export const LOOKUP_TABLE_LIST = '/lookup_tables'
export const LOOKUP_TABLE_DETAIL = '/lookup_tables/:lookupTableId'
/* Group */
export const GROUP_LIST = '/groups'
export const GROUP_DETAIL = '/groups/:groupId'
/* User */
export const USER_LIST = '/users'

export interface HistoryDetailParams {
  workflowId: string
  workflowInstanceId: string
}

export interface WorkflowDetailParams {
  workflowId: string
}

export interface RecipeDetailParams {
  recipeId: string
}

export interface LookupTableDetailParams {
  lookupTableId: string
}

const _Router: React.FC<RouteComponentProps> = (props) => {
  useAnalytics(props)
  return (
    <Switch>
      {/* Static Pages */}
      <Route path={HOME} component={Home} exact={true} />
      <Route path={CONTACT} component={Contact} />
      <Route path={LOGIN}>
        <Redirect to={SIGNIN} />
      </Route>
      <Route path={SIGNIN} component={SignInPage} />
      <Route path={SIGNUP} component={SignUpPage} />
      <Route path={ORGANIZATION_CREATE} component={CreateOrganizationPage} />
      <Route path={USER_INVITATION} component={UserInvitationPage} />
      <Route path={LP_RECIPE} component={RecipeLp} />
      <AnyflowApp>
        <Intercom appID="lxx388hu" />
        <Switch>
          <Route
            path={WORKFLOW_DETAIL}
            render={() => (
              <SidebarTemplate>
                <WorkflowDetail />
              </SidebarTemplate>
            )}
          />
          <Route
            path={WORKFLOW_LIST}
            component={(_props: any) => (
              <SidebarTemplate>
                <WorkflowList {..._props} />
              </SidebarTemplate>
            )}
          />
          <Route path={RECIPE_CREATE} component={RecipeWizardPage} />
          <Route
            path={RECIPE_DETAIL}
            component={() => (
              <SidebarTemplate>
                <RecipeDetail />
              </SidebarTemplate>
            )}
          />
          <Route
            path={RECIPE_LIST}
            component={(_props: any) => (
              <SidebarTemplate>
                <RecipeList {..._props} />
              </SidebarTemplate>
            )}
          />
          <Route
            path={CONNECTION_DETAIL}
            component={() => (
              <SidebarTemplate>
                <ConnectionDetailPage />
              </SidebarTemplate>
            )}
          />
          <Route
            path={CONNECTION_LIST}
            component={() => (
              <SidebarTemplate>
                <ConnectionListPage />
              </SidebarTemplate>
            )}
          />
          <Route
            path={LOOKUP_TABLE_DETAIL}
            component={(_props: any) => (
              <SidebarTemplate>
                <LookupTableDetail {..._props} />
              </SidebarTemplate>
            )}
          />
          <Route
            path={LOOKUP_TABLE_LIST}
            component={(_props: any) => (
              <SidebarTemplate>
                <LookupTableList {..._props} />
              </SidebarTemplate>
            )}
          />
          <Route path={GROUP_LIST} component={GroupRoute} />
          <Route path={USER_LIST} component={UserListPage} />
        </Switch>
      </AnyflowApp>
    </Switch>
  )
}

const Router = withRouter(_Router)

export default (
  <Root>
    <Router />
  </Root>
)
