import { Tson } from '~/data/Tson'
import { FunctionJson } from '~/data/workflow/function/FunctionJson'
import { mapToArgumentJson } from '~/data/workflow/function/mapToArgumentJson'
import { mapToValueTypeJson } from '~/data/workflow/valueType/mapToValueTypeJson'

export function mapToFunctionJson(tson: Tson): FunctionJson {
  return {
    key: tson.getString('key'),
    name: tson.getString('name'),
    description: tson.getString('description'),
    argsInfo: tson.getArray('argsInfo').map(mapToArgumentJson),
    returnType: mapToValueTypeJson(tson.getValue('returnType')),
  }
}
