import { UserJson } from '~/data/user/UserJson'
import { mapJsonToOrganization } from '~/data/user/organization/mapJsonToOrganization'
import { User } from '~/domain/user/User'

export function mapJsonToUser(json: UserJson): User {
  return {
    id: json.slug,
    username: json.username,
    organization: mapJsonToOrganization(json.organization),
    isAdmin: json.isAdmin,
    email: json.email ?? undefined,
  }
}
