// https://blog.rapid7.com/2016/05/25/building-svg-maps-with-react/
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { animated } from 'react-spring'
import { Spring } from 'react-spring/renderprops'

import * as editorDuck from '~/ducks/ui/editor'

interface Props {
  width: number
  height: number
  children: React.ReactNode
}

const PanZoomView: React.FC<Props> = ({ width, height, children }) => {
  const dispatch = useDispatch()
  const scale = useSelector(editorDuck.selectors.getScale)
  const tx = useSelector(editorDuck.selectors.getTx)
  const ty = useSelector(editorDuck.selectors.getTy)
  const [startX, setStartX] = React.useState(0)
  const [startY, setStartY] = React.useState(0)
  const [dragging, setDragging] = React.useState(false)
  const [wheeling, setWheeling] = React.useState(false)
  const wheelTimer = React.useRef<number>()

  function onMouseStart(e: React.MouseEvent) {
    // Find start position of drag based on touch/mouse coordinates.
    const x = e.clientX
    const y = e.clientY
    dragStart(x, y)
  }

  function onTouchStart(e: React.TouchEvent) {
    // Find start position of drag based on touch/mouse coordinates.
    const x = e.changedTouches[0].clientX
    const y = e.changedTouches[0].clientY
    dragStart(x, y)
  }

  function dragStart(x: number, y: number) {
    // Update state with above coordinates, and set dragging to true.
    setStartX(x)
    setStartY(y)
    setDragging(true)
  }

  function onMouseMove(e: React.MouseEvent) {
    if (!dragging) {
      return
    }

    // Get the new x coordinates
    const x = e.clientX
    const y = e.clientY

    dragMove(x, y)
  }

  function onTouchMove(e: React.TouchEvent) {
    if (!dragging) {
      return
    }

    // Get the new x coordinates
    const x = e.changedTouches[0].clientX
    const y = e.changedTouches[0].clientY

    dragMove(x, y)
  }

  function dragMove(x: number, y: number) {
    // Take the delta where we are minus where we came from.
    const dx = x - startX
    const dy = y - startY

    // Pan using the deltas
    pan(dx, dy)

    // Update the state
    setStartX(x)
    setStartY(y)
  }

  function onDragEnd() {
    setDragging(false)
  }

  function onWheel(e: React.WheelEvent) {
    window.clearTimeout(wheelTimer.current)
    setWheeling(true)
    wheelTimer.current = window.setTimeout(function () {
      // onWheel が実行されたあと、100 ms 以内に次のスクロールイベントがなければ
      // スクロール終了とみなす
      setWheeling(false)
    }, 100)

    pan(-e.deltaX, -e.deltaY)

    if (e.deltaY < 0) {
      // zoom(1.05)
    } else {
      // zoom(0.95)
    }
  }

  function pan(dx: number, dy: number) {
    dispatch(editorDuck.actions.addTx(dx))
    dispatch(editorDuck.actions.addTy(dy))
  }

  /* 保留
  function zoom(_scale: number) {
    dispatch(editorDuck.actions.setScale(scale * _scale))
    const _tx = tx * _scale + ((1 - _scale) * width) / 2
    dispatch(editorDuck.actions.setTx(_tx))
    const _ty = ty * _scale + ((1 - _scale) * height) / 2
    dispatch(editorDuck.actions.setTy(_ty))
    emitter.emit('zoom')
  }
  */

  return (
    <svg
      height={height}
      width={width}
      style={{
        userSelect: 'none',
      }}
      onMouseDown={onMouseStart}
      onTouchStart={onTouchStart}
      onMouseMove={onMouseMove}
      onTouchMove={onTouchMove}
      onMouseUp={onDragEnd}
      onTouchEnd={onDragEnd}
      onWheel={onWheel}
    >
      <Spring
        // TODO: from
        from={{ tx: 0, ty: 0, scale: 1 }}
        to={{
          tx,
          ty,
          scale,
        }}
        // ドラッグ中やスクロール中はアニメーションを停止
        immediate={dragging || wheeling}
      >
        {(props) => (
          <animated.g
            style={{
              transform: dragging
                ? `translate(${tx}px, ${ty}px) scale(${scale})`
                : `translate(${props.tx}px, ${props.ty}px) scale(${props.scale})`,
            }}
          >
            {children}
          </animated.g>
        )}
      </Spring>
    </svg>
  )
}

export default PanZoomView
