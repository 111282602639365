import styled from '@emotion/styled'
import * as React from 'react'
import { Grid } from 'react-feather'

import * as vars from '~/styles/variables'

interface Props {
  className?: string
  style?: React.CSSProperties
}

const NoAppIcon: React.FC<Props> = (props) => {
  return (
    <Container className={props.className} style={props.style}>
      <Grid size={12} color={vars.color.icon} />
    </Container>
  )
}

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 20,
  height: 20,
  border: `1px solid ${vars.color.border}`,
  borderRadius: vars.borderRadius.s,
  backgroundColor: vars.color.offWhite,
})

export default NoAppIcon
