import {
  ExpectedObjectJson,
  ExpectedObjectsJson,
  TaskExpectedObjectsJson,
  TriggerExpectedObjectsJson,
} from '~/data/workflow/expectedObject/json'
import { ObjectPropertyValueJson } from '~/data/workflow/object/json'
import {
  ExpectedObject,
  ExpectedObjects,
  TaskExpectedObjects,
  TriggerExpectedObjects,
} from '~/domain/workflow/expectedObject/ExpectedObjects'
import { ObjectPropertyValue } from '~/domain/workflow/expectedObject/ObjectPropertyValue'

export function mapModelToExpectedObjectsJson(
  expectedObjects: ExpectedObjects
): ExpectedObjectsJson {
  return {
    tasks: expectedObjects.tasks?.map(mapModelToTaskExpectedObjectsJson) ?? [],
    trigger: expectedObjects.trigger
      ? mapModelToTriggerExpectedObjectsJson(expectedObjects.trigger)
      : undefined,
  }
}

function mapModelToTriggerExpectedObjectsJson(
  triggerExpectedObject: TriggerExpectedObjects
): TriggerExpectedObjectsJson {
  return {
    expectedObjects: triggerExpectedObject.expectedObjects.map(
      mapModelToExpectedObjectJson
    ),
    triggerId: triggerExpectedObject.triggerId,
  }
}

function mapModelToTaskExpectedObjectsJson(
  taskExpectedObjects: TaskExpectedObjects
): TaskExpectedObjectsJson {
  return {
    actionId: taskExpectedObjects.actionId,
    expectedObjects: taskExpectedObjects.expectedObjects.map(
      mapModelToExpectedObjectJson
    ),
    taskId: taskExpectedObjects.taskId,
  }
}

export function mapModelToExpectedObjectJson(
  expectedObject: ExpectedObject
): ExpectedObjectJson {
  return {
    key: expectedObject.key.value,
    name: expectedObject.name,
    objId: expectedObject.objectId,
    sampleValues: expectedObject.sampleValues.map(
      mapModelToObjectPropertyValueJson
    ),
  }
}

export function mapModelToObjectPropertyValueJson(
  objectPropertyValue: ObjectPropertyValue
): ObjectPropertyValueJson {
  return {
    propertyKey: objectPropertyValue.propertyKey,
    value: objectPropertyValue.value,
  }
}
