import styled from '@emotion/styled'
import * as React from 'react'
import Helmet from 'react-helmet'
import { formatRoute } from 'react-router-named-routes'

import MyLink from '~/components/atoms/Link'
import AppComp from '~/components/molecules/App'
import Pager from '~/components/molecules/Pager'
import Inner from '~/components/organisms/Inner'
import {
  Table,
  Body as Tbody,
  Cell as Td,
  HeaderCell as Th,
  Header as Thead,
  Row as Tr,
} from '~/components/organisms/Table'
import { Connection } from '~/domain/connection/Connection'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import ContentLoader from '~/presentation/socialAccount/Loader'
import { useConnectionList } from '~/presentation/socialAccount/useConnectionList'
import { usePageQuery } from '~/presentation/utilityHooks'
import { CONNECTION_DETAIL, CONNECTION_LIST } from '~/routes'
import * as vars from '~/styles/variables'

const ConnectionListPage: React.FC = () => {
  const page = usePageQuery()
  const { result } = useConnectionList(page)

  return (
    <>
      <Helmet>
        <title>コネクション</title>
      </Helmet>
      <_Inner>
        <Heading>コネクション</Heading>
        <div style={{ marginTop: vars.space.l }}>
          {result.loading ? (
            <ContentLoader />
          ) : result.error ? (
            <ConnectionError />
          ) : (
            <ConnectionTable connections={result.data.items} />
          )}
        </div>
        {!result.loading && !result.error && (
          <div style={{ marginTop: vars.space.l }}>
            <_Pager
              to={CONNECTION_LIST}
              currentPageNumber={result.data.paginationMeta.currentPageNumber}
              totalCount={result.data.paginationMeta.totalCount}
              countPerPage={result.data.paginationMeta.countPerPage}
            />
          </div>
        )}
      </_Inner>
    </>
  )
}

const ConnectionError: React.FC = () => (
  <NoResults>
    <NoResultsMessage>コネクションがありません</NoResultsMessage>
  </NoResults>
)

const ConnectionTable: React.FC<{
  connections: Connection[]
}> = (props) => {
  const definitions = useDefinitions()
  return (
    <_Table>
      <Thead>
        <Tr>
          <_Th width="80">アプリ</_Th>
          <_Th>アカウント</_Th>
        </Tr>
      </Thead>
      <Tbody>
        {props.connections.map((connection) => {
          const app = definitions.getApp(connection.appId)
          return (
            <Tr key={`${connection.provider}${connection.uid}`}>
              <_Td>
                <AppComp color={app.color} icon={app.iconPath} size="m" />
              </_Td>
              <_Td>
                <ConnectionLink
                  to={formatRoute(CONNECTION_DETAIL, {
                    connectionId: connection.id,
                  })}
                >
                  {app.name}のコネクション
                </ConnectionLink>
                <Label>{connection.label}</Label>
              </_Td>
            </Tr>
          )
        })}
      </Tbody>
    </_Table>
  )
}

const _Inner = styled(Inner)({
  padding: vars.space.l,
  margin: 'unset',
  width: '100%',
})

const Heading = styled('h2')({
  fontSize: vars.fontSize.xxl,
})

const _Table = styled(Table)({
  tableLayout: 'fixed',
})

const _Th = styled(Th)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const _Td = styled(Td)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const ConnectionLink = styled(MyLink)({
  color: vars.fontColor.primary,
  fontWeight: vars.fontWeight.bold,
  fontSize: vars.fontSize.m,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
})

const Label = styled('div')({
  color: vars.fontColor.secondary,
})

const NoResults = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: vars.space.m,
})

const NoResultsMessage = styled('p')({
  color: vars.fontColor.secondary,
  fontSize: vars.fontSize.s,
  textAlign: 'center',
})

const _Pager = styled(Pager)({
  marginTop: vars.space.l,
  marginRight: vars.space.xl,
})

export default ConnectionListPage
