import * as _ from 'lodash'

import { assertNever } from '~/common/utils'
import { RenderableElement } from '~/domain/workflow/source/RenderableElement'

export type InputValue = InputValue.Raw | InputValue.Render | undefined

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace InputValue {
  export interface Raw {
    mode: 'raw'
    raw: Raw.ValueTypes
  }

  export namespace Raw {
    export interface StructEntry {
      key: string
      value: InputValue
    }

    export type ValueTypes =
      | string
      | number
      | boolean
      | InputValue[]
      | StructEntry[]

    // TODO: PureValue という名前のほうが良いかも？
    // TODO: 定義位置も変えたほうが良いかも？
    export type PureObject =
      | string
      | number
      | boolean
      | PureObject[]
      | { [key: string]: PureObject }
      | null
  }

  export interface Render {
    mode: 'render'
    template: RenderableElement[]
  }

  export function isPureObject(value: unknown): value is Raw.PureObject {
    switch (typeof value) {
      case 'string':
      case 'number':
      case 'boolean':
        return true
      case 'object': {
        if (value === null) {
          return true
        }
        if (_.isArray(value)) {
          return value.every(isPureObject)
        }
        return Object.values(value).every(isPureObject)
      }
      default:
        return false
    }
  }

  export function isStructEntry(
    object: InputValue | Raw.StructEntry
  ): object is Raw.StructEntry {
    return (
      object !== undefined &&
      object.hasOwnProperty('key') &&
      typeof object['key'] === 'string'
    )
  }

  export function isInputValue(
    object: InputValue | Raw.StructEntry
  ): object is InputValue {
    // 引数の型が既に InputValue または StructEntry に絞り込まれているので
    // StructEntry でなければ InputValue である
    return !isStructEntry(object)
  }

  export function isStructEntryList(
    raw: Raw.ValueTypes
  ): raw is Raw.StructEntry[] {
    if (typeof raw !== 'object') {
      return false
    }
    return _.every(raw, isStructEntry)
  }

  export function isInputValueList(raw: Raw.ValueTypes): raw is InputValue[] {
    if (typeof raw !== 'object') {
      return false
    }
    return _.every(raw, isInputValue)
  }

  export function isFilled(inputValue: InputValue): boolean {
    if (inputValue === undefined) {
      return false
    }
    switch (inputValue.mode) {
      case 'raw':
        // raw モードで未入力の場合は inputValue 自体が undefined のはずなので
        // mode が raw の時は常に入力済みとみなす
        return true
      case 'render':
        // 空配列の場合は未入力とみなす
        return inputValue.template.length > 0
      default:
        assertNever(inputValue)
    }
  }

  export function stringify(value: Raw.PureObject): string {
    switch (typeof value) {
      case 'string':
      case 'number':
      case 'boolean':
        return String(value)
      case 'object':
        return JSON.stringify(value)
      default:
        assertNever(value)
    }
  }

  /**
   * JavaScript オブジェクトを InputValue.Raw に変換します。
   *
   * 例:
   * - 42 -> { mode: 'raw', raw: 42 }
   * - 'foo' -> { mode: 'raw', raw: 'foo' }
   * - [1, 2, 3] -> { mode: 'raw', raw: [{ mode: 'raw', raw: 1 }, { mode: 'raw', raw: 2 }, { mode: 'raw', raw: 3 }] }
   */
  export function createRawInputValue(object: unknown): Raw | undefined {
    switch (typeof object) {
      case 'string':
      case 'number':
      case 'boolean':
        return { mode: 'raw', raw: object }
      case 'object': {
        if (object === null) {
          return undefined
        }
        if (_.isArray(object)) {
          return {
            mode: 'raw',
            raw: object.map((it) => createRawInputValue(it)),
          }
        }
        const entries: Raw.StructEntry[] = Object.keys(object).map((key) => {
          return {
            key,
            value: createRawInputValue(object[key]),
          }
        })
        return {
          mode: 'raw',
          raw: entries,
        }
      }
      case 'undefined':
        return undefined
      default:
        throw new Error(`unsupported object type: ${typeof object}`)
    }
  }

  /**
   * InputValue.Raw を JavaScript オブジェクトに変換します。
   */
  export function convertRawInputValueToObject(
    rawInputValue: Raw
  ): InputValue.Raw.PureObject {
    const raw = rawInputValue.raw
    switch (typeof raw) {
      case 'string':
      case 'number':
      case 'boolean':
        return raw
      case 'object':
        if (isInputValueList(raw)) {
          const list: InputValue.Raw.PureObject[] = []
          raw.forEach((inputValue) => {
            if (inputValue === undefined) {
              list.push(null)
              return
            }
            if (inputValue.mode !== 'raw') {
              throw new Error(
                `Only supports raw mode input values here. But was: ${JSON.stringify(
                  inputValue
                )}`
              )
            }
            list.push(convertRawInputValueToObject(inputValue))
          })
          return list
        }
        if (isStructEntryList(raw)) {
          const obj = {}
          raw.forEach((entry) => {
            if (entry.value === undefined) {
              // undefined 値を持つエントリーはキーごと追加しない
              return
            }
            if (entry.value.mode !== 'raw') {
              throw new Error(
                `Only supports raw mode input values here. But was: ${JSON.stringify(
                  entry.value
                )}`
              )
            }
            obj[entry.key] = convertRawInputValueToObject(entry.value)
          })
          return obj
        }
        return assertNever(raw)
      default:
        assertNever(raw)
    }
  }

  export function isAllRawValue(inputValue: InputValue): boolean {
    if (inputValue === undefined) {
      return true
    }
    if (inputValue.mode !== 'raw') {
      return false
    }
    if (InputValue.isInputValueList(inputValue.raw)) {
      return inputValue.raw.every(isAllRawValue)
    }
    if (InputValue.isStructEntryList(inputValue.raw)) {
      return inputValue.raw.every((it) => isAllRawValue(it.value))
    }
    switch (typeof inputValue.raw) {
      case 'string':
      case 'number':
      case 'boolean':
        return true
      default:
        assertNever(inputValue.raw)
    }
  }
}
