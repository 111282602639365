import React from 'react'

import { Result } from '~/common/Result'
import { apiClients } from '~/common/apiClients'
import { User } from '~/domain/user/User'

const service = apiClients.organizationService

export function useUserList(
  organizationId: string
): {
  result: Result<User[]>
} {
  const [result, setResult] = React.useState<Result<User[]>>(
    new Result.Loading()
  )
  React.useEffect(() => {
    let stale = false
    setResult(new Result.Loading())
    service
      .getUsers(organizationId)
      .then((res) => {
        if (stale) {
          return
        }
        setResult(new Result.Success(res))
      })
      .catch((e) => {
        if (stale) {
          return
        }
        console.error(e)
        setResult(new Result.Failure(e))
      })
    return () => {
      stale = true
    }
  }, [organizationId])

  return { result }
}
