import { useCallback } from 'react'

import { apiClients } from '~/common/apiClients'
import { NoOrganizationError } from '~/data/user/NoOrganizationError'
import {
  firebaseAuth,
  googleSignInWithPopup,
} from '~/presentation/auth/firebaseAuth'

const authService = apiClients.authService

type UserState =
  | 'not-authenticated'
  | 'auth-as-new-user'
  | 'auth-as-normal-user'

export function useGoogleSignUp(): {
  signUp: () => Promise<UserState>
} {
  const signUp = useCallback(async () => {
    try {
      const { user } = await googleSignInWithPopup()
      if (user === null) {
        return 'not-authenticated'
      }
      const idToken = await user.getIdToken()
      await authService.signUpWithGoogle(idToken)
      return 'auth-as-normal-user'
    } catch (error) {
      if (error instanceof NoOrganizationError) {
        return 'auth-as-new-user'
      }
      throw error
    } finally {
      await firebaseAuth().signOut()
    }
  }, [])

  return { signUp }
}
