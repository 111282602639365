import _ from 'lodash'

import { apiClients } from '~/common/apiClients'
import { assert, assertNever } from '~/common/utils'
import { TestPreviewApi } from '~/data/workflow/test/TestPreviewApi'
import { mapJsonToSimplifiedMessage } from '~/data/workflow/test/jsonToModelMapper'
import { Definitions } from '~/domain/Definitions'
import { AssistService } from '~/domain/workflow/assist/AssistService'
import { ExpectedObjects } from '~/domain/workflow/expectedObject/ExpectedObjects'
import { InputSource } from '~/domain/workflow/source/WorkflowSourceBody'
import {
  TestPreview,
  TestPreviewField,
  TestPreviewValue,
} from '~/domain/workflow/test/TestPreview'
import { TestPreviewService } from '~/domain/workflow/test/TestPreviewService'
import { ExpectedObjectSnapshot } from '~/domain/workflow/test/TestResult'
import { TestTarget } from '~/domain/workflow/test/TestTarget'
import {
  InputWidgetDefinition,
  RadioWidgetDefinition,
} from '~/domain/workflow/widget/WidgetDefinition'
import { AssistWidgetDefinition } from '~/domain/workflow/widget/assist'
import { RelativeDate } from '~/domain/workflow/widget/dateRange'
import { ListWidgetDefinition } from '~/domain/workflow/widget/list'
import { MultiAssistWidgetDefinition } from '~/domain/workflow/widget/multi_assist'
import {
  MultiSelectWidgetDefinition,
  SelectWidgetDefinition,
} from '~/domain/workflow/widget/select'
import { StructWidgetDefinition } from '~/domain/workflow/widget/struct'

const assistService: AssistService = apiClients.assistService
const testPreviewApi: TestPreviewApi = apiClients.testPreviewApi

interface ResolvedInput {
  fieldKey: string
  value: unknown
}

export class TestPreviewServiceImpl implements TestPreviewService {
  async getPreview(
    target: TestTarget,
    inputs: InputSource[],
    useBulk: boolean,
    expectedObjects: ExpectedObjects,
    snapshots: ExpectedObjectSnapshot[],
    definitions: Definitions
  ): Promise<TestPreview> {
    const previewResponse = await testPreviewApi.getPreview(
      target,
      inputs,
      useBulk,
      expectedObjects,
      snapshots
    )

    if (!previewResponse.isSuccess) {
      return {
        success: false,
        messages: previewResponse.messages.map(mapJsonToSimplifiedMessage),
        fields: [], // 失敗時に値があっても意味ないので空配列にする
      }
    }

    const triggerOrAction =
      target.kind === 'trigger'
        ? definitions.getTrigger(target.triggerId)
        : definitions.getAction(target.actionId)

    // フィールドの定義順を維持すること
    const previewFields: TestPreviewField[] = []
    for (const field of triggerOrAction.fields) {
      if (field.fieldType === 'static') {
        const previewValue = previewResponse.results.find(
          (it) => it.fieldKey === field.key
        )
        previewFields.push({
          fieldKey: field.key,
          fieldLabel: field.label,
          previewValue: await getTestPreviewValue(
            field.form,
            previewValue?.value,
            previewResponse.results
          ),
        })
        continue
      }
      if (field.fieldType === 'info_view') {
        // preview しないので無視
        continue
      }
      if (field.fieldType === 'dynamic') {
        // TODO: Dynamic Field
        continue
      }
      assertNever(field)
    }

    return {
      success: true,
      messages: [],
      fields: previewFields,
    }
  }
}

async function getTestPreviewValue(
  widget: InputWidgetDefinition,
  previewValue: unknown,
  resolvedInputs: ResolvedInput[]
): Promise<TestPreviewValue> {
  if (previewValue === undefined || previewValue === null) {
    // Preview API が null を返したときは値が入力されていないとき (null という値を入力したい場合は Jinja 記法になる）
    return undefined
  }
  switch (widget.formType) {
    case 'text':
    case 'multiline_text':
      return {
        kind: 'plain',
        value: getTextRepresentation(previewValue),
      }
    case 'legacy_account':
    case 'check':
    case 'number':
    case 'switch':
    case 'cron':
      return { kind: 'plain', value: String(previewValue) }
    case 'select':
    case 'radio':
      // セレクトウィジェットとラジオウィジェットではラベルに隠れた値を画面に表示していないため、ここでも表示しない
      return {
        kind: 'plain',
        value: getWidgetLabel(widget, previewValue),
      }
    case 'assist':
      return getAssistPreviewValue(widget, previewValue, resolvedInputs)
    case 'multi_select':
      return getMultiSelectPreviewValue(widget, previewValue)
    case 'multi_assist':
      return getMultiAssistPreviewValue(widget, previewValue, resolvedInputs)
    case 'account':
    case 'multi_provider_account':
      return getTestPreviewValue(widget.valueForm, previewValue, resolvedInputs)
    case 'struct':
      assert(
        typeof previewValue === 'object',
        `Type of previewValue should be object but was ${typeof previewValue}`
      )
      // 上でチェックしているので previewValue は絶対に null ではないが、コンパイラは知らないので assert
      assert(previewValue !== null, 'previewValue === null')
      return getStructWidgetTestPreviewValue(
        widget,
        previewValue,
        resolvedInputs
      )
    case 'list':
      assert(
        Array.isArray(previewValue),
        `Type of previewValue should be array but was ${typeof previewValue}`
      )
      return getListWidgetTestPreviewValue(widget, previewValue, resolvedInputs)
    case 'salesforce_search_condition':
      return { kind: 'none' }
    case 'dict':
      throw new Error('Not Implemented')
    case 'date_range': {
      assert(typeof previewValue === 'object', `object value required`)
      assert(previewValue !== null, 'previewValue === null')
      return {
        kind: 'object',
        entries: [
          {
            keyLabel: '開始',
            value: {
              kind: 'plain',
              value: new RelativeDate(previewValue['start']).toString(),
            },
          },
          {
            keyLabel: '終了',
            value: {
              kind: 'plain',
              value: new RelativeDate(previewValue['end']).toString(),
            },
          },
        ],
      }
    }
    default:
      assertNever(widget)
  }
}

async function getStructWidgetTestPreviewValue(
  widget: StructWidgetDefinition,
  value: object,
  resolvedInputs: ResolvedInput[]
): Promise<TestPreviewValue> {
  return {
    kind: 'object',
    entries: await Promise.all(
      widget.entries.map(async (entry) => {
        return {
          keyLabel: entry.label,
          value: await getTestPreviewValue(
            entry.valueForm,
            value[entry.key],
            resolvedInputs
          ),
        }
      })
    ),
  }
}

async function getListWidgetTestPreviewValue(
  widget: ListWidgetDefinition,
  values: unknown[],
  resolvedInputs: ResolvedInput[]
): Promise<TestPreviewValue> {
  return {
    kind: 'array',
    values: await Promise.all(
      values.map((value) => {
        return getTestPreviewValue(widget.itemForm, value, resolvedInputs)
      })
    ),
  }
}

function getWidgetLabel(
  definition: SelectWidgetDefinition | RadioWidgetDefinition,
  value: unknown
): string {
  // ウィジェット定義の Options から一致するラベルを検索し、見つからなければ `無効な値です` を表示する
  const found = definition.options.find((it) => _.isEqual(it, value))
  if (found === undefined) {
    console.warn(
      `No such value ${JSON.stringify(
        value
      )} found on options: ${JSON.stringify(definition.options)}`
    )
    return '無効な値です'
  }
  return found.label
}

async function getAssistPreviewValue(
  definition: AssistWidgetDefinition,
  value: unknown,
  resolvedInputs: ResolvedInput[]
): Promise<TestPreviewValue>
async function getAssistPreviewValue(
  definition: MultiAssistWidgetDefinition,
  value: unknown,
  resolvedInputs: ResolvedInput[]
): Promise<TestPreviewValue>
async function getAssistPreviewValue(
  definition: AssistWidgetDefinition | MultiAssistWidgetDefinition,
  value: unknown,
  resolvedInputs: ResolvedInput[]
): Promise<TestPreviewValue> {
  const parameterFieldKeys = new Set(definition.assist.parameterFieldKeys)
  const parameterResolvedInputs = resolvedInputs.filter((it) =>
    parameterFieldKeys.has(it.fieldKey)
  )
  const params = _.reduce(
    parameterResolvedInputs,
    (acc, current) => ({ ...acc, [current.fieldKey]: current.value }),
    {}
  )
  const response = await assistService.getLabel(
    definition.assist.assistId,
    params,
    value
  )
  // 値が取得できなかった時は AssistWidget と同じエラー文言を表示する
  if (response === undefined) {
    return {
      kind: 'plain',
      value: '無効な値です',
    }
  }
  return {
    kind: 'labeled',
    label: response.label,
    value: String(value),
  }
}

function getTextRepresentation(value: unknown): string {
  if (typeof value === 'string') {
    return value
  }
  return JSON.stringify(value, undefined, 2)
}

function getMultiSelectPreviewValue(
  definition: MultiSelectWidgetDefinition,
  value: unknown
): TestPreviewValue.Array {
  assert(_.isArray(value), 'value is not array')
  const labels = value.map((v) => {
    assert(typeof v === 'string', `value is not string: ${typeof v}`)
    const found = definition.options.find((it) => it.value === v)
    assert(found !== undefined, `value is not found on options: ${v}`)
    return found.label
  })
  return {
    kind: 'array',
    values: labels.map((it) => ({
      kind: 'plain',
      value: it,
    })),
  }
}

async function getMultiAssistPreviewValue(
  definition: MultiAssistWidgetDefinition,
  value: unknown,
  resolvedInputs: ResolvedInput[]
): Promise<TestPreviewValue.Array> {
  assert(_.isArray(value), 'value is not array')
  const labels = await Promise.all(
    value.map((v) => getAssistPreviewValue(definition, v, resolvedInputs))
  )
  return {
    kind: 'array',
    values: labels,
  }
}
