import styled from '@emotion/styled'
import React from 'react'
import * as Icon from 'react-feather'

import * as vars from '~/styles/variables'

interface Props {
  reverse?: boolean
  disabled?: boolean
  style?: React.CSSProperties
}

const ArrowButton: React.FC<Props> = (props) => {
  return (
    <Container
      reverse={props.reverse}
      disabled={props.disabled}
      style={props.style}
    >
      <Left />
      {props.children}
      <Right>
        <Icon.ArrowRight size={24} strokeWidth={2} />
      </Right>
    </Container>
  )
}

const Container = styled('div')(
  {
    display: 'inline-flex',
    alignItems: 'center',
    paddingTop: vars.space.s * 1.5,
    paddingRight: vars.space.m,
    paddingBottom: vars.space.s * 1.5,
    paddingLeft: vars.space.m,
    fontSize: vars.fontSize.m,
    fontWeight: vars.fontWeight.bold,
    textAlign: 'center',
    textDecoration: 'none',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
    borderRadius: vars.borderRadius.s,
    border: 'none',
    transition: 'all .14s ease',
  },
  (props: { reverse?: boolean; disabled?: boolean }) => ({
    color: props.reverse ? vars.color.theme : vars.color.white,
    backgroundColor: props.reverse ? vars.color.white : vars.color.theme,
    border: props.reverse ? `1.5px solid ${vars.color.theme}` : 'none',
    opacity: props.disabled ? 0.7 : 1,
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    ...(props.disabled
      ? {}
      : {
          '&:hover': {
            opacity: 0.9,
            boxShadow: '0 7px 14px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.08)',
            transform: 'translateY(-2px)',
          },
        }),
  })
)

const Left = styled('div')({
  flexGrow: 1,
})

const Right = styled('div')({
  flexGrow: 1,
  minWidth: 24 + vars.space.s,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
})

export default ArrowButton
