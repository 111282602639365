import { TaskSource } from '~/domain/workflow/source/WorkflowSourceBody'

export class TaskIterator implements IterableIterator<TaskSource> {
  private index: number = 0
  private tasks: TaskSource[] = []

  constructor(rootTask?: TaskSource) {
    if (rootTask === undefined) {
      return
    }
    const queue: TaskSource[] = [rootTask]
    while (queue.length > 0) {
      const task = queue.shift()
      if (task === undefined) {
        continue
      }
      this.tasks.push(task)
      queue.push(...task.tasks)
    }
  }

  next(): IteratorResult<TaskSource, undefined> {
    if (this.index >= this.tasks.length) {
      return { done: true, value: undefined }
    }
    const index = this.index
    this.index = index + 1
    return { value: this.tasks[index] }
  }

  [Symbol.iterator](): IterableIterator<TaskSource> {
    return this
  }
}
