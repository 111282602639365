import * as React from 'react'

export function useEscapeKey(
  callback: (event: KeyboardEvent) => void,
  deps?: Array<any>
) {
  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.stopPropagation()
        callback(e)
      }
    }
    document.addEventListener('keydown', handleKeyDown, { capture: true })
    return () => {
      document.removeEventListener('keydown', handleKeyDown, { capture: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
