import config from '~/common/config'
import { Tson } from '~/data/Tson'
import { requestPost } from '~/data/request'
import { mapJsonToExpectedObjects } from '~/data/workflow/expectedObject/mapJsonToExpectedObjects'
import { mapToExpectedObjectsJson } from '~/data/workflow/expectedObject/mapToExpectedObjectsJson'
import { mapModelToWorkflowSourceBodyJson } from '~/data/workflow/source/mapModelToWorkflowSourceBodyJson'
import { ExpectedObjectApi } from '~/domain/workflow/expectedObject/ExpectedObjectApi'
import { ExpectedObjects } from '~/domain/workflow/expectedObject/ExpectedObjects'
import { WorkflowSourceBody } from '~/domain/workflow/source/WorkflowSourceBody'

export class ExpectedObjectApiImpl implements ExpectedObjectApi {
  async get(
    sourceBody: WorkflowSourceBody,
    parentTaskId?: string
  ): Promise<ExpectedObjects> {
    // parentTaskId が存在しない場合は空を返す（ルートタスクに対しては固定値 "trigger" が指定される）
    if (parentTaskId === undefined) {
      return new ExpectedObjects()
    }
    const body = {
      source: mapModelToWorkflowSourceBodyJson(sourceBody),
      parent: parentTaskId,
    }
    const res = await requestPost(
      `${config.apiRoot}/experimental/expected_objects`,
      body
    )
    const json = mapToExpectedObjectsJson(new Tson(res))
    return mapJsonToExpectedObjects(json)
  }
}
