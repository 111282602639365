import * as React from 'react'

import { TriggerInstructionWidgetDefinition } from '~/domain/workflow/widget/WidgetDefinition'
import { WorkflowEditorContext } from '~/presentation/workflow/detail/editor/WorkflowEditorContext'
import { ViewWidgetProps } from '~/presentation/workflow/detail/editor/form/viewWidget/ViewWidget'
import TriggerInstructionWidget from '~/presentation/workflow/detail/editor/form/viewWidget/triggerInstruction/TriggerInstructionWidget'
import UnavailableTriggerInstructionWidget from '~/presentation/workflow/detail/editor/form/viewWidget/triggerInstruction/UnavailableTriggerInstructionWidget'

interface Props extends ViewWidgetProps {
  definition: TriggerInstructionWidgetDefinition
}

const ConditionalTriggerInstructionWidget: React.FC<Props> = (props) => {
  const workflowContext = React.useContext(WorkflowEditorContext)

  return workflowContext === undefined ? (
    <UnavailableTriggerInstructionWidget />
  ) : (
    <TriggerInstructionWidget
      {...props}
      definition={props.definition}
      workflowId={workflowContext.workflowId}
    />
  )
}

export default ConditionalTriggerInstructionWidget
