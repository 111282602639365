import { combineReducers } from 'redux'

import * as list from '~/ducks/message/list'

export interface MessageState {
  list: list.State
}

export default combineReducers<MessageState>({
  list: list.reducer,
})
