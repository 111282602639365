import dayjs from 'dayjs'

import { InvitationJson } from '~/data/user/invitation/InvitationJson'
import { Invitation } from '~/domain/user/Invitation'

export function mapJsonToInvitation(json: InvitationJson): Invitation {
  return {
    id: json.id,
    email: json.email,
    expireAt: dayjs(json.expireAt).toDate(),
    updatedAt: dayjs(json.updatedAt).toDate(),
  }
}
