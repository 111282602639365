import { assert } from '~/common/utils'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { ValidationResult } from '~/domain/workflow/validator/ValidationResult'
import { BaseWidgetDefinition } from '~/domain/workflow/widget/WidgetDefinition'
import { RawInputWidgetValidator } from '~/domain/workflow/widget/validator/RawInputWidgetValidator'

export interface DateRangeWidgetDefinition extends BaseWidgetDefinition {
  formType: 'date_range'
  startLimit: number
  endLimit: number
}

export class DateRangeValue {
  public readonly from: number
  public readonly to: number

  constructor(from: number = -1, to: number = 1) {
    this.from = from
    this.to = to
  }

  static fromRange(range?: { from: number; to: number }): DateRangeValue {
    return new DateRangeValue(range?.from, range?.to)
  }

  static fromInputValue(
    inputValue: InputValue.Raw | undefined
  ): DateRangeValue {
    if (inputValue === undefined) {
      return new DateRangeValue()
    }
    assert(
      InputValue.isStructEntryList(inputValue.raw),
      `Struct value required`
    )
    const startInputValue = inputValue.raw.find((it) => it.key === 'start')
      ?.value
    const endInputValue = inputValue.raw.find((it) => it.key === 'end')?.value
    const start =
      startInputValue === undefined ||
      startInputValue.mode !== 'raw' ||
      typeof startInputValue.raw !== 'number'
        ? undefined
        : startInputValue.raw
    const end =
      endInputValue === undefined ||
      endInputValue.mode !== 'raw' ||
      typeof endInputValue.raw !== 'number'
        ? undefined
        : endInputValue.raw
    return new DateRangeValue(start, end)
  }

  toInputValue(): InputValue.Raw | undefined {
    return InputValue.createRawInputValue({
      start: this.from,
      end: this.to,
    })
  }
}

export class DateRangeWidgetValidator extends RawInputWidgetValidator<
  DateRangeWidgetDefinition
> {
  async validate(inputValue: InputValue.Raw): Promise<ValidationResult> {
    const value = DateRangeValue.fromInputValue(inputValue)

    if (value.from < this.context.widgetDefinition.startLimit) {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat(
          `${new RelativeDate(
            this.context.widgetDefinition.startLimit
          )}より前の日付は選択できません`
        )
      )
    }

    if (value.to > this.context.widgetDefinition.endLimit) {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat(
          `${new RelativeDate(
            this.context.widgetDefinition.startLimit
          )}より後の日付は選択できません`
        )
      )
    }

    return ValidationResult.valid()
  }
}

export class RelativeDate {
  constructor(public readonly relativeDate: number) {}

  toString(): string {
    if (this.relativeDate < 0) {
      return `${Math.abs(this.relativeDate)}日前`
    }
    return `${this.relativeDate}日後`
  }
}
