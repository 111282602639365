import styled from '@emotion/styled'
import { HierarchyPointNode } from 'd3'
import * as _ from 'lodash'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { FetchStatus } from '~/common/types'
import * as utils from '~/common/utils'
import Button from '~/components/atoms/Button'
import Loader from '~/components/atoms/Loader'
import Text from '~/components/atoms/Text'
import StatusWithTooltip from '~/components/molecules/StatusWithTooltip'
import User from '~/components/molecules/User'
import NoResults from '~/components/organisms/NoResults'
import NodeTree from '~/components/organisms/NodeTree'
import { RunningStatus } from '~/domain/workflow/instance/RunningStatus'
import { TaskInstanceSummary } from '~/domain/workflow/instance/taskInstance/TaskInstanceSummary'
import * as messageListDuck from '~/ducks/message/list'
import * as taskInstanceListDuck from '~/ducks/taskInstance/list'
import * as messageViewerDuck from '~/ducks/ui/messageViewer'
import { Node, useWorkflowTree } from '~/hooks/useTree'
import MessageWindow from '~/presentation/workflow/history/detail/MessageWindow'
import { useWorkflowInstance } from '~/presentation/workflow/history/detail/useWorkflowInstance'
import * as routes from '~/routes'
import * as vars from '~/styles/variables'

const HistoryDetail: React.FC = () => {
  const dispatch = useDispatch()
  const params = useParams<routes.HistoryDetailParams>()
  const taskInstances = useSelector(
    taskInstanceListDuck.selectors.getTaskInstances
  )
  const {
    workflowInstance,
    refetch: refetchWorkflowInstance,
    fetchStatus,
  } = useWorkflowInstance(params.workflowInstanceId)
  const [nodes, links] = useWorkflowTree(workflowInstance?.frozenSource)
  const messageViewer = useSelector(messageViewerDuck.selectors.getState)
  const [messageViewerAutoOpened, setMessageViewerAutoOpened] = React.useState<
    boolean
  >(false)

  React.useEffect(() => {
    fetchAndSync()
    return () => {
      dispatch(taskInstanceListDuck.actions.stopSync())
      dispatch(taskInstanceListDuck.actions.reset())
      dispatch(messageListDuck.actions.reset())
      dispatch(messageViewerDuck.actions.reset())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const timer = setInterval(() => {
      refetchWorkflowInstance(true)
    }, 5000)
    return () => {
      clearInterval(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.workflowInstanceId])

  // エラー状態のタスクがある場合はそのタスクのメッセージウィンドウを開く
  React.useEffect(() => {
    // すでに自動でメッセージウィンドウが開かれたことがある場合はこれ以上何もしない。
    // ユーザーが既に手動で閉じていた場合に再度開いてしまうことを避けるため。
    if (messageViewerAutoOpened) {
      return
    }

    // なんらかの要因ですでにメッセージウィンドウが開かれているか、準備ができていなければ抜ける
    if (messageViewer.isShown || taskInstances.length <= 0) {
      return
    }

    const firstFailedTask = _.first(
      taskInstances.filter((task) => task.state === RunningStatus.error)
    )
    // エラーとなっているタスクが存在しなければ抜ける
    if (!firstFailedTask) {
      return
    }

    dispatch(
      messageViewerDuck.actions.show({
        taskId: firstFailedTask.taskId,
      })
    )
    dispatch(
      messageListDuck.actions.fetch.started({
        filter: {
          taskInstanceIds: [firstFailedTask.taskInstanceId],
        },
        page: 1,
      })
    )
    setMessageViewerAutoOpened(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, taskInstances])

  function fetchAndSync() {
    dispatch(taskInstanceListDuck.actions.stopSync())
    dispatch(
      taskInstanceListDuck.actions.fetch.started({
        workflowInstanceId: params.workflowInstanceId,
        onSuccess: () => {
          dispatch(
            taskInstanceListDuck.actions.startSync({
              workflowInstanceId: params.workflowInstanceId,
            })
          )
        },
      })
    )
  }

  const handleActionClick = React.useCallback(
    (
      e: React.MouseEvent<SVGCircleElement, MouseEvent>,
      node: HierarchyPointNode<Node>,
      taskInstance?: TaskInstanceSummary
    ) => {
      if (!taskInstance) {
        return
      }
      if (messageViewer.taskId === node.data.nodeId) {
        dispatch(messageViewerDuck.actions.hide())
        return
      }
      dispatch(
        messageViewerDuck.actions.show({
          taskId: node.data.nodeId,
        })
      )
      dispatch(
        messageListDuck.actions.fetch.started({
          filter: {
            taskInstanceIds: [taskInstance.taskInstanceId],
          },
          page: 1,
        })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messageViewer]
  )

  return (
    <Container>
      {fetchStatus === FetchStatus.loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Loader />
        </div>
      ) : fetchStatus === FetchStatus.loaded && workflowInstance ? (
        <>
          <HeaderList>
            <HeaderItem>
              <HeaderLabel
                element="span"
                color="secondary"
                fontSize="xs"
                lineHeight="just"
              >
                ステータス
              </HeaderLabel>
              <StatusWithTooltip status={workflowInstance.runningStatus} />
            </HeaderItem>
            <HeaderItem>
              <HeaderLabel
                element="span"
                color="secondary"
                fontSize="xs"
                lineHeight="just"
              >
                実行日時
              </HeaderLabel>
              <Text
                element="span"
                fontSize="s"
                lineHeight="just"
                style={{ marginTop: vars.space.xs }}
              >
                {utils.formatDate(workflowInstance.updatedAt)}
              </Text>
            </HeaderItem>
            <HeaderItem>
              <HeaderLabel
                element="span"
                color="secondary"
                fontSize="xs"
                lineHeight="just"
              >
                実行者
              </HeaderLabel>
              <User username={workflowInstance.runner.username} />
            </HeaderItem>
          </HeaderList>
          <NodeTree
            nodes={nodes}
            links={links}
            taskInstances={taskInstances}
            selectedTaskId={
              messageViewer.taskId ? messageViewer.taskId : undefined
            }
            onActionClick={handleActionClick}
          />
          <MessageWindow workflowInstance={workflowInstance} />
        </>
      ) : (
        <NoResults
          heading="実行履歴が見つかりません"
          description="削除されたか、URL が間違っている可能性があります"
          renderFooter={() => (
            <Button to={routes.HISTORY_LIST} params={params}>
              実行履歴リストへ
            </Button>
          )}
        />
      )}
    </Container>
  )
}

const Container = styled('div')({
  padding: vars.space.l,
  width: '100%',
})

const HeaderList = styled('div')({
  display: 'flex',
  marginBottom: vars.space.xl,
})

const HeaderItem = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  marginLeft: vars.space.l,
  '&:first-of-type': {
    marginLeft: 0,
  },
})

const HeaderLabel = styled(Text)({
  marginBottom: vars.space.s,
})

export default HistoryDetail
