import { assert } from '~/common/utils'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { SalesforceSObject } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSObject'
import {
  SalesforceOperandType,
  SalesforceSearchConditionOperator,
} from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchConditionOperator'
import { SalesforceSObjectField } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchConditionService'

export class SalesforceSearchCondition {
  public readonly field: SalesforceSObjectField | undefined
  public readonly operator: SalesforceSearchConditionOperator | undefined
  public readonly operand: InputValue

  constructor(
    field?: SalesforceSObjectField,
    operator?: SalesforceSearchConditionOperator,
    operand?: InputValue
  ) {
    this.field = field
    this.operator = operator
    this.operand = operand
  }

  static fromInputValue(
    sObject: SalesforceSObject,
    inputValue: InputValue
  ): SalesforceSearchCondition {
    if (inputValue !== undefined && inputValue.mode === 'render') {
      throw new Error('render value is not supported')
    }

    const {
      field: fieldName,
      operator: operatorId,
      operand,
    } = unpackInputValue(inputValue)

    if (fieldName === undefined) {
      return new SalesforceSearchCondition(undefined, undefined, undefined)
    }
    const field = sObject.findField(fieldName)
    if (field === undefined) {
      // Salesforce アカウントおよびSオブジェクトのフィールドの値が変更されるか、
      // Salesforce のSオブジェクトの構造自体が外部で変更された時などに発生する
      return new SalesforceSearchCondition(undefined, undefined, undefined)
    }

    if (operatorId === undefined) {
      return new SalesforceSearchCondition(field, undefined, undefined)
    }
    const operator = field.dataType.operators.find((it) => it.id === operatorId)
    if (operator === undefined) {
      // Salesforce アカウントおよびSオブジェクトのフィールドの値が変更されるか、
      // Salesforce のSオブジェクトの構造自体が外部で変更された時などに発生する
      // （偶然同じ名前のフィールドがあったが、フィールドのデータ型のみ異なる場合）
      return new SalesforceSearchCondition(field, undefined, undefined)
    }

    return new SalesforceSearchCondition(field, operator, operand)
  }

  setField(
    field: SalesforceSObjectField | undefined
  ): SalesforceSearchCondition {
    if (field === undefined) {
      return new SalesforceSearchCondition(undefined, undefined, undefined)
    }
    // フィールドを変更した時にオペレータとオペランドをクリア
    return new SalesforceSearchCondition(field, undefined, undefined)
  }

  setOperator(operatorId: string | undefined): SalesforceSearchCondition {
    assert(this.field !== undefined, 'Select field first')
    if (operatorId === undefined) {
      return new SalesforceSearchCondition(this.field, undefined, undefined)
    }
    const operator = this.operators.find((it) => it.id === operatorId)
    if (operator === undefined) {
      throw new Error(
        `You can only set operator that exists on the operator list. operator id: ${operatorId}`
      )
    }
    // オペレータを変更した時にオペランドをクリア
    return new SalesforceSearchCondition(this.field, operator, undefined)
  }

  setOperand(operand: InputValue): SalesforceSearchCondition {
    assert(this.field !== undefined, 'Select field first')
    assert(this.operator !== undefined, 'Select operator first')
    return new SalesforceSearchCondition(this.field, this.operator, operand)
  }

  get operators(): SalesforceSearchConditionOperator[] {
    return this.field?.dataType.operators ?? []
  }

  get operandType(): SalesforceOperandType {
    return this.operator?.operandType ?? { name: 'none' }
  }

  toInputValue(): InputValue.Raw | undefined {
    return packInputValue(this.field?.id, this.operator?.id, this.operand)
  }
}

function unpackInputValue(
  inputValue: InputValue.Raw | undefined
): {
  field: string | undefined
  operator: string | undefined
  operand: InputValue
} {
  if (inputValue === undefined) {
    return { field: undefined, operator: undefined, operand: undefined }
  }
  if (!InputValue.isStructEntryList(inputValue.raw)) {
    throw new Error(`inputValue.raw is not struct value`)
  }
  const fieldInputValue = inputValue.raw.find((it) => it.key === 'field')?.value
  if (fieldInputValue === undefined) {
    return { field: undefined, operator: undefined, operand: undefined }
  }
  if (fieldInputValue.mode === 'render') {
    throw new Error(`field doesn't support render mode`)
  }
  if (typeof fieldInputValue.raw !== 'string') {
    throw new Error(`field raw value must be of type string`)
  }
  const operatorInputValue = inputValue.raw.find((it) => it.key === 'operator')
    ?.value
  if (operatorInputValue === undefined) {
    return {
      field: fieldInputValue.raw,
      operator: undefined,
      operand: undefined,
    }
  }
  if (operatorInputValue.mode === 'render') {
    throw new Error(`operator doesn't support render mode`)
  }
  if (typeof operatorInputValue.raw !== 'string') {
    throw new Error(`operator raw value must be of type string`)
  }
  const operandInputValue = inputValue.raw.find((it) => it.key === 'operand')
    ?.value
  if (operandInputValue === undefined) {
    return {
      field: fieldInputValue.raw,
      operator: operatorInputValue.raw,
      operand: undefined,
    }
  }
  return {
    field: fieldInputValue.raw,
    operator: operatorInputValue.raw,
    operand: operandInputValue,
  }
}

function packInputValue(
  field: string | undefined,
  operator: string | undefined,
  operand: InputValue
): InputValue.Raw | undefined {
  if (field === undefined) {
    return undefined
  }
  if (operator === undefined) {
    return {
      mode: 'raw',
      raw: [
        {
          key: 'field',
          value: { mode: 'raw', raw: field },
        },
        {
          key: 'operator',
          value: undefined,
        },
        {
          key: 'operand',
          value: undefined,
        },
      ],
    }
  }
  return {
    mode: 'raw',
    raw: [
      {
        key: 'field',
        value: { mode: 'raw', raw: field },
      },
      {
        key: 'operator',
        value: { mode: 'raw', raw: operator },
      },
      {
        key: 'operand',
        value: operand,
      },
    ],
  }
}
