import styled from '@emotion/styled'
import * as React from 'react'
import {
  AnimatedValue,
  ForwardedProps,
  animated,
  useSpring,
} from 'react-spring'
import { Spring } from 'react-spring/renderprops-universal'

import StatusIcon from '~/components/atoms/StatusIcon'
import Text from '~/components/atoms/Text'
import ActionStateTip from '~/components/molecules/ActionNode/ActionStateTip'
import NodeChangeButton from '~/components/molecules/NodeChangeButton'
import NodeDeleteButton from '~/components/molecules/NodeDeleteButton'
import ForeignObject from '~/components/utils/ForeignObject'
import { ActionState } from '~/domain/workflow/action/ActionState'
import { AppDefinition } from '~/domain/workflow/app/AppDefinition'
import { RunningStatus } from '~/domain/workflow/instance/RunningStatus'

interface Props {
  name: string
  supportState: ActionState
  app: AppDefinition
  x: number
  y: number
  selected?: boolean
  runningStatus?: RunningStatus
  animatedValue?: AnimatedValue<ForwardedProps<React.CSSProperties>>
  showSupportState?: boolean // default: false
  readonly: boolean
  onClick?: React.MouseEventHandler<SVGCircleElement>
  onRemoveClick?: () => void
  onChangeActionClick?: React.MouseEventHandler<HTMLDivElement>
}

export const actionNodeSize = 150
const actionStateTipHeight = 90

const ActionNode: React.FunctionComponent<Props> = ({
  name,
  supportState,
  app,
  x,
  y,
  selected,
  runningStatus,
  animatedValue,
  showSupportState = false,
  readonly,
  onClick,
  onRemoveClick,
  onChangeActionClick,
}) => {
  const [hovering, setHovering] = React.useState(false)
  const removeButtonProps = useSpring({
    to: {
      opacity: hovering ? 1 : 0,
      transform: hovering ? 'scale(1)' : 'scale(0.7)',
    },
    config: { tension: 500 },
  })

  return (
    <TaskGroupContainer transform={`translate(${x}, ${y})`}>
      <animated.g
        style={{
          ...(animatedValue || {}),
          transformOrigin: `${actionNodeSize / 2}px ${actionNodeSize / 2}px`,
        }}
      >
        {showSupportState ? (
          <g transform={`translate(0, -${actionStateTipHeight})`}>
            <ForeignObject width={actionNodeSize} height={actionStateTipHeight}>
              <ActionStateTip supportState={supportState} />
            </ForeignObject>
          </g>
        ) : null}
        {runningStatus ? (
          <g transform={`translate(${actionNodeSize - 20}, -20)`}>
            <ForeignObject width={40} height={40}>
              <StatusIcon status={runningStatus} />
            </ForeignObject>
          </g>
        ) : null}
        <g
          onMouseEnter={() => onClick && setHovering(true)}
          onMouseLeave={() => onClick && setHovering(false)}
        >
          <Spring
            from={{ transform: 'scale(1)' }}
            to={{
              transform: hovering || selected ? 'scale(1.13)' : 'scale(1)',
            }}
            config={{ tension: 500 }}
          >
            {(props) => (
              <animated.circle
                cx={actionNodeSize / 2}
                cy={actionNodeSize / 2}
                r={actionNodeSize / 2}
                fill={app.color}
                fillOpacity={0.5}
                style={{
                  ...props,
                  transformOrigin: `${actionNodeSize / 2}px ${
                    actionNodeSize / 2
                  }px`,
                }}
              />
            )}
          </Spring>
          <circle
            cx={actionNodeSize / 2}
            cy={actionNodeSize / 2}
            r={actionNodeSize / 2}
            fill={app.color}
            cursor={onClick ? 'pointer' : 'auto'}
            // tabIndex={0}
            onClick={onClick}
          />
          <image
            // 画像サイズの半分
            x={(actionNodeSize - 90) / 2}
            y={(actionNodeSize - 90) / 2}
            xlinkHref={app.iconPath}
            width={90}
            height={90}
            pointerEvents="none"
          />
          {onChangeActionClick ? (
            <g transform={`translate(-15, -10)`}>
              <ForeignObject width={50} height={50}>
                {/* ホバー可能範囲を広げるための div */}
                <div
                  style={{
                    width: 50,
                    height: 50,
                  }}
                >
                  <animated.div
                    style={{
                      transformOrigin: `15px 15px`,
                      ...removeButtonProps,
                    }}
                  >
                    <NodeChangeButton
                      disabled={readonly}
                      onClick={onChangeActionClick}
                    />
                  </animated.div>
                </div>
              </ForeignObject>
            </g>
          ) : null}
          {onRemoveClick ? (
            <g transform={`translate(${actionNodeSize - 35}, -10)`}>
              <ForeignObject width={50} height={50}>
                {/* ホバー可能範囲を広げるための div */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: 50,
                    height: 50,
                  }}
                >
                  <animated.div
                    style={{
                      transformOrigin: `15px 15px`,
                      ...removeButtonProps,
                    }}
                  >
                    <NodeDeleteButton
                      disabled={readonly}
                      onClick={onRemoveClick}
                    />
                  </animated.div>
                </div>
              </ForeignObject>
            </g>
          ) : null}
        </g>
        <g transform={`translate(-30, ${actionNodeSize + 23})`}>
          <ForeignObject width={30 + actionNodeSize + 30} height={30}>
            <Text
              element="p"
              color="primary"
              fontSize="m"
              fontWeight="bold"
              lineHeight="just"
              truncated={true}
              style={{
                textAlign: 'center',
              }}
            >
              {app.name}
            </Text>
          </ForeignObject>
        </g>
        <g transform={`translate(-30, ${actionNodeSize + 48})`}>
          <ForeignObject width={30 + actionNodeSize + 30} height={55}>
            <Text
              element="p"
              color="secondary"
              fontSize="s"
              fontWeight="normal"
              lineHeight="heading"
              style={{
                textAlign: 'center',
              }}
            >
              {name}
            </Text>
          </ForeignObject>
        </g>
      </animated.g>
    </TaskGroupContainer>
  )
}

const TaskGroupContainer = styled('g')({
  transition: 'transform .2s .2s ease',
})

export default ActionNode
