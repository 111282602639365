import * as React from 'react'

interface Props extends React.SVGAttributes<SVGElement> {
  size?: number
  fill?: string
}

const Triangle: React.FC<Props> = ({ size = 12, fill = 'black', ...props }) => (
  <svg width={size} height={size} viewBox="0 0 12 12" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M9.75192456,6.9635332 L3.5547002,11.0950161 C3.09517151,11.4013686 2.47430216,11.2771947 2.16794971,10.817666 C2.05843803,10.6533985 2,10.4603908 2,10.2629658 L2,2 C2,1.44771525 2.44771525,1 3,1 C3.19742499,1 3.39043268,1.05843803 3.5547002,1.16794971 L9.75192456,5.29943261 C10.2114532,5.60578507 10.3356271,6.22665442 10.0292747,6.6861831 C9.9560398,6.79603539 9.86177685,6.89029834 9.75192456,6.9635332 Z"
        fill={fill}
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default React.memo(Triangle)
