import * as React from 'react'
import { Helmet } from 'react-helmet'
import { DispatchProp, connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'

import ErrorBoundary from '~/components/common/ErrorBoundary'
import GlobalStyles from '~/components/common/GlobalStyles'
import Tip from '~/components/common/Tip'
import Toaster from '~/presentation/Toaster'

interface OwnProps {
  children: React.ReactNode
}

type Props = OwnProps & RouteComponentProps & DispatchProp

class App extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const { children } = this.props
    return (
      <ErrorBoundary showLogo={true}>
        <Helmet
          meta={[]}
          titleTemplate="%s | Anyflow"
          defaultTitle="Anyflow | SaaSを繋ぐ業務自動化iPaaS"
        />
        <Toaster>{children}</Toaster>
        <Tip />
        <GlobalStyles />
      </ErrorBoundary>
    )
  }
}

export default withRouter(connect()(App))
