import styled from '@emotion/styled'
import * as React from 'react'

import * as vars from '~/styles/variables'

interface TableProps {
  className?: string
  children: React.ReactNode
}

export const Table: React.FunctionComponent<
  TableProps & React.HTMLAttributes<HTMLTableElement>
> = ({ className, children }) => (
  <StyledTable className={className}>{children}</StyledTable>
)

const StyledTable = styled('table')({
  width: '100%',
  borderCollapse: 'collapse',
})

interface HeaderProps {
  className?: string
  children: React.ReactNode
}

export const Header: React.FunctionComponent<
  HeaderProps & React.HTMLAttributes<HTMLTableHeaderCellElement>
> = ({ className, children }) => (
  <StyledHeader className={className}>{children}</StyledHeader>
)

const StyledHeader = styled('thead')({})

interface BodyProps {
  className?: string
  children: React.ReactNode
}

export const Body: React.FunctionComponent<
  BodyProps & React.HTMLAttributes<HTMLTableSectionElement>
> = ({ className, children }) => (
  <StyledBody className={className}>{children}</StyledBody>
)

const StyledBody = styled('tbody')({
  '& tr:hover': {
    backgroundColor: vars.color.offWhite,
  },
})

interface RowProps {
  className?: string
  children: React.ReactNode
}

export const Row: React.FunctionComponent<
  RowProps & React.HTMLAttributes<HTMLTableRowElement>
> = ({ className, children, ...props }) => (
  <StyledRow className={className} {...props}>
    {children}
  </StyledRow>
)

const StyledRow = styled('tr')({})

interface CellProps {
  className?: string
  children: React.ReactNode
}

export const Cell: React.FunctionComponent<
  CellProps & React.HTMLAttributes<HTMLTableDataCellElement>
> = ({ className, children, ...props }) => (
  <StyledCell className={className} {...props}>
    {children}
  </StyledCell>
)

const StyledCell = styled('td')({
  paddingTop: vars.space.s * 1.5,
  paddingRight: vars.space.s,
  paddingBottom: vars.space.s * 1.5,
  paddingLeft: vars.space.s,
  fontSize: vars.fontSize.s,
  '&:first-of-type': {
    paddingLeft: 0,
  },
})

interface HeaderCellProps
  extends React.AllHTMLAttributes<HTMLTableHeaderCellElement> {
  className?: string
  children: React.ReactNode
}

export const HeaderCell: React.FunctionComponent<HeaderCellProps> = ({
  className,
  children,
  ...props
}) => (
  <StyledHeaderCell className={className} {...props}>
    {children}
  </StyledHeaderCell>
)

const StyledHeaderCell = styled('th')({
  padding: vars.space.s,
  color: vars.fontColor.tertiary,
  fontSize: vars.fontSize.s,
  fontWeight: vars.fontWeight.normal,
  textAlign: 'left',
  '&:first-of-type': {
    paddingLeft: 0,
  },
})
