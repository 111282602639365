import { delay } from '~/common/utils'
import {
  TriggerInstructionParameters,
  TriggerInstructionService,
} from '~/domain/workflow/triggerInstruction/TriggerInstructionService'
import { ViewUnitDefinition } from '~/domain/workflow/viewUnit/ViewUnitDefinition'

export class MockTriggerInstructionService
  implements TriggerInstructionService {
  async getList(
    triggerInstructionId: string,
    params: TriggerInstructionParameters
  ): Promise<ViewUnitDefinition[]> {
    console.log(
      `[API] Mock trigger instruction API called.\ntriggerInstructionId: ${triggerInstructionId}\nparams: ${JSON.stringify(
        params
      )}\n`
    )
    await delay(500)
    return [
      {
        type: 'copy',
        label: 'URL',
        value: 'https://app.anyflow.jp/',
      },
    ]
  }
}
