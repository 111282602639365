import { apiClients } from '~/common/apiClients'
import { AssertionError, assert } from '~/common/utils'
import { AssistParameters } from '~/domain/workflow/assist/AssistService'
import { AssistedValue } from '~/domain/workflow/assist/AssistedValue'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { ValidationResult } from '~/domain/workflow/validator/ValidationResult'
import {
  BaseWidgetDefinition,
  InputWidgetDefinition,
} from '~/domain/workflow/widget/WidgetDefinition'
import { RawInputWidgetValidator } from '~/domain/workflow/widget/validator/RawInputWidgetValidator'
import { createRawWidgetValidator } from '~/domain/workflow/widget/validator/createRawWidgetValidator'

const assistService = apiClients.assistService

export interface AssistWidgetDefinition extends BaseWidgetDefinition {
  formType: 'assist'
  assist: {
    assistId: string
    queryPlaceholder: string
    parameterFieldKeys: string[]
  }
  fallbackForm: InputWidgetDefinition
}

export class AssistWidgetValidator extends RawInputWidgetValidator<
  AssistWidgetDefinition
> {
  async validate(inputValue: InputValue.Raw): Promise<ValidationResult> {
    const assistDefinition = this.context.widgetDefinition.assist

    // 依存値チェック
    assistDefinition.parameterFieldKeys.forEach((key) => {
      if (!this.context.dependentValues.hasOwnProperty(key)) {
        throw new AssertionError(
          `This AssistWidget depends on field '${key}' but its value is not passed. Missing dependencies?`
        )
      }
    })

    // 依存値に変数が含まれていればフォールバック
    if (
      Object.values(this.context.dependentValues).some(
        (dependentInputValue) => !InputValue.isAllRawValue(dependentInputValue)
      )
    ) {
      console.debug('AssistWidgetValidator: fallback')
      return createRawWidgetValidator({
        ...this.context,
        widgetDefinition: this.context.widgetDefinition.fallbackForm,
      }).validate(inputValue)
    }

    // ラベルを取得
    const assistParameters: AssistParameters = {}
    Object.entries(this.context.dependentValues).forEach(([key, value]) => {
      if (value === undefined) {
        assistParameters[key] = null
        return
      }
      // 既に上で依存値に変数が含まれていないことを確認しているので assert
      assert(value.mode === 'raw', `value.mode !== 'raw'`)
      assistParameters[key] = InputValue.convertRawInputValueToObject(value)
    })
    let label: AssistedValue | undefined
    try {
      label = await assistService.getLabel(
        assistDefinition.assistId,
        assistParameters,
        InputValue.convertRawInputValueToObject(inputValue)
      )
    } catch (e) {
      console.error(e)
      return ValidationResult.invalid(
        new ValidationResult.Failed(e, 'バリデーションに失敗しました')
      )
    }

    // ラベルが見つからなければ BadFormat
    if (label === undefined) {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat('この値は使用できません')
      )
    }

    return ValidationResult.valid()
  }
}
