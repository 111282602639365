import dayjs from 'dayjs'

import { JsonError } from '~/data/JsonError'
import { Tson } from '~/data/Tson'
import { mapToExpectedObjectJson } from '~/data/workflow/expectedObject/mapToExpectedObjectsJson'
import { mapToObjectPropertyValueJson } from '~/data/workflow/object/mapToObjectPropertyValueJson'
import {
  ExpectedObjectSnapshotJson,
  SimplifiedMessageJson,
  TestPreviewJson,
  TestResultJson,
} from '~/data/workflow/test/json'

export function mapToTestResultJson(tson: Tson): TestResultJson {
  const testedAt = tson.getString('testedAt')
  if (!dayjs(testedAt).isValid()) {
    throw new JsonError(`Invalid date format for 'testedAt': ${testedAt}`)
  }
  return {
    snapshots: tson.getArray('snapshots').map(mapToExpectedObjectSnapshotJson),
    isSuccess: tson.getBoolean('isSuccess'),
    expectedObjects: tson
      .getArray('expectedObjects')
      .map(mapToExpectedObjectJson),
    messages: tson.getArray('messages').map(mapToSimplifiedMessageJson),
    testedAt,
  }
}

export function mapToTestPreviewJson(tson: Tson): TestPreviewJson {
  return {
    isSuccess: tson.getBoolean('isSuccess'),
    messages: tson.getArray('messages').map(mapToSimplifiedMessageJson),
    results: tson.getArray('results').map((subTson) => ({
      fieldKey: subTson.getString('fieldKey'),
      value: subTson.getValue('value', false)?.asIs() ?? null,
    })),
  }
}

function mapToExpectedObjectSnapshotJson(
  tson: Tson
): ExpectedObjectSnapshotJson {
  return {
    expectedObjectKey: tson.getString('expectedObjectKey'),
    values: tson.getArray('values').map(mapToObjectPropertyValueJson),
  }
}

function mapToSimplifiedMessageJson(tson: Tson): SimplifiedMessageJson {
  const type = tson.getString('typ')
  switch (type) {
    case 'info':
    case 'warning':
    case 'error':
      break
    default:
      throw new JsonError(`Unknown message type: ${type}`)
  }
  return {
    body: { text: tson.getValue('body').getString('text') },
    typ: type,
  }
}
