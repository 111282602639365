import styled from '@emotion/styled'
import React from 'react'
import ReactContentLoader from 'react-content-loader'

import Button from '~/components/atoms/Button'
import { DropdownMenuItem } from '~/components/atoms/DropdownMenu'
import Text from '~/components/atoms/Text'
import MoreActionsButtonWithDropdownMenu from '~/components/molecules/MoreActionsButtonWithDropdownMenu'
import NoResults from '~/components/organisms/NoResults'
import {
  Table,
  Body as Tbody,
  Cell as Td,
  HeaderCell as Th,
  Header as Thead,
  Row as Tr,
} from '~/components/organisms/Table'
import { HttpError } from '~/data/request'
import { Permission } from '~/domain/workflow/permission/Permission'
import { PermissionPolicy } from '~/domain/workflow/permission/PermissionPolicy'
import { PermissionSelect } from '~/presentation/PermissionSelect'
import { useToaster } from '~/presentation/ToasterContext'
import { ConnectionAddPermissionModal } from '~/presentation/socialAccount/ConnectionAddPermissionModal'
import { useConnectionPermissionList } from '~/presentation/socialAccount/useConnectionPermissionList'
import * as vars from '~/styles/variables'

interface Props {
  connectionId: string
}

export const ConnectionShareTabContent: React.FC<Props> = (props) => {
  const toaster = useToaster()
  const {
    result,
    addingGroupIds,
    changingPermissionIds,
    removingPermissionIds,
    addPermission,
    changePermissionPolicy,
    removePermission,
  } = useConnectionPermissionList(props.connectionId)

  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)

  const filteredPermissions: Permission[] = React.useMemo(() => {
    if (result.loading || result.error) {
      return []
    }
    return result.data.filter(
      (it) => !(it.policy instanceof PermissionPolicy.Ownable)
    )
  }, [result])

  const addedGroupIds: string[] = React.useMemo(
    () =>
      result.loading || result.error
        ? []
        : result.data.map((it) => it.group.id),
    [result]
  )

  const ownerGroupIds: string[] = React.useMemo(() => {
    if (result.loading || result.error) {
      return []
    }
    return result.data
      .filter((it) => it.policy instanceof PermissionPolicy.Ownable)
      .map((it) => it.group.id)
  }, [result])

  const handleChangePermission = React.useCallback(
    async (permissionId: string, newPolicy: PermissionPolicy) => {
      try {
        await changePermissionPolicy(permissionId, newPolicy)
      } catch (e) {
        console.error(e)
        toaster.showError(`権限の変更に失敗しました`)
      }
    },
    [changePermissionPolicy, toaster]
  )

  const handleRemovePermission = React.useCallback(
    async (permissionId: string) => {
      if (!window.confirm('共有を取り消しますか？')) {
        return
      }
      try {
        await removePermission(permissionId)
      } catch (e) {
        console.error(e)
        toaster.showError(`共有の取り消しに失敗しました`)
      }
    },
    [removePermission, toaster]
  )

  const handleAddPermission = React.useCallback(
    async (groupId: string) => {
      try {
        await addPermission(groupId, new PermissionPolicy.Readable())
      } catch (e) {
        console.error(e)
        toaster.showError(`共有に失敗しました`)
      }
    },
    [addPermission, toaster]
  )

  const handleModalOpen = React.useCallback(() => setIsModalOpen(true), [])

  const handleModalClose = React.useCallback(() => setIsModalOpen(false), [])

  return (
    <Container>
      <Header>
        <div>
          <Text element="h1" fontWeight="bold" fontSize="xl">
            共有済みのグループ
          </Text>
        </div>
        <div>
          <Button
            onClick={handleModalOpen}
            disabled={result.loading || result.error !== undefined}
          >
            共有するグループを追加
          </Button>
        </div>
      </Header>
      <div>
        {result.loading ? (
          <LoadingTable />
        ) : result.error ? (
          <ErrorTable error={result.error} />
        ) : filteredPermissions.length < 1 ? (
          <EmptyTable />
        ) : (
          <PermissionTable
            permissions={filteredPermissions}
            changingPermissionIds={changingPermissionIds}
            removingPermissionIds={removingPermissionIds}
            handleChangePermission={handleChangePermission}
            handleRemovePermission={handleRemovePermission}
          />
        )}
      </div>
      <div>
        <ConnectionAddPermissionModal
          isOpen={isModalOpen}
          ownerGroupIds={ownerGroupIds}
          addingGroupIds={addingGroupIds}
          addedGroupIds={addedGroupIds}
          handleClose={handleModalClose}
          handleAdd={handleAddPermission}
        />
      </div>
    </Container>
  )
}

const LoadingTable = () => (
  <div>
    <div style={{ display: 'flex', height: 37, alignItems: 'center' }}>
      <ReactContentLoader speed={1} width={1100} height={14}>
        <rect x={0} y={0} rx="2" ry="2" width="60" height="14" />
        <rect x={258} y={0} rx="2" ry="2" width="60" height="14" />
      </ReactContentLoader>
    </div>
    {new Array(6).fill(null).map((__, i) => (
      <div
        key={i}
        style={{ display: 'flex', height: 74, alignItems: 'center' }}
      >
        <ReactContentLoader speed={1} width={1100} height={74}>
          <rect x={0} y={27} rx="2" ry="2" width="150" height="17" />
          <rect x={258} y={12} rx="2" ry="2" width="200" height="50" />
        </ReactContentLoader>
      </div>
    ))}
  </div>
)

const ErrorTable: React.FC<{ error: Error }> = (props) => {
  if (props.error instanceof HttpError && props.error.statusCode === 403) {
    return (
      <NoResults
        heading="権限がありません"
        description="このコネクションを共有する権限がありません"
      />
    )
  }
  return (
    <NoResults
      heading="エラー"
      description="共有しているグループの取得に失敗しました"
    />
  )
}

const EmptyTable = () => (
  <NoResults
    heading="共有しているグループはありません"
    description="コネクションを共有してみましょう"
  />
)

const PermissionTable: React.FC<{
  permissions: Permission[]
  changingPermissionIds: string[]
  removingPermissionIds: string[]
  handleChangePermission: (groupId: string, policy: PermissionPolicy) => void
  handleRemovePermission: (groupId: string) => void
}> = (props) => {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th style={{ width: 250 }}>グループ名</Th>
          <Th style={{ width: 250 }}>権限管理</Th>
          <Th>{null}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {props.permissions.map((permission) => (
          <PermissionRow
            key={permission.id}
            permission={permission}
            permissionChanging={props.changingPermissionIds.includes(
              permission.id
            )}
            removing={props.removingPermissionIds.includes(permission.id)}
            handleChangePermission={props.handleChangePermission}
            handleRemove={props.handleRemovePermission}
          />
        ))}
      </Tbody>
    </Table>
  )
}

const PermissionRow: React.FC<{
  permission: Permission
  permissionChanging: boolean
  removing: boolean
  handleChangePermission: (
    permissionId: string,
    permissionPolicy: PermissionPolicy
  ) => void
  handleRemove: (permissionId: string) => void
}> = (props) => {
  const { handleChangePermission, handleRemove } = props

  const handlePermissionSelectChange = React.useCallback(
    (newValue: PermissionPolicy['name']) => {
      handleChangePermission(
        props.permission.id,
        PermissionPolicy.createByName(newValue)
      )
    },
    [props.permission, handleChangePermission]
  )

  const handleUnshareClick = React.useCallback(() => {
    handleRemove(props.permission.id)
  }, [handleRemove, props.permission.id])

  return (
    <Tr style={{ opacity: props.removing ? 0.5 : 1 }}>
      <Td>
        <Text element="span" fontSize="s" fontWeight="bold">
          {props.permission.group.name}
        </Text>
      </Td>
      <Td>
        <PermissionSelect
          value={props.permission.policy.name}
          loading={props.permissionChanging}
          onChange={handlePermissionSelectChange}
        />
      </Td>
      <Td>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <MoreActions handleUnshareClick={handleUnshareClick} />
        </div>
      </Td>
    </Tr>
  )
}

const actions: DropdownMenuItem[] = [
  {
    id: 'unshare',
    label: '共有を取り消す',
    role: 'danger',
  },
]

const MoreActions: React.FC<{
  handleUnshareClick: () => void
}> = (props) => {
  const { handleUnshareClick } = props
  const handleMenuItemClick = React.useCallback(
    (item: DropdownMenuItem) => {
      if (item.id === 'unshare') {
        handleUnshareClick()
      }
    },
    [handleUnshareClick]
  )
  return (
    <MoreActionsButtonWithDropdownMenu
      items={actions}
      horizontalAlign="left"
      handleMenuItemClick={handleMenuItemClick}
    />
  )
}

const Container = styled('div')({
  padding: vars.space.l,
})

const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: vars.space.m,
  paddingBottom: vars.space.m,
  borderBottom: `1px solid ${vars.color.border}`,
})
