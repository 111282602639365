import hash from 'object-hash'
import * as React from 'react'

import { InputValue } from '~/domain/workflow/source/InputValue'
import { AssistWidgetDefinition } from '~/domain/workflow/widget/assist'
import InputWidget from '~/presentation/workflow/detail/editor/form/inputWidget/InputWidget'
import { RawInputWidgetProps } from '~/presentation/workflow/detail/editor/form/inputWidget/RawInputWidget'
import AssistWidget from '~/presentation/workflow/detail/editor/form/inputWidget/assist/AssistWidget'

interface Props extends RawInputWidgetProps {
  definition: AssistWidgetDefinition
}

const ConditionalAssistWidget: React.FC<Props> = (props) => {
  // オブジェクトの参照が毎回変わってしまうので、ハッシュ値を計算してそれで比較する
  const dependentFieldsHash = hash(props.dependentFields)

  // useEffect で state で切り替えると依存しているフィールドの値があとから変わった時に、
  // AssistWidget の raw 値判定より遅れて実行されてしまい、 AssistWidget の raw 値判定でエラーが吐かれてしまう。
  // それを防ぐために useMemo を用いて AssistWidget に値が伝わる前にチェックする
  const useFallbackWidget = React.useMemo(() => {
    const parameterValues: InputValue[] = props.definition.assist.parameterFieldKeys.map(
      (fieldKey) => {
        const found = props.dependentFields.findField(fieldKey)
        if (found === undefined) {
          throw new Error(
            `This ConditionalAssistWidget depends on field '${fieldKey}' but its value is not passed. Missing dependencies?`
          )
        }
        return found.value
      }
    )
    // 依存しているフィールドの値に1つでも raw 以外の値が含まれていればフォールバックする
    return parameterValues.some(isNotOnlyRawValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.definition, dependentFieldsHash])

  if (useFallbackWidget === undefined) {
    // 判定中は何も描画しない
    return null
  }
  return useFallbackWidget ? (
    <InputWidget {...props} definition={props.definition.fallbackForm} />
  ) : (
    <AssistWidget {...props} />
  )
}

export default ConditionalAssistWidget

function isNotOnlyRawValue(inputValue: InputValue): boolean {
  return !InputValue.isAllRawValue(inputValue)
}
