import _ from 'lodash'

import { AssertionError } from '~/common/utils'
import { StructValueType } from '~/domain/ValueType'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { ValidationResult } from '~/domain/workflow/validator/ValidationResult'
import {
  BaseWidgetDefinition,
  InputWidgetDefinition,
} from '~/domain/workflow/widget/WidgetDefinition'
import { InputWidgetValidator } from '~/domain/workflow/widget/validator/InputWidgetValidator'
import { RawInputWidgetValidator } from '~/domain/workflow/widget/validator/RawInputWidgetValidator'
import { SubWidgetsValidator } from '~/domain/workflow/widget/validator/SubWidgetsValidator'

export interface StructWidgetDefinition extends BaseWidgetDefinition {
  formType: 'struct'
  entries: InputWidgetDefinition.Entry[]
}

export class StructWidgetValidator extends RawInputWidgetValidator<
  StructWidgetDefinition,
  StructValueType
> {
  async validate(inputValue: InputValue.Raw): Promise<ValidationResult> {
    // inputValue.raw が StructEntry[] でなければ invalid
    const entries = inputValue.raw
    if (!InputValue.isStructEntryList(entries)) {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat('不正な値が入力されています')
      )
    }

    // サブウィジェットをチェック
    const entryWidgetDefinitions = _.mapValues(
      _.keyBy(this.context.widgetDefinition.entries, (it) => it.key),
      (it) => it.valueForm
    )
    const entryValueTypes = _.mapValues(
      _.keyBy(this.context.valueType.entries, (it) => it.key),
      (it) => it.valueType
    )
    const entryValidators: InputWidgetValidator[] = []
    const entryValues: InputValue[] = []
    entries.forEach((entry) => {
      const widgetDefinition = entryWidgetDefinitions[entry.key]
      if (widgetDefinition === undefined) {
        throw new AssertionError(
          `Missing widget definitions of the entry (key: ${entry.key}).`
        )
        // 定義が存在しない値が inputValue のエントリーに合った場合、古いワークフローでバリデーションした可能性があるので、エラーではなくバリデーションで落とす？
        // いや、エラーで落とすようにしておいて、定義から古い定義を削除しないようにする？（同名の key で定義だけ変更するともちろんバリデーションエラー）
      }
      const valueType = entryValueTypes[entry.key]
      if (valueType === undefined) {
        throw new AssertionError(
          `Missing value type of the entry (key: ${entry.key}).`
        )
      }
      entryValidators.push(
        new InputWidgetValidator({
          ...this.context,
          widgetDefinition,
          valueType,
        })
      )
      entryValues.push(entry.value)
    })
    return new SubWidgetsValidator(entryValidators).validate(entryValues)
  }
}
