import * as React from 'react'

import config from '~/common/config'

export enum ProcessStatus {
  none = 'none',
  processing = 'processing',
  processed = 'processed',
  failed = 'failed',
}

export function useLoginSocial(): {
  socialAccountUid?: string
  status: ProcessStatus
  startProcess: (provider: string) => void
} {
  const [socialAccountUid, setSocialAccountUid] = React.useState<string>()
  const [processStatus, setProcessStatus] = React.useState<ProcessStatus>(
    ProcessStatus.none
  )
  const childWindow = React.useRef<Window>()

  // message 監視
  React.useEffect(() => {
    const handleWindowMessage = (e: MessageEvent) => {
      // オリジンが Anyflow でなければ無視
      if (e.origin !== config.serverRoot) {
        return
      }
      const data: any = e.data
      // タイプが違えば無視
      if (data.type !== 'OAUTH_LOGIN') {
        return
      }
      if (data.status === 'success') {
        setSocialAccountUid(data.social_account_uid)
        setProcessStatus(ProcessStatus.processed)
      } else {
        setProcessStatus(ProcessStatus.failed)
      }
      childWindow.current?.close()
    }
    window.addEventListener('message', handleWindowMessage)
    return () => {
      window.removeEventListener('message', handleWindowMessage)
    }
  }, [])

  const startProcess = (provider: string) => {
    childWindow.current?.close()
    setSocialAccountUid(undefined)
    setProcessStatus(ProcessStatus.processing)
    childWindow.current =
      window.open(
        `${config.serverRoot}/accounts/${provider}/login`,
        'login_social',
        'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=700,width=650'
      ) ?? undefined
  }

  return { socialAccountUid, status: processStatus, startProcess }
}
