import { Tson } from '~/data/Tson'
import { mapToUserJson } from '~/data/user/mapToUserJson'
import { WorkflowInstanceJson } from '~/data/workflow/instance/json'
import { mapToRunningStatus } from '~/data/workflow/instance/mapToRunningStatus'
import { mapToWorkflowJson } from '~/data/workflow/mapToWorkflowJson'
import { mapToWorkflowSourceBodyJson } from '~/data/workflow/source/mapToWorkflowSourceBodyJson'

export function mapToWorkflowInstanceJson(tson: Tson): WorkflowInstanceJson {
  return {
    frozenSource: mapToWorkflowSourceBodyJson(tson.getValue('frozenSource')),
    runner: mapToUserJson(tson.getValue('runner')),
    slug: tson.getString('slug'),
    state: mapToRunningStatus(tson.getString('state')),
    updatedAt: tson.getString('updatedAt'),
    workflow: mapToWorkflowJson(tson.getValue('workflow')),
  }
}
