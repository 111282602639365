import * as React from 'react'
import * as ReactGA from 'react-ga'
import { RouteComponentProps } from 'react-router-dom'

import config from '~/common/config'

ReactGA.initialize(config.gaId, { testMode: process.env.NODE_ENV === 'test' })

export default function useAnalytics(props: RouteComponentProps) {
  const [prevPathname, setPrevPathname] = React.useState('')

  // if location is changed
  if (props.location.pathname !== prevPathname) {
    trackPage(props.location.pathname)
    setPrevPathname(props.location.pathname)
  }

  function trackPage(page: string) {
    ReactGA.set({ page })
    ReactGA.pageview(page)
  }
}
