import { NodeView } from 'prosemirror-view'
import React from 'react'
import ReactDOM from 'react-dom'

export class ParagraphNodeView implements NodeView {
  public readonly dom: HTMLElement
  public readonly contentDOM?: HTMLElement
  public readonly portal: React.ReactPortal

  constructor(isLeafNode: boolean) {
    this.dom = document.createElement('div')
    this.dom.classList.add('ProseMirror__dom')

    if (!isLeafNode) {
      this.contentDOM = document.createElement('span')
      this.contentDOM.classList.add('ProseMirror__contentDOM')
      this.dom.appendChild(this.contentDOM)
    }

    const Component: React.FC = () => (
      <span className="ProseMirror__reactComponent" />
    )

    this.portal = ReactDOM.createPortal(<Component />, this.dom)
  }

  update(): boolean {
    return true
  }
}
