import styled from '@emotion/styled'

import * as vars from '~/styles/variables'

const Inner = styled('div')({
  margin: '0 auto',
  paddingRight: vars.space.m,
  paddingLeft: vars.space.m,
  width: '100%',
  maxWidth: 1240,
})

export default Inner
