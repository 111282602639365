import styled from '@emotion/styled'
import * as React from 'react'
import * as Icon from 'react-feather'

import * as vars from '~/styles/variables'

interface Props {
  active: boolean
  handleClick: () => void
  className?: string
  style?: React.CSSProperties
}

const MoreActionsButton: React.ForwardRefRenderFunction<
  HTMLDivElement,
  Props
> = (props, ref) => {
  return (
    <Container
      ref={ref}
      className={props.className}
      style={props.style}
      active={props.active}
      onClick={props.handleClick}
    >
      <Icon.MoreHorizontal color={vars.fontColor.tertiary} />
    </Container>
  )
}

const Container = styled('div')(
  {
    width: 34,
    height: 34,
    padding: vars.space.xs,
    borderRadius: vars.borderRadius.s,
    border: `1px solid transparent`,
    cursor: 'pointer',
    '&:hover': {
      borderColor: `${vars.color.gray}`,
    },
  },
  (props: { active: boolean }) => ({
    borderColor: props.active ? `${vars.color.gray}` : 'transparent',
  })
)

export default React.forwardRef(MoreActionsButton)
