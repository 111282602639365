import styled from '@emotion/styled'
import * as React from 'react'

import { RunningStatus } from '~/domain/workflow/instance/RunningStatus'
import * as vars from '~/styles/variables'

interface Props {
  status: RunningStatus
  className?: string
}

const Status: React.FC<Props> = ({ status, className }) => (
  <Container className={className} status={status}>
    <Label>
      {status === RunningStatus.running
        ? '実行中'
        : status === RunningStatus.success
        ? '成功'
        : status === RunningStatus.stop || status === RunningStatus.queueing
        ? '実行前'
        : '失敗'}
    </Label>
  </Container>
)

const Container = styled('div')<{ status: RunningStatus }>(
  {
    display: 'inline-block',
    paddingTop: vars.space.xxs,
    paddingRight: vars.space.m,
    paddingBottom: vars.space.xxs,
    paddingLeft: vars.space.m,
    borderRadius: vars.borderRadius.s,
  },
  (props) => ({
    backgroundColor:
      props.status === RunningStatus.running
        ? vars.color.running
        : props.status === RunningStatus.success
        ? vars.color.success
        : props.status === RunningStatus.stop ||
          props.status === RunningStatus.queueing
        ? vars.color.warning
        : vars.color.error,
  })
)

const Label = styled('div')({
  color: vars.color.white,
  fontSize: vars.fontSize.xs,
  fontWeight: vars.fontWeight.bold,
})

export default Status
