import produce from 'immer'
import { createSelector } from 'reselect'
import { actionCreatorFactory } from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { RootState } from '~/ducks'

const actionCreator = actionCreatorFactory('anyflow/editor/loader')

export const types = {
  RESET: 'RESET',
  SHOW: 'SHOW',
  HIDE: 'HIDE',
}

export const actions = {
  reset: actionCreator(types.RESET),
  show: actionCreator(types.SHOW),
  hide: actionCreator(types.HIDE),
}

export const selectors = {
  getIsLoading: createSelector(
    (state: RootState) => state.editor.loader.isLoading,
    (isLoading) => isLoading
  ),
}

export type State = {
  isLoading: boolean
}

const initialState: State = {
  isLoading: false,
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.reset, () => initialState)
  .case(actions.show, (state) =>
    produce(state, (draft) => {
      draft.isLoading = true
    })
  )
  .case(actions.hide, (state) =>
    produce(state, (draft) => {
      draft.isLoading = false
    })
  )
