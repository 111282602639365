import * as React from 'react'

export function useComposingRef(): React.RefObject<boolean> {
  const composingRef = React.useRef(false)

  React.useEffect(() => {
    const handleStart = () => {
      composingRef.current = true
    }
    const handleEnd = () => {
      composingRef.current = false
    }
    document.addEventListener('compositionstart', handleStart)
    document.addEventListener('compositionend', handleEnd)
    return () => {
      document.removeEventListener('compositionstart', handleStart)
      document.removeEventListener('compositionend', handleEnd)
    }
  }, [])

  return composingRef
}
