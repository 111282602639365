import config from '~/common/config'
import { Tson } from '~/data/Tson'
import requestJson from '~/data/request'
import { mapJsonToFunctionDefinition } from '~/data/workflow/function/mapJsonToFunctionDefinition'
import { mapToFunctionJson } from '~/data/workflow/function/mapToFunctionJson'
import { FunctionDefinition } from '~/domain/workflow/function/FunctionDefinition'
import { FunctionService } from '~/domain/workflow/function/FunctionService'

export class FunctionServiceImpl implements FunctionService {
  async getAll(): Promise<FunctionDefinition[]> {
    const res = await requestJson(`${config.apiRoot}/functions`)
    const jsons = new Tson(res).asArray().map(mapToFunctionJson)
    return jsons.map(mapJsonToFunctionDefinition)
  }
}
