import styled from '@emotion/styled'
import * as React from 'react'

import Inner from '~/components/organisms/Inner'
import * as vars from '~/styles/variables'

const Bottom: React.FC = () => {
  return (
    <Inner responsive={true}>
      <Heading>
        Anyflow は
        <br />
        人の"時間"を創ります
      </Heading>
      <Subheading>
        Anyflow は単純業務の時間を減らし、人にしかできない仕事の
        <PcBr />
        時間を創ることで、ビジネスの成長を加速させます。
      </Subheading>
      <img
        alt=""
        srcSet="/static/images/lp/footer_illust@2x.png 2x"
        style={{
          display: 'block',
          maxWidth: 862,
          width: '50%',
          marginTop: vars.space.l,
          marginRight: 'auto',
          marginLeft: 'auto',
        }}
      />
    </Inner>
  )
}

const Heading = styled('h1')({
  textAlign: 'center',
  fontWeight: 800,
  fontSize: vars.fontSize.xxxxxl,
  lineHeight: vars.lineHeight.heading,
  [vars.media.sp]: {
    fontSize: vars.fontSize.xxl,
  },
  [vars.media.tablet]: {
    fontSize: vars.fontSize.xxxl,
  },
})

const Subheading = styled('p')({
  marginTop: vars.space.m,
  fontSize: 18,
  lineHeight: vars.lineHeight.heading,
  textAlign: 'center',
  [vars.media.tablet]: {
    fontSize: 15,
  },
})

const PcBr = styled('br')({
  [vars.media.tablet]: {
    display: 'none',
  },
})

export default Bottom
