import styled from '@emotion/styled'
import * as _ from 'lodash'
import * as React from 'react'
import { ChevronLeft } from 'react-feather'
import { useSelector } from 'react-redux'
import { animated, useSpring, useTransition } from 'react-spring'

import { actionNodeSize } from '~/components/molecules/ActionNode'
import App from '~/components/molecules/App'
import OutsideClickTrap from '~/components/utils/OutsideClickTrap'
import { ActionDefinition } from '~/domain/workflow/action/ActionDefinition'
import { ActionState } from '~/domain/workflow/action/ActionState'
import { EMPTY_ACTION_ID } from '~/ducks/editor/types'
import * as editorDuck from '~/ducks/ui/editor'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import * as vars from '~/styles/variables'

interface OwnProps {
  /** 置換対象のタスクID */
  taskId: string
  isShown: boolean
  top: number
  left: number
  onSelect: (selectedAction: ActionDefinition) => void
  onClose: () => void
}

type Props = OwnProps

const ActionSelector: React.FC<Props> = ({
  isShown,
  top,
  left,
  onSelect,
  onClose,
}) => {
  const definitions = useDefinitions()
  const scale = useSelector(editorDuck.selectors.getScale)

  const target = document.getElementById('portal-root')
  const [view, setView] = React.useState<'app' | 'action'>('app')
  const [selectedAppId, setSelectedAppId] = React.useState('')
  const transitions = useTransition(view, null, {
    from: { opacity: 0, transform: 'translate3d(50%, 0, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0%, 0, 0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%, 0, 0)' },
  })

  function resetState() {
    setView('app')
  }

  function handleDocumentClick() {
    resetState()
    onClose()
  }

  function handleSelect(action: ActionDefinition) {
    onSelect(action)
    resetState()
  }

  // PanZoomViewがドラッグしたときにAppSelectorが消えるようにするために、
  // documentにmousedownイベントがつけてあるが、それをすべて無効にする
  function handleMouseDown(e: React.MouseEvent) {
    e.nativeEvent.stopImmediatePropagation()
  }

  const animationProps = useSpring({
    opacity: isShown ? 1 : 0,
    transform: isShown ? 'scale(1)' : 'scale(0.9)',
  })

  if (!target) {
    return null
  }

  const actionAppIds: Set<string> = new Set()
  Object.values(definitions.actionsById)
    .filter(
      (it) =>
        it.actionId !== EMPTY_ACTION_ID && it.state === ActionState.available
    )
    .forEach((it) => actionAppIds.add(it.appId))
  const availableApps = Object.values(definitions.appsById).filter((app) => {
    return actionAppIds.has(app.appId)
  })

  function render() {
    return (
      <OutsideClickTrap handleOutsideClick={handleDocumentClick}>
        <Container
          style={{
            ...animationProps,
            top,
            left: left + actionNodeSize * scale,
          }}
        >
          {transitions.map(({ item, props, key }) => {
            return item === 'app' ? (
              <AppList style={props} key={key}>
                {availableApps.map((app) => {
                  return (
                    <AppItem
                      key={app.appId}
                      available={true}
                      onClick={(e) => {
                        // https://gist.github.com/ggregoire/ce7bc946212920c0a6bad8125567d001
                        e.nativeEvent.stopImmediatePropagation()
                        setView('action')
                        setSelectedAppId(app.appId)
                      }}
                      onMouseDown={handleMouseDown}
                    >
                      <App icon={app.iconPath} color={app.color} size="m" />
                      <AppName>{app.name}</AppName>
                    </AppItem>
                  )
                })}
              </AppList>
            ) : (
              (() => {
                const _actions = _.filter(
                  Object.values(definitions.actionsById),
                  (o) =>
                    o.appId === selectedAppId &&
                    o.state === ActionState.available &&
                    o.actionId !== EMPTY_ACTION_ID
                )
                const app = definitions.getApp(selectedAppId)
                if (_actions.length === 0) {
                  return (
                    <ActionContainer style={props} key={key}>
                      <Header>
                        <ChevronLeft
                          onClick={(e) => {
                            e.nativeEvent.stopImmediatePropagation()
                            resetState()
                          }}
                          onMouseDown={handleMouseDown}
                        />
                        <AppName>{app.name}</AppName>
                      </Header>
                      <NoActions>アクションがありません</NoActions>
                    </ActionContainer>
                  )
                }
                return (
                  <ActionContainer style={props} key={key}>
                    <Header>
                      <ChevronLeft
                        onClick={(e) => {
                          e.nativeEvent.stopImmediatePropagation()
                          resetState()
                        }}
                        onMouseDown={handleMouseDown}
                      />
                      <AppName>{app.name}</AppName>
                    </Header>
                    <ActionList>
                      {_actions.map((action) => {
                        return (
                          <ActionItem
                            key={action.actionId}
                            onClick={(e) => {
                              // https://gist.github.com/ggregoire/ce7bc946212920c0a6bad8125567d001
                              e.nativeEvent.stopImmediatePropagation()
                              handleSelect(action)
                            }}
                            onMouseDown={handleMouseDown}
                          >
                            <App
                              icon={app.iconPath}
                              color={app.color}
                              size="m"
                            />
                            <ActionName>{action.name}</ActionName>
                          </ActionItem>
                        )
                      })}
                    </ActionList>
                  </ActionContainer>
                )
              })()
            )
          })}
        </Container>
      </OutsideClickTrap>
    )
  }
  return isShown ? render() : null
}

const Container = styled(animated.div)({
  position: 'fixed',
  maxHeight: 500,
  minHeight: 500,
  width: 300,
  padding: vars.space.m * 1.3,
  backgroundColor: vars.color.white,
  borderRadius: vars.borderRadius.m,
  boxShadow: vars.shadow.m,
  overflowY: 'scroll',
  overflowX: 'hidden',
  zIndex: 2,
})

const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: vars.space.s,
})

const NoActions = styled('div')({
  padding: vars.space.s,
  fontSize: vars.fontSize.s,
})

const AppList = styled(animated.div)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  padding: vars.space.m * 1.3,
  willChange: 'transform, opacity',
})

const AppItem = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    paddingTop: vars.space.xs * 1.5,
    paddingBottom: vars.space.xs * 1.5,
    cursor: 'pointer',
    '&:hover': {
      transform: 'translateY(-2px)',
    },
    '&:first-of-type': {
      marginTop: 0,
    },
  },
  (props: { available: boolean }) => ({
    opacity: props.available ? 1 : 0.5,
  })
)

const AppName = styled('div')({
  marginLeft: vars.space.s,
  fontSize: vars.fontSize.s,
  fontWeight: vars.fontWeight.bold,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const ActionContainer = styled(animated.div)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
})

const ActionList = styled('div')({
  paddingRight: vars.space.m * 1.3,
  paddingLeft: vars.space.m * 1.3,
  paddingBottom: vars.space.m * 1.3,
})

const ActionItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  paddingTop: vars.space.xs * 1.5,
  paddingBottom: vars.space.xs * 1.5,
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-2px)',
  },
  '&:first-of-type': {
    marginTop: 0,
  },
})

const ActionName = styled('div')({
  marginLeft: vars.space.s,
  fontSize: vars.fontSize.s,
  fontWeight: vars.fontWeight.bold,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

export default ActionSelector
