import * as React from 'react'
import Helmet from 'react-helmet'

export function useRecaptcha(siteKey: string): [JSX.Element, string, boolean] {
  const [verified, setVerified] = React.useState<boolean>(false)
  const [timestamp, setTimestamp] = React.useState<string>('')
  const [response, setResponse] = React.useState<string | null>(null)
  const [callbackName, expiredCallbackName] = React.useMemo<
    [string, string]
  >(() => {
    const r = Math.floor(Math.random() * 1000000)
    return [`recaptchaCallback__${r}`, `recaptchaExpiredCallback__${r}`]
  }, [])

  // reCAPTCHA のコールバックがグローバル関数しか受け付けないので window に定義する
  React.useEffect(() => {
    window[callbackName] = (res: string) => {
      setVerified(true)
      setResponse(res)
    }
    window[expiredCallbackName] = () => {
      setVerified(false)
      setResponse(null)
    }
    return () => {
      delete window[callbackName]
      delete window[expiredCallbackName]
    }
  }, [callbackName, expiredCallbackName])

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (response === null || response.trim() === '') {
        setTimestamp(JSON.stringify(new Date().getTime()))
      }
    }, 500)
    return () => {
      clearInterval(timer)
    }
  }, [response])

  const element = (
    <>
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js" />
      </Helmet>
      <div
        className="g-recaptcha"
        data-sitekey={siteKey}
        data-callback={callbackName}
        data-expired-callback={expiredCallbackName}
      />
    </>
  )

  return [element, timestamp, verified]
}
