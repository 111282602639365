import React from 'react'

import { Result } from '~/common/Result'
import { apiClients } from '~/common/apiClients'
import { GroupService } from '~/domain/group/GroupService'
import { GroupSummary } from '~/domain/group/GroupSummary'

const service: GroupService = apiClients.groupService

export function useAllGroups(): { result: Result<GroupSummary[]> } {
  const [result, setResult] = React.useState<Result<GroupSummary[]>>(
    new Result.Loading()
  )

  React.useEffect(() => {
    service
      .getAllGroups()
      .then((res) => {
        setResult(new Result.Success(res))
      })
      .catch((e) => {
        setResult(new Result.Failure(e))
      })
  }, [])

  return { result }
}
