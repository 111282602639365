import styled from '@emotion/styled'
import * as React from 'react'

import VariableUnavailableBadge from '~/presentation/workflow/detail/editor/form/inputWidget/VariableUnavailableBadge'
import * as vars from '~/styles/variables'
import {
  widgetAnimationStyle,
  widgetBorderErrorStyle,
  widgetBorderStyle,
  widgetFocusedStyle,
} from '~/styles/widget'

interface Props {
  hasError: boolean
  renderable: boolean
  disabled?: boolean
  children: React.ReactNode[]
}

const InputContainer: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
  props,
  ref
) => {
  const [showBadge, setShowBadge] = React.useState(true)
  return (
    <Container
      ref={ref}
      hasError={props.hasError}
      disabled={props.disabled ?? false}
      onFocus={() => setShowBadge(false)}
      onBlur={() => setShowBadge(true)}
    >
      {props.children}
      {!props.renderable && showBadge ? <_VariableUnavailableBadge /> : null}
    </Container>
  )
}

const Container = styled('div')(
  {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: vars.color.white,
    borderRadius: widgetBorderStyle.borderRadius,
    ...widgetAnimationStyle,
    '&:focus-within': {
      ...widgetFocusedStyle,
    },
  },
  (props: { hasError: boolean; disabled: boolean }) => ({
    border: props.hasError
      ? widgetBorderErrorStyle.border
      : widgetBorderStyle.border,
    opacity: props.disabled ? 0.5 : 1,
  })
)

const _VariableUnavailableBadge = styled(VariableUnavailableBadge)({
  position: 'absolute',
  top: 0,
  right: 0,
})

export default React.forwardRef(InputContainer)
