import { SalesforceSObjectField } from '~/domain/workflow/widget/salesforceSearchCondition/SalesforceSearchConditionService'

export class SalesforceSObject {
  public readonly accountUid: string
  public readonly name: string
  public readonly fields: SalesforceSObjectField[]

  constructor(
    accountUid: string,
    sObjectName: string,
    fields: SalesforceSObjectField[]
  ) {
    this.accountUid = accountUid
    this.name = sObjectName
    this.fields = fields
  }

  findField(fieldName: string): SalesforceSObjectField | undefined {
    return this.fields.find((it) => it.id === fieldName)
  }
}
