import { Pagination } from '~/common/Pagination'
import config from '~/common/config'
import { StandardTrigger } from '~/common/constants'
import { Tson } from '~/data/Tson'
import requestJson, {
  HttpError,
  requestDelete,
  requestPatch,
  requestPost,
} from '~/data/request'
import { WorkflowJson } from '~/data/workflow/WorkflowJson'
import { mapJsonToExpectedObjects } from '~/data/workflow/expectedObject/mapJsonToExpectedObjects'
import { mapToExpectedObjectsJson } from '~/data/workflow/expectedObject/mapToExpectedObjectsJson'
import { mapToWorkflowInstanceJson } from '~/data/workflow/instance/mapToWorkflowInstanceJson'
import { mapJsonToWorkflow } from '~/data/workflow/mapJsonToWorkflow'
import { mapToWorkflowJson } from '~/data/workflow/mapToWorkflowJson'
import { mapJsonToPermission } from '~/data/workflow/permission/mapJsonToPermission'
import { mapModelToPermissionPolicyJson } from '~/data/workflow/permission/mapModelToPermissionJson'
import { mapToPermissionJson } from '~/data/workflow/permission/mapToPermissionJson'
import { mapModelToWorkflowSourceBodyJson } from '~/data/workflow/source/mapModelToWorkflowSourceBodyJson'
import { Workflow } from '~/domain/workflow/Workflow'
import { WorkflowService } from '~/domain/workflow/WorkflowService'
import { ExpectedObjects } from '~/domain/workflow/expectedObject/ExpectedObjects'
import { Permission } from '~/domain/workflow/permission/Permission'
import { PermissionPolicy } from '~/domain/workflow/permission/PermissionPolicy'
import { WorkflowSourceBody } from '~/domain/workflow/source/WorkflowSourceBody'

export class WorkflowServiceImpl implements WorkflowService {
  async create(
    workflowName: string,
    sourceBody: WorkflowSourceBody
  ): Promise<Workflow> {
    const requestBody = {
      name: workflowName,
      body: sourceBody
        ? mapModelToWorkflowSourceBodyJson(sourceBody)
        : {
            trigger: {
              triggerId: StandardTrigger,
            },
          },
    }
    const res = await requestPost(`${config.apiRoot}/workflows`, requestBody)
    const json = mapToWorkflowJson(new Tson(res))
    return mapJsonToWorkflow(json)
  }

  async delete(workflowId: string): Promise<void> {
    return requestDelete(
      `${config.apiRoot}/workflows/${workflowId}`
    ).then(() => {})
  }

  async execute(workflowId: string): Promise<string> {
    const requestBody = {
      workflow: workflowId,
    }
    const res = await requestPost(
      `${config.apiRoot}/triggers/standard`,
      requestBody
    )
    const json = mapToWorkflowInstanceJson(new Tson(res))
    return json.slug
  }

  async get(workflowId: string): Promise<Workflow | undefined> {
    try {
      const res = await requestJson(`${config.apiRoot}/workflows/${workflowId}`)
      const json = mapToWorkflowJson(new Tson(res))
      return await mapJsonToWorkflow(json)
    } catch (e) {
      if (e instanceof HttpError && e.statusCode === 404) {
        return undefined
      }
      throw e
    }
  }

  async getList(pageNumber: number): Promise<Pagination<Workflow>> {
    const res = await requestJson(
      `${config.apiRoot}/workflows?page=${pageNumber}`
    )
    const json = mapToGetListResponseJson(new Tson(res))
    return {
      paginationMeta: {
        currentPageNumber: json.current,
        totalCount: json.count,
        countPerPage: json.limit,
      },
      items: await Promise.all(
        json.results.map(async (it) => mapJsonToWorkflow(it))
      ),
    }
  }

  async getExpectedObjects(
    workflowId: string,
    parentTaskId?: string
  ): Promise<ExpectedObjects> {
    const url =
      parentTaskId === undefined
        ? `${config.apiRoot}/workflows/${workflowId}/expected_objects/trigger`
        : `${config.apiRoot}/workflows/${workflowId}/expected_objects/task/${parentTaskId}`
    const res = await requestJson(url)
    const json = mapToExpectedObjectsJson(new Tson(res))
    return mapJsonToExpectedObjects(json)
  }

  async getPermissions(workflowId: string): Promise<Permission[]> {
    const res = await requestJson(
      `${config.apiRoot}/workflows/${workflowId}/permissions`
    )
    const json = new Tson(res).asArray().map(mapToPermissionJson)
    return json.map(mapJsonToPermission)
  }

  async update(
    workflowId: string,
    workflow: Partial<Workflow>
  ): Promise<Workflow> {
    // WorkflowSource クラスが保存に必要なデータ（name 等）を保持しない設計になっているため、
    // WorkflowService の update では WorkflowSource の更新は行わせない。
    // 代わりに WorkflowSourceService を使用すること。
    // ただし name や editor などは WorkflowSourceService を使用しても変更できない。もし変更しなければならないことに
    // なった場合はこの制限を解除してこのメソッドで WorkflowSource の更新もできるようにする。
    const requestBody: UpdateWorkflowRequestJson = {
      workflowId: workflow.workflowId,
      name: workflow.name,
      description: workflow.description,
      isEnabled: workflow.isEnabled,
      updatedAt: workflow.updatedAt,
    }
    const res = await requestPatch(
      `${config.apiRoot}/workflows/${workflowId}`,
      requestBody
    )
    const json = mapToWorkflowJson(new Tson(res))
    return mapJsonToWorkflow(json)
  }

  async addPermission(
    workflowId: string,
    groupId: string,
    policy: PermissionPolicy
  ): Promise<Permission> {
    const body = {
      group_slug: groupId,
      permission_policy: mapModelToPermissionPolicyJson(policy),
    }
    const res = await requestPost(
      `${config.apiRoot}/workflows/${workflowId}/permissions`,
      body
    )
    const json = mapToPermissionJson(new Tson(res))
    return mapJsonToPermission(json)
  }

  async changePermission(
    workflowId: string,
    permissionId: string,
    policy: PermissionPolicy
  ): Promise<Permission> {
    const body = {
      permission_policy: mapModelToPermissionPolicyJson(policy),
    }
    const res = await requestPatch(
      `${config.apiRoot}/workflows/${workflowId}/permissions/${permissionId}`,
      body
    )
    const json = mapToPermissionJson(new Tson(res))
    return mapJsonToPermission(json)
  }

  async removePermission(
    workflowId: string,
    permissionId: string
  ): Promise<void> {
    await requestDelete(
      `${config.apiRoot}/workflows/${workflowId}/permissions/${permissionId}`
    )
  }
}

interface UpdateWorkflowRequestJson {
  workflowId?: string
  name?: string
  description?: string
  isEnabled?: boolean
  updatedAt?: string
}

interface GetListResponseJson {
  current: number
  count: number
  limit: number
  results: WorkflowJson[]
}

function mapToGetListResponseJson(tson: Tson): GetListResponseJson {
  return {
    count: tson.getNumber('count'),
    current: tson.getNumber('current'),
    limit: tson.getNumber('limit'),
    results: tson.getArray('results').map(mapToWorkflowJson),
  }
}
