import { combineReducers } from 'redux'

import * as list from '~/ducks/taskInstance/list'

export interface TaskInstanceState {
  list: list.State
}

export default combineReducers<TaskInstanceState>({
  list: list.reducer,
})
