import * as React from 'react'
import { animated, useSpring } from 'react-spring'

interface Props {}

const AppLoader: React.FC<Props> = () => {
  const props = useSpring({
    from: {
      transform: 'rotate(0deg)',
    },
    to: async (next: any) => {
      for (let i = 0; i < 100; i++) {
        await next({
          transform: 'rotate(360deg)',
        })
      }
      // TODO: ここでローダーの回転が終わった時の処理
    },
    reset: true,
    config: {
      tension: 150,
    },
  })
  return (
    <animated.img
      style={props}
      src="/static/images/symbol.svg"
      alt="Anyflow"
      width="70"
    />
  )
}

export default AppLoader
