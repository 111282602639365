import * as Sentry from '@sentry/browser'
import * as dayjs from 'dayjs'
import 'dayjs/locale/ja'
import relativeTime from 'dayjs/plugin/relativeTime'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import config, { Env, targetEnv } from '~/common/config'
import routes from '~/routes'
import store from '~/store'

if (import.meta.webpackHot) {
  import.meta.webpackHot?.accept()
}

if (targetEnv !== Env.local) {
  Sentry.init({
    dsn: config.sentryDsn,
  })
}

// localeをグローバルに変更
dayjs.locale('ja')
// 相対時間を有効化
dayjs.extend(relativeTime)

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>{routes}</BrowserRouter>
  </Provider>,
  document.getElementById('react-root')
)
