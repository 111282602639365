import styled from '@emotion/styled'
import * as React from 'react'
import Helmet from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { formatRoute } from 'react-router-named-routes'

import { PendingResult, SubmitStatus } from '~/common/types'
import { formatDate } from '~/common/utils'
import Button from '~/components/atoms/Button'
import Link from '~/components/atoms/Link'
import Text from '~/components/atoms/Text'
import Pager from '~/components/molecules/Pager'
import Inner from '~/components/organisms/Inner'
import {
  Body,
  Cell,
  Header,
  HeaderCell,
  Row,
  Table,
} from '~/components/organisms/Table'
import { useToaster } from '~/presentation/ToasterContext'
import ContentLoader from '~/presentation/lookupTable/ContentLoader'
import { useCreateLookupTable } from '~/presentation/lookupTable/useCreateLookupTable'
import { useLookupTables } from '~/presentation/lookupTable/useLookupTables'
import { usePageQuery } from '~/presentation/utilityHooks'
import { LOOKUP_TABLE_DETAIL, LOOKUP_TABLE_LIST } from '~/routes'
import * as vars from '~/styles/variables'

const LookupTableList: React.FC = () => {
  const history = useHistory()
  const pageNumber = usePageQuery()
  const toaster = useToaster()

  const { paginationMeta, result } = useLookupTables(pageNumber)
  const {
    create: createLookupTable,
    status: createStatus,
  } = useCreateLookupTable()

  const handleCreateLookupTable = async () => {
    try {
      const newLookupTableId = await createLookupTable(
        '無題のルックアップテーブル'
      )
      history.push(
        formatRoute(LOOKUP_TABLE_DETAIL, {
          lookupTableId: newLookupTableId,
        })
      )
    } catch (reason) {
      console.error(reason)
      toaster.showError('作成に失敗しました')
    }
  }

  return (
    <>
      <Helmet>
        <title>ルックアップテーブル</title>
      </Helmet>
      <_Inner>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Heading>ルックアップテーブル</Heading>
          <Button
            loading={createStatus === SubmitStatus.submitting}
            onClick={handleCreateLookupTable}
          >
            新規作成
          </Button>
        </div>
        <div
          style={{
            marginTop: vars.space.l,
          }}
        >
          {result.status === PendingResult.Status.pending ? (
            <ContentLoader />
          ) : result.status === PendingResult.Status.success &&
            result.data.length > 0 ? (
            <_Table>
              <Header>
                <Row>
                  <HeaderCell>テーブル名</HeaderCell>
                  <HeaderCell width="300">最終更新日時</HeaderCell>
                </Row>
              </Header>
              <Body>
                {result.data.map((lookupTable) => (
                  <Row key={lookupTable.id}>
                    <Cell>
                      <Name
                        to={LOOKUP_TABLE_DETAIL}
                        params={{ lookupTableId: lookupTable.id }}
                        title={lookupTable.name}
                      >
                        <Text
                          element="p"
                          fontWeight="bold"
                          lineHeight="heading"
                          truncated={true}
                        >
                          {lookupTable.name}
                        </Text>
                      </Name>
                    </Cell>
                    <Cell>
                      <Text
                        element="span"
                        color="tertiary"
                        fontSize="s"
                        lineHeight="heading"
                      >
                        {formatDate(lookupTable.updatedAt)}
                      </Text>
                    </Cell>
                  </Row>
                ))}
              </Body>
            </_Table>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src="/static/images/no-lookup-table.svg"
                alt="ルックアップテーブルはまだありません"
              />
              <Text
                element="p"
                color="secondary"
                fontSize="s"
                textAlign="center"
                style={{ marginTop: vars.space.l }}
              >
                ルックアップテーブルはまだありません。
                <br />
                各サービスのID紐付けが簡単に行える機能を試してみませんか？
              </Text>
            </div>
          )}
          {paginationMeta && (
            <_Pager
              to={LOOKUP_TABLE_LIST}
              totalCount={paginationMeta.totalCount}
              currentPageNumber={paginationMeta.currentPageNumber}
              countPerPage={paginationMeta.countPerPage}
            />
          )}
        </div>
      </_Inner>
    </>
  )
}

const _Inner = styled(Inner)({
  padding: vars.space.l,
  margin: 'unset',
  width: '100%',
})

const Heading = styled('h1')({
  margin: 0,
  fontSize: vars.fontSize.xxl,
})

const _Table = styled(Table)({
  tableLayout: 'fixed',
})

const Name = styled(Link)({
  color: vars.fontColor.primary,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
})

const _Pager = styled(Pager)({
  marginTop: vars.space.l,
  marginRight: vars.space.xl,
})

export default LookupTableList
