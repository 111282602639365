import { InputValue } from '~/domain/workflow/source/InputValue'
import { ValidationResult } from '~/domain/workflow/validator/ValidationResult'
import {
  BaseWidgetDefinition,
  InputWidgetDefinition,
} from '~/domain/workflow/widget/WidgetDefinition'
import { InputWidgetValidatorContext } from '~/domain/workflow/widget/validator/InputWidgetValidator'
import { RawInputWidgetValidator } from '~/domain/workflow/widget/validator/RawInputWidgetValidator'

export interface SelectWidgetDefinition extends BaseWidgetDefinition {
  formType: 'select'
  options: InputWidgetDefinition.Option[]
}

export class SelectWidgetValidator extends RawInputWidgetValidator<
  SelectWidgetDefinition
> {
  private readonly valueSet: Set<string>

  constructor(context: InputWidgetValidatorContext<SelectWidgetDefinition>) {
    super(context)
    const set = new Set<string>()
    context.widgetDefinition.options.forEach((option) => {
      const rawInputValue = InputValue.createRawInputValue(option.value)
      set.add(JSON.stringify(rawInputValue))
    })
    this.valueSet = set
  }

  async validate(inputValue: InputValue.Raw): Promise<ValidationResult> {
    // 定義に存在しない値が選択されている場合は invalid
    if (!this.valueSet.has(JSON.stringify(inputValue))) {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat('選択肢にない値が入力されています')
      )
    }
    return ValidationResult.valid()
  }
}

export interface MultiSelectWidgetDefinition extends BaseWidgetDefinition {
  formType: 'multi_select'
  options: InputWidgetDefinition.Option[]
}

export class MultiSelectWidgetValidator extends RawInputWidgetValidator<
  MultiSelectWidgetDefinition
> {
  private readonly valueSet: Set<string>

  constructor(
    context: InputWidgetValidatorContext<MultiSelectWidgetDefinition>
  ) {
    super(context)
    const set = new Set<string>()
    context.widgetDefinition.options.forEach((option) => {
      const rawInputValue = InputValue.createRawInputValue(option.value)
      set.add(JSON.stringify(rawInputValue))
    })
    this.valueSet = set
  }

  async validate(inputValue: InputValue.Raw): Promise<ValidationResult> {
    if (!InputValue.isInputValueList(inputValue.raw)) {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat('不正な値が入力されています')
      )
    }

    // 定義に存在しない値が1つでも選択されている場合は invalid
    if (
      inputValue.raw.some((value) => {
        return !this.valueSet.has(JSON.stringify(value))
      })
    ) {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat('選択肢にない値が入力されています')
      )
    }

    return ValidationResult.valid()
  }
}
