import {
  ExpectedObjectJson,
  ExpectedObjectsJson,
  TaskExpectedObjectsJson,
  TriggerExpectedObjectsJson,
} from '~/data/workflow/expectedObject/json'
import { mapJsonToObjectPropertyValue } from '~/data/workflow/object/mapJsonToObjectDefinition'
import {
  ExpectedObject,
  ExpectedObjects,
  TaskExpectedObjects,
  TriggerExpectedObjects,
} from '~/domain/workflow/expectedObject/ExpectedObjects'
import { ExpectedObjectKey } from '~/domain/workflow/object/ExpectedObjectKey'

export function mapJsonToExpectedObjects(
  json: ExpectedObjectsJson
): ExpectedObjects {
  const trigger =
    json.trigger !== undefined
      ? mapJsonToTriggerObjects(json.trigger)
      : undefined
  const tasks = json.tasks.map(mapJsonToTaskObjects)
  return new ExpectedObjects(trigger, tasks)
}

export function mapJsonToExpectedObject(
  json: ExpectedObjectJson
): ExpectedObject {
  return {
    key: new ExpectedObjectKey(json.key),
    name: json.name,
    objectId: json.objId,
    sampleValues: json.sampleValues.map(mapJsonToObjectPropertyValue),
  }
}

function mapJsonToTriggerObjects(
  json: TriggerExpectedObjectsJson
): TriggerExpectedObjects {
  return {
    expectedObjects: json.expectedObjects.map(mapJsonToExpectedObject),
    triggerId: json.triggerId,
  }
}

function mapJsonToTaskObjects(
  json: TaskExpectedObjectsJson
): TaskExpectedObjects {
  return {
    taskId: json.taskId,
    actionId: json.actionId,
    expectedObjects: json.expectedObjects.map(mapJsonToExpectedObject),
  }
}
