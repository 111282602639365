import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { animated, useSpring } from 'react-spring'

import { apiClients } from '~/common/apiClients'
import Avatar from '~/components/atoms/Avatar'
import Text from '~/components/atoms/Text'
import { useMe } from '~/presentation/AnyflowAppContext'
import * as routes from '~/routes'
import * as vars from '~/styles/variables'

interface OwnProps {
  isShown: boolean
  collapsed: boolean
  onClose: () => void
}

type Props = OwnProps & RouteComponentProps

const Dropdown: React.FC<Props> = ({
  isShown,
  collapsed,
  onClose,
  history,
}) => {
  const me = useMe()
  const containerProps = useSpring({
    to: async (next: any) => {
      if (isShown) {
        await next({ display: 'block' })
        await next({ opacity: 1, transform: 'translateX(0)' })
      } else {
        await next({
          opacity: 0,
          transform: 'translateX(-10px)',
        })
        await next({ display: 'none' })
      }
    },
    from: {
      opacity: 0,
      display: 'none',
      transform: 'translateX(-10px)',
    },
  })

  const handleClickSignOut = async () => {
    try {
      await apiClients.authService.signOut()
    } finally {
      history.push(routes.SIGNIN)
    }
  }

  React.useEffect(() => {
    const handleClose = () => {
      onClose()
    }
    if (isShown) {
      document.addEventListener('click', handleClose)
    }
    return () => {
      document.removeEventListener('click', handleClose)
    }
  }, [isShown, onClose])

  const target = document.getElementById('portal-root')
  if (target === null) {
    return null
  }
  return ReactDOM.createPortal(
    <Container style={containerProps} collapsed={collapsed}>
      <User>
        <_Avatar />
        <NameContainer>
          <Name>{me ? me.username : 'ユーザー名'}</Name>
          <Organization>{me ? me.organization.name : '組織名'}</Organization>
        </NameContainer>
      </User>
      <NavList>
        <NavItem onClick={handleClickSignOut}>
          <NavItemLabel>サインアウト</NavItemLabel>
        </NavItem>
      </NavList>
    </Container>,
    target
  )
}

const Container = styled(animated.div, {
  shouldForwardProp: isPropValid,
})(
  {
    position: 'fixed',
    bottom: vars.space.l,
    width: vars.width.sidebar,
    backgroundColor: vars.color.white,
    borderRadius: vars.borderRadius.m,
    boxShadow: vars.shadow.m,
    zIndex: vars.zIndex.dropdown,
  },
  (props: { collapsed: boolean }) => ({
    left: props.collapsed
      ? vars.width.sidebarCollapsed + vars.space.m
      : vars.width.sidebar + vars.space.m,
  })
)

const User = styled('div')({
  display: 'flex',
  alignItems: 'center',
  paddingTop: vars.space.m,
  paddingRight: vars.space.m,
  paddingBottom: vars.space.m,
  paddingLeft: vars.space.m,
  borderBottom: `1px solid ${vars.color.border}`,
})

const _Avatar = styled(Avatar)({
  flexShrink: 0,
})

const NameContainer = styled('div')({
  marginLeft: vars.space.s,
  whiteSpace: 'nowrap',
})

const Name = styled('p')({
  fontSize: vars.fontSize.m,
  lineHeight: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const Organization = styled('p')({
  marginTop: vars.space.xs,
  color: vars.fontColor.secondary,
  fontSize: vars.fontSize.s,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const NavList = styled('div')({
  paddingTop: vars.space.s,
  paddingBottom: vars.space.s,
})

const NavItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: 40,
  marginRight: vars.space.s,
  marginLeft: vars.space.s,
  paddingRight: vars.space.m,
  paddingLeft: vars.space.m,
  borderRadius: vars.borderRadius.m,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: vars.color.lightGray,
  },
})

const NavItemLabel: React.FC = (props) => (
  <Text element="span" fontSize="s" color="primary" lineHeight="just">
    {props.children}
  </Text>
)

export default withRouter(Dropdown)
