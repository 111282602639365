import config, { Env, targetEnv } from '~/common/config'
import { Tson } from '~/data/Tson'
import requestJson, { HttpError } from '~/data/request'
import { sanitize } from '~/data/utils'
import { mapJsonToTriggerDefinition } from '~/data/workflow/trigger/mapJsonToTriggerDefinition'
import { mapToTriggerJson } from '~/data/workflow/trigger/mapToTriggerJson'
import { TriggerDefinition } from '~/domain/workflow/trigger/TriggerDefinition'
import { TriggerService } from '~/domain/workflow/trigger/TriggerService'

export class TriggerServiceImpl implements TriggerService {
  async get(triggerId: string): Promise<TriggerDefinition | undefined> {
    try {
      const res = await requestJson(
        `${config.apiRoot}/triggers_info/${sanitize(triggerId)}`
      )
      const json = mapToTriggerJson(new Tson(res))
      return mapJsonToTriggerDefinition(json)
    } catch (e) {
      if (e instanceof HttpError && e.statusCode === 404) {
        return undefined
      }
      throw e
    }
  }

  async getAll(): Promise<TriggerDefinition[]> {
    const res = await requestJson(`${config.apiRoot}/triggers_info`)
    const jsons = new Tson(res).asArray().map(mapToTriggerJson)
    const definitions = jsons.map(mapJsonToTriggerDefinition)

    if (targetEnv === Env.local) {
      definitions.push(devTrigger)
    }
    return definitions
  }
}

export const devTrigger: TriggerDefinition = {
  triggerId: '__dev',
  name: '開発用トリガー',
  description: '開発用のトリガーです',
  appId: '__DevelopApp',
  testable: true,
  fields: [
    {
      key: 'text',
      fieldType: 'static',
      label: 'テキスト',
      note: '',
      required: true,
      renderable: false,
      bulkable: false,
      dependencies: [],
      valueType: {
        typeName: 'string',
        nullable: false,
      },
      form: {
        formType: 'text',
      },
    },
    {
      fieldType: 'info_view',
      key: 'info',
      dependencies: ['text'],
      form: {
        formType: 'trigger_instruction',
        instructionText:
          'クラウドサインの設定ページより、｢詳細｣を開き、Webhook URL 項に URL を貼り付けてください。URL には必ずを含める必要があるのでご注意ください。長い文章の場合、こうなります。',
        triggerInstruction: {
          triggerInstructionId: 'foo',
          parameterFieldKeys: ['text'],
        },
      },
    },
  ],
}
