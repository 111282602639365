import * as React from 'react'

import { RequiredMark } from '~/components/atoms/RequiredMark'
import Text from '~/components/atoms/Text'
import { AccountFormFieldDefinition } from '~/domain/provider/tokenBased/AccountFormFieldDefinition'
import { useMarkedLink } from '~/hooks/useMarkedLink'
import { DependentFields } from '~/presentation/workflow/detail/editor/form/field/DependentField'
import InputWidget from '~/presentation/workflow/detail/editor/form/inputWidget/InputWidget'
import * as vars from '~/styles/variables'

interface Props {
  definition: AccountFormFieldDefinition
  value: string | undefined
  onChange: (value: string | undefined) => void
}

const AccountFormField: React.FC<Props> = (props) => {
  const note = useMarkedLink(props.definition.note)

  return (
    <>
      <Text
        element="p"
        fontSize="s"
        fontWeight={props.definition.required ? 'bold' : 'normal'}
        lineHeight="heading"
        style={{ marginBottom: vars.space.s }}
      >
        {props.definition.label}
        {props.definition.required ? <RequiredMark /> : null}
      </Text>
      <InputWidget
        definition={props.definition.form}
        valueType={{ typeName: 'string', nullable: false }}
        bulkMode={false}
        required={props.definition.required}
        renderable={false}
        bulkable={false}
        readonly={false}
        dependentFields={new DependentFields([])}
        value={
          props.value !== undefined
            ? { mode: 'raw', raw: props.value }
            : undefined
        }
        onChange={(newValue) => {
          if (newValue === undefined) {
            props.onChange(undefined)
            return
          }
          if (newValue.mode === 'render' || typeof newValue.raw !== 'string') {
            throw new Error('Cannot use non-string value in Account Form.')
          }
          props.onChange(newValue.raw)
        }}
        onBulkSizeChange={() => {}}
      />
      <Text
        element="p"
        color="tertiary"
        fontSize="s"
        lineHeight="body"
        style={{ marginTop: vars.space.s }}
        dangerouslySetInnerHTML={{
          __html: note,
        }}
      ></Text>
    </>
  )
}

export default AccountFormField
