import * as React from 'react'

import { AppDefinition } from '~/domain/workflow/app/AppDefinition'
import { useDefinitions } from '~/presentation/AnyflowAppContext'

export function useGetAppFromTrigger(): (triggerId: string) => AppDefinition {
  const definitions = useDefinitions()

  const getAppFromTrigger = React.useCallback(
    (triggerId: string): AppDefinition => {
      const appId = definitions.getTrigger(triggerId).appId
      return definitions.getApp(appId)
    },
    [definitions]
  )

  return getAppFromTrigger
}
