import styled from '@emotion/styled'
import * as React from 'react'

import Loader from '~/components/atoms/Loader'
import * as vars from '~/styles/variables'

interface Props {
  role?: 'primary' | 'secondary' | 'tertiary' | 'danger'
  isLoading?: boolean
  disabled?: boolean
}

const MiniButton: React.FC<Props & React.HTMLAttributes<HTMLButtonElement>> = (
  props
) => {
  return (
    <Container {...props}>
      {props.children}
      {props.isLoading && <Loader reverse={true} size="s" />}
    </Container>
  )
}

const Container = styled('button')(
  {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 32,
    paddingRight: vars.space.m,
    paddingLeft: vars.space.m,
    minWidth: 60,
    fontSize: vars.fontSize.s,
    lineHeight: 1,
    textAlign: 'center',
    borderRadius: vars.borderRadius.s * 1.5,
    border: 'none',
    cursor: 'pointer',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    boxShadow: 'none',
    transition: 'all .14s ease',
  },
  ({ role = 'primary', isLoading = false, disabled = false }: Props) => ({
    backgroundColor:
      role === 'secondary' || role === 'tertiary'
        ? 'transparent'
        : role === 'primary'
        ? vars.color.theme
        : role === 'danger'
        ? vars.color.error
        : '',
    border:
      role === 'tertiary'
        ? 'none'
        : role === 'secondary'
        ? `2px solid ${vars.color.theme}`
        : 'none',
    color:
      role === 'tertiary'
        ? vars.fontColor.tertiary
        : role === 'secondary'
        ? vars.color.theme
        : vars.color.white,
    fontWeight: vars.fontWeight.bold,
    opacity: isLoading || disabled ? 0.5 : 1,
    cursor: isLoading || disabled ? 'not-allowed' : 'pointer',
    '&:hover': {
      opacity: isLoading || disabled ? 0.5 : 0.9,
    },
  })
)

export default MiniButton
