import styled from '@emotion/styled'
import * as React from 'react'
import { Plus } from 'react-feather'

import Button from '~/components/atoms/Button'
import { RequiredMark } from '~/components/atoms/RequiredMark'
import Text from '~/components/atoms/Text'
import SelectField, { Option } from '~/components/molecules/SelectField'
import Inner from '~/components/organisms/Inner'
import Modal from '~/components/organisms/Modal'
import { MultiProviderAccountWidgetDefinition } from '~/domain/workflow/widget/multiProviderAccount'
import InputWidget from '~/presentation/workflow/detail/editor/form/inputWidget/InputWidget'
import { RawInputWidgetProps } from '~/presentation/workflow/detail/editor/form/inputWidget/RawInputWidget'
import {
  ProcessStatus,
  useLoginSocial,
} from '~/presentation/workflow/detail/editor/form/inputWidget/account/useLoginSocial'
import { useInputWidgetValidation } from '~/presentation/workflow/detail/editor/form/validation/useValidation'
import * as vars from '~/styles/variables'
import { widgetBorderStyle } from '~/styles/widget'

interface Props extends RawInputWidgetProps {
  definition: MultiProviderAccountWidgetDefinition
}

export const MultiProviderAccountWidget: React.FC<Props> = (props) => {
  const { onChange } = props
  const [modalShown, setModalShown] = React.useState(false)
  const {
    startProcess,
    status: loginProcessStatus,
    socialAccountUid,
  } = useLoginSocial()

  // バリデーション
  // バリデーションエラーは表示しない（サブウィジェット側のものが表示されるので問題ない）
  useInputWidgetValidation(props)

  const changeValueWithAccountUid = React.useCallback(
    (accountUid: string) => {
      onChange({
        mode: 'raw',
        raw: accountUid,
      })
    },
    [onChange]
  )

  React.useEffect(() => {
    if (loginProcessStatus !== ProcessStatus.processed) {
      return
    }
    if (socialAccountUid === undefined) {
      return
    }
    changeValueWithAccountUid(socialAccountUid)
  }, [changeValueWithAccountUid, loginProcessStatus, socialAccountUid])

  const handleClickAddButton = React.useCallback(() => {
    if (props.readonly) {
      return
    }
    setModalShown(true)
  }, [props.readonly])

  const handleModalClose = React.useCallback(() => {
    setModalShown(false)
  }, [])

  const handleDecideProvider = React.useCallback(
    (provider: string) => {
      setModalShown(false)
      startProcess(provider)
    },
    [startProcess]
  )

  return (
    <>
      <Modal isOpened={modalShown} onClose={handleModalClose}>
        <_Inner width={550}>
          <SelectProviderForm
            providers={props.definition.providers}
            fieldLabel={props.definition.fieldLabel}
            handleClose={handleModalClose}
            handleDecide={handleDecideProvider}
          />
        </_Inner>
      </Modal>
      <div style={{ display: 'flex', width: '100%' }}>
        <WidgetContainer>
          <InputWidget {...props} definition={props.definition.valueForm} />
        </WidgetContainer>
        <AddButton disabled={props.readonly} onClick={handleClickAddButton}>
          <Plus size={20} color={vars.color.icon} />
        </AddButton>
      </div>
    </>
  )
}

interface Provider {
  label: string
  value: string
}

const unselectedOption: Option<string> = {
  label: '選択してください',
  value: '',
}

const SelectProviderForm: React.FC<{
  providers: Provider[]
  fieldLabel: string
  handleClose: () => void
  handleDecide: (provider: string) => void
}> = (props) => {
  const { handleDecide } = props

  const [provider, setProvider] = React.useState(unselectedOption.value)
  const onChange = React.useCallback((selectedValue: string) => {
    setProvider(selectedValue)
  }, [])

  const handleClickDecideButton = React.useCallback(() => {
    if (provider === unselectedOption.value) {
      return
    }
    handleDecide(provider)
  }, [handleDecide, provider])

  const options: Option<string>[] = React.useMemo(() => {
    return props.providers.map((it) => {
      return { label: it.label, value: it.value }
    })
  }, [props.providers])

  return (
    <div>
      <Heading element="h2" fontSize="l" fontWeight="bold">
        アカウントの種類を選択
      </Heading>
      <Text
        element="p"
        fontSize="s"
        fontWeight="bold"
        lineHeight="heading"
        style={{ marginBottom: vars.space.s }}
      >
        {props.fieldLabel}
        <RequiredMark />
      </Text>

      <SelectField
        options={options}
        value={provider}
        onChange={onChange}
        unselectedOption={unselectedOption}
      />
      <ButtonContainer>
        <Button nativeType="button" type="tertiary" onClick={props.handleClose}>
          キャンセル
        </Button>
        <_Button
          nativeType="button"
          onClick={handleClickDecideButton}
          disabled={provider === ''}
        >
          決定
        </_Button>
      </ButtonContainer>
    </div>
  )
}

const _Inner = styled(Inner)({
  padding: vars.space.m,
})

const WidgetContainer = styled('div')({
  flexGrow: 1,
  // minWidth: 0 がないと、SelectWidget のときに truncated が効かない
  minWidth: 0,
})

const AddButton = styled('div')(
  {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: vars.space.s,
    width: vars.height.field,
    height: vars.height.field,
    backgroundColor: vars.color.white,
    ...widgetBorderStyle,
  },
  (props: { disabled: boolean }) => ({
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    opacity: props.disabled ? 0.5 : 1,
  })
)

const Heading = styled(Text)({
  marginBottom: vars.space.m,
})

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: vars.space.l,
})

const _Button = styled(Button)({
  marginLeft: vars.space.s,
})
