import { InputValue } from '~/domain/workflow/source/InputValue'
import { ValidationResult } from '~/domain/workflow/validator/ValidationResult'
import { BaseWidgetDefinition } from '~/domain/workflow/widget/WidgetDefinition'
import { RawInputWidgetValidator } from '~/domain/workflow/widget/validator/RawInputWidgetValidator'

export interface TextWidgetDefinition extends BaseWidgetDefinition {
  formType: 'text'
  placeholder?: string
  pattern?: string
}

export class TextWidgetValidator extends RawInputWidgetValidator<
  TextWidgetDefinition
> {
  async validate(inputValue: InputValue.Raw): Promise<ValidationResult> {
    if (typeof inputValue.raw !== 'string') {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat('不正な値が入力されています')
      )
    }
    if (
      this.context.widgetDefinition.pattern !== undefined &&
      !inputValue.raw.match(new RegExp(this.context.widgetDefinition.pattern))
    ) {
      return ValidationResult.invalid(
        new ValidationResult.BadFormat('テキストの形式が異なります')
      )
    }
    return ValidationResult.valid()
  }
}
