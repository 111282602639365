import { assertNever } from '~/common/utils'
import {
  InputSourceJson,
  InputValueJson,
  RawInputValueJsonTypes,
  TaskSourceJson,
  TriggerSourceJson,
  WorkflowSourceBodyJson,
} from '~/data/workflow/source/json'
import { InputValue } from '~/domain/workflow/source/InputValue'
import {
  InputSource,
  TaskSource,
  TriggerSource,
  WorkflowSourceBody,
} from '~/domain/workflow/source/WorkflowSourceBody'
import { convertRenderableElementsToJinjaTemplateText } from '~/presentation/workflow/detail/editor/form/inputWidget/render/EntityField/utils'

export function mapModelToWorkflowSourceBodyJson(
  workflowSourceBody: WorkflowSourceBody
): WorkflowSourceBodyJson {
  const task = workflowSourceBody.getRootTask()
  const trigger = workflowSourceBody.getTrigger()
  return {
    task: task !== undefined ? mapModelToTaskSourceJson(task) : undefined,
    trigger:
      trigger !== undefined ? mapModelToTriggerSourceJson(trigger) : undefined,
  }
}

export function mapModelToTaskSourceJson(
  taskSource: TaskSource
): TaskSourceJson {
  return {
    actionId: taskSource.actionId,
    inputs: taskSource.inputs.map(mapModelToInputSourceJson),
    taskId: taskSource.taskId,
    tasks: taskSource.tasks.map(mapModelToTaskSourceJson),
  }
}

export function mapModelToTriggerSourceJson(
  triggerSource: TriggerSource
): TriggerSourceJson {
  return {
    inputs: triggerSource.inputs.map(mapModelToInputSourceJson),
    triggerId: triggerSource.triggerId,
  }
}

export function mapModelToInputSourceJson(
  inputSource: InputSource
): InputSourceJson {
  return {
    key: inputSource.fieldKey,
    value: mapModelToInputValueJson(inputSource.value),
  }
}

export function mapModelToInputValueJson(
  inputValue: InputValue
): InputValueJson {
  if (inputValue === undefined) {
    return null
  }
  switch (inputValue.mode) {
    case 'raw':
      return {
        mode: 'raw',
        raw: mapModelToRawInputValueJson(inputValue.raw),
      }
    case 'render':
      return {
        mode: 'render',
        template: convertRenderableElementsToJinjaTemplateText(
          inputValue.template
        ),
      }
    default:
      assertNever(inputValue)
  }
}

function mapModelToRawInputValueJson(
  rawInputValue: InputValue.Raw.ValueTypes
): RawInputValueJsonTypes {
  switch (typeof rawInputValue) {
    case 'string':
    case 'number':
    case 'boolean':
      return rawInputValue
    case 'object':
      if (InputValue.isStructEntryList(rawInputValue)) {
        return rawInputValue.map((it) => ({
          key: it.key,
          value: mapModelToInputValueJson(it.value),
        }))
      }
      if (InputValue.isInputValueList(rawInputValue)) {
        return rawInputValue.map(mapModelToInputValueJson)
      }
      throw new Error(
        `Illegal rawInputValue type: must be InputValue[] or StructEntry[]`
      )
    default:
      throw new Error(`Illegal rawInputValue type: ${typeof rawInputValue}`)
  }
}
