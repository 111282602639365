import { InputValue } from '~/domain/workflow/source/InputValue'
import { DependentValues } from '~/domain/workflow/widget/validator/DependentValues'

export interface DependentField {
  fieldKey: string
  value: InputValue
  required: boolean
}

export class DependentFields {
  public readonly fields: DependentField[]

  constructor(fields: DependentField[]) {
    this.fields = fields
  }

  findField(fieldKey: string): DependentField | undefined {
    return this.fields.find((it) => it.fieldKey === fieldKey)
  }

  isFilled(): boolean {
    return this.fields.every(
      (it) => !it.required || InputValue.isFilled(it.value)
    )
  }

  toDependentValues(): DependentValues {
    const obj = {}
    this.fields.forEach((it) => {
      obj[it.fieldKey] = it.value
    })
    return obj
  }

  equals(other: DependentFields): boolean {
    if (other.fields.length !== this.fields.length) {
      return false
    }
    for (const otherField of other.fields) {
      const field = this.findField(otherField.fieldKey)
      if (field === undefined || otherField.value !== field.value) {
        return false
      }
    }
    return true
  }
}
