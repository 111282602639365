import styled from '@emotion/styled'
import * as React from 'react'

import Button from '~/components/atoms/Button'
import * as vars from '~/styles/variables'

interface Props {
  label: string
  loading: boolean
  onClick: () => void
}

export const GoogleSignInButton: React.FC<Props> = (props) => {
  return (
    <_Button onClick={props.onClick} loading={props.loading}>
      <Icon alt="" src="/static/icons/google@2x.png" width={24} />
      <Label>{props.label}</Label>
    </_Button>
  )
}

const googleColor = '#4285F4'

const _Button = styled(Button)({
  border: 0,
  height: 45,
  minHeight: 45,
  width: '100%',
  backgroundColor: googleColor,
  position: 'relative',
})

const Icon = styled('img')({
  position: 'absolute',
  left: vars.space.m,
})

const Label = styled('span')({
  fontSize: vars.fontSize.l,
})
