import * as utils from '~/common/utils'
import { ValueType } from '~/domain/ValueType'

export function valueTypeToString(valueType: ValueType): string {
  switch (valueType.typeName) {
    case 'string': {
      return 'string'
    }
    case 'number': {
      if (valueType.canDecimal) {
        return 'float'
      }
      return 'int'
    }
    case 'boolean': {
      return 'boolean'
    }
    case 'list': {
      return `${valueTypeToString(valueType.itemType)}[]`
    }
    case 'dict': {
      return `{ [string]: ${valueTypeToString(valueType.valueType)} }`
    }
    case 'struct': {
      const entries = valueType.entries
        .map((entry) => {
          return `{ ${entry.key}: ${valueTypeToString(entry.valueType)} }`
        })
        .join(', ')
      return `{ ${entries} }`
    }
    case 'file': {
      return 'file'
    }
    case 'any': {
      return 'any'
    }
    case 'never': {
      return 'never'
    }
    default:
      utils.assertNever(valueType)
  }
}
