import * as React from 'react'

import { apiClients } from '~/common/apiClients'

const authService = apiClients.authService

export function useSignInWithEmail() {
  const [signingIn, setSigningIn] = React.useState(false)

  const signInWithEmail = React.useCallback(
    async (email: string, password: string) => {
      setSigningIn(true)
      try {
        await authService.signInWithEmail(email, password)
      } finally {
        setSigningIn(false)
      }
    },
    []
  )
  return { signingIn, signInWithEmail }
}
