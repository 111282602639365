import { ConnectionJson } from '~/data/connection/ConnectionJson'
import { mapJsonToUser } from '~/data/user/mapJsonToUser'
import { Connection } from '~/domain/connection/Connection'

export function mapJsonToConnection(json: ConnectionJson): Connection {
  return {
    id: json.id.toString(),
    provider: json.provider,
    uid: json.uid,
    label: json.label,
    user: mapJsonToUser(json.user),
    appId: json.appId,
  }
}
