import * as React from 'react'

import { apiClients } from '~/common/apiClients'
import { ActionDefinition } from '~/domain/workflow/action/ActionDefinition'
import { ActionService } from '~/domain/workflow/action/ActionService'

const actionService: ActionService = apiClients.actionService

export function useActionDefinitions(): {
  actions?: ActionDefinition[]
  fetching: boolean
  errorMessage?: string
} {
  const [actions, setActions] = React.useState<ActionDefinition[]>()
  const [fetching, setFetching] = React.useState<boolean>(true)
  const [errorMessage, setErrorMessage] = React.useState<string>()
  React.useEffect(() => {
    actionService
      .getAll()
      .finally(() => setFetching(false))
      .then((it) => setActions(it))
      .catch((reason) => {
        console.error(`Failed to get actions: ${reason}`)
        setErrorMessage(reason)
      })
  }, [])
  return { actions, fetching, errorMessage }
}

export function useActionDefinition(
  actionId?: string
): {
  action?: ActionDefinition
  fetching: boolean
  errorMessage?: string
} {
  const [action, setAction] = React.useState<ActionDefinition>()
  const [fetching, setFetching] = React.useState<boolean>(true)
  const [errorMessage, setErrorMessage] = React.useState<string>()
  React.useEffect(() => {
    if (actionId === undefined) {
      return
    }
    actionService
      .get(actionId)
      .finally(() => setFetching(false))
      .then((it) => setAction(it))
      .catch((reason) => {
        console.error(`Failed to get actions: ${reason}`)
        setErrorMessage(reason)
      })
  }, [actionId])
  return { action, fetching, errorMessage }
}
