import styled from '@emotion/styled'
import * as React from 'react'
import { animated, useSpring, useTransition } from 'react-spring'

import * as vars from '~/styles/variables'

interface Props {
  isShown: boolean
  children: React.ReactNode
  onClose: () => void
}

const FormContainer: React.FC<Props> = ({ isShown, children, onClose }) => {
  const transitions = useTransition(isShown, null, {
    from: { transform: `translateX(400px)` },
    enter: { transform: `translateX(0)` },
    leave: { transform: `translateX(400px)` },
  })
  const overlayProps = useSpring({
    opacity: isShown ? 1 : 0,
  })
  return (
    <>
      {transitions.map(({ item, key, props }) =>
        item ? (
          <React.Fragment key={key}>
            <Overlay style={overlayProps} onClick={onClose} />
            <Container style={props}>{children}</Container>
          </React.Fragment>
        ) : null
      )}
    </>
  )
}

export const formWidth = 550

const Container = styled(animated.div)({
  position: 'fixed',
  top: 0,
  right: 0,
  width: formWidth,
  height: '100%',
  zIndex: vars.zIndex.form,
})

const Overlay = styled(animated.div)({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  zIndex: vars.zIndex.formOverlay,
})

export default FormContainer
