import styled from '@emotion/styled'
import _ from 'lodash'
import hash from 'object-hash'
import * as React from 'react'

import { assert, assertNever, run } from '~/common/utils'
import Button from '~/components/atoms/Button'
import DropdownButton from '~/components/atoms/DropdownButton'
import ErrorMessage from '~/components/atoms/ErrorMessage'
import ErrorBoundary from '~/components/common/ErrorBoundary'
import { ActionDefinition } from '~/domain/workflow/action/ActionDefinition'
import { InputValue } from '~/domain/workflow/source/InputValue'
import { WorkflowNode } from '~/domain/workflow/source/WorkflowNode'
import {
  InputSource,
  WorkflowSourceBody,
} from '~/domain/workflow/source/WorkflowSourceBody'
import { TriggerDefinition } from '~/domain/workflow/trigger/TriggerDefinition'
import { CompositeValidator } from '~/domain/workflow/validator/CompositeValidator'
import { useDefinitions } from '~/presentation/AnyflowAppContext'
import { MapLike, useMapState } from '~/presentation/useMapState'
import InputForm from '~/presentation/workflow/detail/editor/form/InputForm'
import InputFormHeader from '~/presentation/workflow/detail/editor/form/InputFormHeader'
import ExpectedObjectProvider from '~/presentation/workflow/detail/editor/form/expectedObject/ExpectedObjectProvider'
import {
  ValidationContext,
  ValidationContextInterface,
} from '~/presentation/workflow/detail/editor/form/validation/ValidationContext'
import VariableFinderHolder from '~/presentation/workflow/detail/editor/form/variableFinder/VariableFinderHolder'
import { ExpectedObjectSnapshotsProvider } from '~/presentation/workflow/detail/editor/test/ExpectedObjectSnapshotsContext'
import NodeTestModal from '~/presentation/workflow/detail/editor/test/NodeTestModal'
import NodeTestResultModal from '~/presentation/workflow/detail/editor/test/NodeTestResultModal'
import NodeTestStatus from '~/presentation/workflow/detail/editor/test/NodeTestStatus'
import { useWorkflowTestResult } from '~/presentation/workflow/detail/editor/test/WorkflowTestResultContext'
import { scrollbarStyle } from '~/styles/scrollbar'
import * as vars from '~/styles/variables'

interface Props {
  workflowId: string
  source: WorkflowSourceBody
  targetNode: WorkflowNode
  readonly: boolean
  handleSaveClick: (newInputs: InputSource[]) => Promise<void>
  handleCloseClick: () => void
}

const NodeEditor: React.FC<Props> = (props) => {
  const definitions = useDefinitions()
  const [bulkSizeErrorMessage, setBulkSizeErrorMessage] = React.useState<
    string
  >()
  const [validating, setValidating] = React.useState<boolean>(false)
  const [saving, setSaving] = React.useState<boolean>(false)
  const [saveErrorMessage, setSaveErrorMessage] = React.useState<string>()
  const [inputValues, putInputValue] = useMapState<InputValue>(
    run(() => {
      const inputSources = run(() => {
        switch (props.targetNode.kind) {
          case 'trigger': {
            return props.source.getTrigger()?.inputs ?? []
          }
          case 'task': {
            return props.source.findTask(props.targetNode.taskId)?.inputs ?? []
          }
        }
      })
      const obj = {}
      inputSources.forEach((inputSource) => {
        obj[inputSource.fieldKey] = inputSource.value
      })
      return obj
    })
  )
  const validator = React.useRef<CompositeValidator>(new CompositeValidator())
  const [isTestModalOpen, setIsTestModalOpen] = React.useState<boolean>(false)
  const [isTestResultModalOpen, setIsTestResultModalOpen] = React.useState<
    boolean
  >(false)

  const {
    workflowTestResult,
    update: updateWorkflowTestResult,
  } = useWorkflowTestResult()

  const targetTriggerOrAction:
    | TriggerDefinition
    | ActionDefinition = React.useMemo(() => {
    switch (props.targetNode.kind) {
      case 'trigger': {
        const trigger = props.source.getTrigger()
        assert(
          trigger !== undefined,
          "targetNode is trigger, but source doesn't have a trigger."
        )
        return definitions.getTrigger(trigger.triggerId)
      }
      case 'task': {
        const task = props.source.findTask(props.targetNode.taskId)
        assert(
          task !== undefined,
          `targetNode is task (${props.targetNode.taskId}), but source doesn't have such task.`
        )
        return definitions.getAction(task.actionId)
      }
      default:
        assertNever(props.targetNode)
    }
  }, [definitions, props.source, props.targetNode])

  // バルク可能なフィールドが一つでもあれば、自動的にバルクモードにする
  const bulkMode = React.useMemo<boolean>(() => {
    return targetTriggerOrAction.fields.some((it) => {
      if (it.fieldType === 'info_view') {
        return false
      }
      return it.bulkable
    })
  }, [targetTriggerOrAction])

  const inputValuesSaved = React.useMemo(() => {
    let sourceInputValues: InputSource[]
    switch (props.targetNode.kind) {
      case 'trigger': {
        sourceInputValues = props.source.getTrigger()?.inputs ?? []
        break
      }
      case 'task': {
        const taskSource = props.source.findTask(props.targetNode.taskId)
        sourceInputValues = taskSource?.inputs ?? []
        break
      }
      default: {
        assertNever(props.targetNode)
      }
    }
    return sameInputValues(
      sourceInputValues,
      transformMapToInputSource(inputValues)
    )
  }, [props.targetNode, props.source, inputValues])

  const validationContextValue: ValidationContextInterface = React.useMemo(
    () => ({ validator: validator.current }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validator.current]
  )

  const save = React.useCallback(async () => {
    if (inputValuesSaved) {
      return
    }
    setSaving(true)
    try {
      await props.handleSaveClick(transformMapToInputSource(inputValues))
      await updateWorkflowTestResult((current) => {
        if (props.targetNode.kind === 'trigger') {
          return current.removeTriggerResult()
        } else {
          return current.removeTaskResult(props.targetNode.taskId)
        }
      })
    } catch (e) {
      console.error(e)
      setSaveErrorMessage('保存に失敗しました')
    } finally {
      setSaving(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.handleSaveClick, inputValues, bulkMode])

  const testResult = React.useMemo(() => {
    switch (props.targetNode.kind) {
      case 'trigger':
        return workflowTestResult.findTriggerResult()
      case 'task':
        return workflowTestResult.findTaskResult(props.targetNode.taskId)
      default:
        assertNever(props.targetNode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(props.targetNode), workflowTestResult])

  const handleInputChange = React.useCallback(
    (fieldKey: string, newInput: InputValue) => {
      if (props.readonly) {
        return
      }
      putInputValue(fieldKey, newInput)
    },
    [props.readonly, putInputValue]
  )

  const handleOkClick = React.useCallback(async () => {
    setValidating(true)
    const validationResults = await validator.current.validate()
    setValidating(false)
    if (validationResults.some(CompositeValidator.isInvalid)) {
      return
    }
    await save()
    props.handleCloseClick()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [save, props.handleCloseClick])

  const handleTestClick = React.useCallback(async () => {
    setValidating(true)
    const validationResults = await validator.current.validate()
    setValidating(false)
    if (validationResults.some(CompositeValidator.isInvalid)) {
      return
    }
    await save()
    setIsTestModalOpen(true)
  }, [save])

  const handleTestComplete = React.useCallback(() => {
    setIsTestResultModalOpen(true)
    setIsTestModalOpen(false)
  }, [])

  const handleTestModalCloseClick = React.useCallback(
    () => setIsTestModalOpen(false),
    []
  )

  const handleTestResultModalCloseClick = React.useCallback(
    () => setIsTestResultModalOpen(false),
    []
  )

  const handleTestResultModalRetestClick = React.useCallback(() => {
    if (!inputValuesSaved) {
      alert('再実行前に入力値を保存してください')
      return
    }
    setIsTestResultModalOpen(false)
    setIsTestModalOpen(true)
  }, [inputValuesSaved])

  const parentId = run(() => {
    if (props.targetNode.kind === 'trigger') {
      return undefined
    }
    if (props.targetNode.kind === 'task') {
      return props.source.findParentTaskOf(props.targetNode.taskId)?.taskId
    }
    assertNever(props.targetNode)
  })

  return (
    <Container>
      <FormComp>
        <ErrorBoundary
          description={
            <>
              申し訳ございません。エラーが発生しました。
              <br />
              しばらく待ってから再度お試しください。
            </>
          }
        >
          <ValidationContext.Provider value={validationContextValue}>
            <ExpectedObjectProvider
              workflowId={props.workflowId}
              parentTaskIdOfTarget={parentId}
            >
              <ExpectedObjectSnapshotsProvider>
                <InputFormHeader
                  app={definitions.getApp(targetTriggerOrAction.appId)}
                  title={targetTriggerOrAction.name}
                  onCloseClick={props.handleCloseClick}
                />
                <Line />
                <Content>
                  {bulkSizeErrorMessage && (
                    <ErrorMessage style={{ marginBottom: vars.space.s }}>
                      {bulkSizeErrorMessage}
                    </ErrorMessage>
                  )}
                  <VariableFinderHolder offsetRight={vars.space.m * 2.5}>
                    <InputForm
                      bulkMode={bulkMode}
                      readonly={props.readonly}
                      fields={targetTriggerOrAction.fields}
                      inputValues={inputValues}
                      onInputChange={handleInputChange}
                      onBulkSizeError={(errorMessage) =>
                        setBulkSizeErrorMessage(errorMessage)
                      }
                    />
                  </VariableFinderHolder>
                  {/* 余白を作ることで下方のフィールドを入力しやすく */}
                  <Spacer />
                </Content>
                <NodeTestModal
                  source={props.source}
                  target={props.targetNode}
                  useBulk={bulkMode}
                  isOpen={isTestModalOpen}
                  onTestComplete={handleTestComplete}
                  onCloseClick={handleTestModalCloseClick}
                />
                <NodeTestResultModal
                  isOpen={isTestResultModalOpen}
                  result={testResult}
                  onCloseClick={handleTestResultModalCloseClick}
                  onRetestClick={handleTestResultModalRetestClick}
                />
                <Footer>
                  <div>
                    {targetTriggerOrAction.testable && (
                      <NodeTestStatus
                        result={testResult}
                        onClick={() => setIsTestResultModalOpen(true)}
                      />
                    )}
                  </div>
                  <ButtonContainer>
                    {targetTriggerOrAction.testable ? (
                      <DropdownButton
                        label="保存してテスト"
                        loading={saving || validating}
                        disabled={props.readonly}
                        options={[
                          {
                            label: '保存',
                            onClick: handleOkClick,
                          },
                        ]}
                        onClick={handleTestClick}
                      />
                    ) : (
                      <Button
                        type="primary"
                        loading={saving || validating}
                        disabled={props.readonly}
                        onClick={handleOkClick}
                      >
                        保存
                      </Button>
                    )}
                    <CloseButton
                      type="tertiary"
                      onClick={props.handleCloseClick}
                    >
                      閉じる
                    </CloseButton>
                  </ButtonContainer>
                </Footer>
                {saveErrorMessage && (
                  <p style={{ textAlign: 'right' }}>{saveErrorMessage}</p>
                )}
              </ExpectedObjectSnapshotsProvider>
            </ExpectedObjectProvider>
          </ValidationContext.Provider>
        </ErrorBoundary>
      </FormComp>
    </Container>
  )
}

export default NodeEditor

function transformMapToInputSource(
  map: MapLike<InputValue | undefined>
): InputSource[] {
  const result: InputSource[] = []
  Object.entries(map).forEach(([key, value]) => {
    if (value !== undefined) {
      result.push({ fieldKey: key, value })
    }
  })
  return result
}

function sameInputValues(first: InputSource[], second: InputSource[]): boolean {
  const reducer = (acc: {}, inputSource: InputSource) => {
    if (inputSource.value !== undefined) {
      acc[inputSource.fieldKey] = inputSource.value
    }
    return acc
  }
  const firstMap = _.reduce(first, reducer, {})
  const secondMap = _.reduce(second, reducer, {})
  return hash(firstMap) === hash(secondMap)
}

const Container = styled('div')({
  padding: vars.space.m,
  width: '100%',
  height: '100%',
})

const FormComp = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: vars.color.white,
  borderRadius: vars.borderRadius.m,
  boxShadow: vars.shadow.l,
})

const Content = styled('div')({
  flexGrow: 1,
  paddingTop: vars.space.m * 1.5,
  paddingRight: vars.space.m * 1.5,
  paddingBottom: vars.space.m * 1.5,
  paddingLeft: vars.space.m * 1.5,
  overflowY: 'auto',
  ...scrollbarStyle,
})

const Line = styled('hr')({
  height: 1,
  backgroundColor: vars.color.border,
  border: 'none',
})

const Spacer = styled('div')({
  height: '100%',
  maxHeight: '50vh',
})

const Footer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: vars.space.m,
  borderTop: `1px solid ${vars.color.border}`,
})

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row-reverse',
})

const CloseButton = styled(Button)({
  marginRight: vars.space.s,
})
