import produce from 'immer'
import { createSelector } from 'reselect'
import { actionCreatorFactory } from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { RootState } from '~/ducks'

const actionCreator = actionCreatorFactory('anyflow/ui/messageViewer')

export const types = {
  RESET: 'RESET',
  SHOW: 'SHOW',
  HIDE: 'HIDE',
}

interface ShowRequest {
  taskId: string
}

export const actions = {
  reset: actionCreator(types.RESET),
  show: actionCreator<ShowRequest>(types.SHOW),
  hide: actionCreator(types.HIDE),
}

export const selectors = {
  getState: createSelector(
    (state: RootState) => state.ui.messageViewer,
    (state) => state
  ),
}

export type State = {
  taskId: string | null
  isShown: boolean
}

const initialState: State = {
  taskId: null,
  isShown: false,
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.reset, () => Object.assign({}, initialState))
  .case(actions.show, (state, payload) =>
    produce(state, (draft) => {
      draft.taskId = payload.taskId
      draft.isShown = true
    })
  )
  .case(actions.hide, () => Object.assign({}, initialState))
