import React, { ReactPortal, useContext, useMemo, useState } from 'react'

import { AssertionError } from '~/common/utils'

const ProseMirrorNodeViewPortalsContext = React.createContext<{
  addPortal: (portalContainer: ReactPortal) => void
  portals: Portals
}>({
  addPortal: (): never => {
    throw new AssertionError(
      'ProseMirrorNodeViewPortalsContext is not initialize properly'
    )
  },
  get portals(): never {
    throw new AssertionError(
      'ProseMirrorNodeViewPortalsContext is not initialize properly'
    )
  },
})

export const ProseMirrorNodeViewPortalsProvider: React.FC = (props) => {
  const [portals, setPortals] = useState<Portals>([])
  const contextValue = useMemo(() => {
    return {
      addPortal: (portal: ReactPortal) => {
        setPortals((prev) => {
          return [...prev, portal]
        })
      },
      portals: portals,
    }
  }, [portals])

  return (
    <ProseMirrorNodeViewPortalsContext.Provider value={contextValue}>
      {props.children}
      {portals}
    </ProseMirrorNodeViewPortalsContext.Provider>
  )
}

type Portals = ReactPortal[]

export const useProseMirrorNodeViewPortals = () =>
  useContext(ProseMirrorNodeViewPortalsContext)
