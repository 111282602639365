import styled from '@emotion/styled'
import Color from 'color'
import React from 'react'

import Text from '~/components/atoms/Text'
import EnterKeyTrap from '~/components/utils/EnterKeyTrap'
import { useFollowState } from '~/hooks/useFollowState'
import * as vars from '~/styles/variables'

interface Range {
  from: number
  to: number
}

interface Props {
  min: number
  max: number
  origin: number
  step: number
  beforeLabel?: string
  afterLabel?: string
  originLabel?: string
  disabled?: boolean
  value: Range
  onChange: (newValue: Range) => void
}

interface Params {
  max: number
  min: number
  size: number
  step: number
  origin: number
  originPercent: number
}

type Action =
  | { type: 'start_drag_a'; rect: DOMRect }
  | { type: 'start_drag_b'; rect: DOMRect }
  | { type: 'mousemove'; clientX: number }
  | { type: 'mouseup' }
  | { type: 'set_params'; params: Params }
  | { type: 'set_rect'; rect: DOMRect }
  | { type: 'set_value'; from: number; to: number }
  | { type: 'change_value_a'; valueA: number }
  | { type: 'change_value_b'; valueB: number }

interface State {
  draggingA: boolean // A がドラッグされているか
  draggingB: boolean // B がドラッグされているか
  valueA: number // A の値
  valueB: number // B の値
  rect: DOMRect // コンテナの Bounding Box
  params: Params // props から計算したパラメータ
}

function reducer(prev: State, action: Action): State {
  switch (action.type) {
    case 'start_drag_a': {
      return { ...prev, rect: action.rect, draggingA: true }
    }
    case 'start_drag_b': {
      return { ...prev, rect: action.rect, draggingB: true }
    }
    case 'mousemove': {
      if (!prev.draggingA && !prev.draggingB) {
        return prev
      }
      const relativeX = action.clientX - prev.rect.x
      const ratio = normalize(relativeX / prev.rect.width)
      const value = snap(
        ratio * prev.params.size + prev.params.min,
        prev.params.step
      )
      const next = { ...prev }
      if (prev.draggingA) {
        next.valueA = value
      }
      if (prev.draggingB) {
        next.valueB = value
      }
      return next
    }
    case 'mouseup': {
      return { ...prev, draggingA: false, draggingB: false }
    }
    case 'set_params': {
      return { ...prev, params: action.params }
    }
    case 'set_rect': {
      return { ...prev, rect: action.rect }
    }
    case 'set_value': {
      // valueA が 5, valueB が 10 の状態から valueB を 1 にすると、 mouseup のタイミングで onChange に
      // from が 1 で to が 5 として通知される。その onChange によって props.value が変更されこの set_value
      // が呼ばれるが、from を常に valueA にしてしまうと valueA が 1 に、valueB が 5 になってしまう。
      // その結果 valueA と valueB が入れ替わるので、draggingB によって表示されていたシャドーが完全に消える前に
      // knob A, B が入れ替わってしまい、シャドーが点滅して見えてしまう。
      // それを防ぐために一致判定を行う。
      if (prev.valueB === action.from) {
        return { ...prev, valueB: action.from, valueA: action.to }
      }
      return { ...prev, valueA: action.from, valueB: action.to }
    }
    case 'change_value_a': {
      return {
        ...prev,
        valueA: Math.min(
          Math.max(action.valueA, prev.params.min),
          prev.params.max
        ),
      }
    }
    case 'change_value_b': {
      return {
        ...prev,
        valueB: Math.min(
          Math.max(action.valueB, prev.params.min),
          prev.params.max
        ),
      }
    }
  }
}

function normalize(value: number): number {
  return Math.min(Math.max(value, 0), 1)
}

function snap(value: number, step: number): number {
  return Math.round(value / step) * step
}

const RangeSlider: React.FC<Props> = ({ onChange, ...props }) => {
  const containerRef = React.useRef<HTMLDivElement>(null)

  const [state, dispatch] = React.useReducer(reducer, {
    draggingA: false,
    draggingB: false,
    valueA: 0,
    valueB: 0,
    rect: new DOMRect(),
    params: {
      min: 0,
      max: 0,
      size: 0,
      step: 0,
      origin: 0,
      originPercent: 0,
    },
  })

  // props が更新されたときに state を更新する
  React.useEffect(() => {
    const min = Math.min(props.min, props.max)
    const max = Math.max(props.min, props.max)
    const origin = Math.min(Math.max(props.origin, min), max)
    const size = Math.abs(props.max - props.min)
    const unitPercent = 100 / size // 1 あたりの percent
    const originPercent = Math.abs(origin - min) * unitPercent
    dispatch({
      type: 'set_params',
      params: {
        min,
        max,
        size,
        step: props.step,
        origin,
        originPercent,
      },
    })
  }, [props.min, props.max, props.origin, props.step])

  // value が変更された時に state を更新する
  React.useEffect(() => {
    dispatch({ type: 'set_value', from: props.value.from, to: props.value.to })
  }, [props.value.from, props.value.to])

  // 初回 rect を設定する
  React.useEffect(() => {
    if (containerRef.current === null) {
      return
    }
    dispatch({
      type: 'set_rect',
      rect: containerRef.current.getBoundingClientRect(),
    })
  }, [])

  // mousemove イベントの設定
  React.useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      dispatch({ type: 'mousemove', clientX: e.clientX })
    }
    document.addEventListener('mousemove', handleMouseMove)
    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  // mouseup イベントの設定
  const prevRangeRef = React.useRef<Range>()
  React.useEffect(() => {
    const handleMouseUp = () => {
      dispatch({ type: 'mouseup' })
      const from = Math.min(state.valueA, state.valueB)
      const to = Math.max(state.valueA, state.valueB)
      // state が変わったタイミングですぐに onChange しないのは、
      // state はドラッグ時などに頻繁に変更されるため
      const prev = prevRangeRef.current
      if (prev === undefined || prev.from !== from || prev.to !== to) {
        onChange({ from, to })
      }
      prevRangeRef.current = { from, to }
    }
    document.addEventListener('mouseup', handleMouseUp)
    return () => {
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [onChange, state.valueA, state.valueB])

  const computedValues = React.useMemo(() => {
    function asPercent(n: number): number {
      return normalize((n - state.params.min) / state.params.size) * 100
    }
    const percentA = asPercent(state.valueA)
    const xA = (percentA / 100) * state.rect.width
    const percentB = asPercent(state.valueB)
    const xB = (percentB / 100) * state.rect.width
    return { percentA, percentB, xA, xB }
  }, [state.valueA, state.valueB, state.params, state.rect])

  const handleMouseDownA = React.useCallback(
    (e: React.MouseEvent) => {
      if (props.disabled) {
        return
      }
      e.preventDefault()
      dispatch({
        type: 'start_drag_a',
        rect: containerRef.current?.getBoundingClientRect() ?? new DOMRect(),
      })
    },
    [props.disabled]
  )

  const handleMouseDownB = React.useCallback(
    (e: React.MouseEvent) => {
      if (props.disabled) {
        return
      }
      e.preventDefault()
      dispatch({
        type: 'start_drag_b',
        rect: containerRef.current?.getBoundingClientRect() ?? new DOMRect(),
      })
    },
    [props.disabled]
  )

  const handleChangeValueA = React.useCallback((newValue: number) => {
    dispatch({ type: 'change_value_a', valueA: newValue })
  }, [])

  const handleChangeValueB = React.useCallback((newValue: number) => {
    dispatch({ type: 'change_value_b', valueB: newValue })
  }, [])

  const upSideDownA = React.useMemo(() => {
    return (
      computedValues.xA > computedValues.xB &&
      computedValues.xA - computedValues.xB < 60
    )
  }, [computedValues])

  const upSideDownB = React.useMemo(() => {
    return (
      computedValues.xB >= computedValues.xA &&
      computedValues.xB - computedValues.xA < 60
    )
  }, [computedValues])

  return (
    <FlexBox>
      <div>
        <Text element="span" fontSize="xs" lineHeight="just">
          {props.beforeLabel}
        </Text>
      </div>
      <div style={{ flex: 1 }}>
        <Spacer>
          <Container ref={containerRef}>
            <Line
              backgroundColor={vars.colorPalette.gray2}
              originPercent={state.params.originPercent}
            />
            <Line
              backgroundColor={vars.color.theme}
              originPercent={state.params.originPercent}
              style={{
                clipPath: `inset(-2px calc(100% - ${Math.max(
                  computedValues.percentA,
                  computedValues.percentB
                )}%) -2px ${Math.min(
                  computedValues.percentA,
                  computedValues.percentB
                )}%)`,
              }}
            />
            <Locator leftPercent={state.params.originPercent}>
              <div style={{ transform: 'translate(-50%, 43px)' }}>
                <Text
                  element="span"
                  fontSize="xs"
                  fontWeight="bold"
                  lineHeight="just"
                >
                  {props.originLabel}
                </Text>
              </div>
            </Locator>
            <Locator leftPercent={computedValues.percentA}>
              <Knob dragging={state.draggingA} onMouseDown={handleMouseDownA} />
              <BalloonWrapper upSideDown={upSideDownA}>
                <Balloon
                  value={state.valueA}
                  upSideDown={upSideDownA}
                  disabled={props.disabled ?? false}
                  handleValueChange={handleChangeValueA}
                />
              </BalloonWrapper>
            </Locator>
            <Locator leftPercent={computedValues.percentB}>
              <Knob dragging={state.draggingB} onMouseDown={handleMouseDownB} />
              <BalloonWrapper upSideDown={upSideDownB}>
                <Balloon
                  value={state.valueB}
                  upSideDown={upSideDownB}
                  disabled={props.disabled ?? false}
                  handleValueChange={handleChangeValueB}
                />
              </BalloonWrapper>
            </Locator>
          </Container>
        </Spacer>
      </div>
      <div>
        <Text element="span" fontSize="xs" lineHeight="just">
          {props.afterLabel}
        </Text>
      </div>
    </FlexBox>
  )
}

const Line: React.FC<{
  backgroundColor: string
  originPercent: number
  style?: React.CSSProperties
}> = (props) => {
  return (
    <div
      style={{
        width: '100%',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
      }}
    >
      <div
        style={{
          height: 6,
          width: '100%',
          position: 'relative',
          borderRadius: 5,
          backgroundColor: props.backgroundColor,
          ...props.style,
        }}
      >
        <div
          style={{
            height: 10,
            width: 10,
            position: 'absolute',
            top: '50%',
            left: `${props.originPercent}%`,
            border: `1px solid ${vars.color.white}`,
            borderRadius: '50%',
            backgroundColor: props.backgroundColor,
            transform: 'translate(-50%, -50%)',
          }}
        />
      </div>
    </div>
  )
}

const Balloon: React.FC<{
  value: number
  upSideDown: boolean
  disabled: boolean
  handleValueChange: (newValue: number) => void
}> = (props) => {
  const { handleValueChange } = props
  const [temp, setTemp] = useFollowState<string>(toSignedText(props.value))

  const resetChange = React.useCallback(() => {
    setTemp(toSignedText(props.value))
  }, [setTemp, props.value])

  const handleSubmit = React.useCallback(() => {
    const n = Number.parseInt(temp)
    if (!Number.isFinite(n)) {
      resetChange()
      return
    }
    handleValueChange(n)
  }, [temp, resetChange, handleValueChange])

  return (
    <EnterKeyTrap handleEnter={handleSubmit}>
      <BalloonContainer upSideDown={props.upSideDown ?? false}>
        <input
          type="text"
          value={temp}
          disabled={props.disabled}
          onChange={(e) => setTemp(e.target.value)}
          onBlur={handleSubmit}
          style={{ width: '100%', textAlign: 'right' }}
        />
      </BalloonContainer>
    </EnterKeyTrap>
  )
}

function toSignedText(n: number): string {
  if (n > 0) {
    return `+${n.toString()}`
  }
  return n.toString()
}

const Spacer = styled('div')({
  paddingTop: vars.space.xl,
  paddingRight: vars.space.m,
  paddingBottom: vars.space.xl,
  paddingLeft: vars.space.m,
})

const FlexBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const Container = styled('div')({
  position: 'relative',
})

const Locator = styled('div')(
  {
    position: 'absolute',
  },
  (props: { leftPercent: number }) => ({
    left: `${props.leftPercent}%`,
  })
)

const Knob = styled('div')(
  {
    height: 14,
    width: 14,
    position: 'absolute',
    top: '50%',
    backgroundColor: vars.color.theme,
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'box-shadow 0.25s',
    transform: 'translate(-50%, -50%)',
  },
  (props: { dragging: boolean }) => ({
    boxShadow: props.dragging
      ? `0 0 0 8px ${Color(vars.color.theme).alpha(0.25)}`
      : 'none',
  })
)

const BalloonContainer = styled('div')(
  {
    width: 56,
    padding: `${vars.space.xs}px ${vars.space.xs * 1.5}px`,
    fontSize: vars.fontSize.s,
    lineHeight: 1,
    color: vars.fontColor.primary,
    whiteSpace: 'nowrap',
    backgroundColor: vars.color.white,
    borderRadius: vars.borderRadius.xs,
    boxShadow: vars.shadow.m,
    '&::after': {
      content: '""',
      width: 4,
      height: 6,
      position: 'absolute',
      left: '50%',
      bottom: 0,
      borderTop: `3px solid ${vars.color.white}`,
      borderRight: `2px solid transparent`,
      borderLeft: `2px solid transparent`,
      boxSizing: 'border-box',
      transform: 'translate(-50%, 100%)',
    },
  },
  (props: { upSideDown: boolean }) => ({
    '&::after': {
      top: props.upSideDown ? 0 : 'unset',
      bottom: props.upSideDown ? 'unset' : 0,
      borderTop: props.upSideDown ? 'none' : `3px solid ${vars.color.white}`,
      borderBottom: props.upSideDown ? `3px solid ${vars.color.white}` : 'none',
      transform: props.upSideDown
        ? 'translate(-50%, -100%)'
        : 'translate(-50%, 100%)',
    },
  })
)

const BalloonWrapper = styled('div')(
  {
    position: 'absolute',
    top: 0,
    display: 'inline-block',
  },
  (props: { upSideDown?: boolean }) => ({
    transform: props.upSideDown
      ? 'translate(-50%, 15px)'
      : 'translate(-50%, calc(-100% - 15px))',
  })
)

export default RangeSlider
