import { delay, failRandomly } from '~/common/utils'
import { Group } from '~/domain/group/Group'
import { SessionService } from '~/domain/session/SessionService'
import { Me } from '~/domain/user/Me'
import { User } from '~/domain/user/User'

const user: User = {
  id: 'mocked_user_slug',
  username: 'モック太郎',
  organization: { id: 'mocked_organization_slug', name: '合同会社モック' },
  isAdmin: true,
}

const groups = [
  new Group({
    id: 'group_1',
    name: 'グループ１',
    workflowCount: 1,
    updatedAt: new Date(),
    members: [user],
  }),
  new Group({
    id: 'group_2',
    name: 'グループ2',
    workflowCount: 2,
    updatedAt: new Date(),
    members: [user],
  }),
  new Group({
    id: 'group_3',
    name: 'グループ3',
    workflowCount: 3,
    updatedAt: new Date(),
    members: [user],
  }),
]

const data = {
  me: new Me({
    ...user,
    email: 'mock@example.com',
    isAdmin: true,
    currentGroupId: groups[0].id,
    belongingGroups: groups,
  }),
}

export class MockSessionService implements SessionService {
  async getMe(): Promise<Me> {
    await delay(50)
    failRandomly(0.02)
    return data.me
  }

  async changeCurrentGroup(groupId: string): Promise<void> {
    await delay(500)
    failRandomly(0.1)
    data.me = data.me.changeCurrentGroup(groupId)
  }
}
