import config from '~/common/config'
import { getCSRFToken } from '~/common/utils'
import { Tson } from '~/data/Tson'
import { AuthError } from '~/data/auth/AuthError'
import {
  defaultErrorMessage,
  mapJsonToInvitationSignUpErrorMessage,
  mapJsonToSignInErrorMessage,
  mapJsonToSignUpErrorMessage,
} from '~/data/auth/mapJsonToErrorMessage'
import { mapJsonToMeWithoutRole } from '~/data/auth/mapJsonToMeWithoutRole'
import {
  mapToInvitationSignUpErrorJson,
  mapToSignInErrorJson,
  mapToSignUpErrorJson,
} from '~/data/auth/mapToAuthErrorJson'
import { mapToMeWithoutRoleJson } from '~/data/auth/mapToMeWithoutRoleJson'
import requestJson, { HttpError, requestPost } from '~/data/request'
import { NoOrganizationError } from '~/data/user/NoOrganizationError'
import { AuthService } from '~/domain/auth/AuthService'
import { MeWithoutRole } from '~/domain/auth/MeWithoutRole'

export class AuthServiceImpl implements AuthService {
  async signUpWithGoogle(idToken: string): Promise<MeWithoutRole> {
    await this.forceSignOut()
    try {
      const res = await requestJson(`${config.apiRoot}/google_auth/signup`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      const json = mapToMeWithoutRoleJson(new Tson(res))
      return mapJsonToMeWithoutRole(json)
    } catch (error) {
      if (error instanceof NoOrganizationError) {
        throw error
      }
      if (error instanceof HttpError) {
        const errorBody = await error.responseBody
        const json = mapToSignUpErrorJson(new Tson(errorBody))
        const errorMessage = mapJsonToSignUpErrorMessage(json)
        throw new AuthError(errorMessage)
      }
      throw new AuthError(defaultErrorMessage.signUp)
    }
  }

  async signInWithGoogle(idToken: string): Promise<MeWithoutRole> {
    await this.forceSignOut()
    try {
      const res = await requestJson(`${config.apiRoot}/google_auth/signin`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      const json = mapToMeWithoutRoleJson(new Tson(res))
      return mapJsonToMeWithoutRole(json)
    } catch (error) {
      if (error instanceof NoOrganizationError) {
        throw error
      }
      if (error instanceof HttpError) {
        const errorBody = await error.responseBody
        const json = mapToSignInErrorJson(new Tson(errorBody))
        const errorMessage = mapJsonToSignInErrorMessage(json)
        throw new AuthError(errorMessage)
      }
      throw new AuthError(defaultErrorMessage.signIn)
    }
  }

  async signUpByInvitationWithGoogle(
    idToken: string,
    invitationToken: string
  ): Promise<MeWithoutRole> {
    await this.forceSignOut()
    const url = `${config.apiRoot}/google_auth/invitation_signup`
    const body = { invitation_token: invitationToken }
    try {
      const res = await requestJson(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      const json = mapToMeWithoutRoleJson(new Tson(res))
      return mapJsonToMeWithoutRole(json)
    } catch (error) {
      if (error instanceof NoOrganizationError) {
        throw error
      }
      if (error instanceof HttpError) {
        const errorBody = await error.responseBody
        const json = mapToInvitationSignUpErrorJson(new Tson(errorBody))
        const errorMessage = mapJsonToInvitationSignUpErrorMessage(json)
        throw new AuthError(errorMessage)
      }
      throw new AuthError(defaultErrorMessage.invitationSignUp)
    }
  }

  async signInWithEmail(email: string, password: string): Promise<void> {
    await this.forceSignOut()
    await requestJson(`${config.apiRoot}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    })
  }

  async signOut(): Promise<void> {
    await requestPost(`${config.apiRoot}/auth/logout`)
  }

  // 二重ログインはエラーになるので防ぐ
  private async forceSignOut(): Promise<void> {
    const CSRFToken = getCSRFToken()
    if (CSRFToken !== undefined) {
      try {
        await this.signOut()
      } catch (ignore) {}
    }
  }
}
