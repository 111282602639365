import config from '~/common/config'
import { Tson } from '~/data/Tson'
import { requestJson, requestPost } from '~/data/request'
import { mapJsonToUser } from '~/data/user/mapJsonToUser'
import { mapToUserJson } from '~/data/user/mapToUserJson'
import { sanitize } from '~/data/utils'
import { OrganizationService } from '~/domain/user/OrganizationService'
import { User } from '~/domain/user/User'

export class OrganizationServiceImpl implements OrganizationService {
  async getUsers(organizationId: string): Promise<User[]> {
    const res = await requestJson(
      `${config.apiRoot}/organizations/${sanitize(
        organizationId
      )}/users?all=true`
    )
    const json = new Tson(res).asArray().map(mapToUserJson)
    return json.map(mapJsonToUser)
  }

  async create(name: string): Promise<void> {
    await requestPost(`${config.apiRoot}/organizations`, { name })
  }
}
