import styled from '@emotion/styled'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { formatRoute } from 'react-router-named-routes'

import { PendingResult } from '~/common/types'
import { assertNever } from '~/common/utils'
import Button from '~/components/atoms/Button'
import Loader from '~/components/atoms/Loader'
import AppComp from '~/components/molecules/App'
import Grid from '~/components/organisms/Grid'
import Inner from '~/components/organisms/Inner'
import NoResults from '~/components/organisms/NoResults'
import NodeTree from '~/components/organisms/NodeTree'
import * as sidebarDuck from '~/ducks/ui/sidebar'
import { useAppsFromSource } from '~/hooks/useAppsFromSource'
import { useMarkedLink } from '~/hooks/useMarkedLink'
import { useRecipe } from '~/hooks/useRecipe'
import { useWorkflowTree } from '~/hooks/useTree'
import * as routes from '~/routes'
import * as vars from '~/styles/variables'

const RecipeDetail: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams<routes.RecipeDetailParams>()
  const [creating, setCreating] = React.useState<boolean>(false)

  const recipeResult = useRecipe(params.recipeId)
  const [, apps] = useAppsFromSource(
    recipeResult.status === PendingResult.Status.success
      ? recipeResult.data.source
      : undefined
  )
  const [nodes, links] = useWorkflowTree(
    recipeResult.status === PendingResult.Status.success
      ? recipeResult.data.source
      : undefined
  )
  const linkedDescription = useMarkedLink(
    recipeResult.status === PendingResult.Status.success
      ? recipeResult.data.description
      : ''
  )

  React.useEffect(() => {
    dispatch(sidebarDuck.actions.expand())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleButtonClick() {
    if (recipeResult.status !== PendingResult.Status.success) {
      return
    }
    setCreating(true)
    history.push(
      formatRoute(`${routes.RECIPE_DETAIL}/create`, {
        recipeId: recipeResult.data.id,
      })
    )
  }

  return (
    <>
      {recipeResult.status === PendingResult.Status.pending ? (
        <Centering>
          <Loader />
        </Centering>
      ) : recipeResult.status === PendingResult.Status.failure ? (
        <Centering>
          <NoResults
            heading={'レシピが見つかりません'}
            description={'レシピが削除された可能性があります。'}
          />
        </Centering>
      ) : recipeResult.status === PendingResult.Status.success ? (
        <>
          <Masthead>
            <TopInner width={860}>
              <HeadingConainer>
                <div>
                  <Heading>{recipeResult.data.name}</Heading>
                  <CategoryContainer>
                    <CategoryTitle>カテゴリー</CategoryTitle>
                    <CategoryList>
                      {recipeResult.data.categories.map((category) => (
                        <CategoryName key={category.id}>
                          {category.label}
                        </CategoryName>
                      ))}
                    </CategoryList>
                  </CategoryContainer>
                </div>
                <_Button onClick={handleButtonClick} loading={creating}>
                  このレシピを使う
                </_Button>
              </HeadingConainer>
            </TopInner>
          </Masthead>
          <BottomInner width={860}>
            <DescriptionContainer>
              <SubHeading>概要</SubHeading>
              <Description
                dangerouslySetInnerHTML={{ __html: linkedDescription }}
              />
            </DescriptionContainer>
            <AppListContainer>
              <SubHeading>使用するアプリケーション</SubHeading>
              <GridContainer>
                <Grid
                  items={apps}
                  keyExtractor={(app) => app.appId}
                  gap={vars.space.s}
                  column={3}
                  renderItem={(app) => (
                    <AppItem key={app.appId}>
                      <AppComp icon={app.iconPath} color={app.color} size="m" />
                      <AppName>{app.name}</AppName>
                    </AppItem>
                  )}
                />
              </GridContainer>
            </AppListContainer>
            <NodeTreeContainer>
              <NodeTree nodes={nodes} links={links} />
            </NodeTreeContainer>
          </BottomInner>
        </>
      ) : (
        assertNever(recipeResult)
      )}
    </>
  )
}

const Centering = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
})

const TopInner = styled(Inner)({
  paddingTop: vars.space.l,
  paddingBottom: vars.space.l,
})

const BottomInner = styled(Inner)({
  paddingTop: vars.space.l,
  paddingBottom: vars.space.l,
})

const Masthead = styled('div')({
  backgroundColor: '#fbfbfb',
})

const HeadingConainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const Heading = styled('h2')({
  fontSize: vars.fontSize.xxl,
})

const CategoryContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginTop: vars.space.s,
})

const CategoryList = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const CategoryTitle = styled('span')({
  marginRight: vars.space.s,
  fontSize: vars.fontSize.xs,
  fontWeight: vars.fontWeight.bold,
})

const CategoryName = styled('span')({
  marginRight: vars.space.s,
  fontSize: vars.fontSize.xs,
  '&:last-of-type': {
    marginRight: 0,
  },
})

const DescriptionContainer = styled('div')({})

const Description = styled('p')({
  marginTop: vars.space.m,
  fontSize: vars.fontSize.m,
  lineHeight: 1.9,
  whiteSpace: 'pre-wrap',
})

const SubHeading = styled('h3')({
  fontSize: vars.fontSize.s,
  fontWeight: vars.fontWeight.bold,
})

const NodeTreeContainer = styled('div')({
  marginTop: vars.space.xl,
  boxShadow: vars.shadow.m,
  textAlign: 'center',
})

const AppListContainer = styled('div')({
  marginTop: vars.space.l,
})

const GridContainer = styled('div')({
  marginTop: vars.space.m,
})

const AppItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginTop: vars.space.s,
  '&:first-of-type': {
    marginTop: 0,
  },
})

const AppName = styled('div')({
  marginLeft: vars.space.s,
  fontSize: vars.fontSize.s,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
})

const _Button = styled(Button)({
  flexShrink: 0,
  marginLeft: vars.space.m,
})

export default RecipeDetail
