import styled from '@emotion/styled'
import * as React from 'react'
import { Calendar } from 'react-feather'
import Helmet from 'react-helmet'
import { useParams } from 'react-router-dom'

import { PendingResult } from '~/common/types'
import { formatDate } from '~/common/utils'
import Loader from '~/components/atoms/Loader'
import Text from '~/components/atoms/Text'
import Pager from '~/components/molecules/Pager'
import Inner from '~/components/organisms/Inner'
import SingleLineEditor from '~/components/organisms/SingleLineEditor'
import { LookupTableCell } from '~/domain/lookupTable/LookupTableCell'
import { LookupTableColumnSchema } from '~/domain/lookupTable/LookupTableColumnSchema'
import { useToaster } from '~/presentation/ToasterContext'
import ErrorMessage from '~/presentation/lookupTable/ErrorMessage'
import LookupTableView from '~/presentation/lookupTable/LookupTableView'
import NotFound from '~/presentation/lookupTable/NotFound'
import { useLookupTable } from '~/presentation/lookupTable/useLookupTable'
import { usePageQuery } from '~/presentation/utilityHooks'
import { LOOKUP_TABLE_DETAIL, LookupTableDetailParams } from '~/routes'
import * as vars from '~/styles/variables'

const LookupTableDetail: React.FC = () => {
  const params = useParams<LookupTableDetailParams>()
  const pageNumber = usePageQuery()
  const toaster = useToaster()

  const {
    result: lookupTableResult,
    updateColumnSchema,
    updateTableName,
    insertRow,
    deleteRow,
  } = useLookupTable(params.lookupTableId, pageNumber)

  const handleColumnSchemaChange = async (
    columnSchema: LookupTableColumnSchema
  ) => {
    try {
      await updateColumnSchema(columnSchema)
    } catch (e) {
      console.error(e)
      toaster.showError('エラーが発生しました')
    }
  }

  const handleTableNameChange = async (newName: string) => {
    try {
      await updateTableName(newName)
    } catch (e) {
      console.error(e)
      toaster.showError('エラーが発生しました')
    }
  }

  const handleInsertRow = async (cells: LookupTableCell[]) => {
    try {
      await insertRow(cells)
    } catch (e) {
      console.error(e)
      toaster.showError('エラーが発生しました')
    }
  }

  const handleDeleteRow = async (rowId: number) => {
    if (!confirm('行を削除してもよろしいですか？')) {
      return
    }
    try {
      await deleteRow(rowId)
    } catch (e) {
      console.error(e)
      toaster.showError('エラーが発生しました')
    }
  }

  // ルックアップテーブル自体がペンディングのとき、画面全体にローダー表示
  if (lookupTableResult.status === PendingResult.Status.pending) {
    return (
      <div
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: `calc(100w - ${vars.width.sidebar}px)`,
          height: '100vh',
        }}
      >
        <Loader />
      </div>
    )
  }

  if (lookupTableResult.status === PendingResult.Status.failure) {
    return <ErrorMessage />
  }

  if (lookupTableResult.data === undefined) {
    return <NotFound />
  }

  const lookupTable = lookupTableResult.data // alias
  return (
    <>
      <Helmet>
        <title>{lookupTable.name}</title>
      </Helmet>
      <_Inner>
        <div>
          <SingleLineEditor
            editorInitialText={lookupTable.name}
            onSubmit={handleTableNameChange}
          >
            <Heading>{lookupTable.name}</Heading>
          </SingleLineEditor>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: vars.space.m,
          }}
        >
          <Calendar
            size={14}
            color={vars.fontColor.secondary}
            style={{ marginRight: vars.space.s }}
          />
          <Text element="span" fontSize="s" color="secondary" lineHeight="just">
            {formatDate(lookupTable.updatedAt)}
          </Text>
        </div>
        <>
          <LookupTableView
            data={lookupTableResult.data}
            onRequestSchemaChange={handleColumnSchemaChange}
            onRequestInsertRow={handleInsertRow}
            onRequestDeleteRow={handleDeleteRow}
            style={{
              marginTop: vars.space.l,
            }}
          />
          <_Pager
            to={LOOKUP_TABLE_DETAIL}
            currentPageNumber={
              lookupTableResult.data.rowsPagination.currentPageNumber
            }
            countPerPage={lookupTableResult.data.rowsPagination.countPerPage}
            totalCount={lookupTableResult.data.rowsPagination.totalCount}
            params={{
              lookupTableId: lookupTable.id,
            }}
          />
        </>
      </_Inner>
    </>
  )
}

const _Inner = styled(Inner)({
  padding: vars.space.l,
  margin: 'unset',
  width: '100%',
})

const Heading = styled('h1')({
  margin: 0,
  fontSize: vars.fontSize.xxl,
})

const _Pager = styled(Pager)({
  margin: '0 auto',
  marginTop: vars.space.l,
  maxWidth: vars.width.m,
})

export default LookupTableDetail
