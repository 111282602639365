import produce from 'immer'
import { createSelector } from 'reselect'
import { actionCreatorFactory } from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { DeepReadonly } from 'utility-types'

import { RootState } from '~/ducks'

const actionCreator = actionCreatorFactory('anyflow/ui/sidebar')

export const types = {
  COLLAPSE: 'COLLAPSE',
  EXPAND: 'EXPAND',
}

export const actions = {
  collapse: actionCreator(types.COLLAPSE),
  expand: actionCreator(types.EXPAND),
}

export const selectors = {
  getIsCollapsed: createSelector(
    (state: RootState) => state.ui.sidebar.isCollapsed,
    (isCollapsed) => isCollapsed
  ),
}

export type State = DeepReadonly<{
  isCollapsed: boolean
}>

const initialState: State = {
  isCollapsed: false,
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.collapse, (state) =>
    produce(state, (draft) => {
      draft.isCollapsed = true
    })
  )
  .case(actions.expand, (state) =>
    produce(state, (draft) => {
      draft.isCollapsed = false
    })
  )
